<template>
    <div>
     <b-modal size="lg" :id="ModalId" :visible="true" scrollable centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalAddGoals" @close="this.emitEventCancel" @ok="this.emitEventOK" ok-only :ok-title="$t('Shared.Close')" > 
        <Loading v-bind:IsLoading="this.Load" />   
		
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
              <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
          </div>
   

        <div class="IncidentContainerBox noNum mt-2">            
            <div class="row">
            <div class="col-md-12">
                <div class="grid-margin stretch-card">
                <div class="card activeCard">
                    <div class="card-body">
                    <div 
                        class="d-flex align-items-start justify-content-between">
                        <h6 v-b-toggle="'InfoBoxs'" class="card-title flex-grow lower-line text-truncate">
                            {{PhotoObj.patientName}} - {{PatientCode}} - {{ $t('Shared.Dob') }} {{PhotoObj.dob}} {{PhotoObj.ageFormatted}} - {{$t('Nurses.Weight')}} {{PhotoObj.weight + label('Weightlbs')}} - {{$t('Nurses.Height')}} {{PhotoObj.heights + label('Heightft')}}</h6>
                        </div>
                        <b-collapse visible id="InfoBoxs" class="small-info ml-2"><small>{{$t('modalMarsMed.clickTitleTextPatient')}}</small></b-collapse>
                    <b-collapse id="InfoBoxs"> 
                        


                        <div class="card activeCard">			
                            <div class="container-mars">
                                <div class="card-container">
                                    <div class="card-body">
                                        <div class="row patPhoto-container">
                                            <div class="col-md-1 patPhoto">

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="PhotoObj.allergies && !PhotoObj.specialPrecautions && !PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIcon helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.ALLERGIC')}: `+PhotoObj.allergies" 
                                                    variant="primary">
                                                </i>

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="!PhotoObj.allergies && PhotoObj.specialPrecautions && !PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIcon helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.SPECIALPR')}: `+PhotoObj.specialPrecautions" 
                                                    variant="primary">
                                                </i>

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="!PhotoObj.allergies && !PhotoObj.specialPrecautions && PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIcon helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.MarsAlerts')}: `+PhotoObj.marsAlerts" 
                                                    variant="primary">
                                                </i>

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="PhotoObj.allergies && PhotoObj.specialPrecautions && !PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIcon helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.ALLERGIC')}: `+PhotoObj.allergies + ` / ` + $t('RigthBar.SPECIALPR') +': '+ PhotoObj.specialPrecautions" 
                                                    variant="primary">
                                                </i>
                                                
                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="PhotoObj.allergies && !PhotoObj.specialPrecautions && PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIcon helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.ALLERGIC')}: `+PhotoObj.allergies + ` / ` + $t('RigthBar.MarsAlerts') +': '+ PhotoObj.marsAlerts" 
                                                    variant="primary">
                                                </i>


                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="!PhotoObj.allergies && PhotoObj.specialPrecautions && PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIcon helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.SPECIALPR')}: `+PhotoObj.specialPrecautions + ` / ` + $t('RigthBar.MarsAlerts') +': '+ PhotoObj.marsAlerts" 
                                                    variant="primary">
                                                </i>

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="PhotoObj.allergies && PhotoObj.specialPrecautions && PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIcon helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.ALLERGIC')}: `+PhotoObj.allergies + ' / '+   `${$t('RigthBar.SPECIALPR')}: `+PhotoObj.specialPrecautions + ` / ` + $t('RigthBar.MarsAlerts') +': '+ PhotoObj.marsAlerts" 
                                                    variant="primary">
                                                </i>

                                                <img v-if="PhotoObj.patientPhoto" v-bind:src="'data:image/'+'.'+PhotoObj.patientPhotoExtension+';base64,'+PhotoObj.patientPhoto" alt="Foto" class="patPic img-fluid" style="height: 100%;">
                                                <img v-if="(PhotoObj.patientPhoto === null || PhotoObj.patientPhoto === '') && PhotoObj.sex === 'Male'" src="@/assets/images/faces/nopic-male.jpg" alt="Foto" class="photo img-fluid">
                                                <img v-if="(PhotoObj.patientPhoto === null || PhotoObj.patientPhoto === '') && PhotoObj.sex === 'Female'" src="@/assets/images/faces/nopic-female.jpg" alt="Foto" class="photo img-fluid">
                                                
                                            
                                            </div>
                                            <div class="col-md-11">
                                                <div class="row">
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{ $t('Shared.PatientCode') }}</small><br>
                                                        <b>{{PatientCode}}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{ $t('Shared.PatientName') }}</small><br>
                                                        <b>{{PhotoObj.patientName}}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{ $t('Shared.Dob') }}</small><br>
                                                        <b>{{PhotoObj.dob}}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{ $t('Shared.Age') }}</small><br>
                                                        <b>{{PhotoObj.ageFormatted}}</b>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{ $t('Shared.Gender') }}</small><br>
                                                        <b>{{PhotoObj.sex}}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{$t('Nurses.Weight')}}</small><br>
                                                        <b>{{PhotoObj.weight + label('Weightlbs')}}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{$t('Nurses.Height')}}</small><br>
                                                        <b>{{PhotoObj.heights + label('Heightft')}}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item">
                                                        <small>{{$t('Nurses.Program')}}</small><br>
                                                        <b>{{PhotoObj.programName}}</b>
                                                    </div>                                                    
                                                </div>
                                            </div>
                                        </div>                   
                                    </div>
                                </div>
                            </div>    
                        </div>
                        


                        <div class="row mt-2"> 
                            <MdPatientAllergies
                                v-bind:NoUseClass="false"               
                                v-bind:ModalIdAddAllergy="'AddAllergyMars'"
                                v-bind:VisibleAllergy="true"                    
                                v-bind:UseColumnDinamic="true"
                                v-bind:Column="'col-md-3'"                   
                                v-bind:PatientCode="PatientCode.trim()"
                                v-bind:NoValidateChanges="true">						
                            </MdPatientAllergies> 
                            <MdPatientSpecialPrecautions	
                                v-bind:NoUseClass="false"                
                                v-bind:ModalIdAddSpecialPrecautions="'AddSpecialPrecautionMarsMed'"                   
                                v-bind:VisibleSpecialPrecautions="true"
                                v-bind:UseColumnDinamic="true"
                                v-bind:Column="'col-md-3'"
                                v-bind:PatientCode="PatientCode.trim()"
                                v-bind:NoValidateChanges="true">						
                            </MdPatientSpecialPrecautions> 
                            <MdPatientMarsAlert v-if="true"	
                                v-bind:UseColumnDynamic="true"
                                v-bind:ModalIdAddMarksAlert="'ModalMarsAlert'"
                                v-bind:VisibleMarsAlert="true"
                                v-bind:Column="'col-md-3'"					
                                v-bind:PatientCode="PatientCode.trim()">						
                            </MdPatientMarsAlert>
                            <MdPatientDiagnosis v-if="true"
                                v-bind:UseColumnDinamic="true"
                                v-bind:VisibleDiagnosis="true"
                                v-bind:ModalIdDiagnosis="'ModalIdDiagMarsMed'"
                                v-bind:Column="'col-md-3'"
                                v-bind:PatientCode="PatientCode.trim()">						
                            </MdPatientDiagnosis>
                        </div>               
                </b-collapse>
                    </div> 
                </div>                
                </div>                 
            </div>                     
            </div>                
        </div>

      

        <b-row class="mt-2">
            <b-col md="2" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Nurses.DateTimeRangeFrom')"                      
                    v-bind:TypeProp="'text'" 
                    v-bind:isReadOnly="ViewReadOnly || isReadOnly"
                    v-bind:Mask="'##/##/#### ##:## AA'"
                    v-bind:Min="'1900-01-01'"                    
                    v-bind:Max="'2200-12-31'" 
                    v-bind:isRequired="true"
                    v-bind:isValid="IsValidDateandTimeFrom" 
                    v-bind:OnPressEnter="getListing"                       
                    v-model="DateFromModal"/>          
            </b-col>
            <b-col md="2" sm="12">
                <MdEdit 
                v-bind:Label="$t('Nurses.DateTimeRangeTo')"                      
                v-bind:TypeProp="'text'" 
                v-bind:isReadOnly="ViewReadOnly || isReadOnly"
                v-bind:Mask="'##/##/#### ##:## AA'"
                v-bind:Min="'1900-01-01'"                
                v-bind:Max="'2200-12-31'"  
                v-bind:isRequired="true"
                v-bind:isValid="IsValidDateandTimeTo" 
                v-bind:OnPressEnter="getListing"                       
                v-model="DateToModal"/>  
            </b-col>
            <b-col md="2">
                <MdSelect
                    v-bind:Label="$t('modalMarsMed.Type')"                   
                    v-bind:Options="ModeList"
                    v-bind:FieldDescription="'name'"
                    v-bind:FieldCode="'code'"
                    v-bind:AllowEmpty="false"
                    v-bind:Model="mode"                       
                    v-bind:Multiple="false"
                    v-bind:IsDisabled="ViewReadOnly || isReadOnly"
                    :Input="ChangeDropDownMode"                   
                  />
            </b-col>

            <b-col md="5">
                <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                    <div class="input-group">
                      <input type="text" class="form-control" v-model="parameter.SearchCriteria" :readonly="ViewReadOnly || isReadOnly"
                        @keyup.enter="GetMedications">
      
                      <div class="input-group-append">
                        <MdButton v-bind:Name="'btnsearchinput'" v-bind:VariantProp="'btn btn-sm search-attached'"
                          v-bind:IconClass="'mdi mdi-magnify'" v-on:click="GetMedications" v-bind:isReadOnly="ViewReadOnly || isReadOnly">
                        </MdButton>
                      </div>
                    </div>
                  </div>
            

            </b-col>
            <b-col lg="1" md="1" sm="12" class="align-self-end mb-2">
                <button 
                    :disabled="isReadOnly || ViewReadOnly"
                    v-on:click="SearchMed"
                    type="button"
                    class="btn btn-primary btn-login"												
                    id="btnsearchinput">
                    {{ $t('Shared.Search') }}
                </button>
            </b-col>       
        </b-row>
        

  
          <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

          <div class="alert alert-fill-infoPlus d-flex align-items-center m-3" role="alert" v-if="ViewReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
              <span>{{ $t('modalMarsMed.EmployeeMessageType') }}</span>
            </div>
          </div>

          <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite"><div class="text-center my-2">{{$t('MdTable.nRecShow')}}</div></div>
          <div class="zebraAccordion">
          <div class="zebraRow" v-for="(item, index) in MedList" :key="index"> 

            <div class="accordion" role="tablist" >              
                <div class="zebraItem" v-b-toggle="'accordion-'+index">

                    <b-row class="d-flex align-items-center">
                        <b-col md="2">    
                           <!-- <div class="zItemIcon"><i class="ikon med-dashboard h3" v-on:click="SaveAuto($event,item)"></i></div>-->
                            <div v-if="HaveOneGiven(index)" class="zItemIcon"><i class="ikon med-adm-given h3" v-b-tooltip.hover :title="$t('modalMarsMed.Given')"></i></div>
                            <div v-if="HaveOneNoGiven(index) && !HaveOneGiven(index)" class="zItemIcon"><i class="ikon med-adm-not-given h3" v-b-tooltip.hover :title="$t('modalMarsMed.NoGiven')"></i></div>
                           <!-- <div v-if="GivenNeutro(index) && !item.needAssessment"> <b-icon  icon="play-fill" @click="SaveAuto($event,item)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon></div>-->
                           <div v-if="GivenNeutro(index) && !item.needAssessment" class="zItemIcon"> <i class="ikon h3 quickSave" v-bind:class="{ 'med-adm-not-selected': !item.isHovered, 'med-adm-given': item.isHovered }"
                             @mouseover="IsHover(true,index)"
                                @mouseout="IsHover(false,index)" v-on:click="SaveAuto($event,item)"></i>
                            </div>
                            <div v-if="GivenNeutro(index) && item.needAssessment" class="zItemIcon"><i class="ikon med-adm-not-selected h3" v-b-tooltip.hover :title="$t('modalMarsMed.Neutral')"></i></div>
                        </b-col>
                        <b-col md="8">
                            <div class="zItemText">{{ item.drugName }} {{ item.qtySet }} {{ item.dispensingUOM }} <br><small class="ml-2">{{ item.adminDate }} <span class="PRNlabel" v-if="item.mode === 'PRN'" v-b-tooltip.hover title="Pro Re Nata (as needed)">PRN</span> <span v-else><span class="opAT">@</span> {{item.adminTime}}</span>  </small></div>
                        </b-col>
                        <b-col>
                            <div class="zItemIcon pull-right">
                                <i :class=" 
                                     getStatus(item.mode,index) === '4_NO_PEND_ADM' ? 'ikon med-adm-not-pending h3' 
                                    :getStatus(item.mode,index) === '3_PRIOR_TO_ADM' ? 'ikon med-adm-prior h3'
                                    :getStatus(item.mode,index) === '2_CLOSE_TO_ADM' ? 'ikon med-adm-close h3'
                                    : 'ikon med-adm-overdue h3'" v-b-tooltip.hover :title="getTitle(item.mode,index)">
                                </i>                        
                            </div>
                        </b-col>
                    </b-row>

                   
<!-- HAY UN TERCER ESTADO QUE ES EL QUE NO HA SELECCIONADO NI GIVEN, NI NO GIVEN. REVISA ESTO
<div v-if="!HaveOneGiven(index)" class="zItemIcon"><i class="ikon med-adm-not-selected h3" v-b-tooltip.hover :title="$t('modalMarsMed.NoGiven')"></i></div>
GivenNeutro
-->

                    
                   
                
                </div>
                <b-card no-body>
                  <!--<b-card-header header-tag="header" class="p-1" role="tab">                 
                   <b-button block v-b-toggle.accordion-1 variant="info">{{ item.drugName }}</b-button>
                  </b-card-header>-->
                  <b-collapse :id="'accordion-'+index" accordion="my-accordion" role="tabpanel">
                    <b-card-body class="accordionContent pt-3">

                    <!-- ALERT ZONE -->
                        <b-alert show class="alert alert-fill-warning" v-if="item.isHighAlert || item.isLookAlikeSoundAlike || item.allergicToDrug">
                            <h5 class="alert-heading mt-2">
                                <i class="mdi mdi-alert me-2" style="font-size: 1.5rem;"></i>
                                <span v-if="HasMoreThanOneAlert(index)">{{$t('modalMarsMed.Alerts')}}<span class="d-none d-sm-inline"> {{$t('modalMarsMed.FoundMed')}}</span>!</span>
                                <span v-else>{{$t('modalMarsMed.Alert')}}<span class="d-none d-sm-inline"> {{$t('modalMarsMed.FoundMed')}}</span>!</span>
                            </h5>
                            <hr>
                            <ul class="ml-2">
                                <li v-if="item.isHighAlert">{{$t('modalMarsMed.HighAlert')}}</li>
                                <li v-if="item.isLookAlikeSoundAlike">{{$t('modalMarsMed.LookAlikeSoundAlike')}} </li>
                                <li v-if="item.allergicToDrug">{{$t('modalMarsMed.allergicToDrug')}}</li>
                            </ul>
                        </b-alert>
                    <!-- END OF ALERT ZONE -->
                    <!-- MED INFORMATION ZONE -->
                   <!-- {{PhotoObj.allergies + PhotoObj.specialPrecautions + PhotoObj.marsAlerts}}-->

                 
                    <div class="card activeCard">			
                            <div class="container-mars mt-3">
                                <div class="card-container">
                                    <div class="card-body">
                                        <div class="row patPhoto-container">
                                            <div class="col-md-2 patPhoto">
                                               
                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="PhotoObj.allergies && !PhotoObj.specialPrecautions && !PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIconMed helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.ALLERGIC')}: `+PhotoObj.allergies" 
                                                    variant="primary">
                                                </i>

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="!PhotoObj.allergies && PhotoObj.specialPrecautions && !PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIconMed helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.SPECIALPR')}: `+PhotoObj.specialPrecautions" 
                                                    variant="primary">
                                                </i>

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="!PhotoObj.allergies && !PhotoObj.specialPrecautions && PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIconMed helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.MarsAlerts')}: `+PhotoObj.marsAlerts" 
                                                    variant="primary">
                                                </i>

                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="PhotoObj.allergies && PhotoObj.specialPrecautions && !PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIconMed helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.ALLERGIC')}: `+PhotoObj.allergies + ` / ` + $t('RigthBar.SPECIALPR') +': '+ PhotoObj.specialPrecautions" 
                                                    variant="primary">
                                                </i>
                                                
                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="PhotoObj.allergies && !PhotoObj.specialPrecautions && PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIconMed helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.ALLERGIC')}: `+PhotoObj.allergies + ` / ` + $t('RigthBar.MarsAlerts') +': '+ PhotoObj.marsAlerts" 
                                                    variant="primary">
                                                </i>


                                                <i 
                                                    id="rm-allergic" 
                                                    v-if="!PhotoObj.allergies && PhotoObj.specialPrecautions && PhotoObj.marsAlerts" 
                                                    class="mdi mdi-alert text-warning allergies relocateIconMed helpCursor" 
                                                    v-b-tooltip.hover="`${$t('RigthBar.SPECIALPR')}: `+PhotoObj.specialPrecautions + ` / ` + $t('RigthBar.MarsAlerts') +': '+ PhotoObj.marsAlerts" 
                                                    variant="primary">
                                                </i>
                                              
                                              

                                                <!--
                                                <img v-if="PhotoObj.patientPhoto" v-bind:src="'data:image/'+'.'+PhotoObj.patientPhotoExtension+';base64,'+PhotoObj.patientPhoto" alt="Foto" class="patPic img-fluid" style="height: 100%;">
                                                <img v-if="(PhotoObj.patientPhoto === null || PhotoObj.patientPhoto === '') && PhotoObj.sex === 'Male'" src="@/assets/images/faces/nopic-male.jpg" alt="Foto" class="photo img-fluid">
                                                <img v-if="(PhotoObj.patientPhoto === null || PhotoObj.patientPhoto === '') && PhotoObj.sex === 'Female'" src="@/assets/images/faces/nopic-female.jpg" alt="Foto" class="photo img-fluid">
                                                -->
                                                <img  src="@/assets/images/faces/nopic-meds.jpg" alt="Foto" class="photo img-fluid">
                                                
                                            
                                            </div>
                                            <div class="col-md-10 resetFontSize">
                                                <div class="row">
                                                    <div class="col-md-3 text-muted p-item" v-if="item.drugName">
                                                        <small>{{$t('modalMarsMed.DrugName')}}</small><br>                                                   
                                                        <b>{{ item.drugName }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.DrugName')}}</small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>

                                                    <div class="col-md-3 text-muted p-item" v-if="item.qtySet">
                                                        <small>{{$t('modalMarsMed.QuantityDispensingUOM')}}</small><br>
                                                        <b>{{ item.qtySet }} {{ item.dispensingUOM }}</b>
                                                    </div>

                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.QuantityDispensingUOM')}}</small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>

                                                    <div class="col-md-3 text-muted p-item" v-if="item.diagnosticName">
                                                        <small>{{$t('modalMarsMed.DiagnosticName')}}</small><br>
                                                        <b>{{ item.diagnosticName }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.DiagnosticName')}}</small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>

                                                    <div class="col-md-3 text-muted p-item" v-if="item.indicationName">
                                                        <small>{{$t('modalMarsMed.IndicationName')}}</small><br>
                                                        <b>{{ item.indicationName }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.IndicationName')}}</small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 text-muted p-item" v-if="item.sig">
                                                        <small>{{$t('modalMarsMed.Sig')}}</small><br>
                                                        <b>{{ item.sig }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.Sig')}}</small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>

                                                    
                                                    <div class="col-md-3 text-muted p-item" v-if="item.prescriberNotes">
                                                        <small>{{$t('modalMarsMed.PrescriberNotes')}}</small><br>
                                                        <b>{{ item.prescriberNotes }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.PrescriberNotes')}}</small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>   

                                                    <div class="col-md-3 text-muted p-item" v-if="item.isPatientOwnMedication">
                                                        <small>{{$t('modalMarsMed.PatientOwnMedication')}} </small><br>
                                                        <b>{{ item.isPatientOwnMedication }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.PatientOwnMedication')}} </small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>

                                                    <div class="col-md-3 text-muted p-item" v-if="item.orderByName">
                                                        <small>{{$t('modalMarsMed.OrderByName')}} </small><br>
                                                        <b>{{ item.orderByName }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.OrderByName')}} </small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>   
                                                    
                                                    <div class="col-md-3 text-muted p-item" v-if="item.dailyQTYSet">
                                                        <small>{{$t('modalMarsMed.DailyQTYSet')}} </small><br>
                                                        <b>{{ item.dailyQTYSet }}</b>
                                                    </div>
                                                    <div class="col-md-3 text-muted p-item" v-else>
                                                        <small>{{$t('modalMarsMed.DailyQTYSet')}} </small><br>
                                                        <b>{{$t('Shared.Notdefined')}}</b>
                                                    </div>  

                                                    <div class="col-md-3 text-muted p-item" v-if="item.quantityDailyAdm && item.mode === 'PRN'">
                                                        <small>{{$t('modalMarsMed.DailyUse')}} </small><br>
                                                        <b>{{ item.quantityDailyAdm }}</b>
                                                    </div>                                                   

                                                </div>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                            </div>    
                        </div>
                    <!-- END OF MED INFORMATION ZONE -->
                                    
             <!----------------------------------------------------------------------------------------------------------->
                           
             <div v-for="(itemchild,indexchild) in item.marsAdministrations" v-bind:key="indexchild" >            
                <b-button-group class="ml-3 groupActions whiteBtn" v-if="item.mode === 'PRN'">                    
                    <b-button  v-b-tooltip.hover
                    :disabled="isReadOnly || ViewReadOnly"
                    v-on:click="AddAdm(item)"
                    :title="$t('modalMarsMed.AddAdm')" size="sm" variant="light">
                    <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
                    </b-button>
                
                    <b-button  v-b-tooltip.hover
                    :disabled="isReadOnly || ViewReadOnly"
                    :title="$t('modalMarsMed.DelAdm')" size="sm" variant="light">
                    <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                    </b-button>
                </b-button-group>
             

                <b-card bg-variant="white">
                    <b-row v-if="item.needAssessment">  
                        <b-col md="12">
                            <div class="alert alert-fill-warning d-flex flex-column flex-md-row align-items-center m-2" role="alert">
                                <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                                <div class="flex-grow-1 d-flex flex-column align-items-center align-items-md-start text-center text-md-left">
                                <span v-if="item.needAssessment"> {{$t('modalMarsMed.Assessment') }}</span>
                                </div>
                                <b-dropdown id="dropdown-right" :text="$t('modalMarsMed.Assess')" variant="primary" class="mt-2 mt-md-0 ms-md-auto">
                                <b-dropdown-item 
                                    :disabled="isReadOnly"
                                    v-for="(assessmentItem, assessmentIndex) in itemchild.assessmentDetails"
                                    :key="assessmentItem.templateCode + assessmentIndex"
                                    v-bind:value="assessmentItem.templateCode"
                                    v-on:click="showModalOutCome(assessmentItem, item, itemchild, index, indexchild, assessmentIndex)">
                                    <div v-if="assessmentItem.metAssessment">
                                    <i class="ikon med-status-complete h3" v-b-tooltip.hover :title="$t('modalMarsMed.AssessmentCompleted')"></i> {{assessmentItem.templateDescription}}
                                    </div>
                                    <div v-else>
                                    <i class="ikon med-pen h3" v-b-tooltip.hover :title="$t('modalMarsMed.AssessmentNotCompleted')"></i> {{assessmentItem.templateDescription}}
                                    </div>
                                </b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </b-col>

                    </b-row>

                    <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="itemchild.expiredAdministration">
                        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                        <div class="flex-grow-1">
                          <span>{{ $t('modalMarsMed.ExpiredAdministration') }}</span>
                        </div>
                      </div>

                    <b-row>  
                    <b-col md="2" class=" align-self-center text-center">
                       <!-- <div class="zItemIcon"><i v-on:click="Give(item,itemchild)" class="ikon med-adm-given h3" v-b-tooltip.hover :title="$t('modalMarsMed.Given')"></i></div>
                        <div class="zItemIcon"><i v-on:click="NoGive(item,itemchild)" class="ikon med-adm-not-given h3 mx-3" v-b-tooltip.hover :title="$t('modalMarsMed.NoGiven')"></i></div>
                    -->
                    <b-button-toolbar class="mt-2">
                        <b-button-group class="mr-1 giveMeds">

                        
                            <b-button :disabled="isReadOnly || itemchild.expiredAdministration || ViewReadOnly || !VerifyAllAssessment(item,itemchild.assessmentDetails)" v-if="itemchild.given === null || itemchild.given" v-on:click="Give(item,itemchild,index,indexchild)" class="greenMed" v-b-tooltip.hover :title="$t('modalMarsMed.Given')">
                                <i class="ikon med-adm-given"></i>
                            </b-button>

                            <b-button :disabled="isReadOnly || itemchild.expiredAdministration || ViewReadOnly || !VerifyAllAssessment(item,itemchild.assessmentDetails)" v-if="itemchild.given !== null && itemchild.given"  v-on:click="NoGiveUndo(item,itemchild,index,indexchild)" class="redMed undoMedRight" v-b-tooltip.hover :title="$t('modalMarsMed.UndoGive')">
                                <i class="ikon med-adm-undo"></i>
                            </b-button>






                            <b-button :disabled="isReadOnly || itemchild.expiredAdministration || ViewReadOnly || !VerifyAllAssessment(item,itemchild.assessmentDetails)" v-if="itemchild.given !== null && !itemchild.given" v-on:click="GiveUndo(item,itemchild,index,indexchild)" class="greenMed undoMedLeft" v-b-tooltip.hover :title="$t('modalMarsMed.UndoNoGive')">
                                <i class="ikon med-adm-undo"></i>
                            </b-button>                          

                            <b-button :disabled="isReadOnly || itemchild.expiredAdministration || ViewReadOnly || !VerifyAllAssessment(item,itemchild.assessmentDetails)" v-if="itemchild.given === null || !itemchild.given" v-on:click="NoGive(item,itemchild,index,indexchild)" class="redMed" v-b-tooltip.hover :title="$t('modalMarsMed.NoGiven')">
                                <i class="ikon med-adm-not-given"></i>
                            </b-button>

                           

                           <!-- 

                             <b-button v-if="itemchild.VisibleUndoGive" v-on:click="GiveUndo(item,itemchild)" class="greenMed undoMedLeft" v-b-tooltip.hover :title="'22222'">
                                <i class="ikon med-adm-undo"></i>
                            </b-button>

                            <b-button v-if="itemchild.VisibleUndoNoGive" v-on:click="NoGiveUndo(item,itemchild)" class="redMed undoMedRight" v-b-tooltip.hover :title="'1111'">
                                <i class="ikon med-adm-undo"></i>
                            </b-button>-->
                        
<!-- ESTOS SON LOS CAMBIOS PARA QUE EL BOTÓN LUZCA SECUNDARIO. BÁSICAMENTE ES AÑADIR LA CLASE UNDO EN EL BOTÓN Y EL ÍCONO

    <b-button v-on:click="Give(item,itemchild)" class="greenMed undoMedLeft" v-b-tooltip.hover :title="$t('modalMarsMed.Given')">
        <i class="ikon med-adm-undo"></i>
    </b-button>
    <b-button v-on:click="Give(item,itemchild)" class="redMed undoMedRight" v-b-tooltip.hover :title="$t('modalMarsMed.Given')">
        <i class="ikon med-adm-undo"></i>
    </b-button>

-->
                        </b-button-group> 
                   </b-button-toolbar>

                    </b-col>

                    <!--<b-col md="4">
                        <MdEdit 
                            v-bind:Label="$t('modalMarsMed.GivenDateandTime')"
                            v-bind:TypeProp="'text'" 
                            v-bind:Name="'GivenDateandTime'"
                            v-model="itemchild.givenDateTime"                                       
                            v-bind:Mask="'##/##/#### ##:## AA'"
                            v-bind:Min="'1900-01-01'"
                            v-bind:Max="'2200-12-31'"  
                            v-bind:isReadOnly="(item.needAssessment && !VerifyAllAssessment(itemchild.assessmentDetails)) || isReadOnly || itemchild.expiredAdministration || ViewReadOnly"
                            v-bind:Id="'input_Given_id'+indexchild">                                     
                        </MdEdit>
                    </b-col>-->

                    <b-col md="4">
                        <MdEdit 
                            v-bind:Label="$t('modalMarsMed.GivenTime')"
                            v-bind:TypeProp="'time'"
                            v-bind:IdButton="'btn_save_marsadm'+indexchild" 
                            v-bind:Name="'time'"
                            v-bind:isReadOnly="(item.needAssessment && !VerifyAllAssessment(item,itemchild.assessmentDetails)) || isReadOnly || itemchild.expiredAdministration || ViewReadOnly"
                            v-bind:Id="'input_Given_id'+indexchild"
                            v-model="itemchild.givenTime">                                     
                        </MdEdit>
                    </b-col>



                    <b-col md="3" v-show="itemchild.given === null || itemchild.given === true">
                        <MdEdit 
                            v-bind:Label="$t('modalMarsMed.GivenQuantity') +' ('+ item.dispensingUOM + ')'"
                            v-bind:TypeProp="'number'"                                   
                            v-model="itemchild.givenQTY" 
                            v-bind:Min="'0'"
                            v-bind:IdButton="'btn_save_marsadm'+indexchild"
                            v-bind:Max="'9999'"  
                            v-bind:isReadOnly="(item.needAssessment && !VerifyAllAssessment(item,itemchild.assessmentDetails)) || isReadOnly || itemchild.expiredAdministration || ViewReadOnly"
                            v-bind:Id="'input_givenQTY_id'+indexchild">                                     
                        </MdEdit>
                    </b-col>

                   

                    <b-col md="3" v-show="itemchild.given === false">
                        <MdSelect
                            v-bind:Label="$t('modalMarsMed.AdministrationStatus')"
                            data-field="therapistcode"
                            v-bind:Options="ReasonList"
                            v-bind:IdButton="'btn_save_marsadm'+indexchild"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'code'"
                            :IsDisabled="(item.needAssessment && !VerifyAllAssessment(item,itemchild.assessmentDetails)) || (itemchild.given) || isReadOnly || itemchild.expiredAdministration || ViewReadOnly"
                            v-bind:Model="itemchild.reason" 
                            v-bind:UseParamsAux="true"
                            v-bind:UseResetModel="true"
                            v-bind:AuxParam="itemchild"                                 
                            :Input="ChangeDropDownReason"
                        />

                    </b-col>    
                   
               
                </b-row>
                <b-row>
                    <b-col md="12">
                        <div :data-label="$t('modalMarsMed.AdministrationNotes')" class="textlabel"></div>
                        <MdMemo         
                            v-bind:TypeProp="'text'" 
                            v-bind:Name="'notes'"
                            v-bind:IdButton="'btn_save_marsadm'+indexchild"
                            v-bind:Rows="'3'"
                             v-bind:isReadOnly="(item.needAssessment && !VerifyAllAssessment(item,itemchild.assessmentDetails)) || isReadOnly || itemchild.expiredAdministration || ViewReadOnly"
                            v-bind:MaxRows="'6'" 
                            v-model="itemchild.administrationNotes"
                            v-bind:Tag="'administrationNotes'">                                     
                        </MdMemo>
                    </b-col>
                </b-row>
                
                <b-row>
                    <!--<b-col md="4">
                        <div :data-label="$t('modalMarsMed.ReviewedByName')" class="textlabel"><span class="ml-3" v-if="itemchild.reviewedByName && itemchild.reviewedByName.trim()"> {{ itemchild.reviewedByName }} </span> <b class="text-muted p-item ml-3" v-else>{{$t('Shared.Notdefined')}}</b></div>
                    </b-col>
                    <b-col md="4">
                        <div :data-label="$t('modalMarsMed.ReviewedOn')" class="textlabel"><span class="ml-3" v-if="itemchild.reviewedOn">{{ itemchild.reviewedOn }}</span> <b class="text-muted p-item ml-3" v-else>{{$t('Shared.Notdefined')}}</b></div>
                    </b-col>-->
                    <b-col md="12" class="mt-3 text-right">
<!-- ESTE BOTÓN DEBERÍA DECIR: SAVE ITEM sino SAVE CHANGES, PERO NO DEBE DECIR SÓLO: SAVE -->
                        <MdButton
                        v-on:click="SaveAdministration(item,itemchild,'btn_save_marsadm'+indexchild)"
                        v-bind:isReadOnly="(item.needAssessment && !VerifyAllAssessment(item,itemchild.assessmentDetails)) || isReadOnly || itemchild.expiredAdministration || ViewReadOnly || itemchild.given === null"
                        v-bind:Label="$t('modalMarsMed.SaveAdministration')"                      
                        v-bind:Name="'btn_save_marsadm'+indexchild"
                        v-bind:ClassProp="'secondary mx-2 btn-login'" 
                    />
                    </b-col>
                </b-row>
                    <br v-if="(itemchild.reviewedByName && itemchild.reviewedByName.trim() !== '') || (itemchild.reviewedOn && itemchild.reviewedOn.trim() !== '')">
                    <div class="mt-2" v-if="(itemchild.reviewedByName && itemchild.reviewedByName.trim() !== '') || (itemchild.reviewedOn && itemchild.reviewedOn.trim() !== '')">
                        <div class="d-sm-flex justify-content-center justify-content-sm-between">
                            <span class="text-muted text-center italic text-sm-left d-block d-sm-inline-block">
                                <span>{{$t('modalMarsMed.ReviewedByName')}}:</span> <b>{{itemchild.reviewedByName}}</b>
                                /// <span>{{$t('modalMarsMed.ReviewedOn')}}:</span> <b>{{itemchild.reviewedOn}}</b>
                            </span>                       
                        </div>
                    </div>
                </b-card>
            </div>

            <!------------------------------------------------------------------------------------------------------------->




                      
                    </b-card-body>
                  </b-collapse>
                </b-card>         
              </div>
          </div>
        </div>    
                       
          <div class="row mt-4">
            <div class="my-1 col-sm-7 col-md-6">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                @change="onChange"
                pills
                size="lg"
              ></b-pagination>
            </div>
            <div align="right" class="mr-2 col">
              {{ $t('Shared.Show') }}
              <b-form-select 
                v-model="perPage" 
                :options="options" 
                size="sm" 
                id="perPageSelect"
                v-bind:style="perPageStyle"
                @change="perPageChange">
              </b-form-select>
              {{ $t('Shared.Of') }} {{totalRows}}
            </div>
          </div>

          <MdPasswordDlg v-if="showSignPassword" 
                v-bind:Type="'Sign'"
                v-bind:Obj1="Obj1"
                v-bind:Obj2="Obj2"
                v-bind:EmployeeCode="this.$store.state.employeeCode"
                @getModalPassword="onModalPasswordResult($event)">
            </MdPasswordDlg>


         <MdPainAssessmentNotesOutCome v-if="activeModalPainAssessment"
            v-bind:ModalId="PainAssessmentId"
            v-bind:TitleWindow="outComeTitle"
            v-bind:NotOpenByDefault="false"
            v-bind:MarsParam="MarsParam"
            v-bind:outComeCode="outComeCode"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdPainAssessmentNotesOutCome>

        <MdPatientVitalSingOutCome v-if="activeModalVitalSign"
            v-bind:ModalId="PatientVitalId"
            v-bind:PatientCode="this.PatientCode"
            v-bind:TitleWindow="outComeTitle"
            v-bind:outComeCode="outComeCode"
            v-bind:MarsParam="MarsParam"
            v-bind:ForUsefromMars="true"
            v-bind:ComponentKind="'Memo'"
            v-bind:NotOpenByDefault="false"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdPatientVitalSingOutCome>


        <MdPatientDiagCodes v-if="activateModalDiagCodes"
            v-bind:ModalId="DiagCodeId"          
            v-bind:TitleWindow="outComeTitle"
            v-bind:MarsParam="MarsParam"
            v-bind:PatientCode="this.PatientCode"
            v-bind:RecordId="this.AdmRecordId"
            v-bind:ForUsefromMars="true"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdPatientDiagCodes>

        <MdGenericOutCome v-if="activateModalGeneric"
            v-bind:ModalId="GenericId"
            v-bind:TitleWindow="outComeTitle"
            v-bind:NotOpenByDefault="false"
            v-bind:ForUsefromMars="true"
            v-bind:MarsParam="MarsParam"
            v-bind:outComeCode="outComeCode"
            v-bind:outComeDescription="outComeDescription"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdGenericOutCome>

        <MdPatientInsurances v-if="activateModalInsurance"
            v-bind:ModalId="InsuranceId"
            v-bind:NotOpenByDefault="false"
            v-bind:ForUsefromMars="true"
            v-bind:MarsParam="MarsParam"
            v-bind:outComeCode="outComeCode"
            v-bind:PatientCode="this.PatientCode"
            v-bind:TitleWindow="outComeTitle"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdPatientInsurances>

        <MdPatientAllergiesOutCome v-if="activateModalAllergies"
            v-bind:ModalId="AllergyId"
            v-bind:PatientCode="this.PatientCode"
            v-bind:NotOpenByDefault="false"
            v-bind:ForUsefromMars="true"
            v-bind:outComeCode="outComeCode"
            v-bind:MarsParam="MarsParam"
            v-bind:TitleWindow="outComeTitle"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdPatientAllergiesOutCome>

        <MdPatientContacts v-if="activateModalContacts"
            v-bind:ModalId="ContactId"
            v-bind:PatientCode="this.PatientCode"
            v-bind:NotOpenByDefault="false"
            v-bind:ForUsefromMars="true"
            v-bind:outComeCode="outComeCode"
            v-bind:MarsParam="MarsParam"
            v-bind:TitleWindow="outComeTitle"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdPatientContacts>

        <MdPainAssessmentModalListing v-if="activateModalPainAssessmentList"
            v-bind:ModalId="AssessmentModalId"
            v-bind:RecordId="RecordId"
            v-bind:PatientCode="this.PatientCode"
            v-bind:NotOpenByDefault="false"
            v-bind:outComeCode="outComeCode"
            v-bind:ForUsefromMars="true"
            v-bind:MarsParam="MarsParam"
            v-bind:TitleWindow="outComeTitle"
            @getCodeConditionOK="onCodeConditionOk($event)"
            @getCancel="onCancel($event)">
        </MdPainAssessmentModalListing>
       
     </b-modal>  
    </div>
</template>
 
 <script>
import Utils from '@/common/utils'
import ShowMessage from '@/components/messages/ShowMessage.js'
import MarsService from '@/services/mars.service'
import Loading from '@/components/template/loading/Loading'
import Companies from '@/services/companies.service'
import EmployeeService from '@/services/employee.service'
import GlobalService from '@/services/global.service'
import PharmacyOptions from '@/services/pharmacyoption.service'
import { EventBus } from '@/event-bus.js'; 
import ReasonService from '@/services/reason.service'
//import MdPainAssessmentNotesOutCome from '@/components/NotesComponents/OutComeStudies/MdPainAssessmentNotesOutCome'
let elemSaveBtn = '';
 export default {  
   name: 'MarsMedicationModal',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     PatientCode:String,
     DateFrom:String,
     DateTo:String,  
     PhotoObj:Object,  
   },
   components: {
      Loading/*,MdPainAssessmentNotesOutCome*/
    },
   
   data(){
     return {
        IsValidDateandTimeFrom:true,
        IsValidDateandTimeTo:true,
        btnIdClicked:"",
        pendingChanges:false,      
        showSignPassword:false, 
        dailyUse:1,
        AdmRecordId:null,   
        RecordId:null,
        PharmacyOption:{},
        ReasonList:[],
        activeModal:false,
        outComeTitle:"Assessment Notes OutCome",
        outComeDescription:"",
        outComeCode:"",
        AssessmentModalId:"MarsMedicationModalPainAssessmentList",
        PainAssessmentId:"MarsMedicationModalPainAssessment",
        InsuranceId:"MarsMedicationModalInsurance",
        PatientVitalId:"MarsMedicationModalPatientVital",
        GenericId:"MarsMedicationModalGeneric",
        DiagCodeId:"MarsMedicationModalDiagCodes",
        AllergyId:"MarsMedicationModalAllergy",
        ContactId:"MarsMedicationModalContact",
        activeModalPainAssessment:false,
        activeModalVitalSign:false,
        activateModalGeneric:false,
        activateModalDiagCodes:false,
        activateModalInsurance:false,
        activateModalAllergies:false,
        activateModalContacts:false,
        activateModalPainAssessmentList:false,

        MarsParam:{
            indexParent:null,
            indexAdministracion:null,
            indexAssessment:null,
        },
        
        // outComeTemplateCode:this.$attrs.control.outComeTemplateCode,
        //outComeDescription: Utils.strToTitleCase(this.$attrs.control.outComeDescription),
        // outComeTitle: Utils.strToTitleCase(this.$attrs.control.outComeDescription),
        // outComeCode:this.$attrs.control.outComeCode,

        Obj1:{},
        Obj2:{},
       InsertStatus:false,
       DateTimeNow:null,     
       isReadOnly:false,
       UsePrn:true,
       ViewReadOnly:false,
       TypeList:['N','T','M'],
       MedList:[],
       MedListCopy:[],
       disableAddAllergies: false,
       Load:false,
       perPage: 10,
       countAlerts:0,      
       totalRows: 0, 
       currentPage: 1,   
       showEmptyRecords:false,
       DateFromModal:this.DateFrom,
       DateToModal:this.DateTo,
       unitSys: 'U',
       parameter:{  
        DateTimeFrom:null,
        DateTimeTo:null,       
        PatientList:[],
        LevelList:[],
        BuildingList:[],
        RoomList:[],
        ProgramList:[],
        SearchCriteria:null,
        PageSize:10,
        PageNumber:1
      },
      perPageStyle: {
        width: 'auto'
      },
      options: [
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 15, text: '15' },
      ],
      mode:{code:"",name:"ALL"},
      ModeList:[{code:"",name:"ALL"},{code:"TIMED",name:"TIMED"},{code:"PRN",name:"PRN"},{code:"STAT",name:"STAT"}],
      emptyRecordsStyle: {
            backgroundColor: '#CF0',
            fontWeight: 'bold !important',
            textTransform: 'uppercase',
            padding: '2px',
            borderRadius: '50px',
            animation: 'fadeOut 10s ease-in-out infinite'
        },
     }
   },
 
   methods:{  

    validate_required(){
        this.IsValidDateandTimeFrom = this.DateFromModal ? true : false;
        this.IsValidDateandTimeTo = this.DateToModal ? true : false;        
        return this.IsValidDateandTimeFrom  && this.IsValidDateandTimeTo; 
    },

    IsHover(over,index){
        if(over){
            this.MedList = [];
            this.MedListCopy[index].isHovered = true;   
            this.MedList = [...this.MedListCopy];             
        }else{
            this.MedList = [];
            this.MedListCopy[index].isHovered = false;   
            this.MedList = [...this.MedListCopy];    
        }
    },
   
    async Give(item,itemchild,index,indexChild){ 
        if(item.needAssessment){
            if(this.VerifyAllAssessment(item,itemchild.assessmentDetails)){               
                await this.getDateTime();   
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = true;               
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = this.DateTimeNow; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenQTY = item.mode !== 'PRN' ? item.qtySet : null;
                this.MedList = [...this.MedListCopy];
            }else{
                this.showMessage(this.$t('modalMarsMed.MarsMed'),this.$t('modalMarsMed.NeedAssessment'),"error");
            }
        }else{
        
                await this.getDateTime();   
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = true; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = this.DateTimeNow; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenQTY = item.mode !== 'PRN' ? item.qtySet : null;
                this.MedList = [...this.MedListCopy];
        }     
     
     },

    async NoGive(item,itemchild,index,indexChild){       
        if(item.needAssessment){
            if(this.VerifyAllAssessment(item,itemchild.assessmentDetails)){              
                await this.getDateTime(itemchild); 
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = false; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = this.DateTimeNow; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenQTY = null; 
                this.MedList = [...this.MedListCopy];
            }else{
                this.showMessage(this.$t('modalMarsMed.MarsMed'),this.$t('modalMarsMed.NeedAssessment'),"error");
            }
        }else{           
                await this.getDateTime(itemchild); 
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = false; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = this.DateTimeNow; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenQTY = null; 
                this.MedList = [...this.MedListCopy];

        } 
        
        
       
     },

     GiveUndo(item,itemchild,index,indexChild){     
       if(item.needAssessment){
            if(this.VerifyAllAssessment(item,itemchild.assessmentDetails)){            
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reason = {code:"",name:""};             
                this.MedList = [...this.MedListCopy];

            }else{
                this.showMessage(this.$t('modalMarsMed.MarsMed'),this.$t('modalMarsMed.NeedAssessment'),"error");
            }
        }else{           
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reason = {code:"",name:""};                
                this.MedList = [...this.MedListCopy];      
        } 
      
               /* this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenDateTime = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reason = {code:"",name:""};             
                this.MedList = [...this.MedListCopy];*/
     },

     NoGiveUndo(item,itemchild,index,indexChild){
        if(item.needAssessment){
            if(this.VerifyAllAssessment(item,itemchild.assessmentDetails)){             
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenQTY = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reason = {code:"",name:""};                
                this.MedList = [...this.MedListCopy];   
            }else{
                this.showMessage(this.$t('modalMarsMed.MarsMed'),this.$t('modalMarsMed.NeedAssessment'),"error");
            }
        }else{          
                this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenTime = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenQTY = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reason = {code:"",name:""};                
                this.MedList = [...this.MedListCopy];   
        }  

        /*this.MedList = [];
                this.MedListCopy[index].marsAdministrations[indexChild].given = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenDateTime = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reasonCode = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].givenQTY = null; 
                this.MedListCopy[index].marsAdministrations[indexChild].reason = {code:"",name:""};                
                this.MedList = [...this.MedListCopy]; */
      
     },

     getListing(){
        this.GetMedications();
     },

     ChangeDropDownMode(item){
        this.mode = item ? item : {code:"",name:"ALL"};
        this.GetMedications();
     },

     AddAdm(item){       
        item.marsAdministrations.push(
        {  
            administrationNotes:"",
            administrationStatus: "",
            administrationStatusTranslated:"",
            expiredAdministration:false,
            given:null,
            givenDate:item.adminDate,
            givenQTY:null,
            assessmentDetails:item.assessmentTypes,
            givenTime:null,
            marsRecNo:null,
            metAssessment:false,
            reasonCode:null,
            reasonDescription:null,
            reviewedByName:"",
            reviewedOn:null,
            signed:false,
        }
        )
    },

     PopulateUndoAdm(){
                           
        for(var i = 0; i < this.MedList.length; i++){
            let arr = this.MedList[i].marsAdministrations;
            let total = 0;
            arr.forEach(function(element) {         
              // let gDate = element.givenDate && element.givenDate !== '1899-12-30T00:00:00' ? Utils.formatterDateToString(element.givenDate) : null;
              // let gTime = element.givenTime ? Utils.formatTime(element.givenTime) : null;
              // let gDateTime = gDate + " "+gTime;
              // element.givenDateTime = gDate && gDateTime ? Utils.formatterDateTimeToString(gDateTime) : null;
               element.givenTime = element.givenTime ? Utils.formatTimeHm(element.givenTime) : null;
               element.reviewedOn = element.reviewedOn ? Utils.formatterDateTimeToString(element.reviewedOn) : null;               
               element.reason = {code:element.reasonCode,name:element.reasonDescription};
               let quantity = element.givenQTY === null ? 0 : element.givenQTY;
               total+=quantity;
            })

            this.MedList[i].quantityDailyAdm = total;
        }   
        this.MedListCopy = [...this.MedList];
         
     },

   

     HaveOneGiven(index){
        let count = 0; 
            let arr = this.MedList[index].marsAdministrations;
            for(var i = 0; i < arr.length; i++){
                if(arr[i].given !== null && arr[i].given){
                    count++;
                }
            }        
       return count > 0;
    },

    HaveOneNoGiven(index){
        let count = 0; 
            let arr = this.MedList[index].marsAdministrations;
            for(var i = 0; i < arr.length; i++){
                if(arr[i].given !== null && !arr[i].given){
                    count++;
                }
            }        
       return count > 0;
    },

    GivenNeutro(index){
        let count = 0; 
            let arr = this.MedList[index].marsAdministrations;
            for(var i = 0; i < arr.length; i++){
                if(arr[i].given === null){
                    count++;
                }
            }        
       return count === arr.length;
    },

    

    DoAssess(/*item,itemchild,assessmentItem,*/index, indexChild,assessmentIndex){            
        this.MedList = [];
        this.MedListCopy[index].marsAdministrations[indexChild].assessmentDetails[assessmentIndex].metAssessment = true; 
        this.MedList = [...this.MedListCopy];
       // let allCompleted = this.VerifyAllAssessment(itemchild.assessmentDetails);       
       // item.needAssessment = allCompleted ? false : true;  
      // item.needAssessment = false;        
     },

    onCodeConditionOk(result){        

        let info = result.info;
        if(info !== null || info !== ""){
            let admin = result.MarsParam;
            this.MedList = [];               
            this.MedListCopy[admin.indexParent].marsAdministrations[admin.indexAdministracion].administrationNotes += info+"\n";                
            this.MedList = [...this.MedListCopy];  
            
            this.DoAssess(/*item,itemchild,assessmentItem,*/admin.indexParent,admin.indexAdministracion,admin.indexAssessment);  
        }      
       
       this.deActivateModal()
    },

     SearchMed(){
        this.GetMedications();
     },
       

     OnChangeGoals(){

     },

     VerifyAllAssessment(item,arr){
        let count = 0; 
        if(item.needAssessment){
            arr.forEach(function(element) {
            if(element.metAssessment){
                count++;
                }
            })   
            return count === arr.length;
        }  
        return true;
           
        
     },





     showModalOutCome(assessmentItem,item,itemchild,index,indexchild,assessmentIndex){       
        this.MarsParam.indexParent = index;           
        this.MarsParam.indexAdministracion = indexchild; 
        this.MarsParam.indexAssessment =  assessmentIndex;        
        if(assessmentItem.templateCode === 'TFRMMdPainAssessmentNotes'){  
            this.outComeCode = assessmentItem.testCode;  
            this.RecordId =  item.recordId;
            this.activateModalPainAssessmentList = true; 
        }
        else if(assessmentItem.templateCode === 'TFRMMdVitalSignsNotes'){ 
            this.outComeCode = assessmentItem.testCode;  
            this.activeModalVitalSign = true;
        }
        else if(assessmentItem.templateCode === 'TfmMdNoteOutComeStudies'){ 
            this.outComeCode = assessmentItem.testCode; 
            this.activateModalGeneric = true;
        }   
        else if(assessmentItem.templateCode === 'TFRMPatientDiagCodes'){   
            this.outComeCode = assessmentItem.testCode;          
            this.AdmRecordId = item.admissionRecordId;
            this.activateModalDiagCodes = true; 
        }
        else if(assessmentItem.templateCode === 'TFRMPatientInsurances'){
            this.outComeCode = assessmentItem.testCode; 
            this.activateModalInsurance = true;           
        }
        else if(assessmentItem.templateCode === 'TFRMALLERGIESEHR'){   
            this.outComeCode = assessmentItem.testCode;          
            this.activateModalAllergies = true;            
        }
        else if(assessmentItem.templateCode === 'TFRMMDPATIENTCONTACTS'){
            this.outComeCode = assessmentItem.testCode; 
            this.activateModalContacts = true;
        }
           
             
       
    },

    deActivateModal(/*item*/){
       /* if(item.code === 'TFRMMdPainAssessmentNotes'){
            this.activeModalPainAssessment = false;
        }*/

        this.activeModalPainAssessment = false;
        this.activeModalVitalSign = false;
        this.activateModalGeneric = false;
        this.activateModalDiagCodes = false;
        this.activateModalInsurance = false;
        this.activateModalAllergies = false;
        this.activateModalContacts = false;
        this.activateModalPainAssessmentList = false;
        },

    

     ChangeDropDownReason(value,itemchild){      
        itemchild.reasonCode = value ? value.code : null;
     },

     onChange(page) {      
      this.currentPage = page;   
      this.GetMedications();  
    },

    getStatus(mode,index){
        let status = "";
        if(mode === 'PRN'){
            status = "4_NO_PEND_ADM";
        }else{
            status = this.MedList[index].marsAdministrations[0].administrationStatus;
        }
        return status;
    },

    HasMoreThanOneAlert(index){
        let count = 0;
        if(this.MedList[index].isHighAlert){
            count++;
        }
        if(this.MedList[index].isLookAlikeSoundAlike){
            count++;
        }
        if(this.MedList[index].allergicToDrug){
            count++;
        }
        return count > 1;
    },

    getTitle(mode,index){
        let statusTranslated = "";
        if(mode === 'PRN'){           
            statusTranslated = this.$t('modalMarsMed.NoPendAdm');
        }else{
            statusTranslated = this.MedList[index].marsAdministrations[0].administrationStatusTranslated;
        }
        return statusTranslated;
    },

    perPageChange(value) {
      this.perPage = value;  
      this.currentPage = 1;   
      this.GetMedications();
    },

    async getDateTime() { 
         await GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {                                  
                    this.DateTimeNow = Utils.formatTimeHm(response.data);                     
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
            }
         }); 
      },


      getReason() { 
        ReasonService.getReasonMars()
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.ReasonList = response.data;             
            }
          })
          .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('ModalCancelAppointmentReason.AReason'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('ModalCancelAppointmentReason.AReason'),error,"error");
            }
          });
      },

    async EmployeeCanChange(){
        this.Load = true;
            var parameter = { 
                TypeList:this.TypeList,              
                Code:this.$store.state.employeeCode,
                Active:true
            };
           await EmployeeService.EmployeeCanChangesinView(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {                                    
                    this.ViewReadOnly = response.data.length === 0 ? true :false;                                                       
                }              
             }).catch(error => { 
                if (!error.response) {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), error.response.data.errorMessage, "error");
                }
            });
    },

    onModalPasswordResult(result){     
        if(result.type === 'Sign'){
          if(result.status !== true) {           
            this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");          
          }else{         
            let item = result.Obj1;
            let itemchild = result.Obj2;
            this.$store.commit('setWrotePasswordMars',true);           
            if(itemchild.recNo){            
                this.UpdateAdministration(item,itemchild);              
            }else{
                if(item.mode === 'PRN'){
                    this.InsertAdministration(item,itemchild);
                }else{
                    this.UpdateAdministration(item,itemchild);
                } 
            }
          }
        }

        this.showSignPassword = false;   
    },

    SaveAdministration(item,itemchild,idBtn){
      //  elemSaveBtn.setAttribute('disabled','');
      this.btnIdClicked = idBtn;
      elemSaveBtn = document.getElementById(idBtn);
      elemSaveBtn.setAttribute('disabled','');
        this.Obj1 = item;
        this.Obj2 = itemchild;      
        if(this.PharmacyOption.byPassMarsSignature){
            if(itemchild.recNo){
                this.UpdateAdministration(item,itemchild);
            }else{
                if(item.mode === 'PRN'){
                    this.InsertAdministration(item,itemchild);
                }else{                   
                    this.UpdateAdministration(item,itemchild);
                } 
            }
        }else{  
            if(this.$store.state.alreadyWrotePassword){
                if(itemchild.recNo){
                    this.UpdateAdministration(item,itemchild);
                }else{
                if(item.mode === 'PRN'){
                    this.InsertAdministration(item,itemchild);
                }else{                   
                    this.UpdateAdministration(item,itemchild);
                } 
            }
            }else{
                this.showSignPassword = true;
            }          
            
        }
       
    },

    getTestCodesCompleted(item,itemchild){
        let result = [];
        if(item.needAssessment){
            let arr = itemchild.assessmentDetails;           
            for(var i = 0; i < arr.length; i++){
                if(arr[i].metAssessment){
                    result.push(arr[i].testCode);
                }
            }
            return result;
        }

        return result;
       
    },



    UpdateAdministration(item,itemchild){
        this.Load = true;         
        let gtime = itemchild.givenTime ? Utils.getDefaultDate()+itemchild.givenTime:null;       
        let param = {
            RecordId:item.recordId,
            Given:itemchild.given,
            GivenQTY:itemchild.givenQTY,
            GivenTime: gtime,
            TestCodes:this.getTestCodesCompleted(item,itemchild),
           // GivenDateTime: itemchild.givenDateTime ? Utils.formatterToISO(itemchild.givenDateTime, Utils.getDateTimeFormat()) : null,
            ReasonCode:itemchild.reasonCode,
            AdministrationNotes:itemchild.administrationNotes,
            Signed:true, //itemchild.signed
            MetAssessment:true // itemchild.
        }        
      
     if(this.PharmacyOption.byPassMarsSignature){
            param.Signed = true;
        }
        MarsService.update(itemchild.recNo, param)
        .then((response) => {             
                if(response.status == '200'){
                    this.pendingChanges = false;
                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })                               
                }
                this.Load = false;
            })
        .catch((error) => {
            this.Load = false;
            elemSaveBtn.removeAttribute("disabled");
            if (!error.response) {
               this.showMessage(this.$t('modalMarsMed.Administration'),this.$t('Msg.NotAbleToInsert'),"error");
            }                      
            else if(error.response.status === 400 ){
               this.showMessage(this.$t('modalMarsMed.Administration'),error.response.data.errorMessage,"error");
            } else if(error.response.status === 500){
                this.showMessage(this.$t('modalMarsMed.Administration'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
                this.showMessage(this.$t('modalMarsMed.Administration'),error.response.data.errorMessage,"error");
            }           
        });   
    },

    async SaveAuto(e,item){ 
       if(item.mode === 'PRN'){        
        if(!item.needAssessment){
            e.stopPropagation(); 
            await this.getDateTime(); 
            item.marsAdministrations[item.marsAdministrations.length-1].given = true;
            item.marsAdministrations[item.marsAdministrations.length-1].givenTime = this.DateTimeNow;
            item.marsAdministrations[item.marsAdministrations.length-1].givenQTY = 1;
            await this.InsertAdministration(item,item.marsAdministrations[item.marsAdministrations.length-1]);  
            if(this.InsertStatus){               
                this.AddAdm(item);
                this.InsertStatus = false;
            }         
        }else{
           if(this.VerifyAllAssessment(item,item.marsAdministrations[item.marsAdministrations.length-1].assessmentDetails)){
            e.stopPropagation(); 
            item.marsAdministrations[item.marsAdministrations.length-1].given = true;
            item.marsAdministrations[item.marsAdministrations.length-1].givenTime = this.DateTimeNow;
            item.marsAdministrations[item.marsAdministrations.length-1].givenQTY = 1;
            await this.getDateTime(); 
             await this.InsertAdministration(item,item.marsAdministrations[item.marsAdministrations.length-1]); 
             if(this.InsertStatus){
                this.AddAdm(item);
                this.InsertStatus = false;
            }   
            }else{
                this.$bvToast.toast(this.$t('modalMarsMed.RequiredAssessment'), {
                    title: this.$t('modalMarsMed.Administration'),
                    autoHideDelay: 5000,
                    variant: "warning"
                });
            }         
        }
       
           
       }else{       
        e.stopPropagation(); 
        await this.getDateTime(); 
        item.marsAdministrations[item.marsAdministrations.length-1].given = true;
        item.marsAdministrations[item.marsAdministrations.length-1].givenTime = this.DateTimeNow;
        item.marsAdministrations[item.marsAdministrations.length-1].givenQTY = 1;
        await this.UpdateAdministration(item,item.marsAdministrations[0]);
       }
    },

    async InsertAdministration(item,itemchild){
        this.Load = true;            
        let gtime = itemchild.givenTime ? Utils.getDefaultDate()+itemchild.givenTime:null;       
        let param = {
            AdminDate:item.adminDate ? Utils.formatterToISO(item.adminDate, Utils.getDateTimeFormat()) : null,
            RecordId:item.recordId,
            AdmissionRecordId:item.admissionRecordId,
            Given:itemchild.given,
            GivenQTY:itemchild.givenQTY,          
            GivenTime:gtime,
            ReasonCode:itemchild.reasonCode,
            AdministrationNotes:itemchild.administrationNotes,
            NeedAssessment:item.needAssessment,
            TestCodes:this.getTestCodesCompleted(item,itemchild),
            Signed:true, //itemchild.signed
            MetAssessment:true // itemchild.
        }

        if(this.PharmacyOption.byPassMarsSignature){
            param.Signed = true;
        }
       
        await MarsService.add(param)
        .then((response) => {             
                if(response.status == '200'){
                    this.pendingChanges = false;
                    itemchild.recNo = response.data.recNo;
                    itemchild.reviewedByName = response.data.reviewedByName;
                    itemchild.reviewedOn = response.data.reviewedOn ? Utils.formatterDateTimeToString(response.data.reviewedOn):null;
                    this.InsertStatus = true;
                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                        title: this.$t('Msg.Saving'),
                        autoHideDelay: 5000,
                        variant: "success"
                    })   
                }else{
                    this.InsertStatus = false;
                }               
                this.Load = false;
            })
        .catch((error) => {
            this.InsertStatus = false;
            elemSaveBtn.removeAttribute("disabled");
            this.Load = false;
            if (!error.response) {
               this.showMessage(this.$t('modalMarsMed.Administration'),this.$t('Msg.NotAbleToInsert'),"error");
            }                      
            else if(error.response.status === 400 ){
               this.showMessage(this.$t('modalMarsMed.Administration'),error.response.data.errorMessage,"error");
            } else if(error.response.status === 500){
                this.showMessage(this.$t('modalMarsMed.Administration'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
                this.showMessage(this.$t('modalMarsMed.Administration'),error.response.data.errorMessage,"error");
            }           
        });      
    },

    async getPharmacyOptions(){                 
         await PharmacyOptions.getPharmacyOptions()
            .then((response) => {
               if(response.status == '200' && response.data && response.data.length > 0){                
                  this.PharmacyOption = response.data[0];  
               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }         
        });        
      },


    async GetMedications() {


      if(this.validate_required()){
        this.MedList = []; 
        this.Load = true;       
        let param = {  
            Mode:this.mode.code,             
            PatientCode :this.PatientCode,
            SearchCriteria:this.parameter.SearchCriteria,
            DateFrom:this.DateFromModal,
            DateTo:this.DateToModal,          
            PageNumber : this.currentPage,
            PageSize : this.perPage  
        };
           await MarsService.getMedListing(param)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.MedList = response.data;
                    this.showEmptyRecords = response.data && response.data.length === 0 ? true : false;                   
                    this.totalRows = response.data.length > 0 ? response.data[0].maxRows : 0;  
                    this.MedList.forEach(function(element) {  

                        element.isHovered = false;

                        if (element.adminDate)	
                            element.adminDate = Utils.formatterDateToString(element.adminDate);                            
                        if (element.adminTime)	
                            element.adminTime = Utils.formatTime(element.adminTime); 
                        if (element.dateTimeExpired)
                            element.dateTimeExpired = Utils.formatterDateTimeToString(element.dateTimeExpired);
                        
                        
                        
                        if(element.mode === 'PRN'){
                            for(var i = 0; i < element.assessmentTypes.length; i++){
                                 element.assessmentTypes[i].metAssessment = false;
                            }
                         //   if(element.marsAdministrations.length === 0){
                            element.marsAdministrations.push(
                            {
                                isNewAdm:true,
                                administrationNotes:"",
                                administrationStatus: "",
                                administrationStatusTranslated:"",
                                expiredAdministration:false,
                                given:null,
                                givenDate:null,
                                givenQTY:null,
                                givenTime:null,
                                marsRecNo:null,
                                assessmentDetails:element.assessmentTypes,
                                //metAssessment:element.needAssessment ? false : true,
                                metAssessment:true,
                                reasonCode:null,
                                reasonDescription:null,
                                reviewedByName:"",
                                reviewedOn:null,
                                signed:true,                              
                            })    
                            //}                                                  
                        }
                    

                      
                            
                          
                        });

                        this.PopulateUndoAdm();


                      
                }
                //this.$emit('load', false);
                this.Load = false;
            })
                .catch(error => {
                //this.$emit('load', false);
                this.Load = false;
                if (!error.response) {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('Nurses.marsListPatientMed'), error.response.data.errorMessage, "error");
                }
            });
       
        }else{
            this.showMessage(this.$t('Medication.Medications'), this.$t('modalMarsMed.DateFromToRequired'), "errorAutoHide");    
        }
       
        },

        label(value) {   
            if (value === 'Weightlbs') {
                return this.unitSys === 'U' ? ' (lbs)' :  ' (Kgs)';
            }
            if (value === 'Heightft' || value === 'HeadCirin') {
                return this.unitSys === 'U' ? ' (ft)' :  ' (cm)';
            } 
            return '';
        },

     emitEventCancel(){
     this.$emit('ModalTPMarsMed');
    }, 
    onCancel(){
        this.deActivateModal();
    },   

    emitEventOK(/*bvModalEvent*/){
        this.$emit('ModalTPMarsMed');
    },
    checkUserAction(IdButton) {      
        elemSaveBtn = document.getElementById(IdButton);            
      if (elemSaveBtn !== null && elemSaveBtn.disabled) {
         elemSaveBtn.removeAttribute('disabled');
      }
   },

   DisabledAllButtons(){
    for(var i = 0; i < this.MedList.length; i++){
        let arrMarsAdm = this.MedList[i].marsAdministrations;

        for(var j = 0; j < arrMarsAdm.length; j++){
           let elbtn = document.getElementById('btn_save_marsadm'+j);
           elbtn.setAttribute('disabled','');
        }
    }
   },

   },

   created(){
    EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed;
          this.checkUserAction(e.IdButton);
     }.bind(this));  
   },
 
  async mounted(){
    this.disableAddAllergies = this.$store.state.isUsePatientAllergies;
    this.isReadOnly = this.$store.state.isMarsEHRReadOnly;
    this.getReason();
    this.getPharmacyOptions();
    this.EmployeeCanChange();   
    await this.GetMedications();
   

    Companies.getAll().then((response) => {
        var data=response.data;
        if(data && data.length > 0){
            this.unitSys = data[0].unitSystem ?? 'U';
        }
      });
     let arr = this.MedList;
      setTimeout( function() {
        /* elemSaveBtn = document.getElementById('btn_save_marsadm');
         elemSaveBtn.setAttribute('disabled','');*/
        // this.DisabledAllButtons();
        for(var i = 0; i < arr.length; i++){
        let arrMarsAdm = arr[i].marsAdministrations;

        for(var j = 0; j < arrMarsAdm.length; j++){
           let elbtn = document.getElementById('btn_save_marsadm'+j);
           elbtn.setAttribute('disabled','');
        }
    }
      }, 50,this);

   },
 };
</script>

<style scoped>

.patPhoto {
    position: relative;
    display: inline-block;
    overflow: hidden;
    padding-bottom: 10px;
    transition: transform 0.3s ease-in-out;
}

.patPhoto img {
    display: block;
    width: 100%;
    height: auto;
    border: 3px solid white;
    border-radius: 10px;
    object-fit: cover;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
}

.patPhoto:hover {
    transform: scale(1.2);
}
.patPhoto:hover img{
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.patPhoto::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
    transform: translateY(100%);
    transition: transform 0.5s ease-in-out;
}

.patPhoto:hover::after {
    transform: translateY(-100%);
}

.relocateIcon{
    position: absolute;
    top: 5px;
    right: 20px;
}
.relocateIconMed{
    position: absolute;
    top: 5px;
    right: 25px;
}

.medAdminLabel{
    padding: 0 0.8em;
    display: inline-block;
    margin-top: .4em;
    border-radius: 10px;
    font-variant-caps: all-small-caps;
    vertical-align: top !important;
    font-kerning: 5em;
    font-weight: bold;
    font-size: 0.8em;
    color: #FFF;
}
.allergicToMed{
    background-color: #F00;
    color: #FF9;
    animation: blinking 1s linear infinite;
}
.overdue{
    background-color: var(--overdue);
}
.close-to-admin{
    background-color: var(--close-to-admin);
}
.prior-to-admin{
    background-color: var(--prior-to-admin);
}
.no-pending{
    background-color: var(--no-pending);
}

@keyframes blinking {
    0%, 50% {
        color: #FF9;
    }
    100% {
        color: #F00;
    }
}
@keyframes fadingNow {
    0%, 50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.withDot::after{
    content: "●";
    color: #F00;
    position: absolute;
    top: 4px;
    left: 14px;
    animation: fadingNow 1s infinite;
}
.greenBTN{
    background-color: #0C0 !important;
    border-radius: 15px 0 0 15px !important;
    color: #6F6 !important;
}
.greenBTN:hover{
    background-color: #0F0 !important;
    box-shadow: 0 0 5px #008300;
    color: #FFF !important;
    transform: scale(1.1);
}
.redBTN{
    background-color: #C00 !important;
    border-radius: 0 15px 15px 0 !important;
    color: #F66 !important;
}
.redBTN:hover{
    background-color: #F00 !important;
    box-shadow: 0 0 5px #820000;
    color: #FFF !important;
    transform: scale(1.1);
}

/* MODAL REMAKE */
.zebraAccordion{
    margin: 0 1em;
}
.resetFontSize small{
    font-size: .95em;
}
.zebraAccordion .zebraRow .zebraItem{
    border-radius: 4px 4px 0 0;
    padding: 5px;
    width: 100%;
    cursor: pointer;
    transition: all .3s ease-in-out;
}

.zebraAccordion .zebraRow:nth-child(odd) .zebraItem{
    background-color: #d4f7ff;
}
.zebraAccordion .zebraRow:nth-child(even) .zebraItem{
    background-color: #ecfcff;
}

.zebraAccordion .zebraRow .accordion .zebraItem.not-collapsed {
    background-color: #19BEE1;
    color: #FFF;
}
.zebraAccordion .zebraRow .accordion .zebraItem:hover {
    background-color: #64d6f0;
    color: #FFF;
}
.zItemIcon{
    width: 15%;
    margin: 0 1%;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
}

.zItemText{
    width: 86%;
    display: inline-block;
    vertical-align: middle;
}
@media (max-width: 992px) {
    .zItemIcon{
        width: 10%;
    }
    .zItemText{
        width: 75%;
    }
}
.quickSave{
    color: #19BEE1;
    transition: all .3s ease-in-out;
    animation: spreadShadowFirst 1s ease-in-out infinite;
}
.quickSave:hover{
    color: #FFF;
}
.zebraAccordion .zebraRow .zebraItem small{
    font-size: .7em;
}
.PRNlabel{
    background-color: #6F6;
    border-radius: 10px;
    padding: 0 10px;
    color: #060 !important;
    transition: all .3s ease-in-out;
}
.PRNlabel:hover{
    background-color: #FF9;
}
.opAT{
    opacity: .5;
}
.accordionContent{
    border-top: 0;
    background: linear-gradient(173deg, #D9F4FB, #FFF 30% );
    padding-top: 3em;
}

.giveMeds button{
    border: 4px solid #FFFFFFEE;
    transition: all .3s ease-in-out;
    cursor: pointer;
}
.redMed{
    border-radius: 0 50px 50px 0;
    border-left: 0;
    background-color: #660000;
    background: linear-gradient(-40deg, #FF0000, #660000);
    animation: spreadShadowLast 1s ease-in-out infinite;
}
.redMed:hover{
    background-color: #FF0000;
    background: linear-gradient(-40deg, #990000, #FF0000);
}
.redMed:hover i::before{
    animation: spreadShadow 1s ease-in-out infinite;
}

.greenMed{
    border-radius: 50px 0 0 50px;   
    background-color: #008236;
    background: linear-gradient(-40deg, #008236, #003300);
    border-right: 0 !important;
    animation: spreadShadowFirst 1s ease-in-out infinite;
}
.greenMed i::before{
    font-size: 1.1em;
}
.greenMed:hover{
    background-color: #00CC00;
    background: linear-gradient(-40deg, #006600,#01d85b);
}
.greenMed:hover i::before{
    animation: spreadShadow 1s ease-in-out infinite;
}

.GivNeutral{
    opacity: 0.2;
}

.undoMedLeft{
    background-color: #666;
    background: linear-gradient(-40deg, #EEE, #CCC);
    border: 4px solid #EEE !important;
    border-right: 0 !important;
}
.undoMedLeft:hover{
    background-color: #999;
    background: linear-gradient(-40deg, #999, #CCC);
}
.undoMedRight{
    background-color: #666;
    background: linear-gradient(-40deg, #CCC, #EEE);
    border: 4px solid #EEE !important;
    border-left: 0 !important;
}
.undoMedRight:hover{
    background-color: #999;
    background: linear-gradient(-40deg, #CCC, #999);
}
@keyframes spreadShadow {
    0%, 100% {
        text-shadow: 0 0 1px #00000099;
    }
    50% {
        text-shadow: 0 0 20px #000000FF;
    }
}
@keyframes spreadShadowFirst {
    0%, 100% {
        text-shadow: 0 0 1px #FFFFFF;
    }
    50% {
        text-shadow: 0 0 20px #FFFFFF;
    }
}
@keyframes spreadShadowLast {
    0%, 100% {
        text-shadow: 0 0 20px #FFFFFF, 0 0 20px #FFFFFF;
    }
    25% {
        text-shadow: 0 0 1px #FFFFFF, 0 0 1px #FFFFFF;
    }
}
</style>