<template>
   <div id="schedulerdb">
        <div class="row">
            <div class="col-12">
                  <MdTitle v-bind:Text="$t('schedulerDB.ScheDashboard')" /> 
                    <div class="row">
                        <div class="col-sm-12 col-md-3">                      
                            <MdButton
                              v-bind:Label="$t('Appointments.addAppointment')"
                              v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                              v-on:click="AddAppointment"
                              v-bind:IconClass="'ti-plus pr-1'"
                              v-bind:Name="'btn_add_appointment'">
                            </MdButton>
                        </div>                     
                        <div class="col-sm-12 col-md-9 mb-4">
                            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="parameter.SearchCriteria" @keyup.enter="SearchAppoinments()">
                                        <div class="input-group-append">
                                            <MdButton 
                                                v-bind:Name="'btnsearchinput'"
                                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                                v-bind:IconClass="'mdi mdi-magnify'"
                                                v-on:click="SearchAppoinments">
                                            </MdButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div> 
        </div>

        <b-row>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Shared.datefrom')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'DateFrom'"
                    v-model="DateFrom"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-bind:Id="'input_schedulerdb_date_from'"
                    v-bind:OnPressEnter="SearchAppoinments">                                     
                </MdEdit>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Shared.dateTo')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'DateTo'"
                    v-model="DateTo"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-bind:Id="'input_schedulerdb_date_to'"
                    v-bind:OnPressEnter="SearchAppoinments">                                     
                </MdEdit>
            </b-col>  
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$tc('Shared.Patient',2)"
                  v-bind:Options="PatientsList"
                  v-bind:Model="PatientVmodel"
                  v-bind:FieldDescription="'patientname'"
                  v-bind:FieldCode="'patientcode'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownPatients"
                  :CustomLabel="customLabelPatients"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$tc('Shared.Therapist')"
                  v-bind:Options="TherapistList"
                  v-bind:Model="TherapistVmodel"
                  v-bind:FieldDescription="'employeeName'"
                  v-bind:FieldCode="'employeeCode'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownTherapist"/>
            </b-col>
        </b-row>

        <b-row>
          <b-col md="3" lg="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.AppointmentType')"
              v-bind:Options="AppoinmentTypeList"
              v-bind:Model="AppoinmentTypeVmodel"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Placeholder="selectPlaceholder"
              v-bind:Multiple="true"
              v-bind:RequireEvent="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownAppoinmentType"/>
        </b-col>

            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$tc('Shared.Program',2)"
                  v-bind:Options="ProgramsList"
                  v-bind:Model="ProgramsVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownPrograms"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$t('schedulerDB.AppointmentStatus')"
                  v-bind:Options="ApptStatusList"
                  v-bind:Model="ShowVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownShow"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$t('ClinicalNotes.NoteStatus')"
                  v-bind:Options="NoteStatusList"
                  v-bind:Model="NoteStatusVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="false"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownNoteStatus"/>
            </b-col>
        </b-row>
        <b-row>
						<b-col md="12">
            <div class="text-center text-md-right mt-3">
                <button 
                  v-on:click="SearchAppoinments()"
									type="button"
									class="btn btn-primary btn-login mx-2 pull-right"												
									id="btnsearchinput">
										{{ $t('Shared.Search') }}
								</button>
							</div>
						</b-col>
				</b-row>
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'" class="mt-2"/>

        <div class="row"> 
            <div class="col-md-3">
               <MdCheckBox
                v-bind:Label="$t('Shared.SelectAll')"
                v-bind:Name="'SelectTableAll'"
                v-bind:ClassProp="'AlignCheck mx-3'"
                v-bind:CheckedValue="true"
                v-bind:isReadOnly="this.items.length === 0"
                v-bind:UncheckedValue="false"
                v-model="UseSelectTableAll" 
                v-on:change="onChangeSelectAll"                
             /> 
            </div>
            <div class="col-md-3 mt-3">
              <span class="tableSelectedItems"><span class="selItemsVal">{{ SelectedRecords }}</span> {{ $t('claimbatchList.item') }}<span v-if="SelectedRecords != 1">s</span> {{ $t('claimbatchList.selected') }}</span>
            </div> 
        </div> 
       
        <MdTablePaginationClient
              v-bind:refTable="'AppntTable'"
              ref="AppntTable"
              v-bind:fields="fields" 
              v-bind:isSchedulerDB="true"          
              v-bind:items="items"
              v-bind:totalRows="totalRows"    
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"             
              v-bind:ShowDetails="true"
              v-bind:SelectAll="UseSelectTableAll"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:pageOptions="pageOptions" 
              v-bind:viewIconEvent="handleViewEvent"   
              v-bind:ondblClick="handleDblClick"          
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :RowClick="RowClicked"
              :perPageFunction="PerPageFunction"
              :otherIcon="'printer-fill'"
              :otherIconEvent="showReport">
            </MdTablePaginationClient>
   
            <div class="space-actions"></div>
        <div class="page-actions">  
           <b-dropdown id="dropdown-dropup" dropup :text="$tc('Shared.ActionsTxt',2)" variant="primary" class="m-2">
                <b-dropdown-item href="#" v-if="confirmStatus" v-on:click="ConfirmAppt('T')">{{$t('schedulerDB.dropdown.Confirm')}}</b-dropdown-item>
                <b-dropdown-item href="#" v-if="uConfirmStatus" v-on:click="ConfirmAppt('F')">{{$t('schedulerDB.dropdown.uConfirm')}}</b-dropdown-item>
                <b-dropdown-item href="#" v-if="showUp" v-on:click="ShowUpAppt('T')">{{$t('schedulerDB.dropdown.ShowUp')}}</b-dropdown-item>
                <b-dropdown-item href="#" v-if="uShowUp" v-on:click="ShowUpAppt('F')">{{$t('schedulerDB.dropdown.uShowUp')}}</b-dropdown-item>
                <b-dropdown-item href="#" v-if="cancel" v-on:click="OpenAppointmentReason('F')">{{$t('schedulerDB.dropdown.Cancel')}}</b-dropdown-item>
                <b-dropdown-item href="#" v-if="uCancel" v-on:click="OpenAppointmentReason('T')">{{$t('schedulerDB.dropdown.uCancel')}}</b-dropdown-item>
                <b-dropdown-item href="#" v-if="isNotify" v-on:click="ShowNotify()">{{$t('schedulerDB.dropdown.Notify')}}</b-dropdown-item>          
                <b-dropdown-item href="#" v-on:click="ShowReport()">{{$t('schedulerDB.dropdown.Report')}}</b-dropdown-item>
            </b-dropdown>          
        </div>

        <ModalAppointmentReason 
          v-bind:TitleWindow="$t('AppointmentDashboard.ReasonForNoShow')"  
          v-bind:ModalId="this.modalReasonId"
          v-bind:ClickedText="this.ClickedText" 
        />  
        <SchedulerSingleRep v-if="isReportVisible" 
          :closeWindow="closeParamRepWindow"
          :activityId="paramActivityId"
        />
        
        <SchedulerDailyRep v-if="isDailyReportVisible" 
          :closeWindow="closeParamDailyReportWindow"
          :startDate="startDate"
        />

        <ScheduleNotify v-if="isNotifyVisible"
          :onClose="closeNotifyWindow"
          :activityId="activityId"
          :module="'schedulerdb'"
          :onSuccess="notifySuccess"
        />

        <PatientAlertsInfoModal
            v-bind:PatientCode = "PatientCodeSelected"
            v-bind:AutoShow = "false"
            v-bind:ShowModal = "ShowModalPatientAlerts"
            v-bind:CloseEvent = "handleCloseEvent"
        >
        </PatientAlertsInfoModal>
   
    </div>
</template>

<script>

import moment from 'moment'
import PatientService from '@/services/patient.service' 
import CalendarResourcesService from '@/services/calendarresources.service'
import AppoinmentTypeService from '@/services/appointmenttypes.service'
import FacilitiesService from '@/services/facilities.service'
import ProgramService from '@/services/programs.services'
import AppointmentService from '@/services/appointment.service'
import Utils from '@/common/utils'
import EnumService from '@/services/enum.service' 
import { EventBus } from '@/event-bus.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  mixins: [ShowMessage, DBFunctions],
  name: 'SchedulerDB',
  data(){
    return{
        parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: '',
        Programs:'',    
      },
      OptionsRightMenu:{
            visible:false,
            btnvisible:false,
            ItemsList:[],
            favButtonAtion: false
        },
      fullParam: {
            PageSize:0,
            PageNumber:0
        },
    PatientsList:[],
    TherapistList:[],
    AppoinmentTypeList:[],
    FacilitiesList:[],
    ProgramsList:[],
    NoteStatusList:EnumService.SchedulerDashboardNoteStatus,
    ApptStatusList:EnumService.SchedulerDashboardApptStatus,    
    items:[],
    FieldsDateArr:['startDate','endDate'],
    PatientVmodel:"",
    NoteStatusVmodel:"",
    ShowVmodel:"",
    TherapistVmodel:"",
    AppoinmentTypeVmodel:"",
    FacilitiesVmodel:"",
    ProgramsVmodel:"",
    PatientSelected:"",
    FacilitieSelected:"",
    ProgramSelected:"",
    EmployeeSelected:"",
    ApptTypeSelected:"",
    ShowSelected:"",
    NoteStatusSelected:"",
    refTable: 'selectableTableSchedulerDB',
    ShowMessageIsEmpty:false,
    UseSelectTableAll:false,
    SelectedRecords:0, 
    perPage: 10,
    SelectedPage : 0,
    totalRows: 0,      
    currentPage: 1,
    pageOptions: [5, 10, 15 ],
    selectPlaceholder: this.$t('ClinicalNotes.SelectOptions'),
    DateFrom: moment(this.NewDate).format("YYYY-MM-DD"),
    DateTo: moment(this.NewDate).format("YYYY-MM-DD"),
    fields:[        
        { key: 'activityId', sortable: true, label: this.$t('Activities.Activity'), class: 'text-rigth hidden' }, 
        { key: 'startDate', sortable: true, label: this.$t('Medication.StartDate'), class: 'text-rigth' }, 
        { key: 'endDate', sortable: true, label: this.$t('Medication.EndDate'), class: 'text-rigth' },        
        { key: 'appointmentTypeName', sortable: true, label: this.$tc('Shared.AppointmentType'), class: 'text-rigth' }, 
        { key: 'therapistName', sortable: true, label:  this.$tc('Shared.Therapist'), class: 'text-rigth' }, 
        { key: 'patientName', sortable: true, label: this.$t('Shared.PatientName'), class: 'text-rigth' }, 
        { key: 'nickName', sortable: true, label: this.$t('Patient.NickName'), class: 'text-rigth' }, 
        { key: 'patientCode', sortable: true, label: this.$t('Shared.PatientCode'), class: 'text-rigth' }, 
        { key: 'dob', sortable: true, label: this.$t('Shared.Dob'), class: 'text-rigth' }, 
        { key: 'cellPhone', sortable: true, label: this.$t('Shared.CellPhone'), class: 'text-rigth' }, 
        { key: 'dayPhone', sortable: true, label: this.$t('Shared.DayPhone'), class: 'text-rigth' }, 
        { key: 'nighTPhone', sortable: true, label: this.$t('Shared.NightPhone'), class: 'text-rigth' }, 
        { key: 'email', sortable: true, label: this.$t('Shared.Email'), class: 'text-rigth' }, 
        { key: 'programName', sortable: true, label: this.$tc('Shared.Program'), class: 'text-rigth' },  
    ],
    confirmStatus: false,
    uConfirmStatus: false,
    showUp: false,
    uShowUp: false,
    cancel: false,
    uCancel: false,
    isNotify: false,
    selected: [],
    indexSelected: 0,
    modalReasonId:"modalIdAppointmentReason",
    ClickedText:"",
    isReportVisible: false,
    paramActivityId: null,
    isDailyReportVisible: false,
    startDate: new Date(),
    isNotifyVisible: false,
    activityId: [],      
    ShowModalPatientAlerts: false,
    PatientCodeSelected: '',
    currentItemSelected: null
    }
  },

  methods:{

    notifySuccess() {
      this.showMessage(this.$t('scheduleNotify.noti'),this.$t('scheduleNotify.msgSent'));
    },

    AddAppointment() {
      this.emitDataforFavorites();
      EventBus.$emit('onUpdatedMenu', '');  
      this.$router.push({ name: 'Appointment-Editor-Add'});
    },

    ShowNotify() {
      this.isNotifyVisible = true
    },

    closeNotifyWindow() {
      this.isNotifyVisible = false
    },

    ShowReport() {
      this.isDailyReportVisible = true
    },

    closeParamDailyReportWindow() {
        this.isDailyReportVisible = false
      },

    tbodyRowAttr() {
			return {style: 'cursor: pointer'}
		},

    onRowClicked(item,index) {
      this.indexSelected = index
    },

    resetSelections() {
      this.selected = []
      this.SelectedRecords = 0;
      this.UseSelectTableAll = false
      this.showAllActions(false)
    },

    onChangeSelectAll(v){
      this.activityId = []
      if(v){
        this.SelectedRecords = this.items.length;
        this.selected = []
        for(var i = 0 ; i < this.items.length; i++){
           this.$set(this.items[i], 'selected', true);
           this.selected.push(this.items[i])
           this.activityId.push(this.items[i].activityId)
        }
        this.showAllActions(true)
      }else{
        //this.SelectedRecords = 0;
        for(var j= 0 ; j < this.items.length; j++){
          this.$set(this.items[j], 'selected', false);  
        }
        this.resetSelections()
      }
    },

    ChangeDropDownPatients(patients){
      this.PatientVmodel = patients;
      this.PatientSelected = "";
      var temp = [];
      patients.forEach(function(e) {   
        temp.push(e.patientcode.trim());
      })
      this.PatientSelected = temp.join(',');     
      this.SearchAppoinments();   
    },

    ChangeDropDownTherapist(therapists){
      this.TherapistVmodel = therapists;
      this.EmployeeSelected = "";
      var temp = [];
      therapists.forEach(function(e) {   
        temp.push(e.employeeCode.trim());
      })
      this.EmployeeSelected = temp.join(',');    
      this.SearchAppoinments();   
    },

    ChangeDropDownAppoinmentType(appttypes){
      this.AppoinmentTypeVmodel = appttypes;
      this.ApptTypeSelected = "";
      var temp = [];
      appttypes.forEach(function(e) {   
        temp.push(e.code.trim());
      })
      this.ApptTypeSelected = temp.join(',');    
      this.SearchAppoinments();   
    },

    ChangeDropDownFacilities(facilities){
      this.FacilitiesVmodel = facilities;
      this.FacilitieSelected = "";
      var temp = [];
      facilities.forEach(function(e) {   
        temp.push(e.code.trim());
      })
      this.FacilitieSelected = temp.join(',');    
    },

    ChangeDropDownPrograms(programs){
      this.ProgramsVmodel = programs;
      this.ProgramSelected = "";
      var temp = [];
      programs.forEach(function(e) {   
        temp.push(e.code.trim());
      })
      this.ProgramSelected = temp.join(',');  
      this.SearchAppoinments();     
    },

    ChangeDropDownNoteStatus(notestatus){
      this.NoteStatusVmodel = notestatus != null ? notestatus : "";
      this.NoteStatusSelected = notestatus != null ? notestatus.value : "";
      this.SearchAppoinments();   
    },

    ChangeDropDownShow(options){
      this.ShowVmodel = options;
      this.ShowSelected = "";
      var temp = [];
      options.forEach(function(e) {   
        temp.push(e.value.trim());
      })
      this.ShowSelected = temp.join(',');
      this.SearchAppoinments();   
    },

    ViewIcon(row){
      //appointment-Dashboard
      this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems
        
        let layoutStatus = this.$store.state.layoutStatus;
      
        switch(layoutStatus) {
          case 'L0R0':
              this.$store.commit('setlayoutStatus', 'L0R1');
          break;
          default:
              this.$store.commit('setlayoutStatus', 'L1R1');
        }
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        
      this.$router.push({ name: "appointment-Dashboard", params: { id: row.patientCode,actId:row.activityId } });   
    },

    showAllActions(enabled) {
        this.confirmStatus = enabled
        this.uConfirmStatus = enabled
        this.showUp = enabled 
        this.uShowUp = enabled 
        this.cancel = enabled
        this.uCancel = enabled
        this.isNotify = enabled
    },

    ConfirmAppt(opt){
      let param = {}
      let aIdList = []
      if (this.selected && this.selected.length == 1) {
        param = {           
            ActivityIdList: this.selected[0].activityId,
            IsConfirmed: opt          
        };
      } else if (this.selected && this.selected.length > 1) {
        this.selected.forEach(item => {
          aIdList.push(item.activityId);
        });
        param = {           
            ActivityIdList: aIdList.toString(),
            IsConfirmed: opt          
        };
      } 

        AppointmentService.ConfirmAppt(param)
              .then(async (response) => {
                if(response.status == '200'){
                    if(param.IsConfirmed == 'F'){ 
                        this.$bvToast.toast(this.$t('Appointments.nConfirmMsg'), {
                         title: this.$t('Appointments.Appo'),
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast(this.$t('Appointments.confirmMsg'), {
                         title: this.$t('Appointments.Appo'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                    }

                    await this.SearchAppoinments();
                    this.resetSelections() 
                    //this.$refs.AppntTable.selectRow2(this.indexSelected)
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage(this.$t('Appointments.Appo'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('Appointments.Appo'),error,"error");
                }
              });
    },

    ShowUpAppt(opt){
        let param = {};
        let aIdList = []

        if (this.selected && this.selected.length == 1) {
        param = {           
            ActivityIdList : this.selected[0].activityId,
            State: opt,
            Action: opt == 'T' ? 'Show Up' : 'Undo Show Up',       
        };
        
      } else if (this.selected && this.selected.length > 1) {
        this.selected.forEach(item => {
          aIdList.push(item.activityId);
        });
        param = {           
            ActivityIdList: aIdList.toString(),
            State: opt,
            Action: opt == 'T' ? 'Show Up' : 'Undo Show Up',       
        };
      }

         AppointmentService.ShowUp(param)
              .then( async (response) => {
                if(response.status == '200'){
                  //this.GetPermission(this.selected[0].activityId);
                   if(opt == 'T'){ 
                        this.$bvToast.toast(this.$t('Appointments.showUpMsg'), {
                         title: this.$t('Appointments.Appo'),
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast(this.$t('Appointments.uShowUpMsg'), {
                         title: this.$t('Appointments.Appo'),
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }
                    await this.SearchAppoinments();
                    //this.$refs.AppntTable.selectRow2(this.indexSelected)
                    this.resetSelections() 
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage(this.$t('Appointments.Appo'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('Appointments.Appo'),error,"error");
                }
              });      
    },

    OpenAppointmentReason(opt){
      if (opt == 'F') {
        EventBus.$emit('onAppointmentReasonModal', opt); 
      }        
      else{
          this.CancelAppt(opt,'');
      }
    },

    CancelAppt(opt,reason){
        let param = {}
        let aIdList = []

        if (this.selected && this.selected.length == 1) {
          param.ActivityIdList = this.selected[0].activityId
        }
        else if (this.selected && this.selected.length > 1) {
          this.selected.forEach(item => {
            aIdList.push(item.activityId);
          });
          param = {           
            ActivityIdList: aIdList.toString(),  
          };
        }

        if(opt == 'F'){           
            param.Reason = reason;
        }
        else{
            param.Reason = "";
        }

        if (param.ActivityIdList) {
          AppointmentService.CancelAppt(param)
              .then(async (response) => {
                if(response.status == '200'){
                    //this.GetPermission(this.selected[0].activityId);

                    if(opt == 'F'){ 
                        this.$bvToast.toast(this.$t('Appointments.cancelMsg'), {
                         title: this.$t('Appointments.Appo'),
                          autoHideDelay: 5000,
                          variant: "success"
                        });
                    }else{
                        this.$bvToast.toast(this.$t('Appointments.cancelUnDoneMsg'), {
                         title: this.$t('Appointments.Appo'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                    }

                    await this.SearchAppoinments();
                    //this.$refs.AppntTable.selectRow2(this.indexSelected)
                    this.resetSelections() 
                }
              })
              .catch(error => {
                if (!error.response) {               
                  this.showMessage(this.$t('Appointments.Appo'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('Appointments.Appo'),error,"error");
                }
              });
        }
    },

    onFiltered(filteredItems){
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    pageChanged(){

    },

    RowClicked(item){
      if(item.selected){      
        this.$set(item, 'selected', false);         
      }else{
        this.$set(item, 'selected', true);       
      }
        EventBus.$emit('rowClicked', item);
      
      let itemselected = this.selected.findIndex((element) => element.activityId == item.activityId)
      
      if (itemselected != -1 && !item.selected) {
        this.selected.splice(itemselected, 1)
        let aIdIndx = this.activityId.indexOf(item.activityId)
        this.activityId.splice(aIdIndx, 1)
      } else if (itemselected == -1 && item.selected) {
        this.selected.push(item)
        this.activityId.push(item.activityId)
      }

      if (this.selected && this.selected.length == 1) {
        this.confirmStatus = item.status == 'New' ? true : false
        this.uConfirmStatus = item.status == 'Confirmed' ? true : false
        this.showUp = item.status == 'New' || item.status == 'Confirmed' ? true : false
        this.uShowUp = item.status == 'Showed Up' ? true : false
        this.cancel = item.status == 'New' || item.status == 'Confirmed' ? true : false
        this.uCancel = item.status == 'Missed' ? true : false
        this.isNotify = true
      }
       else if (this.selected && this.selected.length > 0) {
        this.showAllActions(true)
        //this.activityId.push(item.activityId)
      } else {
        
        this.showAllActions(false)
      }
    },

    PerPageFunction(page){
      this.perPage = page;
    },

    getEmployees() { 
        CalendarResourcesService.getAll(this.fullParam)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.TherapistList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
            },

    getPatients(){   
      PatientService.getAll(this.fullParam)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.PatientsList = response.data; 
        this.PatientsList.forEach(function(element) {	
            element.patientname = element.firstname + " " + element.lastname;   
        });       
        }       
        this.$emit('load', false);    
      })
      .catch(error => {      
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$tc('Shared.Patient',2),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$tc('Shared.Patient',2),error,"error");
        }
      });    
    },

    getAppointmentTypes() { 
        AppoinmentTypeService.getListing()
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.AppoinmentTypeList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
    },

    getFacilities() {        
        FacilitiesService.getAll(this.fullParam)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.FacilitiesList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
    },

    getPrograms(){
      ProgramService.getAll(this.fullParam)
        .then((response) => { 
          if(response.data && response.data.length > 0){   
            this.ProgramsList = response.data;
          }
        })
        .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$tc('Shared.Program',2),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$tc('Shared.Program',2),error,"error");
            }
        });  
    },

    async SearchAppoinments(){ 
      var param = {
        PatientCode:this.PatientSelected,
        EmployeeCode:this.EmployeeSelected,
        AppointmentType:this.ApptTypeSelected,
        FacilityCode:this.FacilitieSelected,
        ProgramCode:this.ProgramSelected,
        NoteStatus:this.NoteStatusSelected,
        AppointmentStatus:this.ShowSelected,
        DateFrom:this.DateFrom,
        DateTo:this.DateTo,
        SearchCriteria: this.parameter.SearchCriteria
      }
      this.$emit('load', true);
      this.resetSelections()
      await AppointmentService.getAll(param)
        .then((response) => { 
          if(response.data){   
            this.items = response.data;
            this.totalRows = this.items.length;
            this.items.forEach(function(element) {   
              let { dob, startDate, endDate, timeIn, timeOut  } = element
              if (dob) element.dob = Utils.formatterDateToString(dob);  
              if (startDate) element.startDate = `${Utils.formatterDateToString(startDate)} ${Utils.formatTime(timeIn, 'MM/DD/YYYY HH:mm:ss')}`;         
              if (endDate) element.endDate = `${Utils.formatterDateToString(endDate)} ${Utils.formatTime(timeOut, 'MM/DD/YYYY HH:mm:ss')}`;               
            });
            if (this.items.length === 0) {
							this.ShowMessageIsEmpty = true;
						}      
          }
          this.$emit('load', false);
        })
        .catch(error => {
          this.$emit('load', false);
            if (!error.response) {
            // network error
              this.showMessage(this.$t('schedulerDB.Appo'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('schedulerDB.Appo'),error,"error");
            }
        });  
    },

    customLabelPatients ({ patientname, patientcode }) {
        return `${patientname} – ${patientcode}`
    }
    ,
    showReport(item) {
      if (item && item.activityId) {
        this.paramActivityId = item.activityId
      }
      this.isReportVisible = true
    },
    closeParamRepWindow() {
      this.isReportVisible = false
      this.paramActivityId = null
    },   

    async handleViewEvent(item) {
        await this.handleDblClick(item)
    },

    async handleDblClick(item) {
        this.PatientCodeSelected = item.patientCode;
        const hasAlerts = await this.IsPatientWithActiveAlerts(this.PatientCodeSelected);
        if (hasAlerts) {
            this.currentItemSelected = item;
            this.ShowModalPatientAlerts = true;
        } else {
            this.ViewIcon(item);
        }
    },

    handleCloseEvent() {
        this.ShowModalPatientAlerts = false;
        if (this.currentItemSelected) {
            this.ViewIcon(this.currentItemSelected)
        }
    }
  },

  created(){
    this.getPatients();
    this.getEmployees();
    this.getAppointmentTypes();
    this.getFacilities();
    this.getPrograms();

    EventBus.$on("rowClicked", function () {        
    this.SelectedRecords = 0;
    for(var i = 0; i < this.items.length; i++){
      if(this.items[i].selected){
        this.SelectedRecords++;
      }
    }
      
     }.bind(this));

    EventBus.$on("onAppointmentReasonModal", function (reason) { 
			this.ClickedText  = reason;     
			this.$bvModal.show(this.modalReasonId);    
		}.bind(this));

    EventBus.$on("onSelectAppointmentReasonCode", function (param) { 
      this.CancelAppt(param.Text, param.Code);
    }.bind(this));
  },

  mounted(){
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }  

      this.SearchAppoinments();   
  },

  destroyed(){
    EventBus.$off('onAppointmentReasonModal');
    EventBus.$off('onSelectAppointmentReasonCode');
  }
   
};
</script>


