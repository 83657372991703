<template>
	<component 
		:is="propTag" 
		class="text-truncate" 
		:class="propClass"
		:title="isTruncated ? fullTitle : ''"
		:ref="titleRef"
		v-b-tooltip.hover>
		{{ fullTitle }}
	</component>
</template>

<script>

export default {
	
	name: 'MdTitleRegInfo',
	data() {
		return {
			isTruncated: false,
			isMovile: false
		}
	},
	props: {
		titlePrefix: String,
		titleRef: String,
		patientCode: String,		
		RegistrationInfo: {
			type: Object,
			required: true,
			default: () => ({}) // Default value to avoid errors
		},
		IsClinical: {
			type: Boolean,
			default: false,
		},
		propTag:{
			type: String,
			default: 'h4',
		},
		propClass:{
			type: String,
			default: 'page-title',
		},
		isTitle: {
			type: Boolean,
			default: true,
		},
	},
	computed: {
		fullTitle() {
			let title = this.titlePrefix;
			const pCode = this.patientCode;
			const { patientName, dob, ageFormatted, 
					programName, dateFrom, dateTo,
					fullName, activitytypename, startdatetime, 
					company /** only added for insurance company */,
					appointmentType /** only added for AppointmentEditor */ } = this.RegistrationInfo;
			
			if (patientName) 		title += ` - ${patientName}`;
			if (fullName) 			title += ` - ${fullName}`;
			if (pCode) 				title += ` - ${pCode}`;
			if (dob) 				title += ` - ${this.$t('Shared.Dob')} ${dob}`;
			if (ageFormatted) 		title += ` - ${this.$t('Shared.Age')} ${ageFormatted}`;
			if (activitytypename) 	title += ` - ${activitytypename}`;
			if (appointmentType) 	title += ` - ${appointmentType}`;/** only added for AppointmentEditor */
			if (startdatetime) 		title += ` - ${startdatetime}`;
			if (programName) 		title += ` - ${programName}`;
			if (dateFrom) 			title += ` - ${dateFrom}`;
			if (dateTo) 			title += ` - ${dateTo}`;
			else 					title += this.IsClinical ? ` - ${this.$t('Shared.Active')}` : '';
			if (company) 			title += ` : ${company}`;/** only added for insurance company */
		
			return title;
		},
	},
	methods:{
		addEventListeners() {
			this.$el.addEventListener('mouseenter', this.checkTruncation);
			this.$el.addEventListener('mouseleave', this.checkTruncation);
			// Events for movile
			if ( this.isMovile ) {
				this.$el.addEventListener('touchstart', this.checkTruncation);
				this.$el.addEventListener('touchend', this.checkTruncation);
				this.$el.addEventListener('touchcancel', this.checkTruncation);
			}
			// Re-check on window resize
			window.addEventListener('resize', this.checkTruncation);
		},
		removeEventListeners() {
			this.$el.removeEventListener('mouseenter', this.checkTruncation);
			this.$el.removeEventListener('mouseleave', this.checkTruncation);
			// Events for movile
			if ( this.isMovile ) {
				this.$el.removeEventListener('touchstart', this.checkTruncation);
				this.$el.removeEventListener('touchend', this.checkTruncation);
				this.$el.removeEventListener('touchcancel', this.checkTruncation);
			}
			window.removeEventListener('resize', this.checkTruncation);
		},
		checkTruncation() {
			/**For ChartReview, ClinicalNotes accordion headers  */
			const parentElement = this.$el.parentNode
			if(!this.isTitle && parentElement) {
				this.isTruncated = parentElement.offsetWidth < parentElement.scrollWidth;
				return;
			}
			/**End */
			/**For titles whith blue background*/
			if(this.$refs[this.titleRef])
				this.isTruncated = this.$refs[this.titleRef].offsetWidth < this.$refs[this.titleRef].scrollWidth;
		}
	},
	mounted(){
		this.isMovile = this.$store.state.isMovile;
		// Initially check if truncated on mount
		this.$nextTick(() => {
			this.addEventListeners();
			this.checkTruncation();
		});
	},
	beforeDestroy() {
		this.removeEventListeners();
	}
};
</script>