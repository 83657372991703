<template>
  <div>
    <b-modal id="InsuranceModal" size="lg" :visible="!NotOpenByDefault ? true : false"  :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :title="TitleWindow" :ok-disabled="isEditMode" @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')"> 
    <div v-if="isListing" id="ContainerPatientInsurance">
         <MdTitle v-bind:Text="$t('InsurancesList.PatientInsurance')" />
    <div class="row mb-2">
      <div class="card" style="width: 100%">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-3">
              <MdButton 
                 v-bind:Label="$t('InsurancesList.AddInsurance')"
                 v-bind:Name="'btnaddinsurance'"
                 v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                 v-bind:IconClass="'ti-plus pr-1'"
                 v-bind:Weight="'300px'"
                 v-on:click="AddInsurance"
                 v-bind:isReadOnly=this.isReadOnly>
              </MdButton>
            </div>
            <div class="col-sm-12 col-md-6 mb-4">
              <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="parameter.SearchCriteria"
                    @keyup.enter="SearchInsurance"
                  />
                  <div class="input-group-append">
                    <MdButton 
                      v-bind:Name="'btnsearchinsurance'"
                      v-bind:VariantProp="'btn btn-sm search-attached'"
                      v-bind:IconClass="'mdi mdi-magnify'"
                      v-on:click="SearchInsurance">
                    </MdButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3">              
              <MdSelect
                v-bind:Label="$t('Shared.Type')"
                v-bind:Options="InsuranceTypesList"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'code'"
                v-bind:Model="ModelInsuranceType"
                v-bind:Multiple="false"              
                :Input="ChangeDropDownInsTypeList"
               />
            </div>               
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="card" style="width: 100%">
        <div class="card-body" style="margin-top: -30px !important">
          <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <div>
                <b-col lg="6" class="my-1"> </b-col>

                <MdTable 
                  v-bind:fields="fields"
                  v-bind:items="items"
                  v-bind:totalRows="totalRows"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                  v-bind:currentPage="currentPage"
                  v-bind:perPage="perPage"
                  v-bind:pageOptions="pageOptions"
                  :editIconEvent="EditInsurance"
                  v-bind:VisibleEditIcon="true"
                  :deleteIconEvent="RemoveInsurance"
                  v-bind:VisibleDeleteIcon="true"
                  :onRowSelected="onRowSelected"
                  :onFiltered="onFiltered"
                  :pageChanged="pageChanged"
                  :perPageFunction="PerPageFunction">
                </MdTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="isEditMode" id="PatientInsurance">
      <b-row class="mt-2">
          <b-col md="12">
            <div class="text-center text-md-right mt-3 container-button">
              <MdButton
                  v-bind:Label="$t('Insurance.InsuranceList')"
                  v-bind:Name="'btnpatientlist'"
                  v-bind:ClassProp="'secondary mx-2 btn-login'"
                  v-on:click="DiscardChanges"/>
              <MdButton
                v-bind:Label="$t('Insurance.SelfPay')"
                v-bind:ClassProp="'primary btn-login mx-2'"
                v-on:click="SelfPay()"
                v-bind:isReadOnly=this.isReadOnly
              />
              <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
                v-bind:isReadOnly=this.isReadOnly
              />
            </div>
          </b-col>
        </b-row>
      <div class="card" style="width:100%;">
        <div class="card-body">
          <h4 class="page-title text-truncate">
            {{this.editMode ? $t('Insurance.EditPatientInsurance') : $t('Insurance.AddPatientInsurance')}}
            <a v-if="Insurance.company">{{ ": " + Insurance.company }}</a>
          </h4>
          <b-row>
            <b-col md="3">
              <MdLookup
                v-bind:Label="$t('Insurance.Insurance')"
                v-bind:ModelCode="PatientInsurance.insCode"
                v-bind:ModelDescription="Insurance.company"
                v-bind:RefCode="'InsuranceCode'"
                v-bind:Api="'Insurances'"
                v-bind:FieldCode="'insurancecode'"
                v-bind:FieldDescription="'company'"
                :IsDisabled="this.isReadOnly"    
                :SearchTitle="'Insurances'"
                :GetValues="getInsuranceCodeValues"
                :InvalidFeedback="$t('Insurance.InvalidInsuranceCode')"
                :SearchFieldCode="'InsuranceCode'"
                v-bind:isValid="this.isInsCodeValid"
                v-bind:isRequired="true"                     
              />
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Insurance.EffectiveDate')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'effectiveDate'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="PatientInsurance.effectiveDate"
                v-bind:isRequired="true"
                v-bind:isReadOnly=this.isReadOnly>                                     
              </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
                 v-bind:Label="$t('Insurance.CoPay')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'CurrencyValue'"
                 v-bind:MaxLengthProp="11"
                 v-model="formattedCurrencyValue"
                 v-bind:isReadOnly=this.isReadOnly
                 v-on:blur="focusOut"
                 v-on:focus="focusIn"
                 v-bind:Id="'input_copay_id'">                                     
              </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
                 v-bind:Label="$t('Insurance.Discount')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'CurrencyValue1'"
                 v-model="formattedCurrencyValue1"
                 v-bind:isReadOnly=this.isReadOnly
                 v-on:blur="focusOut1"
                 v-on:focus="focusIn1"
                 v-bind:Id="'input_discount_id'">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
                 v-bind:Label="$t('Insurance.PolicyNo')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="20"
                 v-bind:Name="'insNo'"
                 v-model="PatientInsurance.insNo"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:isValid="this.isInsNoValid"
                 v-bind:isRequired="true"
                 v-bind:Id="'input_policyNo_id'">                                     
              </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
                 v-bind:Label="$t('Insurance.Coverage')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="25"
                 v-bind:Name="'coverage'"
                 v-model="PatientInsurance.coverage"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_coverage_id'">                                     
              </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
                 v-bind:Label="$t('Insurance.InsGroup')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="15"
                 v-bind:Name="'insGroup'"
                 v-model="PatientInsurance.insGroup"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_insgroup_id'">                                     
              </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
                 v-bind:Label="$t('Insurance.InsuranceGroupName')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="30"
                 v-bind:Name="'insGroupName'"
                 v-model="PatientInsurance.insGroupName"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_insgroupname_id'">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Insurance.InsuranceCategory')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="4"
                 v-bind:Name="'InsuranceCategory'"
                 v-model="InsuranceCategory"
                 v-bind:isReadOnly='true'
                 v-bind:Id="'input_category_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdSelect
                v-bind:Label="$t('Insurance.InsType')"
                v-bind:Options="InsuranceTypesList"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'code'"
                v-bind:Model="PatientInsurance.insType"
                v-bind:Multiple="false"
                :IsDisabled="this.isReadOnly"
                :Input="ChangeDropDownInsType"
                v-bind:isRequired="true"
                v-bind:isValid="this.isInsTypeValid"
                v-bind:FeedBackText="$t('Insurance.InsuranceTypeRequired')"/>
            </b-col>
            <b-col md="3">
                <MdLookup
                  v-bind:Label="$t('Insurance.MedicareInsType')"
                  v-bind:ModelCode="Insurance.medicareType"
                  v-bind:ModelDescription="MedicareInsuranceType.description"
                  v-bind:RefCode="'MedicareInsureType'"
                  v-bind:Api="'InsuranceMedicareTypes'"
                  v-bind:FieldCode="'code'"
                  :IsDisabled="this.isReadOnly"    
                  :SearchTitle="'Insurances'"
                  :GetValues="getMedicareInsuranceTypeValues"
                  :InvalidFeedback="$t('Insurance.InvalidMedicareCode')"
                  :SearchFieldCode="'Code'"                     
                />
            </b-col>
            <b-col md="3">
              <MdSelect
                v-bind:Label="$t('Shared.Relation')"
                v-bind:Options="PatientInsuranceRelations"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'code'"
                v-bind:Model="PatientInsurance.relation"
                v-bind:Multiple="false"
                :IsDisabled="this.isReadOnly"
                :Input="ChangeDropDownRelation"
                v-bind:isRequired="true"
                v-bind:isValid="this.isRelationValid"
                v-bind:FeedBackText="$t('Insurance.InsuranceRelationRequired')"/>
            </b-col>
          </b-row>
          <MdTitle v-bind:Text="$t('Insurance.InsuredInformation')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-col md="3">
             <MdCheckBox
            v-bind:Label="$t('Insurance.UsePatientInfo')"
            v-bind:Name="'checkbox-1'"
            v-model="checkedUsePatientInfo"
            v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
            v-on:change="usePatientInfo"
          />
          </b-col>
          <b-row class="mt-3">
            <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.FirstName')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="50"
                 v-bind:Name="'insFirstname'"
                 v-model="PatientInsurance.insFirstname"
                 v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                 v-bind:Id="'input_insfirstname_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Insurance.Initial')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="1"
                 v-bind:Name="'initial'"
                 v-model="PatientInsurance.initial"
                 v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                 v-bind:Id="'input_initial_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.LastName')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="50"
                 v-bind:Name="'insLastname'"
                 v-model="PatientInsurance.insLastname"
                 v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                 v-bind:Id="'input_inslastname_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.Dob')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'dob'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="PatientInsurance.dob"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.DayPhone')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="15"
                 v-bind:Name="'workPhone'"
                 v-model="PatientInsurance.workPhone"
                 v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                 v-bind:Id="'input_insdayphone_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.NightPhone')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="15"
                 v-bind:Name="'homePhone'"
                 v-model="PatientInsurance.homePhone"
                 v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                 v-bind:Id="'input_insnightphone_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
               <MdEdit 
                 v-bind:Label="$t('Shared.Address1')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="30"
                 v-bind:Name="'address1'"
                 v-model="PatientInsurance.address1"
                 v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                 v-bind:Id="'input_insaddr1_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
               <MdEdit 
                 v-bind:Label="$t('Shared.Address2')"
                 v-bind:TypeProp="'text'" 
                 v-bind:MaxLengthProp="30"
                 v-bind:Name="'address2'"
                 v-model="PatientInsurance.address2"
                 v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                 v-bind:Id="'input_insdayphone_id'">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.Zip')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'zip'"
                v-bind:MaxLengthProp="10"
                v-model="PatientInsurance.zip"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-on:blur="patientLegalZipBlur(PatientInsurance.zip)"
                v-on:change="patientLegalZipChange($event)"
                v-bind:Id="'input_inszip_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.City')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'city'"
                v-bind:MaxLengthProp="2"
                v-model="PatientInsurance.city"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_indcity_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.State')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'state'"
                v-bind:MaxLengthProp="2"
                v-model="PatientInsurance.state"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_insState_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.Country')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'country'"
                v-bind:MaxLengthProp="25"
                v-model="PatientInsurance.country"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_inscountry_id'">                                     
              </MdEdit>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
               <MdEdit 
                v-bind:Label="$t('Insurance.PlaceOfEmployment')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'employment'"
                v-bind:MaxLengthProp="30"
                v-model="PatientInsurance.employment"
                v-bind:isReadOnly="this.enabledInsuredChecked || this.isReadOnly"
                v-bind:Id="'input_insEmploy_id'">                                     
              </MdEdit>
            </b-col>
            <b-col md="3">
              <MdSelect
                v-bind:Label="$t('Shared.Gender')"
                v-bind:Options="sex_list"
                v-bind:FieldDescription="'text'"
                v-bind:FieldCode="'value'"
                v-bind:Model="PatientInsurance.sex"
                v-bind:Multiple="false"
                :IsDisabled="this.enabledInsuredChecked || this.isReadOnly"
                :Input="ChangeDropDownSex"/>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    </b-modal>
  </div>
</template>

<script>

import EnumService from "@/services/enum.service";
import InsuranceService from "@/services/insurance.service";
import ShowMessage from "@/components/messages/ShowMessage.js";
import DBFunctions from '@/common/DBFunctions.js'
import Utils from "@/common/utils";
import PatientService from '@/services/patient.service';
import ExternalAPI from '@/services/external.service'
import { EventBus } from '@/event-bus.js';
export default {
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPIn = await vm.$store.state.isUsePatientInsurance;
            if(iPIn) next()
            else next('/accessdenied');
        }) 
  },

  name: "Insurance",
  mixins: [ShowMessage, DBFunctions],
  props: {
     ModalId: String,
     PatientCode:String,
     TitleWindow: String,
     NotOpenByDefault:Boolean,
     ForUsefromMars:Boolean,
     MarsParam:Object
   },
  data() {
    return {
      ShowMessageIsEmpty: false,
      Loading: true,
      
     // ModelStatus:{'value':'A','name':'Active'},
      ModelInsuranceType:{'code':'','description':''},
      OptionsRightMenu: {
        visible: true,
        ItemsList: [],
      },
      PatientId: "",
      PatientCode1: "",
      InsuranceCode: "",
      Mode: "",
      parameter: {
        SearchCriteria: "",
        PageNumber: 0,
        PageSize: 0,
        PatientCode: "",
      },
      fields: [
        {
          key: "insuranceTypeDescription",
          sortable: true,
          label: this.$t('Insurance.colInsuranceType'),
          class: "text-rigth",
        },
        { key: "insuranceName", sortable: true, label: this.$t('Insurance.colInsuranceName'), class: "text-rigth" },
        { key: "insuranceCode", sortable: true, label: this.$t('Insurance.InsuranceCode') },
        { key: "effectiveDate", sortable: true, label: this.$t('Insurance.EffectiveDate'), class: "text-rigth" },
        { key: "coPay", sortable: true, label: this.$t('Insurance.CoPay'), class: "text-rigth" },
        { key: "policyNo", sortable: true, label: this.$t('Insurance.PolicyNo'), class: "text-rigth" },
      ],
      pageOptions: [5, 10, 15],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header: {},
      isReadOnly: false,
      isListing: true,
      isEditMode:false,
      // var use in Insurance Add/Edit
      sex_list: EnumService.Sexs,
      counter: 0,
      MedicareInsuranceType: { "description": '' },
      ActiveReleaseOnFile: EnumService.YesNoOptions,
      YesNoOptionsNumericThree: EnumService.YesNoOptionsNumericThree,
      YesNoOptionsNumericTwo: EnumService.YesNoOptionsNumericTwo,
      options: [
        { text: this.$t('Shared.Male'), value: "M" },
        { text: this.$t('Shared.Female'), value: "F" },
      ],
      PatientInsuranceRelations: [],
      InsuranceMedicareTypes: {},
      Insurance: {},
      //PatientCode: this.PatientCode,
      ResourceParameter: "",
      FieldsParameter: [],
      TypeOption: "",
      PatientInsurance: {
                        },
      InsuranceCategory: '',
      InsuranceTypesList: [],
      formattedCurrencyValue: "$ 0.00",
      formattedCurrencyValue1: "$ 0.00",
      PatientInsuranceAll: {},
      parameterInsuranceAll: {
        PatientCode: this.PatientCode
      },
      lookupFieldChanged: false,
      isInsTypeValid: true,
      isRelationValid: true,
      isInsCodeValid: true,
      isInsNoValid: true,
      enabledInsuredChecked: true,
      checkedUsePatientInfo: true,
      patient: {},
      patientLegalZipChanged: false,
      shippingData: [],
      editMode: false,
      pendingChanges: false,
      clickOK: false
    };
  },

  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  methods: {
    emitEventOK(){      
        this.clickOK = true
      },

      emitEventHide(handled){
        if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Insurance.Insurance'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
              if(this.ForUsefromMars){
                  let param = {
                     info:this.getTextResult(),
                     MarsParam:this.MarsParam 
                  }
                  this.$emit('getCodeConditionOK', param); 
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';   
                }else{
                  this.$emit('getCodeConditionOK', this.getTextResult());
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';
                }
               
            } else {
                this.$emit('getCancel');
                this.ResetProps();
            }
        }
      },

      ResetProps(){
        this.editMode = false;
        this.parameter.SearchCriteria = '';
        this.mountInsuranceEdit();
        this.isEditMode = false;
        this.isListing = true;
      },

      getTextResult() {
        let textResult = '';
        let insuranceName = '';
        this.items.map(item => (
            insuranceName = item.insuranceName ? item.insuranceName: '',
            textResult = textResult + ' '+this.$tc('Insurance.Insurance')+': '+ insuranceName+' '+this.$t('Shared.Type')+': '+item.insuranceTypeDescription +' '+ this.$t('Insurance.PolicyNo')+': '+item.policyNo + ' '+ this.$t('Shared.Relation')+': '+item.relation +"\n"
        ))
        return textResult
      },

    SearchInsurance() {
      this.Loading = true;
      this.$emit("load", this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getInsurance();
    },

    OnUpdateInsuranceList() {
      this.getInsurance();
    },

      getInsuranceTypesList() {
      this.InsuranceTypesList = [];
      InsuranceService.getPatientInsuranceTypes()
        .then((response) => {
          if (response.data) {
            this.InsuranceTypesList = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
          }
        });
    },

    getInsurance() {
      this.parameter.PageNumber = this.currentPage;
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.PatientCode;
      this.parameter.Type = this.InsuranceTypeCode;
      InsuranceService.getAllPatientInsurances(this.parameter)
        .then((response) => {
          if (response.status == "200" && response.data && response.data.length > 0) {
            this.items = response.data;
            this.items.forEach(function (element) {
              if (element.effectiveDate)
                element.effectiveDate = new Date(
                  element.effectiveDate
                ).toLocaleDateString();
            });
            this.Loading = false;
            this.$emit("load", this.Loading);
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
          }
          this.Loading = false;
          this.$emit("load", this.Loading);
          if (this.items.length === 0) {
              this.ShowMessageIsEmpty = true;
          }
        })
        .catch((error) => {
          this.Loading = false;
          this.$emit("load", this.Loading);
          if (!error.response) {
            // network error
            this.showMessage(
              this.$tc('Insurance.Insurance'),
              this.$t('Msg.RetrieveInformation'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
          }
        });
    },

    AddInsurance() {
        this.editMode = false;
        this.OptionsRightMenu.visible = false;
        this.OptionsRightMenu.ItemsList = [];
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.mountInsuranceEdit();
        this.isEditMode = true;
        this.isListing = false;
    },

    EditInsurance(row) {
        this.editMode = true;
        this.OptionsRightMenu.visible = false;
        this.OptionsRightMenu.ItemsList = [];
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.mountInsuranceEdit(row.recId); 
        this.isEditMode = true;
        this.isListing = false;    
    },

    RemoveInsurance(row) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
          title: this.$t('InsurancesList.DeleteInsurance'),
          okTitle: this.$t('Shared.TitleDelete'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
        })
        .then((value) => {
          if (value) {
            InsuranceService.deletePatientInsurances(row.recId)
              .then(() => {
                const index = this.items.findIndex(item => item.recId === row.recId) // find the post index 
                if (~index) {// if the post exists in array 
                  this.items.splice(index, 1) //delete the post and refresh view
                  this.getInsurance(); //refresh listing
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.showMessage(
                    this.$tc('Insurance.Insurance'),
                    this.$t('Msg.NotAbleToDelete'),
                    "error"
                  );
                } else {
                  this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
                }
              });
          }
        });
    },

    onRowSelected(items) {
      this.selected = items;
    },

    pageChanged(page) {
      this.currentPage = page;
    },

    PerPageFunction(perpage) {
      this.perPage = perpage;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },

    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    //Methods use in Insurance Add/Edit
    getInsuranceCodeValues(value) {
      this.PatientInsurance.insCode = value.code;
      this.Insurance.company = value.description;
    },
    
    getMedicareInsuranceTypeValues(value) {
      this.PatientInsurance.medicareType = value.code;
      this.MedicareInsuranceType.code = value.code;
      this.MedicareInsuranceType.description = value.description;
    },

    ChangeDropDownInsType(value) {
      this.PatientInsurance.insType = value ? value.code : '';
    },

    ChangeDropDownInsTypeList(value){
      if(value !== null){
        this.ModelInsuranceType = value;
        this.InsuranceTypeCode = value.code
      }else{
        this.InsuranceTypeCode = '';
      }
      this.items = [];
      this.getInsurance();
    },
    
    ChangeDropDownRelation(value) {
      this.PatientInsurance.relation = value ? value.code : '';
      this.enabledInsuredChecked = (this.PatientInsurance.relation.indexOf("01") == 0);
      if (this.enabledInsuredChecked) {
        this.OnUpdateInsuredFieldsPatientInfo({});
        // insFirstname  initial   insLastname  dob employment  sex
        this.PatientInsurance.insFirstname = "";
        this.PatientInsurance.initial = "";
        this.PatientInsurance.insLastname = "";
        this.PatientInsurance.dob = null;
        this.PatientInsurance.employment = "";
        this.PatientInsurance.sex = "U";
        this.checkedUsePatientInfo = false;
      }
    },

    ChangeDropDownSex(value) {
      this.PatientInsurance.sex = value ? value.value : '';
    },

    getEnabledInsuredFields(result) {
      this.enabledInsuredChecked = (result.indexOf("01") < 0);
    },

    usePatientInfo() {
      this.checkedUsePatientInfo = !this.checkedUsePatientInfo;
      if (this.checkedUsePatientInfo) {
        this.checkedUsePatientInfo = !this.checkedUsePatientInfo;
        this.OnUpdateInsuredFieldsPatientInfo(this.patient);
      } else {
        this.checkedUsePatientInfo = !this.checkedUsePatientInfo;
        this.OnUpdateInsuredFieldsPatientInfo({});
      }

    },
    patientLegalZipChange: function() {
      this.patientLegalZipChanged = true;

    },
    patientLegalZipBlur: function(value) {
      if (this.patientLegalZipChanged) {
        ExternalAPI.getCityStateByZipCode(value)
          .then((response) => {
            this.shippingData = response;
            if (this.shippingData.City) {
              this.PatientInsurance.city = this.shippingData.City
              this.PatientInsurance.state = this.shippingData.State
            }
            this.patientLegalZipChanged = false;
          })
          .catch((error) => {
            this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
          });
      }
    },

    getPatient(id) {
      // Get patient data
      PatientService.get(id)
        .then((response) => {
          if (response.status == '200' && response.data) {
            this.patient = response.data;
            this.patient.dob = this.formatterDate(this.patient.dob);
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.showMessage(this.$tc('Shared.Patient'), this.$t('Msg.RetrieveInformation'), "error");
          } else {
            this.showMessage(this.$tc('Shared.Patient'), error, "error");
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        });
    },

    OnUpdateInsuredFieldsPatientInfo(result) {
      if (result) {
        this.PatientInsurance.address1 = result.address1;
        this.PatientInsurance.address2 = result.address2;
        this.PatientInsurance.city = result.city;
        this.PatientInsurance.country = result.country;
        this.PatientInsurance.homePhone = result.nightPhone;
        this.PatientInsurance.state = result.state;
        this.PatientInsurance.workPhone = result.dayPhone;
        this.PatientInsurance.zip = result.zip;
      }
    },

    lookupFieldChange: function() {
      this.lookupFieldChanged = true;
      this.isInsCodeInvalid = this.PatientInsurance.insCode && this.PatientInsurance.insCode.length > 0 ? false : true;
    },

    lookupNameByCode: function(control, value) {
      if (this.lookupFieldChanged) {
        this.getLookup(control, value);
        this.lookupFieldChanged = false;
      }
      this.isInsCodeInvalid = this.PatientInsurance.insCode && this.PatientInsurance.insCode.length > 0 ? false : true;
    },

    lookupEnterEvent: function(control, value) {
      this.lookupNameByCode(control, value);
    },

    onContextEffectiveDate(ctx) {
      this.formatted = ctx.selectedFormatted;
      this.selected = ctx.selectedYMD;
      if (ctx.selectedFormatted != "No date selected") {
        this.PatientInsurance.effectiveDate = ctx.selectedFormatted;
      }
    },

    focusOut() {
      this.PatientInsurance.paymentpercent = this.formattedCurrencyValue ? parseFloat(
        this.formattedCurrencyValue.toString().replace("$ ", "")) : null;
      if (isNaN(this.PatientInsurance.paymentpercent)) {
        this.PatientInsurance.paymentpercent = 0;
      }
      this.formattedCurrencyValue = this.formattedCurrencyValue ?
        "$" +
        this.PatientInsurance.paymentpercent
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") : null;
    },

    focusIn() {
      this.formattedCurrencyValue = this.PatientInsurance.paymentpercent;
    },

    focusOut1() {
      this.PatientInsurance.discount = this.formattedCurrencyValue1 ? parseFloat(
        this.formattedCurrencyValue1.replace("$ ", "")
      ): null;
      if (isNaN(this.PatientInsurance.discount)) {
        this.PatientInsurance.discount = 0;
      }
      this.formattedCurrencyValue1 = this.formattedCurrencyValue1 ?
        "$ " +
        this.PatientInsurance.discount
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"): null;
    },

    focusIn1() {
      this.formattedCurrencyValue1 = this.PatientInsurance.discount;
    },

    getMedicareInsuranceType() {
      if (this.PatientInsurance.medicareType) {
        InsuranceService.getInsuranceMedicareTypesById(this.PatientInsurance.medicareType)
          .then((response) => {
            if (response.data) {
              this.MedicareInsuranceType.code = this.PatientInsurance.medicareType;
              this.MedicareInsuranceType.description = response.data.description;
            }
          })
          .catch((error) => {
            if (!error.response) {
              // network error
              this.showMessage(
                this.$tc('Insurance.Insurance'),
                this.$t('Msg.RetrieveInformation'),
                "error"
              );
            } else {
              this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
            }
          });
      }
    },

    SelfPay() {
      this.PatientInsurance.insCode = "SELF";
      this.PatientInsurance.relation = "01";
      this.PatientInsurance.insType = "P";
      this.PatientInsurance.insNo = "SELF";
      this.PatientInsurance.catcode = "SELF";
      this.PatientInsurance.paymentpercent = 0;
      this.isInsTypeInvalid = false;
      this.isRelationInvalid = false;
      this.isInsCodeInvalid = false;
    },


    getPatientInsuranceID() {
      InsuranceService.getPatientInsurancesById(this.InsuranceCodeTable)
        .then((response) => {
          if (response.data) {
            this.PatientInsurance = response.data;
            this.getInsuranceId(this.PatientInsurance.insCode);
            this.PatientInsurance.effectiveDate = this.formatterDate(
              this.PatientInsurance.effectiveDate
            );
            this.PatientInsurance.dob = this.formatterDate(
              this.PatientInsurance.dob
            );
            this.formattedCurrencyValue1 = this.PatientInsurance.discount
              ? "$ " + this.PatientInsurance.discount
              : "";

            this.formattedCurrencyValue = this.PatientInsurance.paymentpercent
              ? "$ " + this.PatientInsurance.paymentpercent
              : "";
            this.focusOut();
            this.focusOut1();

            this.enabledInsuredChecked = (this.PatientInsurance.relation.indexOf("01") == 0);
            this.checkedUsePatientInfo = this.enabledInsuredChecked;
            if (this.checkedUsePatientInfo) {
              this.OnUpdateInsuredFieldsPatientInfo({});
            }
            if (this.editMode) {
              this.getMedicareInsuranceType();
            }
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.showMessage(
              this.$ct('Insurance.Insurance'),
              this.$t('Msg.RetrieveInformation'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
          }
        });
    },

    //Obtener El Nombre de la Categoria de Insurance del Paciente
    getInsuranceCategoryList() {
      if (this.PatientInsurance.catcode) {
        InsuranceService.getInsuranceCategoryById(this.PatientInsurance.catcode)
          .then((response) => {
            if (response.data) {
              this.InsuranceCategoryList = response.data;
            }
            this.Loading = false;
            this.$emit("load", this.Loading);
          })
          .catch((error) => {
            if (error.response) {
              this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
            }
          });
      }
    },

    //COMBO -- Obtener Patients Insurance Relations
    getPatientInsuranceRelationsList() {
      InsuranceService.getPatientInsuranceRelations()
        .then((response) => {
          if (response.data) {
            this.PatientInsuranceRelations = response.data;
          }
          this.Loading = false;
          this.$emit("load", this.Loading);
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$ct('Insurance.Insurance'), error, "error");
          }
        });
    },

    //COMBO -- Obtener Patients Insurance Relations


    getInsuranceId(idInsurance) {
      InsuranceService.getInsuranceByCode(idInsurance)
        .then((response) => {
          if (response.status == '200' && response.data) {
            this.Insurance = response.data;
            this.PatientInsurance.catcode = this.Insurance.category;
            this.InsuranceCategory = this.Insurance.categoryDescription;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.showMessage(
              this.$tc('Insurance.Insurance'),
              this.$t('Msg.RetrieveInformation'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance'), idInsurance + ' '+ this.$t('Insurance.NotValidInsuranceCode'), "error");
          }
        });
    },

    CreateInsurance() {
      this.PatientInsurance.patientcode = this.PatientCode;
      InsuranceService.addPatientInsurances(this.PatientInsurance)
        .then(() => {
          this.showMessage(this.$t('Insurance.SavingInsurance'), this.$t('Insurance.InsuranceSavedSuccessfully'), "success");
          this.$emit('load', false);
          this.getInsurance();
          this.isEditMode = false;
          this.isListing = true;
        })
        .catch((error) => {
          if (!error.response) {
            this.showMessage(
              this.$tc('Insurance.Insurance'),
              this.$t('Msg.NotAbleToInsert'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance'), error.response.data.errorMessage, "error");
          }
        });
    },

    UpdateInsurance() {
      InsuranceService.updatePatientInsurances(
        this.InsuranceCodeTable,
        this.PatientInsurance
      )
        .then((response) => {
          if (response.status == '200' && response.data) {
            this.showMessage(this.$t('Insurance.SavingInsurance'), this.$t('Insurance.InsuranceSavedSuccessfully'), "success");
            this.$emit('load', false);
            this.DiscardChanges();
          }
        })
        .catch((error) => {
          if (!error.response) {
            this.showMessage(
              this.$tc('Insurance.Insurance'),
              this.$t('Msg.NotAbleToUpdate'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance'), error.response.data.errorMessage, "error");
          }
        });
    },

    EmitUpdateInsuranceList() {
      this.$emit("UpdateInsuranceList", {});
    },

    SaveOrEdit() {
      if (this.validate_required()) {
        this.pendingChanges = false 
        if (this.editMode) {
          this.UpdateInsurance();
        } else {
          this.CreateInsurance();
        }
      } else {
        this.showMessage(this.$tc('Insurance.Insurance'),  this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
      }
    },

    DiscardChanges() {
      if(this.pendingChanges){
          this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Insurance.Insurance'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.pendingChanges = false
              this.getInsurance();
              this.isEditMode = false;
              this.isListing = true;
            }
          })
        } else {
          this.getInsurance();
          this.isEditMode = false;
          this.isListing = true;
        }
    },

    EnabledItemRightMenu() {
      this.OptionsRightMenu.PatientCode = this.PatientCode;
      this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
      this.OptionsRightMenu.visible = true;
      this.OptionsRightMenu.itemdisabled = false;
      this.$emit("getVisibleMenu", this.OptionsRightMenu);
    },

    EnableBtnRightMenu() {
      this.OptionsRightMenu.btnvisible = true;
      this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
      this.OptionsRightMenu.Mode = this.edit_mode;
      this.OptionsRightMenu.btnvisible = true;
      this.$emit("getBtnRightMenu", this.OptionsRightMenu);
    },

    formatterDate(value) {
      return Utils.formatterDate(value);
    },

    formatterDateToString(date) {
      return Utils.formatterDateToString(date);
    },

    validate_required() {
      this.isInsTypeValid = this.PatientInsurance.insType && this.PatientInsurance.insType.length > 0 ? true : false
      this.isRelationValid = this.PatientInsurance.relation && this.PatientInsurance.relation.length > 0 ? true : false
      this.isInsCodeValid = this.PatientInsurance.insCode && this.PatientInsurance.insCode.length > 0 ? true : false
      this.isInsNoValid = this. PatientInsurance.insNo && this.PatientInsurance.insNo.length > 0 ? true : false
      
      if (!this.isInsTypeValid || !this.isRelationValid || !this.isInsCodeValid || !this.isInsNoValid ) {
        return false;
      }
      return true;
    },

    mountInsuranceEdit(recId) {
      this.EnabledItemRightMenu();
      this.EnableBtnRightMenu();      
      this.getPatientInsuranceRelationsList();
      this.getInsuranceTypesList();
      this.getPatient(this.PatientCode);
      if (this.editMode) {
        this.InsuranceCodeTable = recId;
        this.getPatientInsuranceID();
        this.isReadOnly = this.$store.state.isPatientInsuranceEHRReadOnly;
      } else {
        this.PatientInsurance = {};
        this.InsuranceCodeTable = "";
        this.enabledInsuredChecked = true;
        this.checkedUsePatientInfo = false;
      }
    },

    beforeWindowUnload(e) {
       if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
  },

  mounted() {
    this.getInsuranceTypesList();
    this.$emit("load", this.Loading);
    this.getInsurance();
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);
    this.isReadOnly = this.$store.state.isPatientInsuranceEHRReadOnly;
  },

  destroyed() {
    this.OptionsRightMenu.ItemsList = [];
    this.OptionsRightMenu.Mode = false;
    this.OptionsRightMenu.visible = false;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  }

</script>