<template>
   <div id="marsList">
    <div class="row">
        <div class="col-12">
          <MdTitle v-bind:Text="$t('Nurses.marsList')" />  

         
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input type="text" class="form-control" v-model="parameter.SearchCriteria"
                  @keyup.enter="SearchPatients">

                <div class="input-group-append">
                  <MdButton v-bind:Name="'btnsearchinput'" v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'" v-on:click="SearchPatients">
                  </MdButton>
                </div>
              </div>
            </div>
      

            <b-row>
                <b-col lg="3" md="3" sm="12">
                    <MdEdit 
                        v-bind:Label="$t('Nurses.DateTimeRange')"                      
                        v-bind:TypeProp="'text'" 
                        v-bind:Mask="'##/##/#### ##:## AA'"
                        v-bind:Min="'1900-01-01'"
                        v-bind:OnPressEnter="getListing"
                        v-bind:Max="'2200-12-31'"                         
                        v-model="DateFrom"/>          
                </b-col>
                <b-col lg="3" md="3" sm="12">
                    <MdEdit 
                    v-bind:Label="$t('Nurses.DateTimeRange')"                      
                    v-bind:TypeProp="'text'" 
                    v-bind:Mask="'##/##/#### ##:## AA'"
                    v-bind:OnPressEnter="getListing"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"                         
                    v-model="DateTo"/>  
                </b-col>
                <b-col lg="3" md="3" sm="12">
                    <MdSelect
                    v-bind:Label="$tc('Shared.Program',2)"
                    v-bind:Options="ProgramList"
                    v-bind:Model="ProgramVmodel"
                    v-bind:FieldDescription="'name'"
                    v-bind:FieldCode="'code'"
                    v-bind:Placeholder="selectPlaceholder"
                    v-bind:Multiple="false"
                    v-bind:RequireEvent="true"
                    v-bind:IfUsevalidation="false"
                    :Input="ChangeDropDownPrograms"/>
                </b-col>
                <b-col lg="3" md="3" sm="12">
                    <MdSelect
                        v-bind:Label="$tc('Shared.Patient',2)"
                        v-bind:Options="PatientList"
                        v-bind:Model="Patients"
                        v-bind:FieldDescription="'patientname'"
                        v-bind:FieldCode="'patientcode'"
                        v-bind:Multiple="false"                       
                        :Input="ChangeDropDownPatients"/>                  
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="3" md="3" sm="12">
                    <MdSelect
                        v-bind:Label="$t('Shared.Building')"
                        v-bind:Options="BuildinsList"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'code'"
                        v-bind:Model="buildings"   
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownBuilding"/>
                </b-col>  
                <b-col lg="3" md="3" sm="12">
                    <MdSelect
                        v-bind:Label="$t('Shared.Level')"
                        v-bind:Options="LevelList"
                        v-bind:FieldDescription="'description'"
                        v-bind:FieldCode="'code'"
                        v-bind:Model="level"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownLevel"/>
                </b-col>
                <b-col lg="3" md="3" sm="12">                  
                    <MdSelect
                        v-bind:Label="$t('Shared.Room')"
                        v-bind:Options="RoomList"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'code'" 
                        v-bind:UseResetModel="true"                 
                        v-bind:Model="room"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownRoom"/>
                </b-col>                
            </b-row>
            <b-row>
                <b-col lg="12" md="12" sm="12">
                    <div class="mt-3 text-right">
                        <button 
                            v-on:click="SearchPatients"
                            type="button"
                            class="btn btn-primary btn-login mx-2"												
                            id="btnsearchinput">
                            {{ $t('Shared.Search') }}
                        </button>
                    </div>
                </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
           
       
            <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite"><div class="text-center my-2">{{$t('MdTable.nRecShow')}}</div></div>
        
            <div class="container-mars">
                <div class="card-container">

                    <div class="card" v-for="item in items" :key="item.patientCode">
                        <div class="card-body overdue-card">
                            <div class="row">
                                <div class="col-md-4 photo-container">
                                    <img v-on:click="ViewPatientMed(item)" v-if="item.patientPhoto !== null && item.patientPhoto !== ''" v-bind:src="'data:image/'+'.'+item.patientPhotoExtension+';base64,'+item.patientPhoto" alt="Foto" class="photo img-fluid">                                    
                                    <img v-on:click="ViewPatientMed(item)" v-if="(item.patientPhoto === null || item.patientPhoto === '') && item.sex === 'Male'" src="@/assets/images/faces/nopic-male.jpg" alt="Foto" class="photo img-fluid">
                                    <img v-on:click="ViewPatientMed(item)" v-if="(item.patientPhoto === null || item.patientPhoto === '') && item.sex === 'Female'" src="@/assets/images/faces/nopic-female.jpg" alt="Foto" class="photo img-fluid">
                                    <span class="d-flex justify-content-center uId" v-b-tooltip.hover :title="$t('Nurses.PatientID')">{{item.patientCode}}</span>
                                </div>
                                <div class="col-md-8 cContent">
                                    <h5 v-on:click="ViewPatientMed(item)"
                                        class="card-title"
                                        :class="{
                                            'overdue': item.administrationStatus === '1_OVER_DUE',
                                            'close-to-admin': item.administrationStatus === '2_CLOSE_TO_ADM',
                                            'prior-to-admin': item.administrationStatus === '3_PRIOR_TO_ADM',
                                            'no-pending': item.administrationStatus === '4_NO_PEND_ADM'
                                            }">{{item.patientName}}
                                        
                                        <i
                                        class="ikon float-right mr-1 text-white "
                                        :class="{
                                            'med-adm-overdue': item.administrationStatus === '1_OVER_DUE',
                                            'med-adm-close': item.administrationStatus === '2_CLOSE_TO_ADM',
                                            'med-adm-prior': item.administrationStatus === '3_PRIOR_TO_ADM',
                                            'med-adm-not-pending': item.administrationStatus === '4_NO_PEND_ADM'
                                            }"
                                        
                                        v-b-tooltip.hover :title="$t('Nurses.Status')+': ' + item.administrationStatusTranslated">
                                        
                                        <!-- PLEASE, ADD THESE STATUSES TO THE TITLE ABOVE:
                                             - Over due administration
                                             - Close to administering
                                             - Prior to administering
                                             - No pending administering
                                        -->
                                        
                                        </i>
                                    </h5>
                                    <p class="card-text"><i class="fa fa-birthday-cake icon" v-b-tooltip.hover :title="$t('Nurses.DateOfBirth')"></i><span v-if="item.dob">{{ item.dob }}</span><span v-else>- - / - - / - - - -</span></p>
                                    <p class="card-text text-truncate"><i class="fa fa-users icon" v-b-tooltip.hover :title="$t('Nurses.Program')+': ' + item.programName"></i>{{item.programName}}</p>
                                    <p class="card-text text-truncate">
                                        <i class="fa fa-building icon" v-b-tooltip.hover :title="$t('Nurses.Building')+': ' + item.buildingName"></i><span v-if="item.buildingName">{{ item.buildingName }}</span><span v-else>Not defined</span>                                        
                                    </p>
                                    <p class="card-text text-truncate">
                                        <i class="fa fa-door-open icon" v-b-tooltip.hover :title="$t('Nurses.Room')+': ' + item.roomName"></i><span v-if="item.roomName">{{item.roomName}}</span><span v-else>Not defined</span>
                                    </p>
                                    <p class="card-text">
                                        <i class="fa fa-arrow-up icon" v-b-tooltip.hover :title="$t('Nurses.Floor')"></i><span v-if="item.roomLevel">{{item.roomLevel}}</span><span v-else>-</span>
                                        <i class="fa fa-bed icon" v-b-tooltip.hover :title="$t('Nurses.Bed')"></i><span v-if="item.bedName">{{item.bedName}}</span><span v-else>-</span>
                                    </p>
                                </div>                                
                            </div>
                            <!--<div class="row">
                                <div class="col-12">
                                    <p class="card-text estado prior-to-admin" v-if="item.administrationStatus === 'PRIOR_TO_ADM'">{{item.administrationStatusTranslated}}</p>
                                    <p class="card-text estado overdue text-center" v-if="item.administrationStatus === 'OVER_DUE'">{{item.administrationStatusTranslated}}</p>                              
                                    <p class="card-text estado no-pending" v-if="item.administrationStatus === 'NO_PEND_ADM'">{{item.administrationStatusTranslated}}</p>
                                    <p class="card-text estado close-to-admin" v-if="item.administrationStatus === 'CLOSE_TO_ADM'">{{item.administrationStatusTranslated}}</p>
                                </div>
                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            

            <hr/>
            <MarsMedicationModal  
                v-if="VisibleMed" 
                @ModalTPMarsMed="ModalTPMarsMed"              
                v-bind:PatientCode="PatientCodeSelected"
                v-bind:DateFrom="DateFrom"
                v-bind:DateTo="DateTo"
                v-bind:PhotoObj="PhotoObj"
                v-bind:ModalId="'modalMarsMed'"
                v-bind:TitleWindow="$t('Nurses.MarsMedication')">
            </MarsMedicationModal>
            <div class="row mt-4">
              <div class="my-1 col-sm-7 col-md-6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  @change="onChange"
                  pills
                  size="lg"
                ></b-pagination>
              </div>
              <div align="right" class="mr-2 col">
                {{ $t('Shared.Show') }}
                <b-form-select 
                  v-model="perPage" 
                  :options="options" 
                  size="sm" 
                  id="perPageSelect"
                  v-bind:style="perPageStyle"
                  @change="perPageChange">
                </b-form-select>
                {{ $t('Shared.Of') }} {{totalRows}}
              </div>
            </div>




        </div>
    </div>
   </div>
</template>

<script>
 import EnumService from '@/services/enum.service'
 import GlobalService from '@/services/global.service' 
 import Utils from "@/common/utils";
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import ClinicalService from '@/services/clinical.service'
 import AdmissionService from '@/services/admission.service'
 import MarsService from '@/services/mars.service'
 import PatientService from '@/services/patient.service'
 import LocalStorage from '@/services/storage.service'
 import { EventBus } from '@/event-bus.js';
 import UserPreference from "@/services/quickhelp.service";
 import moment from 'moment'

export default {
 mixins: [ShowMessage],
  name: 'MarsList',

  data(){
    return{
        sex:"",
        PhotoObj:{patientPhoto:"",patientPhotoExtension:"",dob:null,patientName:"",sex:"",marsAlerts:"",},
        UserId: LocalStorage.getUserId(),
        VisibleMed:false,
        PatientCodeSelected:"",
        OptionsRightMenu:{
            visible:false,
            btnvisible:false,
            ItemsList:[],
            favButtonAtion: false
        },
        items:[],
        RoomList:[],
        ShowMessageIsEmpty:false,
        showEmptyRecords: false,    
      perPage: 12,      
      totalRows: 0, 
      currentPage: 1,   
      Header:{},
      ProgramVmodel:[],
      ProgramList:[],
      selectPlaceholder: this.$t('Clinical.SelectOptions'),
      FieldsDateArr:['dob'],
      fields: [
        { key: 'dob', sortable: true, label: this.$t('Shared.PatientName'), class: 'text-rigth' },         
        { key: 'building', sortable: true, label: this.$t('PatientsList.colNickname'), class: 'text-rigth' },
        { key: 'room', sortable: true, label: this.$t('Shared.PatientCode'), class: 'text-rigth' }, 
        ], 
     // pageOptions: [4, 12, 40 ],
      perPageStyle: {
        width: 'auto'
      },
      options: [
          { value: 4, text: '4' },
          { value: 12, text: '12' },
          { value: 40, text: '40' },
      ],
      parameter:{  
        DateTimeFrom:null,
        DateTimeTo:null,       
        PatientList:[],
        LevelList:[],
        BuildingList:[],
        RoomList:[],
        ProgramList:[],
        SearchCriteria:null,
        PageSize:10,
        PageNumber:1
      },
      fullParam: {
        PageSize:0,
        PageNumber:0
        },
        ProgramCode:"",
        dProgram:"",
        buildings:"",
        room:"",
        droom:"",
        level:"",
        BuildinsList:[],
        BuildingSelected:[],
        LevelList:[],
        Patients:"",
        PatientList:[],
        PatientCode:"",
        DateFrom:null,
        DateTo:null,
        BuildingAuxFields:['buildingName','buildingcode','bedAvailable'],
        BaseFields:[
            { key: 'name', sortable: true, label: this.$t('Shared.FirstName') },
            { key: 'code', sortable: true, label: this.$t('Shared.Code')},
            { key: 'inPatient', sortable: true, label: this.$t('Shared.Inpatient')},
            { key: 'isInPatient', sortable: true, label: this.$t('Shared.IsInPatient')}
          ],
        FieldsRooms:[
         { key: 'name', sortable: true, label: this.$t('Admission.FieldsRoomsName') },
         { key: 'code', sortable: true, label: this.$t('Shared.Code') },
         { key: 'buildingName', sortable: true, label: this.$t('Shared.BuildingName') },
         { key: 'capacity', sortable: true, label: this.$t('Shared.Capacity') },
         { key: 'bedAvailable', sortable: true, label: this.$t('Admission.FieldsRoomsBedAvailable') },
         { key: 'isPrivateRoom', sortable: true, label: this.$t('Admission.FieldsRoomsPrivate') },
         
         ],
         emptyRecordsStyle: {
            backgroundColor: '#CF0',
            fontWeight: 'bold !important',
            textTransform: 'uppercase',
            padding: '2px',
            borderRadius: '50px',
            animation: 'fadeOut 10s ease-in-out infinite'
        },
    }
  }, 
  
  methods:{
    onCodeConditionOk(){

    },

    ModalTPMarsMed(){
        this.VisibleMed = false;
    },

    async ChangeDropDownBuilding(item){
        this.parameter.BuildingList = [];
        item ? this.parameter.BuildingList.push(item.code) : this.parameter.BuildingList = [];
       /* if(item.length > 0)  {
            this.buildings = item;
            let selectedBuilds = [];
            item.forEach(function(item) {   
                selectedBuilds.push(item.code.trim());               
            });
            this.parameter.BuildingList = selectedBuilds;
        } else {
            this.buildings = null;
            EventBus.$emit('OnResetModelSelect');   
            this.RoomList = [];
            this.room = [];         
            this.parameter.BuildingList = null
      } */ 
     await this.getRomms({BuildingCodeList:this.parameter.BuildingList});
     await this.UpdateBuildingPreference(item ? item.code : null);
     this.parameter.RoomList = [];
     this.room ? this.parameter.RoomList.push(this.room) : this.parameter.RoomList;

     this.totalRows = 0;
     this.getListing();    
    }, 

    ViewPatientMed(item){  
        console.log(item); 
        this.PhotoObj.patientPhoto = item.patientPhoto;
        this.PhotoObj.patientPhotoExtension = item.patientPhotoExtension;
        this.PhotoObj.dob = item.dob;
        this.PhotoObj.weight = item.weight;
        this.PhotoObj.patientName = item.patientName;
        this.PhotoObj.programName = item.programName;
        this.PhotoObj.sex = item.sex;
        this.PhotoObj.ageFormatted = item.ageFormatted;
        this.PhotoObj.marsAlerts = item.marsAlerts;
        this.PhotoObj.allergies = item.allergies;
        this.PhotoObj.heights = item.heights;
        this.PhotoObj.specialPrecautions = item.specialPrecautions;       
        this.PatientCodeSelected = item.patientCode;
        this.VisibleMed = true;
    },
    
    ChangeDropDownRoom(item){
        this.parameter.RoomList = [];
        item ? this.parameter.RoomList.push(item.code) : this.parameter.RoomList = [];
        /*if(item.length > 0)  {
            this.room = item;
            let selectedrooms = [];
            item.forEach(function(item) {   
                selectedrooms.push(item.code.trim());               
            });
            this.parameter.RoomList = selectedrooms;
        } else {
            this.room = null;
            this.parameter.RoomList = null
      } */
      this.totalRows = 0; 
      this.getListing();
    },
    
   async ChangeDropDownLevel(item){
        this.parameter.LevelList = [];
        item ? this.parameter.LevelList.push(item.code) : this.parameter.LevelList = [];
        /*if(item.length > 0)  {
            this.Patients = item;
            let selectedlevels = [];
            item.forEach(function(item) {   
                selectedlevels.push(item.code.trim());               
            });
            this.parameter.LevelList = selectedlevels
        } else {
            this.PatientsModel = null;
            this.parameter.LevelList = null
      }  */
      await this.UpdateLevelPreference(item ? item.code : null);
      this.totalRows = 0;
      this.getListing();
    },
    ChangeDropDownPatients(item){
        this.parameter.PatientList = [];
        item ? this.parameter.PatientList.push(item.patientcode) : this.parameter.PatientList = [];
       /* if(item.length > 0)  {
            this.Patients = item;
            let selectedPatients = [];
            item.forEach(function(item) {   
            selectedPatients.push(item.patientcode.trim());               
            });
            this.parameter.PatientList = selectedPatients
        } else {
            this.PatientsModel = null;
            this.parameter.PatientList = null
      }*/
      this.totalRows = 0;  
      this.getListing();
    },
    async ChangeDropDownPrograms(item){ 
        this.parameter.ProgramList = [];      
        item ? this.parameter.ProgramList.push(item.code) : this.parameter.ProgramList = [];

        /*if(item.length > 0)  {
            this.ProgramVmodel = item;
            let selectedPrograms = [];
            item.forEach(function(item) {   
                selectedPrograms.push(item.code.trim());               
            });
            this.parameter.ProgramList = selectedPrograms
        } else {
            this.ProgramVmodel = null;
            this.parameter.ProgramList = null
      }  */
      
     /* if(this.ProgramVmodel.length > 0){
          this.$store.commit('setclinicalDashboardProgramsSelected',this.ProgramVmodel);
        }else{
          this.$store.commit('setclinicalDashboardProgramsSelected',[]);
        }*/
        await this.UpdateProgramPreference(item ? item.code : null)
        this.totalRows = 0;
        this.getListing();
    },

    async UpdateProgramPreference(value){
      let program = {
        LastMarsProgram:value
      };
      await UserPreference.UpdateLastMarsProgram(this.UserId, program)
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },

    async UpdateBuildingPreference(value){
      let building = {
        LastMarsBuilding:value
      };
      await UserPreference.UpdateLastMarsBuilding(this.UserId, building)
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },

    async UpdateLevelPreference(value){
      let level = {
        LastMarsLevel:value
      };
      await UserPreference.UpdateLastMarsLevel(this.UserId, level)
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },

    async getBuildings(){
        await AdmissionService.getBuildings()
                .then((response) => {
                    if(response.status == '200'){                       
                        this.BuildinsList = response.data;                       
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionBuildings'),error,"error");
                    }
                });
    },

    async getPrograms(){
      await ClinicalService.getPrograms()
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){                        
                       this.ProgramList = response.data;                                          
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Clinical.ClinicalProgramFilter'),error,"error");
                    }
                });  
    },

   async getLevel(){
    let param = {
        CodeType:"RoomLevel"
    };
        await GlobalService.getGlobalCustom(param)
                .then((response) => {
                    if(response.status == '200' && response.data.length > 0){                          
                        this.LevelList = response.data;
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionSource'),error,"error");
                    }
                }); 
    },

   async getRomms(build){       
        await MarsService.getMarsRooms(build)
                .then((response) => {
                    if(response.status == '200' && response.data.length > 0){                         
                        this.RoomList = response.data;
                        if(this.RoomList.length === 1){
                            this.room = this.RoomList[0].code;
                        }else{
                            EventBus.$emit('OnResetModelSelect'); 
                            this.room = "";   
                        }

                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionSource'),error,"error");
                    }
                }); 

    },

    async getUserPreference() {
      await UserPreference.getQhUserPreference(this.UserId)
        .then((response) => {
          if (response.data && response.data.length > 0) {          
                let lastMarsProgram = response.data[0].lastMarsProgram;
                let lastMarsbuilding = response.data[0].lastMarsBuilding;
                let lastMarsLevel = response.data[0].lastMarsLevel;
                if(lastMarsProgram){           
                    this.ProgramVmodel = lastMarsProgram;
                    this.parameter.ProgramList = [];
                    this.parameter.ProgramList.push(lastMarsProgram);
                }else{
                    this.ProgramVmodel = null;
                    this.parameter.ProgramList = [];
                }

                if(lastMarsbuilding){           
                    this.buildings = lastMarsbuilding;
                    this.parameter.BuildingList = [];
                    this.parameter.BuildingList.push(lastMarsbuilding);
                }else{
                    this.buildings = null;
                    this.parameter.BuildingList = [];
                }

                if(lastMarsLevel){           
                    this.level = lastMarsLevel;
                    this.parameter.LevelList = [];
                    this.parameter.LevelList.push(lastMarsLevel);
                }else{
                    this.level = null;
                    this.parameter.LevelList = [];
                }


          } 
        })
        .catch((error) => {
          if (error.errorMessage) {
            this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), error, "error");
          }
        });
    },

   async getPatients(){   
        await PatientService.getAll(this.fullParam)
              .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0) {               
                this.PatientList = response.data;                          
                this.PatientList.forEach(function(element) {	
                    element.patientname = element.firstname + " " + element.lastname + " - "+element.patientcode;   
                });       
              }       
              })
              .catch(error => {      
                if (!error.response) {
                    // network error
                    this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.retrieveInfo'),"error");
                } else {
                    this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                }
        });    
    },

    perPageChange(value) {
      this.perPage = value;      
      this.getListing();
    },
    onChange(page) {      
      this.currentPage = page;   
      this.getListing();  
    },


    async getListing(){  
      this.parameter.PageNumber = this.currentPage;
      this.parameter.PageSize = this.perPage;
      this.parameter.DateTimeFrom = Utils.formatDateTime(this.DateFrom, Utils.getDateTimeFormat());
      this.parameter.DateTimeTo = Utils.formatDateTime(this.DateTo, Utils.getDateTimeFormat());
     // this.totalRows = 0;
      this.$emit('load', true);      

      await MarsService.getAllListing(this.parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data; 
                       this.showEmptyRecords = response.data && response.data.length === 0 ? true : false      
                       /* STATUS COLOR TEST 
                       if(this.items.length > 4){
                        this.items[0].administrationStatus = "1_OVER_DUE";
                        this.items[1].administrationStatus = "2_CLOSE_TO_ADM";
                        this.items[2].administrationStatus = "3_PRIOR_TO_ADM";
                        this.items[3].administrationStatus = "4_NO_PEND_ADM";
                       }
                       END OF STATUS COLOR TEST */

                       if(response.data.length > 0){
                        this.totalRows = response.data[0].maxRows;                        
                        this.items.forEach(function(element) {
                          if (element.dob)	
                              element.dob = Utils.formatterDateToString(element.dob);                          
                        });                                                
                       }
                       this.$emit('load', false);  
                     }  
                   
                })
                .catch((error) => {                   
                   this.$emit('load', false);               
                    if (!error.response) {
                      this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                    }                    
                    else if(error.response.status === 500){
                        this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                      this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                    } 
                    
                }); 
    },




    SearchPatients(){
       this.getListing();
    },
    async getDateTime() { 
         await GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {                             
                this.DateFrom = Utils.formatterDateTimeToString(moment(response.data).subtract(1, 'hours').subtract(moment(response.data).minute(), 'minutes')); 
                this.DateTo = Utils.formatterDateTimeToString(moment(response.data).add(1, 'hours').subtract(moment(response.data).minute(), 'minutes')); 
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
            }
         }); 
      }, 

     async loadListing(){
         await this.getUserPreference();
         this.getListing();
      }
  },

 created(){
    EventBus.$on("OnUpdateAllergieOutCome", function (items) {  
            this.PhotoObj.allergies = null;           
            let allergiestmp = "";
            for(var i = 0; i < items.length; i++){
                allergiestmp += i < items.length-1 ? items[i].allergy+"," : items[i].allergy;
            }          
            this.PhotoObj = Object.assign({}, this.PhotoObj, { allergies:allergiestmp });
            console.log(this.PhotoObj);           
        }.bind(this));

     //   

    EventBus.$on("OnUpdateSpecialPrecaution", function (specialprecaution) {        
        this.PhotoObj.specialPrecautions = null;             
        this.PhotoObj = Object.assign({}, this.PhotoObj, { specialPrecautions:specialprecaution });
    }.bind(this));

    EventBus.$on("OnUpdateMarsAlerts", function (marsAlert) {        
        this.PhotoObj.marsAlerts = null;             
        this.PhotoObj = Object.assign({}, this.PhotoObj, { marsAlerts:marsAlert });
    }.bind(this));

       
  },

 async mounted(){

    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
  }


    this.$emit('load', true),     
    Promise.allSettled([           
        this.getDateTime(),
        this.getPrograms(),
        this.getBuildings(),
        this.getLevel(),
        this.getRomms({BuildingCodeList:[]}),       
        this.getPatients(),
        ]).then((result) => result.length === 6 ? this.loadListing() :null);
},

destroyed(){
    this.$store.commit('setWrotePasswordMars',false);
}
   
}
</script>

<style scoped>

.cContent{
    padding-left: 0;
}
.card-container {
    display: flex;
    flex-wrap: wrap;
}
.card {
    flex: 0 1 calc(25% - 20px);
    margin-bottom: 20px;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;
}
.card-body{
    border-radius: 10px;
    background: linear-gradient(300deg, #F9F9F9, #FFF);
    padding: 0.8rem 0.4rem 0.6rem 0.4rem;
    transition: all 1s ease;
}
.card-title{
    padding: 5px 0 5px 5px;
    margin-bottom: 5px;
    border-radius: 0 5px 0 0;
    transition: all 1s ease;
    cursor: pointer;
}
.card .card-title i::before, .card .icon {
    cursor: help;
}
.card:hover .card-title.overdue i::before{
        color: #ffd6d6 !important;
}
.card:hover .card-title.close-to-admin i::before{
        color: #fcfcb2 !important;
}
.card:hover .card-title.prior-to-admin i::before{
        color: #afffaf !important;
}
.card:hover .card-title.no-pending i::before{
        color: #00f2ff !important;
}
.card:hover .card-title i::before{
        text-shadow: none !important;
}

.card-title::before{
    content: '►';
    position: absolute;
    left: -5px;
    top: 2px;
    opacity: 0;
    transition: all .5s ease-in-out;
    font-size: 20px;
    transform: scale(.4);
}
.card-text{
    color: #666;
    padding-left: 5px;
    margin-top: .7em;
    text-transform: lowercase capitalize;
}

@media (max-width: 1200px) {
    .card {
        flex: 0 1 calc(33.333% - 20px);
    }
}
@media (max-width: 992px) {
    .card {
        flex: 0 1 calc(50% - 20px);
    }
}
@media (max-width: 576px) {
    .card {
        flex: 0 1 100%;
    }
}
@media (min-width: 765px) {
    .card:hover .photo{
        transform: rotateY(10deg) rotateX(1deg) rotateZ(-5deg) scale(1.1);
        transform-style: preserve-3d;
    }
    .card:hover .card-title{
        text-shadow: 0 0 #FFF, -1px 0 6px #FFF, 1px 0 6px #FFF, 0 -1px 6px #FFF, 0 1px 6px #FFF ;
        color: #000;
    }
    .photo{
        height: 148px !important;
    }
}
@media (max-width: 765px) {
    .cContent{
        padding-left: 15px;
    }
}
.card-container::after {
    content: "";
    flex: auto;
}
.overdue {
    background: linear-gradient(90deg, #D6454500, var(--overdue));
}
.close-to-admin {
    background: linear-gradient(90deg, #D9A44100, var(--close-to-admin));
}
.prior-to-admin {
    background: linear-gradient(90deg, #4FA94F00, var(--prior-to-admin));
}
.no-pending {
    background: linear-gradient(90deg, #5E99D900, var(--no-pending));
}

.icon {
    z-index: 1;
    margin: 0 5px 0 5px;
    color: #CCC;
    transition: all 0.4s ease-in-out;
    width: 1.3em;
    text-align: center;
}
.icon:hover {
     color: #000;
     position: relative;
     text-shadow: 2px 2px 1px #CCC;
     top: -1px;
     left: -1px;
}
.photo-container {
    perspective: 300px;
    z-index: 2;
}
.photo {
    width: 100%;
    height: 100px;
    cursor: pointer;
    object-fit: cover;
    border-radius: 10px;
    border: 4px solid #F3F3F3;
    filter: grayscale(100%) brightness(1.2); 
    transition: all 0.4s;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}
.card:hover .photo {
    border: 4px solid #EEEEEE;
    filter: grayscale(0%) brightness(0.99);
    box-shadow: 8px 8px 6px rgba(0, 0, 0, 0.05);
}
.card:hover .card-body{
    background: linear-gradient(120deg, #F1F1F1, #FFF);
}
.card:hover .card-title{
    margin-left: 20px;
}
.card:hover .card-title::before{
    opacity: 1;
    left: 3px;
    transform: scale(1);
}
.card:hover .uId{
    background: linear-gradient(90deg, #FFFFFF00, #FFFFFFFF, #FFFFFFFF, #FFFFFFFF, #FFFFFF00);
}
p i:not(:first-of-type) {
    margin-left: 1.1em;
}

.uId{
    font-size: .7em;
    color: #999;
    cursor: help;
    position: inherit;
    left: 0;
    z-index: 1;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-image: linear-gradient(to right, #CCCCCC00, #CCCCCCFF, #CCCCCC00) 1;
    margin-top: .5em;
    background: linear-gradient(90deg, #FFFFFF00, #FFFFFFFF, #FFFFFF00);
    transition: all 0.4s;
}
.uId:hover{
    color: #000;
    left: 2px;
}

</style>