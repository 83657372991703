<template>
  <div id="ContainerInsurancesSetup">
    <div class="row">
        <div class="col-12">
          <MdTitle v-bind:Text="$t('insuranceSetup.iSetupTitle')" />
          <div class="row">
              <div class="col-sm-12 col-md-3" v-if="permission.length > 0 && permission[0].roleCanAdd">                      
                  <MdButton
                    v-bind:Label="$t('InsurancesList.AddInsurance')" 
                    v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                    v-on:click="GetAddPatient"
                    v-bind:IconClass="'ti-plus pr-1'"
                    v-bind:isReadOnly="this.isReadOnly"
                    v-bind:Name="'btn_add_insurancesetup'">
                  </MdButton>
              </div>
              <div v-bind:class="permission.length > 0 && permission[0].roleCanAdd ? 'col-sm-12 col-md-9 mb-4':'col-sm-12 col-md-12 mb-4'">
                  <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                      <div class="input-group">
                          <input 
                            type="text" 
                            class="form-control"
                            v-model="parameter.SearchCriteria"
                            @keyup.enter="SearchInsurance()"
                          >
                          <div class="input-group-append">
                              <MdButton 
                                v-bind:Name="'btnsearchinput'"
                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                v-bind:IconClass="'mdi mdi-magnify'"
                                v-on:click="SearchInsurance">
                              </MdButton>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <b-toast id="error-toast" title="Error" static no-auto-hide>
            {{ error_toast }}  
          </b-toast>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div>
              <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
              <!--<div class="">
                <div class="row">
                  <div class="col-md-12">-->
                      <MdTable 
                        v-bind:fields="fields"
                        v-bind:items="items"
                        v-bind:totalRows="totalRows"
                        v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                        v-bind:currentPage="currentPage"
                        v-bind:perPage="perPage"
                        v-bind:pageOptions="pageOptions"
                        v-bind:refTable="refTable"
                        :editIconEvent="EditInsuranceView"
                        v-bind:VisibleEditIcon="this.permission.length && this.permission[0].roleCanEdit ? true : false"
                        :deleteIconEvent="RemoveInsurance"
                        v-bind:VisibleDeleteIcon="this.permission.length && this.permission[0].roleCanDelete ? true : false"
                        :onRowSelected="onRowSelected"
                        :onFiltered="onFiltered"
                        :pageChanged="pageChanged"
                        :perPageFunction="PerPageFunction">
                      </MdTable>
                    <!--</div>
                  </div>
                </div> -->
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import InsuranceService from '@/services/insurance.service'
//import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../../event-bus';
//import Patient from '@/components/pages/patients/Patient'

export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  mixins: [ShowMessage, DBFunctions],
  name: 'InsuranceSetupList',
  components: {
   // Patient
  },
  data() {
    return { 
      search_criteria: "",
      error_toast: "",
      permission:[],
      Menu : this.$store.state.leftMenu,
      ShowMessageIsEmpty:false,
      Loading:true,
      Mode:'',  
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: true,       
      },
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
     //
      fields: [
		{ key: 'company', sortable: true, label: this.$t('Insurance.colInsuranceName'), class: 'text-rigth' },
    { key: 'categoryDescription', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' },
		{ key: 'insurancecode', sortable: true, label: this.$t('Insurance.InsuranceCode'), class: 'hidden text-rigth' },
    //{ key: 'insuranceTypeDescription', sortable: true, label: 'Type', class: 'text-rigth' }, 
		{ key: 'address1', sortable: true, label: this.$t('Shared.Address1'), class: 'text-rigth' },
		//{ key: 'address2', sortable: true, label: this.$t('Shared.Address2'), class: 'text-rigth' },	
		{ key: 'city', sortable: true, label: this.$t('Shared.City'), class: 'text-rigth' },
    //{ key: 'contact', sortable: true, label: 'Contact', class: 'text-rigth' },		
      

		/* { key: 'categoryDescription', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' },
        { key: 'contact', sortable: true, label: 'Contact', class: 'text-rigth' },
        { key: 'address1', sortable: true, label: this.$t('Shared.Address1'), class: 'text-rigth' },
        { key: 'address2', sortable: true, label: this.$t('Shared.Address2'), class: 'text-rigth' },
        { key: 'city', sortable: true, label: this.$t('Shared.City'), class: 'text-rigth' },   */
        ],

      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{},
      isReadOnly: false,
      refTable: 'selectableTable',  
    }
  },

  computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }
  },
    
  methods: {

    pageChanged (page) {            
      this.currentPage = page;
      this.getInsurances();
    },

    PerPageFunction(perpage){
      this.perPage = perpage;
      this.currentPage = 1;
      this.getInsurances();
    },

    CanView(){
      return this.permission[0].roleCanView;
  },

    SearchInsurance(){
      this.items = []
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getInsurances();
    },
    getInsurances(){
      this.parameter.PageSize = this.perPage;
      this.parameter.PageNumber = this.currentPage; 
      this.$emit('load', true)
      InsuranceService.getAllInsurances(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.items = response.data;
         this.$emit('load', false);
        let headerString = response.headers["x-pagination"];
        this.Header = JSON.parse(headerString);
        this.totalRows = this.Header.TotalCount;
        }       
        this.$emit('load', false);
        if(this.items.length === 0){
          this.ShowMessageIsEmpty = true;
        } 
      })
      .catch(error => { 
        if (!error.response) {
                this.showMessage(this.$tc('Insurance.Insurance'),this.$t('Msg.NotAbleToSave'),"error");
                this.showMessage(this.$tc('Insurance.Insurance'),this.$t('Msg.retrieveInfo'),"error");
           }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$tc('Insurance.Insurance'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){
                   this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50); 
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$tc('Insurance.Insurance'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$tc('Insurance.Insurance'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);      
        /*this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('insuranceSetup.iSetupTitle'),this.$t('Msg.RetrieveInformation'),"error");
        } else {
            this.showMessage(this.$t('insuranceSetup.iSetupTitle'),error,"error");
        }*/
      });
    },


    /*getPatients() {               
      this.parameter.PageSize = this.perPage;
       this.parameter.PageNumber = this.currentPage;   
      this.parameter.Status = this.status.value;
      this.$emit('load', true)
      PatientService.getAll(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.items = response.data; 
        this.items.forEach(function(element) {
						if (element.dob)	
              element.dob = new Date(element.dob).toLocaleDateString();
              
            element.PatientName = element.firstname + " " + element.lastname;   
          });
         this.$emit('load', false);
        let headerString = response.headers["x-pagination"];
        this.Header = JSON.parse(headerString);
        this.totalRows = this.Header.TotalCount;
        }
        //this.Loading = false;
        this.$emit('load', false);
        if(this.items.length === 0){
          this.ShowMessageIsEmpty = true;
        } 
      })
      .catch(error => {
        //this.Loading = false;
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage("Patients",this.$t('Msg.RetrieveInformation'),"error");
        } else {
            this.showMessage("Patients",error,"error");
        }
      });
    },*/

    EditInsuranceView(row) {
       this.code = row.insurancecode;
       this.Mode = "Edit";
       this.$router.push({ name: "insurances-details", params: { id: this.code } });       
      /*this.Mode= "Edit";
      this.$router.push({ name: "patient-summary", params: { id: item.patientcode } });

      this.OptionsRightMenu.btnvisible = true;
      this.OptionsRightMenu.ItemsList = EnumService.PatientItems;

      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R0':
            this.$store.commit('setlayoutStatus', 'L0R1');
        break;
        default:
            this.$store.commit('setlayoutStatus', 'L1R1');
      }
      this.$emit('getVisibleMenu', this.OptionsRightMenu); */
    },

    RemoveInsurance(row){     
      const Id = row.insurancecode;
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('InsurancesList.DeleteInsurance'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_insuranceSetup'
				})
          .then(value => {
            if(value){
              this.$emit('load', true);
                InsuranceService.deleteInsurance(Id)
                .then(() => {
                  const index = this.items.findIndex(item => item.insurancecode === Id) // find the post index 
                  if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view                    
                    this.getInsurances(); //refresh listing
                  }
                })
                .catch((error) => {            
                   if (!error.response) {
                      this.showMessage(this.$t('insuranceSetup.iSetupTitle'),this.$t('Msg.NotAbleToInsert'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('insuranceSetup.iSetupTitle'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('insuranceSetup.iSetupTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('insuranceSetup.iSetupTitle'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
                });
            }
          })
    },

    focusInput() {
      this.$refs.SearchPatient.focus();
    },

    onRowSelected(items) {
      this.selected = items
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {    
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    GetAddPatient(){     
      this.Mode = "Add"; 
      this.emitDataforFavorites(); 
      EventBus.$emit('onUpdatedMenu', '');
      this.$router.push({ name: "insurances-add" }).catch(()=>{});
    },

    info(item, index) {
      alert(item.FirstName,index);
    },

  },

  created(){
    this.getPermissionLeftMenu(this.Menu, this.$route.path, this.permission);  
  },

  mounted() {
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
    this.getInsurances();
    this.isReadOnly = false;   
  },

};
</script>


