<template>
   <div id="schedulerNotes">
	<b-row>
		<b-col lg="12" md="12" sm="12">
			<div class="accordion" role="tablist">
				<b-card no-body class="noteSearchPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block						
							class="collapseBTN text-truncate"
							v-on:click="Accordion1Click"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							<MdTitleRegInfo
								v-bind:RegistrationInfo="PatientTitle" 
								v-bind:titlePrefix="$t('SchedulerNotes.SNTitle')" 
								v-bind:IsClinical="IsClinical"
								v-bind:titleRef="'titleElementSchedulerNotes'"
								v-bind:patientCode="this.$route.params.id"
								v-bind:propClass="''"
								v-bind:propTag="'span'"
								v-bind:isTitle=false>
							</MdTitleRegInfo>
						</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-1"
						visible
						accordion="ClinicalNotes"
						role="tabpanel"
						v-show="ShowFilter"
						v-on:click="ShowFilter = true">
						<b-card-body class="p-1">
							<b-card-text class="notesPanelsClinical cutescroll">
								<b-row>
									<b-col md="4" lg="4" sm="12">													
										<MdSelect
											v-bind:Label="$t('ChartReview.NoteStatus')"
											v-bind:Options="OptionNoteStatus"
											v-bind:ModelArray="NoteStatusVModel"												
											v-bind:FieldDescription="'name'"
											v-bind:Multiple="false"
											v-bind:FieldCode="'value'"
											v-bind:IfUsevalidation="false"
											:Input="ChangeDropDownNoteStatus"/>							
									</b-col>
									<b-col md="8" lg="8" sm="12">
										<div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
											<div class="input-group">
												<input 
													type="text" 
													class="form-control"
													v-model="parameter.SearchCriteria"
													@keyup.enter="SearchPatients()"
												>
												<div class="input-group-append">
														<MdButton 
														v-bind:Name="'btnsearchinput'"
														v-bind:VariantProp="'btn btn-sm search-attached'"
														v-bind:IconClass="'mdi mdi-magnify'"
														v-on:click="SearchPatients">
													</MdButton>
												</div>
											</div>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" lg="12" md="12">
										<div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="this.currentAppointmentDB && this.currentAppointmentDB.appointmentStatus != 'Showed Up'">
											<i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
											<div class="flex-grow-1">
												<span>{{ $t('MdNote.pShowUpTheAppointment')}}</span>
											</div>
											<button type="button" class="btn btn-primary ms-3" v-on:click="ShowUpAppt">{{ $t('MdNote.showedUp')}}</button>
										</div>
									</b-col>
									<b-col sm="12" lg="12" md="12">
									<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
									<MdTable 
										v-bind:fields="fields"
										v-bind:items="ListNotes"
										v-bind:IsSchedulerNotesDB="true"
										v-bind:totalRows="totalRows"
										v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
										v-bind:currentPage="currentPage"
										v-bind:perPage="perPage"
										:viewIconEvent="OpenNote"
										v-bind:pageOptions="pageOptions"
										:onRowSelected="onRowSelected"										
										:onFiltered="onFiltered"
										:pageChanged="pageChanged"
										:perPageFunction="PerPageFunction"
										:NotePatientInfo="PatientInfo">
									</MdTable>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="openNotesPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block							
							class="collapseBTN"
							v-on:click="ClickBtnAccordion2"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('ClinicalNotes.OPENNOTES') }}</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-2"
						accordion="ClinicalNotes"
						role="tabpanel"
						:class="this.ClassAccordion"
						v-show="ShowNote"
						v-on:click="ShowNote = true"
						ref="a2">
						<b-card-body class="p-1" v-show="ShowNote">
							<b-card-text class="notesPanels">
								<!-- OPEN NOTES CODE -->
								<div :class="this.ClassContainerNotes" ref="ContainerNotes">
								<MdNote
									v-for="(model, index) in this.ClinicalNotesListOpened"
									v-bind:NOTE="model.noteName"										
									v-bind:TransDate="model.transDate"
									v-bind:Time="model.Time"
									v-bind:EventScheduler="false"
									v-bind:Location="'ClinicalNotes'"
									v-bind:Header="model"
									v-bind:IsChartReview="false"
									v-bind:ProgramCode="ProgramCode"
									v-bind:CategoryCode="TherapiesVModel"									
									:key="model.Id"
									:id="model.Id"
									v-on:remove="CloseNote(index)"
									:model="model"
									v-bind:NotePatientInfo="PatientInfo"
									v-bind:IsSchedulerNote="true">
								</MdNote>
								</div>
								<!-- END OPEN NOTES CODE -->
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>

		</b-col>
	</b-row>

	<ModalHistoricalAppointment
		v-bind:TitleWindow="$t('SchedulerNotes.HisAppt')"  
		v-bind:ModalId="this.modalApptHistoricalId">
	</ModalHistoricalAppointment>
		



   </div>
</template>

<script>
import moment from "moment";
import EnumService from "@/services/enum.service";
import DBFunctions from '@/common/DBFunctions.js';
import { EventBus } from '@/event-bus.js';
import Utils from '@/common/utils'
import AppointmentService from '@/services/appointment.service'
import ShowMessage from '@/components/messages/ShowMessage.js'



export default {
  name: 'SchedulerNotes',
  mixins: [DBFunctions,ShowMessage],
  data(){
	return{
		Menu : this.$store.state.leftMenu,
		PatientInfo:{},
		modalApptHistoricalId:"modalHistoricalAppointment",
		TherapiesVModel:'',
		ProgramCode:'',		
		ListNotes:[],
		ListNotesHistorical:[],		
		ExistsNote:false,
		LabelNotes:'Selected Appointment Notes from ',
		selectPlaceholder:"Select a Note",
		noteVModel:{
			notesID:"",
			description:""
		},
		noteHistoricalVmodel:{
			code:"",
			description:""
		},
		ClassContainerNotes:'',
		ClinicalNotesListOpened:[],
		currentAppointmentDB:{
			guid:'',
			activitytypecode:'',
        employee:{
            firstname:"",
            lastname:""
        }
		},
		OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,
			
		},
		PatientTitle:{
			activitytypename:'',
			startdatetime:'',
			ageFormatted:''
		},
		ShowNote:false,
		ShowFilter:true,
		ClassAccordion:'',
		fields: [
			{ key: 'transDate', sortable: true,label: this.$t('ChartReview.NoteDate')},
			{ key: 'description', sortable: true,label: this.$t('Shared.NoteType')},
			{ key: 'therapistName', sortable: true,label: this.$tc('Shared.Therapist')},
			{ key: 'coTherapistName', sortable: true,label: this.$t('ChartReview.CoTherapist')},			
			{ key:'notesId',sortable: true, class:'hidden'},				
			{ key: 'isBillable',label: this.$t('ChartReview.Billable'), sortable: true, formatter: value => {return value ? this.$t('ChartReview.Billable') : this.$t('ChartReview.NotBillable')}},
			{ key:'report',sortable: true, label:this.$t('Shared.Report')},
		],
		totalRows: 0,
		ShowMessageIsEmpty: false,
		currentPage: 1,
		perPage: 50,
		pageOptions: [5,10,20,30,40,50],
		parameter:{
			PageSize: 50,          
			PageNumber : 1,       
			PatientCode: this.$route.params.id,   
			ActivityId: this.$route.params.actId,
			ActivityTypeCodeList:"",
			NoteStatus:0, 
			NoteIdList:"",
			TherapistCode:"",       
			DateFrom:null,
			DateTo:null,
			SearchCriteria:''
		},
		OptionNoteStatus: EnumService.NoteStatus,
		NoteStatusVModel: {value:0,name: this.$t('Shared.All')},
		IsClinical: false,
	}
  }, 

  methods:{

	ChangeDropDownNoteStatus(NoteStatus){
	if(NoteStatus !== null){
			this.NoteStatusVModel =  NoteStatus;
	}else{
			this.NoteStatusVModel.value =  0;
	}
	
	this.getNotes()
	},

	async ShowUpAppt(){
        var param = {
            ActivityIdList: this.$route.params.actId,
            Action: 'Show Up',
            State: 'T',
        };
		await AppointmentService.ShowUp(param)
            .then((response) => {
                if(response.status == '200'){
					this.showMessage(this.$t('MdNotesByAppt.Appointment'),this.$t('Appointments.showUpMsg'));
					this.getUpNotes()
                }
            })
            .catch(error => {
                if (!error.response) {               
					this.showMessage(this.$t('MdNotesByAppt.Appointment'),this.$t('Msg.RetrieveInformation'),"error");
				} else {
					this.showMessage(this.$t('MdNotesByAppt.Appointment'),error,"error");
				}
            });  
    },


	async SearchPatients(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.ListNotes = []
      this.currentPage = 1;
      this.totalRows = this.ListNotes.length;
      this.parameter.PageNumber = 1;
      await this.getNotes();
    },

	async PerPageFunction(perpage){
		this.perPage = perpage;
		this.parameter.PageNumber = 1;
		await this.getNotes();
    },
	
	async pageChanged(page) {            
		this.currentPage = page;
		this.parameter.PageNumber = this.currentPage;        
		await this.getNotes();
    },

	onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

	onRowSelected(items) {
      this.selected = items;		
    },
	
	Accordion1Click(){
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
	},

	ClickBtnAccordion2(){
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
	},

	OpenNote(item) {		
		item.notesId = item.notesID;
		item.Id = item.headerID;
		if(item.frequency === 'DAILY MULTIPLES'){
			item.Time = item.transdate;
		}
		item.serviceDate = item.serviceDate !== null ? Utils.formatterDate(item.serviceDate) : null;
		this.OnResultViewDate(item);
    },

	OnResultViewDate(obj){
		this.$emit('load', true);
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id)) ||
							(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)) ||
							((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && 
							(nItem.noteTime === obj.noteTime)))));																	  

																		
		if (!this.ExistsNote) {
			this.AdjustNoteContainer("ADD");
			this.ClinicalNotesListOpened.push(obj);
		}
		this.ClassAccordion = 'show';
		this.ShowNote = true;
		this.ShowFilter = false;
		setTimeout(() => this.$emit('load', false), 500);
	},

	OpenHistoricalNotes(){
		EventBus.$emit('onOpenHistoricalAppt', true);
		this.$bvModal.show(this.modalApptHistoricalId);
	},

	OnCLickMenuNotes(Id){
		//v-on:click="launchPage('HomePage', '/app/main', 'Main')"
		this.OpenNoteDefault(Id);
	},

	trademark() {
      //return "MedEZ<sup>®</sup>";
      //return "<i class='ikon med-isologo'></i> Estas son las notas a mostrar";
		return this.$t('SchedulerNotes.OpenSelectApptMsg');
    },

	ChangeDropDownNotesHistorical(nh){
		this.noteHistoricalVmodel = nh;
	},

	AdjustNoteContainer(Operation){
		var CountNotes = 0;			
			if(this.$refs.ContainerNotes !== undefined){
				CountNotes = Operation === 'ADD'?this.$refs.ContainerNotes.children.length+1:this.$refs.ContainerNotes.children.length-1;		
			}
			if(CountNotes === 1){
				this.ClassContainerNotes = 'cutescroll';
			}
			if(CountNotes > 1){
				this.ClassContainerNotes = 'notesHolder cutescroll';
			}
	},

	CloseNote(index){		
		this.ClinicalNotesListOpened.splice(index, 1)
		this.AdjustNoteContainer("REMOVE");
		if(this.ClinicalNotesListOpened.length == 0){
			this.ShowFilter = true;
			this.ShowNote = false;
		}
	},

	PopulateListNotesDropDown(arr){

		var temp = [];
		arr.forEach(element => {
			temp.push({code:element.notesID.trim(),description:element.description.trim()})
		});
		this.ListNotes = temp;

		EventBus.$on("onNoteSchedulerClicked", function (e) {
			this.noteVModel.code = e.Clicked.code;
			this.noteVModel.description = e.Clicked.description;	
		}.bind(this));

	},

	SetIcon(noteStatus){
		var obj = {
			icon:"",
			description:"",
		}
		if(noteStatus == 'New'){	
			obj.icon = "icon-doc mr-2";	
			obj.description = this.$t('Shared.IconDescN');
		}
		else if(noteStatus == 'Voided'){			
			obj.icon = "icon-ban mr-2";	
			obj.description = this.$t('Shared.IconDescVN');
		}
		else if(noteStatus == 'Pending Signature'){			
			obj.icon = "icon-note mr-2";	
			obj.description = this.$t('Shared.IconDescPS');							
		}
		else if(noteStatus == 'Pending Cosigner Signature'){
			obj.icon = "icon-user mr-2";	
			obj.description = this.$t('Shared.IconDescPCS');			
		}else{
			obj.icon = "icon-check mr-2";	
			obj.description = this.$t('Shared.IconDescF');									
		}
		return obj;
    },

	async getNotes() { 
 /*      let params = {
        PageSize: 9999999,          
        PageNumber : 1,       
        PatientCode: this.$route.params.id,   
        ActivityId: this.$route.params.actId,
        ActivityTypeCodeList:"",
        NoteStatus:0, 
        NoteIdList:"",
        TherapistCode:"",       
        DateFrom:null,
        DateTo:null,
		SearchCriteria:null
      }; */
		this.$emit('load', true);
		this.parameter.NoteStatus = this.NoteStatusVModel.value
        await AppointmentService.getNotesByActId(this.parameter)
              .then((response) => {
                if(response.status == '200' && response.data){
                  this.ListNotes = response.data;

				this.ListNotes.forEach(element => { 
					element.Id = element.headerID;
					element.transDate = moment(element.transDate).format("ddd, ll");
					element.Time = element.noteTime;
					element.notesId = element.notesID;
					var obj = this.SetIcon(element.noteStatus);
					element.icon = obj.icon;
					element.descriptionIcon = obj.description;
				});

				if(response.data.length > 0){                
                    let headerString = response.headers["x-pagination"];					
                    this.Header = JSON.parse(headerString);
                    this.totalRows = this.Header.TotalCount;
                }

				if (this.ListNotes.length == 0) {
					this.ShowMessageIsEmpty = true;
				}

				}
				this.$emit('load', false);	
              })
              .catch(error => {
				this.$emit('load', false);	
                if (!error.response) {                
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),error,"error");
                }
              });
            },

	async PatientInfoData(){
		this.PatientInfo = await this.getData(this.$route.params.id);
	},

	getObjectByNoteId(Id){
		for(var i = 0; i < this.ListNotes.length; i++){			
			if(this.ListNotes[i].notesID === Id){
				return this.ListNotes[i];
			}	
		}
		return null;
	},

	OpenNoteDefault(Id){
		var obj = this.getObjectByNoteId(Id);		
		this.$emit('load', true);
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesID === obj.notesID) && (nItem.Id === obj.Id)) ||
																		(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate)) ||
																		((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime)))));																	  
		
		if (!this.ExistsNote) {
			this.ClinicalNotesListOpened.push(obj);
			this.AdjustNoteContainer("ADD");			
		}
		
		this.ClassAccordion = 'show';
        this.ShowNote = true;
		this.ShowFilter = false;		
		setTimeout(() => this.$emit('load', false), 500);

	},

	OpenNoteHistorical(obj){
		//var obj = this.getObjectByNoteId(Obj.notesID);		
		this.$emit('load', true);
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesID === obj.notesID) && (nItem.Id === obj.Id)) ||
																		(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate)) ||
																		((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesID === obj.notesID) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime)))));																	  
		
		if (!this.ExistsNote) {
			this.AdjustNoteContainer("ADD");
			this.ClinicalNotesListOpened.push(obj);
		}
		
		this.ClassAccordion = 'show';
        this.ShowNote = true;
		this.ShowFilter = false;		
		setTimeout(() => this.$emit('load', false), 500);
	},

	UpdateNoteStatus(objNote){       
       for(var i = 0; i<this.ListNotes.length; i++){
		if(this.ListNotes[i].notesID === objNote.Notesid && this.ListNotes[i].headerID === objNote.Headerid){
			var obj = this.SetIcon(objNote.NoteStatus);
			this.ListNotes[i].icon = obj.icon;
			this.ListNotes[i].descriptionIcon = obj.description;
			this.ListNotes[i].noteStatus = objNote.NoteStatus;
		}
       }
      },

	async getPatientInfo(){
	var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
		const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

        if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
        if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
        if (age) this.PatientTitle.age = age;
        if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
      }
	},

	async getUpNotes() {
		await this.getNotes();
		this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
	},
	
	async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }
  },

 async created(){	
	await this.getUpNotes()

	EventBus.$on("onSelectNoteinModalHistorical", function (noteSelected) { 
		if(noteSelected.serviceDate){		
			noteSelected.serviceDate = new Date(noteSelected.serviceDate).toISOString();  
		}
		if(noteSelected.transDate){
			noteSelected.transDate = new Date(noteSelected.transDate).toISOString();  
		}

		if(noteSelected.date){
			noteSelected.date = new Date(noteSelected.date).toISOString(); 
		}
		
       this.OpenNoteHistorical(noteSelected);
     }.bind(this));

	EventBus.$on("onChangeNoteStatus", function (objNote) {       
        this.UpdateNoteStatus(objNote);
     }.bind(this));
	

  },

  async mounted(){
	
	this.OptionsRightMenu.visible = true;    
	this.OptionsRightMenu.btnvisible = true;
	this.OptionsRightMenu.itemdisabled = false;
	this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems;
	this.$emit('getVisibleMenu', this.OptionsRightMenu); 

	this.PatientInfoData();

	moment.suppressDeprecationWarnings = true; 		
	this.getPatientInfo();
	await this.getCurrentAppointment()		
	this.ClinicalNotesListOpened = [];
  },

  destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
	EventBus.$off('onNoteSchedulerClicked');

  }
   
};
</script>

<style scoped>
.m-pagecontent {
    overflow-y: hidden!important;
    overflow-x: hidden!important;
    padding: 5px;
    height: 100%;
    height: calc(100vh - 60px);
    height: calc(100dvh - 60px);
    background-color: #fff;
    border-radius: 7px;
}

</style>

