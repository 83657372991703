<template>
      <div class="col-md-3 grid-margin stretch-card">
					<div class="card activeCard">
						<div class="card-body">
					<div class="d-flex align-items-start justify-content-between">
							<h6 class="card-title flex-grow lower-line text-truncate"> {{ $t('MdPatientRegistrationsHistory.RegistrationsHistory') }} </h6>
					</div>
					<div class="text-muted p-fixed cutescroll">
						<p v-if="Programs.length === 0">{{ $t('MdPatientRegistrationsHistory.NoRegistrationHistory') }} </p>
						
						<ul>							
							<li v-for="(it, index) in Programs" :key="`it.patientCode-${index}`">
								{{it.programName}} (
								{{it.admitDate}} -
								<a v-if="it.dischargeDate"> {{ it.dischargeDate}} )</a>
								<a v-else> {{ $t('MdPatientRegistrationsHistory.Present') }}</a>
							</li>							
						</ul>
						</div>
						</div>
					</div>
					</div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
  export default {
    props: {
     PatientCode: String,
   },
    mixins: [DBFunctions],
    data() {
      return {
		Programs:[]
      }
    },
    
  methods:{
	async getPrograms(){
		this.Programs = await this.getProgramsByPatient(this.PatientCode);			
	},
  },
  mounted() {
	this.getPrograms();
   }
}

</script>