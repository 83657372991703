
<template>
  <div class="pr-1">
    <component v-if="Hide"
        :is="propTag"   
        :class="Class" 
        :id="Name"
        :title="isTruncated ? Text : ''"
        :ref="titleRef"
        v-b-tooltip.hover
        >            
          <b-icon v-if="VisibleAdd"
              @click="ClickAdd"
              icon="plus" 
              v-b-tooltip.hover.top="$t('Shared.Add')+' '+this.IconAddDescription" 
              class="rounded-circle editTitleButton mr-1" 
              scale=".5" 
              font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisibleAddBlank"
            @click="ClickAddBlank"
            icon="plus-circle-fill" 
            v-b-tooltip.hover.top="$t('Shared.Add')+' '+this.BlankDescription" 
            class="rounded-circle editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisibleAddExisting"
            @click="ClickAdd"
            icon="shield-fill-plus" 
            v-b-tooltip.hover.top="$t('Shared.Add')+' '+this.ExistingDescription" 
            class="rounded-circle editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisibleEdit"
            @click="ClickIcon"
            icon="pencil-fill" 
            v-b-tooltip.hover.top="$t('Shared.Edit')" 
            class="rounded-circle  editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisiblePrint"
            @click="ClickPrint"
            icon="printer-fill" 
            v-b-tooltip.hover.top="this.IconPrintDescription" 
            class="rounded-circle editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
          <div class="d-inline" v-html="Text"></div>
    </component>
  </div>
</template>

<script>
  export default {
    name: 'MdTitle',
    props: {
      IconAddDescription:String,
      Text:          String,
      isSubtitle:    Boolean,
      VisibleEdit :Boolean,
      VisibleAdd:Boolean,
      isHide:        Boolean,
      Name:          String,
      titleRef: String,   
      
      //TP
      VisibleAddBlank:Boolean,
      BlankDescription:String,
      VisibleAddExisting:Boolean,   
      ExistingDescription:String,
      IconPrintDescription:String,
      VisiblePrint:Boolean,
      propTag:{
        type: String,
        default: 'h4',
      },
    },
    data() {
      return {
          Class: this.isSubtitle ? "page-subtitle text-truncate" : "page-title text-truncate",
          Hide: this.isHide  ? false : true,
          isTruncated: false,
          isMovile: false
      }
    },
    methods:{
      ClickIcon(){
        this.$emit('ClickEdit');
      },

      ClickAdd(){
        this.$emit('ClickAdd');
      },

      ClickAddBlank(){
        this.$emit('ClickAddTPBlank');
      },

      ClickPrint(){
        this.$emit('ClickPrint');
      },

      checkTruncation() {
        if (this.$refs[this.titleRef])
          this.isTruncated = this.$refs[this.titleRef].offsetWidth < this.$refs[this.titleRef].scrollWidth;
      },

      addEventListeners() {
        this.$el.addEventListener('mouseenter', this.checkTruncation);
        this.$el.addEventListener('mouseleave', this.checkTruncation);
        // Events for movile
        if ( this.isMovile ) {
          this.$el.addEventListener('touchstart', this.checkTruncation);
          this.$el.addEventListener('touchend', this.checkTruncation);
          this.$el.addEventListener('touchcancel', this.checkTruncation);
        }
        // Re-check on window resize
        window.addEventListener('resize', this.checkTruncation);
      },
      removeEventListeners() {
        this.$el.removeEventListener('mouseenter', this.checkTruncation);
        this.$el.removeEventListener('mouseleave', this.checkTruncation);
        // Events for movile
        if ( this.isMovile ) {
          this.$el.removeEventListener('touchstart', this.checkTruncation);
          this.$el.removeEventListener('touchend', this.checkTruncation);
          this.$el.removeEventListener('touchcancel', this.checkTruncation);
        }
        window.removeEventListener('resize', this.checkTruncation);
      },
    },
    mounted(){
      this.isMovile = this.$store.state.isMovile;
      // Initially check if truncated on mount
      this.$nextTick(() => {
        this.addEventListeners();
        this.checkTruncation();
      });
    },
    beforeDestroy() {
      this.removeEventListeners();
    }
  }
</script>