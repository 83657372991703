<template>
	
	<div class="page-height">
		<!--<div class="col-12">-->
			<!--<MdTitle 
			v-bind:Text="'PATIENT DASHBOARD - ' + this.PatientTitle.fullName + ' - ' + this.$route.params.id" 
			/>-->

			<MdTitleRegInfo
				v-bind:RegistrationInfo="PatientTitle" 
				v-bind:titlePrefix="$t('PatientSummary.PatientDashboard')" 
				v-bind:titleRef="'titleElementPatientSummary'"
				v-bind:patientCode="this.$route.params.id">
			</MdTitleRegInfo>
			
			<div class="row">
				<MdPatientInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientInfoBox>

                <MdPatientAllergies	v-if="isUsePatientAllergies"					
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientAllergies>

				<MdPatientSpecialPrecautions v-if="isUsePatientSpecialPr"						
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientSpecialPrecautions>

				<MdPatientDiagnosis v-if="isUsePatientDiagnosis"
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientDiagnosis>

                <!--
                <MdPatientAlertsInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientAlertsInfoBox>
                -->
			</div>
		<!--</div>-->
		<div class="space-actions"></div>
		<div class="page-actions">
			<MdButton 
			v-bind:Label="$t('Shared.Back')"
			v-bind:Name="'btnpatientlist'"
			v-bind:ClassProp="'btn btn-primary mx-2 btn-login'"
			v-bind:IconClass="'ti-back-left pr-1'"
			v-on:click="GoToPatientList"/>
		</div>
	</div>
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from '@/common/utils'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'PatientSummary',
    mixins: [DBFunctions],
    data() {
		return {		
			InfoPatient:{},
                PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:''
            }, 
			patient:{},
			VisibleH6:false,
			Allergies:[],
			Diagnosis:[],
			OptionsRightMenu:{
				visible:true,
				ItemsList:[],
				btnvisible:false,
				itemdisabled: false,
				PatientCode:'',
				Mode: this.$attrs.editMode,
			},
            isUsePatientAllergies: this.$store.state.isUsePatientAllergies,
            isUsePatientSpecialPr: this.$store.state.isUsePatientSpecialPr,
            isUsePatientDiagnosis: this.$store.state.isUsePatientDiagnosis,
            isUsePatientMarsAlert: this.$store.state.isUsePatientMarsAlert,
		}
	},

	async mounted() {
		this.GetPatientByCode();
		this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
		this.OptionsRightMenu.Mode = this.edit_mode;
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.$emit('getVisibleMenu', this.OptionsRightMenu);
		this.$emit('getBtnRightMenu', this.OptionsRightMenu);
	},
	methods:{

	async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
		if(infoList.length > 0){
			this.PatientTitle.programName = '';
			this.PatientTitle.admitDate = '';
			this.PatientTitle.dischargeDate = '';
			const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

			if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
			if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
			if (age) this.PatientTitle.age = age;
			if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     			
		}
    },

		GoToPatientInfo(){
		this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } }).catch(()=>{});
		},

		GoToPatientList(){
			this.checkExistFavorite('/app/patients');
			this.$router.push('/app/patients').catch(()=>{});
		},

		VisibleAsam(){
			if(this.$store.state.isUseASAM) {
				return true;
			}
			return false;
		},
			
		formatterDate(value){
			return Utils.formatterDate(value);
		},
		formatterDateToString(date){
			return Utils.formatterDateToString(date);
		},
	}
}


</script>


