<template>
  <div id="treatmentplanList">
    <div class="row">
      <div class="col-12">
        <MdTitleRegInfo v-if="VisibleBar"
            v-bind:RegistrationInfo="RegistrationInfo" 
            v-bind:titlePrefix="$t('treatmentplan.Treatmentplan')" 
            v-bind:IsClinical="true"
            v-bind:titleRef="'titleElementTreatmentplanList'"
            v-bind:patientCode="this.$route.params.id">
        </MdTitleRegInfo>
       
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <MdButton v-bind:Label="$t('treatmentplan.AddProblem')"
              v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'" v-on:click="AddProblem" v-bind:isReadOnly="this.isReadOnly || ReadOnlyProp"
              v-bind:Name="'btn_add_problem'">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-6 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input type="text" class="form-control" v-model="parameter.SearchCriteria"
                  @keyup.enter="SearchProblems" :placeholder="$t('treatmentplanList.sProbDes')">

                <div class="input-group-append">
                  <MdButton v-bind:Name="'btnsearchinput'" v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'" v-on:click="SearchProblems">
                  </MdButton>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">  
            <MdSelect
              v-bind:Label="$t('MdNote.Versions')"
              v-bind:Options="VersionList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:AllowEmpty="false"
              v-bind:NoEmitChange="true"
              v-bind:Model="VersionModel"
              v-bind:Multiple="false"              
              :Input="ChangeDropDownVersions"
              />         
        </div>
        </div>

        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="this.VersionModel.code > 0">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('MdNote.pNoteVersionMsg') }}</span>
          </div>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div v-if="VisibleNoRecords" class="empty-row-banner m-3">
          <div class="text-center my-2">{{$t('treatmentplanList.nProbYet')}}</div>
        </div>
        <div v-if="VisibleNoRecordsWithFilter" class="empty-row-banner m-3">
          <div class="text-center my-2">{{$t('treatmentplanList.nProbMa')}}</div>
        </div>

        
        <div v-for="(item, index) in this.TreatmentPlan.problems" :key="index" class="IncidentContainerBox">


          <div class="pull-right containerBoxTitle ml-5">
            <!-- <b-icon icon="pencil-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="'Edit Problem'" scale=".5" font-scale="1.6" variant="light"></b-icon>-->
            <b-icon icon="printer-fill" @click="PrintOnlyProblem(item)" v-b-tooltip.hover.top="$t('treatmentplanList.printProb')"
              scale=".5" font-scale="1.6" variant="light"></b-icon>           
          </div>


          <div class="row">
            <div class="col-md-12">
              <div class="grid-margin stretch-card">
                <div class="card activeCard">
                  <div class="card-body">
                    <div v-b-toggle="'probList' + index + ' probListBanner' + index"
                      class="d-flex align-items-start justify-content-between">
                      <h6 class="card-title flex-grow lower-line text-truncate">
                        {{$t('modalEditProblem.Problem')}}: {{ item.problem.probDesc }}
                      </h6>
                    </div>
                    <b-collapse :id="'probListBanner' + index" class="small-info ml-2" visible><small>{{$t('treatmentplanList.cliTiteltextP')}}</small></b-collapse>
                    <b-collapse :id="'probList' + index">
                      <div class="flex-container">


                        <!--<div class="subtitleButtons">
                                      <div class="containerBoxTitle">                                          
                                          <b-icon icon="pencil-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="'Edit Problem'" scale=".5" font-scale="1.6" variant="light"></b-icon>                                          
                                      </div>
                                  </div>
                                  <h4 class="page-subtitle text-truncate">Problem</h4>-->
                        <MdTitle v-bind:VisibleEdit="!isReadOnly && !ReadOnlyProp" v-bind:VisiblePrint="true" 
                          @ClickPrint="PrintProblem(item)" @ClickEdit="OpenModal(item)" class="shrinkMargin"
                          v-bind:Text="$t('modalEditProblem.Problem')" v-bind:IconPrintDescription="$t('treatmentplanList.proReview')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleProblem'" />

                        <div class="row">
                          <div class="col-md-3">
                            <div class="float-left col text-muted p-item">
                              <small>{{$t('modalEditProblem.Problem')}}</small><br>
                              <b>{{ item.problem.probDesc }}</b>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="float-left col text-muted p-item">
                              <small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                              <b>{{ item.problem.probDate }}</b>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="float-left col text-muted p-item">
                              <small>{{$t('treatmentplanList.createdBy')}}</small><br>
                              <b>{{ item.problem.userName }}</b>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="float-left col text-muted p-item">
                              <small>{{$t('treatmentplanList.cDate')}}</small><br>
                              <b>{{ item.problem.dateTimeCreate }}</b>
                            </div>
                          </div>
                        </div>
                   

                        <MdTitle @ClickEdit="OpenModalBehavioral(item)"
                          @ClickAddTPBlank="OpenModalBlankBehavioral(item)" v-bind:VisibleAddBlank="false"
                          v-bind:BlankDescription="$t('treatmentplan.PsychosocialEnvironmental')" v-bind:VisibleEdit="!isReadOnly && !ReadOnlyProp" v-bind:Text="$t('treatmentplan.PsychosocialEnvironmental')"
                          v-bind:isSubtitle="true" v-bind:Name="'T_TitleBehavioral'" />

                        <div class="row">
                          <div class="col-md-12">
                            <div class="wizObjectives" v-for="(agg, aggindex) in item.problemAggregates" :key="aggindex">
                                <b-icon icon="check" class="wizardCheckObj"></b-icon> <span> {{agg.aggName}} </span>
                                <div class="ml-3 mt-2">
                                    <ul>							
                                        <li v-for="(it, indexit) in agg.list" :key="indexit"> {{it.name}}</li>						
                                    </ul>
                                </div>
                            </div>
                          </div>
                        </div>


                        <!--<div class="subtitleButtons">
                                        <div class="containerBoxTitle">
                                           
                                        </div>
                                    </div>
                                    <h4 class="page-subtitle text-truncate">Goals</h4>-->

                        <MdTitle @ClickAddTPBlank="OpenModalGoal(item)" v-bind:VisibleAddBlank="!isReadOnly && !ReadOnlyProp"
                          v-bind:BlankDescription="$t('modalAddGoal.goals')" v-bind:Text="$t('modalAddGoal.goals')" v-bind:isSubtitle="true"
                          v-bind:Name="'T_TitleGoal'" />

                        <div class="row">
                          <div class="col-md-12">
                            <div class="text-muted p-item">
                              <div v-for="(g, goalindex) in item.goals" :key="goalindex">
                                <!-- GOALS -->
                                <!--<MdTitle v-bind:Text="'Goal ' +(goalindex+1)" v-bind:isSubtitle="true" v-bind:Name="'T_TitleGoals'"/>-->
                                <div class="mb-2 ml-2">
                                  <b-row v-b-toggle="'goal' + index + (goalindex + 1)">
                                    <b-col>
                                      <!-- Condicionar esto para mostrar estados específicos -->
                                      <!-- Si el estado es "MET", eliminar la clase completeDraft -->
                                      <span v-if="g.goal.status" class="chRstatusfinished completeDraft align-middle">{{ g.goal.status
                                        }}</span>
                                      <p class="font-weight-bold ml-1"><b-icon class="when-closed" font-scale="1"
                                          variant="primary" icon="caret-down-fill"></b-icon><b-icon class="when-open"
                                          font-scale="1" variant="primary" icon="caret-up-fill"></b-icon>{{$t('modalEditGoal.goal')}}
                                        {{ goalindex + 1 }} {{ g.goal.goalDesc }}</p>
                                    </b-col>
                                  </b-row>
                                </div>
                                <b-collapse :id="'goal' + index + (goalindex + 1)" class="mb-3 ml-2">

                                  <b-button-group class="ml-3 groupActions" v-if="!isReadOnly && !ReadOnlyProp">
                                    <b-button v-on:click="OpenModalAddObjective(item, g)" v-b-tooltip.hover
                                      title="Add objective" size="sm" variant="light">
                                      <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
                                    </b-button>
                                    <b-button v-on:click="OpenModalEditGoal(item, g)" v-b-tooltip.hover
                                      title="Edit goal" size="sm" variant="light">
                                      <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                    </b-button>
                                    <b-button v-on:click="DeleteGoal(g)" v-b-tooltip.hover :title="$t('modalEditProblem.DelGolaTT')" size="sm"
                                      variant="light">
                                      <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                    </b-button>
                                  </b-button-group>

                                  <b-card bg-variant="light">
                                    <b-row cols="3">
                                      <b-col><small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                        <p v-if="g.goal.dateSet">{{ g.goal.dateSet }}</p>
                                        <p v-else>{{$t('Shared.Notdefined')}}</p>
                                      </b-col>

                                      <b-col v-if="g.goal.dateExp"><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                        <p>{{ g.goal.dateExp }}</p>
                                      </b-col>
                                      <b-col v-else><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                        <p>{{$t('Shared.Notdefined')}}</p>
                                      </b-col>

                                      <b-col v-if="g.goal.dateAchieve"><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                        <p>{{ g.goal.dateAchieve }}</p>
                                      </b-col>
                                      <b-col v-else><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                        <p>{{$t('Shared.Notdefined')}}</p>
                                      </b-col>

                                      <b-col v-if="g.goal.status"><small>{{$t('Shared.Status')}}</small><br>
                                        <p>{{ g.goal.status }}</p>
                                      </b-col>
                                      <b-col v-else><small>{{$t('Shared.Status')}}</small><br>
                                        <p>{{$t('Shared.Notdefined')}}</p>
                                      </b-col>

                                      <b-col v-if="g.goal.sysUser"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                        <p>{{ g.goal.userName }}</p>
                                      </b-col>
                                      <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                        <p>{{$t('Shared.Notdefined')}}</p>
                                      </b-col>

                                      <b-col v-if="g.goal.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                        <p>{{ g.goal.dateTimeCreate }}</p>
                                      </b-col>
                                      <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                        <p>{{$t('Shared.Notdefined')}}</p>
                                      </b-col>
                                    </b-row>
                                    <div class="mb-2 ml-3" v-for="(o, oindex) in g.objectives" :key="oindex">
                                      <b-row v-b-toggle="'obj' + index + (goalindex + 1) + (oindex + 1)">
                                        <b-col>
                                          <!-- Condicionar esto para mostrar estados específicos -->
                                          <!-- Los posibles estados son: inprogress, met, unmet, revised, extended, deferred, reffered -->
                                          <!-- Con las 2 clases sale azul, sin la 2da, sale verde -->
                                          <span v-if="o.objective.status" class="chRstatusfinished completeDraft">{{ o.objective.status }}</span>
                                          <p class="mt-1"><span class="font-weight-bold"><b-icon class="when-closed"
                                                font-scale="1" variant="primary" icon="caret-down-fill"></b-icon><b-icon
                                                class="when-open" font-scale="1" variant="primary"
                                                icon="caret-up-fill"></b-icon> {{$t('modalAddObjective.objectives')}} {{ oindex + 1 }}</span>:
                                            {{ o.objective.objDesc }}</p>
                                        </b-col>
                                      </b-row>

                                      <b-collapse :id="'obj' + index + (goalindex + 1) + (oindex + 1)"
                                        class="mb-3 mt-2 ml-2">

                                        <b-button-group class="ml-3 groupActions whiteBtn" v-if="!isReadOnly && !ReadOnlyProp">
                                          <b-button v-on:click="OpenModalAddIntervention(item, g, o)" v-b-tooltip.hover
                                            :title="$t('treatmentplanList.addInt')" size="sm" variant="light">
                                            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
                                          </b-button>
                                          <b-button v-on:click="OpenModalEditObjective(item,o)" v-b-tooltip.hover
                                            title="Edit objective" size="sm" variant="light">
                                            <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                          </b-button>
                                          <b-button v-on:click="PrintObjective(o)" v-b-tooltip.hover
                                            title="Objective Review" size="sm" variant="light">
                                            <b-icon icon="printer-fill" aria-hidden="true"></b-icon>
                                          </b-button>
                                          <b-button v-on:click="DeleteObjective(o)" v-b-tooltip.hover
                                            :title="$t('modalEditProblem.DelObjTT')" size="sm" variant="light">
                                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                          </b-button>
                                        </b-button-group>

                                        <b-card bg-variant="white">
                                          <b-row cols="3">
                                            <!-- IMHO, la condición debería estar en el párrafo, que es lo que cambia -->
                                            <b-col v-if="o.objective.dateSet"><small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                              <p>{{ o.objective.dateSet }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>

                                            <b-col v-if="o.objective.dateExp"><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                              <p>{{ o.objective.dateExp }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>

                                            <b-col v-if="o.objective.dateAchieve"><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                              <p>{{ o.objective.dateAchieve }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>

                                            <b-col v-if="o.objective.status"><small>{{$t('Shared.Status')}}</small><br>
                                              <p>{{ o.objective.status }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('Shared.Status')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>

                                            <b-col v-if="o.objective.sysUser"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                              <p>{{ o.objective.userName }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>

                                            <b-col v-if="o.objective.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                              <p>{{ o.objective.dateTimeCreate }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>
                                          </b-row>
                                        </b-card>
                                      </b-collapse>
                                    
                                      <div class="mb-2 ml-2" v-for="(i, iindex) in o.interventions" :key="iindex">
                                        <b-row
                                          v-b-toggle="'int' + index + (goalindex + 1) + (oindex + 1) + (iindex + 1)">
                                          <b-col>
                                            <p class="mt-1"><span class="font-weight-bold"><b-icon class="when-closed"
                                                  font-scale="1" variant="primary"
                                                  icon="caret-down-fill"></b-icon><b-icon class="when-open"
                                                  font-scale="1" variant="primary" icon="caret-up-fill"></b-icon>
                                                {{$t('modalAddIntervention.intervention')}} {{ iindex + 1 }}</span>:
                                              {{ i.intDesc }}</p>
                                          </b-col>
                                        </b-row>

                                        <b-collapse :id="'int' + index + (goalindex + 1) + (oindex + 1) + (iindex + 1)"
                                          class="mb-3 mt-2 ml-2">

                                          <b-button-group class="ml-3 groupActions whiteBtn" v-if="!isReadOnly && !ReadOnlyProp">
                                            <b-button v-on:click="OpenModalEditIntervention(item, i)"
                                              v-b-tooltip.hover :title="$t('treatmentplanList.editInt')" size="sm" variant="light">
                                              <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                                            </b-button>
                                            <b-button v-on:click="DeleteIntervention(i)" v-b-tooltip.hover
                                              :title="$t('modalEditProblem.DelIntTT')" size="sm" variant="light">
                                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                            </b-button>
                                          </b-button-group>

                                          <b-card bg-variant="white">
                                            <b-row cols="3">  

                                              <b-col v-if="i.startDate"><small>{{$t('treatmentplanList.starOn')}}</small><br>
                                                <p>{{ i.startDate }}</p>
                                              </b-col>
                                              <b-col v-else><small>{{$t('treatmentplanList.starOn')}}</small><br>
                                                <p>{{$t('Shared.Notdefined')}}</p>
                                              </b-col>

                                              <b-col v-if="i.amount"><small>{{$t('modalEditIntervention.amount')}}</small><br>
                                                <p>{{ i.noAmt }} {{ i.amount }}</p>
                                              </b-col>
                                              <b-col v-else><small>{{$t('modalEditIntervention.amount')}}</small><br>
                                                <p>{{$t('Shared.Notdefined')}}</p>
                                              </b-col>

                                              <b-col v-if="i.frequency"><small>{{$t('Shared.Frequency')}}</small><br>
                                                <p>{{ i.noFrequency }} {{ i.frequency }}</p>
                                              </b-col>
                                              <b-col v-else><small>{{$t('Shared.Frequency')}}</small><br>
                                                <p>{{$t('Shared.Notdefined')}}</p>
                                              </b-col>

                                              <b-col v-if="i.duration"><small>{{$t('MdPainAssessmentNotesOutCome.Duration')}}</small><br>
                                                <p>{{ i.noDuration }} {{ i.duration }}</p>
                                              </b-col>
                                              <b-col v-else><small>{{$t('MdPainAssessmentNotesOutCome.Duration')}}</small><br>
                                                <p>{{$t('Shared.Notdefined')}}</p>
                                              </b-col>

                                              <b-col v-if="i.therapistCode"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                <p>{{ i.therapistName }}</p>
                                              </b-col>
                                              <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                <p>{{$t('Shared.Notdefined')}}</p>
                                              </b-col>

                                              <b-col v-if="i.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                <p>{{ i.dateTimeCreate }}</p>
                                              </b-col>
                                              <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                <p>{{$t('Shared.Notdefined')}}</p>
                                              </b-col>

                                            </b-row>
                                          </b-card>
                                          <b-card bg-variant="white" class="mt-2" v-if="i.therapyNames.length > 0">
                                            <b-row>
                                              <b-col><small>{{$t('modalEditIntervention.Modalities')}}</small><br>
                                                <ul>
                                                  <li v-for="(m, mindex) in i.therapyNames" :key="mindex">{{ m }}</li>
                                                </ul>
                                              </b-col>
                                            </b-row>
                                          </b-card>
                                        </b-collapse>
                                      </div>

                                    </div>
                                  </b-card>
                                </b-collapse>

                                <!-- OBJECTIVES -->


                              </div>


                            </div>
                          </div>
                        </div>


                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="row" v-if="VisibleSignature">
      <div class="col-12">
        <!-- div Container Boxs -->
        <div class="IncidentContainerBox noNum">
          <div class="row">
            <div class="col-md-12">
              <div class="grid-margin stretch-card">
                <div class="card activeCard">
                  <div class="card-body">
                    <div v-b-toggle="'signaList signaListBanner'"
                      class="d-flex align-items-start justify-content-between">
                      <h6 class="card-title flex-grow lower-line text-truncate">{{$t('modalAddSignature.sign', {'label': ""})}}</h6>
                    </div>
                    <div class="pull-right containerBoxTitle ml-5">
                      <b-icon icon="plus-circle-fill" @click="AddSignature" v-b-tooltip.hover.top="$t('modalAddSignature.sign', {'label': $t('Shared.Add')})"
                        scale=".5" font-scale="1.6" variant="light" v-if="!isReadOnly && !ReadOnlyProp"></b-icon>
                    </div>
                    <b-collapse id="signaListBanner" class="small-info ml-2" visible><small>{{$t('treatmentplanList.cliTiteltext')}}</small></b-collapse>
                    <b-collapse id="signaList">
                      <div v-for="(s, sindex) in TreatmentPlan.signatures" :key="sindex">


                        <b-button-group class="ml-3 groupActions" v-if="!isReadOnly && !ReadOnlyProp">
                          <b-button v-if="TreatmentPlan.signatures.length - 1 == sindex" v-on:click="AddSignature"
                            v-b-tooltip.hover :title="$t('modalAddSignature.sign', {'label': $t('Shared.Add')})" size="sm" variant="light">
                            <b-icon icon="plus-circle-fill" aria-hidden="true"></b-icon>
                          </b-button>
                          <b-button v-if="TreatmentPlan.signatures.length > 0" v-on:click="EditSignature(s)"
                            v-b-tooltip.hover :title="$t('modalAddSignature.sign', {'label': $t('Shared.Edit')})" size="sm" variant="light">
                            <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                          </b-button>
                          <b-button v-on:click="DeleteSignature(s)" v-b-tooltip.hover :title="$t('modalAddSignature.sign', {'label': $t('Shared.Delete')})" size="sm"
                            variant="light">
                            <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                          </b-button>
                          <!--<b-button v-on:click="DeleteGoal(g)" v-b-tooltip.hover title="Delete signature" size="sm" variant="light">
                                              <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                                            </b-button>-->
                        </b-button-group>
                        <b-card bg-variant="light" class="mb-3">
                          <div>
                            <b-row>
                              <div class="float-left col-md-3 text-muted p-item">
                                <small>{{$t('modalProviderSelector.Provider')}}</small><br>
                                <b v-if="s.therapistName">{{ s.therapistName }}</b>
                                <b v-else>{{$t('treatmentplanList.nProvider')}}</b>
                                <br>
                                <span v-if="s.therapistName">{{ s.signedDate }}</span>
                              </div>

                              <div class="float-left col-md-3 text-muted p-item">
                                <small>{{$t('treatmentplanList.CoProvider')}}</small><br>
                                <b v-if="s.coTherapistName && s.coTherapistName.trim() !== ''">{{ s.coTherapistName }}</b>
                                <b v-else>{{$t('treatmentplanList.nCoProSign')}}</b>
                                <br>
                                <span v-if="s.coTherapistName">{{ s.coSignedDate }}</span>
                              </div>

                              <div class="float-left col-md-3 text-muted p-item">
                                <small>{{$tc('Shared.Patient')}}</small><br>
                                <b v-if="s.patientName !== '' && s.refuseSigned === false">{{ s.patientName }}</b>
                                <b v-if="s.refuseSigned">{{$t('modalAddSignature.pRefused')}}</b>
                                <b v-if="s.refuseSigned === false && s.patientName === ''">{{$t('treatmentplanList.nPaSign')}}</b>
                               
                                <br>
                                <span v-if="s.patientSignedDate">{{ s.patientSignedDate }}</span>
                              </div>

                              <div class="float-left col-md-3 text-muted p-item">
                                <small>{{$t('modalAddSignature.guardian')}}</small><br>
                                <b v-if="s.guardianName">{{ s.guardianName }}</b>
                                <b v-else>{{$t('treatmentplanList.nGaSign')}}</b>
                                <br>
                                <span v-if="s.guardianName">{{ s.guardianSignedDate }}</span>
                              </div>
                            </b-row>
                          </div>



                        </b-card>
                      </div>
                    </b-collapse>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <mdModalEditSignature @ModalTreatmentPlanSignatureCANCEL="OnReturnSignatureCancel($event)"
      v-bind:Signatures="TreatmentPlan.signatures" v-if="showModalSignature" v-bind:ModalId="'modalIdEditSignature'"
      v-bind:TitleWindow="$t('modalAddSignature.sign', {'label': $t('Shared.Edit')})" @RefreshGetTP="RefreshGetTP" v-bind:SignatureEdit="SignatureEdit" />

    <mdModalEditTPProblem @ModalTPEditProblemCANCEL="OnEditTPProblemCancel($event)" v-if="VisibleEditTPProblem"
      v-bind:ModalId="'modalIdEditTPProblem'" v-bind:TitleWindow="$t('treatmentplanList.editP')" v-bind:ProbDesc="ProbDesc"
      v-bind:DiagDesc="DiagDesc" v-bind:DiagCode="DiagCode" v-bind:ProblemDateIdentified="ProblemDateIdentified"
      v-bind:ProblemRecId="ProblemRecId" @RefreshGetTP="RefreshGetTP" />

    <mdModalEditTPBehavioral v-if="VisibleEditTPBehavioral" v-bind:ModalId="'modalIdEditBehaviral'"
      v-bind:BehavioralsProp="BehavioralsModal" v-bind:TitleWindow="$t('treatmentplanList.mdeditB')" v-bind:probCode="this.probCode"  @RefreshGetTP="RefreshGetTP"
      v-bind:ProblemRecId="ProblemRecId" @ModalTPEditBehavioralCANCEL="ModalTPEditBehavioralCANCEL" v-bind:ItemSelected="ItemSelected"
      />













    <mdModalAddGoal @ModalTPGoal="ModalTPGoalCANCEL" v-if="VisibleAddGoals" v-bind:ModalId="'modalIdAddGoals'"
      v-bind:TitleWindow="$t('treatmentplanList.addG')" v-bind:groupId="this.groupId" v-bind:headerId="this.headerId"
      v-bind:probCode="this.probCode" v-bind:ProblemRecId="ProblemRecId" @RefreshGetTP="RefreshGetTP" />

    <mdModalAddObjective @ModalTPObjective="ModalTPObjectiveCANCEL" v-if="VisibleAddObjetive"
      v-bind:ModalId="'modalIdAddObjective'" v-bind:TitleWindow="$t('treatmentplanList.addObj')" v-bind:groupId="this.groupId"
      v-bind:headerId="this.headerId" v-bind:probCode="this.probCode" v-bind:ProblemRecId="ProblemRecId"
      v-bind:GoalListObj="GoalListObj" @RefreshGetTP="RefreshGetTP" v-bind:GoalRecId="GoalRecId" />

    <mdModalEditGoal @ModalTPEditGoal="ModalTPEditGoal" v-if="VisibleEditGoal" v-bind:ModalId="'modalIdEditGoal'"
      v-bind:TitleWindow="$t('treatmentplanList.editG')" v-bind:groupId="this.groupId" v-bind:headerId="this.headerId"
      v-bind:probCode="this.probCode" v-bind:ProblemRecId="ProblemRecId" @RefreshGetTP="RefreshGetTP"
      v-bind:GoalEdit="this.GoalEdit" />

    <mdModalAddIntervention @ModalTPAddIntervention="ModalTPAddIntervention" v-if="VisibleAddIntervention"
      v-bind:ModalId="'modalIdAddIntervention'" v-bind:TitleWindow="$t('treatmentplanList.addInt')" v-bind:groupId="this.groupId"
      v-bind:headerId="this.headerId" v-bind:probCode="this.probCode" v-bind:ProblemRecId="ProblemRecId"
      @RefreshGetTP="RefreshGetTP" v-bind:GoalListObj="GoalListObj" v-bind:ObjectiveListObj="ObjectiveListObj"
      v-bind:GoalRecId="GoalRecId" v-bind:ObjRecId="ObjRecId" />

    <mdModalEditObjective @ModalTPEditObjective="ModalTPEditObjective" v-if="VisibleEditObjective"
      v-bind:ModalId="'modalIdEditObjective'" v-bind:TitleWindow="$t('treatmentplanList.editObj')" v-bind:groupId="this.groupId"
      v-bind:headerId="this.headerId" v-bind:probCode="this.probCode" v-bind:ProblemRecId="ProblemRecId"
      @RefreshGetTP="RefreshGetTP" v-bind:ObjectiveEdit="this.ObjectiveEdit" />

    <mdModalEditIntervention @ModalTPEditIntervention="ModalTPEditIntervention" v-if="VisibleEditIntervention"
      v-bind:ModalId="'modalIdEditIntervention'" v-bind:TitleWindow="$t('treatmentplanList.editInt')" v-bind:groupId="this.groupId"
      v-bind:headerId="this.headerId" v-bind:probCode="this.probCode" v-bind:ProblemRecId="ProblemRecId"
      @RefreshGetTP="RefreshGetTP" v-bind:IntEditModal="this.InterventionEdit" />

    <mdModalAddSignature @ModalTPAddSignature="ModalTPAddSignature" v-if="VisibleAddSignature"
      v-bind:ModalId="'modalIdAddSignature'" v-bind:TitleWindow="$t('modalAddSignature.sign', {'label': $t('Shared.Add')})" v-bind:groupId="this.groupId"
      v-bind:headerId="this.headerId" v-bind:probCode="this.probCode" v-bind:ProblemRecId="ProblemRecId"
      @RefreshGetTP="RefreshGetTP" />

    <TreatmentPlanRep v-if="isReportVisible" :closeWindow="closeParamRepWindow" :patientCode="parameter.AdmissionId"
      :recNo="parameter.AdmissionId" :notesId="parameter.NotesId" :version="this.VersionModel.code" />
    <ObjectiveReviewRep v-if="isObjectiveRepVisible" :closeWindow="closeParamRepWindow" :recNo="this.RegistrationInfo.recordId"
    :objRecId="ObjRecId" />
    <ProblemReviewRep v-if="isProblemRepVisible" :closeWindow="closeParamRepWindow" :recNo="this.RegistrationInfo.recordId"
    :problemRecId="ProblemRecId"/>
    <TreatmentPlanProblemRep v-if="isTreatmentPlanProblemRepVisible" :closeWindow="closeParamRepWindow" :recNo="parameter.AdmissionId"
    :notesId="parameter.NotesId" :probRecId="ProblemRecId" :version="this.VersionModel.code" />
    <div class="space-actions"></div>
    <div class="page-actions" v-if="VisibleSignature">
      <b-dropdown split  :text="$tc('Shared.ActionsTxt',2)" class="mx-2" variant="primary" v-if="VisibleSignature">
        <b-dropdown-item-button v-on:click="VoidClick" v-if="!isReadOnly && !ReadOnlyProp">
          {{ $t('MdNote.Void') }}
        </b-dropdown-item-button>

        <b-dropdown-item-button v-on:click="showReport">
          {{ $t('Shared.Report') }}
        </b-dropdown-item-button>
      </b-dropdown>
    </div>

  </div>
</template>

<script>
import EnumService from '@/services/enum.service'
import DBFunctions from '@/common/DBFunctions.js'
//import GlobalService from '@/services/global.service'
import TreatmentPlanService from '@/services/treatmentplan.service'
import ClinicalService from '@/services/clinical.service'
import Utils from "@/common/utils";
import StorageService from '@/services/storage.service'
//import { EventBus } from '@/event-bus.js';

export default {
  name: 'TreatmentPlanList',
  mixins: [DBFunctions],
  props:{
    VisibleBar: {
      default: true,
      type: Boolean
    },
    ReadOnlyProp: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      NoteOptions:{},
      VersionList:[],
      isSupervisor:StorageService.IsSupervisor(),
      VersionModel:{code:0,description:this.$t('MdNote.Current')},
      ItemSelected:[],
      VisibleVersion:false,
      ProbDesc: "",
      DiagDesc: "",
      DiagCode: "",
      HeaderId:"",
      ProblemDateIdentified: "",
      ProblemRecId: "",
      groupId: 0,
      headerId: "",
      probCode: "",
      VisibleAddSignature: false,
      BehavioralsModal: [],
      GoalListObj: [],
      GoalRecId: "",
      ObjRecId: "",
      ObjectiveListObj: [],
      SignatureEdit:{},
      GoalEdit: {
        goalCode: "",
        goalRecId: "",
        dateIdentified: null,
        dateExpected: null,
        dateAchieve: null,
        status: {
          code: "",
          description: ""
        },
        description: "",
      },
      ObjectiveEdit: {
        objCode: "",
        objRecId: "",
        dateIdentified: null,
        dateExpected: null,
        dateAchieve: null,
        status: {
          code: "",
          description: ""
        },
        description: "",
      },

      InterventionEdit: {
        startDate: null,
        intCode: "",
        intDesc: "",
        therapistCode: "",
        intRecId: "",
        therapyCode: "",
        noAmt: null,
        amount: null,
        amountObj: { code: "", description: "" },
        frequency: null,
        frequencyObj: { code: "", description: "" },
        noDuration: null,
        duration: null,
        durationObj: { code: "", description: "" },
        noFrequency: null,
        coTherapistCode: null,
        isEvidenceBased: false,
        therapistName: "",
        coTherapistName: "",
        therapyCodes: [],// therapyCodes: ["12001"], Codes
        therapyNames: [] // therapyNames: ["Simple Repair Of Superficial Wounds, 2.5Cm Or Less"], Names
      },

      visibleEditProblem: false,
      VisibleAddObjetive: false,
      VisibleEditGoal: false,
      VisibleEditIntervention: false,
      VisibleAddIntervention: false,
      VisibleEditObjective: false,
      VisibleAddGoals: false,
      VisibleBlankTPBehavioral: false,
      ModalIdEditProblemTP: "ModalIdEditProblemTP",
      ModalIdEditBehavioralTP: "ModalIdEditBehavioralTP",
      VisibleEditTPProblem: false,
      VisibleEditTPBehavioral: false,
      VisibleNoRecords: false,
      VisibleNoRecordsWithFilter:false,
      VisibleSignature: false,
      TreatmentPlan: {},
      showModalSignature: false,
      SelectedEdit: {},
      RegistrationInfo: {},
      isReadOnly: false,
      ShowMessageIsEmpty: false,
      FieldsDateArr: ['probDate', 'dateTimeCreate'],
      OptionsRightMenu: {
        visible: true,
        ItemsList: [],
        btnvisible: true,
        itemdisabled: false,
      },
      Header: {},
      parameter: {
        SearchCriteria: '',
        PageNumber: 0,
        PageSize: 0,
        Code: '',
        AdmissionId: this.$route.params.recordid,
        NotesId: this.$route.params.notesid,
        HeaderId: '',
      },
      items: [],
      fields: [
        //{ key: 'transDate', sortable: true, label: this.$t('Shared.Date'), class: 'text-rigth' },   
        { key: 'probDate', sortable: true, label: this.$t('treatmentplanList.pDate'), class: 'text-rigth' },
        { key: 'probCode', sortable: true, label: this.$t('treatmentplanList.pCode'), class: 'hidden' },
        { key: 'probDesc', sortable: true, label: this.$t('modalEditProblem.Problem'), class: 'text-rigth' },
        { key: 'problemGroup', sortable: true, label: this.$t('treatmentplanList.pGroup'), class: 'text-rigth' },
        { key: 'dsm5description', sortable: true, label: this.$t('treatmentplanList.dsm5CodeDescription'), class: 'text-rigth' },
        { key: 'dsm5', sortable: true, label: this.$t('treatmentplanList.dsm5Code'), class: 'text-rigth' },
        { key: 'sysUser', sortable: true, label: this.$t('treatmentplanList.createdBy'), class: 'text-rigth' },
        { key: 'dateTimeCreate', sortable: true, label: this.$t('treatmentplanList.cDate'), class: 'text-rigth' },
        { key: 'headerId', sortable: true, label: 'Header Id', class: 'hidden' },
        { key: 'probRecId', sortable: true, label: 'ProbRecId', class: 'hidden' },
        { key: 'groupId', sortable: true, label: 'Group Id', class: 'hidden' },
      ],
      pageOptions: [5, 10, 15],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      isReportVisible: false,
      isObjectiveRepVisible: false,
      isProblemRepVisible: false,
      isTreatmentPlanProblemRepVisible: false
    }
  },
  methods: {
    AddProblem() {     
     if(this.$route.name.includes('Clinical')){
        this.$router.push({ name: "MdTreatmentplan-add-Clinical", params: { id: this.$route.params.id, recordid: this.$route.params.recordid,notesid:this.$route.params.notesid } });
      }else{
        this.$router.push({ name: "MdTreatmentplan-add-Appointment", params: { id: this.$route.params.id, recordid: this.$route.params.recordid,notesid:this.$route.params.notesid,actId:this.$route.params.actId } });
      }
    },

    EditProblem(item) {      
      console.log(item);
    },

    EditSignature(s) {     
      s.ModelClient = {code:"",name:""};
      s.ModelClientCheck = false;
      if(s.isPatientSigned){
        s.ModelClientCheck = true;
        s.ModelClient = {code:"PatientSigned",name:this.$t('modalAddSignature.pSigned')};
      }
    
    if(s.refuseSigned){
      s.ModelClientCheck = true;
      s.ModelClient = {code:"PatientRefused",name:this.$t('modalAddSignature.pRefused')};
    }

    if(s.guardianSigned){
      s.ModelClientCheck = true;           
      s.ModelClient = {code:"GuardianSigned",name:this.$t('modalAddSignature.gSigned')};       
    }
      this.SignatureEdit = {...s};
      this.showModalSignature = true;
    },

    AddSignature() {     
      this.headerId = this.TreatmentPlan.problems[0].problem.headerId;
      this.VisibleAddSignature = true;
    },

    OpenModal(item) {     
      this.ProbDesc = item.problem.probDesc;
      this.DiagDesc = item.problem.dsm5description;
      this.DiagCode = item.problem.dsm5;
      this.ProblemDateIdentified = item.problem.probDate;
      this.ProblemRecId = item.problem.probRecId;
      this.VisibleEditTPProblem = true;
    },

    CopyProblemDefinitions(arr){
      let temp = [];

      for(var i = 0; i < arr.length; i++){
        temp.push({...arr[i]});
      }

      return temp;
    },

    OpenModalBehavioral(item) {       
      this.ItemSelected = item.problemAggregates;     
      this.ProblemRecId = item.problem.probRecId;      
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.BehavioralsModal = this.CopyProblemDefinitions(item.problemDefinitions);// item.problemDefinitions;
      this.VisibleEditTPBehavioral = true;
    },
    ModalTPEditObjective() {
      this.VisibleEditObjective = false;
    },

    ModalTPAddSignature() {
      this.VisibleAddSignature = false;
    },

    ModalTPBlankBehavioralCANCEL() {
      this.VisibleBlankTPBehavioral = false;
    },

    ModalTPEditBehavioralCANCEL() {
      this.VisibleEditTPBehavioral = false;
    },

    ModalTPAddIntervention() {
      this.VisibleAddIntervention = false;
    },

    ModalTPEditIntervention() {
      this.VisibleEditIntervention = false;
    },

    ModalTPEditGoal() {
      this.VisibleEditGoal = false;
    },

    ModalTPGoalCANCEL() {
      this.VisibleAddGoals = false;
    },

    getVersions(){
      ClinicalService.getNotesHeaderVers(this.HeaderId,this.$route.params.notesid)
                .then((response) => {
                    if(response.status == '200'){
                       this.VersionList = response.data;
                       this.VisibleVersion = this.VersionList.length <= 1 ? false : true;                         
                    }                             
                })
                .catch((error) => {                     
                    if (!error.response) {
                   this.showMessage(this.$t('MdNote.GetNotesHeader'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('MdNote.GetNotesHeader'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('MdNote.GetNotesHeader'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('MdNote.GetNotesHeader'),error.response.data.errorMessage,"error");
                     } 
                     this.Load = false;
                     this.VisibleVersion = false;
                }); 
    },

    ChangeDropDownVersions(item){
        this.VersionModel =  item ? item : {code:0,description:"Current"}; 
       // this.Load = true;
       this.getProblems();
       // this.getNotesHeader(this.VersionModel.code);
      //  this.getControlsAndValues(this.VersionModel.code);
        
        if(this.VersionModel.code > 0){
         // this.NoteIsReadOnly = true;  
         this.isReadOnly = true;    
        }else{
          //this.NoteIsReadOnly = false;
          this.isReadOnly = false;  
        }
        //this.showPrint = true;
    },

    async getNoteOption(){
      this.NoteOptions = await this.getNoteOptions();   
    },

    DeleteSignature(s){
this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title:  this.$tc('Shared.DelRecord'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning',
        id: 'modal_delete_tp_signature'
      })
        .then(value => {
          if (value) {           
            TreatmentPlanService.deleteSignature(s.signatureRecId)
              .then((response) => {
                if (response.status == '200') {
                  this.RefreshGetTP();
                  setTimeout(() =>
                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                      title: this.$t('Msg.Saving'),
                      autoHideDelay: 5000,
                      variant: "success"
                    })
                    , 5);
                }
                this.$emit('load', false);
              })
              .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                  this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Delete')}), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                  this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Delete')}), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                  this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Delete')}), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                  this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Delete')}), error.response.data.errorMessage, "error");
                }
              });
          }
        })
    },

    DeleteIntervention(i) {
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$tc('Shared.DelRecord'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning',
        id: 'modal_delete_tp_Intervention'
      })
        .then(value => {
          if (value) {           
            TreatmentPlanService.deleteIntervention(i.intRecId)
              .then((response) => {
                if (response.status == '200') {
                  this.RefreshGetTP();
                  setTimeout(() =>
                    this.$bvToast.toast(this.$t('treatmentplan.DeleteIntervention'), {
                      title: this.$t('Msg.Saving'),
                      autoHideDelay: 5000,
                      variant: "success"
                    })
                    , 5);
                }
                this.$emit('load', false);
              })
              .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                  this.showMessage(this.$t('modalEditProblem.DelIntTT'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                  this.showMessage(this.$t('modalEditProblem.DelIntTT'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                  this.showMessage(this.$t('modalEditProblem.DelIntTT'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                  this.showMessage(this.$t('modalEditProblem.DelIntTT'), error.response.data.errorMessage, "error");
                }
              });






          }
        })
    },

    DeleteGoal(g) {
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$tc('Shared.DelRecord'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning',
        id: 'modal_delete_tp_Goal'
      })
        .then(value => {
          if (value) {
            this.$emit('load', true);
            TreatmentPlanService.deleteGoal(g.goal.goalRecId)
              .then((response) => {
                if (response.status == '200') {
                  this.RefreshGetTP();
                  setTimeout(() =>
                    this.$bvToast.toast(this.$t('treatmentplan.DeleteGoal'), {
                      title: this.$t('Msg.Saving'),
                      autoHideDelay: 5000,
                      variant: "success"
                    })
                    , 5);
                }
                this.$emit('load', false);
              })
              .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                  this.showMessage(this.$t('modalEditProblem.DelGolaTT'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                  this.showMessage(this.$t('modalEditProblem.DelGolaTT'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                  this.showMessage(this.$t('modalEditProblem.DelGolaTT'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                  this.showMessage(this.$t('modalEditProblem.DelGolaTT'), error.response.data.errorMessage, "error");
                }
              });
          }
        })
    },

    DeleteObjective(o) {
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$tc('Shared.DelRecord'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning',
        id: 'modal_delete_tp_Objective'
      })
        .then(value => {
          if (value) {
            this.$emit('load', true);
            TreatmentPlanService.deleteObjective(o.objective.objRecId)
              .then((response) => {
                if (response.status == '200') {
                  this.RefreshGetTP();
                  setTimeout(() =>
                    this.$bvToast.toast(this.$t('treatmentplan.DeleteObjective'), {
                      title: this.$t('Msg.Saving'),
                      autoHideDelay: 5000,
                      variant: "success"
                    })
                    , 5);
                }
                this.$emit('load', false);
              })
              .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                  this.showMessage(this.$t('modalEditProblem.DelObjTT'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                  this.showMessage(this.$t('modalEditProblem.DelObjTT'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                  this.showMessage(this.$t('modalEditProblem.DelObjTT'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                  this.showMessage(this.$t('modalEditProblem.DelObjTT'), error.response.data.errorMessage, "error");
                }
              });



          }
        })
    },

    OpenModalAddObjective(item, g) {     
      this.GoalListObj = [];
      this.GoalListObj.push(g.goal.goalCode);
      this.ProblemRecId = item.problem.probRecId;
      this.GoalRecId = g.goal.goalRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.VisibleAddObjetive = true;
    },

    OpenModalAddIntervention(item, g, o) {
      this.GoalListObj = [];
      this.GoalListObj.push(g.goal.goalCode);
      this.ObjectiveListObj = [];
      this.ObjectiveListObj.push(o.objective.objCode);
      this.ProblemRecId = item.problem.probRecId;
      this.GoalRecId = g.goal.goalRecId;
      this.ObjRecId = o.objective.objRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.VisibleAddIntervention = true;
    },

    OpenModalEditIntervention(item, i) { 
      this.InterventionEdit = i;
      this.InterventionEdit.amountObj = { code: i.amount, description: i.amount };
      this.InterventionEdit.frequencyObj = { code: i.frequency, description: i.frequency };
      this.InterventionEdit.durationObj = { code: i.duration, description: i.duration };
      this.ProblemRecId = item.problem.probRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.VisibleEditIntervention = true;
    },

    OpenModalEditGoal(item, g) {    
      this.ProblemRecId = item.problem.probRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.GoalEdit.goalRecId = g.goal.goalRecId;
      this.GoalEdit.goalCode = g.goal.goalCode;
      this.GoalEdit.dateIdentified = g.goal.dateSet;
      this.GoalEdit.dateExpected = g.goal.dateExp;
      this.GoalEdit.dateAchieve = g.goal.dateAchieve;
      this.GoalEdit.status = g.goal.statusobj;
      this.GoalEdit.description = g.goal.goalDesc;

      this.VisibleEditGoal = true;
    },

    OpenModalEditObjective(item,o) {     
      this.ProblemRecId = item.problem.probRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.ObjectiveEdit.objRecId = o.objective.objRecId;
      this.ObjectiveEdit.objCode = o.objective.objCode;
      this.ObjectiveEdit.dateIdentified = o.objective.dateSet;
      this.ObjectiveEdit.dateExpected = o.objective.dateExp;
      this.ObjectiveEdit.dateAchieve = o.objective.dateAchieve;
      this.ObjectiveEdit.status = { code: o.objective.status, description: o.objective.status };
      this.ObjectiveEdit.description = o.objective.objDesc;

      this.VisibleEditObjective = true;
    },

    OpenModalGoal(item) {    
      this.ProblemRecId = item.problem.probRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.VisibleAddGoals = true;
    },


    OpenModalBlankBehavioral(item) {            
      this.ProblemRecId = item.problem.probRecId;
      this.groupId = item.problem.groupId;
      this.headerId = item.problem.headerId;
      this.probCode = item.problem.probCode;
      this.VisibleBlankTPBehavioral = true;
    },


    VoidProblem() {

    },

    SearchProblems() {
      this.getProblems();
    },

    OnReturnSignatureCancel() {
      this.showModalSignature = false;
    },

    OnEditTPProblemCancel() {
      this.VisibleEditTPProblem = false;
    },

    ModalTPObjectiveCANCEL() {
      this.VisibleAddObjetive = false;
    },


    showReport() {
      this.isReportVisible = true
    },
    closeParamRepWindow() {
      this.isReportVisible = false;
      this.isObjectiveRepVisible = false;
      this.isProblemRepVisible = false;
      this.isTreatmentPlanProblemRepVisible = false
    },

    VoidClick() {
      this.$emit('load', true);
      let data = {
        notesId: this.$route.params.notesid
      };
      TreatmentPlanService.VoidTP(this.HeaderId, data)
            .then((response) => { 
              this.$emit('load', false);
              if(response.status == '200'){  
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.tPlanVoidmsg'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 30);     
                  this.getProblems();                    
               }             
            })
            .catch((error) => {
              this.$emit('load', false);
                if (!error.response) {
                  this.showMessage(this.$t('treatmentplan.tPlanVoid'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                else if(error.response.status === 400 ){
                  this.showMessage(this.$t('treatmentplan.tPlanVoid'),error.response.data.errorMessage,"error");
                }   
                else if(error.response.status === 500){
                  this.showMessage(this.$t('treatmentplan.tPlanVoid'),this.$t('Msg.ThereWereSomeError'),"error");
                }else{
                  this.showMessage(this.$t('treatmentplan.tPlanVoid'),error.response.data.errorMessage,"error");
                }    
            });
    },

    FormatTreatmentPlanDates(arr) {    
      arr.problems.forEach(function (item) {
        if (item.problem.dateTimeCreate)
          item.problem.dateTimeCreate = Utils.formatterDateToString(item.problem.dateTimeCreate);
        if (item.problem.probDate)
          item.problem.probDate = Utils.formatterDateToString(item.problem.probDate);

        item.goals.forEach(function (g) {
          if (g.goal.dateAchieve)
            g.goal.dateAchieve = Utils.formatterDateToString(g.goal.dateAchieve);
          if (g.goal.dateExp)
            g.goal.dateExp = Utils.formatterDateToString(g.goal.dateExp);
          if (g.goal.dateSet)
            g.goal.dateSet = Utils.formatterDateToString(g.goal.dateSet);
          if (g.goal.dateTimeCreate)
            g.goal.dateTimeCreate = Utils.formatterDateToString(g.goal.dateTimeCreate);

          g.goal.statusobj = { code: g.goal.status, description: g.goal.status };

          g.objectives.forEach(function (o) {
            if (o.objective.dateAchieve)
              o.objective.dateAchieve = Utils.formatterDateToString(o.objective.dateAchieve);
            if (o.objective.dateExp)
              o.objective.dateExp = Utils.formatterDateToString(o.objective.dateExp);
            if (o.objective.dateSet)
              o.objective.dateSet = Utils.formatterDateToString(o.objective.dateSet);
            if (o.objective.dateTimeCreate)
              o.objective.dateTimeCreate = Utils.formatterDateToString(o.objective.dateTimeCreate);


            o.interventions.forEach(function (i) {
              if (i.dateTimeCreate)
                i.dateTimeCreate = Utils.formatterDateToString(i.dateTimeCreate);
              if (i.signedDate)
                i.signedDate = Utils.formatterDateToString(i.signedDate);
              if (i.startDate)
                i.startDate = Utils.formatterDateToString(i.startDate);

            })
          })
        })


      });

      arr.signatures.forEach(function (sig) {
        if (sig.coSignedDate)
          sig.coSignedDate = Utils.formatterDateToString(sig.coSignedDate);
        if (sig.patientSignedDate)
          sig.patientSignedDate = Utils.formatterDateToString(sig.patientSignedDate);
        if (sig.signedDate)
          sig.signedDate = Utils.formatterDateToString(sig.signedDate);

        if (sig.guardianSignedDate)
          sig.guardianSignedDate = Utils.formatterDateToString(sig.guardianSignedDate);

        if (sig.isPatientSigned === true) {
          sig.CheckRefuseReadOnly = true;
          sig.CheckGuardianReadOnly = true;
          sig.SelectGuardianReadOnly = true;
        } else {
          sig.CheckRefuseReadOnly = false;
          sig.CheckGuardianReadOnly = false;
          sig.SelectGuardianReadOnly = false;
        }
        sig.ProviderModel = { therapistCode: sig.therapistCode, therapistName: sig.therapistName };
        sig.CoProviderModel = { coTherapistCode: sig.coTherapistCode, coTherapistName: sig.coTherapistName };
      })

    },

    RefreshGetTP() {
      this.VisibleEditTPBehavioral = false;
      this.VisibleEditTPProblem = false;
      this.VisibleBlankTPBehavioral = false;
      this.VisibleAddGoals = false;
      this.VisibleAddObjetive = false;
      this.VisibleEditGoal = false;
      this.VisibleAddIntervention = false;
      this.VisibleEditObjective = false;
      this.VisibleEditIntervention = false;
      this.VisibleAddSignature = false;
      this.showModalSignature = false;
      this.getProblems();
    },

    async getProblems() {
      this.$emit('load', true);
      await TreatmentPlanService.getProblemsByAdmId(this.$route.params.recordid, this.$route.params.notesid,this.VersionModel.code, this.parameter.SearchCriteria)
        .then((response) => {
          if (response.status == '200' && response.data) {
            this.TreatmentPlan = response.data;

            if(this.TreatmentPlan.problems.length > 0){
              this.HeaderId = this.TreatmentPlan.problems[0].problem.headerId;

              if(this.NoteOptions.enableNoteVersioning && this.NoteOptions.showVersioOnlyForSupervisors){
                  if(this.isSupervisor){
                    this.getVersions();
                  }else{
                    this.VisibleVersion = false;
                  }       
              }
              else if(this.NoteOptions.enableNoteVersioning && !this.NoteOptions.showVersioOnlyForSupervisors){
                  this.getVersions();
              }
              else{
                  this.VisibleVersion = false;
              }
                          
            }

            for (var i = 0; i < this.TreatmentPlan.signatures.length; i++) {
              this.TreatmentPlan.signatures[i].disabledCheckClient = false;
            }

            this.VisibleSignature = this.TreatmentPlan.problems.length > 0 ? true : false;
            this.FormatTreatmentPlanDates(this.TreatmentPlan);            

            if(this.parameter.SearchCriteria.trim() === ""){
              this.VisibleNoRecordsWithFilter = false;
              this.VisibleNoRecords = this.TreatmentPlan.problems.length > 0 ? false : true;
            }else{
              this.VisibleNoRecords = false;
              this.VisibleNoRecordsWithFilter = this.TreatmentPlan.problems.length > 0 ? false : true;
            }
           


          }
          this.$emit('load', false);
        })
        .catch(error => {
          this.$emit('load', false);
          if (!error.response) {
            this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.retrieveInfo'), "error");
          }
          else if (error.response.status === 400) {
            this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
          } else if (error.response.status === 500) {
            this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.ThereWereSomeError'), "error");
          } else {
            this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
          }
        });
    },
    PrintObjective(o){
      this.ObjRecId = o.objective.objRecId
      this.isObjectiveRepVisible = true
    },
    PrintProblem(item){
      this.ProblemRecId = item.problem.probRecId
      this.isProblemRepVisible = true
    },
    PrintOnlyProblem(item){
      this.ProblemRecId = item.problem.probRecId
      this.isTreatmentPlanProblemRepVisible = true
    }
  },
  created() {

  },

  async mounted() { 
    this.getNoteOption();      
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    // this.$emit('getBtnRightMenu', this.OptionsRightMenu);

    this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
    const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

		if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
		if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
		if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
		if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;

    this.getProblems();
    this.isReadOnly = await this.$store.state.isTreatmentPlanClinicalEHRReadOnly; 
   
  }

};
</script>