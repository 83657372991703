<template>
    <div id="ContainerPatientAllergy">
       <div class="row">
           <div class="col-12">
              <MdTitleRegInfo
                  v-bind:RegistrationInfo="PatientTitle" 
                  v-bind:titlePrefix="$t('TreatmentPlanList.treatmentplan')" 
                  v-bind:IsClinical="IsClinical"
                  v-bind:titleRef="'titleElementTreatmentplanBase'"
                  v-bind:patientCode="this.$route.params.id">
              </MdTitleRegInfo>
             
             <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
               <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
               <div class="flex-grow-1">
                 <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
               </div>
             </div>
             <div class="row" v-if="visibleTable">          
                 <div class="col-sm-12 col-md-12 mb-4">
                     <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                         <div class="input-group">
                             <input 
                               type="text" 
                               class="form-control"
                               v-model="parameter.SearchCriteria"   
                                 @keyup.enter="Search"                            
                             >
                             <div class="input-group-append">
                                 <div class="input-group-append">
                                 <MdButton 
                                   v-bind:Name="'btnsearchinput'"
                                   v-bind:VariantProp="'btn btn-sm search-attached'"
                                   v-bind:IconClass="'mdi mdi-magnify'"
                                   v-on:click="Search">
                                 </MdButton>
                             </div>
                         </div>
                     </div>
                 </div>
             </div>
           </div>              
         </div>
       </div>
       <p  v-if="!visibleTable" class="mx-3 text-muted">{{$t('TreatmentPlanList.NoAdmRecId')}}</p>
       <div class="row" v-if="visibleTable">
         <div class="col-12 mt-2">
           <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
           <div class="row">
             <div class="col-md-12">
               <MdTable                
                 v-bind:fields="fields"
                 v-bind:items="items"
                 v-bind:totalRows="totalRows"
                 v-bind:isReadOnly="isReadOnly"
                 v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                 v-bind:currentPage="currentPage"
                 v-bind:perPage="perPage"               
                 v-bind:pageOptions="pageOptions"  
                 :onRowSelected="onRowSelected"
                 :viewIconEvent="ViewTP"
                 :onFiltered="onFiltered"
                 :pageChanged="pageChanged"
                 :perPageFunction="PerPageFunction"
                 
                 >                
               </MdTable>
             </div>
           </div>
         </div>
       </div>
       <div class="space-actions"></div>

           
     </div>
 </template>
 
 <script>
 import TreatmentPlan from '@/services/treatmentplan.service'
 import EnumService from '@/services/enum.service' 
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import DBFunctions from '@/common/DBFunctions.js'
 import Utils from '@/common/utils'
 
 export default {
 
   beforeRouteEnter(to, from, next) {
         next(async vm => {
             const iPI = await vm.$store.state.isUseTreatmentPlanClinical;
             if(iPI) next()
             else next('/accessdenied');
         }) 
   },
 
   mixins: [ShowMessage, DBFunctions],
   name: 'TreatmentPlanBase',
   data() {
     return {
       patient:{},
       IsClinical:false,
       RegistrationInfo:{},     
       ShowMessageIsEmpty:false,
       Loading:true,
       visibleTable:true,
       AppoitmentList:[],
       PatientTitle:{      
        programName:'',
        fullName:'',
        admitDate:'',
        dischargeDate:'',
        dob:'',
        activitytypename:'',
        startdatetime:'',
        ageFormatted:''
      }, 
       OptionsRightMenu:{
             visible:true,
             ItemsList:[],
             btnvisible:true,
             itemdisabled: false,
             PatientCode:''         
         },  
       
       parameter:{
         SearchCriteria:'',
         PageNumber : 0,
         PageSize:0 ,
         PatientCode:''             
       },
       fields: [
         { key: 'description', sortable: true, label: this.$t('TreatmentPlanList.description'), class: 'text-rigth' }, 
         { key: 'customFrameFriendly', sortable: true, label: this.$t('TreatmentPlanList.type'), class: 'text-rigth' }, 
         ],
       pageOptions: [5, 10, 15 ],
       totalRows: 0,
       currentPage: 1,
       perPage: 10,
       filter: null,
       filterOn: [],
       items: [],
       selected: [],
       Header:{}, 
       isReadOnly: false,
       currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee:{
          firstname:"",
              lastname:""
          }
      },
     }
   },
 
   computed: {
       sortOptions() {       
         return this.fields
           .filter(f => f.sortable)
           .map(f => {
             return { text: f.label, value: f.key }
           });
       }    
   },
     
   methods: {
    PopulatePatientTitle(){
      const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
      if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
      if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	

      if(this.$route.name.includes('treatmentplanbase-Clinical')){ 
        if (programName) this.PatientTitle.programName = programName; 
        if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
        if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
        if (patientName) this.PatientTitle.fullName = patientName;
        if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
        if (age) this.PatientTitle.age = age === 0 ? "0" : age;
        if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('treatmentplanbase-Scheduler')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          //this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    },
      async GetPatientByCode(){
       var infoList = await this.getInfoTitle(this.$route.params.id);
       if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';

          const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

          if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
          if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
          if (age) this.PatientTitle.age = age;
          if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
       }
     },
     Search(){
       //this.Loading = true;
       //this.$emit('load', this.Loading);
       this.items = [];
       this.currentPage = 1;
       this.parameter.PageNumber = 1;
       this.totalRows = this.items.length;
      // this.getTP();
     },
 
     ViewTP(item){   
      this.OptionsRightMenu.btnvisible = true;
      this.OptionsRightMenu.ItemsList =  this.$route.name.includes('treatmentplanbase-Clinical') ? EnumService.ClinicalItems : EnumService.AppointmentItems;     
      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R0':
            this.$store.commit('setlayoutStatus', 'L0R1');
        break;
        default:
            this.$store.commit('setlayoutStatus', 'L1R1');
      }
      this.$emit('getVisibleMenu', this.OptionsRightMenu); 

if(item.customframe === 'TfmWileyTreatmentPlanNotes'){
  if(this.$route.name.includes('treatmentplanbase-Clinical')){
    this.$router.push({ name: "WyTreatmentplan-Clinical", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, notesid: item.notesid } });
  }else{
    this.$router.push({ name: "WyTreatmentplan-Appointment", params: { id: this.$route.params.id, recordid: this.AppoitmentList[0].admissionRecordId.trim(), actId: this.$route.params.actId, notesid: item.notesid } });
  }
}

if(item.customframe === 'TFRMTREATMENTPLANNOTES'){
  if(this.$route.name.includes('treatmentplanbase-Clinical')){
    this.$router.push({ name: "MdTreatmentplan-Clinical", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, notesid: item.notesid  } });
  }else{
    this.$router.push({ name: "MdTreatmentplan-Appointment", params: { id: this.$route.params.id, recordid:this.AppoitmentList[0].admissionRecordId.trim(), actId: this.$route.params.actId, notesid: item.notesid } });
  }
}

     

     },
     
     DoCalls(){       
    if(this.$route.name.includes('treatmentplanbase-Scheduler') || this.$route.name.includes('appointment-Dashboard')){
      let param = {
        PageNumber : this.currentPage,           
        PageSize : this.perPage,
        PatientCode : this.$route.params.id,
        ActivityId:this.$route.params.actId,
      }     
        TreatmentPlan.getAppointmentByActivity(param)
       .then((response) => {
         if(response.status == '200' && response.data && response.data.length > 0){
          this.AppoitmentList = response.data;               
          this.visibleTable = (this.AppoitmentList[0].admissionRecordId === null || this.AppoitmentList[0].admissionRecordId === "") ? false : true;           
          if(this.visibleTable){
            this.getTP();
          }
          
         this.Loading = false;
         this.$emit('load', this.Loading);
         }
       })
       .catch(error => {
         this.Loading = false;
         this.$emit('load', this.Loading);
         if (!error.response) {
             // network error
             this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.retrieveInfo'),"error");
         } else {
             this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
         }
       });
        }else{
          this.getTP();
        }
     },
 
     getTP() { 
       this.parameter.PageNumber = this.currentPage;           
       this.parameter.PageSize = this.perPage;
       this.parameter.PatientCode = this.$route.params.id;
       this.parameter.IsTreatmentPlan = true;
       this.parameter.showAll = false;

       

       TreatmentPlan.getTPList(this.parameter)
       .then((response) => {
         if(response.status == '200' && response.data && response.data.length > 0){
          this.items = response.data;  
          let headerString = response.headers["x-pagination"];
          this.Header = JSON.parse(headerString);
          this.totalRows = this.Header.TotalCount;
         }
         if(this.items.length === 0){
            this.ShowMessageIsEmpty = true;
          }
       
          if(this.items.length === 1){
            let item = this.items[0];
            if(item.customframe === 'TfmWileyTreatmentPlanNotes'){
              if(this.$route.name.includes('treatmentplanbase-Clinical')){
                this.$router.push({ name: "WyTreatmentplan-Clinical", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, notesid: item.notesid } });
              }else{
                this.$router.push({ name: "WyTreatmentplan-Appointment", params: { id: this.$route.params.id, recordid: this.AppoitmentList[0].admissionRecordId.trim(), actId: this.$route.params.actId, notesid: item.notesid } });
              }
            }

            if(item.customframe === 'TFRMTREATMENTPLANNOTES'){
              if(this.$route.name.includes('treatmentplanbase-Clinical')){
                this.$router.push({ name: "MdTreatmentplan-Clinical", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, notesid: item.notesid  } });
              }else{
                this.$router.push({ name: "MdTreatmentplan-Appointment", params: { id: this.$route.params.id, recordid:this.AppoitmentList[0].admissionRecordId.trim(), actId: this.$route.params.actId, notesid: item.notesid } });
              }
            }
          }


         this.Loading = false;
         this.$emit('load', this.Loading);
       })
       .catch(error => {
         this.Loading = false;
         this.$emit('load', this.Loading);
         if (!error.response) {
             // network error
             this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.retrieveInfo'),"error");
         } else {
             this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
         }
       });
     },
 
     pageChanged (page) {            
       this.currentPage = page;    
      // this.getTP();     
     },
 
     PerPageFunction(perpage){
       this.perPage = perpage;  
       this.currentPage = 1;
      // this.getTP();  
     },
 
     onRowSelected(items) {    
      this.selected = items;
     },
 
     selectAllRows() {
       this.$refs.selectableTable.selectAllRows()
     },
 
     clearSelected() {
       this.$refs.selectableTable.clearSelected()
     },
 
     selectThirdRow() {
       this.$refs.selectableTable.selectRow(2)
     },
 
     unselectThirdRow() {
       this.$refs.selectableTable.unselectRow(2)
     },
 
     onFiltered(filteredItems) {
       this.totalRows = filteredItems.length
       this.currentPage = 1
     },

     async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }
   
   },
 
  async mounted() {
    this.$emit('load', this.Loading);  
    ////  this.getTP(); 
    this.DoCalls();
    this.OptionsRightMenu.visible = true;   
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('treatmentplanbase-Clinical') ? EnumService.ClinicalItems : EnumService.AppointmentItems;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);   
    this.isReadOnly = await this.$store.state.isTreatmentPlanClinicalEHRReadOnly; 

    if(this.$route.name.includes('treatmentplanbase-Scheduler')){  
        this.GetPatientByCode();
        await this.getCurrentAppointment()
    } 

    if(this.$route.name.includes('treatmentplanbase-Clinical')){      
      this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
      /* eslint-disable no-unused-vars */
      const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

      // if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
      if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
      this.IsClinical = dateTo ? false : true;
    }
    this.PopulatePatientTitle();
  },
   
  destroyed(){
    this.OptionsRightMenu.ItemsList = [];
    this.OptionsRightMenu.Mode = false;
    this.OptionsRightMenu.visible = false; 
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
  }
 };
 
 </script>