<template>
   <div class="row page-height">
      <div class="col-12">
      <h4 v-if="this.Mode" class="page-title text-truncate"> {{$t('InsurancesList.EditInsurance')}} </h4>
      <h4 v-if="!this.Mode" class="page-title text-truncate"> {{$t('InsurancesList.AddInsurance')}} </h4>

       <b-row>
         <b-col md="12" sm="12" lg="12">
            <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false">
          <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
            <div class="tab-pane fade show active" id="general" role="tabpanel">
              <b-row>
               <b-col lg="3" md="3" sm="12">
                   <MdLookup
                     v-bind:ModelCode="insurance.ocnaCode"
                     v-bind:ModelDescription="ocnaDescription"
                     v-bind:Label="$t('insuranceSetup.OCNA')"
                     v-bind:RefCode="'Program'"                   
                     v-bind:Api="'program'"
                     v-bind:FieldCode="'code'"                    
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnly"    
                     :SearchTitle="$t('insuranceSetup.OCNA')"
                     :GetValues="onCodeConditionOk"
                     v-bind:isRequired="true"
                     v-bind:isValid="isOCNAValid"
                     :InvalidFeedback="$t('insuranceSetup.InvalidOCNA')"
                     :SearchFieldCode="'Code'"
                  />
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.PayorId')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'payorId'"
                  v-model="insurance.payorId"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_payor'"
                  />     
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.CompanyName')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'companyName'"
                  v-model="insurance.companyName"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_comNme'"
                  />     
               </b-col>
               <b-col lg="3" md="3" sm="12">
                   <MdEdit 
                  v-bind:Label="$t('Shared.Address1')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'address1'"
                  v-model="insurance.address1"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_addrs1'"
                  />    
               </b-col>
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.Address2')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'address2'"
                  v-model="insurance.address2"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_addrs2'"
                  />     
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.Zip')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'zip'"
                  v-model="insurance.zip"
                  v-on:blur="insuranceZipBlur(insurance.zip)"
                  v-on:change="insuranceZipChange($event)"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_zip'"
                  />     
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.City')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'city'"
                  v-model="insurance.city"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_city'"
                  />     
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.State')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="2" 
                  v-bind:Name="'state'"
                  v-model="insurance.state"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_state'"
                  />     
               </b-col>
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.Country')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'country'"
                  v-model="insurance.country"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_country'"
               />  
               </b-col>  
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$tc('Contact.Contact')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'contact'"
                  v-model="insurance.contact"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_cotact'"
               />  
               </b-col> 
                <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Shared.Phone')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'phone'"
                  v-model="insurance.phone"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_ph'"
               />  
               </b-col>  
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Patient.LegalFax')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'fax'"
                  v-model="insurance.fax"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_lf'"
               />  
               </b-col> 
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.SpecialNotes')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'specialNotes'"
                  v-model="insurance.specialnotes"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_sn'"

                  />  
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.CleHouseCode')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'clearingHouseCode'"
                  v-model="insurance.clearingHouseCode"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_chc'"
               />  
               </b-col>  
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.Co')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'coPaypercentage'"
                  v-model="insurance.coPaypercentage"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_coP'"
               />  
               </b-col>  
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.MnThera')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'minimunDailyTherapies'"
                  v-model="insurance.minimunDailyTherapies"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_th'"
               />  
               </b-col>  
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('employee.Taxonomy')"
                  v-bind:Options="TaxonomyList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.taxonomy"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownTaxonomy"
                  :IsDisabled="isReadOnly"
               />
               </b-col> 
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.EIN-SSN')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'einssn'"
                  v-model="insurance.einssn"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_einssn'"
               />  
               </b-col> 
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('insuranceSetup.NPI')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'npi'"
                  v-model="insurance.npi"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_dpi'"

               />  
               </b-col> 
               <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                     v-bind:Label="$t('insuranceSetup.IsSolo')"
                     v-bind:Name="'checkbox-issolo'"
                     v-bind:ClassProp="'AlignCheck'"
                     v-bind:CheckedValue="true"
                     v-bind:UncheckedValue="false"
                     v-model="insurance.issolo"
                     v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
              </b-row>
              <b-row>
                  <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('Shared.Category')"
                  v-bind:Options="CategoryList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.category"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownCategory"
                  :IsDisabled="isReadOnly"
               />
               </b-col> 
              </b-row>
            </div>
            <div class="tab-pane fade" id="ecsinfo" role="tabpanel">
               <b-row>             
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.EcsInsCode')"
                  v-bind:Options="EcsInsCodeList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.ecsInsCode"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownEcsInsCode"
                  :IsDisabled="isReadOnly"
               />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.EcsFormat')"
                  v-bind:Options="EcsFormatList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.ecsFormat"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownEcsFormat"
                  :IsDisabled="isReadOnly"
               />
               </b-col>               
               <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('Insurance.colInsuranceType')"
                  v-bind:Options="InsuranceTypeList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.insurancetype"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownInsuranceType"
                  :IsDisabled="isReadOnly"
               />
               </b-col>
               <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('insuranceSetup.DowLine')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'downloadline'"
                  v-model="insurance.downloadline"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_dow'"

               />  
               </b-col>
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">             
               <MdRadioGroup
                  v-bind:Label="$t('insuranceSetup.Part')"
                  v-model="insurance.part"
                  v-bind:Options="optionsPart"
                  v-bind:Name="'radio-inline'"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>  
             <b-col lg="3" md="3" sm="12">             
               <MdRadioGroup
                  v-bind:Label="$t('insuranceSetup.EcsModeInd')"
                  v-model="insurance.ecsModeIndicator"
                  v-bind:Options="optionsEcsModeIndicator"
                  v-bind:Name="'radio-inline'"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col> 
            <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.EnLineBill')"
                  v-bind:Name="'checkbox-EnableLineBilling'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.enableLineBilling"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col> 
               <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.BiAllCh')"
                  v-bind:Name="'checkbox-BillAllChargesToZero'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.billAllChargesToZero"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('insuranceSetup.LeftMg')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'left'"
                  v-model="insurance.left"
                  v-bind:isReadOnly="isReadOnly"
               />  
               </b-col>
                <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('insuranceSetup.TopMg')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="50" 
                  v-bind:Name="'top'"
                  v-model="insurance.top"
                  v-bind:isReadOnly="isReadOnly"
                  v-bind:Id="'input_insu_tm'"

               />  
               </b-col>
               <b-col lg="3" md="3" sm="12">             
               <MdRadioGroup
                  v-bind:Label="$t('insuranceSetup.Network')"
                  v-model="insurance.network"
                  v-bind:Options="optionsNetwork"
                  v-bind:Name="'radio-inline'"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col> 
            <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.Ecs')"
                  v-bind:Name="'checkbox-ecs'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.ecs"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>  
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.UpLine')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'Uploadline'"
                     v-model="insurance.uploadline"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_upl'"
                  /> 
                 </b-col> 
                  <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.EcsId')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'EcsId'"
                     v-model="insurance.ecsid"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_escif'"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.EcsPw')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'Ecspassword'"
                     v-model="insurance.ecspassword"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_escpw'"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.oSignon')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'othersignon'"
                     v-model="insurance.othersignon"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_osig'"
                  /> 
                 </b-col>
              </b-row>
              <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.AddIdRPT')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'addidrpt'"
                     v-model="insurance.idrpt"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_addidrpt'"
                  /> 
                 </b-col> 
                  <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.AddId')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'addId'"
                     v-model="insurance.addid"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_addid'"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.BatchId')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'batchid'"
                     v-model="insurance.batchid"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_bach'"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.SignonRMT')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'signonrmt'"
                     v-model="insurance.signonrmt"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_dignon'"
                  /> 
                 </b-col>
              </b-row>
               <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.oPass')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'otherpassword'"
                     v-model="insurance.otherpassword"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
                 </b-col> 
                  <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.AddIdRMT')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'addIdrmt'"
                     v-model="insurance.addIdrmt"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_AddIdRMT1'"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.bRate')"
                  v-bind:Options="BaudRateList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.baudrate"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownBaudRate"
                  :IsDisabled="isReadOnly"
               />
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.Protocol')"
                  v-bind:Options="ProtocolList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.protocol"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownProtocol"
                  :IsDisabled="isReadOnly"
               />
               </b-col>
              </b-row>
              <b-row>
                 <b-col lg="3" md="3" sm="12">
                   <MdLookup
                     v-bind:ModelCode="insurance.template"
                     v-bind:ModelDescription="templateDescription"
                     v-bind:Label="$t('insuranceSetup.Template')"
                     v-bind:RefCode="'Template'"                   
                     v-bind:Api="'program'"
                     v-bind:FieldCode="'code'"                    
                     v-bind:FieldDescription="'name'"
                     :IsDisabled="isReadOnly"    
                     :SearchTitle="$t('insuranceSetup.Template')"
                     :GetValues="onCodeConditionOk"
                     v-bind:isRequired="true"
                     v-bind:isValid="istemplateValid"
                     :InvalidFeedback="$t('insuranceSetup.iTemplMsg')"
                     :SearchFieldCode="'Code'"
                  />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.Parity')"
                  v-bind:Options="ParityList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.parity"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownParity"
                  :IsDisabled="isReadOnly"
               />
               </b-col>
               <b-col lg="3" md="3" sm="12">             
               <MdRadioGroup
                  v-bind:Label="$t('insuranceSetup.Word')"
                  v-model="insurance.word"
                  v-bind:Options="optionsWord"
                  v-bind:Name="'radio-inline'"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col> 
            <b-col lg="3" md="3" sm="12">             
               <MdRadioGroup
                  v-bind:Label="$t('insuranceSetup.Stopbit')"
                  v-model="insurance.stopbit"
                  v-bind:Options="optionsStopbit"
                  v-bind:Name="'radio-inline'"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col> 
              </b-row>
              <b-row>
              <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.rZip')"
                  v-bind:Name="'checkbox-ReceiveZip'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.receivezip"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
            <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.sZip')"
                  v-bind:Name="'checkbox-sendzip'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.sendzip"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
            <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.Unwrapped')"
                  v-bind:Name="'checkbox-Unwrapped'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.unwrapped"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
              </b-row>
            </div>
            <div class="tab-pane fade" id="diag9_12" role="tabpanel">
            <b-row>
              <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('employee.UserID')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'userid'"
                     v-model="insurance.userid"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
                 </b-col> 
                <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.Password')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'password'"
                     v-model="insurance.password"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.Url')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'url'"
                     v-model="insurance.url"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
               </b-col>
               <b-col lg="3" md="3" sm="12">
                   <MdEdit 
                     v-bind:Label="$t('insuranceSetup.Port')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'port'"
                     v-model="insurance.port"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
               </b-col>
            </b-row>
            <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.cHouseType')"
                  v-bind:Options="ClearingHouseTypeList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.clearingHouseType"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownClearingHouseType"
                  :IsDisabled="isReadOnly"
               />
               </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.uPath')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'uploadingPath'"
                     v-model="insurance.uploadingPath"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.dPath')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'downloadingPath'"
                     v-model="insurance.downloadingPath"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.iPath')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'intermediatePath'"
                     v-model="insurance.intermediatePath"
                     v-bind:isReadOnly="isReadOnly"
                  /> 
                 </b-col> 
            </b-row>
            <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.Send')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'Send'"
                     v-model="insurance.send"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_send'"
                  /> 
                 </b-col> 
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.Receive')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'Receive'"
                     v-model="insurance.receive"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_rece'"
                  /> 
                 </b-col> 
                  <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.cType')"
                  v-bind:Options="CommTypeList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.commtype"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownCommType"
                  :IsDisabled="isReadOnly"
                  v-bind:Id="'input_insu_type'"
               />
                 </b-col> 
            </b-row>
            <b-row>
               <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.sfCreation')"
                  v-bind:Name="'checkbox-SendAfterFileCreation'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.sendAfterFileCreation"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
            <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.uVPN')"
                  v-bind:Name="'checkbox-UseVPN'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.useVPN"
                  v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
            </b-row>
            </div>

         <div class="tab-pane fade" id="dailytherapies" role="tabpanel">
            <MdTitle v-bind:Text="$t('Shared.Therapies')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('insuranceSetup.adTherapies')" 
              v-bind:ClassProp="isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"              
              v-on:click="CallModalTherapies"
              v-bind:isReadOnly="this.isReadOnly">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchtherapies"   
                  @keyup.enter="FindTherapies">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinput'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindTherapies">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fieldsTherapies"
              v-bind:items="itemsTherapies"
              v-bind:totalRows="totalRowstherapies"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPagetherapies"
              v-bind:perPage="perPagetherapies"
              v-bind:pageOptions="pageOptionstherapies"
              :onFiltered="onFilteredtherapies"
              :pageChanged="pageChangedtherapies"
              :deleteIconEvent="Removetherapies"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedtherapies"
              :perPageFunction="PerPageFunctiontherapies">
            </MdTable>
          </div>
    </div>

         </div>
            <div class="tab-pane fade" id="priorauthorization" role="tabpanel">
               <b-row>
               <b-col lg="3" md="3" sm="12">
                  <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.uAuthorization')"
                  v-bind:Name="'checkbox-UsePriorAuthorization'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.usePriorAuthorization"
                  v-bind:isReadOnly="isReadOnly"
               />
               </b-col>
               <b-col lg="3" md="3" sm="12">
                  <MdCheckBox
                  v-bind:Label="$t('insuranceSetup.uDateRangeAuthorization')"
                  v-bind:Name="'checkbox-UseDateRangeForPriorAuthorization'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="insurance.useDateRangeForPriorAuthorization"
                  v-bind:isReadOnly="isReadOnly"
               />
               </b-col>
                <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$t('insuranceSetup.pAuthorizationBasedOn')"
                  v-bind:Options="PriorAuthorizationBasedOnList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.priorAuthorizationBasedOn"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownPriorAuthorizationBasedOn"
                  :IsDisabled="isReadOnly"
               />
               </b-col>               
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$t('insuranceSetup.uUnitsAs')"
                  v-bind:Options="UseUnitsAsList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="insurance.useUnitsAs"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownUseUnitsAs"
                  :IsDisabled="isReadOnly"
               />
               </b-col>
              </b-row>
              <b-row>
                 <b-col lg="3" md="3" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.WarnMeBeEndDate')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'WarnmeDayBeforeEndDate'"
                     v-model="insurance.warnmeDaysBeforeEndDate"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_WarnMeBeEndDate'"
                  /> 
                 </b-col> 
                 <b-col lg="4" md="4" sm="12">
                  <MdEdit 
                     v-bind:Label="$t('insuranceSetup.WarnAllowedBy')"
                     v-bind:TypeProp="'text'"
                     v-bind:MaxLengthProp="50" 
                     v-bind:Name="'WarnMeWhentheTotalUnitsUsedIsCloseToTheTotalUnitsAllowedBy'"
                     v-model="insurance.warnMeWhentheTotalUnitsUsedIsCloseToTheTotalUnitsAllowedBy"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'input_insu_WarnAllowedBy'"
                  /> 
                 </b-col> 
              </b-row>
            </div>



<!-- Tab Insurance Contacts -->




             <div class="tab-pane fade" id="contacts" role="tabpanel">
                <MdTitle v-bind:Text="$tc('Contact.Contact')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('insuranceSetup.adContacts')" 
              v-bind:ClassProp="isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"              
              v-on:click="CallModalContacts"
              v-bind:isReadOnly="this.isReadOnly">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchcontacts"   
                  @keyup.enter="FindContacts">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinput'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindContacts">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fieldsContacts"
              v-bind:items="itemsContacts"
              v-bind:totalRows="totalRowscontacts"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPagecontacts"
              v-bind:perPage="perPagecontacts"
              v-bind:pageOptions="pageOptionscontacts"
              :onFiltered="onFilteredcontacts"
              :pageChanged="pageChangedcontacts"
              :deleteIconEvent="Removecontacts"
              :onRowSelected="onRowSelectedcontacts"
              :perPageFunction="PerPageFunctioncontacts">
            </MdTable>
          </div>
    </div>
             </div>
          </div>  
        </MdTabList>
         </b-col>
         </b-row>

         <ModalInsuranceTherapies
            v-bind:TitleWindow="$t('insuranceSetup.iTherapies')"          
            @getCodeConditionOK="OnReturnDataModalTherapies($event)"
            v-bind:ModalId="modalInsuranceTherapiesId">
         </ModalInsuranceTherapies>

         <ModalInsuranceContacts
            v-bind:TitleWindow="$t('insuranceSetup.iContacts')"          
            @getCodeConditionOK="OnReturnDataModalContacts($event)"
            v-bind:ModalId="modalInsuranceContactsId">
         </ModalInsuranceContacts>
         
                  
         <div class="space-actions"></div>
         <div class="page-actions">
            <MdButton
               v-bind:Label="$t('Insurance.InsuranceList')"
               v-bind:Name="'btninsurancelist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>
            <MdButton
               v-bind:Label="$t('Shared.Save')"
               v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
               v-on:click="SaveOrEdit"
               v-bind:isReadOnly="isReadOnly"
               v-bind:Name="'btn_save_insurance_setup'"
               />
         </div>
      </div>
   </div>
</template>

<script>

//import EnumService from '@/services/enum.service' 

//import Utils from '@/common/utils.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import GlobalService from '@/services/global.service'
//import StorageService from '@/services/storage.service'
import { EventBus } from '@/event-bus.js';
//import moment from 'moment'
let elemSaveBtn = ''
export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

   beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('insuranceSetup.iSetupTitle'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],
   name: 'InsuranceSetup',  
    data() {
      return { 
      permission:[],
      Menu : this.$store.state.leftMenu,
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
      modalInsuranceTherapiesId:"ModalaInsuranceTherapies",
      modalInsuranceContactsId:"ModalaInsuranceContacts",
      isOCNAValid:true,  
      istemplateValid:true,
      ocnaDescription:"",
      templateDescription:"",
      searchtherapies:"",
      searchcontacts:"",
      itemsTherapies:[
        // {"Code":"1","Name":"Peter","Age":"20"}
         ],
      itemsContacts:[],
      ShowMessageIsEmpty:false,
      fieldsTherapies:[
          { key: 'therapyName', sortable: true, label: this.$t('insuranceSetup.tName'), class: 'text-rigth'}, 
          { key: 'therapyCode', sortable: true, label: this.$t('insuranceSetup.tCode'), class: 'text-rigth' },       
      ],
      fieldsContacts:[
          { key: 'namedept', sortable: true, label: this.$t('insuranceSetup.Name-Dept'), class: 'text-rigth'}, 
          { key: 'phone', sortable: true, label: this.$t('Shared.Phone'), class: 'text-rigth' },     
          { key: 'Fax', sortable: true, label: this.$t('Patient.LegalFax'), class: 'text-rigth'}, 
          { key: 'email', sortable: true, label: this.$t('Shared.Email'), class: 'text-rigth' },    
          { key: 'website', sortable: true, label: this.$t('insuranceSetup.WebSite'), class: 'text-rigth'}, 
          { key: 'notes', sortable: true, label: this.$t('Shared.Notes'), class: 'text-rigth' },      
      ],

      insurance:{
         ocnaCode:""
         
         },
      insuranceZipChanged:false, 
      pageOptionstherapies: [5, 10, 15 ],
      pageOptionscontacts:[5, 10, 15 ],
      totalRowstherapies: 0,
      totalRowscontacts:0,
      currentPagetherapies: 1,
      currentPagecontacts:1,
      perPagetherapies: 10,
      perPagecontacts:10,
      filter: null,
      filterOn: [],
       refTable: 'selectableTableDailyTherapies',
      selectedtherapies: [],
      selectedcontacts:[],
      TaxonomyList:[],
      CategoryList:[],
      CommTypeList:[],
      ProtocolList:[],
      EcsInsCodeList:[],
      BaudRateList:[],
      EcsFormatList:[],
      InsuranceTypeList:[],
      ClearingHouseTypeList:[],
      optionsPart: [
          { text: this.$t('insuranceSetup.oPart.PartA'), value: 'a' },
          { text: this.$t('insuranceSetup.oPart.PartB'), value: 'b' } 
      ],
      optionsEcsModeIndicator:[
         {text:this.$t('insuranceSetup.oEModeIndicator.Test'),value:'t'},
         {text:this.$t('insuranceSetup.oEModeIndicator.Production'),value:'p'}
      ],
      optionsWord:[
         { text: '7', value: '7' },
         { text: '8', value: '8' },
      ],
      optionsStopbit:[
         { text: '1', value: '1' },
         { text: '2', value: '2' },
      ],
      optionsNetwork:[
         {text:this.$t('insuranceSetup.opNetwork.iNetwork'),value:'i'},
         {text:this.$t('insuranceSetup.opNetwork.oNetwork'),value:'o'}
      ],
      ParityList:[
         {code:'n',name: this.$t('insuranceSetup.oPList.n')},
         {code:'e',name: this.$t('insuranceSetup.oPList.e')},
         {code:'o',name: this.$t('insuranceSetup.oPList.o')},
         {code:'m',name: this.$t('insuranceSetup.oPList.m')},
      ],

      UseUnitsAsList:[
         {code:'u',name: this.$t('insuranceSetup.uList.u')},
         {code:'d',name: this.$t('insuranceSetup.uList.d')},
         {code:'n',name: this.$t('insuranceSetup.oPList.n')}
      ],
      PriorAuthorizationBasedOnList:[
         {code:'c',name: this.$t('insuranceSetup.POnList.c')},
         {code:'h',name: this.$t('insuranceSetup.POnList.h')},
         {code:'t',name:this.$t('Shared.Therapies')},
         {code:'n',name: this.$t('insuranceSetup.oPList.n')},
      ],
      tablist: [
         { title: this.$t('Shared.General'), link: '#general', active: true},
         { title: this.$t('insuranceSetup.tablist.e'), link: '#ecsinfo'},
         { title: this.$t('insuranceSetup.tablist.c'), link: '#diag9_12'},
         { title: this.$t('insuranceSetup.tablist.d'), link: '#dailytherapies'},
         { title: this.$t('insuranceSetup.tablist.p'), link: '#priorauthorization'},
         { title: this.$t('insuranceSetup.tablist.co'), link: '#contacts'},
      ],
        
      ProviderItems:[],
      Userdescription:"",
      ObjectItems:{},
      SpecialtyList:[],
       PatientInfo:{},
       PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     },          
        Mode:this.$attrs.editMode,  
        Loading:true,
        isReadOnly:false, 
        pendingChanges: false
      }
    },

    methods:{
       isUserIdValid(){
          return true;
       },

      CanView(){
         return this.permission[0].roleCanView;
      },
   onRowSelectedtherapies(items) {
      this.selectedtherapies = items;
    },

    onRowSelectedcontacts(items){
        this.selectedcontacts = items;
    },

    onFilteredtherapies(filteredItems) {     
      this.totalRowstherapies = filteredItems.length
      this.currentPagetherapies = 1
    },

    onFilteredcontacts(filteredItems){
      this.totalRowscontacts = filteredItems.length
      this.currentPagecontacts = 1
    },

   PerPageFunctiontherapies(perpage){
      this.perPagetherapies = perpage;
      this.currentPagetherapies = 1;     
    },

    PerPageFunctioncontacts(perpage){
      this.perPagecontacts = perpage;
      this.currentPagecontacts = 1; 
    },

    Removetherapies(){

    },

    Removecontacts(){

    },

    FindContacts(){

    },

    OnReturnDataModalTherapies(data){
       console.log(data);
    },

    OnReturnDataModalContacts(data){
       console.log(data);
    },

   pageChangedtherapies(page) {            
      this.currentPagetherapies = page;    
    },

    pageChangedcontacts(page){
       this.currentPagecontacts = page; 
    },

    FindTherapies(){

    },

       onCodeConditionOk(){

       },

       ChangeDropDownTaxonomy(){

       },

       ChangeDropDownCategory(){

       },

       ChangeDropDownEcsInsCode(){

       },

       ChangeDropDownEcsFormat(){

       },

       ChangeDropDownInsuranceType(){

       },

       ChangeDropDownProtocol(){

       },

       ChangeDropDownBaudRate(){

       },

       ChangeDropDownParity(){

       },

       ChangeDropDownClearingHouseType(){

       },

       ChangeDropDownCommType(){

       },

       ChangeDropDownPriorAuthorizationBasedOn(){

       },

       ChangeDropDownUseUnitsAs(){

       },

     CallModalContacts(){
      this.$emit('load', true);
      this.$bvModal.show(this.modalInsuranceContactsId);
      this.$emit('load', false);
     },

   CallModalTherapies: async function() {         
      this.$emit('load', true);
      this.$bvModal.show(this.modalInsuranceTherapiesId);
      this.$emit('load', false);
     },

     insuranceZipChange: function() {            
         this.insuranceZipChanged = true;
        },

       
        insuranceZipBlur: function (value) {
            if (this.insuranceZipChanged && value.trim()!== '') {
            this.$emit('load', true); 
            GlobalService.getCityAndStateByZipCode(value)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        var obj = response.data
                       if(obj.city) {
                           this.$set(this.insurance, 'city', obj.city);
                           this.$set(this.insurance, 'state', obj.state);
                        }
                        this.insuranceZipChanged = false;
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false); 
                    this.doctor.city = '';
                    this.doctor.state = '';
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('insuranceSetup.iSetupTitle'),error,"error");
                    }
                  }
                    
                });  
            }
        },

  
      SaveOrEdit(){
         /* if (this.Mode) { 
               this.UpdateDoctor()           
            }else{            
               this.AddDoctor()
            }*/
        /*if(!this.isReadOnly) {
          if (this.validate_required()) {       
            this.pendingChanges = false       
            if (this.Mode) { 
               this.UpdateAllergy()           
            }else{            
               this.AddAllergy()
            }
          } else {
           this.showMessage(this.$t('Allergy.PatientAllergies'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
            this.$route.router.go('/');
          }
        } */
      },

    

    DiscardChanges(){
         this.checkExistFavorite('/app/insurances');
         this.$router.push({name: "insuranceSetupList"});
    },


      validate_required() {
         this.isAllergyValid = this.Allergy.allergyCode && this.Allergy.allergyCode.length > 0 ? true : false
         return this.isAllergyValid
      },

      async Initialize(){

      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		},
      checkUserAction() {
         if (elemSaveBtn.disabled) {
            elemSaveBtn.removeAttribute('disabled');
         }
      }
    },

   async mounted(){
      if(this.permission.length == 0){
         this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
      }else{
        if(this.Mode){
          if(!this.permission[0].roleCanEdit){
         this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
          }else{
           await this.Initialize();
          }
        }else{
          if(!this.permission[0].roleCanAdd){
         this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
          }else{
            await this.Initialize();
          }
        }
      }
     setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_insurance_setup');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );
    },

    created () {
       this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }

       this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission); 
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed;
          this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  }
</script>


