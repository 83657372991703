<template>
    <div>
        <b-modal :no-stacking="this.Stacked" 
            :ref="modalId" :id="modalId" size="xl" 
            scrollable centered :no-close-on-backdrop="true" 
            :no-close-on-esc="true"  :title="TitleWindow" 
            @ok="this.onEventOk" 
            @cancel="this.onEventCancel" 
            @hide="resetModal" 
            :cancel-title="$t('Shared.BtnCancel')" 
            :hide-footer="hideDefaultSlot"
        > 
            <Loading v-bind:IsLoading="this.Load" />  
            <b-col v-if="hideDefaultSlot && $slots.body">
                <slot name="body"></slot>
            </b-col>
            
                <b-col v-else md="12" >
                    <div v-if="ShowAddRecord && $slots.body">
                        <b-row>
                            <div class="col-md-3">
                                <MdButton
                                    v-bind:Label="AddRecordLabel ? AddRecordLabel : $t('Shared.Add')"
                                    v-bind:ClassProp="'btn btn-primary my-3 btn-login btn-block'"
                                    v-on:click="showDefaultSlot(false)"
                                    v-bind:IconClass="'ti-plus'"                     
                                    v-bind:Name="'btn_add_slot'">
                                </MdButton>
                            </div>
                            <div class="col-sm-12 col-md-9 mb-4">
                                <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                                    <div class="input-group">
                                        <input 
                                            type="text" 
                                            class="form-control"
                                            v-model="parameter.SearchCriteria"
                                            @keyup.enter="Search()"
                                            ref="inputSearchCriteria"
                                            id="inputSearchCriteria"
                                        >
                                        <div class="input-group-append">
                                            <button class="btn btn-sm search-attached" type="button" v-on:click="Search()"
                                                id="buttonSearchCriteria"
                                            >
                                                <i class="mdi mdi-magnify"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-row>
                    </div>
                    <div v-else class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                                type="text" 
                                class="form-control"
                                v-model="parameter.SearchCriteria"
                                @keyup.enter="Search()"
                                ref="inputSearchCriteria"
                                id="inputSearchCriteria"
                            >
                            <div class="input-group-append">
                                <button class="btn btn-sm search-attached" type="button" v-on:click="Search()"
                                    id="buttonSearchCriteria"
                                >
                                    <i class="mdi mdi-magnify"></i>
                                </button>
                            </div>
                        </div>
                    </div>
            
                    <MdTable 
                        v-bind:fields="fields"
                        v-bind:items="items"
                        v-bind:totalRows="totalRows"
                        v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                        v-bind:currentPage="currentPage"
                        v-bind:pageOptions="pageOptions"
                        v-bind:refTable="refTable"
                        v-bind:perPage="perPage"
                        :onRowSelected="onRowSelected"
                        :ondblClick="ondblClicked"
                        :onFiltered="onFiltered"
                        :pageChanged="pageChanged"
                        :perPageFunction="PerPageFunction"
                        :empty-text="$t('MdTable.nRecShow')">
                    </MdTable>
                </b-col>
            
        </b-modal>
    </div>
</template>

<script>

import ModalService from '@/services/modal.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import Loading from '@/components/template/loading/Loading'
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
  export default {
    mixins: [ShowMessage],
    components: {
        Loading
    },
    props: {
     Fields: Array,
     TypeOption: String,
     Resource:String,
     TitleWindow:String,
     FieldCode:String,    
     FieldDescription:String,
     ModalId: String,
     OptionParamList:Array,
     AuxFields:Array,
     Stacked:[Boolean],
     FieldsDate: Array,
     UseFilter:Boolean,
     modelFilter:Object,   
     ShowAddRecord:Boolean,
     AddRecordLabel:String,
     BackToGridEventName:String,
     OkEventName:String,
     valueToSearch: String,
   },
   watch:{
    ModalId(newId){
      if(newId){
        this.modalId = newId;
      }

    }

   },
    data() {
      return {
        modalId: this.ModalId ? this.ModalId : "bv-modal-table-paginationServer",
        ShowMessageIsEmpty:false,
        parameter:{
          SearchCriteria:'',
          PageNumber : 0,
          PageSize:0,
        },    
        Api:"",
        fields:this.Fields, 
        Header:{},          
        pageOptions: [5, 10, 15 ],
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: [],
        items: [],
        selected: [],
        rowSelected:{
          code:'',
          description:'',
          FieldName:''
        },
        modelFilterParam:this.modelFilter,
        OptionList:this.OptionParamList,
        refTable: 'selectableTable',
        Load:true,
        hideDefaultSlot: false
      }
    },
    
    methods:{
      emitEventOK(/*bvModalEvt*/){ 
       // bvModalEvt.preventDefault();
        this.$emit('getCodeConditionOK', this.rowSelected);
        this.ResetProps();
        this.parameter.SearchCriteria="";
          if(this.rowSelected.code !== '')
            EventBus.$emit('IsCorrectCode', true);   
      },

      emitEventCancel(){
        this.$emit('getCodeConditionCancel');
        this.ResetProps();
      }, 

      ChangeDropDownFilter(m){
        this.modelFilterParam = m;
      },

      ondblClicked(item){      
         this.rowSelected = {
          FieldName:"",
          code:"",
          description:"",
          AuxFields:[]
        };      
        this.rowSelected.FieldName = this.TypeOption;
        this.rowSelected.code = item ? item[this.FieldCode] : null;
        this.rowSelected.description = item ? item[this.FieldDescription]: null;
     if(this.AuxFields !== undefined){
        if(this.AuxFields.length > 0){
          for(var i = 0; i < this.AuxFields.length; i++){
            if(item){
              this.rowSelected.AuxFields.push(item[this.AuxFields[i]]);
            }            
          }
        }
     } 
     this.emitEventOK();
      },

      resetModal(){
        this.parameter.SearchCriteria="";
        this.hideDefaultSlot = false;
      },

      ResetProps(){
         this.items = [];
         this.hideDefaultSlot = false;
      },

      pageChanged (page) {         
        this.currentPage = page;        
        this.getData();
      },

      PerPageFunction(perpage){
       this.perPage = perpage;
       this.currentPage = 1;
       this.getData();
      },

      Search(){
        this.currentPage = 1;
        this.parameter.PageNumber = 1;
        this.getData();
      },

      getData() {         
        this.parameter.PageNumber = this.currentPage;              
        this.parameter.PageSize = this.perPage;
        if(this.Resource != ''){
          this.Load = true; 
          ModalService.getAll(this.Resource,this.parameter)
            .then((response) => {
              if (response.status == '200' && response.data) {   
                this.items = response.data;                    
                this.items.forEach(element => {
                  if(this.FieldsDate) {
                    this.FieldsDate.forEach(fieldDate => {
                      if (Utils.isValidDate(element[fieldDate])){
                        element[fieldDate] = Utils.formatterDateToString(element[fieldDate])
                      }
                    });
                  }
                    if(this.TypeOption === 'Program'){
                        element.inPatient = element.isInPatient ? 'Y' : 'N';
                    }                   
                });
                let headerString = response.headers["x-pagination"];
                this.Header = JSON.parse(headerString);       
                this.totalRows = this.Header.TotalCount;
                if(this.items.length === 0) {
                    this.ShowMessageIsEmpty = true;
                }           
              }
              this.Load = false;          
            })
            .catch(error => {
              this.Load = false;
              if (!error.response) {
                    // network error
                this.showMessage(this.TitleWindow,this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.TitleWindow,error,"error");
              }
            }); 
        }     
      },

      onRowSelected(item) {  
        this.rowSelected = {
          FieldName:"",
          code:"",
          description:"",
          AuxFields:[]
        };      
        this.rowSelected.FieldName = this.TypeOption;
        this.rowSelected.code = item[0] ? item[0][this.FieldCode] : null;
        this.rowSelected.description = item[0] ? item[0][this.FieldDescription]: null;
     if(this.AuxFields !== undefined){
        if(this.AuxFields.length > 0){
          for(var i = 0; i < this.AuxFields.length; i++){
            if(item[0]){
              this.rowSelected.AuxFields.push(item[0][this.AuxFields[i]]);
            }            
          }
        }
     }     
      },

      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },

      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },

      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },

      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      }
      ,
      focusInput() {
        if (this.$refs.inputSearchCriteria)
          this.$refs.inputSearchCriteria.focus();
        
        if (this.valueToSearch) {
          this.parameter.SearchCriteria = this.valueToSearch
          this.Search()
        }
      },

      setZIndex(){
        let editorZIndex = document.getElementById('Schedule_dialog_wrapper') && 
                           document.getElementById('Schedule_dialog_wrapper').style ?
                           Number(document.getElementById('Schedule_dialog_wrapper').style.zIndex) + 1 : null
        if (document.getElementById('ee-334-5rt654___BV_modal_outer_') && document.getElementById('ee-334-5rt654___BV_modal_outer_').style && editorZIndex) {
          document.getElementById('ee-334-5rt654___BV_modal_outer_').style.zIndex = editorZIndex;
        }
      },  

      showDefaultSlot(value) {
        this.hideDefaultSlot = !value;
      },

      onEventOk(event) {
        if (this.hideDefaultSlot) {
            this.hideDefaultSlot = false;
            event.preventDefault();
        } else {
            this.emitEventOK();
        }
      },

      onEventCancel() {
        this.emitEventCancel();
      }
    },

    mounted() {
        this.$root.$on('bv::modal::shown', (bvEvent, _modalId) => { 
       
        if(_modalId === this.modalId){ 
          this.getData();
        }            

        this.currentPage = 1;
        this.setZIndex()     
        this.$nextTick(() => {
          setTimeout(() =>  this.focusInput(), 500);
          });
        });
    },

    created () {
        if (this.OkEventName) {
            EventBus.$on(this.OkEventName, function (item) {      
                this.ondblClicked(item);
                this.showDefaultSlot(true);
                this.emitEventOK();
            }.bind(this));
        }
        if (this.BackToGridEventName) {
            EventBus.$on(this.BackToGridEventName, function () {
                this.showDefaultSlot(true);
            }.bind(this));
        }
    },

    beforeDestroy () {
        if (this.OkEventName) {
            EventBus.$off(this.OkEventName);
        }
        
        if (this.BackToGridEventName) {
            EventBus.$off(this.BackToGridEventName);
        }
    },
    /*beforeDestroy() {
        this.$root.$off("bv::modal::shown", null);
      },*/
     /*created () {
        EventBus.$on("OndblClicked", function (item) {       
          this.rowSelected = item;  
          this.rowSelected.FieldName = this.TypeOption;
          console.log(this.TypeOption);
          this.rowSelected.description = item ? item[this.FieldDescription]: null;
          this.rowSelected.AuxFields = [];
            if(this.AuxFields !== undefined){
        if(this.AuxFields.length > 0){
          for(var i = 0; i < this.AuxFields.length; i++){
            if(item){   
              this.rowSelected.AuxFields.push(item[this.AuxFields[i]]);
            }            
          }
        }
     }  
          this.emitEventOK();
        }.bind(this));  
     },*/
    /*beforeDestroy () {  
      console.log('Destroyed');   
      EventBus.$off('OndblClicked');
    },*/
  }

</script>