<template>
   <div>
    <b-modal :id="modalId" size="md" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal" :cancel-title="$t('Shared.BtnCancel')"> 
      <b-row style="width:450px;">      
        <b-col lg="8" md="8" sm="12">
           <MdSelect
              v-bind:Label="$t('Shared.Therapies')"
              v-bind:Options="TherapiesList"
              v-bind:Model="Vmodeltherapies"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Multiple="false"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownTherapies"/>
        </b-col>       
      </b-row>
    </b-modal>
   </div>
</template>

<script>

export default {
  name: 'ModalInsuranceTherapies',
   props: {
     ModalId: String,
     TitleWindow:String
   },
   data(){
       return { 
          modalId:this.ModalId,  
          TherapiesList:[],
          Vmodeltherapies:""
       }
   },

   methods:{

    emitEventOK(){

    },
  
    emitEventCancel(){

    },

    resetModal(){

    },

    ChangeDropDownTherapies(){
        
    }
   
   },

   mounted(){
 
      this.$root.$on('bv::modal::show', () => {     
        this.$nextTick(() => {
          setTimeout(500);
        });
      });
   }
   
};
</script>