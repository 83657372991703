<template>

<div class="col-md-12 grid-margin stretch-card">
			<div class="card activeCard">
			<div class="card-body">
				<div v-if="isUsePatientInfo" class="d-flex align-items-start justify-content-between pointer">
				<h6 v-on:click="GoToPatientInfo" class="card-title flex-grow lower-line text-truncate">{{ $t('MdPatientInfoBox.PatientInfo') }}</h6><i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="GoToPatientInfo" ></i>
			</div>
			<div v-if="!isUsePatientInfo" class="d-flex align-items-start justify-content-between">
				<h6 class="card-title flex-grow lower-line text-truncate">{{ $t('MdPatientInfoBox.PatientInfo') }}</h6>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.patientCode">
				<small>{{ $t('Shared.PatientCode') }}</small><br>
				<b>{{this.PatientInfo.patientCode}}</b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.firstName && this.PatientInfo.lastName">
				<small>{{ $t('Shared.PatientName') }}</small><br>
				<b>{{this.PatientInfo.firstName +" "+this.PatientInfo.lastName}}</b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.dob">
				<small>{{ $t('Shared.Dob') }}</small><br>
				<b>{{this.PatientInfo.dob}}</b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.gender">
				<small>{{ $t('Shared.Gender') }}</small><br>
				<b>{{this.PatientInfo.gender}}</b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.primaryInsurance">
				<small>{{ $t('MdPatientInfoBox.PrimaryInsurance') }}</small><br>
				<b>{{this.PatientInfo.primaryInsurance}}</b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.attendingDoctor">
				<small>{{ $t('MdPatientInfoBox.AttendingDoctor') }}</small><br>
				<b>{{this.PatientInfo.attendingDoctor}}</b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.cellPhone">
				<small>{{ $t('Shared.CellPhone') }}</small><br>
				<b><a :href="'tel:'+this.PatientInfo.cellPhone" class="dashLink dashCell">{{this.PatientInfo.cellPhone}}</a></b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.email">
				<small>{{ $t('Shared.Email') }}</small><br>
				<b><a :href="'mailto:'+this.PatientInfo.email" class="dashLink dashMail">{{this.PatientInfo.email}}</a></b>
			</div>
			<div class="float-left col-md-4 text-muted p-item" v-if="this.PatientInfo.patientAddress">
				<small>{{ $t('MdPatientInfoBox.PatientAddress') }} </small><br>
				<b>{{this.PatientInfo.patientAddress}}</b>
			</div>				
			</div>
		</div>
	</div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
  export default {
    props: {
	PatientCode:String    
   },
     mixins: [DBFunctions],
    data() {
      return {
		PatientInfo:{},
		isUsePatientInfo: this.$store.state.isUsePatientInfo
      }
    },
    
  methods:{

	async getPatientInfo(){
		this.PatientInfo = await this.getData(this.PatientCode);
	},

    GoToPatientInfo(){
		if(this.$route.name.includes('patient')){
			this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } }).catch(()=>{});
		}
		if(this.$route.name.includes('Clinical')){
			this.$router.push({ name: "Clinical-PatientInfo", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
		}

		if(this.$route.name.includes('appointment-Dashboard')){			
			this.$router.push({ name: "Appointments-PatientInfo", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
		}
       
    },
  },
  mounted() {
	this.getPatientInfo();
  }
}

</script>