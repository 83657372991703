<template> 
      <div :id="targetId" oncontextmenu="return false;">
        <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
          <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu> 
        </div> 
        <input
            v-b-tooltip.hover 
            :title="Hint"
            :type="internalType"
            min="1900-01-01"
            class="cursor-context-menu"
            :maxlength="this.$attrs.control.controlMaxLenght"          
            :disabled="this.$attrs.control.isReadOnly === 'Y' || NoteIsVoid || this.$attrs.control.IsChartReview || this.isReadOnly"
            :class="classValue" 
            :formatter="OnFormatter"
            v-on:blur="OnBlur"
            v-on:change="OnChange"             
            v-on:keyup.enter="$emit('keyup', $event)"
            :aria-describedby="this.$attrs.control.controlID"           
            v-bind:value="formattedValue"            
            v-on:keypress="$emit('keypress', $event)"
            v-on:input="Input"
            v-on:focus="$emit('focus', $event.target.value)"
            v-bind:style="style"
            :id="idControl"
            :placeholder="internalPlaceholder"
            v-mask="internalMask"
            >
            <!-- <VueContext ref="menu">
                <a class="dropdown-item" @click="showModalGetLastAnswers()">
                  Get 10 Last Answers
                </a>
            </VueContext> -->
            <MdGetLastAnswers v-if="showGetLastAnswers"
              v-bind:ShowPopup="showGetLastAnswers"
              @getCancel="onCancelGetLastAnswers($event)"
              @getOK="onOKGetLastAnswers($event)"
              v-bind:ControlId="controlID"
              v-bind:PatientCode="PatientCode"
              v-bind:TransDate="TransDate"
            />            
     </div>         
</template>

<script>
import Utils from '@/common/utils.js'
import { EventBus } from '../../event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
import moment from 'moment'
import ShowMessage from '@/components/messages/ShowMessage.js'

Vue.use(ContextMenuPlugin);
export default {
  name: 'MdDateTimePicker',
  mixins: [ShowMessage, EventBus],
   props: {     
     MaxLengthProp: Number,
     isReadOnly:Boolean,
     Formatter:Function,    
     Change:Function,
     Blur:Function,
     Name:String,
     KeyEnter:Function,
     KeyPress:Function,
     value:[String],
     TransDate: String,
     NoteId: String,
     id: [String, Number],
     PatientCode: String,
     IsCommonPart: Boolean,
     IsGroupContainer: Boolean     
   },

    data(){
        return{     
            classValue:"form-control",
            controlFont:[],
            NoteIsVoid:false,
            style:{
                position:'absolute',
                top: this.$attrs.control.controlTop+'px',
                width:this.$attrs.control.controlWidth+5+'px',
                height:this.$attrs.control.controlHeight+'px',
                left:this.$attrs.control.controlLeft+'px',
                fontSize:'',
                fontWeight: '',
                fontFamily:'',
                color:'',
                background:'',
                textDecorationLine:'',
                fontStyle:'',
                border: '',     
            },
            Hint: this.$attrs.control.controlHint,
            showGetLastAnswers: false,
            controlID:String(this.$attrs.control.controlID),
            //PatientCode: this.$route.params.id,
            Value: this.value,
            isRequired: this.$attrs.control.isRequired,
            menuItems:[],
            targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
            menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
            idControl:this.id.toString(),
            formattedValue: this.fromISO(this.value),
            loaded: false
        }
    },

    computed : {
        internalPlaceholder() {
            return Utils.getShortDateFormat();
        },

        internalType() {
            if (!Utils.useBrowserBuildItCalendar()) {
                return 'text';
            }
            
            return 'date';
        },

        internalMask() {
            if (!Utils.useBrowserBuildItCalendar()) {
                return '##/##/####';
            }

            return '';
        },

        format() {
            if (!Utils.useBrowserBuildItCalendar()) {
                return Utils.getShortDateFormat();
            }

            return 'YYYY-MM-DD';
        }
    },

    methods:{
        fromISO(value) {
            if (Utils.useBrowserBuildItCalendar()) {
                return Utils.formatterDate(value, 'MM/DD/YYYY');
            }

            let newValue = value;
            if (Utils.isValidDate(value)) {
                newValue = Utils.formatterDateToString(value);
            } 
            
            return newValue;
        },

        InitializeVariables(){
            if(this.$attrs.control.controlFontForHtml){         
                this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
                //var size = this.controlFont[1].parseInt()+1;
                var size = parseInt(this.controlFont[1]) + 3;
                this.style.fontFamily = this.controlFont[0];
                this.style.fontSize = size+'px !important';
                this.style.color = this.controlFont[3];
                var Decorations = this.controlFont[2];
                this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
                this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
                this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
                this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
            }
        },

        OnChange(evt){
            this.$emit('change', Utils.formatterToISO(evt.target.value, this.format))
            this.style.border = !evt.target.validity.valid || (!Utils.isValidDate(evt.target.value, this.format, true) && this.isRequired) ? 'solid 1px #dc3545 !important': ''
        },

        OnFormatter(valueIn,valueOu) {
            this.Formatter(valueIn, valueOu);
            this.$emit('Formatter', valueOu)
        },

        OnBlur(evt){
            let input = document.getElementById(evt.target.id);
            if(evt.target.value !== '' || !evt.target.validity.valid) {
                if (evt.target.validity.valid && Utils.isValidDate(evt.target.value, this.format, true)) {
                    this.$emit('Blur', Utils.formatterToISO(evt.target.value, this.format))

                    let inputDate =  moment(evt.target.value, this.format);
                    if (inputDate.year() < 1900) {
                        inputDate.set('year', 1900)
                        evt.target.value = Utils.formatterDateToString(inputDate.format());
                        input.dispatchEvent(new Event("input"));
                    }

                    this.setValid(false);
                } else {
                    this.setValid(true);
                    this.showMessage(this.$t('Msg.DatePicker'), this.$t('Msg.InvalidDate'), "error");

                    evt.target.focus();
                    evt.target.value = '';
                    input.dispatchEvent(new Event("input"));
                }
            } else {
                this.$emit('Blur', '');
            }
        },

        showModalGetLastAnswers() {
            this.showGetLastAnswers = true
        },

        onCancelGetLastAnswers(){
            this.showGetLastAnswers = false
        },

        onOKGetLastAnswers(value) {
            let input = document.getElementById(this.id);
            input.value = value;
            input.dispatchEvent(new Event("input"));
            this.showGetLastAnswers = false
        },

        handlerClick(evt){
            if(!this.$attrs.control.isReadOnly && !this.isReadOnly) {
                this.$refs.menu.open(evt)
            }
        },

        Input(w){ 
            this.setValid(w.target.value == '' && this.isRequired);

            this.formattedValue = w.target.value;
            this.Value = w.target.validity.valid && Utils.isValidDate(w.target.value, this.format) ? this.formatterDate(w.target.value) : '';
            this.$emit('input', this.Value);
            if (this.loaded) {
                this.setChangedStatus()
            }
        },

        formatterDate(value){
            return Utils.formatterToISO(value, this.format);
        },
    
        setValid(state){
            this.style.border = state ? 'solid 1px #dc3545 !important': ''
        },

        onSelect: function(args ) {
            if(args.item.id === 'gettenlastans') {
                this.showModalGetLastAnswers()
            }
        },

        loadMenuItems() {
            this.menuItems = []
            if (!this.IsCommonPart) {
                this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
            }
        },

        setChangedStatus(){
            var changed = {
                "ControlId":this.$attrs.control.controlID,
                "Changed":true,
                "NoteId":this.NoteId
            }
            EventBus.$emit('onControlWasChanged', changed);
        }
    },

    created() {
        EventBus.$on("onSetReadOnly", function (e) {
            this.NoteIsVoid = e;
        }.bind(this));
        if(this.$attrs.NoteIsVoid){
            this.NoteIsVoid = this.$attrs.NoteIsVoid;
        }
    },

    mounted(){
        this.InitializeVariables();
        this.loadMenuItems();
        this.loaded = true;

        if (!this.IsCommonPart && this.IsGroupContainer && this.$attrs.control.isCommonControlNote) {
            this.Hint = this.$t('GroupNotesDashboard.ToCompletedInGroupFocus')
        }
    }
};

</script>

<style>
@import '../../assets/css/Patients/Patient.css';
.scroll{
	overflow: hidden !important;
}
</style>


