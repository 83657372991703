<template>
    <div class="row page-height">
		<div class="col-12">
			<MdTitleRegInfo 
				v-bind:RegistrationInfo="RegistrationInfo" 
				v-bind:titlePrefix="$t('Clinical.ClinicalDashboard')" 
				v-bind:IsClinical="true"
				v-bind:titleRef="'titleElementClinicalDash'"
				v-bind:patientCode="this.$route.params.id">
			</MdTitleRegInfo> 
			<div class="row">
				<MdPatientRegistrationInfo
					v-bind:RecordId="this.$route.params.recordid">
				</MdPatientRegistrationInfo>

				<MdPatientInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientInfoBox>

				<MdPatientAllergies v-if="isUsePatientAllergies"
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientAllergies>

                <MdPatientSpecialPrecautions v-if="isUsePatientSpecialPr"						
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientSpecialPrecautions>

				<MdPatientDiagnosis v-if="isUsePatientDiagnosis"
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientDiagnosis>

				<MdPatientRegistrationsHistory
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientRegistrationsHistory>

                <!--
                <MdPatientAlertsInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientAlertsInfoBox>
                -->

			</div>
			<div class="space-actions"></div>
			<div class="page-actions">
				<MdButton 
					v-bind:Label="$t('Shared.Back')"
					v-bind:Name="'btnpatientlist'"
					v-bind:ClassProp="'btn btn-primary mx-2 btn-login'"
					v-bind:IconClass="'ti-back-left pr-1'"
					v-on:click="GotoClinical"/>
			</div>
		</div>
	</div>
   
</template>

<script>
 import EnumService from '@/services/enum.service'
 import Utils from '../../../common/utils'
 import DBFunctions from '@/common/DBFunctions.js'

export default {
	
  name: 'DashBoardClinical',
   mixins: [DBFunctions],
  data() {
      return {
            Allergies:[],
            Diagnosis:[],
            InfoPatient:{},
            RegistrationInfo:{},
            Loading:false,
            OptionsRightMenu:{
                visible:true,
                ItemsList:[],
                btnvisible:true,
                itemdisabled: false,
                PatientCode:''         
            },
            isUsePatientAllergies: this.$store.state.isUsePatientAllergies,
            isUsePatientSpecialPr: this.$store.state.isUsePatientSpecialPr,
            isUsePatientDiagnosis: this.$store.state.isUsePatientDiagnosis,
            isUsePatientMarsAlert: this.$store.state.isUsePatientMarsAlert
		}
	},
	
	methods:{

		formatterDateToString(date){
			return Utils.formatterDateToString(date);
		},
		GoToPatientInfo(){
			this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } }).catch(()=>{});
		},

		GotoClinical(){
			this.checkExistFavorite('/app/clinical');
			this.$router.push('/app/clinical').catch(()=>{});
		}
	},
	async mounted(){
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
		this.$emit('getVisibleMenu', this.OptionsRightMenu); 

		this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
		const { dob, dateFrom, dateTo, age } = this.RegistrationInfo;

		if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
		if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
		if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
		if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);
	
	},
    destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
     }
};
</script>


