<template>
<div id="ContainerTransfer">
    <div class="row">
      <div class="col-12">
        <MdTitleRegInfo
            v-bind:RegistrationInfo="RegistrationInfo" 
            v-bind:titlePrefix="$t('transfer.Transfer')" 
            v-bind:IsClinical="true"
            v-bind:titleRef="'titleElementAllergy'"
            v-bind:patientCode="this.$route.params.id">
         </MdTitleRegInfo>
        
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
              <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- !! Important ,Added for testing purpose -->
      <div style='display:none;'><MdEdit 
            v-bind:TypeProp="'text'" 
            v-model="this.$route.params.recordid"
            v-bind:Id="'input_transfer_id'">                                     
          </MdEdit>
      </div>
      <!-- End -->
    <b-row>
      <b-col lg="4" md="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('transfer.CurrentProgram')"
            v-bind:Name="'CurrentProgram'"
            v-bind:TypeProp="'text'" 
            v-model="currentProgram"
            v-bind:isReadOnly="true"
            v-bind:Id="'input_transfer_cp'"
          /> 
        </b-col>
       <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:Name="'DATEFROM'"
            v-bind:TypeProp="'text'" 
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="DateFrom"
            v-bind:isReadOnly="true"            
          /> 
        </b-col>       
         <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('transfer.LastTransactionDate')"
            v-bind:Name="'LastTransactionDate'"
            v-bind:TypeProp="'text'" 
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="LastTransactionDate"
            v-bind:isReadOnly="true"
          /> 
        </b-col>
    </b-row>
    <MdTitle v-bind:Text="$t('transfer.TRANSERINFO')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row>       
        <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('transfer.DateFromAndTime')"
            v-bind:Name="'TransferDateFrom'"
            v-bind:TypeProp="'text'" 
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-bind:Mask="'##/##/#### ##:## AA'"
            v-model="transferdateFrom"
            v-bind:isReadOnly="isReadOnly || !isActive"
            v-bind:isRequired="Required"
            v-bind:isValid="isTransferDateFromValid"
            v-bind:Id="'input_trasfer_date'"
          /> 
        </b-col> 
        <b-col lg="4" md="4" sm="12">
            <MdSelect
              v-bind:Label="$t('transfer.ReasonForTransfer')"
              v-bind:Options="ReasonTransfer"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="transfer.assessmentCode"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownReasonTransfer"
              :IsDisabled="isReadOnly || !isActive"
               v-bind:Name="'reason_select'"
              id="reason_select"
            />
          </b-col> 
        <b-col lg="4" md="4" sm="12">
            <MdLookup
              v-bind:ModelCode="transfer.programcode"
              v-bind:ModelDescription="dProgram"
              v-bind:Label="$t('transfer.NewProgram')"
              v-bind:RefCode="'Program'"
              v-bind:Fields="ProgramFields"
              v-bind:Api="'Programs'"
              v-bind:FieldCode="'code'"
              v-bind:AuxFields="this.ProgramAuxFields"
              v-bind:FieldDescription="'name'"
              :IsDisabled="isReadOnly || !isActive"    
              :SearchTitle="$tc('Shared.Program')"
              v-bind:WrittenCodeisValid="ProgramCodeWrittedIsValid"
              v-bind:isRequired="true"
              v-bind:isValid="isProgramCodeValid"             
              :GetValues="onCodeConditionOk"            
              :InvalidFeedback="$t('transfer.InvalidProgramCode')"
              :SearchFieldCode="'Code'"                     
            />
        </b-col>  
    </b-row>
    <b-row>
        <b-col md="4" lg="4" sm="12" v-if="ProgramIsInPatient">
            <MdSelect
              v-bind:Label="$t('Shared.Building')"
              v-bind:Options="BuildinsList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="buildings"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownBuilding"
              :IsDisabled="isReadOnly || !isActive"
            />
        </b-col>
        <b-col lg="4" md="4" sm="12" v-if="ProgramIsInPatient">         
            <MdLookup 
              v-bind:ModelCode="transfer.roomno"
              v-bind:ModelDescription="drooms"
              v-bind:Label="$t('Shared.Room')"
              v-bind:RefCode="'Rooms'"   
              v-bind:Fields="FieldsRooms"   
              v-bind:AuxFields="this.BuildingAuxFields"              
              v-bind:Api="'Rooms?Buildingcode='+buildings"
              v-bind:FieldCode="'code'"
              v-bind:FieldDescription="'name'"
              :IsDisabled="isReadOnly || !isActive"    
              :SearchTitle="$t('Shared.Rooms')"
              :GetValues="onCodeConditionOk"
              :InvalidFeedback="$t('transfer.InvalidRoomCode')"
              :SearchFieldCode="'Code'"                     
            />
        </b-col>
        
        <b-col md="4" lg="4" sm="12" v-if="ProgramIsInPatient && BedsAvailable !== 0">
            <MdLookup 
              v-bind:ModelCode="transfer.bedCode"
              v-bind:ModelDescription="dbeds"
              v-bind:Label="$t('Shared.Bed')"
              v-bind:RefCode="'Beds'"   
              v-bind:Fields="FieldsBeds"   
              v-bind:AuxFields="this.BedAuxFields"              
              v-bind:Api="'RoomBeds?Roomcode='+transfer.roomno"
              v-bind:FieldCode="'bedCode'"
              v-bind:FieldDescription="'bedName'"
              :IsDisabled="isReadOnly || !isActive"    
              :SearchTitle="$t('Shared.Beds')"
              :GetValues="onCodeConditionOk"
              :InvalidFeedback="$t('transfer.InvalidBedCode')"                   
            />
        </b-col>
    </b-row>

    <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row>
       <b-col lg="12" md="12" sm="12">
               <MdMemo
                 v-bind:Name="'textarea_notes'"
                 v-bind:Rows="'5'"
                 v-bind:MaxRows="'6'"
                 v-bind:MaxLength="'500'"
                 v-model="transfer.transferNotes"
               />                                     
            </b-col>
    </b-row>

    <MdTitle v-bind:Text="$t('transfer.DOCUMENTS')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row>
        <b-col lg="12" md="12" sm="12">
            <LoadDocument 
                v-bind:Records="this.files" 
                v-bind:ModeEdit="this.$attrs.editMode"
                v-bind:DocType="this.DocumentTypesVModel.description" 
                v-bind:DocCode="this.DocumentTypesVModel.code"
                v-bind:UserId="UserId"
                v-bind:DocumentTypeList="OptionDocumentTypes"
                @UpdateFiles="OnUpdateDocumentFile($event)" 
                @FileChanged="OnFileChanged($event)" 
            />
        </b-col>
    </b-row>

    <div class="page-actions">
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="Save"
                v-bind:isReadOnly="isReadOnly || !isActive || DisabledBtnTransfer"
                v-bind:Name="'btn_save_patient_transfer'"
            />
    </div>
  </div>
</template>

<script>
import EnumService from '@/services/enum.service' 
import DBFunctions from '@/common/DBFunctions.js'
import GlobalService from '@/services/global.service' 
import TransferService from '@/services/transfer.service' 
import BedsService from '@/services/beds.service'
import LocalStorage from '@/services/storage.service'
import DocumentType from '@/services/documenttype.service'

import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';

let elemSaveBtn = '';
export default {
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('transfer.Transfer'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
                this.Save();
               next();
            }
          })		
	} else {
		next()
	}
    },
  name: 'Transfer',
    mixins: [DBFunctions],
  data(){
      return{
        ReasonTransfer:[],
        BuildinsList:[],
        Required:true,
        RegistrationInfo:{},
        ProgramIsInPatient:false,
        ProgramAuxFields:['isInPatient'],
        permission:[],
        Menu : this.$store.state.leftMenu,
        dProgram:"",
        drooms:"",
        dbeds:"",
        ApiProgram:"",
        isActive:true,
        DisabledBtnTransfer:false,
        OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,         
        },  
        isReadOnly:false,
        pendingChanges: false,
        LastTransactionDate:"",
        DateFrom:"",
        buildings:"",
        currentProgram:"",
        transferdateFrom:null,
        isTransferDateFromValid:false,
        isProgramCodeValid:true,
        ProgramCodeWrittedIsValid:true,
        transfer:{
          "oldRecordId":this.$route.params.recordid,
          "dateFrom":"",       
          "assessmentCode":"",
          "programcode":"",         
          "roomno":"",
          "transferNotes": "",    
          "bedCode": "",
           "files":[]   
        },
         BuildingAuxFields:['buildingName','buildingcode','bedAvailable'],
         BedAuxFields:['bedName','bedCode','bedAvailable'],
         BedsAvailable: 0,
         ProgramFields:[
           { key: 'name', sortable: true, label: this.$t('Shared.Description') },          
           { key: 'code', sortable: true, label: this.$t('Shared.Code')},
         //  { key: 'isInPatient',sortable: true, label: 'Inpatient',formatter: value => {this.FormatterIsInPatient(value)}},
           { key: 'isInPatient', sortable: true,class:'hidden', label: this.$t('Shared.isInPatient')},
           { key: 'inPatient', sortable: true, label: this.$t('Shared.Inpatient')}
        ],
        FieldsRooms:[
         { key: 'name', sortable: true, label: this.$t('transfer.FieldsRoomsName') },
         { key: 'code', sortable: true, label: this.$t('Shared.Code') },
         { key: 'buildingName', sortable: true, label: this.$t('Shared.BuildingName') },
         { key: 'capacity', sortable: true, label: this.$t('Shared.Capacity') },
         { key: 'bedAvailable', sortable: true, label: this.$t('transfer.FieldsRoomsBedAvailable') },
         { key: 'isPrivateRoom', sortable: true, label: this.$t('transfer.FieldsRoomsPrivate') },
         ],
         FieldsBeds:[
            { key: 'bedName', sortable: true, label: this.$t('transfer.FieldsBedsName') },
            { key: 'bedCode', class:'hidden', sortable: true, label: this.$t('Shared.Code') },
            { key: 'isBedAvailable', sortable: true, label: this.$t('transfer.FieldsBedAvailable') },
         ],
        files:[],
        DocumentTypesVModel:{code:'',description:''},
        UserId:LocalStorage.getUserId(),
        OptionDocumentTypes:[],
      }
  },

  methods:{
      formatterDate(value){
         return Utils.formatterDateTimeToString(value);
      },
      CanView(){
        return this.permission[0].roleCanView;
      },

      FormatterIsInPatient(value){
        if(value)
          return 'Y'
        
        return 'N'
      },
     getDateTime() { 
         if(!this.Mode){
             GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                this.transferdateFrom = Utils.formatterDateTimeToString(response.data);
               }  
         })
         .catch(error => {
            if(error.response.status === 400 ){
                 this.showMessage(this.$t('Admission.AdmissionGetDateTime'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('Admission.AdmissionGetDateTime'),error,"error");
            }
         }); 
         }        
      },

    getLastTransactionDate() {       
          GlobalService.getAdmissionLastTransactionDate(this.$route.params.recordid)
            .then((response) => {      
               if (response.data) {    
                    this.LastTransactionDate = response.data ? Utils.formatterDate(response.data): ""; 
               }  
         })
         .catch(error => {     
            if(error.response.status === 400 ){
                 this.showMessage(this.$t('transfer.LastTransactionDate'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('transfer.LastTransactionDate'),error,"error");
            }
         });         
      },

    async PopulateBuildings(){
      this.BuildinsList = await this.getBuildings();
      this.$emit('load', false);
    },

    async PopulateBeds() {
        this.BedsAvailable = 0;
        this.transfer.bedCode = "";
        var parameter = {
            roomCode : this.transfer.roomno
        }
        await BedsService.SearchAvailable(parameter)
                    .then((response) => {
                        if (response.status == '200' && response.data) {
                            this.BedsAvailable = response.data.totalBeds;
                            if (response.data.onlyBedAvailable) {
                                setTimeout(() => {
                                    this.transfer.bedCode = response.data.onlyBedAvailable.bedCode;
                                    document.getElementById("Beds").value = response.data.onlyBedAvailable.bedName;
                                }, 100);
                            }
                        }
                    })
                    .catch(error => {
                        if (!error.response) {
                            // network error
                            this.showMessage(this.SearchTitle, this.$t('Msg.RetrieveInformation'), "error");
                        } else {
                            this.showMessage(this.SearchTitle, error, "error");
                        }
                    });
    },

   async GetReasonTransfer(){
    this.ReasonTransfer = await this.getTransferReason();
    },
      
    onCodeConditionOk(result){
       var type = result.FieldName;
       if(type.indexOf('Program') >= 0){
          switch (type) {
            default:
              if(result.code){
                this.transfer.programcode = result.code;  
                this.dProgram = result.description; 
                this.ProgramIsInPatient = result.AuxFields[0];
                if(!this.ProgramIsInPatient){
                     this.transfer.roomno = "";  
                     this.drooms = ""; 
                  }  
                this.PopulateBuildings();
              }else{
                this.ProgramIsInPatient = false;
              }
             }
         }else if(type.indexOf('Rooms') >= 0 ){
              switch (type) {               
               default:
                  this.transfer.roomno = result.code;  
                  this.drooms = result.description; 
                  var buildingCode = result.AuxFields[1];
                  this.buildings = this.buildings ? this.buildings :  buildingCode; 
                  this.dbeds = "";
                  this.PopulateBeds();              
             }
         } else if (type.indexOf('Beds') >= 0) {
           if (Object.prototype.hasOwnProperty.call(result, "AuxFields")) {
                if (result.AuxFields[2]) {
                    this.dbeds = result.description;
                    this.transfer.bedCode = result.code;
                } else {
                    this.showMessage(this.$t('Shared.Beds'),this.$t('transfer.BedAvailable'),"error");
                    this.ResetBeds();
                }
            } else {
                this.ResetBeds();
            }
        }
    },

    AddTransfer(){
      this.transfer.dateFrom =  Utils.formatterToISO(this.transferdateFrom, Utils.getDateTimeFormat());
      this.transfer.files = this.files;
      this.transfer.files.forEach(element => {
            element.doccode = 'TRAN';
            element.documentLink = this.$route.params.recordid;
        });

      TransferService.AddTransfer(this.transfer)
        .then((response) => {
              if(response.status == '200'){
                this.pendingChanges = false;   
                this.DisabledBtnTransfer = true;    
                this.files.forEach(element => {
                    element.isNew = false;
                });

                this.$emit('load', false);                              
                setTimeout(() => 
                  this.$bvToast.toast(this.$t('transfer.TransferSavedSuccessfully'), {
                    title: this.$t('transfer.SavingTransfer'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                , 50); 
              }                             
            })
            .catch((error) => {  
              elemSaveBtn.removeAttribute("disabled");
               if (!error.response) {
                   this.showMessage(this.$t('transfer.AddTransfer'),this.$t('Msg.NotAbleToInsert'),"error");
                 }          
              else if(error.response.status === 400 ){
                 this.showMessage(this.$t('transfer.AddTransfer'),error.response.data.errorMessage,"error");
              }else if(error.response.status === 500){
                this.showMessage(this.$t('transfer.AddTransfer'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
                  this.showMessage(this.$t('transfer.AddTransfer'),error.response.data.errorMessage,"error");
              }  
              this.$emit('load', false);
          });
    },

    validate_required(){
        this.isTransferDateFromValid = this.transferdateFrom ? true : false;
        this.isProgramCodeValid = this.transfer.programcode && this.transfer.programcode.length > 0 && this.ProgramCodeWrittedIsValid? true : false;         
        return this.isTransferDateFromValid  && this.isProgramCodeValid;
    },

    validateDate(){
      return Utils.IsValidDateAndRange(this.transferdateFrom, Utils.getDateTimeFormat()) ? true : false;   
    },

    Save(){ 
        
      this.$emit('load', true);
      if(this.validate_required()){
         elemSaveBtn.setAttribute("disabled","disabled");
        if(this.validateDate()){
           this.AddTransfer();
        }else{
          this.$emit('load', false);
          elemSaveBtn.removeAttribute("disabled");
          this.showMessage(this.$t('transfer.Transfer'), this.$t('Msg.InvalidDate'), "errorAutoHide");     
        }        
      }else{
          this.$emit('load', false);
          elemSaveBtn.removeAttribute("disabled");
          this.showMessage(this.$t('transfer.Transfer'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
      }  
     
    },

    async getActiveTransfer(){    
     // if(this.$route.name.includes('Clinical')){        
        this.isActive = await this.IsActiveAdmission(this.$route.params.recordid); 
      //}       
    },

      // Events OnChange
    ChangeDropDownReasonTransfer(e){
        this.transfer.assessmentCode = e.code;
    },
    ChangeDropDownBuilding(e){
      this.buildings = e !== null ? e.code : "";        
      this.transfer.roomno = "";
      this.drooms = "";
      
      this.BedAvailable = 0;
      this.transfer.bedCode = "";
    },
    checkUserAction() {
      if (elemSaveBtn.disabled) {
        elemSaveBtn.removeAttribute('disabled');
      }
    },
    ResetBeds(){
         setTimeout(() => this.ClearBeds(), 100); 
     },

     ClearBeds(){
        this.transfer.bedCode = ""; 
        this.dbeds = ""; 
        document.getElementById("Beds").value = "";

        if(this.transfer.bedCode !== ""){
            this.showMessage(this.$t('Shared.Beds'),this.$t('transfer.BedAvailable'),"error");
        }
     },

     getDocumentTypes() {  
        DocumentType.getAll(this.parameter)
            .then((response) => {
                if(response.status == '200'){           
                    this.OptionDocumentTypes = response.data; 
                }
            })
            .catch(error => {
                if (!error.response) {                      
                    this.showMessage(this.$t('transfer.Transfer'),this.$t('Msg.retrieveInfo'),"error");
                } else if(error.response.status === 400 ){
                    this.showMessage(this.$t('transfer.Transfer'),error.response.data.errorMessage,"error");
                } else if(error.response.status === 500){
                    this.showMessage(this.$t('transfer.Transfer'),this.$t('Msg.ThereWereSomeError'),"error");
                } else{
                    this.showMessage(this.$t('transfer.Transfer'),error.response.data.errorMessage,"error");
                } 
            });
    },

    OnUpdateDocumentFile(file){   
        this.pendingChanges = true;    
        this.files = file;
        this.checkUserAction();
    },

    OnFileChanged(){     
        this.changesPending = true;
    },
  },

 async mounted(){
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-transfer') ? EnumService.AppointmentItems : EnumService.ClinicalItems;    
    this.DisabledBtnTransfer = false;
    this.OptionsRightMenu.visible = true;
   
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    // this.$emit('getBtnRightMenu', this.OptionsRightMenu);
    this.isReadOnly = await this.$store.state.isClinicalTransferEHRReadOnly;
    this.$emit('load', true);  
    await this.getActiveTransfer();  
    this.getDocumentTypes();
    
    if(!this.isActive){  
      this.Required = false;    
      this.$emit('load', false);
      this.showMessage(this.$t('transfer.Transfer'), this.$t('transfer.TransfersCannotBeDone'), "error");       
    }else{
      this.getLastTransactionDate();
      this.getDateTime();
      this.GetReasonTransfer();
      this.PopulateBuildings();
    }

    this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);

    const { dob, dateFrom, dateTo, age, 
            dateFromAndTime, programName  } = this.RegistrationInfo; // Destructure for easier access
        
    if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
    if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
    if (dateFromAndTime) this.DateFrom = Utils.formatterDateTimeToString(dateFromAndTime);
    if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
    if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
    if (programName) this.currentProgram = programName;    
    
    setTimeout( function() {
        elemSaveBtn = document.getElementById('btn_save_patient_transfer');
        elemSaveBtn.setAttribute('disabled','');
    }, 50, this );
  },
  created(){ 
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);     
    EventBus.$on("onChanged", function (e) { 
        this.pendingChanges = e.Changed;
        this.checkUserAction();
    }.bind(this));

    EventBus.$on("IsCorrectCode", function (valid) {       
        this.ProgramCodeWrittedIsValid = valid;         
    }.bind(this));

    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  //  EventBus.$off('onChanged');
  },
   
};
</script>


