<template>
   <div id="MarsPatientMedication">
    <div class="row">
        <div class="col-12">
          <MdTitle v-bind:Text="$t('Nurses.marsListPatientMed')" />         
        </div>
    </div>


    <div class="container-mars">
        <div class="card-container">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 photo-container">
                            <img src="@/assets/images/faces/face005.jpg" alt="Foto" class="photo img-fluid">
                        </div>
                        <div class="col-md-8">
                            <h5 class="card-title">Medication 1</h5>
                            <p class="card-text"><i class="fa fa-birthday-cake icon" v-b-tooltip.hover title="Fecha de nacimiento"></i> 02/09/1985</p>
                            <p class="card-text"><i class="fa fa-stethoscope icon"></i> Cardiología</p>
                            <p class="card-text"><i class="fa fa-building icon"></i>A<i class="fa fa-arrow-up icon"></i>2<i class="fa fa-bed icon"></i>15</p>
                        </div>
                    </div>             
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 photo-container">
                            <img src="@/assets/images/faces/face005.jpg" alt="Foto" class="photo img-fluid">
                        </div>
                        <div class="col-md-8">
                            <h5 class="card-title">Medication 2</h5>
                            <p class="card-text"><i class="fa fa-birthday-cake icon" v-b-tooltip.hover title="Fecha de nacimiento"></i> 31/01/1963</p>
                            <p class="card-text"><i class="fa fa-stethoscope icon"></i> Cardiología</p>
                            <p class="card-text"><i class="fa fa-building icon"></i>A<i class="fa fa-arrow-up icon"></i>2<i class="fa fa-bed icon"></i>16</p>
                        </div>
                    </div>                 
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 photo-container">
                            <img src="@/assets/images/faces/face005.jpg" alt="Foto" class="photo img-fluid">
                        </div>
                        <div class="col-md-8">
                            <h5 class="card-title">Medication 3</h5>
                            <p class="card-text"><i class="fa fa-birthday-cake icon" v-b-tooltip.hover title="Fecha de nacimiento"></i> 11/10/1995</p>
                            <p class="card-text"><i class="fa fa-stethoscope icon"></i> Cardiología</p>
                            <p class="card-text"><i class="fa fa-building icon"></i>B<i class="fa fa-arrow-up icon"></i>3<i class="fa fa-bed icon"></i>4</p>
                        </div>
                    </div>                  
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4 photo-container">
                            <img src="@/assets/images/faces/face005.jpg" alt="Foto" class="photo img-fluid">
                        </div>
                        <div class="col-md-8">
                            <h5 class="card-title">Medication 4</h5>
                            <p class="card-text"><i class="fa fa-birthday-cake icon" v-b-tooltip.hover title="Fecha de nacimiento"></i> 22/08/1990</p>
                            <p class="card-text"><i class="fa fa-stethoscope icon"></i> Neurología</p>
                            <p class="card-text"><i class="fa fa-building icon"></i>A<i class="fa fa-arrow-up icon"></i>2<i class="fa fa-bed icon"></i>21</p>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-4">
           
        </div>
    </div>
    <div class="space-actions"></div>
    <div class="page-actions">
        <MdButton
        v-bind:Label="$t('Shared.Back')"
        v-bind:ClassProp="'secondary mx-2 btn-login'"
        v-on:click="goMarsList"
    />
    </div>
   </div>
</template>

<script>
 //import EnumService from '@/services/enum.service'
import MarsService from '@/services/mars.service'
export default {
  name: 'MarsPatientMed',
  props:{
    DateFrom:String,
    default:null,
  },
  data(){
    return{
        parameter:{  
        PatientCode:this.$route.params.id,        
        SearchCriteria:null,
        PageSize:10,
        PageNumber:1
      },
      perPage: 10,      
      totalRows: 0, 
      currentPage: 1,   
        OptionsRightMenu:{
            visible:false,
            btnvisible:false,
            ItemsList:[],
            favButtonAtion: false
        },
    }
  },

  methods:{
    goMarsList(){

    },

    async getListing(){  
      this.parameter.PageNumber = this.currentPage;
      this.parameter.PageSize = this.perPage;
   //   this.parameter.DateTimeFrom = Utils.formatDateTime(this.DateFrom, Utils.getDateTimeFormat());
   //   this.parameter.DateTimeTo = Utils.formatDateTime(this.DateTo, Utils.getDateTimeFormat());
     // this.totalRows = 0;
      this.$emit('load', true);      

      await MarsService.getMedListing(this.parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data;
                       /*if(response.data.length > 0){
                        this.totalRows = response.data[0].maxRows;                        
                        this.items.forEach(function(element) {
                          if (element.dob)	
                              element.dob = Utils.formatterDateToString(element.dob);                          
                        });                                                
                       }*/
                       this.$emit('load', false);  
                     }  
                   
                })
                .catch((error) => {                   
                   this.$emit('load', false);               
                    if (!error.response) {
                      this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                    }                    
                    else if(error.response.status === 500){
                        this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                      this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                    } 
                    
                }); 
    },
  },

  created(){

  },

  mounted(){
    //this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems

    this.getListing();
    this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
  }
  }
   
};
</script>



<style scoped>
.card-container {
    display: flex;
    flex-wrap: wrap;
}
.card {
    flex: 0 1 calc(25% - 20px);
    margin-bottom: 20px;
    border-radius: 10px;
    margin-right: 10px;
    margin-left: 10px;
}
.card-body{
    border-radius: 10px;
    background: linear-gradient(300deg, #F9F9F9, #FFF);
    padding: 0.8rem 0.4rem 0.6rem 0.4rem;
    cursor: pointer;
    transition: all 1s ease;
}
.card-title{
    border-bottom: 1px solid #EEEEEE;
    padding-bottom: 8px;
    padding-left: 5px;
    margin-bottom: 5px;
    transition: all 1s ease;
}
.card-title::before{
    content: '►';
    position: absolute;
    left: 10px;
    top: -3px;
    opacity: 0;
    transition: all .5s ease-in-out;
    font-size: 20px;
    color: #19BEE8;
    transform: scale(.4);
}
.card-text{
    color: #999;
    padding-left: 5px;
    margin-top: .7em;
    text-transform: lowercase;
}
.card-text::first-letter {
        text-transform: uppercase;
}
@media (max-width: 1200px) {
    .card {
        flex: 0 1 calc(33.333% - 20px);
    }
}
@media (max-width: 992px) {
    .card {
        flex: 0 1 calc(50% - 20px);
    }
}
@media (max-width: 576px) {
    .card {
        flex: 0 1 100%;
    }
}
@media (min-width: 576px) {
    .card:hover .photo{
        transform: rotateY(30deg) rotateX(10deg) rotateZ(-10deg) scale(1.1);
        transform-style: preserve-3d;
    }
    .card:hover .card-title{
        text-shadow: 0 0 #FFF, -1px 0 6px #FFF, 1px 0 6px #FFF, 0 -1px 6px #FFF, 0 1px 6px #FFF ;
        color: #19BEE8;
    }
}
@media (max-width: 765px) {
    .card-title{
        margin-top: 2em;
    }
}
.card-container::after {
    content: "";
    flex: auto;
}
.estado {
    font-weight: bold;
    color: white;
    text-align: center;
    border-radius: 0 0 5px 5px;
    margin-top: 10px;
}
.over-due {
    background-color: red;
}
.close-to-admin {
    background-color: #ffa500;
}
.prior-to-admin {
    background-color: #eac806;
}
.no-pending {
    background-color: #05b005;
}
.icon {
    margin-right: 5px;
    color: #CCC;
}
.photo-container {
    perspective: 300px;
    z-index: 1;
}
.photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    border: 4px solid #F3F3F3;
    filter: grayscale(100%) brightness(1.2); 
    transition: all 0.4s;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
}
.card:hover .photo {
    border: 4px solid #EEEEEE;
    filter: grayscale(0%) brightness(0.99);
    box-shadow: 8px 8px 6px rgba(0, 0, 0, 0.05);
}
.card:hover .card-body{
    background: linear-gradient(120deg, #F1F1F1, #FFF);
}
.card:hover .card-title{
    margin-left: 20px;
}
.card:hover .card-title::before{
    opacity: 1;
    left: 18px;
    transform: scale(1);
}
p i:not(:first-of-type) {
    margin-left: 1.1em;
}

.cutProgram{
 white-space: nowrap;  
  overflow: hidden;
  text-overflow: ellipsis;
  }
</style>


