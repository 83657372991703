import ApiService from '@/services/api.service'

const BedsService = {
    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/roomBeds/",       
            params: {
                SearchCriteria: parameter.SearchCriteria,
                PageSize: parameter.PageSize,
                PageNumber: parameter.PageNumber
            }
        }       
        return ApiService.customRequest(requestData);
    }, 
    Add(data){
        return ApiService.post(`/roomBeds`, data);
    },
    Delete(Id){      
        return ApiService.delete(`/roomBeds/${Id}`);
    },
    Update(Id,data){
        return ApiService.put(`/roomBeds/${Id}`,data);
    },   
    Get(Id){     
        return ApiService.get(`/roomBeds/${Id}`);
    },
    getAllFromRoom(parameter){
        const requestData = {
            method: 'get',
            url: "/roomBeds/",
            headers: {
            },
            params: {          
                RoomCode: parameter.roomCode,
                OrderBy: parameter.orderBy,
                OnlyAvailable: parameter.onlyAvailable            
            }
        }     
        return ApiService.customRequest(requestData);       
    },
    SearchAvailable(parameter){
        const requestData = {
            method: 'get',
            url: "/roomBeds/SearchAvailable/",
            headers: {
            },
            params: {          
                RoomCode: parameter.roomCode,
                bedCodeToSearch : parameter.bedCodeToSearch            
            }
        }     
        return ApiService.customRequest(requestData);    
    },
};

export default BedsService;