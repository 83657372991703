import ApiService from '@/services/api.service'

const EmployeeTypesService = {

    getAll(){
        return ApiService.get(`/EmployeeTypes/?OnlyTypeFromCalendar=true`);
    },
    getAllEmployeeType(parameter){       
        const requestData = {
            method: 'get',
            url: "/EmployeeTypes/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
              Active: parameter.Active
            }
          }
          return ApiService.customRequest(requestData);
    },
    DeleteEmployeeType(Id){
        return ApiService.delete(`/EmployeeTypes/${Id}`);
    },
    AddEmployeeType(data){
        return ApiService.post(`/EmployeeTypes`, data);
    },
    UpdateEmployeeType(Id,data){
        return ApiService.put(`/EmployeeTypes/${Id}`,data);
    },   
    GetEmployeeType(Id){     
        return ApiService.get(`/EmployeeTypes/${Id}`);
    },

}

export default EmployeeTypesService;