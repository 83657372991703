<template>
   <div id="ContainerChartReview">
      <div class="row">
		<div class="col-12">
			<div class="accordion" role="tablist">
				<b-card no-body class="noteSearchPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block
							v-on:click="Accordion1Click"
							class="collapseBTN text-truncate"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							<MdTitleRegInfo
								v-bind:RegistrationInfo="PatientTitle" 
								v-bind:titlePrefix="$t('ChartReview.CHARTREVIEW')" 
								v-bind:IsClinical="IsClinical"
								v-bind:titleRef="'titleElementPatientChartR'"
								v-bind:patientCode="this.$route.params.id"
								v-bind:propClass="''"
								v-bind:propTag="'span'"
								v-bind:isTitle=false>
							</MdTitleRegInfo>
						</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-1"
						visible
						accordion="ClinicalNotes"
						role="tabpanel"
						v-show="ShowFilter"
						v-on:click="ShowFilter = true">
						<b-card-body class="p-1">
							<b-card-text class="notesPanelsChartReview cutescroll">
								<b-row>
									<b-col md="3" lg="3" sm="12">
										<MdEdit 
											v-bind:Label="$t('Shared.datefrom')"
											v-bind:TypeProp="'date'" 
											v-bind:Name="'DateFrom'"
											v-model="DateFrom"
											v-bind:Min="'1900-01-01'"
											v-bind:Max="'2200-12-31'"
											v-on:change="DateFromChange($event)"
											v-bind:OnPressEnter="SearchNotes">                                     
										</MdEdit>
									</b-col>
									<b-col md="3" lg="3" sm="12">
										<MdEdit 
											v-bind:Label="$t('Shared.dateTo')"
											v-bind:TypeProp="'date'" 
											v-bind:Name="'DateTo'"
											v-model="DateTo"
											v-bind:Min="'1900-01-01'"
											v-bind:Max="'2200-12-31'"
											v-on:change="DateToChange($event)"
											v-bind:OnPressEnter="SearchNotes">                                     
										</MdEdit>
									</b-col>

                                    <b-col md="3" lg="3" sm="12">
										<div class="form-group">
												<MdSelect
												v-bind:Label="$t('ChartReview.TypesToInclude')"
												v-bind:FieldDescription="'text'"
												v-bind:FieldCode="'value'"
												v-bind:Options="typesToIncludeOptions"
												v-bind:Model="this.TypeToIncludeVModel"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownTypesToInclude"
                                                />
											</div>		
									</b-col>

                                    <b-col md="3" lg="3" sm="12">
										<div class="form-group">
												<MdSelect
												v-bind:Label="$t('ChartReview.Names')"
												v-bind:FieldDescription="'description'"
												v-bind:FieldCode="'code'"
												v-bind:Options="OptionTypes"
												v-bind:Model="this.NoteTypesVModel"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownNoteTypes"
                                                v-bind:GroupValues="'optionValues'"
                                                v-bind:GroupLabel="'optionLabel'"
                                                v-bind:GroupSelect="true"
                                                />
											</div>		
									</b-col>
								</b-row>
								<b-row>
									<b-col  md="3" lg="3" sm="12">
										<div class="form-group">
												<MdSelect
												v-bind:Label="$tc('Shared.Therapist')"
												v-bind:FieldDescription="'name'"
												v-bind:FieldCode="'code'"
												v-bind:Options="TherapistList"
												v-bind:Model="this.Therapist"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownTherapist"/>
											</div>		
									</b-col>
									<b-col  md="3" lg="3" sm="12">
										<div class="form-group">
												<MdSelect
												v-bind:Label="$t('ChartReview.CoTherapist')"
												v-bind:FieldDescription="'name'"
												v-bind:FieldCode="'code'"
												v-bind:Options="CotherapistList"
												v-bind:Model="this.Cothetapist"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownCotherapist"/>
											</div>		
									</b-col>
									
								<b-col md="3" lg="3" sm="12">													
											<div class="form-group">
												<MdSelect
												v-bind:Label="$t('ChartReview.NoteStatus')"
												v-bind:Options="OptionNoteStatus"
												v-bind:ModelArray="this.NoteStatusVModel"
												v-bind:Placeholder="selectPlaceholder"																
												v-bind:FieldDescription="'name'"
												v-bind:Multiple="false"
												v-bind:FieldCode="'value'"
												v-bind:IfUsevalidation="false"
												:Input="ChangeDropDownNoteStatus"/>
											</div>										
									</b-col>
								</b-row>
								<b-row >
								<b-col  md="3" lg="3" sm="12" v-if="this.Origin === 'Patient'">
									<div class="form-group">
												<MdSelect
												v-bind:Label="$t('Shared.Admissions')"
												v-bind:FieldDescription="'label'"
												v-bind:FieldCode="'recordId'"
												v-bind:Options="ProgramList"
												v-bind:Model="Program"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownProgram"/>
									</div>	
								</b-col>
								<b-col lg="3" md="3" sm="12">             
									<MdRadioGroup
										v-bind:Label="$t('ChartReview.OrderBy')"
										v-model="RadioOrderBy"
										v-bind:Options="options"
										v-bind:Name="'radio-inline'"
										v-on:change="ChangeRadioGroup($event)"
									/>
								</b-col>  									
								</b-row>							
								<b-row>
									<b-col md="12">
										<div class="text-center text-md-right mt-3">
											<button v-on:click="SearchNotes"
												type="button"
												class="btn btn-primary btn-login mx-2 pull-right">
												{{ $t('Shared.Search') }}
											</button>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" lg="12" md="12">
									<div v-if="RadioOrderBy === 'NoteType'">
										<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
										<MdTable
											v-bind:fields="fields"
											v-bind:IsChartReview="true"
											v-bind:items="items"
											v-bind:useAddTime="false"
											v-bind:totalRows="totalRows"
											v-bind:totalRowsSubItems="totalRowsSubItems"
											v-bind:showMessageIsEmpty="
												ShowMessageIsEmpty
											"
											v-bind:isReadOnly="false"
											v-bind:selectMode="
												selectMode
											"
											v-bind:currentPage="
												currentPage
											"
											v-bind:currentPageSubItems="currentPageSubItems"
											v-bind:perPage="perPage"
											v-bind:perPageSubItems="perPageSubItems"
											v-bind:pageOptions="
												pageOptions
											"
											v-bind:pageOptionsSubItems="
												pageOptionsSubItems
											"
											v-bind:refTable="refTable"
											
											:onRowSelected="
												onRowSelected
											"
											:onFiltered="onFiltered"
											:pageChanged="pageChanged"
											:pageChangedSubItems="pageChangedSubItems"
											v-bind:ShowDetails="true"
											:perPageFunction="
												PerPageFunction
											"
											:perPageFunctionSubItems="
												PerPageFunctionSubItems
											"
											:avisar="onResultados"
											:CheckClick="onCheck"
											:NotePatientInfo="PatientInfo"
                                            :ReportEvent="DoReport"
										>
										</MdTable>
									</div>
									<div v-else v-show="Date">
								<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
									<MdTable 
										v-bind:fields="fieldsDate"
										v-bind:items="items"
										v-bind:IsChartReview="true"
										v-bind:IsViewDate="true"
										v-bind:totalRows="totalRows"
										v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
										v-bind:currentPage="currentPage"
										v-bind:perPage="perPage"
										:viewIconEvent="OpenNote"
										v-bind:ShowDetails="true"
										v-bind:pageOptions="pageOptions"
										:onRowSelected="onRowSelected"										
										:onFiltered="onFiltered"
										:pageChanged="pageChanged"
										:perPageFunction="PerPageFunction"
										:NotePatientInfo="PatientInfo"
                                        :ReportEvent="DoReport">
									</MdTable>
									</div>
									</b-col>
								</b-row>
							
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="openNotesPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block
							v-on:click="ClickBtnAccordion2"
							class="collapseBTN"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('ChartReview.OPENNOTES') }}</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-2"
						accordion="ClinicalNotes"
						role="tabpanel"
						:class="this.ClassAccordion"
						v-show="ShowNote"
						v-on:click="ShowNote = true"
						ref="a2">
						<b-card-body class="p-1" v-show="ShowNote">
							<b-card-text class="notesPanels">
								<!-- OPEN NOTES CODE -->
								<div :class="this.ClassContainerNotes" ref="ContainerNotes">
                                    <template v-for="(model, index) in this.NotesListOpened">
                                        <MdNote
                                            v-if="model.noteType == 'N'"
                                            v-bind:NOTE="model.description"										
                                            v-bind:TransDate="model.date"
                                            v-bind:Time="model.Time"
                                            v-bind:Header="model"
                                            v-bind:ProgramCode="ProgramCode"
                                            v-bind:IsChartReview="true"
                                            :key="model.Id"
                                            :id="model.Id"
                                            v-on:remove="CloseNote(index)"
                                            :model="model"
                                            v-bind:NotePatientInfo="PatientInfo">
                                        </MdNote>
                                        <DocumentChartReview
                                            v-if="model.noteType == 'D'"
                                            :key="model.chartno"
                                            :id="model.chartno"
                                            v-bind:seqNo="model.seqno"
                                            v-bind:document="model"
                                            v-on:remove="CloseDocument(index)"
                                        ></DocumentChartReview>
                                        <ActivityChartReview
                                            v-if="model.noteType == 'A'"
                                            :key="model.chartno"
                                            :id="model.chartno"
                                            v-bind:activity="model"
                                            v-on:remove="CloseDocument(index)"
                                            :reportEvent="PatientActivityView"
                                        ></ActivityChartReview>
                                        <LogNoteChartReview
                                            v-if="model.noteType == 'LN'"
                                            :key="`LN-${index}`"
                                            :id="`LN-${index}`"
                                            v-bind:logNote="model"
                                            v-on:remove="CloseDocument(index)"
                                            :reportEvent="PatientLogNoteView"
                                        ></LogNoteChartReview>
                                        <DailyNoteChartReview
                                            v-if="model.noteType == 'DN'"
                                            :key="model.chartno"
                                            :id="model.chartno"
                                            v-bind:dailyNote="model"
                                            v-on:remove="CloseDocument(index)"
                                            :reportEvent="PatientDailyNoteView"
                                        ></DailyNoteChartReview>
                                        <TreatmentPlanChartReview
                                            v-if="model.noteType == 'TP' && !model.isWiley"
                                            :key="model.transCode"
                                            :id="model.transCode"
                                            v-bind:item="model"
                                            v-on:remove="CloseDocument(index)"
                                            :reportEvent="PatientTreatmentPlanView"
                                        ></TreatmentPlanChartReview>
                                        <WileyTreatmentPlanChartReview
                                            v-if="model.noteType == 'TP' && model.isWiley"
                                            :key="model.transCode"
                                            :id="model.transCode"
                                            v-bind:item="model"
                                            v-on:remove="CloseDocument(index)"
                                            :reportEvent="PatientTreatmentPlanView"
                                        ></WileyTreatmentPlanChartReview>
                                    </template>
								</div>
								<!-- END OPEN NOTES CODE -->
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
		</div>
	</div>
    
        <DxPoPup v-if="isVisible" 
            :Title="$tc('PatientDocument.PatientDoc',2)"
            :closeWindow="closeRepWindow"
            :isVisible="isVisible"
            :Url="this.FullUrl"
        />

        <PatientActivitiesRep v-if="isActivityReportVisible" 
            :closeWindow="closeParamRepWindow"
            :patientCode="this.$route.params.id"
            :patientDob="this.PatientTitle.dob"
            :recNo="recNoToReport"
            :patientName="this.PatientTitle.fullName"
            :userName="userNameToReport"
        />

        <DailyNotesRep v-if="isDailyNoteReportVisible" 
            :closeWindow="closeParamRepWindow"
            :patientCode="this.$route.params.id"
            :noteType="ModelDailyNoteType"
            :recNo="recNoToReport"
            :noteTypeName="getDailyNoteTypeName()"
            :patientName="this.RegistrationInfo.patientName"
        />

        <LogNotesRep v-if="isLogNoteReportVisible" 
            :closeWindow="closeParamRepWindow"
            :patientCode="this.$route.params.id"
            :intakeDate="intakeDate"
            :noteTypeName="getLogNoteTypeName()"
            :patientName="this.RegistrationInfo.patientName"
            :headerID="LogNoteHeaderId"
            :notesID="this.ModelLogNoteType"
        />

        <TreatmentPlanProblemRep 
            v-if="isTreatmentPlanProblemRepVisible" 
            :closeWindow="closeParamRepWindow" 
            :recNo="ProblemRecNo"
            :notesId="ProblemNotesId" 
            :probRecId="ProblemRecId" 
            :version="'0'" 
        />
    </div>
</template>

<script>
import EnumService from '@/services/enum.service';
import DBFunctions from '@/common/DBFunctions.js'
import LocalStorage from '@/services/storage.service';
import moment from "moment";
import GlobalService from '@/services/global.service';
import ChartReview from '@/services/chartreview.service.js';
import ShowMessage from '@/components/messages/ShowMessage.js';
import EmployeeService from '@/services/employee.service';
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';
import DocumentType from '@/services/documenttype.service'
import DocumentManagement from '@/services/documentmanagement.service'
import NotesTitlesService from '@/services/notestitles.service'
import BioSetupService from '@/services/biosetup.service'

export default {
    name: 'ChartReviewBase',
    props:{Origin:String},
    mixins: [DBFunctions,ShowMessage],

    data(){
        return {
            OptionsRightMenu:{
                visible:true,
                ItemsList:[],
                btnvisible:true,
                itemdisabled: false,          
            },
            IsClinical:false,
            PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:'',
                activitytypename:'',
                startdatetime:'',
                ageFormatted:''
            },
            OptionNoteStatus: EnumService.NoteStatus,
            NoteStatusVModel: {value:0,name: this.$t('Shared.All')},
            options: EnumService.ChartReviewViewType,
            typesToIncludeOptions: EnumService.ChartReviewTypeToIncludeList.sort((a, b) => {
                        const x = a.text.toLowerCase();
                        const  y = b.text.toLowerCase();
                        return x < y ? -1 : x > y ? 1 : 0;
                    }),
            RadioOrderBy: 'NoteType',
            parameterListing:{
                SearchCriteria:'',
                PageNumber : 1,
                PageSize:1,
                AdmissionRecordIdList:[],
                TherapistCodesList:[],
                CoTherapistCodesList:[],
                NotesIdList:[],
                DocumentsIdList:[],
                typesToInclude:[],
                DailyNotesIdList:[],
                LogNotesIdList: []
            },
            PatientInfo:{},
            RegistrationInfo:{},
            DateFrom:'',
            DateTo:'',
            ProgramCode:'',
            TherapistList:[],
            CotherapistList:[],
            OptionNoteTypes:[],
            OptionDocumentTypes:[],
            OptionTypes:[],
            ProgramList:[],
            Program:"",
            Therapist:"",
            ShowFilter:true,
            ShowNote: false,
            items: [],
            ShowMessageIsEmpty: false,
            refTable: "selectableTable",
            selectMode: "single",
            selectPlaceholder: this.$t('ChartReview.SelectOptions'),
            NoteTypesVModel: "",
            TypeToIncludeVModel: '',
            Cothetapist:"",
            ClassContainerNotes:'',
            ClassAccordion:'',
            NotesListOpened:[],
            parameter:{
                NoteTypes:[],
                NoteStatus:0,
                SearchCriteria:'',
                PageNumber : 1,
                PageSize:1,
                AdmissionRecordIdList:[],
                TherapistCodesList:[],
                CoTherapistCodesList:[],
                NotesIdList:[]
            },
            pageOptions: [5,10,20,30,40,50],
            pageOptionsSubItems: [5,10,20,30,40,50],
            totalRows: 0,
            totalRowsSubItems: 0,
            currentPage: 1,
            currentPageSubItems: 1,
            perPage: 50,
            perPageSubItems: 50,
            filter: null,
            filterOn: [],
            fields: [
                    { key: 'show_details', sortable: true, label: this.$t('ChartReview.ShowDetails')},
                    { key: 'description', sortable: true,label:this.$t('Shared.Name')},	
                    { key: 'noteName', sortable: true,label:this.$t('Shared.Type')},				
                    { key:'notesId',sortable: true, class:'hidden'},
                    { key: 'frequency', sortable: true, label: this.$t('Shared.Frequency')},
                    { key: 'isBillable', class:'hidden',sortable: true, formatter: value => { return value ? this.$t('ChartReview.Billable') : this.$t('ChartReview.NotBillable')}},
                    { key: 'isGroup', sortable: true, label: this.$t('ChartReview.IsGroup'), formatter: value => {return value ? 'Y' : 'N'}},
            ],
            fieldsDate: [
                { key: 'transdate', sortable: true,label: this.$t('ChartReview.NoteDate') },
                { key: 'description', sortable: true,label: this.$t('Shared.Name')},
                { key: 'noteName', sortable: true,label:this.$t('Shared.Type')},	
                { key: 'therapistName', sortable: true,label: this.$tc('Shared.Therapist')},
                { key: 'coTherapistName', sortable: true,label: this.$t('ChartReview.CoTherapist')},			
                { key:'notesId',sortable: true, class:'hidden'},				
                { key: 'isBillable',label: this.$t('ChartReview.Billable'), sortable: true, formatter: value => {return value ? this.$t('ChartReview.Billable') : this.$t('ChartReview.NotBillable')}},
                { key:'report',sortable: true, label:this.$t('Shared.Report')},
            ],
            currentAppointmentDB:{
                guid:'',
                activitytypecode:'',
                employee:{
                    firstname:"",
                    lastname:""
                }
            },
            FullUrl: "",
            isVisible: false,
            isActivityReportVisible: false,
            isDailyNoteReportVisible: false,
            isLogNoteReportVisible: false,
            recNoToReport: null,
            userNameToReport: null,
            OptionDailyNoteTypes: [],
            ModelDailyNoteType: null,
            OptionLogNoteTypes: [],
            ModelLogNoteType: null,
            intakeDate: null,
            LogNoteHeaderId: null,
            isTreatmentPlanProblemRepVisible: false,
            ProblemRecId: null,
            ProblemRecNo: null,
            ProblemNotesId: null
        }
    },

    methods:{
        DateFromChange(e){
            this.DateFrom = e;
        },

        DateToChange(){		
            
        },

        ChangeRadioGroup(e){		
            this.currentPage = 1;
            this.parameter.PageNumber = 1;
            this.RadioOrderBy = e;
            this.getNoteList();
        },

        ChangeDropDownNoteTypes(notetypes){
            this.parameterListing.NotesIdList = [];
            this.parameterListing.DocumentsIdList = [];
            this.parameterListing.DailyNotesIdList = [];
            this.parameterListing.LogNotesIdList = []
            notetypes.forEach(element => {
                if (element.type == "N") {
                    this.parameterListing.NotesIdList.push(element.code);
                } else if (element.type == "D") {
                    this.parameterListing.DocumentsIdList.push(element.code);
                } else if (element.type == "DN") {
                    this.parameterListing.DailyNotesIdList.push(element.code);
                } else if (element.type == "LN") {
                    this.parameterListing.LogNotesIdList.push(element.code);
                }
            });
        },

        ChangeDropDownCotherapist(cotherapist){
            this.parameterListing.CoTherapistCodesList = [];  
            cotherapist.forEach(element => {
                this.parameterListing.CoTherapistCodesList.push(element.code);
            });
        },

        ChangeDropDownTherapist(therapist){
            this.parameterListing.TherapistCodesList = [];  
            therapist.forEach(element => {
                this.parameterListing.TherapistCodesList.push(element.code);
            });
        },

        ChangeDropDownViewAs(){

        },

        async getPrograms(){
            var arr = await this.getProgramsByPatient(this.$route.params.id);
            arr.forEach(element => {	
                element.label = element.dischargeDate ? element.programName + " ( "+element.admitDate + " - "+element.dischargeDate+" )": element.programName + " ( "+element.admitDate + " - Present )";
            
            });

            this.ProgramList = arr;
        },

        ChangeDropDownNoteStatus(NoteStatus){
            if(NoteStatus !== null){
                this.NoteStatusVModel =  NoteStatus;
            }else{
                this.NoteStatusVModel.value =  0;
            }
        },

        ChangeDropDownProgram(programs){
            this.parameterListing.AdmissionRecordIdList = [];  
            programs.forEach(element => {
                this.parameterListing.AdmissionRecordIdList.push(element.recordId);
            });   
        },

        ChangeDropDownTypesToInclude(types) {
            this.parameterListing.typesToInclude = [];
            types.forEach(element => {
                this.parameterListing.typesToInclude.push(element.value);
            });
        },

        NoteIsDailyMultiple(NoteId){
            var result = false;
            this.items.forEach(element => {
                if(element.notesId === NoteId){	
                    result = element.frequency.toLowerCase() === 'DAILY MULTIPLES'.toLowerCase();		
                }
            });
            return result;
        },

        AddSubItemsbyRow(NotesId,SubItems,Pagination){		
            this.items.forEach(element => {
                if(element.notesId === NotesId){				
                    this.$set(element, 'currentPageSubItems', Pagination.currentPageSubItems);
                    this.$set(element, 'perPageSubItems', Pagination.perPageSubItems);
                    this.$set(element, 'totalRowsSubItems', Pagination.totalRowsSubItems);
                    this.$set(element, 'SubItems', SubItems);
                }
            });		
        },

        OpenNote(item) {	
            item.notesId = item.notesID;
            item.Id = item.headerID;
            if(item.frequency === 'DAILY MULTIPLES'){
                item.Time = item.transdate;
            }
            item.serviceDate = item.serviceDate !== null ? Utils.formatterDate(item.serviceDate) : null;
            this.OnResultViewDate(item);
        },

        ExistDate(arr,TransDate){
            for(var i = 0; i < arr.length; i++){
                if(arr[i].date === TransDate){
                    return true;
                }
            }
            return false;
        },

        getTimesByDate(arr,date){
            var temparr = [];
            for(var i = 0; i < arr.length; i++){
                if(moment(arr[i].transDate).format("ddd, ll") === date){
                    var obj = {};
                    if(arr[i].noteStatus == 'New'){								
                        obj.icon = 'icon-doc mr-2';
                        obj.description = this.$t('Shared.IconDescN');				
                    }
                    else if(arr[i].noteStatus == 'Voided'){
                        obj.icon = 'icon-ban mr-2';
                        obj.description = this.$t('Shared.IconDescVN');
                    }
                    else if(arr[i].noteStatus == 'Pending Signature'){
                        obj.icon = 'icon-note  mr-2';
                        obj.description = this.$t('Shared.IconDescPS');
                    }
                    else if(arr[i].noteStatus == 'Pending CosignerSignature'){
                        obj.icon = 'icon-user mr-2';
                        obj.description = this.$t('Shared.IconDescPCS');
                    }else{
                        obj.icon = 'icon-check mr-2';
                        obj.description = this.$t('Shared.IconDescF');
                    }

                    obj.Id = arr[i].headerID;
                    obj.Time = moment(arr[i].noteTime).format("HH:mm:ss");	
                    obj.isBillable = this.NoteDates[i].isBillable;
                    obj.therapistName = this.NoteDates[i].therapistName;
                    obj.coTherapistName = this.NoteDates[i].coTherapistName;
                    obj.isVoid = this.NoteDates[i].isVoid;
                    obj.chartno = this.NoteDates[i].chartno;
                    obj.patientCode = this.NoteDates[i].patientCode;
                    obj.defaultUnit = this.NoteDates[i].defaultUnit;
                    obj.coTherapistCode = this.NoteDates[i].coTherapistCode;
                    obj.disableBillable = this.NoteDates[i].disableBillable;
                    obj.showBilling = this.NoteDates[i].showBilling;
                    obj.frequency = this.NoteDates[i].frequency;
                    obj.enableEdit = this.NoteDates[i].enableEdit;
                    obj.userHasReadOnlyAccess = this.NoteDates[i].userHasReadOnlyAccess;
                    obj.customFrame = this.NoteDates[i].customFrame;
                    obj.showReport = this.NoteDates[i].showReport;
                    if(this.NoteDates[i].coSignedDate){
                        obj.coSignedDate = Utils.formatterDateTimeToString(this.NoteDates[i].coSignedDate);
                        //obj.coSignedDate = moment(this.NoteDates[i].coSignedDate).format("ddd, ll");
                    }else{
                        obj.coSignedDate = null;
                    }
                    
                    obj.endTime = this.NoteDates[i].endTime;
                    obj.facilityCode = this.NoteDates[i].facilityCode;
                    obj.isCoSigned = this.NoteDates[i].isCoSigned;
                    obj.isPendingCoSignature = this.NoteDates[i].isPendingCoSignature;
                    obj.isPendingSignature = this.NoteDates[i].isPendingSignature;
                    obj.isSigned = this.NoteDates[i].isSigned;
                    obj.noteStatus = this.NoteDates[i].noteStatus;
                    obj.noteTime = this.NoteDates[i].noteTime;
                    
                    if(this.NoteDates[i].serviceDate){
                        obj.serviceDate = Utils.formatterDate(this.NoteDates[i].serviceDate);
                    }else{
                        obj.serviceDate = null;
                    }
                    if(this.NoteDates[i].signedDate){
                        obj.signedDate = Utils.formatterDateTimeToString(this.NoteDates[i].signedDate);
                        //obj.signedDate = moment(this.NoteDates[i].signedDate).format("ddd, ll");
                    }else{
                        obj.signedDate = null;
                    }

                    if(this.NoteDates[i].noteTime === null)
                        obj.isBase = true;
                
                    obj.startTime = this.NoteDates[i].startTime;
                    obj.therapistCode = this.NoteDates[i].therapistCode;
                    obj.transCode = this.NoteDates[i].transCode;
                    obj.transDate = this.NoteDates[i].transDate;
                    obj.notesContents = this.NoteDates[i].notesContents;
                    temparr.push(obj);
                }
            }
            return temparr;
        },

		getNoteList(){
            //this.$emit('load', true);
            EventBus.$emit('ShowWeel', true); 
            this.items = [];
            this.parameterListing.PageNumber = this.currentPage;
            this.parameterListing.PageSize = this.perPage;
            this.parameterListing.PatientCode = this.$route.params.id;
            this.parameterListing.DateFrom = Utils.formatterToISO(this.DateFrom);
            this.parameterListing.DateTo = Utils.formatterToISO(this.DateTo);	
            this.parameterListing.NoteStatus = this.NoteStatusVModel.value;
            this.parameterListing.UserId = LocalStorage.getUserId();

            var SubItems = [];
            var Exist = false;			
            if(this.RadioOrderBy === 'NoteType'){
                ChartReview.getNoteList(this.parameterListing)
                    .then((response) => {
                        if(response.status == '200' && response.data){
                            this.items = response.data;
                            if(response.data.length > 0){                
                                let headerString = response.headers["x-pagination"];					
                                this.Header = JSON.parse(headerString);
                                this.totalRows = this.Header.TotalCount;
                            }
                        }                     

                        EventBus.$emit('ShowWeel', false); 
                        if (this.items.length === 0) {
                            this.ShowMessageIsEmpty = true;
                        }
                    })
                    .catch((error) => {                    
                        EventBus.$emit('ShowWeel', false); 
                        this.showResponseErrorMessage(error, this.$t('ChartReview.NotesList'));  
                    }); 
            }else{
                ChartReview.getAllDate(this.parameterListing)
                    .then((response) => {
                        if(response.status == '200' && response.data){
                            this.items = response.data;					 										
                            if(response.data.length > 0){                
                                let headerString = response.headers["x-pagination"];					
                                this.Header = JSON.parse(headerString);
                                var l = this.items.length;
                                this.totalRows = this.Header.TotalCount;
                                var Pagination = {};				
                                var obj = {};					
                                Pagination.currentPageSubItems = this.currentPageSubItems;
                                Pagination.perPageSubItems = this.perPageSubItems;
                                Pagination.totalRowsSubItems = this.totalRows;

                                var tmpdate,tmpsigneddate,tmpcosigneddate,tmptime,fulldate;
                                for(var i = 0; i < this.items.length; i++){
                                    var notesID = this.items[i].notesID;
                                    tmptime = "";
                                    if(this.items[i].transDate){
                                        //tmpdate = Utils.formatterDateTimeToString(this.items[i].transDate);
                                        tmpdate = Utils.formatterDate(this.items[i].transDate);
                                        tmptime = moment(this.items[i].transDate).format('HH:mm:ss');
                                    }	
							
                                    if(this.items[i].signedDate){
                                        tmpsigneddate = Utils.formatterDateTimeToString(this.items[i].signedDate);
                                        this.items[i].signedDate = tmpsigneddate;
                                    }

                                    if(this.items[i].coSignedDate){
                                        tmpcosigneddate = Utils.formatterDateTimeToString(this.items[i].coSignedDate);
                                        this.items[i].coSignedDate = tmpcosigneddate;
                                    }
														
                                    if(this.items[i].frequency === 'DAILY MULTIPLES'){ // noteTime  transDate
                                        if(this.items[i].noteTime){
                                            tmptime = moment(this.items[i].noteTime).format('HH:mm:ss');
                                        }								
                                    }
                                    fulldate = tmpdate+" "+tmptime;
                                    this.items[i].transdate = Utils.formatterDateToShortLocaleString(fulldate);

                                    if(l > 0){
                                        if(this.NoteIsDailyMultiple(notesID)){
                                                var TransD = moment(this.items[i].transDate).format("ddd, ll");
                                                Exist = this.ExistDate(SubItems,TransD);
                                                obj.ArrTimes = this.getTimesByDate(this.items, TransD);							
                                                obj.date = TransD;
                                                obj.dateFormatted = Utils.formatterDateToShortLocaleString(this.items[i].transDate);
                                                obj.noteType = "N";
                                                obj.noteName = this.$t('ChartReview.ClinicalNotes');
                                                if(!Exist)
                                                    SubItems.push(obj);
                                                obj = {};							
                                            
                                        }else{								
                                            obj = {
                                                date:'',
                                                patientCode:'',
                                                dateFormatted:'',
                                                icon:'',
                                                description:'',
                                                Id:'',
                                                isBillable:false,
                                                defaultUnit:0,
                                                therapistName:'',
                                                coTherapistName:'',
                                                isVoid:false,
                                                coTherapistCode:null,
                                                coSignedDate:null,
                                                frequency:"",
                                                endTime:null,
                                                facilityCode:"",
                                                isCoSigned:null,
                                                isPendingCoSignature:null,
                                                isPendingSignature:null,
                                                isSigned:null,
                                                noteStatus:"",
                                                noteTime:null,							
                                                chartno:"",
                                                serviceDate:null,
                                                signedDate:null,
                                                startTime:null,
                                                therapistCode:"",
                                                transCode:"",
                                                transDate:null,
                                                notesContents:"",
                                                disableBillable:false,
                                                showBilling:false,
                                                showReport:false,
                                                userHasReadOnlyAccess:false,
                                                enableEdit:true,
                                                customFrame:"",
                                                noteType:"",
                                                noteName:"",
                                                fileName:"",
                                                fileExtension:"",
                                                fileMimeType:""
                                            };
                                            obj.noteType = this.items[i].noteType;
                                            obj.noteName = this.items[i].noteName;
                                            obj.fileName = this.items[i].fileName;
                                            obj.fileExtension = this.items[i].fileExtension;
                                            obj.fileMimeType = this.items[i].fileMimeType;
                                            obj.patientCode = this.items[i].patientCode;
                                            obj.therapistName = this.items[i].therapistName;
                                            obj.coTherapistName = this.items[i].coTherapistName;
                                            obj.date = moment(this.items[i].transDate).format("ddd, ll");
                                            obj.dateFormatted = Utils.formatterDateToShortLocaleString(this.items[i].transDate);
                                            obj.Id = this.items[i].headerID;
                                            obj.isBillable = this.items[i].isBillable;
                                            obj.isVoid = this.items[i].isVoid;
                                            obj.coTherapistCode = this.items[i].coTherapistCode;
                                            obj.disableBillable = this.items[i].disableBillable;
                                            obj.defaultUnit = this.items[i].defaultUnit;
                                            obj.showBilling = this.items[i].showBilling;
                                            obj.chartno = this.items[i].chartno;
                                            obj.frequency = this.items[i].frequency;
                                            obj.enableEdit = this.items[i].enableEdit;
                                            obj.showReport = this.items[i].showReport;
                                            obj.customFrame = this.items[i].customFrame;
                                            obj.userHasReadOnlyAccess = this.items[i].userHasReadOnlyAccess;						
                                            if(this.items[i].coSignedDate){
                                                obj.coSignedDate = Utils.formatterDateTimeToString(this.items[i].coSignedDate);
                                                //obj.coSignedDate = moment(this.items[i].coSignedDate).format("ddd, ll");
                                            }
                                            obj.endTime = this.items[i].endTime;
                                            obj.facilityCode = this.items[i].facilityCode;
                                            obj.isCoSigned = this.items[i].isCoSigned;
                                            obj.isPendingCoSignature = this.items[i].isPendingCoSignature;
                                            obj.isPendingSignature = this.items[i].isPendingSignature;
                                            obj.isSigned = this.items[i].isSigned;
                                            obj.noteStatus = this.items[i].noteStatus;
                                            obj.noteTime = this.items[i].noteTime;
                                            if(this.items[i].serviceDate){
                                                obj.serviceDate = Utils.formatterDate(this.items[i].serviceDate);
                                            }
                                            if(this.items[i].signedDate){
                                                obj.signedDate = Utils.formatterDateTimeToString(this.items[i].signedDate);
                                                //obj.signedDate = moment(this.items[i].signedDate).format("ddd, ll");
                                            }
						
                                            obj.startTime = this.items[i].startTime;
                                            obj.therapistCode = this.items[i].therapistCode;
                                            obj.transCode = this.items[i].transCode;
                                            obj.transDate = this.items[i].transDate;
                                            obj.notesContents = this.items[i].notesContents;
                                            if(this.items[i].noteStatus == 'New'){								
                                                obj.icon = 'icon-doc mr-2';
                                                obj.description = this.$t('Shared.IconDescN');				
                                            }
                                            else if(this.items[i].noteStatus == 'Voided'){
                                                obj.icon = 'icon-ban mr-2';
                                                obj.description = this.$t('Shared.IconDescVN');
                                            }
                                            else if(this.items[i].noteStatus == 'Pending Signature'){
                                                obj.icon = 'icon-note  mr-2';
                                                obj.description = this.$t('Shared.IconDescPS');
                                            }
                                            else if(this.items[i].noteStatus == 'Pending CosignerSignature'){
                                                obj.icon = 'icon-user mr-2';
                                                obj.description = this.$t('Shared.IconDescPCS');
                                            }else{
                                                obj.icon = 'icon-check mr-2';
                                                obj.description = this.$t('Shared.IconDescF');
                                            }
                                            SubItems.push(obj);	
                                            this.AddSubItemsbyRow(notesID,SubItems,Pagination);						
                                        }
                                    } 
                                }				
                            }
                        }                     
						
                        EventBus.$emit('ShowWeel', false); 
						if (this.items.length === 0) {
							this.ShowMessageIsEmpty = true;
						}
                    })
                    .catch((error) => {                    
                        //this.$emit('load', false);
                        EventBus.$emit('ShowWeel', false); 
                        this.showResponseErrorMessage(error, this.$t('ChartReview.NotesList'));  
                    }); 
            }
        },

        async getNoteAllDate(notesid,type,currentPageSubItems,perPageSubItems){
            EventBus.$emit('ShowWeel', true);
            var SubItems = [];
            var Exist = false;	
            this.parameter.PageNumber = currentPageSubItems
            this.parameter.PageSize = perPageSubItems;
            this.parameter.PatientCode = this.$route.params.id;
            this.parameter.DateFrom = Utils.formatterToISO(this.DateFrom);
            this.parameter.DateTo = Utils.formatterToISO(this.DateTo);	
            this.parameter.UserId = LocalStorage.getUserId();
            this.parameter.NotesIdList = [notesid];
            this.parameter.NoteStatus = this.NoteStatusVModel.value;
            this.parameter.Type = type;

            await ChartReview.getAllDate(this.parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.NoteDates = response.data;						
						var l = this.NoteDates.length;
						let headerString = response.headers["x-pagination"];
						this.Header = JSON.parse(headerString);
						var totalRowsSubItems = this.Header.TotalCount;					
						var Pagination = {};				
						var obj = {};					
						Pagination.currentPageSubItems = currentPageSubItems;
						Pagination.perPageSubItems = perPageSubItems;
						Pagination.totalRowsSubItems = totalRowsSubItems;
						if(l > 0){
							if(this.NoteIsDailyMultiple(notesid)){
								for(var i = 0; i<l; i++){
									var TransD = moment(this.NoteDates[i].transDate).format("ddd, ll");
									Exist = this.ExistDate(SubItems,TransD);
									obj.ArrTimes = this.getTimesByDate(this.NoteDates, TransD);							
									obj.date = TransD;
									obj.dateFormatted = Utils.formatterDateToShortLocaleString(this.NoteDates[i].transDate);
                                    obj.noteType = 'N';
                                    obj.noteName = this.$t('ChartReview.ClinicalNotes');
									if(!Exist)
										SubItems.push(obj);
									obj = {};							
								}
							}else{
								for(var j = 0; j<l; j++){
                                    obj = {
                                        patientCode:'',
                                        date:'',
                                        dateFormatted:'',
                                        icon:'',
                                        description:'',
                                        Id:'',
                                        isBillable:false,
                                        defaultUnit:0,
                                        isVoid:false,
                                        therapistName:'',
                                        coTherapistName:'',
                                        coTherapistCode:null,
                                        coSignedDate:null,
                                        frequency:"",
                                        endTime:null,
                                        facilityCode:"",
                                        isCoSigned:null,
                                        isPendingCoSignature:null,
                                        isPendingSignature:null,
                                        isSigned:null,
                                        noteStatus:"",
                                        noteTime:null,		
                                        showReport:false,					
                                        chartno:"",
                                        serviceDate:null,
                                        signedDate:null,
                                        startTime:null,
                                        therapistCode:"",
                                        transCode:"",
                                        transDate:null,
                                        notesContents:"",
                                        disableBillable:false,
                                        showBilling:false,
                                        userHasReadOnlyAccess:false,
                                        enableEdit:true,
                                        customFrame:"",
                                        noteType:"",
                                        notename:"",
                                        fileName:"",
                                        fileExtension:"",
                                        fileMimeType:""
                                    };
                                    obj.noteType = this.NoteDates[j].noteType;
                                    obj.noteName = this.NoteDates[j].noteName;
                                    obj.fileName = this.NoteDates[j].fileName;
                                    obj.fileExtension = this.NoteDates[j].fileExtension;
                                    obj.fileMimeType = this.NoteDates[j].fileMimeType;
                                    obj.patientCode = this.NoteDates[j].patientCode;
                                    obj.therapistName = this.NoteDates[j].therapistName;
                                    obj.coTherapistName = this.NoteDates[j].coTherapistName;
                                    obj.date = moment(this.NoteDates[j].transDate).format("ddd, ll");
                                    obj.dateFormatted = Utils.formatterDateToShortLocaleString(this.NoteDates[j].transDate);
                                    obj.Id = this.NoteDates[j].headerID;
                                    obj.isBillable = this.NoteDates[j].isBillable;
                                    obj.isVoid = this.NoteDates[j].isVoid;
                                    obj.coTherapistCode = this.NoteDates[j].coTherapistCode;
                                    obj.disableBillable = this.NoteDates[j].disableBillable;
                                    obj.defaultUnit = this.NoteDates[j].defaultUnit;
                                    obj.showBilling = this.NoteDates[j].showBilling;
                                    obj.chartno = this.NoteDates[j].chartno;
                                    obj.frequency = this.NoteDates[j].frequency;
                                    obj.enableEdit = this.NoteDates[j].enableEdit;
                                    obj.customFrame = this.NoteDates[j].customFrame;
                                    obj.showReport = this.NoteDates[j].showReport;
                                    obj.userHasReadOnlyAccess = this.NoteDates[j].userHasReadOnlyAccess;						
                                    if(this.NoteDates[j].coSignedDate){
                                        obj.coSignedDate = Utils.formatterDateTimeToString(this.NoteDates[j].coSignedDate);
                                        //obj.coSignedDate = moment(this.NoteDates[j].coSignedDate).format("ddd, ll");
                                    }
                                    obj.endTime = this.NoteDates[j].endTime;
                                    obj.facilityCode = this.NoteDates[j].facilityCode;
                                    obj.isCoSigned = this.NoteDates[j].isCoSigned;
                                    obj.isPendingCoSignature = this.NoteDates[j].isPendingCoSignature;
                                    obj.isPendingSignature = this.NoteDates[j].isPendingSignature;
                                    obj.isSigned = this.NoteDates[j].isSigned;
                                    obj.noteStatus = this.NoteDates[j].noteStatus;
                                    obj.noteTime = this.NoteDates[j].noteTime;
                                    if(this.NoteDates[j].serviceDate){
                                        obj.serviceDate = Utils.formatterDate(this.NoteDates[j].serviceDate);
                                    }
                                    if(this.NoteDates[j].signedDate){
                                        obj.signedDate = Utils.formatterDateTimeToString(this.NoteDates[j].signedDate);
                                        //obj.signedDate = moment(this.NoteDates[j].signedDate).format("ddd, ll");
                                    }
						
                                    obj.startTime = this.NoteDates[j].startTime;
                                    obj.therapistCode = this.NoteDates[j].therapistCode;
                                    obj.transCode = this.NoteDates[j].transCode;
                                    obj.transDate = this.NoteDates[j].transDate;
                                    obj.notesContents = this.NoteDates[j].notesContents;
                                    if(this.NoteDates[j].noteStatus == 'New'){								
                                        obj.icon = 'icon-doc mr-2';
                                        obj.description = this.$t('Shared.IconDescN');				
                                    }
                                    else if(this.NoteDates[j].noteStatus == 'Voided'){
                                        obj.icon = 'icon-ban mr-2';
                                        obj.description = this.$t('Shared.IconDescVN');
                                    }
                                    else if(this.NoteDates[j].noteStatus == 'Pending Signature'){
                                        obj.icon = 'icon-note  mr-2';
                                        obj.description = this.$t('Shared.IconDescPS');
                                    }
                                    else if(this.NoteDates[j].noteStatus == 'Pending CosignerSignature'){
                                        obj.icon = 'icon-user mr-2';
                                        obj.description = this.$t('Shared.IconDescPCS');
                                    }else{
                                        obj.icon = 'icon-check mr-2';
                                        obj.description = this.$t('Shared.IconDescF');
                                    }
                                    SubItems.push(obj);
                                }
                            }
						}                      
						
						this.AddSubItemsbyRow(notesid,SubItems,Pagination);
                    }                     

					EventBus.$emit('ShowWeel', false);
                })
                .catch((error) => {     
                    this.showResponseErrorMessage(error, this.$t('ChartReview.ClinicalGetAllDates'));               
                    EventBus.$emit('ShowWeel', false);
                }); 
        },

        SearchNotes(){
            this.getNoteList();
        },

        pageChanged(page) {            
            this.currentPage = page;
            this.parameter.PageNumber = this.currentPage;        
            this.getNoteList();
        },

        pageChangedSubItems(e) {            
            this.currentPageSubItems = e.page;	
            this.getNoteAllDate(e.NoteId,e.Type,this.currentPageSubItems,this.perPageSubItems);
        },


        PerPageFunction(perpage){
            this.perPage = perpage;
            this.parameter.PageNumber = 1;
            this.getNoteList();
        },

        PerPageFunctionSubItems(e){
            this.perPageSubItems = e.page;
            this.getNoteAllDate(e.NoteId,e.Type,1,this.perPageSubItems);
        },

        onRowSelected(items) {
            this.selected = items;		
        },

        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
        },

        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },

        selectThirdRow() {
            // Rows are indexed from 0, so the third row is index 2
            this.$refs.selectableTable.selectRow(2)
        },

        unselectThirdRow() {
            // Rows are indexed from 0, so the third row is index 2
            this.$refs.selectableTable.unselectRow(2)
        },

        ChangeDropDownTherapies(value){
            this.TherapiesVModel = value ? value.code : '';
        },

        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        async getPatientInfo(){
            this.PatientInfo = await this.getData(this.$route.params.id);
        },

		AdjustNoteContainer(Operation){
            var CountNotes = 0;	
            CountNotes = Operation === 'ADD'?this.$refs.ContainerNotes.children.length+1:this.$refs.ContainerNotes.children.length-1;		
            if(CountNotes === 1){
                this.ClassContainerNotes = 'cutescroll';
            }

            if(CountNotes > 1){
                this.ClassContainerNotes = 'notesHolder cutescroll';
            }
        },

        OnResultViewDate(obj){
            const showLoading = obj.noteType !== 'LN' && obj.noteType !== 'TP';
            this.$emit('load', showLoading);
            this.appendToNotesListOpened(obj);
            this.ClassAccordion = 'show';
            this.ShowNote = true;
            this.ShowFilter = false;
            if (showLoading) {
                setTimeout(() => this.$emit('load', false), 500);
            }
        },

        onResultados(obj) {
            this.OnResultViewDate(obj);
            /*const showLoading = obj.noteType !== 'LN' && obj.noteType !== 'TP';
            this.$emit('load', showLoading);
            this.appendToNotesListOpened(obj);
            this.ClassAccordion = 'show';
            this.ShowNote = true;
            this.ShowFilter = false;	
            if (showLoading) {
                setTimeout(() => this.$emit('load', false), 500);
            }*/
        },

        appendToNotesListOpened(obj) {
            if (obj.noteType == 'N') {
                this.ExistsNote = this.NotesListOpened.some(nItem => obj.noteType == 'N' &&
                                        (   
                                            ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id)) ||
                                            (   
                                                ((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)) ||
                                                ((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime))
                                            )
                                        ));																	  

                if (!this.ExistsNote) {
                    this.AdjustNoteContainer("ADD");
                    this.NotesListOpened.push(obj);
                }
            } else if (obj.noteType == 'D') {
                this.ExistsNote = this.NotesListOpened.some(nItem => obj.noteType == 'D' &&
                                        ( ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id)) ||
                                                                                (((nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)) )));																	  

                if (!this.ExistsNote) {
                    this.AdjustNoteContainer("ADD");
                    this.NotesListOpened.push({
                        Image: Utils.getType(obj.fileExtension),
                        IsNew: false,
                        comments: obj.notesContents,
                        description: obj.description,
                        doccode: obj.transCode,
                        entitycode: obj.patientCode,
                        entitytype: 'P',
                        expirationDate: obj.endTime,
                        extension: obj.fileExtension,
                        fileName: obj.fileName,
                        mimeType: obj.fileMimeType,
                        scanneddate: obj.transDate,
                        seqno: obj.chartno,
                        sysUser: obj.therapistCode,
                        Id: obj.Id,
                        notesId: obj.notesId,
                        noteType: obj.noteType
                    });
                } 
            } else if (obj.noteType == 'A') {
                this.ExistsNote = this.NotesListOpened.some(nItem => obj.noteType == 'A' && ((nItem.transCode === obj.transCode) && (nItem.Id === obj.Id) ||
                        (nItem.transCode === obj.transCode) && (nItem.transDate === obj.transDate)));

                if (!this.ExistsNote) {
                    this.AdjustNoteContainer("ADD");
                    this.NotesListOpened.push({
                        memoInfo: obj.notesContents,
                        showToPatient: obj.showBilling,
                        transCode: obj.transCode,
                        transDate: obj.transDate,
                        seqno: obj.chartno,
                        sysUser: obj.therapistCode,
                        Id: obj.Id,
                        notesId: obj.notesId,
                        noteType: obj.noteType,
                        therapistName: obj.therapistName
                    });
                }
            } else if (obj.noteType == 'LN') {
                this.ExistsNote = this.NotesListOpened.some(nItem => obj.noteType == 'LN' && ((nItem.Id === obj.Id) &&
                        (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && (nItem.transCode === obj.transCode)));

                if (!this.ExistsNote) {
                    this.AdjustNoteContainer("ADD");
                    this.NotesListOpened.push({
                        comments: obj.notesContents,
                        intakeDate: obj.transDate,
                        description: obj.description,
                        transCode: obj.transCode,
                        transDate: obj.transDate,
                        seqno: obj.chartno,
                        sysUser: obj.therapistCode,
                        Id: obj.Id,
                        notesId: obj.notesId,
                        noteType: obj.noteType
                    });
                }
            } else if (obj.noteType == 'DN') {
                this.ExistsNote = this.NotesListOpened.some(nItem => obj.noteType == 'DN' && ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id) ||
                        (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)));

                if (!this.ExistsNote) {
                    this.AdjustNoteContainer("ADD");
                    this.NotesListOpened.push({
                        comments: obj.notesContents,
                        serviceDate: obj.serviceDate,
                        serviceTime: obj.noteTime,
                        description: obj.description,
                        transCode: obj.transCode,
                        transDate: obj.transDate,
                        seqno: obj.chartno,
                        sysUser: obj.therapistCode,
                        Id: obj.Id,
                        notesId: obj.notesId,
                        noteType: obj.noteType,
                        therapistName: obj.therapistName
                    });
                }
            } else if (obj.noteType == 'TP') {
                this.ExistsNote = this.NotesListOpened.some(nItem => obj.noteType == 'TP' && ((nItem.notesId === obj.notesId) &&
                        (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && (nItem.transCode === obj.transCode)));

                if (!this.ExistsNote) {
                    this.AdjustNoteContainer("ADD");
                    this.NotesListOpened.push({
                        description: obj.description,
                        transCode: obj.transCode,
                        transDate: obj.transDate,
                        seqno: obj.chartno,
                        sysUser: obj.therapistCode,
                        Id: obj.Id,
                        notesId: obj.notesId,
                        noteType: obj.noteType,
                        isWiley: obj.customFrame == 'TfmWileyTreatmentPlanNotes',
                        admissionId: obj.facilityCode
                    });
                }
            }
        },

        CloseDocument(index) {
            this.NotesListOpened.splice(index, 1)
            this.AdjustNoteContainer("REMOVE");

            if(this.NotesListOpened.length == 0 && this.NotesListOpened.length == 0){
                this.ShowFilter = true;
                this.ShowNote = false;
            }
        },

        CloseNote(index){
            this.CloseDocument(index);
        },

        Accordion1Click(){	
            this.ShowFilter = !this.ShowFilter;
            this.ShowNote = !this.ShowNote;	
        },

        ClickBtnAccordion2(){			
            this.ShowFilter = !this.ShowFilter;
            this.ShowNote = !this.ShowNote;	
        },

        onCheck(param){	
            this.NotesId = param.notesId;
            this.currentPageSubItems = param.currentPage;
            this.perPageSubItems = 50;
            this.getNoteAllDate(this.NotesId,param.type,this.currentPageSubItems,this.perPageSubItems);
        },

        getNoteTypes(){
            GlobalService.getNoteTypes(false)
                .then((response) => {
                    if(response.data && response.data.length > 0){
                        this.OptionNoteTypes = response.data
                                        .filter(x => x.description != null && x.description != "")
                                        .sort((a, b) => {
                                            const x = a.description.toLowerCase();
                                            const  y = b.description.toLowerCase();
                                            return x < y ? -1 : x > y ? 1 : 0;
                                        });	
                        this.OptionTypes.push({
                            optionLabel: this.$t('ChartReview.ClinicalNotes'), 
                            optionValues: this.OptionNoteTypes.map(x => { 
                                return  {
                                    code: x.notesid,
                                    type: "N", 
                                    description: x.description
                                }
                            })
                        });
                    }             
                })
                .catch((error) => {
                    this.showResponseErrorMessage(error, this.$t('ChartReview.GetNoteTypes'));
                });
        },

        getDefaultDateFromAndDateTo(){		
            if(this.Origin === 'Clinical'){
                ChartReview.getPatientRegistrationsDefaultDates(this.$route.params.recordid)
                    .then((response) => {
                        if(response.data){
                            let defaultDate = response.data;			
                            this.DateFrom = moment(defaultDate.dateFrom).format('YYYY-MM-DD');
                            this.DateTo = moment(defaultDate.dateTo).format('YYYY-MM-DD');				
                        }             
                    })
                    .catch((error) => {
                        this.showResponseErrorMessage(error, this.$t('ChartReview.GetDefaultDate'));
                    });
            } else if(this.Origin === 'Patient' || this.Origin === undefined) {
                GlobalService.getDateTime()
                    .then((response) => {
                        if(response.status == '200' && response.data){
                            this.DateTo = moment(response.data).format('YYYY-MM-DD');	
                            this.DateFrom = moment(response.data).subtract(30, "days").format('YYYY-MM-DD');
                        }
                    })
                    .catch((error) => {
                        this.showResponseErrorMessage(error, this.$t('ChartReview.GetDate'));
                    });
            }
        },

        getAllEmployee(){
            EmployeeService.getAllEmployees()
                .then((response) => { 
                    if(response.data && response.data.length > 0){
                            this.TherapistList = response.data;
                            this.CotherapistList = response.data;
                    }             
                })
                .catch((error) => {
                    this.showResponseErrorMessage(error, this.$t('Shared.Employee'));
                });
        },

        async GetPatientByCode(){
            var infoList = await this.getInfoTitle(this.$route.params.id);     
            if(infoList.length > 0){
                this.PatientTitle.programName = '';
                this.PatientTitle.admitDate = '';
                this.PatientTitle.dischargeDate = '';

                const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

                if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age;
                if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;
            }
        },

        PopulatePatientTitle(){
            const { dob, dateFrom, dateTo, age, 
                    programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
            
            if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
            if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);		
            if(this.$route.name.includes('Clinical')){ 
                if (programName) this.PatientTitle.programName = programName; 
                if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
                if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
                if (patientName) this.PatientTitle.fullName = patientName;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age === 0 ? "0" : age;
                if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
            }
            if(this.$route.name.includes('patient') || this.$route.name.includes('appointments-chartreview')){         
                this.PatientTitle.programName = '';
                this.PatientTitle.dateFrom = '';
                this.PatientTitle.dateTo = '';
            }
        },

        async getProgramCodeByRecordId(){
            this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);	
            const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

            if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
            if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
            if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
            if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
            this.ProgramCode = this.RegistrationInfo.programCode;	
        },

        async getCurrentAppointment() {
            this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
            this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
            this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
        },

        getDocumentTypes() {  
            DocumentType.getAll(this.parameter)
                    .then((response) => {
                        if(response.status == '200' && response.data.length > 0){           
                            this.OptionDocumentTypes = response.data; 
                            this.OptionTypes.push({
                                optionLabel: this.$t('ChartReview.Documents'), 
                                optionValues: this.OptionDocumentTypes.map(x => { 
                                    return  {
                                        code: x.code,
                                        type: "D", 
                                        description: x.description
                                    }
                                })
                            });
                        }
                    })
                    .catch(error => {
                        this.showResponseErrorMessage(error, this.$t('ChartReview.GetDocumentTypes'));
                    });
        },

        DoReport(row, item) {
            if (item.noteType !== undefined) {
                switch (item.noteType) {
                    case 'D':
                        this.PatientDocumentView({seqno: item.chartno});
                        break;
                    case 'A':
                        this.PatientActivityView({recId: item.chartno, userName: item.therapistName});
                        break;
                    case 'DN':
                        this.PatientDailyNoteView({recId: item.chartno, modelDailyNoteType: item.facilityCode});
                        break;
                    case 'LN':
                        this.PatientLogNoteView({recId: item.Id, modelLogNoteType: item.facilityCode, intakeDate: item.transDate, headerId: item.Id});
                        break;
                    case 'TP':
                        this.PatientTreatmentPlanView({recId: item.facilityCode, problemRecId: item.chartno, notesId: row.notesId ? row.notesId : item.notesID});
                        break;
                    default:
                        return false;
                }

                return true;
            }

            return false;
        },

        VerifyExtension(ext){
            if(ext) {
                if (ext.toLowerCase() === ".pdf" || ext.toLowerCase() === ".jpeg" ||
                    ext.toLowerCase() === ".jpg" || ext.toLowerCase() === ".png" || 
                    ext.toLowerCase() === ".bmp" || ext.toLowerCase() === ".svg" ||
                    ext.toLowerCase() === ".gif" || ext.toLowerCase() === ".eps"){
                        return true;
                }
                return false;
            }

            return false;
        },

        PatientActivityView(row){
            if (row && row.recId) {
                this.recNoToReport = row.recId;
                this.userNameToReport = row.userName;
            }

            this.isActivityReportVisible = true;
        },

        PatientDailyNoteView(row){
            if (row && row.recId) {
                this.recNoToReport = row.recId;
                this.ModelDailyNoteType = row.modelDailyNoteType;
            }

            this.isDailyNoteReportVisible = true;
        },

        PatientLogNoteView(row){
            if (row && row.recId) {
                this.recNoToReport = row.recId;
                this.ModelLogNoteType = row.modelLogNoteType;
                this.intakeDate = row.intakeDate;
                this.LogNoteHeaderId = row.headerId;
            }

            this.isLogNoteReportVisible = true;
        },

        PatientTreatmentPlanView(row) {
            if (row && row.recId) {
                this.ProblemRecId = row.problemRecId,
                this.ProblemRecNo = row.recId;
                this.ProblemNotesId = row.notesId;
            }
            this.isTreatmentPlanProblemRepVisible = true;
        },

        closeParamRepWindow() {
            this.isActivityReportVisible = false;
            this.isDailyNoteReportVisible = false;
            this.isLogNoteReportVisible = false;
            this.isTreatmentPlanProblemRepVisible = false;
            this.recNoToReport = null;
            this.userNameToReport = null;
            this.ProblemRecId = null;
            this.ProblemRecNo = null;
            this.ProblemNotesId = null;
        },

        PatientDocumentView(row){     
            var baseurl = this.$store.state.apiurl.replace('/api/', '');      
            this.$emit('load', true);           
            DocumentManagement.GetPatientUrl(row.seqno)
                .then((response) => {
                    if(response.status == '200'){  
                        const doc = response.data;         
                        this.FullUrl = baseurl+doc.url;  

                        if (this.VerifyExtension(doc.extension)){
                            this.isVisible = true;
                        } else {          
                            this.Download(this.FullUrl, doc.fileName);       
                        }
                    }
                    this.$emit('load', false);
                })
                .catch (error => {
                    this.showResponseErrorMessage(error, this.$t('ChartReview.GetDocument'));
                    this.$emit('load', false);
                });
        },

        async Download(url, filename) {          
            const data = await fetch(url)
            const blob = await data.blob()
            const objectUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.setAttribute('href', objectUrl)
            link.setAttribute('download', filename)
            link.style.display = 'none'
            document.body.appendChild(link)                          
            link.click()                          
            document.body.removeChild(link)
        },

        closeRepWindow() {
            this.isVisible = false;
        },

        async getDailyNoteTypes() {
            const NotesTitlesParams = {
                    SearchCriteria:'',
                    PageNumber : 0,
                    PageSize:0,      
                };

            await NotesTitlesService.getAll(NotesTitlesParams)
                .then((response) => {
                    if (response.status == "200" && response.data && response.data.length > 0) {
                        this.OptionDailyNoteTypes = response.data
                                        .filter(x => x.name != null && x.name != "")
                                        .sort((a, b) => {
                                            const x = a.name.toLowerCase();
                                            const  y = b.name.toLowerCase();
                                            return x < y ? -1 : x > y ? 1 : 0;
                                        });	
                            this.OptionTypes.push({
                                optionLabel: this.$t('ChartReview.DailyNotes'), 
                                optionValues: this.OptionDailyNoteTypes.map(x => { 
                                    return  {
                                        code: x.code,
                                        type: "DN", 
                                        description: x.name
                                    }
                                })
                            });
                        }
                    }
                )
                .catch((error) => {
                    this.showResponseErrorMessage(error, this.$t('ChartReview.GetDailyNoteTypes'));
                });
        },

        getDailyNoteTypeName() {
            return this.findNoteTypeCode(this.OptionDailyNoteTypes, this.ModelDailyNoteType).name
        },

        getLogNoteTypeName() {
            return this.findNoteTypeNoteId(this.OptionLogNoteTypes, this.ModelLogNoteType).description
        },

        findNoteTypeCode (array, code) {
            return array.find(element => element.code === code) ?? -1;
        },

        findNoteTypeNoteId (array, code) {
            return array.find(element => element.notesId === code) ?? -1;
        },

        async getLogNoteTypes() {
            const NotesTitlesParams = {
                    SearchCriteria:'',
                    PageNumber : 0,
                    PageSize:0,   
                    NoteType: 'TFrmMdLogNotes'   
                };

            await BioSetupService.getAll(NotesTitlesParams)
                .then((response) => {
                    if (response.status == "200" && response.data && response.data.length > 0) {
                        this.OptionLogNoteTypes = response.data
                                        .filter(x => x.description != null && x.description != "")
                                        .sort((a, b) => {
                                            const x = a.description.toLowerCase();
                                            const  y = b.description.toLowerCase();
                                            return x < y ? -1 : x > y ? 1 : 0;
                                        });	
                            this.OptionTypes.push({
                                optionLabel: this.$t('ChartReview.LogNotes'), 
                                optionValues: this.OptionLogNoteTypes.map(x => { 
                                    return  {
                                        code: x.biocode,
                                        type: "LN", 
                                        description: x.description
                                    }
                                })
                            });
                        }
                    }
                )
                .catch((error) => {
                    this.showResponseErrorMessage(error, this.$t('ChartReview.GetLogNoteTypes'));
                });
        },
    },

    updated(){
        //this.getDefaultDateFromAndDateTo();
    },

    async mounted(){  
        this.parameter.PageNumber = this.currentPage;        
        this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinicalchartreview') ? EnumService.ClinicalItems : this.$route.name.includes('appointments-chartreview') ? EnumService.AppointmentItems : EnumService.PatientItems;
        this.OptionsRightMenu.Mode = true;
        this.OptionsRightMenu.visible = true; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.$emit('getBtnRightMenu', this.OptionsRightMenu); 
        
        if(this.$route.name.includes('patient') || this.$route.name.includes('appointments-chartreview')){  
            this.GetPatientByCode();
        }

        if (this.$route.name.includes('appointments-chartreview'))
        await this.getCurrentAppointment()

        if(this.$route.name.includes('Clinical')){   
            this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
            /* eslint-disable no-unused-vars */
            const { dob, age, dateTo } = this.RegistrationInfo; // Destructure for easier access
        
            this.IsClinical = dateTo ? false : true; 
        }

        this.PopulatePatientTitle();
        this.getDefaultDateFromAndDateTo();
        this.getAllEmployee();
        this.getPatientInfo();
        await this.getDocumentTypes();
        await this.getNoteTypes();
        await this.getDailyNoteTypes();
        await this.getLogNoteTypes();
        
        if(this.Origin === 'Patient'){
            this.getPrograms();
        }
    }
};
</script>


