<template>
  <div>
    <div class="row">
         <div class="col-12">
            <h4 class="page-title text-truncate">
                {{ $t('HouseFile.HouseFileTitle') }} 
                <span v-if="this.TemplateModel.code">- {{this.TemplateModel.code}}</span>
            </h4>
        <b-row>
            <b-col md="12" sm="12" lg="12">
                <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false" v-bind:ShowTabEnd="false">
                    <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
                        <div class="tab-pane fade show active" id="general" role="tabpanel">
                            <b-row>
                                <b-col lg="3" md="3" sm="12">
                                    <MdEdit 
                                        v-bind:Label="$t('Shared.datefrom')"
                                        v-bind:TypeProp="'date'" 
                                        v-bind:Name="'DateFrom'"
                                        v-model="DateFrom"
                                        v-bind:Min="'1900-01-01'"
                                        v-bind:Max="'2200-12-31'"
                                        v-bind:OnPressEnter="DoSearch">                                     
                                    </MdEdit>
                                </b-col>
                                <b-col lg="3" md="3" sm="12">
                                    <MdEdit 
                                        v-bind:Label="$t('Shared.dateTo')"
                                        v-bind:TypeProp="'date'" 
                                        v-bind:Name="'DateTo'"
                                        v-model="DateTo"
                                        v-bind:Min="'1900-01-01'"
                                        v-bind:Max="'2200-12-31'"
                                        v-bind:OnPressEnter="DoSearch">                                     
                                    </MdEdit>
                                </b-col>
                                <b-col lg="3" md="3" sm="12">         
                                    <MdSelect
                                        v-bind:Label="$t('HouseFile.PrimaryInsurances')"
                                        v-bind:Options="PrimaryInsuranceList"
                                        v-bind:Model="PrimaryInsuranceModel"
                                        v-bind:FieldDescription="'insuranceName'"
                                        v-bind:FieldCode="'insuranceCode'"
                                        v-bind:Multiple="true"             
                                        :Input="ChangeDropDownPrimaryInsurance"/>
                                    </b-col>
                                <b-col lg="3" md="3" sm="12">         
                                    <MdSelect
                                        v-bind:Label="$t('HouseFile.PrimaryInsurancesC')"
                                        v-bind:Options="PrimaryInsuranceCategoryList"
                                        v-bind:Model="PrimaryInsuranceCategoryModel"
                                        v-bind:FieldDescription="'insuranceName'"
                                        v-bind:FieldCode="'insuranceCode'"
                                        v-bind:Multiple="true"             
                                        :Input="ChangeDropDownPrimaryInsuranceCategory"/>
                                    </b-col>
                            </b-row>

                            <b-row>
                                <b-col lg="3" md="3" sm="12">
                                    <MdSelect
                                        v-bind:Label="$t('HouseFile.InsType')"
                                        v-bind:Options="TypeList"
                                        v-bind:FieldDescription="'name'"
                                        v-bind:FieldCode="'code'"
                                        v-bind:isRequired="true" 
                                        v-bind:FeedBackText="$t('HouseFile.ITypeRequiredMsg')"  
                                        v-bind:isValid="isInsuranceTypeValid"
                                        v-bind:Model="TypeModel"  
                                        v-bind:Multiple="false" 
                                        :Input="ChangeDropDownType" 
                                    />
                                </b-col>   
                                <b-col lg="3" md="3" sm="12">
                                    <MdSelect
                                        v-bind:Label="$t('HouseFile.SendOpt')"
                                        v-bind:Options="SendOptionsList"
                                        v-bind:FieldDescription="'name'"
                                        v-bind:FieldCode="'code'"
                                        v-bind:Model="SendOptionsModel"  
                                        v-bind:Multiple="false" 
                                        v-bind:isRequired="true" 
                                        v-bind:FeedBackText="$t('HouseFile.ORequiredMsg')"  
                                        v-bind:isValid="isSendOptionsValid"
                                        :Input="ChangeDropDownSendOptions" 
                                    />
                                </b-col>  
                                <b-col lg="3" md="3" sm="12">
                                    <MdCheckBox
                                        v-bind:Label="$t('HouseFile.UserCPTs')"
                                        v-bind:Name="'ShowUserDefinedCPTs'"
                                        v-bind:ClassProp="'AlignCheck'"
                                        v-bind:CheckedValue="true"
                                        v-bind:UncheckedValue="false"
                                        v-model="ShowUserDefinedCPTs"                 
                                    />
                                </b-col>
                                <b-col lg="2" md="2" sm="12">
                                <MdCheckBox
                                    v-bind:Label="$t('HouseFile.HZBalance')"
                                    v-bind:Name="'InputHideZeroBalance'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="InputHideZeroBalance"                 
                                />
                                </b-col>
                            </b-row>
                        </div>
                    <div class="tab-pane fade" id="additional" role="tabpanel">
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <!-- <MdSelect
                                    v-bind:Label="'Prior Batches'"
                                    v-bind:Options="PriorBatchesList"
                                    v-bind:FieldDescription="'name'"
                                    v-bind:FieldCode="'code'"
                                    v-bind:Model="PriorBatchesModel"  
                                    v-bind:Multiple="false" 
                                    :Input="ChangeDropDownPriorBatches"/> -->
                                <MdLookup
                                    v-bind:Label="$t('HouseFile.PBatches')"
                                    v-bind:ModelCode="PriorBatchesModel"
                                    v-bind:ModelDescription="PriorBatchesModelDescription"
                                    v-bind:RefCode="'PriorBatchesModel'"
                                    v-bind:Fields="BaseFields"
                                    v-bind:Api="'FileCreationBatches'"
                                    v-bind:FieldsDate="FieldsDatePriosBacthes"
                                    v-bind:FieldCode="'recID'"
                                    v-bind:FieldDescription="'fileName'"
                                    :SearchTitle="'Prior Batches'"
                                    :GetValues="onCodeConditionOk"
                                    :InvalidFeedback="'Invalid Prior Batches Code'"
                                    :SearchFieldCode="'recID'"                      
                                />
                            </b-col>  
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$tc('Shared.Patient',2)"
                                    v-bind:Options="PatientsList"
                                    v-bind:Model="PatientCodeModel"
                                    v-bind:FieldDescription="'patientName'"
                                    v-bind:FieldCode="'patientCode'"
                                    v-bind:Multiple="true"             
                                    :Input="ChangeDropDownPatients"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdSelect
                                    v-bind:Label="$tc('Shared.Program',2)"
                                    v-bind:Options="ProgramList"
                                    v-bind:FieldDescription="'programName'"
                                    v-bind:FieldCode="'programCode'"
                                    v-bind:Multiple="true"
                                    v-bind:Model="ProgramModel"  
                                    :Input="ChangeDropDownPrograms"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdSelect
                                    v-bind:Label="$t('Shared.Doctors')"
                                    v-bind:Options="DoctorsList"
                                    v-bind:FieldDescription="'doctorName'"
                                    v-bind:FieldCode="'doctorCode'"
                                    v-bind:Model="DoctorsModel"  
                                    v-bind:Multiple="true" 
                                    :Input="ChangeDropDownDoctors"/>
                            </b-col>     
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdSelect
                                    v-bind:Label="$tc('Shared.Therapist',2)"
                                    v-bind:Options="TherapistList"
                                    v-bind:FieldDescription="'therapistName'"
                                    v-bind:FieldCode="'therapistCode'"
                                    v-bind:Model="TherapistModel"  
                                    v-bind:Multiple="true" 
                                    :Input="ChangeDropDownTherapist"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdSelect
                                    v-bind:Label="$t('HouseFile.Facilities')"
                                    v-bind:Options="FacilitiesList"
                                    v-bind:FieldDescription="'facilityName'"
                                    v-bind:FieldCode="'facilityCode'"
                                    v-bind:Model="FacilitiesModel"  
                                    v-bind:Multiple="true" 
                                    :Input="ChangeDropDownFacilities"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdSelect
                                    v-bind:Label="$t('HouseFile.CPTs')"
                                    v-bind:Options="CPTsList"
                                    v-bind:FieldDescription="'cptName'"
                                    v-bind:FieldCode="'cptCode'"
                                    v-bind:Model="CPTsModel"  
                                    v-bind:Multiple="true" 
                                    :Input="ChangeDropDownCPTs"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdSelect
                                    v-bind:Label="$t('HouseFile.POfService')"
                                    v-bind:Options="POSList"
                                    v-bind:FieldDescription="'posName'"
                                    v-bind:FieldCode="'posCode'"
                                    v-bind:Model="POSModel"  
                                    v-bind:Multiple="true" 
                                    :Input="ChangeDropDownPOS"/>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="tab-pane fade" id="fileoptions" role="tabpanel">
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdCheckBox
                                    v-bind:Label="$t('HouseFile.TypeOfBill')"
                                    v-bind:Name="'InputTypeOfBill'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="InputTypeOfBill"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdCheckBox
                                    v-bind:Label="$t('HouseFile.LSItems')"
                                    v-bind:Name="'InputUseLineServiceItems'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="InputUseLineServiceItems"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdCheckBox
                                    v-bind:Label="$t('HouseFile.IsSolo')"
                                    v-bind:Name="'InputIsSolo'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="InputIsSolo"/>
                            </b-col>
                                <b-col lg="3" md="3" sm="12">
                                    <MdCheckBox
                                        v-bind:Label="$t('HouseFile.SPFlag')"
                                        v-bind:Name="'InputSetPrintFlag'"
                                        v-bind:ClassProp="'AlignCheck'"
                                        v-bind:CheckedValue="true"
                                        v-bind:UncheckedValue="false"
                                        v-model="InputSetPrintFlag"/>
                                </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdCheckBox
                                    v-bind:Label="$t('HouseFile.TProvider')"
                                    v-bind:Name="'InputUseTherapyProvider'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="InputUseTherapyProvider"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdSelect
                                    v-bind:Label="$t('Shared.Type')"
                                    v-bind:Options="TemplateList"
                                    v-bind:FieldDescription="'name'"
                                    v-bind:FieldCode="'code'"
                                    v-bind:isRequired="true" 
                                    v-bind:Model="TemplateModel"
                                    v-bind:FeedBackText="$t('HouseFile.ORequiredMsg')"  
                                    v-bind:isValid="isTypeValid"
                                    :Input="ChangeDropDownTemplate"/>
                            </b-col>
                        </b-row>
                    </div>  
                </div>  
        </MdTabList>
    </b-col>
</b-row>
		<b-row>
            <b-col md="12">
				<div class="text-center text-md-right mt-3">
					<button v-on:click="DoSearch"
						type="button"
						class="btn btn-primary btn-login mx-2 pull-right">
						{{ $t('Shared.Search') }}
					</button>
				</div>
			</b-col>
		</b-row>	
 <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

   
            <b-row>
                <b-col md="12">
                <div class="flex-container">
              <div class="row">                
              
              <div class="col-md-3">
                 <MdCheckBox
                  v-bind:Label="$t('Shared.SelectAll')"
                  v-bind:Name="'SelectTableAll'"
                  v-bind:ClassProp="'AlignCheck mx-3'"
                  v-bind:CheckedValue="true"
                  v-bind:isReadOnly="this.items.length === 0"
                  v-bind:UncheckedValue="false"
                  v-model="UseSelectTableAll" 
                  v-on:change="onChangeSelectAll"                
               /> 
              </div>
              <div class="col-md-3 mt-3">
                <span class="tableSelectedItems"><span class="selItemsVal">{{ SelectedRecords }}</span> {{ $t('HouseFile.Item') }}<span v-if="SelectedRecords != 1">s</span> {{ $t('HouseFile.selected') }}</span>
              </div>  
             <div class="col-md-6">
                       <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                      <div class="input-group">
                          <input 
                            type="text" 
                            class="form-control"
                            v-model="modelSearch"                          
                            @keyup.enter="FindinItems()"
                          >
                          <div class="input-group-append">
                              <MdButton 
                                v-bind:Name="'btnsearchinput'"
                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                v-bind:IconClass="'mdi mdi-magnify'"                               
                                v-on:click="FindinItems"
                                 >
                              </MdButton>
                          </div>
                      </div>
                  </div>
             </div>                
        </div>
      </div>
            <MdTablePaginationClient
              v-bind:fields="fields"           
              v-bind:items="items"
              v-bind:totalRows="totalRows"  
              v-bind:refTable="refTable"           
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:SelectAll="UseSelectTableAll"
              v-bind:ShowDetails="true"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:pageOptions="pageOptions"              
              v-bind:Error="Error"              
              :onRowSelected="onRowSelected" 
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :RowClick="RowClicked"
              :perPageFunction="PerPageFunction">
            </MdTablePaginationClient>

      <ModalProviderSelector              
        @getCodeConditionOK="OnReturnData($event)"
        v-bind:ModalId="this.modalIdProviderSelector"
        v-bind:TemplateA="templateA"
        v-bind:TypeOfBill="InputTypeOfBillShowInModal"
        v-bind:TitleWindow="$t('HouseFile.BPSelector')">
      </ModalProviderSelector>

      <ModalTypeOfBill              
        @getCodeConditionOK="OnReturnData($event)"
        v-bind:ModalId="this.modalIdTypeofBill"        
        v-bind:TitleWindow="$t('HouseFile.TBill')">
      </ModalTypeOfBill>

      <ModalViewFile             
        @getCodeConditionOK="OnReturnData($event)"
        v-bind:ModalId="this.modalIdViewFile"        
        v-bind:TitleWindow="$t('HouseFile.FContent')">
      </ModalViewFile>
  
            
          <!--<MdButton v-if="this.permission.length > 0 && this.permission[0].roleCanAdd"
              v-bind:Label="'Create File'"
              v-bind:Name="'btnBatchClaimBatch'"
              v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"  
              v-bind:isReadOnly="SelectedRecords==0"         
              v-on:click="DoCreateFile"
             />-->



                </b-col>
            </b-row> 
        </div> 
            <div class="page-actions-btn">
            <MdButton v-if="this.permission.length > 0 && this.permission[0].roleCanAdd"
              v-bind:Label="$t('Shared.Report')"
              v-bind:Name="'CreateFileReport'"
              v-bind:ClassProp="'secondary mx-2 btn-login pull-right '"  
             v-bind:isReadOnly="true"  
             />

              <MdButton v-if="this.permission.length > 0 && this.permission[0].roleCanAdd"
              v-bind:Label="$t('HouseFile.VFile')"
              v-bind:Name="'CreateFileViewFile'"
              v-on:click="ViewFile"
              v-bind:ClassProp="'secondary mx-2 btn-login pull-right '"  
              v-bind:isReadOnly="filewascreated" 
             />

              <MdButton v-if="this.permission.length > 0 && this.permission[0].roleCanAdd"
              v-bind:Label="$t('HouseFile.TFile')"
              v-bind:Name="'CreateFileTransmitFile'"
              v-bind:ClassProp="'secondary mx-2 btn-login pull-right '"  
              v-bind:isReadOnly="SelectedRecords==0"    
             />
        <MdButton v-if="this.permission.length > 0 && this.permission[0].roleCanAdd"
              v-bind:Label="$t('HouseFile.CFile')"
              v-bind:Name="'btnBatchClaimBatch'"
              v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"  
              v-bind:isReadOnly="SelectedRecords==0"         
              v-on:click="DoCreateFile"
             />
    </div>        
       </div> 
 </div> 
</template>

<script>
//import EnumService from '@/services/enum.service'
//import ClinicalService from '@/services/clinical.service'
//import EmployeeService from '@/services/employee.service';
import ClaimFileCreations from '@/services/claimfilecreations.service';
import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import { EventBus } from '../../../event-bus.js';
//import { EventBus } from '../../../event-bus.js';
//import moment from 'moment'
import Utils from '@/common/utils'


export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
    name: "CreateClearingHouseFile",
    mixins: [ShowMessage, DBFunctions],
    data() {
        return {
            defaultValues: {},
            filewascreated:true,
            datafile:"",
        tablist: [
         { title: this.$t('Shared.General'), link: '#general', active: true},
         { title: this.$t('HouseFile.Additional'), link: '#additional'},
         { title: this.$t('HouseFile.FileOptions'), link: '#fileoptions'},          
         ],        
            Menu: this.$store.state.leftMenu,
            permission: [],           
            modalIdProviderSelector: "modalIdClaimProviderSelector",
            modalIdViewFile:"modalIdViewFileContents",
            modalIdTypeofBill:"modalIdClaimTypeOfBill",
            FieldsDateArr: ["dateFrom", "dateTo"], 
            FieldsDatePriosBacthes:['filedate'],
            BaseFields:[
            { key: 'fileName', sortable: true, label: this.$t('HouseFile.FileName')},
            { key: 'recID', sortable: true, label: 'recID',class: "text-center hidden" },
            { key: 'filedate', sortable: true, label: this.$t('HouseFile.FileDate')},
            { key: 'batchno', sortable: true, label: this.$t('HouseFile.BatchNo')}
            ],
            AttendanceDetailListSelected: [],
            SendOptionsList: [
                { "code": "First Time", "name": this.$t('HouseFile.SendOptList.fTime') },
                { "code": "Resend", "name": this.$t('HouseFile.SendOptList.Resend') },
                { "code": "All", "name": this.$t('Shared.All') },
            ],
            SendOptionsModel: { "code": "First Time", "name": this.$t('HouseFile.SendOptList.fTime') },
            FileName: "",
            InputTypeOfBillShowInModal:true,
            ShowBtnBatch: false,
            InputHideZeroBalance: false,
            InputSetPrintFlag: false,
            InputEcsFlag: false,
            isInsuranceTypeValid: true,
            isSendOptionsValid:true,
            isTypeValid:true,
            isTemplateValid:true,
            InputOrderByLineNo: false,
            InputUseTherapyProvider: false,
            ErrorList: [],
            templateclicked:"",
            TypeList: [
                { "code": "Primary", "name": "Primary" },
                { "code": "Primary (Exclude Claims with Primary Payments)", "name": "Primary (Exclude Claims with Primary Payments)" },
                { "code": "Secondary", "name": "Secondary" },
                { "code": "Secondary (Exclude Claims with Secondary Payments)", "name": "Secondary (Exclude Claims with Secondary Payments)" },
                { "code": "Tertiary", "name": "Tertiary" },
                { "code": "Tertiary (Exclude Claims with Tertiary Payments)", "name": "Tertiary (Exclude Claims with Tertiary Payments)" },
                { "code": "Secondary (Primary Pay)", "name": "Secondary (Primary Pay)" },
                { "code": "Tertiary (Primary Pay)", "name": "Tertiary (Primary Pay)" },
                { "code": "Tertiary (Secondary Pay)", "name": "Tertiary (Secondary Pay)" },
            ],
            TypeModel: { "code": "Primary", "name": "Primary" },
            WarningList: [],
            TemplateList: [
                { "code": "837BNPI501", "name": "837BNPI501" },
                { "code": "837ANPI501", "name": "837ANPI501" }
            ],
            TemplateModel:{ "code": "837ANPI501", "name": "837ANPI501" },
           // TemplateModel:{"code":"","name":""},
            SeqNoSelected:"",
            SeqNoListSelected:[],
           
            TitleDetail: "",
            ClassAccordion1: "",
            ClassAccordion2: "",
            TitleError: "",
            TitleWarning: "",
            SelectedRecords: 0,
            OldUnitNumber: 0,
            OldtotalCharges: 0,
            refTable: "selectableTableCreateClearingFile",
            pageOptions: [5, 10, 15],            
            modalIdDetail: "modalIdClaimDetail1",
            modalIdError: "modalIdClaimError",
            modalIdWarning: "modalIdClaimWarning",
            PatientCodeList: [],
            ProgramCodeList: [],
            ProgramCodeListBatch: [],
            TherapistCodeListBatch: [],
            TherapyCodeList: [],
            TherapyCodeListBatch: [],
            InsuranceCodeList: [],
            InsuranceCodeListBatch: [],
            modelSearch: "",
            perPage: 10,           
            SelectedPage: 0,
            totalRows: 0,
          
            // HeightTable:300,
            currentPage: 1,           
            wasSelectedAll: false,
            InputIsSolo: false,
            CopyItems: [],
            items: [],
            OriginalSaved: [],
            ShowMessageIsEmpty: false,
            InputUseLineServiceItems: false,
            SelectAll: false,
            ShowNote: false,
            templateA:false,
            ShowFilter: true,
            PatientCodeModel: "",
            PatientDescription: "",
            ProgramList: [],
            ProgramModel: "",
            TherapistList: [],
            TherapistModel: "",
            TherapiesList: [],
            TherapiesModel: "",
            InsuranceList: [],
            PrimaryInsuranceCategoryList: [],
            PrimaryInsuranceCategoryModel: "",
            InsuranceModel: "",
            DateFrom: "",
            DateTo: "",
            UseCategory: false,
            ExPrimPay: false,
            ShowUserDefinedCPTs: false,
            InputTypeOfBill: false,
            UseSelectTableAll: false,
            search: "",
            PatientsList: [],
            itemsSelected: [],
            PriorBatchesList: [],
            PrimaryInsuranceList: [],
            PrimaryInsuranceModel: "",
            DoctorsList: [],
            DoctorsModel: "",
            FacilitiesList: [],
            CPTsList: [],
            CPTsModel: "",
            FacilitiesModel: "",
            PriorBatchesModel: "",
            PriorBatchesModelDescription: "",
            POSList: [],
            typeOfBillList:"",
            patientCodeList:"",
            POSModel: "",
            PInsuranceList: [],
            PInsuranceCategoryList: [],
            DoctorsCodeList: [],
            CPTCodeList: [],
            TherapistCodeList: [],
            FacilityCodeList: [],
            PlaceOfServiceCodeList: [],
            PreviousBatchRecId  : "",
            fields: [
                { key: "patientName", sortable: true, label: this.$t('Shared.PatientName'), class: "text-rigth" },
                { key: "selected", sortable: true, label: this.$t('HouseFile.Selected'), class: "text-rigth hidden" },
                { key: "patientCode", sortable: true, label: this.$t('Shared.PatientCode'), class: "text-rigth" },
                { key: "issLineNo", sortable: true, label: "LN", class: "text-rigth" },
                { key: "dateFrom", sortable: true, label: this.$t('Shared.datefrom'), class: "text-rigth" },
                { key: "dateTo", sortable: true, label:this.$t('Shared.dateTo'), class: "text-rigth" },

                //{ key: "cptDescription", sortable: true, label: this.$t('Shared.CPT'), class: "text-rigth" },
                { key: "cptCode", sortable: true, label:this.$t('Shared.CPT'), class: "text-rigth" },
                { key: "hcPCsCode", sortable: true, label: this.$t('Shared.HCPCS'), class: "text-rigth" },

                { key: "modifier_1Code", sortable: true, label: "MD", class: "text-rigth" },
                { key: "day_Unit", sortable: true, label:this.$t('HouseFile.Unit'), class: "text-rigth" },
                // { key: 'totalCharges', sortable: true, label: 'Charges', class: 'text-rigth',formatter: value => {return "$"+value} },
                { key: "charges", sortable: true, label: this.$t('HouseFile.Charges'), class: "text-rigth", formatter: value => { return "$" + value; } },
                { key: "balance", sortable: true, label: this.$t('HouseFile.Balance'), class: "text-center", formatter: value => { return "$" + value; } },
                { key: "diagCode", sortable: true, label: "DIAG", class: "text-center" },
                { key: "insuranceCode", sortable: true, label: "Prim Ins", class: "text-center" },
                // { key: "insuranceDescription", sortable: true, label: "Insurance", class: "text-center" },
                { key: "insuranceCode1", sortable: true, label: "Sec Ins", class: "text-center" },
              
                { key: "insuranceCode2", sortable: true, label: "Ter Ins", class: "text-center" },
               
                // { key: "doctorCode", sortable: true, label: "Doctor Code", class: "text-center", formatter: value => { return value == "BLANK" ? "" : value; } },
                { key: "doctorName", sortable: true, label:  this.$t('MdPatientRegistrationInfo.DoctorName'), class: "text-center" },
                { key: "facilityCode", sortable: true, label: this.$t('HouseFile.Facility'), class: "text-center" },               
                { key: "posCode", sortable: true, label: "POS", class: "text-center" },              
                { key: "seqNo", sortable: true, label: "Seq No", class: "text-center" },
            ],
            OptionsRightMenu: {
                visible: false,
                ItemsList: [],
                btnvisible: false,
                itemdisabled: false,
                PatientCode: "",
                Mode: this.edit_mode,
                favButtonAtion: false
            },
        };
    },
    methods: {
        OnReturnData(data) {
            console.log(data);
        },
        Insurances() {
        },
        CanView(){
            return this.permission[0].roleCanView;
        },
        ViewFile(){
            this.$bvModal.show(this.modalIdViewFile);
        },

        CreateFile(pCode){
            this.$emit('load', true); 
            var data = {
                "billingFacilityProviderCode": pCode,
                "seqNoList": this.SeqNoSelected,
                "templateCode": this.TemplateModel.code,
                "insuranceType": this.TypeModel.code,
                "useLineServiceItems": this.InputUseLineServiceItems,
                "isSolo": this.InputIsSolo,
                "useTherapyProvider": this.InputUseTherapyProvider,
                "setPrintFlag": this.InputSetPrintFlag,
                "patientCodeList":this.patientCodeList,
                "typeOfBillList":this.typeOfBillList                  
            }; 
            if(this.TemplateModel.code != ""){       
             ClaimFileCreations.CreateFile(data)
                .then((response) => {
                if (response.status == '200') {
                    if(response.data.errorMessage == "" && response.data.fileContents != ""){
                        this.filewascreated = false;
                        this.datafile = response.data.fileContents;
                        EventBus.$emit("onFileContents", this.datafile);
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('HouseFile.FCSuccessfully'), {
                            title: this.$t('HouseFile.CFile'),
                            autoHideDelay: 5000,
                            variant: "success"
                            })
                        , 50); 
                        if(response.data.transmissionResponseCode == "202") {
                            setTimeout(() => 
                            this.$bvToast.toast(this.$t('HouseFile.FCTransmitSuccess'), {
                            title: this.$t('HouseFile.CFile'),
                            autoHideDelay: 5000,
                            variant: "success"
                            })
                        , 1000); 
                        }
                        else {
                            setTimeout(() => 
                                this.$bvToast.toast(response.data.transmissionErrorMessages, {
                                title: this.$t('HouseFile.CFile'),
                                autoHideDelay: 5000,
                                variant: "warning"
                                })
                            , 1000); 
                        }
                    }else{
                        this.showMessage(this.$t('HouseFile.CFile'), response.data.errorMessage, "error");  
                    }
                        
                    this.RemoveItemsBySeqNo();
                   /* if(!this.ContainTab('Files Created')){
                        this.tablist.push({title: 'Files Created', link: '#filescreated'});
                    }*/
                    this.$emit('load', false); 
                    
                    //this.VisibleFilesCreated = true;
                }
            })
                .catch((error) => {
                    this.$emit('load', false); 
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.CFile'), error, "error");
                }
            });
        }
        },

        DoCreateFile(/*e*/) { 
        /* this.templateclicked = e.target.innerText;               
          if(this.defaultValues.useBillingEntity){
            if(e.target.innerText == '837ANPI501'){
                this.templateA = true;
                EventBus.$emit("onSelectSeqNo", this.SeqNoSelected);
            }else{
                this.templateA = false;
            }
                EventBus.$emit("onGetProviders", true);
                this.$bvModal.show(this.modalIdProviderSelector);
           }
           if(!this.defaultValues.useBillingEntity && this.InputTypeOfBill && e.target.innerText == '837ANPI501'){
                EventBus.$emit("onSelectSeqNo", this.SeqNoSelected);
                EventBus.$emit("onGetTypeOfBill", true);
                this.$bvModal.show(this.modalIdTypeofBill);
           }

           if(!this.defaultValues.useBillingEntity && e.target.innerText != '837ANPI501'){
                this.CreateFile("");
           }*/
            if(this.defaultValues.useBillingEntity){
            if(this.TemplateModel.code == '837ANPI501'){
                this.templateA = true;
                EventBus.$emit("onSelectSeqNo", this.SeqNoSelected);
            }else{
                this.templateA = false;
            }
                EventBus.$emit("onGetProviders", true);
                this.$bvModal.show(this.modalIdProviderSelector);
           }
           if(!this.defaultValues.useBillingEntity && this.InputTypeOfBill && this.TemplateModel.code == '837ANPI501'){
                EventBus.$emit("onSelectSeqNo", this.SeqNoSelected);
                EventBus.$emit("onGetTypeOfBill", true);
                this.$bvModal.show(this.modalIdTypeofBill);
           }

           if(!this.defaultValues.useBillingEntity && this.TemplateModel.code != '837ANPI501'){
                this.CreateFile("");
           }

        },



        RemoveItems(seqNo){
            for( var i = 0; i < this.items.length; i++){     
                if(this.items[i].seqNo == seqNo){ 
                    this.items.splice(i, 1); 
                }    
            }
        },

        RemoveItemsBySeqNo(){            
            var l = this.SeqNoListSelected.length;
            if(this.SendOptionsModel.code == 'First Time'){
                if(l > 0){
                    for(var i = 0; i < l; i++){
                        this.RemoveItems(this.SeqNoListSelected[i]);
                    }
                    this.totalRows = this.items.length;
                    this.SelectedRecords = 0;
                }
            }       
        },



        onChangeSelectAll(v) {
            if (v) {
                this.SelectedRecords = this.items.length;
                for (var i = 0; i < this.items.length; i++) {
                    this.$set(this.items[i], "selected", true);
                }

                EventBus.$emit("rowClickedCreateClearing", true);
            }
            else {
                this.SelectedRecords = 0;
                for (var j = 0; j < this.items.length; j++) {
                    this.$set(this.items[j], "selected", false);
                }
            }
        },
        ViewIcon() {
        },
        RowClicked(item) {
            if (item.selected) {
                this.$set(item, "selected", false);
            }
            else {
                this.$set(item, "selected", true);
            }
            EventBus.$emit("rowClickedCreateClearing", item);
        },
        tbodyRowClass(item) {
            if (item != null) {
                if (item.selected) {
                    return ["b-table-row-selected", "table-active", "cursor-pointer"];
                }
                else {
                    return ["cursor-pointer"];
                }
            }
        },
        Error() {
        },
        Warning() {
        },
        FindinItems() {
            this.items = this.CopyItems;
            var arr = this.items;
            var temp = [];
            if (this.modelSearch) {
                arr.forEach(element => {
                    if (element.patientName && element.patientName.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.patientCode && element.patientCode.toUpperCase().includes(this.modelSearch.toUpperCase())
                        || element.dateFrom && element.dateFrom.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.dateTo && element.dateTo.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
                        /*element.issLineNo && element.issLineNo.toUpperCase().includes(this.modelSearch.toUpperCase()) ||*/ element.cpT_HCPCS && element.cpT_HCPCS == this.modelSearch ||
                        element.cptDescription && element.cptDescription == this.modelSearch || element.modifier_1 && element.modifier_1.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
                        /*element.day_Unit && element.day_Unit.toUpperCase().includes(this.modelSearch.toUpperCase()) || */ element.charges && element.charges.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
                        element.balance && element.balance.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.diagCode && element.diagCode.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
                        element.insuranceCode && element.insuranceCode.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.insuranceCode1 && element.insuranceCode1.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
                        element.insuranceCode2 && element.insuranceCode2.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.doctorCode && element.doctorCode.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
                        element.facilityName && element.facilityName.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.posName && element.posName.toUpperCase().includes(this.modelSearch.toUpperCase())
                    /*||  element.seqNo && element.seqNo.toUpperCase().includes(this.modelSearch.toUpperCase())*/
                    ) {
                        temp.push(element);
                    }
                });
            }
            else {
                temp = this.CopyItems;
            }
            this.items = temp;
            this.totalRows = temp.length;
        },
        Search() {
        },
        ChangeDropDownPrimaryInsurance(pInsurances) {
            var l = pInsurances.length;
            var TemppInsuranceList = [];
            if (l > 0) {
               // var i = 0;
                pInsurances.forEach(function (e) {
                      TemppInsuranceList += "&PrimaryInsurancesCodeList="+e.insuranceCode;
                   /* if (i < l - 1) {
                       // TemppInsuranceList += e.insuranceCode + ",";
                       TemppInsuranceList += "&PrimaryInsurancesCodeList="+e.insuranceCode;
                    }
                    else {
                       // TemppInsuranceList += e.insuranceCode;
                       TemppInsuranceList += "&PrimaryInsurancesCodeList="+e.insuranceCode;
                    }
                    i++;*/
                });
                this.PInsuranceList = TemppInsuranceList;
            }
            else {
                this.PInsuranceList = [];
            }
               // this.PInsuranceList = [];
               /* var TemppInsuranceList = [];
                pInsurances.forEach(function (e) {
                    TemppInsuranceList.push(e.insuranceCode);                 
                });

                 this.PInsuranceList = TemppInsuranceList;*/
        },
        ChangeDropDownPatients(patients) {
            var l = patients.length;
            var TempPatientList = [];
            if (l > 0) {
                //var i = 0;
                patients.forEach(function (e) {
                    TempPatientList += "&PatientCodeList="+e.patientCode;
                    /*if (i < l - 1) {
                        //TempPatientList += e.patientCode + ",";
                        TempPatientList += "&PatientCodeList ="+e.patientCode;
                    }
                    else {
                       // TempPatientList += e.patientCode;
                       TempPatientList += "&PatientCodeList ="+e.patientCode;
                    }
                    i++;*/
                });
                this.PatientCodeList = TempPatientList;
            }
            else {
                this.PatientCodeList = [];
            }
        },
        ChangeDropDownPrimaryInsuranceCategory(pInsurancesCategory) {
            var l = pInsurancesCategory.length;
            var TemppInsuranceCategoryList = [];
            if (l > 0) {
               // var i = 0;
                pInsurancesCategory.forEach(function (e) {
                    TemppInsuranceCategoryList += "&PrimaryInsurancesCategoriesCodeList="+e.insuranceCode;
                   /* if (i < l - 1) {
                        TemppInsuranceCategoryList += e.insuranceCode + ",";
                    }
                    else {
                        TemppInsuranceCategoryList += e.insuranceCode;
                    }
                    i++;*/
                });
                this.PInsuranceCategoryList = TemppInsuranceCategoryList;
            }
            else {
                this.PInsuranceCategoryList = [];
            }
        },
        ChangeDropDownFacilities(facilities) {
            var l = facilities.length;
            var TemppfacilitiesList = [];
            if (l > 0) {
                //var i = 0;
                facilities.forEach(function (e) {
                    TemppfacilitiesList += "&FacilityCodeList="+e.facilityCode;
                   /* if (i < l - 1) {
                        TemppfacilitiesList += e.facilityCode + ",";
                    }
                    else {
                        TemppfacilitiesList += e.facilityCode;
                    }
                    i++;*/
                });
                this.FacilityCodeList = TemppfacilitiesList;
            }
            else {
                this.FacilityCodeList = [];
            }
        },
        ChangeDropDownPOS(placeofservices) {
            var l = placeofservices.length;
            var TempPlaceOfServiceList = [];
            if (l > 0) {
                //var i = 0;
                placeofservices.forEach(function (e) {
                    TempPlaceOfServiceList += "&PlaceOfServiceCodeList="+e.posCode;
                   /* if (i < l - 1) {
                        TempPlaceOfServiceList += e.posCode + ",";
                    }
                    else {
                        TempPlaceOfServiceList += e.posCode;
                    }
                    i++;*/
                });
                this.PlaceOfServiceCodeList = TempPlaceOfServiceList;
            }
            else {
                this.PlaceOfServiceCodeList = [];
            }
        },
        ChangeDropDownPrograms(programs) {
            var l = programs.length;
            var TempProgramList = [];
            //var arr = [];
            if (l > 0) {
              //  var i = 0;
                programs.forEach(function (e) {
                     TempProgramList += "&ProgramCodeList="+e.programCode;
                   /* // arr.push(e.code);        
                    if (i < l - 1) {
                        TempProgramList += e.programCode + ",";
                    }
                    else {
                        TempProgramList += e.programCode;
                    }
                    i++;*/
                });
                //this.ProgramCodeListBatch = arr;
                this.ProgramCodeList = TempProgramList;
            }
            else {
                this.ProgramCodeList = [];
            }
        },
        ChangeDropDownTherapist(therapits) {
            var l = therapits.length;
            var TempTherapistList = [];
            if (l > 0) {
                //var i = 0;
                therapits.forEach(function (e) {
                    TempTherapistList += "&TherapistCodeList="+e.therapistCode;
                   /* if (i < l - 1) {
                        TempTherapistList += e.therapistCode + ",";
                    }
                    else {
                        TempTherapistList += e.therapistCode;
                    }
                    i++;*/
                });
                this.TherapistCodeList = TempTherapistList;
            }
            else {
                this.TherapistCodeList = [];
            }
        },
        ChangeDropDownDoctors(doctors) {
            var l = doctors.length;
            var TempDoctorsList = [];
            if (l > 0) {
              //  var i = 0;
                doctors.forEach(function (e) {
                    TempDoctorsList += "&DoctorsCodeList="+e.doctorCode;
                  /*  if (i < l - 1) {
                        TempDoctorsList += e.doctorCode + ",";
                    }
                    else {
                        TempDoctorsList += e.doctorCode;
                    }
                    i++;*/
                });
                this.DoctorsCodeList = TempDoctorsList;
            }
            else {
                this.DoctorsCodeList = [];
            }
        },
        ChangeDropDownCPTs(cpts) {
            var l = cpts.length;
            var TempCptsList = [];
            if (l > 0) {
             //   var i = 0;
                cpts.forEach(function (e) {
                    TempCptsList += "&CPTCodeList="+e.cptCode;
                  /*  if (i < l - 1) {
                        TempCptsList += e.cptCode + ",";
                    }
                    else {
                        TempCptsList += e.cptCode;
                    }
                    i++;*/
                });
                this.CPTCodeList = TempCptsList;
            }
            else {
                this.CPTCodeList = [];
            }
        },
        ChangeDropDownType(InsuranceType) {
            this.TypeModel = InsuranceType;
        },
        ChangeDropDownPriorBatches(priorbatches) {
            var l = priorbatches.length;
            var TemppriorbatchesList = [];
            if (l > 0) {
               // var i = 0;
                priorbatches.forEach(function (e) {
                    TemppriorbatchesList += "&PreviousBatchRecId="+e.Code;
                   /* if (i < l - 1) {
                        TemppriorbatchesList += e.Code + ",";
                    }
                    else {
                        TemppriorbatchesList += e.Code;
                    }
                    i++;*/
                });
                this.PreviousBatchRecId  = TemppriorbatchesList;
            }
            else {
                this.PreviousBatchRecId  = [];
            }
        },
        ChangeDropDownTemplate(template) {
            if(template){
                this.TemplateModel = template;
            }else{
               this.TemplateModel = {"code":"","name":""}; 
            }
            
        },
        ChangeDropDownSendOptions(sendoption) {
            this.SendOptionsModel = sendoption;
        },
        SearchinFilterList() {
        },
        PerPageFunction(page) {
            this.perPage = page;
        },
        pageChanged() {
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        onRowSelected() {
            this.SelectedRecords = 0;
            
            if (this.items.length > 0) {
                var l = this.items.length;
                for (var i = 0; i < l; i++) {
                    if (this.items[i].selected) {
                        this.SelectedRecords++;                
                    }
                }
            }
        },

        onDBLCLick() {
        },
        Accordion1Click() {
            this.ShowFilter = !this.ShowFilter;
            this.ShowNote = !this.ShowNote;
        },
        ClickBtnAccordion2() {
            this.ShowFilter = !this.ShowFilter;
            this.ShowNote = !this.ShowNote;
        },
        onSelectRow(index) {
            console.log(index);
        },
        formatterDate(value) {
            return Utils.formatterDateTimeToString(value);
        },

   

        getPrimaryInsurancesFilter() {
            ClaimFileCreations.getPrimaryInsurances()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.PrimaryInsuranceList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.PIFilter'), error, "error");
                }
            });
        },
        getInsurancesCategoryFilter() {
            ClaimFileCreations.getInsurancesCategory()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.PrimaryInsuranceCategoryList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.ICFilter'), error, "error");
                }
            });
        },
        getPatientFilter() {
            ClaimFileCreations.getPatientFilter()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.PatientsList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.PFilter'), error, "error");
                }
            });
        },
        getProgramFilter() {
            ClaimFileCreations.getProgramFilter()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.ProgramList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.PFilter'), error, "error");
                }
            });
        },
        getDoctorFilter() {
            ClaimFileCreations.getDoctorFilter()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.DoctorsList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.DFilter'), error, "error");
                }
            });
        },
        getTherapistFilter() {
            ClaimFileCreations.getTherapistFilter()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.TherapistList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.TFilter'), error, "error");
                }
            });
        },
        getFacilityFilter() {
            ClaimFileCreations.getFacilityFilter()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.FacilitiesList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.FFilter'), error, "error");
                }
            });
        },
        getCPTFilter() {
            ClaimFileCreations.getCPTFilter()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.CPTsList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.CPTFilter'), error, "error");
                }
            });
        },
        getPOSFilter() {
            ClaimFileCreations.getPOSFilter()
                .then((response) => {
                if (response.data && response.data.length > 0) {
                    this.POSList = response.data;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.POSFilter'), error, "error");
                }
            });
        },
        getDefaultTemplateClearingHouseFilter() {
            ClaimFileCreations.getDefaultTemplateClearingHouse()
                .then((response) => {
                if (response.data) {
                    this.defaultValues = response.data;
                    if(this.defaultValues.templateCode){
                        this.TemplateModel.code = this.defaultValues.templateCode;
                        this.TemplateModel.name = this.defaultValues.templateCode;
                    }
                    
                   // this.TemplateModel = { "code": "837ANPI501", "name": "837ANPI501" };
                    /*this.$set(this.TemplateModel, 'code', "837ANPI501");
                    this.$set(this.TemplateModel, 'name', "837ANPI501");*/
                    this.InputHideZeroBalance = this.defaultValues.hideZeroBalance;
                    this.InputUseLineServiceItems = this.defaultValues.useLineServiceItems;
                    this.InputTypeOfBill = this.defaultValues.inputTypeOfBill;
                    this.InputTypeOfBillShowInModal = this.InputTypeOfBill;
                    this.InputSetPrintFlag = this.defaultValues.setPrintFlag;
                    this.InputEcsFlag = this.defaultValues.ecsFlag;
                    this.InputIsSolo = this.defaultValues.isSolo;
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('HouseFile.DFTFilter'), error, "error");
                }
            });
        },
        validate_required() {           
            this.isSendOptionsValid = this.SendOptionsModel ? true : false;
            this.isInsuranceTypeValid = this.TypeModel ? true : false;   
            this.isTypeValid = this.TemplateModel.code ? true :false;     
            return this.isSendOptionsValid && this.isInsuranceTypeValid && this.isTypeValid;
        },
        getAllSearch() {
            this.$emit("load", true);
            var parameter = {
                "DateFrom": this.DateFrom,
                "DateTo": this.DateTo,
                "PrimaryInsurancesCodeList": this.PInsuranceList,
                "PrimaryInsurancesCategoriesCodeList": this.PInsuranceCategoryList,
                "PatientCodeList": this.PatientCodeList,
                "InsuranceType":this.TypeModel.code,
                "DoctorsCodeList": this.DoctorsCodeList,
                "CPTCodeList": this.CPTCodeList,
                "TherapistCodeList": this.TherapistCodeList,
                "ProgramCodeList": this.ProgramCodeList,
                "FacilityCodeList": this.FacilityCodeList,
                "PlaceOfServiceCodeList": this.PlaceOfServiceCodeList,
                "PreviousBatchRecId":this.PreviousBatchRecId,
                "SendOptions": this.SendOptionsModel.code,
                "Template": this.TemplateModel.code,
                "ShowUserDefinedCPT": this.ShowUserDefinedCPTs,
                "InputTypeOfBill": this.InputTypeOfBill,
                "UseLineServiceItems": this.InputUseLineServiceItems,
                "OrderByLineNo": this.InputOrderByLineNo,
                "HideZeroBalance": this.InputHideZeroBalance,
                "IsSolo": this.InputIsSolo,
                "SetPrintFlag": this.InputSetPrintFlag,
                "EscFlag": this.InputEcsFlag,
                "UseTherapyProvider": this.InputUseTherapyProvider,
            };
            if (this.TypeModel)
                parameter.InsuranceType = this.TypeModel.code;
            ClaimFileCreations.getAll(parameter)
                .then((response) => {
                if (response.status == "200" && response.data) {
                    this.items = response.data;
                    this.totalRows = this.items.length;
                    this.CopyItems = response.data;
                    if (this.items.length === 0) {
                        this.ShowMessageIsEmpty = true;
                    }
                    this.items.forEach(function (element) {
                        element.selected = false;
                        element.charges = element.charges.toFixed(2);
                        element.balance = element.balance.toFixed(2);
                        if (element.dateFrom)
                            element.dateFrom = Utils.formatterDateToString(element.dateFrom);
                        if (element.dateTo)
                            element.dateTo = Utils.formatterDateToString(element.dateTo);
                    });
                    this.$emit("load", false);
                }
            })
                .catch((error) => {
                this.$emit("load", false);
               /* if (error.response) {
                    this.showMessage("Search Result", error, "error");
                }*/
                     if (!error.response) {
                      this.showMessage(this.$t('Shared.SearchResult'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                      this.showMessage(this.$t('Shared.SearchResult'),error,"error");
                    }
            });
        },
        DoSearch() {
            this.items = [];
            /*this.tablist = [
                { title: 'General', link: '#general', active: true},
                { title: 'Additional', link: '#additional'},
                { title: 'File Options', link: '#fileoptions'},
            ];*/
            this.VisibleFilesCreated = false;
            if (this.DateFrom && Utils.IsValidDateAndRange(this.formatterDate(this.DateFrom)) && this.DateTo && Utils.IsValidDateAndRange(this.formatterDate(this.DateTo))) {
                if (this.DateFrom > this.DateTo) {
                    this.showMessage(this.$t('HouseFile.HouseFileTitle'), this.$t('HouseFile.DateRangeMessage'), "error");
                    return;
                }
            }
            if (this.validate_required()) {
                this.SelectedRecords = 0;
                this.UseSelectTableAll = false;
                this.getAllSearch();
                //this.$refs["bbuttonResult"].click();
            }
            else {
                this.showMessage(this.$t('HouseFile.HouseFileTitle'),this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
            }
        },

        onCodeConditionOk(result)
        {
            this.PriorBatchesModel = String(result.code)
            this.PriorBatchesModelDescription= result.description
            this.PreviousBatchRecId  = String(result.code)
        }

       /* ContainSeqNo(arr,sn){
            var result = false;
            var l = arr.length;

            for(var i = 0; i < l; i++){
                if(arr[i])
            }
        },*/
        /*Search(){
        
          if(this.DateFrom && !Utils.IsValidDateAndRange(this.formatterDate(this.DateFrom))){
            this.showMessage("Claim Batch","Date From Invalid","error");
            return;
          }
          if(this.DateTo && !Utils.IsValidDateAndRange(this.formatterDate(this.DateTo))){
            this.showMessage("Claim Batch","Date To Invalid","error");
             return;
          }
          if(this.DateFrom && Utils.IsValidDateAndRange(this.formatterDate(this.DateFrom)) && this.DateTo && Utils.IsValidDateAndRange(this.formatterDate(this.DateTo))){
            if(this.DateFrom > this.DateTo){
               this.showMessage("Claim Batch","Date From cannot be greater than Date To","error");
                return;
            }
          }
    
          if(this.DateFrom !== "" && this.DateTo === ""){
             this.showMessage("Claim Batch","Date To cannot be empty","error");
              return;
          }
    
          if(this.DateTo !== "" && this.DateFrom === ""){
             this.showMessage("Claim Batch","Date From cannot be empty","error");
              return;
          }
          this.SelectedRecords = 0;
          this.getAll();
        },*/
    },
    created() {
        this.getPermissionLeftMenu(this.Menu, this.$route.path, this.permission);
        this.CopyItems = this.items;
        this.OriginalSaved = this.CopyItems;       
        this.getPrimaryInsurancesFilter();
        this.getInsurancesCategoryFilter();
        this.getPatientFilter();
        this.getProgramFilter();
        this.getDoctorFilter();
        this.getTherapistFilter();
        this.getFacilityFilter();
        this.getCPTFilter();
        this.getPOSFilter();
        this.getDefaultTemplateClearingHouseFilter();

        EventBus.$on("onCreateFile", function (pCode,PatientCodeList,TypeOfBillList) {   
            this.patientCodeList = PatientCodeList;
            this.typeOfBillList = TypeOfBillList;     
            this.CreateFile(pCode);
        }.bind(this));
        
        EventBus.$on("rowClickedCreateClearing", function () {
            this.SeqNoListSelected = [];
            this.SelectedRecords = 0;
            var l = this.items.length;
            for (var i = 0; i < l; i++) {
                if (this.items[i].selected) {
                    this.SelectedRecords++;
                    if(!this.SeqNoListSelected.includes(this.items[i].seqNo)){
                         this.SeqNoListSelected.push(this.items[i].seqNo);
                    }
                }
            }
            this.SeqNoSelected = this.SeqNoListSelected.toString();
        }.bind(this));
    },
    mounted() {       
        this.OptionsRightMenu.ItemsList = [];
        let layoutStatus = this.$store.state.layoutStatus;
        switch (layoutStatus) {
            case "L0R1":
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit("getVisibleMenu", this.OptionsRightMenu);
                break;
            case "L1R0":
            case "L1R1":
                this.OptionsRightMenu.btnvisible = false;
                this.$emit("getVisibleMenu", this.OptionsRightMenu);
                break;
            default:
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit("getVisibleMenu", this.OptionsRightMenu);
        }
        /*if(this.permission.length == 0){
          this.$router.push({name: "Main"});
            setTimeout(() =>
              this.showPermissionMessage()
            , 50);
          this.$emit('load', false);
        }*/
    },
    destroyed() {
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false;
        this.$emit("getVisibleMenu", this.OptionsRightMenu);
    },   
};
</script>


