<template>
  <div>
    <div class="row">
      <div class="col-12">
        <MdTitleRegInfo
          v-bind:RegistrationInfo="PatientTitle" 
          v-bind:titlePrefix="$t('InsurancesList.PatientInsurance')" 
          v-bind:IsClinical="IsClinical"
          v-bind:titleRef="'titleElementPatientInsuranceList'"
          v-bind:patientCode="this.$route.params.id">
        </MdTitleRegInfo>
       
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <MdButton 
                v-bind:Label="$t('InsurancesList.AddInsurance')"
                v-bind:Name="'btnaddinsurance'"                
                v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                v-bind:IconClass="'ti-plus pr-1'"
                v-on:click="AddInsurance"
                v-bind:isReadOnly=this.isReadOnly>
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-6 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control"
                  v-model="parameter.SearchCriteria"
                  @keyup.enter="SearchInsurance"
                />
                <div class="input-group-append">
                  <MdButton 
                    v-bind:Name="'btnsearchinsurance'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'"
                    v-on:click="SearchInsurance">
                  </MdButton>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-3">  
            <MdSelect
              v-bind:Label="$t('Shared.Type')"
              v-bind:Options="InsuranceTypesList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="ModelInsuranceType"
              v-bind:Multiple="false"              
              :Input="ChangeDropDownInsType"
              />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div class="row">
          <div class="col-md-12">
            <div>
              <b-col lg="6" class="my-1"> </b-col>

              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="isReadOnly"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditInsurance"
                v-bind:VisibleEditIcon="true"
                v-bind:FieldsDate="FieldsDateArr"
                :deleteIconEvent="RemoveInsurance"
                v-bind:VisibleDeleteIcon="true"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction"
                :otherIcon="'printer-fill'"
                :otherIconEvent="showReport">
              </MdTable>
            </div>
          </div>
        </div>
      </div>
      <div class="space-actions"></div>
    <div class="page-actions">
      <MdButton
        v-bind:isReadOnly="items.length == 0"
        v-bind:Label="$t('Shared.Report')"
        v-bind:Name="'btnpatientlist'"
        v-bind:ClassProp="'secondary mx-2 btn-login'"
        v-on:click="showReport()"/>
    </div>
    <PatientInsurancesRep v-if="isReportVisible" 
      :closeWindow="closeParamRepWindow"
      :PatientCode="this.$route.params.id"
      :RecNo="recNoToReport"
    />
    </div>
  </div>
</template>

<script>
import EnumService from "@/services/enum.service";
import InsuranceService from "@/services/insurance.service";
import ShowMessage from "@/components/messages/ShowMessage.js";
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPIn = await vm.$store.state.isUsePatientInsurance;
            if(iPIn) next()
            else next('/accessdenied');
        }) 
  },

  name: "Insurance",
  mixins: [ShowMessage, DBFunctions],
  
  data() {
    return {
      patient:{},
      RegistrationInfo:{},
      IsClinical:false,
      InsuranceTypesList:[],
      FieldsDateArr:['effectiveDate'],
      ModelInsuranceType:{'code':'','description':''},
      InsuranceTypeCode:'',
      ShowMessageIsEmpty: false,
      Loading: true,
      OptionsRightMenu: {
        visible: true,
        btnvisible:true,
        ItemsList: [],
      },
       PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     },
      PatientId: "",
      PatientCode1: "",
      InsuranceCode: "",
      Mode: "",
      parameter: {
        SearchCriteria: "",
        PageNumber: 0,
        PageSize: 0,
        PatientCode: "",
      },
      fields: [
        { key: "insuranceTypeDescription", sortable: true, label: this.$t('InsurancesList.colInsuranceType'), class: "text-rigth" },
        { key: "insuranceName", sortable: true, label: this.$t('InsurancesList.colInsuranceName'), class: "text-rigth" },
        { key: "insuranceCode", sortable: true, label: this.$t('Insurance.InsuranceCode') },
        { key: "effectiveDate", sortable: true, label: this.$t('InsurancesList.colEffectiveDate'), class: "text-rigth" },
        { key: "coPay", sortable: true, label: this.$t('Insurance.CoPay'), class: "text-rigth" },
        { key: "policyNo", sortable: true, label: this.$t('Insurance.PolicyNo'), class: "text-rigth" },
      ],
      pageOptions: [5, 10, 15],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header: {},
      isReadOnly: false,
      isReportVisible: false,
      recNoToReport: null,
    };
  },

  computed: {
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  methods: {

    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
        this.PatientTitle.programName = '';
        this.PatientTitle.admitDate = '';
        this.PatientTitle.dischargeDate = '';
        
        const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

        if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
        if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
        if (age) this.PatientTitle.age = age;
        if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
          
      }
    },
    SearchInsurance() {
      this.Loading = true;
      this.$emit("load", this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getInsurance();
    },

    closeParamRepWindow() {
      this.isReportVisible = false
      this.recNoToReport = null
    },

    OnUpdateInsuranceList() {
      this.getInsurance();
    },
    ChangeDropDownStatus(value){
       this.ModelStatus = value;
       this.items = [];
       this.getInsurance();
    },

    ChangeDropDownInsType(value){
      if(value !== null){
        this.ModelInsuranceType = value;
        this.InsuranceTypeCode = value.code
      }else{
        this.InsuranceTypeCode = '';
      }

      this.items = [];
      this.getInsurance();
    },

    getInsurance() {
      this.parameter.PageNumber = this.currentPage;
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.$route.params.id;
      this.parameter.Type = this.InsuranceTypeCode;
      
      InsuranceService.getAllPatientInsurances(this.parameter)
        .then((response) => {
          if (response.status == "200" && response.data && response.data.length > 0) {
            this.items = response.data;
            this.items.forEach(function (element) {
              if (element.effectiveDate)
                element.effectiveDate = Utils.formatterDateToString(element.effectiveDate); 
            });
            this.Loading = false;
            this.$emit("load", this.Loading);
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
          }
          this.Loading = false;
          this.$emit("load", this.Loading);
          if (this.items.length === 0) {
              this.ShowMessageIsEmpty = true;
          }
        })
        .catch((error) => {
          this.Loading = false;
          this.$emit("load", this.Loading);
          if (!error.response) {
            // network error
            this.showMessage(
              this.$tc('Insurance.Insurance'),
              this.$t('Msg.retrieveInfo'),
              "error"
            );
          } else {
            this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
          }
        });
    },

    getInsuranceTypesList() {
      InsuranceService.getPatientInsuranceTypes()
        .then((response) => {
          if (response.data) {
            this.InsuranceTypesList = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
          }
        });
    },

    AddInsurance() {      
      if(this.$route.name.includes('patient')){
         this.$router.push({ name: "add-patient-insurance", params: { idPatient: this.$route.params.id } });       
      }
      else if(this.$route.name.includes('appointments-Insurance')){
         this.$router.push({ name: "add-appointments-insurance", params: { idPatient: this.$route.params.id, actId: this.$route.params.actId } });       
      }
      else {            
         this.$router.push({ name: "add-Clinical-insurance", params: { idPatient: this.$route.params.id,recordid:this.$route.params.recordid } });
      }
      this.$emit('getVisibleMenu', this.OptionsRightMenu);  
    },

    EditInsurance(row) {

        /*this.$router.push({ name: "patient-insurance-details", params: { idPatient: this.$route.params.id, idInsurance: row.recId } });
        this.OptionsRightMenu.btnvisible = true;
        this.$emit('getVisibleMenu', this.OptionsRightMenu); */
        
      if(this.$route.name.includes('patient')){
          this.$router.push({ name: "patient-insurance-details", params: { idPatient: this.$route.params.id, idInsurance: row.recId } });
      }
      else if(this.$route.name.includes('appointments-Insurance')){
          this.$router.push({ name: "appointments-insurance-details", params: { idPatient: this.$route.params.id, idInsurance: row.recId, actId: this.$route.params.actId } });
      }
      else {            
         this.$router.push({ name: "Clinical-insurance-details", params: { idPatient: this.$route.params.id,idInsurance: row.recId,recordid:this.$route.params.recordid } });
      }
    },

    RemoveInsurance(row) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
          title: this.$t('InsurancesList.DeleteInsurance'),
          okTitle: this.$t('Shared.TitleDelete'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
          id: "modal_delete_insurance"
        })
        .then((value) => {
          if (value) {
            InsuranceService.deletePatientInsurances(row.recId)
              .then(() => {
                const index = this.items.findIndex(item => item.recId === row.recId) // find the post index 
                if (~index) {// if the post exists in array 
                  this.items.splice(index, 1) //delete the post and refresh view
                  this.getInsurance(); //refresh listing
                }
              })
              .catch((error) => {
                if (!error.response) {
                  // network error
                  this.showMessage(
                    this.$tc('Insurance.Insurance'),
                    this.$t('Msg.NotAbleToDelete'),
                    "error"
                  );
                } else {
                  this.showMessage(this.$tc('Insurance.Insurance'), error, "error");
                }
              });
          }
        });
    },

    onRowSelected(items) {
      this.selected = items;
    },

    pageChanged(page) {
      this.currentPage = page;
    },

    PerPageFunction(perpage) {
      this.perPage = perpage;
      this.currentPage = 1;
      this.getInsurance();
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2);
    },

    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    showReport(item) {
      if(this.items.length > 0){
        if (item && item.recId) {
          this.recNoToReport = item.recId
        }
        this.isReportVisible = true
      } else {
        setTimeout(() => 
          this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
            title:  this.$tc('Insurance.Insurance'),
            autoHideDelay: 3000,
            variant: "success"
          }),
        50);
      }
    },
    PopulatePatientTitle(){      
      const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
      if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
      if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	

      if(this.$route.name.includes('Clinical-insurance')){ 
        if (programName) this.PatientTitle.programName = programName; 
        if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
        if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
        if (patientName) this.PatientTitle.fullName = patientName;
        if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
        if (age) this.PatientTitle.age = age === 0 ? "0" : age;
        if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-insurance') || this.$route.name.includes('appointments-Insurance')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    },
  },

 async mounted() {
    this.OptionsRightMenu.visible = true;    
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-insurance') ? EnumService.ClinicalItems : this.$route.name.includes('appointments-insurance') ? EnumService.AppointmentItems : EnumService.PatientItems;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);
    this.getInsuranceTypesList();
    this.$emit("load", this.Loading);
    this.getInsurance(); 
    this.isReadOnly = this.$store.state.isPatientInsuranceEHRReadOnly;
   
    if(this.$route.name.includes('patient-insurance') || this.$route.name.includes('appointments-Insurance')){  
        this.GetPatientByCode();
     } 

    if(this.$route.name.includes('Clinical-insurance')){      
      this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
      /* eslint-disable no-unused-vars */
      const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access
      if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
      this.IsClinical = dateTo ? false : true;
    }
    this.PopulatePatientTitle();
  },

  destroyed() {
    this.OptionsRightMenu.ItemsList = [];
    this.OptionsRightMenu.Mode = false;
    this.OptionsRightMenu.visible = false;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);
  },
};
</script>
