import ApiService from '@/services/api.service'

const AllergyAdverseEventTypeService = {
    getAll() { 
        const requestData = {
            method: 'get',
            url: "/AllergyAdverseEventTypes/",
            headers: {
            },
        }     

        return ApiService.customRequest(requestData);         
    },

    get( id) {
        return ApiService.get(`/AllergyAdverseEventTypes/${id}`);
    },

    add(data){
        return ApiService.post(`/AllergyAdverseEventTypes`,data)
    },

    delete(id){
        return ApiService.delete(`/AllergyAdverseEventTypes/${id}`)
    },

    update(RecordId,AllergyAdverse){
        return ApiService.put(`/AllergyAdverseEventTypes/${RecordId}`,AllergyAdverse)
    }
}

export default AllergyAdverseEventTypeService;