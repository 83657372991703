import ApiService from '@/services/api.service'

const MarsAlertService = {

    getMarsAlertByCode(parameter) {              
        const requestData = {
            method: 'get',
            url: "/PatientMarsAlerts",
            headers: {
            },
            params: { 
                Code:parameter.PatientCode,           
                PageSize: parameter.PageSize,          
                PageNumber : parameter.PageNumber,
            }
        }     
        return ApiService.customRequest(requestData);         
    },

    Add(data){
        return ApiService.post(`/PatientMarsAlerts`, data);
    },
    
    Update(Code, data){
        return ApiService.put(`/PatientMarsAlerts/${Code}`,data)
    }
}

export default MarsAlertService;