<template>
    <div :class="UseColumnDynamic ? Column : 'col-md-4'">
        <b-modal
            :no-close-on-backdrop="true" 
            scrollable 
            centered 
            size="xl"  
            @cancel="this.emitEventCancel" 
            :id="ModalIdAddMarksAlert" 
            :title="this.$t('MdPatientMarsAlert.AddMarsAlert')"  
            :ok-title="$t('Shared.Save')" 
            @ok="handleOk" 
            style="padding:none!important" 
            :cancel-title="$t('Shared.BtnCancel')"
            :ok-disabled="isReadOnly"
        >
            <b-row>
                <b-col md="12" lg="12">
                    <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
                        <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                        <div class="flex-grow-1">
                            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <MdTitle v-bind:Text="$t('MdPatientMarsAlert.PatientMarsAlert')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <b-col md="12" lg="12">
                <MdMemo
                    v-bind:Name="'marsalertmedtextarea_description'"
                    v-bind:Rows="'10'"
                    v-bind:MaxRows="'10'"           
                    v-model="MarsAlertDescription"
                    v-bind:isReadOnly="isReadOnly"
                    v-bind:NoValidateChanges="NoValidateChanges"
                />                                     
            </b-col>
        </b-modal>

        <div class="card activeCard">
            <div class="card-body">
                <div v-if="isUsePatientMarsAlert" class="d-flex align-items-start justify-content-between pointer">
                    <h6 v-on:click="GoToPatientMarsAlert" class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning mr-1"></i>{{ $t('MdPatientMarsAlert.PatientMarsAlert') }}</h6>
                    <i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="GoToPatientMarsAlert" ></i>
                </div>
                <div v-if="!isUsePatientMarsAlert" class="d-flex align-items-start justify-content-between">
                        <h6 class="card-title flex-grow lower-line text-truncate"><i class="mdi mdi-alert text-warning"></i>{{ $t('MdPatientMarsAlert.PatientMarsAlert') }}</h6>
                </div>
                <div :class="NoUseClass ? 'text-muted cutescroll': UseFixedMiddleClass ? 'text-muted p-middle-fixed cutescroll' : 'text-muted p-fixed cutescroll'">
                    <p v-if="isNullOrEmpty()">{{ $t('MdPatientMarsAlert.NoKnownMarsAlert') }}</p>
                    <ul v-if="!isNullOrEmpty()">							
                        <li v-for="(it, index) in getNotEmptyMarsAlert()" :key="`it.name-${index}`">{{it.description}}</li>						
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import MarsAlertService from '@/services/marsalert.service'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';

export default {
    props: {
        PatientCode: String,
        UseColumnDynamic: Boolean,
        Column: String,
        NoUseClass:Boolean,
        Description:String,      
        ModalIdAddMarksAlert:String,	
        UseFixedMiddleClass:Boolean,
        NoValidateChanges: Boolean,

        VisibleMarsAlert:Boolean,
        HideViewMarsAlert:Boolean,
    },

    mixins: [DBFunctions],

    data() {
        return {
            MarsAlertDescription:"",
            MarsAlert:[],
            isUsePatientMarsAlert: this.$store.state.isUsePatientMarsAlert,
            isReadOnly: false,
        }
    },

    methods: {
        async getMarsAlert(){
            this.MarsAlert = await this.getPatientMarsAlert(this.PatientCode);  
            this.MarsAlertDescription = this.MarsAlert.length > 0 ? this.MarsAlert[0].description : "";
        },

        handleOk(){
            this.AddMarAlert();
        },

        emitEventCancel(){
            this.$bvModal.hide(this.ModalIdAddMarksAlert);
            this.MarsAlertDescription = "";
        },  

        async closeModal(){
            this.$bvModal.hide(this.ModalIdAddMarksAlert);

            this.MarsAlert = [];
            this.MarsAlertDescription = ""; 
            this.MarsAlert = await this.getPatientMarsAlert(this.PatientCode);      
        },

        AddMarAlert(){
            let param = {
                PatientCode:this.PatientCode,
                Description:this.MarsAlertDescription
            }
            
            if(this.MarsAlert.length === 0){
                MarsAlertService.Add(param)
                    .then((response) => {      
                        if (response.status == '200') {  
                            EventBus.$emit('OnUpdateMarsAlerts', param.Description); 
                            setTimeout(() => 
                                this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                    title: this.$t('Msg.Saving'),
                                    autoHideDelay: 5000,
                                    variant: "success"
                                })
                            , 50); 
                            this.getMarsAlert();
                        }  
                    })
                    .catch(error => {
                        if (!error.response) {            
                            this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),this.$t('Msg.RetrieveInformation'),"error");
                        } else {
                            this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),error,"error");
                        }
                    }); 
            } else {
                MarsAlertService.Update(this.MarsAlert[0].patientCode ,param)
                        .then((response) => {      
                            if (response.status == '200') {  
                                EventBus.$emit('OnUpdateMarsAlerts', param.Description); 
                                setTimeout(() => 
                                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                        title: this.$t('Msg.Saving'),
                                        autoHideDelay: 5000,
                                        variant: "success"
                                    })
                                , 50); 
                                this.getMarsAlert();
                            }  
                        })
                        .catch(error => {
                            if (!error.response) {            
                                this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),this.$t('Msg.RetrieveInformation'),"error");
                            } else {
                                this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),error,"error");
                            }
                        }); 
            }
        },

        GoToPatientMarsAlert(){	
            if(!this.VisibleMarsAlert){               
                if(this.$route.name.includes('patient')){
                    this.$router.push({ name: "patient-mars-alerts", params: { id: this.PatientCode } }).catch(()=>{});
                }
                if(this.$route.name.includes('Clinical')){
                    this.$router.push({ name: "Clinical-mars-alerts", params: { id: this.PatientCode,recordid:this.$route.params.recordid } }).catch(()=>{});
                }
                if(this.$route.name.includes('appointment')){
                    this.$router.push({ name: "appointments-mars-alerts", params: { id: this.PatientCode,recordid:this.$route.params.recordid } }).catch(()=>{});
                }
            } else {         
                this.$bvModal.show(this.ModalIdAddMarksAlert);
                this.getMarsAlert();
            }
        },

        isNullOrEmpty() {
            return this.getNotEmptyMarsAlert().length === 0;
        },

        getNotEmptyMarsAlert() {
            return this.MarsAlert.filter(x => x.description && x.description !== "");
        },
    },

    created(){
        EventBus.$on("ModalIdAddMarksAlert", function () {        
            this.closeModal(); 		
        }.bind(this));
    },

    mounted() {
        this.isReadOnly = this.$store.state.isPatientMarsAlertEHRReadOnly; 
        this.getMarsAlert();
        this.$root.$on('bv::modal::hide', () => {   
            this.MarsAlertDescription = ""; 
        }); 
    },

    beforeDestroy () {     
        EventBus.$off('ModalIdAddMarksAlert');        
    },
}
</script>