<template>
<div>
  <b-modal :id="ModalId" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :title="TitleWindow" :visible="!NotOpenByDefault ? true : false" :ok-disabled="isEditMode" @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')">
   <div v-if="isListing" id="ContainerPatientAllergies">
      <div class="row mb-2">
        <div class="card" style="width:100%;">
            <div class="card-body">
                <MdTitle v-bind:Text="$t('Allergy.PatientAllergies')+' - ' + this.PatientInfo.firstName +' '+ this.PatientInfo.lastName + ' - ' + this.PatientCode" />  
                <div class="row">
                    <div class="col-sm-12 col-md-3">                      
                        <MdButton
                          v-bind:Label="$t('AllergiesList.AddAllergy')" 
                          v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                          v-bind:IconClass="'ti-plus pr-1'"
                          v-on:click="AddAllergy"
                          v-bind:isReadOnly="this.isReadOnly || !this.disableAddAllergies">
                        </MdButton>
                    </div>
                    <div class="col-sm-12 col-md-9 mb-4">
                        <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                            <div class="input-group">
                                <input 
                                  type="text" 
                                  class="form-control"
                                  v-model="parameter.SearchCriteria"   
                                   @keyup.enter="SearchAllergies"                            
                                >
                                <div class="input-group-append">
                                    <div class="input-group-append">
                                    <MdButton 
                                      v-bind:Name="'btnsearchinput'"
                                      v-bind:VariantProp="'btn btn-sm search-attached'"
                                      v-bind:IconClass="'mdi mdi-magnify'"
                                      v-on:click="SearchAllergies">
                                    </MdButton>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>              
            </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="card" style="width:100%;">
            <div class="card-body" style="margin-top:-30px !important;">
                <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <div class="row">
                    <div class="col-md-12">
                     <div>
                       <b-col lg="6" class="my-1">
      </b-col>
        <MdTable 
          v-bind:fields="tablefields"
          v-bind:items="items"
          v-bind:totalRows="totalRows"
          v-bind:isReadOnly="isReadOnly"
          v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
          v-bind:currentPage="currentPage"
          v-bind:perPage="perPage"
          v-bind:pageOptions="pageOptions"
          :editIconEvent="EditAllergy"
          v-bind:VisibleEditIcon="true"
          :deleteIconEvent="RemoveAllergy"
          v-bind:VisibleDeleteIcon="true"
          :onRowSelected="onRowSelected"
          :onFiltered="onFiltered"
          :pageChanged="pageChanged"
          :perPageFunction="PerPageFunction">
        </MdTable>
     </div>
    </div>
   </div>
  </div>
 </div>
</div>
</div>
   
   <div v-show="isEditMode" id="PatientAllergies">
     <div id="ContainerAddAllergy">
  <div class="card" style="width:100%;">
   <div class="card-body">
       <MdTitle 
         v-bind:Text="$t('Allergy.EditPatientAllergy')+' - '  + this.PatientInfo.firstName +' '+ this.PatientInfo.lastName + ' - ' + this.PatientCode"
         v-bind:isHide="!this.Mode"
         v-bind:Name="'IdPatient'"
       />
       <MdTitle 
         v-bind:Text="$t('Allergy.AddPatientAllergy')+' - ' + this.PatientInfo.firstName +' '+ this.PatientInfo.lastName + ' - ' + this.PatientCode"
         v-bind:isHide="this.Mode"
       />
      <b-row>
         <b-col md="3" lg="3" sm="12">
              <MdCheckBox
                   v-bind:Label="$t('Allergy.IsActive')"
                   v-bind:Name="'checkbox-isActive'"
                   v-bind:ClassProp="'AlignCheck'"
                   v-bind:CheckedValue="true"
                   v-bind:UncheckedValue="false"
                   v-model="Allergy.isActive"
                   v-bind:ignoreChangedEmit="NoValidateChanges"
              />
         </b-col>
      </b-row>
      <b-row>
         <b-col lg="4" md="4" sm="12">
              <MdLookup
                  v-bind:Label="$t('Allergy.Allergy')"
                  v-bind:ModelCode="Allergy.allergyCode"
                  v-bind:ModelDescription="AllergyDescription.description"
                  v-bind:RefCode="'AllergyCode'"
                  v-bind:Api="'AllergySetUps'"
                  v-bind:FieldCode="'code'"
                  v-bind:FieldDescription="'description'" 
                  v-bind:Fields="fields"
                  :SearchTitle="$t('Allergy.AllergySetUp')"
                  :GetValues="onCodeDescriptionOk"
                  :InvalidFeedback="'Invalid Allergy'"
                  :SearchFieldCode="'Code'"
                  v-bind:isValid="isAllergyValid"
                  v-bind:isRequired="true"
                  v-bind:NoValidateChanges="NoValidateChanges" 
              />
         </b-col>
         <b-col lg="4" md="4" sm="12">
              <MdEdit 
                  v-bind:Label="$t('Allergy.AllergyToDrug')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'drugName'"
                  v-bind:isReadOnly="true"
                  v-model="AllergyDrugName.description"
                  v-bind:Id="'input_alltodrug_id'"
                  v-bind:NoValidateChanges="NoValidateChanges" >                                     
              </MdEdit>
         </b-col>
         <b-col lg="4" md="4" sm="12">
              <MdEdit 
                  v-bind:Label="$t('Allergy.ProblemDescription')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'problem'"
                  v-bind:isReadOnly="true"
                  v-bind:Id="'input_problem_id'"
                  v-bind:NoValidateChanges="NoValidateChanges" >
              </MdEdit>
         </b-col>
      </b-row>
      <b-row>
         <b-col lg="4" md="4" sm="12">
              <MdSelect
                  v-bind:Label="$t('Allergy.Severity')"
                  data-field="Additional_EducationStatus"
                  v-bind:Options="AllergySeverity"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="Allergy.problemSeverityId"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownAllergySeverity"
                  v-bind:NoValidateChanges="NoValidateChanges"
              />
         </b-col>
         <b-col lg="4" md="4" sm="12">
              <MdEdit 
                  v-bind:Label="$t('Shared.datefrom')"
                  v-bind:TypeProp="'date'" 
                  v-bind:Name="'datefrom'"
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"
                  v-model="Allergy.sysDate"
                  v-bind:NoValidateChanges="NoValidateChanges">                                     
              </MdEdit>
         </b-col>
         <b-col lg="4" md="4" sm="12">
              <MdEdit 
                  v-bind:Label="$t('Shared.dateTo')"
                  v-bind:TypeProp="'date'" 
                  v-bind:Name="'dateto'"
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"
                  v-model="Allergy.dateTo"
                  v-bind:NoValidateChanges="NoValidateChanges">                                     
              </MdEdit>
         </b-col>
      </b-row>
      <b-row>
         <b-col lg="12" md="12" sm="12">
               <MdMemo
                 v-bind:Name="'textarea_notes'"
                 v-bind:Rows="'3'"
                 v-bind:MaxRows="'6'"
                 v-bind:MaxLength="'500'"
                 v-model="Allergy.notes"
                 v-bind:NoValidateChanges="NoValidateChanges"
               />                                     
            </b-col>
      </b-row>
      <b-row class="mt-2">
         <b-col md="12">
           <div class="text-center text-md-right mt-3 container-button">
               <MdButton
                  v-bind:Label="$t('Allergy.ALLERGYLIST')"
                  v-bind:Name="'btnpatientlist'"
                  v-bind:ClassProp="'secondary mx-2'"
                  v-on:click="DiscardChanges"/>
             <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
                />
           </div>
         </b-col>
      </b-row>
   </div>
  </div>
 </div>
   </div>
  </b-modal>
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import AllergyService from '@/services/allergies.service'
import AllergySetupService from '@/services/allergiesetup.service'
import AllergyProblemSeveritiesService from '@/services/allergiesproblemseverities.service'
import StorageService from '@/services/storage.service'
import Utils from '@/common/utils.js'
import { EventBus } from '@/event-bus.js';

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.$store.state.isUsePatientAllergies;
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  
  name: 'Allergies', 
  mixins: [ShowMessage, DBFunctions],
  props: {
     ModalId: String,
     TitleWindow: String,
     NoValidateChanges: Boolean,
     PatientCode:String, 
     NotOpenByDefault:Boolean,
     ForUsefromMars:Boolean,
     MarsParam:Object   
   },
  data() {
    return {
      PatientInfo:{},
      ShowMessageIsEmpty:false,
      Loading:true,
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },  
      AllergyId:'',   
      Mode:'',         
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      tablefields: [
        { key: 'allergy', sortable: true, label: this.$t('Allergy.Allergy'), class: 'text-rigth' },       
        { key: 'isActive', sortable: true, label: this.$t('Allergy.IsActive'), class: 'text-rigth', formatter: value => {return value ? 'Y' : 'N'} },       
        { key: 'problem', sortable: true, label: this.$t('Allergy.ProblemDescription'), class: 'text-rigth' },      
        { key: 'severity', sortable: true, label: this.$t('Allergy.Severity'), class: 'text-rigth' },
        { key: 'datefrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth' }, 
        { key: 'dateto', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth' },        
        ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      destination:[],
      //vars use in Allergies Add/Edit
      AllergyDescription: { "description": '' },
      AllergyDrugName: { "description": '' },
      AllergySetup: {},
      AllergySeverity: [],   
      Allergy:{
          recId: '',
          patientCode: '',
          allergyCode: '',
          sysDate: null,
          sysUser: '',
          notes: '',
          headerId: '',
          drugName: '',
          isActive: true,
          dateTo: null,
          problemSeverityId: null
      },
      fields: [
         { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth' },         
         { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },
         { key: 'category', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' },        
      ],
      isListing: true,
      isEditMode:false,
      isNewAllergy:true,
      isAllergyValid: true,
      disableAddAllergies: false,
      pendingChanges: false,
      clickOK: false
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      },
  },
    
  methods: {
      emitEventOK(){      
        EventBus.$emit('OnUpdateAllergieOutCome', this.items);
        this.clickOK = true
      },

      emitEventHide(handled){
        if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('Allergy.PatientAllergies'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {

              if(this.ForUsefromMars){
                  let param = {
                     info:this.getTextResult(),
                     MarsParam:this.MarsParam 
                  }
                  this.$emit('getCodeConditionOK', param); 
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';   

                }else{
                  this.$emit('getCodeConditionOK', this.getTextResult());
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';
                }
                
            } else {
                this.$emit('getCancel');
                this.ResetProps();
            }
        }
      },
      
     showListing(show) {
        this.isEditMode = show ? false : true;
        this.isListing = show ? true : false;
      },

      ResetProps(){
        this.parameter.SearchCriteria = '';
        this.showListing(true);
      },

      getTextResult() {
        let textResult = '';
        let allergy = '';
        let active = '';
        let severity = '';
        this.items.map(item => (
            allergy = item.allergy ? item.allergy: '',
            active = item.isActive ? 'ACTIVE': '',
            severity = item.severity ? item.severity: '',
            textResult = textResult + 'Allergy To: '+ allergy + ' ' + this.$t('Shared.Status')+' : ' + active + ' Severity: ' + severity +"\n\n"
        ))
        return textResult
      },

    SearchAllergies(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getAllergies();
    },

    funcDestination(value) {
     this.destination=value;
   },

    getAllergies() { 
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.PatientCode;
      AllergyService.getAll(this.parameter)
      .then((response) => {
        if(response.status == '200' && response.data && response.data.length > 0){
         this.items = response.data;
         this.items.forEach(function(element) {
						if (element.datefrom)	
              element.datefrom = new Date(element.datefrom).toLocaleDateString();
            if (element.dateto)	
              element.dateto = new Date(element.dateto).toLocaleDateString();
            if (element.isActive)	
              element.isActive = element.isActive ? 'Y' : 'N';   
          });
         let headerString = response.headers["x-pagination"];
         this.Header = JSON.parse(headerString);
         this.totalRows = this.Header.TotalCount;
        }
        if(this.items.length === 0){
           this.ShowMessageIsEmpty = true;
         }
        this.Loading = false;
        this.$emit('load', this.Loading);
      })
      .catch(error => {
        this.Loading = false;
        this.$emit('load', this.Loading);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.RetrieveInformation'),"error");
        } else {
            this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
        }
      });
    },

    EditAllergy(row){
       this.isNewAllergy = false;
       this.mountAllergiesEdit(row.recId); 
       this.showListing(false)
    },

    RemoveAllergy(row){
      const AllergyId = row.recId
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('AllergiesList.DeleteAllergyTitle'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value){
                AllergyService.deleteAllergy(AllergyId)
                .then(() => {
                  const index = this.items.findIndex(item => item.recId === AllergyId) // find the post index 
                  if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view
                    this.getAllergies(); //refresh listing
                    this.showListing(true)
                  }
                })
                  .catch((error) => {
                    if (!error.response) {
                      // network error
                      this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.NotAbleToDelete'),"error");
                    } else {
                      this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
                    }
                  });
            }
          })
    },

    AddAllergy(){
       this.isNewAllergy = true;
       this.mountAllergiesEdit();
       this.showListing(false)
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getAllergies();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.getAllergies();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    //Use in Allergy Add/Edit
     async getPatientInfo(){     
         this.PatientInfo = await this.getData(this.PatientCode);
      },

      DiscardChanges() {
        if(this.pendingChanges){
          this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('Allergy.PatientAllergies'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.pendingChanges = false
              this.getAllergies();
              this.showListing(true)
            }
          })
        } else {
          this.getAllergies();
          this.showListing(true)
        }
      },

      onCodeDescriptionOk(result){
        this.Allergy.allergyCode = result.code;
        this.getLookupFieldsDesc(result.code);
      },

      formatterDate(value){
         return Utils.formatterDate(value);
      },

      getAllergyById(){
         if (this.AllergyId) {
         AllergyService.get( this.AllergyId )
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.Allergy = response.data;
                 this.Allergy.sysDate = this.formatterDate(this.Allergy.sysDate);
                 this.Allergy.dateTo = this.formatterDate(this.Allergy.dateTo);
                 this.getLookupFieldsDesc(response.data.allergyCode);
               }
            })
            .catch((error) => {
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.RetrieveInformation'),"error");
               } else {
                  this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
               }
               });
         }
      },

      getLookupFieldsDesc(Code){
        AllergySetupService.get(Code)
                .then((response) => {
                     if(response.status == '200' && response.data){
                        this.AllergyDescription.description = response.data.description;
                        this.AllergyDrugName.description = response.data.drugName;  
                     }
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
                    }
                });  
      },

      CreateAllergy(){
         this.Allergy.patientCode = this.PatientCode;
         this.Allergy.sysUser = StorageService.getUserId();
         AllergyService.addAllergy(this.Allergy)
         .then(() => {
             this.showMessage(this.$t('Allergy.SavingAllergies'), this.$t('Msg.SavedSuccessfully'), "success");
             this.getAllergies()
             this.showListing(true)
            })
         .catch((error) => {
            if (!error.response) {
               this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.NotAbleToInsert'),"error");
            } else {
               this.showMessage(this.$t('Allergy.PatientAllergies'),error.response.data.errorMessage,"error");
            }
        });
      },

      UpdateAllergy(){
         AllergyService.update(this.AllergyId, this.Allergy)
        .then(() => {
              this.showMessage(this.$t('Allergy.SavingAllergies'),  this.$t('Msg.SavedSuccessfully'), "success");
              this.getAllergies();
              this.showListing(true)
            })
        .catch((error) => {
           if (!error.response) {
               this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.NotAbleToUpdate'),"error");
           } else {
               this.showMessage(this.$t('Allergy.PatientAllergies'),error.response.data.errorMessage,"error");
           }
        });
      },

      SaveOrEdit(){
        if(!this.isReadOnly) {
          if (this.validate_required()) {
            this.pendingChanges = false        
            if (!this.isNewAllergy) { 
               this.UpdateAllergy()           
            }else{            
               this.CreateAllergy()
            }
          } else {
          this.showMessage(this.$t('Allergy.PatientAllergies'), this.$t('Msg.CheckRedLabeledFields') , "errorAutoHide");
          }
        }
      },

      getAllergiesProblemSeverities(){
         AllergyProblemSeveritiesService.getAll()
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.AllergySeverity = response.data;
                 this.getAllergyById();
               }
            })
            .catch((error) => {
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.RetrieveInformation'),"error");
               } else {
                  this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
               }
               });
      },

      ChangeDropDownAllergySeverity(value){
            this.Allergy.problemSeverityId = value ? value.code : '';
        },

      async loadContents() {
          await this.getAllergiesProblemSeverities();
          this.getPatientInfo();
      },

      mountAllergiesEdit(recId) {
        this.$emit('load', this.Loading);
        this.AllergyId =  recId;
        this.loadContents()
      },

      validate_required() {
         this.isAllergyValid = this.Allergy.allergyCode && this.Allergy.allergyCode.length > 0 ? true : false
         return this.isAllergyValid
      },

      beforeWindowUnload(e) {
        if (this.pendingChanges) {
          e.preventDefault()
          e.returnValue = ''	
        }
      }
  },

  mounted() {
    this.getPatientInfo();
    this.$emit('load', this.Loading);    
    this.getAllergies();  
    this.isReadOnly = this.$store.state.isPatientAllergiesEHRReadOnly;
    this.disableAddAllergies = this.$store.state.isUsePatientAllergies
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
};

</script>