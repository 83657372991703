<template>
  <div>
      <b-row>
        <b-col md="12">         
            <b-table
                :ref="refTable"
                selectable
                :small="small"
                :sticky-header="stickyHeader"
                class="medezTables cutescroll"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :current-page="cPage"
                :show-empty="showMessageIsEmpty"
                @row-selected="rowSelected"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"
                :tbody-tr-class="trClassFn" 
                :empty-text="$t('MdTable.nRecShow')"
            >     
                <template v-if="editIconEvent || deleteIconEvent || viewIconEvent || otherIconEvent" #cell(actions)="row">              
                    <b-icon v-if="editIconEvent && isReadOnly" icon="eye-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="editIconEvent && !isReadOnly" icon="pencil-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Delete')"  class="rounded-circle tableActionBTN-danger ml-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="viewIconEvent" icon="play-fill" @click="viewButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.View')" class="rounded-circle tableActionBTN" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="otherIconEvent" :icon="otherIcon" @click="otherButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon> 
                    
                </template>

                <template v-if="ShowDetails" #cell(show_details)="row">
                  <b-form-checkbox class="expandNotes" v-model="row.detailsShowing" @change="row.toggleDetails"></b-form-checkbox>    
                </template>


                <template v-if="ShowDetails" #row-details="row">                          
                  <b-card>                    
                    <b-row>                           
                      <div class="card mb-3 w-100">
                       <b-row class='dateNotes' v-if="row.item.diagnoses.length > 0">
                            <b-col sm="12" md="3">                             
                              <div class="Finalized Note" style="margin-left:32px;">                               
                               {{ $t('Shared.Description') }}
                              </div>
                            </b-col>                         
                             <b-col sm="12" md="3">
                            
                            </b-col>
                             <b-col sm="12" md="3">
                             <div style="margin-left:25px;">
                              {{ $t('Shared.Code') }}             
                             </div>                           
                            </b-col>                                                   
                          </b-row>
                        <div v-for="i in row.item.diagnoses" :key="i.code" >                        
                          <b-row class='dateNotes'>
                            <b-col sm="12" md="3">                             
                              <div v-bind:class="i.description === noteStatus.Finalized ? 'status finished':'status'">                               
                                {{i.description}}
                              </div>
                            </b-col>                         
                             <b-col sm="12" md="3">                            
                            </b-col>
                             <b-col sm="12" md="3" class="status">
                             {{i.code}}                            
                            </b-col>                                                   
                          </b-row>
                        </div>                        
                      </div>
                    </b-row>  
                    <b-button class="btn-login" size="sm" @click="row.toggleDetails">{{ $t('MdTable.HideDetailsTxt') }}</b-button>
                  </b-card>

   
                
                

                </template>
            </b-table>
        </b-col>    
    </b-row> 
  </div>
</template>

<script>



import ShowMessage from '@/components/messages/ShowMessage.js'
//import moment from 'moment'
//import GlobalService from '@/services/global.service'

  export default {
    mixins: [ShowMessage],
    props: {
     items:  Array,   
     fields: Array,
     totalRows: Number,
     totalRowsSubItems:Number, 
     editIconEvent: Function,
     deleteIconEvent: Function,
     showMessageIsEmpty: Boolean,
     ShowDetails:Boolean,
     onRowSelected: Function,
     currentPage: Number,
     currentPageSubItems:Number,
     onFiltered: Function,
     perPage: Number,
     perPageSubItems:Number,
     pageChanged: Function,
     pageChangedSubItems:Function,
     pageOptions: Array,
     pageOptionsSubItems: Array,
     perPageFunction: Function,
     perPageFunctionSubItems: Function,
     otherIconEvent: Function,
     otherIcon: String,
     viewIconEvent: Function,
     refTable: String,
     subItems: Array,
     multiSelect: Boolean,
     avisar:Function,
     CheckClick:Function,
     small: Boolean,
     stickyHeader: Boolean,
     isReadOnly:Boolean,
     trClassFn: Function,
     useAddTime:[Boolean],
     IsChartReview:[Boolean],
     IsViewDate:[Boolean],
   },
    data() {
      return {
          filter: null,
          filterOn: [],          
          pPage: this.perPage,
          pPageSubItems: this.perPageSubItems,
          cPage: this.currentPage,
          cPageSubItems: this.currentPageSubItems,         
          fFields: this.fields,
          fMultiSelect: 'single',
          CountNotesOpened:0,
          CountSubItems:0,
          DateTimeNow:null,
          TRSubItems:0,
          CPSubItems:0,
          CurrentPage : 1,
          noteStatus:{
            "Finalized":this.$t('Shared.IconDescF')
          }          
      }
    },
    
    methods:{

    editButton(row) {
        this.editIconEvent(row);
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },

    rowSelected(items) {     
        this.onRowSelected(items);
    },

    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },

    SubItemsPageChanged(e){     
    this.pageChangedSubItems(e);
    },

    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    pageFunctionSubItems(perpage) {
        this.perPageFunctionSubItems(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    viewButton(row) {   
      this.viewIconEvent(row);
    },


    AddTime(SubItems){        
      this.getheader(SubItems);     
      
    },

    /*Check(e,row){
      if(e){
         this.IsCheckBoxClicked(row);
      }
    },*/

    /*  async IsCheckBoxClicked(row) {     
      var SendObject = {       
        notesId:row.item.notesId,
        currentPage:1,
        perPage:10      
      }       
        this.CheckClick(SendObject);
      },*/
     
 },
  created() {    
    if(this.subItems) {
      this.fFields.unshift({ key: 'show_details'});
    }

    if(this.editIconEvent || this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent) {
      this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
    }

    this.fMultiSelect = this.multiSelect ? 'multi' : 'single';
  }, 
}

</script>

<style>
.TimeNotes{
 border: 1px solid #F3F3F3;
 background-color: #f9fbfc;
 color: #999;
 padding: 8px;
 border-radius: 6px;
 margin: 4px;
 cursor: pointer;
 transition: all 0.1s ease-out;
}
.addNotesBtn{
  background-color:#19BEE8;
  margin-right: 4px;
	border-radius:10px 2px;
	cursor:pointer;
  vertical-align: top;
	color:#ffffff;
	padding:4px 11px;
	text-decoration:none;
  border: 0;
}
.addNotesBtn:focus{
	background-color: #19BEE8 !important;
  color: #FFF !important;
}
</style>
