<template>
   <div class="row page-height">
      <div class="col-12">
         <MdTitleRegInfo v-if="!HideHeader"
            v-bind:RegistrationInfo="PatientTitle" 
            v-bind:titlePrefix="this.Mode ? $t('Allergy.EditPatientAllergy'): $t('Allergy.AddPatientAllergy')" 
            v-bind:IsClinical="IsClinical"
            v-bind:titleRef="'titleElementAllergy'"
            v-bind:patientCode="this.$route.params.id">
         </MdTitleRegInfo>

         <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
               <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
         </div>
         <!-- !! Important ,Added for testing purpose -->
         <div style='display:none;'><MdEdit 
               v-bind:TypeProp="'text'" 
               v-model="this.AllergyId "
               v-bind:Id="'input_allergy_id'">                                     
            </MdEdit>
         </div>
         <!-- End -->
         <b-row>
            <b-col md="3" lg="3" sm="12">
               <MdCheckBox
                     v-bind:Label="$t('Allergy.Active')"
                     v-bind:Name="'checkbox-isActive'"
                     v-bind:ClassProp="'AlignCheck'"
                     v-bind:CheckedValue="true"
                     v-bind:UncheckedValue="false"
                     v-model="Allergy.isActive"
                     v-bind:isReadOnly="isReadOnly"
               />
            </b-col>
         </b-row>
         <b-row>
            <b-col lg="4" md="4" sm="12">
               <MdLookup
                     v-bind:Label="$t('Allergy.Allergy')"
                     v-bind:ModelCode="Allergy.allergyCode"
                     v-bind:ModelDescription="AllergyDescription.description"
                     v-bind:RefCode="'AllergyCode'"
                     v-bind:Api="'AllergySetUps'"
                     v-bind:FieldCode="'code'"
                     v-bind:FieldDescription="'description'" 
                     v-bind:Fields="fields"
                     :SearchTitle="$t('Allergy.AllergySetUp')"
                     :GetValues="onCodeDescriptionOk"
                     :InvalidFeedback="'Invalid Allergy'"
                     :SearchFieldCode="'Code'"
                     v-bind:IsDisabled="isReadOnly"
                     v-bind:isValid="isAllergyValid"
                     v-bind:isRequired="true" 
                     v-bind:ShowAddRecord="true"
                     v-bind:BackToGridEventName="'onAllergyBackToGrid'"
                     v-bind:OkEventName="'onAllergyOkToGrid'"
               >
                    <template v-slot:body>
                        <MdEditAllergySetup
                            v-bind:EditMode="false"
                            v-bind:IsReadOnly="false"
                            v-bind:LabelCancel="$t('Shared.Back')"
                            v-bind:CancelEventName="'onAllergyBackToGrid'"
                            v-bind:SaveEventName="'onAllergyOkToGrid'"
                        >

                        </MdEditAllergySetup>
                    </template>
               </MdLookup>
            </b-col>
            <b-col lg="4" md="4" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Allergy.AllergyToDrug')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'drugName'"
                     v-bind:isReadOnly="true"
                     v-model="AllergyDrugName.description"
                     v-bind:Id="'input_alletodrog_p_id'">                                     
               </MdEdit>
            </b-col>
            <b-col lg="4" md="4" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Allergy.ProblemDescription')"
                     v-bind:TypeProp="'text'" 
                     v-model="problemDescription"
                     v-bind:Name="'problem'"
                     v-bind:isReadOnly="true"
                     v-bind:Id="'input_ProblemDescription_id'">
               </MdEdit>
            </b-col>
         </b-row>
         <b-row>
            <b-col lg="4" md="4" sm="12">
               <MdSelect
                     v-bind:Label="$t('Allergy.Severity')"
                     data-field="Additional_EducationStatus"
                     v-bind:Options="AllergySeverity"
                     v-bind:FieldDescription="'description'"
                     v-bind:FieldCode="'code'"
                     v-bind:Model="Allergy.problemSeverityId"                       
                     v-bind:Multiple="false"
                     :Input="ChangeDropDownAllergySeverity"
                     v-bind:IsDisabled="isReadOnly"
                     v-bind:Name="'severity_select'"
                     id="severity_select"
               />
            </b-col>
            <b-col lg="4" md="4" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.datefrom')"
                     v-bind:TypeProp="'date'" 
                     v-bind:Name="'datefrom'"
                     v-bind:Min="'1900-01-01'"
                     v-bind:Max="'2200-12-31'"
                     v-model="Allergy.sysDate"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'allergy_date_from'">                                     
               </MdEdit>
            </b-col>
            <b-col lg="4" md="4" sm="12">
               <MdEdit 
                     v-bind:Label="$t('Shared.dateTo')"
                     v-bind:TypeProp="'date'" 
                     v-bind:Name="'dateto'"
                     v-bind:Min="'1900-01-01'"
                     v-bind:Max="'2200-12-31'"
                     v-model="Allergy.dateTo"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Id="'allergy_date_to'">                                     
               </MdEdit>
            </b-col>
         </b-row>
         <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
         <b-row>
            <b-col lg="12" md="12" sm="12">
                  <MdMemo
                  v-bind:Name="'textarea_notes'"
                  v-bind:Rows="'3'"
                  v-bind:MaxRows="'6'"
                  v-bind:MaxLength="'500'"
                  v-model="Allergy.notes"
                  v-bind:isReadOnly="isReadOnly"
                  />                                     
               </b-col>
         </b-row>
         <div class="space-actions"></div>
         <div class="page-actions">
            <MdButton v-if="!HideBack"
               v-bind:Label="$t('Shared.Back')"
               v-bind:Name="'btnpatientlist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>
            <MdButton
               v-bind:Label="$t('Shared.Save')"
               v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
               v-on:click="ClickSaveCall"
               v-bind:isReadOnly="isReadOnly"
               v-bind:Name="'btn_save_patient_allergy'"
               />
         </div>
      </div>
   </div>
</template>

<script>

import EnumService from '@/services/enum.service' 
import AllergyService from '@/services/allergies.service'
import AllergySetupService from '@/services/allergiesetup.service'
import AllergyProblemSeveritiesService from '@/services/allergiesproblemseverities.service'
import Utils from '@/common/utils.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import StorageService from '@/services/storage.service'
import { EventBus } from '@/event-bus.js';

let elemSaveBtn = '';
export default {

   beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.$store.state.isUsePatientAllergies;
            if(iPI) next()
            else next('/accessdenied');
        }) 
   },

   beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('Allergy.PatientAllergies'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],
   props:{
    HideHeader:Boolean,
    HideBack:Boolean, 
    NoRouter:Boolean,
    MedicationRecId:String,
   },
   name: 'AllergyDetails',  
    data() {
      return { 
         ClickSave:false,
         infoList:[],
         RegistrationInfo:{},
         IsClinical:false,
       PatientInfo:{},
       PatientTitle:{      
         programName:'',
         fullName:'',
         admitDate:'',
         dischargeDate:'',
         dob:'',
         activitytypename:'',
         startdatetime:'',
         ageFormatted:''
      },      
        Mode:this.$attrs.editMode,  
        Loading:true,
        isReadOnly:false,
        AllergyId: '',
        problemDescription:"",
        AllergyDescription: { "description": '' },
        AllergyDrugName: { "description": '' },
        AllergySetup: {},
        AllergySeverity: [],   
        Allergy:{            
            patientCode: '',
            allergyCode: '',
            sysDate: null,
            sysUser: '',
            notes: '',
            headerId: '',
            drugName: '',
            isActive: true,
            dateTo: null,           
            problemSeverityId: null
        },
       OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },
        fields: [
         { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },
         { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth' },         
         { key: 'category', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' },        
        ],
        isAllergyValid: true,
        pendingChanges: false,
        currentAppointmentDB:{
         guid:'',
         activitytypecode:'',
         employee:{
            firstname:"",
            lastname:""
         }
        },
        appoAllergyRouteName:[
         'patient-allergy-add',
         'allergy-details',
         'appointments-allergy-details',
         'appointments-allergy-add'
        ]
      }
    },

    methods:{

   async GetPatientByCode(){
      this.infoList = await this.getInfoTitle(this.$route.params.id);
      if(this.infoList.length > 0){
         this.PatientTitle.programName = '';
         this.PatientTitle.admitDate = '';
         this.PatientTitle.dischargeDate = '';

         const { dob, firstname, lastname, age, ageFormatted } = this.infoList[0]; // Destructure for easier access

         if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
         if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
         if (age) this.PatientTitle.age = age;
         if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
      }
    },

      DiscardChanges() {
         
         if(this.$route.name.includes('appointments-allergy-details') || this.$route.name.includes('appointments-allergy-add')){
               this.$router.push({
                  name: "appointments-allergies",
                  params: { id: this.$route.params.id, actId: this.$route.params.actId },
               });
         }  
        
         else if(this.$route.name === 'allergy-details' || this.$route.name ===  'patient-allergy-add'){
               this.$router.push({
                  name: "patient-allergies",
                  params: { id: this.$route.params.id },
               });
         }else{
               this.$router.push({
                   name: "Clinical-allergies",
                    params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
               });
         }                    
         
      },
      onCodeDescriptionOk(result){
        this.Allergy.allergyCode = result.code;
        this.getLookupFieldsDesc(result.code);
      },

      formatterDate(value){
         return Utils.formatterDate(value);
      },

      getAllergyById(){
         if (this.AllergyId) {
         AllergyService.get( this.AllergyId )
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.Allergy = response.data;
                 this.Allergy.sysDate = this.formatterDate(this.Allergy.sysDate);
                 this.Allergy.dateTo = this.formatterDate(this.Allergy.dateTo);
                 this.getLookupFieldsDesc(response.data.allergyCode);
               }
            })
            .catch((error) => {
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
               }
               });
         }
      },

      getLookupFieldsDesc(Code){
        AllergySetupService.get(Code)
                .then((response) => {
                     if(response.status == '200' && response.data){
                        this.AllergyDescription.description = response.data.description;
                        this.AllergyDrugName.description = response.data.drugName;  
                        this.problemDescription = response.data.problemDescription;  
                        this.Allergy.drugName = response.data.drugName;
                     }
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
                    }
                });  
      },

      AddAllergy(){
         this.Allergy.patientCode = this.$route.params.id;
         this.Allergy.sysUser = StorageService.getUserId();
        
         AllergyService.addAllergy(this.Allergy)
         .then((response) => {
             // this.$router.push({ name: "patient-allergies", params: { id: this.Allergy.patientCode } })
               if(response.status == '200'){
                  this.$emit('load', false);
                  this.pendingChanges = false; 
                  EventBus.$emit('UpdateMarqueeRightBar',null);                                      
                  if(this.ClickSave){  
                     if(!this.NoRouter){                                   
                        if(this.$route.name === 'patient-allergy-add'){
                           this.$router.push({
                              name: "patient-allergies",
                              params: { id: this.$route.params.id },
                           })
                        }
                        else if(this.$route.name.includes('appointments-allergy-add')){
                           this.$router.push({
                              name: "appointments-allergies",
                              params: { id: this.$route.params.id, actId: this.$route.params.actId },
                           });
                        }else{
                           this.$router.push({
                              name: "Clinical-allergies",
                              params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                           });
                        } 
                     }
                  }                   
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Allergy.AllergiesSavedSuccessfully'), {
                         title: this.$t('Allergy.SavingAllergies'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                       
                        if(this.NoRouter){
                           EventBus.$emit('CloseModalAddAllergyMed', true);
                        }
                }
            })
         .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            if (!error.response) {
               this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.NotAbleToInsert'),"error");
            } else if (error.response.status === 400){
               this.showMessage(this.$t('Allergy.PatientAllergies'),error.response.data.errorMessage,"error");
            } else if(error.response.status === 500 ){
               this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.ThereWereSomeError'),"error");
            } else {
               this.showMessage(this.$t('Allergy.PatientAllergies'),error.response.data.errorMessage,"error");
            }
            if(!this.NoRouter){
               this.$route.router.go('/');
            }
               
        });
      },

      UpdateAllergy(){
         this.Allergy.sysDate = this.Allergy.sysDate != "" ? this.Allergy.sysDate: null;
         this.Allergy.dateTo = this.Allergy.dateTo != "" ? this.Allergy.dateTo: null;
         AllergyService.update(this.AllergyId, this.Allergy)
        .then((response) => {
               
             // this.$router.push({ name: "patient-allergies", params: { id: this.Allergy.patientCode } })
                if(response.status == '200'){
                  this.$emit('load', false);
                  this.pendingChanges = false; 
                  EventBus.$emit('UpdateMarqueeRightBar',null);
                  if(this.ClickSave){               
                     if(this.$route.name === 'allergy-details'){
                        this.$router.push({
                           name: "patient-allergies",
                           params: { id: this.$route.params.id },
                        });
                     }
                     else if(this.$route.name.includes('appointments-allergy-details')){
                        this.$router.push({
                           name: "appointments-allergies",
                           params: { id: this.$route.params.id, actId: this.$route.params.actId },
                        });
                     }
                     else{
                        this.$router.push({
                           name: "Clinical-allergies",
                           params: { id: this.$route.params.id,recordid:this.$route.params.recordid },
                        });
                     }  
                  }                       
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Allergy.AllergiesSavedSuccessfully'), {
                         title: this.$t('Allergy.SavingAllergies'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    
                }
            })
        .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
           if (!error.response) {
               this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.NotAbleToUpdate'),"error");
           } else if (error.response.status === 400){
               this.showMessage(this.$t('Allergy.PatientAllergies'),error.response.data.errorMessage,"error");
            } else if(error.response.status === 500 ){
               this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.ThereWereSomeError'),"error");
            } else {
               this.showMessage(this.$t('Allergy.PatientAllergies'),error.response.data.errorMessage,"error");
            }
            this.$route.router.go('/');
        });
      },

      ClickSaveCall(){
         this.ClickSave = true;
         this.SaveOrEdit();
      },
      
      SaveOrEdit(){
        if(!this.isReadOnly) {
          if (this.validate_required()) {
            elemSaveBtn.setAttribute("disabled",'');
            this.$emit('load', true);
                
            this.pendingChanges = false       
            if (this.Mode) { 
               this.UpdateAllergy()           
            }else{            
               this.AddAllergy()
            }
          } else {
            this.showMessage(this.$t('Allergy.PatientAllergies'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
            this.$route.router.go('/');
          }
        } 
      },

       getAllergiesProblemSeverities(){
          AllergyProblemSeveritiesService.getAll()
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.AllergySeverity = response.data;
                 this.getAllergyById();
               }
            })
            .catch((error) => {
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
               }
               });
      },

      ChangeDropDownAllergySeverity(value){
            this.Allergy.problemSeverityId = value ? value.code : null;
        },

      async loadContents() {
          await this.getAllergiesProblemSeverities();
         
      },

      validate_required() {
         this.isAllergyValid = this.Allergy.allergyCode && this.Allergy.allergyCode.length > 0 ? true : false
         return this.isAllergyValid
      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
      ,
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      },
      PopulatePatientTitle(){
         /* eslint-disable no-unused-vars */
         const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
         if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
         if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	

         if(this.$route.name.includes('Clinical-allergies')){ 
            if (programName) this.PatientTitle.programName = programName; 
            if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
            if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
            if (patientName) this.PatientTitle.fullName = patientName;
            // if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
            if (age) this.PatientTitle.age = age === 0 ? "0" : age;
            if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
         }
         if (this.appoAllergyRouteName.some(route => this.$route.name.includes(route))){         
            this.PatientTitle.programName = '';
            this.PatientTitle.dateFrom = '';
            this.PatientTitle.dateTo = '';

            if(this.infoList.length > 0){
               /* eslint-disable no-unused-vars */
               const { dob, firstname, lastname, age, ageFormatted } = this.infoList[0]; // Destructure for easier access

               // if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
               // if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
               // if (age) this.PatientTitle.age = age;
               if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
            }          
         }
    },
    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }
    },

     async mounted(){
      //  this.$emit('load', this.Loading);
      this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical') ? EnumService.ClinicalItems : this.$route.name.includes('appointments-') ? EnumService.AppointmentItems : EnumService.PatientItems;
      this.OptionsRightMenu.visible = true;
      this.$emit("getVisibleMenu", this.OptionsRightMenu);
      this.isReadOnly = await this.$store.state.isPatientAllergiesEHRReadOnly;
      this.OptionsRightMenu.Mode = this.Mode;  
      this.AllergyId =  this.$route.params.allergyid;      
      this.loadContents();      
      if (this.$route.name.includes('patient-allergy-add') || this.$route.name.includes('allergy-details') 
         || this.$route.name.includes('appointments-allergy-details') || this.$route.name.includes('appointments-allergy-add')){  
        this.GetPatientByCode();
      }
     
      if (this.$route.name.includes('appointments-allergy-details') || this.$route.name.includes('appointments-allergy-add'))
         await this.getCurrentAppointment()

      if(this.$route.name.includes('Clinical-allergies')){      
         this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

         if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
         if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
         if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;

         this.IsClinical = dateTo ? false : true;
      }

      this.PopulatePatientTitle();

      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_patient_allergy');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );
       
    },

    created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
          this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  }
</script>


