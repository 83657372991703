<template>
    <div id="ContainerDailyNotes">
        <div class="row">
            <div class="col-12">
                <MdTitleRegInfo
                    v-bind:RegistrationInfo="RegistrationInfo" 
                    v-bind:titlePrefix="$t('DailyNoteList.DailyNotesTitle')" 
                    v-bind:titleRef="'titleElementDailyNoteList'"
                    v-bind:patientCode="this.$route.params.id">
                </MdTitleRegInfo>

                <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
                    <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                    <div class="flex-grow-1">
                        <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
                    </div>
                </div>

                <div  v-if="this.ModelNoteType=='ALL'" id="noteTypeWarning" class="row">
                    <div class="col-sm-12">
                        <p class="text-muted">
                            <b-spinner small label="Small Spinner" type="grow" variant="warning"></b-spinner>{{$t('DailyNoteList.noteTypeWarningTxt1')}}<span style="font-variant: small-caps; font-weight: bold;">{{$t('DailyNoteList.noteTypeWarningTxt2')}}</span>{{$t('DailyNoteList.noteTypeWarningTxt3')}}<span style="font-variant: small-caps; font-weight: bold;">{{$t('DailyNoteList.noteTypeWarningTxt4')}}</span> button.
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12 col-md-3">
                        <MdButton
                            v-bind:Label="$t('DailyNoteList.AddNote')" 
                            v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                            v-bind:IconClass="'ti-plus pr-1'"
                            v-on:click="Add"
                            v-bind:isReadOnly="this.isReadOnly || this.AddButtonDisabled"
                            v-bind:Name="'btn_add_dailynote'">
                        </MdButton>  
                    </div>

                    <div class="col-sm-12 col-md-3">  
                        <MdSelect
                            v-bind:Label="$t('Shared.NoteType')"
                            v-bind:Options="NoteTypesList"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ModelNoteType"
                            v-bind:Multiple="false"              
                            :Input="ChangeDropDownNodeType"
                            v-bind:isValid="this.isNoteTypeValid"
                            v-bind:AllowEmpty="false"
                            v-bind:Name="'note_type_select'"
                            id="note_type_select"
                        />
                    </div>

                    <div class="col-sm-12 col-md-6 mb-4">
                        <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                            <div class="input-group">
                                <input 
                                    type="text" 
                                    class="form-control"
                                    v-model="DailyNotesParams.SearchCriteria"
                                    @keyup.enter="SearchDailyNotes"
                                >
                                <div class="input-group-append">
                                        <MdButton 
                                        v-bind:Name="'btnsearchinput'"
                                        v-bind:VariantProp="'btn btn-sm search-attached'"
                                        v-bind:IconClass="'mdi mdi-magnify'"
                                        v-on:click="SearchDailyNotes">
                                    </MdButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>    
        
                <div class="row">
                    <div class="col-sm-12 col-md-4 mb-4">
                        <MdEdit 
                            v-bind:Label="$t('Shared.datefrom')"                      
                            v-bind:TypeProp="'date'" 
                            v-bind:Min="'1900-01-01'"
                            v-bind:OnPressEnter="SearchDailyNotes"
                            v-bind:Max="'2200-12-31'"
                            v-bind:isValid="isDateFromValid" 
                            v-bind:Name="'DateFrom'"
                            ref="DateFrom"                        
                            v-model="DateFrom"/>          
                    </div>
                    <div class="col-sm-12 col-md-4 mb-4">
                        <MdEdit 
                            v-bind:Label="$t('Shared.dateTo')"                      
                            v-bind:TypeProp="'date'" 
                            v-bind:OnPressEnter="SearchDailyNotes"
                            v-bind:Min="'1900-01-01'"
                            v-bind:Max="'2200-12-31'"   
                            v-bind:isValid="isDateToValid"  
                            v-bind:Name="'DateTo'" 
                            ref="DateTo"                     
                            v-model="DateTo"/>  
                    </div>
                    <div class="col-md-4">
                        <div class="mt-4">
                            <button 
                                v-on:click="SearchDailyNotes"
                                type="button"
                                class="btn btn-primary btn-login mx-2"												
                                id="btnsearchinput">
                                {{ $t('Shared.Search') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-2">
                <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        
                <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite">
                    <div class="text-center my-2">{{$t('MdTable.nRecShow')}}</div>
                </div>

                <div v-for="(item,index) in this.DailyNotesList" :key="index">
                    <div class="row dailyNotes">
                        <div class="col-2 mt-1">
                            <b-icon @click="Edit(item)" id="svgEt" icon="pencil-fill" v-b-tooltip.hover.top="$t('Shared.Edit')" :class="isActiveForEdit(item)" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                            <b-icon icon="printer-fill" @click="showReport(item)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                            <b-icon icon="slash-circle" @click="Void(item.recNo, item.isVoid)" v-b-tooltip.hover.top="$t('DailyNoteList.UnVoid')" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                        </div>
                        <div class="col-4">
                            <small>{{ $t('DailyNoteList.DateAndTime') }}</small><br>{{item.serviceDate}}
                        </div>
                        <div class="col-3">
                            <small>{{ $tc('Shared.Employee') }}</small><br>{{item.employeeName }}
                        </div>
                        <div class="col-3">
                            <small>{{ $t('Shared.NoteType') }}</small><br>{{item.noteTypeName }}
                        </div>
                    </div>
    
                    <div v-if="item.isVoid" class="alert alert-fill-warning" style="margin:10px 20px" role="alert">
                        <i class="mdi mdi-alert-circle"></i> {{ $t('Msg.NoteVoied') }} {{item.reasonName}}
                    </div>

                    <div class="row dailyNotesContent">
                        <div v-html="item.comments" class="col-12"></div>
                    </div>
                </div>
            </div>
        </div>
    
        <hr/>
        <div class="row mt-4">
            <div class="my-1 col-sm-7 col-md-6">
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="onChange"
                    pills
                    size="lg"
                ></b-pagination>
            </div>
            <div align="right" class="mr-2 col">
                {{ $t('Shared.Show') }}
                <b-form-select 
                    v-model="perPage" 
                    :options="options" 
                    size="sm" 
                    id="perPageSelect"
                    v-bind:style="perPageStyle"
                    @change="perPageChange">
                </b-form-select>
                {{ $t('Shared.Of') }} {{totalRows}}
            </div>
        </div>

        <div class="space-actions"></div>
        <div class="page-actions">
        <MdButton
            v-bind:isReadOnly="DailyNotesList.length == 0"
            v-bind:Label="$t('Shared.Report')"
            v-bind:Name="'btndailyreport'"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-on:click="showReport()"/>
        </div>

        <MdReasonDlg v-if="showReason"
            @getModalReasonCancel="onModalReasonResult($event)"
            @getModalReasonOK="onModalReasonOK"
        />

        <DailyNotesRep v-if="isReportVisible" 
            :closeWindow="closeParamRepWindow"
            :patientCode="this.$route.params.id"
            :noteType="ModelNoteType"
            :recNo="recNoToReport"
            :noteTypeName="getNoteTypeName()"
            :patientName="this.RegistrationInfo.patientName"
        />
    </div>
</template>



<script>
import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import DailyNotesService from '@/services/dailynotes.service'
import Utils from '@/common/utils'
import NotesTitlesService from '@/services/notestitles.service'
import UserPreference from "@/services/quickhelp.service";
import LocalStorage from '@/services/storage.service'

export default {

    beforeRouteEnter(to, from, next) {
            next(async vm => {
                const iUA = await vm.$store.state.isUseDailyNotes;
                if(iUA) next()
                else next('/accessdenied');
            }) 
    },

    mixins: [ShowMessage, DBFunctions],
    name: 'DailyNotesList',

    data() {
        return {
            Loading:true,
            OptionsRightMenu:{
                    visible:true,
                    ItemsList:[],
                    btnvisible:true,
                    itemdisabled: false,
                    PatientCode:''         
            },
            NotesTitlesParams:{
                SearchCriteria:'',
                PageNumber : 0,
                PageSize:0,      
            },
            DailyNotesParams:{
                SearchCriteria:'',
                PageNumber : 3,
                PageSize: 3,
                RecordId:null,
                NoteType:null        
            },                        

            isReadOnly: false,
            RegistrationInfo:{},
            NoteTypesList: [],
            ModelNoteType: null,
            DailyNotesList: [],
            EmployeeCode: this.$store.state.employeeCode,    
            DailyNotesPreference: {'lastDailyNote':''},
            isNoteTypeValid: true,
            DailyNotesIsVoid: {'isVoid':'','reasonName':''},
            showReason: false,
            idDailyNote: null,
            canInsert: false,
            AddButtonDisabled: false,
            emptyRecordsStyle: {
                backgroundColor: '#CF0',
                fontWeight: 'bold !important',
                textTransform: 'uppercase',
                padding: '2px',
                borderRadius: '50px',
                animation: 'fadeOut 10s ease-in-out infinite'
            },
            showEmptyRecords: false,
            currentPage: 1,
            perPage: 5,
            totalRows: 0, 
            Header:{},
            options: [
                { value: 5, text: '5' },
                { value: 10, text: '10' },
                { value: 15, text: '15' },
            ],
            perPageStyle: {
                width: 'auto'
            },
            isReportVisible: false,
            recNoToReport: null,
            UserId: LocalStorage.getUserId(),
            VisibleEdit:false,
            editClass:`rounded-circle tableActionBTN mr-2`,
            isDateFromValid: true,
            isDateToValid: true,
            DateTo: null,
            DateFrom: null
        }
    },

    methods: {
    
        closeParamRepWindow() {
            this.isReportVisible = false
            this.recNoToReport = null
        },
    
        showReport(item) {
            if(this.DailyNotesList.length > 0){
                if (item && item.recNo) {
                    this.recNoToReport = item.recNo
                }
                this.isReportVisible = true
            } else {
                setTimeout(() => 
                    this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
                        title:  this.$t('DailyNoteList.DailyNotesTitle'),
                        autoHideDelay: 3000,
                        variant: "success"
                    }),
                50);
            }
        },

        SearchDailyNotes() {
            if (this.validateDate()) {
                this.$emit('load', true);
                this.getListing();
            } else {
                this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), 
                    this.$t('Msg.InvalidDate') + ' ' + this.$t('Shared.Format') + ' '+ Utils.getShortDateFormat(), "errorAutoHide");          
            }
        },

        validateDate(){
            this.isDateFromValid = this.DateFrom == null || this.$refs["DateFrom"].editIsValid();
            this.isDateToValid = this.DateTo == null || this.$refs["DateTo"].editIsValid();
            return this.isDateFromValid && this.isDateToValid;
        },

        perPageChange(value) {
            this.perPage = value
            this.$emit('load', true);
            this.getDailyNotes()
        },
    
        onChange(page) {
            this.currentPage = page
            this.$emit('load', true);
            this.getDailyNotes()
        },

        async Add() {
            if (!this.ModelNoteType) {
                this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('DailyNoteList.MandatoryValue'),"errorAutoHide");
                this.isNoteTypeValid = false
            }
            else {
                let NoteTypeName = this.findNoteTypeName(this.NoteTypesList, this.ModelNoteType)
                this.$router.push({ name: "dailynote-add", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, notetype: this.ModelNoteType, noteTypeName: NoteTypeName.name } });
            }
        },

        getNoteTypeName() {
            return this.findNoteTypeName(this.NoteTypesList, this.ModelNoteType).name
        },

        Edit(item) {     
            this.$router.push({ name: "dailynote-details", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, dailynoteid: item.recNo, noteTypeName: item.noteTypeName, noteEmployeeCode: this.EmployeeCode } });
        },

        Void(id, isVoid) {
            this.idDailyNote = id

            if (!isVoid)
                this.showReason = true;
            if (isVoid)
                this.setIsVoid(false,'')
        },

        findNoteTypeName (array, code) {
            return array.find(element => element.code === code) ?? -1;
        },

        getListing() {
            this.$emit('load', true);
            this.currentPage = 1
            this.getDailyNotes()
        },

        onModalReasonResult(){
            this.showReason = false;
        },

        onModalReasonOK(result){
            this.setIsVoid(true,result.name)
            this.showReason = false;
        },

        async setIsVoid(value, reason) {
            this.DailyNotesIsVoid.isVoid = value
            this.DailyNotesIsVoid.reasonName = reason
            this.$emit('load', true);
            await this.UpdateIsVoid(this.idDailyNote)
            this.getDailyNotes()
        },

        ChangeDropDownNodeType(value) {
            this.ModelNoteType = value ? value.code : ''
            
            if (value.code === 'ALL')
                this.DailyNotesParams.NoteType = ''
            else
                this.DailyNotesParams.NoteType = value.code

            this.UpdatePreference(value ? value.code : null)
            this.isNoteTypeValid = value ? true : ''
            this.$emit('load', true);
            this.AddButtonDisabled = value && value.canInsert == true ? false : true
            this.currentPage = 1
            this.getDailyNotes()
        },

        async getUserPreference() {
            await UserPreference.getQhUserPreference(this.UserId)
                .then((response) => {
                    if (response.data && response.data.length > 0) {
                        let LastDailyNote = response.data[0].lastDailyNote ;
                        LastDailyNote = LastDailyNote == null ? 'ALL' : LastDailyNote
                        const NoteTypeObject = this.findNoteTypeName(this.NoteTypesList, LastDailyNote)
                        this.AddButtonDisabled = LastDailyNote && NoteTypeObject.canInsert == true ? false : true
                        if (LastDailyNote) {
                            this.DailyNotesParams.NoteType = LastDailyNote && LastDailyNote === 'ALL' ? '' : LastDailyNote
                            this.ModelNoteType = LastDailyNote
                        }
                    } 
                })
                .catch((error) => {
                    if (error.errorMessage) {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), error, "error");
                    }
                });
        },
    
        async getNotesTitles() {
            await NotesTitlesService.getAll(this.NotesTitlesParams)
                .then((response) => {
                    if (response.status == "200" && response.data && response.data.length > 0) {
                        this.NoteTypesList = response.data;
                        this.NoteTypesList.unshift({code: "ALL", name: this.$t('Shared.All'), canInsert: false})
                    }
                })
                .catch((error) => {
                    if (!error.response) {
                        // network error
                        this.showMessage(
                            this.$t('DailyNoteList.DailyNotesTitle'),
                            this.$t('Msg.retrieveInfo'),
                            "error"
                        );
                    } else {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), error, "error");
                    }
                });
        },

        async getDailyNotes() {
            this.DailyNotesParams.PageNumber = this.currentPage;
            this.DailyNotesParams.PageSize = this.perPage;
            this.DailyNotesParams.RecordId = this.$route.params.recordid;
            this.DailyNotesParams.DateTo = Utils.formatterToISO(this.DateTo);
            this.DailyNotesParams.DateFrom = Utils.formatterToISO(this.DateFrom);
        
            await DailyNotesService.getAll(this.DailyNotesParams)
                .then((response) => {
                    if (response.status == "200" && response.data) {
                        this.DailyNotesList = response.data;
                        let headerString = response.headers["x-pagination"];
                        this.Header = JSON.parse(headerString);
                        this.totalRows = this.Header.TotalCount;
                        this.showEmptyRecords = response.data && response.data.length == 0 ? true : false
                        this.DailyNotesList.forEach(function (element) {
                        if (element.serviceDate)
                            element.serviceDate = Utils.formatterDateToString(element.serviceDate) + ' ' +
                                                Utils.formatTime(element.serviceTime); 
                        }, this);
                        this.$emit("load", false);
                    }
                    this.$emit("load", false);
                })
                .catch((error) => {
                    this.$emit("load", false);
                    if (!error.response) {
                        // network error
                        this.showMessage(
                            this.$t('DailyNoteList.DailyNotesTitle'),
                            this.$t('Msg.retrieveInfo'),
                            "error"
                        );
                    } else {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'), error, "error");
                    }
                });
        },

        UpdatePreference(value){
            this.DailyNotesPreference.lastDailyNote = value
            UserPreference.UpdateLastDailyNotes(this.UserId, this.DailyNotesPreference)
                .catch((error) => {
                    if (!error.response) {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                    }          
                    else if (error.response.data && error.response.data.errorMessage) {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),error.response.data.errorMessage,"error");
                    }
                    else {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                    }
                });
        },

        async UpdateIsVoid(id){
            await DailyNotesService.updateIsVoid(id, this.DailyNotesIsVoid)
                .catch((error) => {
                    if (!error.response) {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.NotAbleToUpdateIsVoid'),"error");
                    }          
                    else if (error.response.data && error.response.data.errorMessage) {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),error.response.data.errorMessage,"error");
                    }
                    else {
                        this.showMessage(this.$t('DailyNoteList.DailyNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                    }
                });
        },

        isActiveForEdit (item) {
            let editClass = '';
            editClass = (item.employeeCode == this.$store.state.employeeCode) 
                                    ? this.editClass 
                                    : "rounded-circle btndisabled mr-2";
            return editClass;
        }
    },

    async mounted() {
        this.$emit('load', true);
        this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.$emit('getBtnRightMenu', this.OptionsRightMenu);
        this.isReadOnly = this.$store.state.isDailyNotesEHRReadOnly;
        
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
        const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

        if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
        if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
        if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
        if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;

        await this.getNotesTitles();
        await this.getUserPreference();
        await this.getDailyNotes();
    },
};

</script>
<style>
.btndisabled{
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
    background-color: gainsboro;
}
</style>