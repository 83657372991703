<template>
    <div class="notes several">
        <h4 class="note-title text-truncate" v-b-tooltip.hover >
            <span :class="icon"></span>
            <span> {{this.title}} </span> 
        </h4>

        <b-icon
            icon="x-circle-fill"
            v-on:click="Close"
            class="rounded-circle notes-close"
            scale=".5"
            font-scale="1.8"
            > <!-- create the CloseNote action. Open notes should be closed with the HIDDEN class -->
        </b-icon>


        <div class="cutescroll pl-1" v-if="TreatmentPlan">
            <div style="position:relative;border: solid 1px transparent;">
                <div class="row">
                    <div class="col-12">
                        <div v-for="(item, index) in this.TreatmentPlan.problems" :key="index" class="IncidentContainerBox">
                            <div class="pull-right containerBoxTitle ml-5">
                                <b-icon icon="printer-fill" @click="PrintOnlyProblem(item)" v-b-tooltip.hover.top="$t('treatmentplanList.printProb')"
                                    scale=".5" font-scale="1.6" variant="light"></b-icon>           
                            </div>


                            <div class="row">
                                <div class="col-md-12">
                                    <div class="grid-margin stretch-card">
                                        <div class="card activeCard">
                                            <div class="card-body">
                                                <div v-b-toggle="'probList' + index + itemProbCode + ' probListBanner' + index + itemProbCode"
                                                        class="d-flex align-items-start justify-content-between">
                                                    <h6 class="card-title flex-grow lower-line text-truncate">
                                                        {{$t('modalEditProblem.Problem')}}: {{ item.problem.probDesc }}
                                                    </h6>
                                                </div>
                                                <b-collapse :id="'probListBanner' + index + itemProbCode" class="small-info ml-2" visible><small>{{$t('treatmentplanList.cliTiteltextP')}}</small></b-collapse>
                                                <b-collapse :id="'probList' + index + itemProbCode">
                                                    <div class="flex-container">
                                                        <MdTitle v-bind:VisiblePrint="true" 
                                                            @ClickPrint="PrintProblem(item)" class="shrinkMargin"
                                                            v-bind:Text="$t('modalEditProblem.Problem')" v-bind:IconPrintDescription="$t('treatmentplanList.proReview')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleProblem'" />

                                                        <div class="row">
                                                            <div class="col-md-3">
                                                                <div class="float-left col text-muted p-item">
                                                                    <small>{{$t('modalEditProblem.Problem')}}</small><br>
                                                                    <b>{{ item.problem.probDesc }}</b>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="float-left col text-muted p-item">
                                                                    <small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                                                    <b>{{ item.problem.probDate }}</b>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="float-left col text-muted p-item">
                                                                    <small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                                    <b>{{ item.problem.userName }}</b>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3">
                                                                <div class="float-left col text-muted p-item">
                                                                    <small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                                    <b>{{ item.problem.dateTimeCreate }}</b>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <MdTitle
                                                            v-bind:BlankDescription="$t('treatmentplan.PsychosocialEnvironmental')" v-bind:Text="$t('treatmentplan.PsychosocialEnvironmental')"
                                                            v-bind:isSubtitle="true" v-bind:Name="'T_TitleBehavioral'" />

                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="wizObjectives" v-for="(agg, aggindex) in item.problemAggregates" :key="aggindex">
                                                                    <b-icon icon="check" class="wizardCheckObj"></b-icon> <span> {{agg.aggName}} </span>
                                                                    <div class="ml-3 mt-2">
                                                                        <ul>							
                                                                            <li v-for="(it, indexit) in agg.list" :key="indexit"> {{it.name}}</li>						
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <MdTitle 
                                                            v-bind:BlankDescription="$t('modalAddGoal.goals')" v-bind:Text="$t('modalAddGoal.goals')" v-bind:isSubtitle="true"
                                                            v-bind:Name="'T_TitleGoal'" />

                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <div class="text-muted p-item">
                                                                    <div v-for="(g, goalindex) in item.goals" :key="goalindex">
                                                                        <!-- GOALS -->
                                                                        <div class="mb-2 ml-2">
                                                                            <b-row v-b-toggle="'goal' + index + (goalindex + 1)">
                                                                                <b-col>
                                                                                    <!-- Condicionar esto para mostrar estados específicos -->
                                                                                    <!-- Si el estado es "MET", eliminar la clase completeDraft -->
                                                                                    <span v-if="g.goal.status" class="chRstatusfinished completeDraft align-middle">
                                                                                        {{ g.goal.status }}
                                                                                    </span>
                                                                                    <p class="font-weight-bold ml-1"><b-icon class="when-closed" font-scale="1"
                                                                                        variant="primary" icon="caret-down-fill"></b-icon><b-icon class="when-open"
                                                                                        font-scale="1" variant="primary" icon="caret-up-fill"></b-icon>{{$t('modalEditGoal.goal')}}
                                                                                        {{ goalindex + 1 }} {{ g.goal.goalDesc }}
                                                                                    </p>
                                                                                </b-col>
                                                                            </b-row>
                                                                        </div>
                                                                        <b-collapse :id="'goal' + index + (goalindex + 1)" class="mb-3 ml-2">
                                                                            <b-card bg-variant="light">
                                                                                <b-row cols="3">
                                                                                    <b-col>
                                                                                        <small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                                                                        <p v-if="g.goal.dateSet">{{ g.goal.dateSet }}</p>
                                                                                        <p v-else>{{$t('Shared.Notdefined')}}</p>
                                                                                    </b-col>
                                                                                    <b-col v-if="g.goal.dateExp"><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                                                                        <p>{{ g.goal.dateExp }}</p>
                                                                                    </b-col>
                                                                                    <b-col v-else><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                    </b-col>
                                                                                    <b-col v-if="g.goal.dateAchieve"><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                                                                        <p>{{ g.goal.dateAchieve }}</p>
                                                                                    </b-col>
                                                                                    <b-col v-else><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                    </b-col>
                                                                                    <b-col v-if="g.goal.status"><small>{{$t('Shared.Status')}}</small><br>
                                                                                        <p>{{ g.goal.status }}</p>
                                                                                    </b-col>
                                                                                    <b-col v-else><small>{{$t('Shared.Status')}}</small><br>
                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                    </b-col>
                                                                                    <b-col v-if="g.goal.sysUser"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                                                        <p>{{ g.goal.userName }}</p>
                                                                                    </b-col>
                                                                                    <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                    </b-col>
                                                                                    <b-col v-if="g.goal.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                                                        <p>{{ g.goal.dateTimeCreate }}</p>
                                                                                    </b-col>
                                                                                    <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                    </b-col>
                                                                                </b-row>
                                                                                <div class="mb-2 ml-3" v-for="(o, oindex) in g.objectives" :key="oindex">
                                                                                    <b-row v-b-toggle="'obj' + index + (goalindex + 1) + (oindex + 1)">
                                                                                        <b-col>
                                                                                            <!-- Condicionar esto para mostrar estados específicos -->
                                                                                            <!-- Los posibles estados son: inprogress, met, unmet, revised, extended, deferred, reffered -->
                                                                                            <!-- Con las 2 clases sale azul, sin la 2da, sale verde -->
                                                                                            <span v-if="o.objective.status" class="chRstatusfinished completeDraft">{{ o.objective.status }}</span>
                                                                                            <p class="mt-1"><span class="font-weight-bold"><b-icon class="when-closed"
                                                                                                    font-scale="1" variant="primary" icon="caret-down-fill"></b-icon><b-icon
                                                                                                    class="when-open" font-scale="1" variant="primary"
                                                                                                    icon="caret-up-fill"></b-icon> {{$t('modalAddObjective.objectives')}} {{ oindex + 1 }}</span>:
                                                                                                {{ o.objective.objDesc }}
                                                                                            </p>
                                                                                        </b-col>
                                                                                    </b-row>

                                                                                    <b-collapse :id="'obj' + index + (goalindex + 1) + (oindex + 1)"
                                                                                            class="mb-3 mt-2 ml-2">

                                                                                        <b-button-group class="ml-3 groupActions whiteBtn">
                                                                                            <b-button v-on:click="PrintObjective(o)" v-b-tooltip.hover
                                                                                                title="Objective Review" size="sm" variant="light">
                                                                                                <b-icon icon="printer-fill" aria-hidden="true"></b-icon>
                                                                                            </b-button>
                                                                                        </b-button-group>

                                                                                        <b-card bg-variant="white">
                                                                                            <b-row cols="3">
                                                                                                <!-- IMHO, la condición debería estar en el párrafo, que es lo que cambia -->
                                                                                                <b-col v-if="o.objective.dateSet"><small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                                                                                    <p>{{ o.objective.dateSet }}</p>
                                                                                                </b-col>
                                                                                                <b-col v-else><small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                                                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                </b-col>
                                                                                                <b-col v-if="o.objective.dateExp"><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                                                                                    <p>{{ o.objective.dateExp }}</p>
                                                                                                </b-col>
                                                                                                <b-col v-else><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                                                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                </b-col>
                                                                                                <b-col v-if="o.objective.dateAchieve"><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                                                                                    <p>{{ o.objective.dateAchieve }}</p>
                                                                                                </b-col>
                                                                                                <b-col v-else><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                                                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                </b-col>
                                                                                                <b-col v-if="o.objective.status"><small>{{$t('Shared.Status')}}</small><br>
                                                                                                    <p>{{ o.objective.status }}</p>
                                                                                                </b-col>
                                                                                                <b-col v-else><small>{{$t('Shared.Status')}}</small><br>
                                                                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                </b-col>
                                                                                                <b-col v-if="o.objective.sysUser"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                                                                    <p>{{ o.objective.userName }}</p>
                                                                                                </b-col>
                                                                                                <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                </b-col>
                                                                                                <b-col v-if="o.objective.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                                                                    <p>{{ o.objective.dateTimeCreate }}</p>
                                                                                                </b-col>
                                                                                                <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                </b-col>
                                                                                            </b-row>
                                                                                        </b-card>
                                                                                    </b-collapse>
                                    
                                                                                    <div class="mb-2 ml-2" v-for="(i, iindex) in o.interventions" :key="iindex">
                                                                                        <b-row
                                                                                                v-b-toggle="'int' + index + (goalindex + 1) + (oindex + 1) + (iindex + 1)">
                                                                                            <b-col>
                                                                                                <p class="mt-1"><span class="font-weight-bold"><b-icon class="when-closed"
                                                                                                    font-scale="1" variant="primary"
                                                                                                    icon="caret-down-fill"></b-icon><b-icon class="when-open"
                                                                                                    font-scale="1" variant="primary" icon="caret-up-fill"></b-icon>
                                                                                                    {{$t('modalAddIntervention.intervention')}} {{ iindex + 1 }}</span>:
                                                                                                {{ i.intDesc }}</p>
                                                                                            </b-col>
                                                                                        </b-row>

                                                                                        <b-collapse :id="'int' + index + (goalindex + 1) + (oindex + 1) + (iindex + 1)"
                                                                                                class="mb-3 mt-2 ml-2">

                                                                                            <b-card bg-variant="white">
                                                                                                <b-row cols="3">  
                                                                                                    <b-col v-if="i.startDate"><small>{{$t('treatmentplanList.starOn')}}</small><br>
                                                                                                        <p>{{ i.startDate }}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-else><small>{{$t('treatmentplanList.starOn')}}</small><br>
                                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-if="i.amount"><small>{{$t('modalEditIntervention.amount')}}</small><br>
                                                                                                        <p>{{ i.noAmt }} {{ i.amount }}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-else><small>{{$t('modalEditIntervention.amount')}}</small><br>
                                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-if="i.frequency"><small>{{$t('Shared.Frequency')}}</small><br>
                                                                                                        <p>{{ i.noFrequency }} {{ i.frequency }}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-else><small>{{$t('Shared.Frequency')}}</small><br>
                                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-if="i.duration"><small>{{$t('MdPainAssessmentNotesOutCome.Duration')}}</small><br>
                                                                                                        <p>{{ i.noDuration }} {{ i.duration }}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-else><small>{{$t('MdPainAssessmentNotesOutCome.Duration')}}</small><br>
                                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-if="i.therapistCode"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                                                                        <p>{{ i.therapistName }}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-if="i.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                                                                        <p>{{ i.dateTimeCreate }}</p>
                                                                                                    </b-col>
                                                                                                    <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                                                                        <p>{{$t('Shared.Notdefined')}}</p>
                                                                                                    </b-col>

                                                                                                </b-row>
                                                                                            </b-card>

                                                                                            <b-card bg-variant="white" class="mt-2" v-if="i.therapyNames.length > 0">
                                                                                                <b-row>
                                                                                                    <b-col><small>{{$t('modalEditIntervention.Modalities')}}</small><br>
                                                                                                        <ul>
                                                                                                            <li v-for="(m, mindex) in i.therapyNames" :key="mindex">{{ m }}</li>
                                                                                                        </ul>
                                                                                                    </b-col>
                                                                                                </b-row>
                                                                                            </b-card>
                                                                                        </b-collapse>
                                                                                    </div>

                                                                                </div>
                                                                            </b-card>
                                                                        </b-collapse>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <!-- div Container Boxs -->
                        <div class="IncidentContainerBox noNum">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="grid-margin stretch-card">
                                        <div class="card activeCard">
                                            <div class="card-body">
                                                <div v-b-toggle="'signaList' + itemProbCode + ' signaListBanner' + itemProbCode"
                                                    class="d-flex align-items-start justify-content-between"
                                                >
                                                    <h6 class="card-title flex-grow lower-line text-truncate">{{$t('modalAddSignature.sign', {'label': ""})}}</h6>
                                                </div>
                                                <b-collapse :id="'signaListBanner' + itemProbCode" class="small-info ml-2" visible><small>{{$t('treatmentplanList.cliTiteltext')}}</small></b-collapse>
                                                <b-collapse :id="'signaList' + itemProbCode">
                                                    <div v-for="(s, sindex) in TreatmentPlan.signatures" :key="sindex">
                                                        <b-card bg-variant="light" class="mb-3">
                                                            <div>
                                                                <b-row>
                                                                    <div class="float-left col-md-3 text-muted p-item">
                                                                        <small>{{$t('modalProviderSelector.Provider')}}</small><br>
                                                                        <b v-if="s.therapistName">{{ s.therapistName }}</b>
                                                                        <b v-else>{{$t('treatmentplanList.nProvider')}}</b>
                                                                        <br>
                                                                        <span v-if="s.therapistName">{{ s.signedDate }}</span>
                                                                    </div>

                                                                    <div class="float-left col-md-3 text-muted p-item">
                                                                        <small>{{$t('treatmentplanList.CoProvider')}}</small><br>
                                                                        <b v-if="s.coTherapistName && s.coTherapistName.trim() !== ''">{{ s.coTherapistName }}</b>
                                                                        <b v-else>{{$t('treatmentplanList.nCoProSign')}}</b>
                                                                        <br>
                                                                        <span v-if="s.coTherapistName">{{ s.coSignedDate }}</span>
                                                                    </div>

                                                                    <div class="float-left col-md-3 text-muted p-item">
                                                                        <small>{{$tc('Shared.Patient')}}</small><br>
                                                                        <b v-if="s.patientName !== '' && s.refuseSigned === false">{{ s.patientName }}</b>
                                                                        <b v-if="s.refuseSigned">{{$t('modalAddSignature.pRefused')}}</b>
                                                                        <b v-if="s.refuseSigned === false && s.patientName === ''">{{$t('treatmentplanList.nPaSign')}}</b>
                                                                    
                                                                        <br>
                                                                        <span v-if="s.patientSignedDate">{{ s.patientSignedDate }}</span>
                                                                    </div>

                                                                    <div class="float-left col-md-3 text-muted p-item">
                                                                        <small>{{$t('modalAddSignature.guardian')}}</small><br>
                                                                        <b v-if="s.guardianName">{{ s.guardianName }}</b>
                                                                        <b v-else>{{$t('treatmentplanList.nGaSign')}}</b>
                                                                        <br>
                                                                        <span v-if="s.guardianName">{{ s.guardianSignedDate }}</span>
                                                                    </div>
                                                                </b-row>
                                                            </div>
                                                        </b-card>
                                                    </div>
                                                </b-collapse>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ObjectiveReviewRep 
            v-if="isObjectiveRepVisible" 
            :closeWindow="closeParamRepWindow" 
            :recNo="this.item.admissionId"
            :objRecId="ObjRecId" />

        <ProblemReviewRep 
            v-if="isProblemRepVisible" 
            :closeWindow="closeParamRepWindow" 
            :recNo="this.item.admissionId"
            :problemRecId="ProblemRecId"/>
    
        <TreatmentPlanProblemRep 
            v-if="isTreatmentPlanProblemRepVisible" 
            :closeWindow="closeParamRepWindow" 
            :recNo="this.item.admissionId"
            :notesId="this.item.notesId" 
            :probRecId="ProblemRecId" 
            :version="'0'" />
    </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import LocalStorage from '@/services/storage.service'
import Utils from '@/common/utils'
import TreatmentPlanService from '@/services/treatmentplan.service'
import ShowMessage from '@/components/messages/ShowMessage.js';
import DBFunctions from '@/common/DBFunctions.js'

export default {
    name: 'TreatmentPlanChartReview',
    mixins: [DBFunctions, ShowMessage],
    props: {
        item: Object,
    },

    data: function() {
        return { 
            icon:"ikon med-doc-complete mr-2",
            isReadOnly: true,
            UserId: LocalStorage.getUserId(),
            TreatmentPlan: null,
            ProblemRecId: null,
            ObjRecId: null,
            isProblemRepVisible: false,
            isTreatmentPlanProblemRepVisible: false,
            isObjectiveRepVisible: false
        }
    },


    computed: {
        title() {
            return Utils.formatterDateToShortLocaleString(this.item.transDate) + " - " + this.item.description;
        },

        itemProbCode() {
            return this.item.transCode;
        }
    },

    methods: {
        async Close() {   
            this.$emit('remove');
            EventBus.$emit('CloseDocument');
        },

        async getProblems() {
            await TreatmentPlanService
                .getProblemsByAdmId(this.item.admissionId, this.item.notesId, null, null, this.item.transCode)
                .then((response) => {
                    if (response.status == '200' && response.data) {
                        this.TreatmentPlan = response.data;
                        this.FormatTreatmentPlanDates(this.TreatmentPlan);
                    }
                    EventBus.$emit('ShowWeel', false); 
                })
                .catch(error => {
                    EventBus.$emit('ShowWeel', false); 
                    this.showResponseErrorMessage(error, this.$t('treatmentplan.Treatmentplan'));  
                });
        },

        FormatTreatmentPlanDates(arr) {    
            arr.problems.forEach(function (item) {
                if (item.problem.dateTimeCreate)
                    item.problem.dateTimeCreate = Utils.formatterDateToString(item.problem.dateTimeCreate);
                if (item.problem.probDate)
                    item.problem.probDate = Utils.formatterDateToString(item.problem.probDate);

                item.goals.forEach(function (g) {
                    if (g.goal.dateAchieve)
                        g.goal.dateAchieve = Utils.formatterDateToString(g.goal.dateAchieve);
                    if (g.goal.dateExp)
                        g.goal.dateExp = Utils.formatterDateToString(g.goal.dateExp);
                    if (g.goal.dateSet)
                        g.goal.dateSet = Utils.formatterDateToString(g.goal.dateSet);
                    if (g.goal.dateTimeCreate)
                        g.goal.dateTimeCreate = Utils.formatterDateToString(g.goal.dateTimeCreate);

                    g.goal.statusobj = { code: g.goal.status, description: g.goal.status };

                    g.objectives.forEach(function (o) {
                        if (o.objective.dateAchieve)
                            o.objective.dateAchieve = Utils.formatterDateToString(o.objective.dateAchieve);
                        if (o.objective.dateExp)
                            o.objective.dateExp = Utils.formatterDateToString(o.objective.dateExp);
                        if (o.objective.dateSet)
                            o.objective.dateSet = Utils.formatterDateToString(o.objective.dateSet);
                        if (o.objective.dateTimeCreate)
                            o.objective.dateTimeCreate = Utils.formatterDateToString(o.objective.dateTimeCreate);


                        o.interventions.forEach(function (i) {
                            if (i.dateTimeCreate)
                                i.dateTimeCreate = Utils.formatterDateToString(i.dateTimeCreate);
                            if (i.signedDate)
                                i.signedDate = Utils.formatterDateToString(i.signedDate);
                            if (i.startDate)
                                i.startDate = Utils.formatterDateToString(i.startDate);
                        })
                    })
                })
            });

            arr.signatures.forEach(function (sig) {
                if (sig.coSignedDate)
                    sig.coSignedDate = Utils.formatterDateToString(sig.coSignedDate);
                if (sig.patientSignedDate)
                    sig.patientSignedDate = Utils.formatterDateToString(sig.patientSignedDate);
                if (sig.signedDate)
                    sig.signedDate = Utils.formatterDateToString(sig.signedDate);

                if (sig.guardianSignedDate)
                    sig.guardianSignedDate = Utils.formatterDateToString(sig.guardianSignedDate);

                if (sig.isPatientSigned === true) {
                    sig.CheckRefuseReadOnly = true;
                    sig.CheckGuardianReadOnly = true;
                    sig.SelectGuardianReadOnly = true;
                } else {
                    sig.CheckRefuseReadOnly = false;
                    sig.CheckGuardianReadOnly = false;
                    sig.SelectGuardianReadOnly = false;
                }
                sig.ProviderModel = { therapistCode: sig.therapistCode, therapistName: sig.therapistName };
                sig.CoProviderModel = { coTherapistCode: sig.coTherapistCode, coTherapistName: sig.coTherapistName };
            });
        },

        PrintObjective(o){
            this.ObjRecId = o.objective.objRecId
            this.isObjectiveRepVisible = true
        },

        PrintProblem(item){
            this.ProblemRecId = item.problem.probRecId
            this.isProblemRepVisible = true
        },
        
        PrintOnlyProblem(item){
            this.ProblemRecId = item.problem.probRecId
            this.isTreatmentPlanProblemRepVisible = true
        },

        closeParamRepWindow() {
            this.isReportVisible = false;
            this.isObjectiveRepVisible = false;
            this.isProblemRepVisible = false;
            this.isTreatmentPlanProblemRepVisible = false
        },
    },

    mounted() {
        EventBus.$emit('ShowWeel', true); 
        this.getProblems();
    }
}
</script>