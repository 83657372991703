<template>
    <div id="diagnosis">
        <div class="row">
            <div class="col-12">
                <MdTitleRegInfo
                    v-bind:RegistrationInfo="PatientTitle" 
                    v-bind:titlePrefix="$t('Diagnosis.PatientDiagnosis')" 
                    v-bind:IsClinical="IsClinical"
                    v-bind:titleRef="'titleElementDiagnosiss'"
                    v-bind:patientCode="this.$route.params.id === undefined ? this.PatientCode :this.$route.params.id">
                </MdTitleRegInfo>
                
                <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
                    <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                    <div class="flex-grow-1">
                        <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
                    </div>
                </div>
            </div>
        </div>
        <p class="mx-3 text-muted" v-if="this.items.length > 0">
            {{ $t('Diagnosis.HasDiagnostic',{fullName: this.PatientTitle.fullName}) }}
        </p>
        <p class="mx-3 text-muted" v-if="this.items.length == 0">
            {{ $t('Diagnosis.NoDiagnostic',{fullName: this.PatientTitle.fullName}) }}
        </p>
        <div class="row">
            <div class="col-md-12">
                <div v-for="(i,index) in this.items" :key="index">                  
                 <MdTitle @ClickEdit="OpenModal(i)" v-bind:VisibleEdit="i.VisibleEdit && !isReadOnly" v-bind:Text="i.title" v-bind:isSubtitle="true" v-bind:Name="i.programName"/>
                 <table class="diagTable mx-2">
                    <tr v-for="(v,index) in i.diagnoses" :key="index">
                        <td><span class="diagCode">{{v.code}}</span></td>
                        <td>{{v.description}}</td>
                    </tr>
                    <tr v-if="i.notes">
                        <td class="align-top"><span class="diagCode">{{$t('Shared.Notes')}}</span></td>
                        <td>{{i.notes}}</td>
                    </tr>
                 </table>
                </div>
            </div>
        </div>

        <ModalDiagnosis
            v-bind:TitleWindow="this.$t('MdPatientDiagCodes.Diagnoses')"
            v-bind:ModalId="this.modalId"           
        />

    </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import EnumService from '@/services/enum.service'
import AdmissionService from '@/services/admission.service'
import PatientService from '@/services/patient.service'  
import Utils from '../../../common/utils'
import { EventBus } from '@/event-bus.js';

export default {
 mixins: [ShowMessage, DBFunctions],
  name: 'Diagnosis',
  props:{
    PatientCode:String
  },

  data(){
      return{
        IsClinical:false,
        infoList:[],
       modalId:"modalDiagnosis",
       RegistrationInfo:{},
       AdmissionSelected:{},
       BaseFields:[{ key: 'name', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
       isReadOnly:false,
       items:[],
       PatientTitle:{      
            programName:'',
            fullName:'',
            admitDate:'',
            dischargeDate:'',
            dob:'',
            activitytypename:'',
            startdatetime:'',
            ageFormatted:''
        },
        OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },
        currentAppointmentDB:{
            guid:'',
            activitytypecode:'',
            employee:{
                firstname:"",
                lastname:""
            }
        },  
      }
  },
  methods: {

    OpenModal(i){
        EventBus.$emit('onOpenModalDiagnosis', i);
        this.$bvModal.show(this.modalId);         
    },

    UpdateDiagnose(data){       
        AdmissionService.DeleteAndInsert(data)
                .then((response) => {
                    if(response.status == '200'){
                        this.getDiagnoses();
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('MdPatientDiagnosis.Diagnosis'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                           
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage('Error',error,"error");
                    }
                });  
    },

    async GetPatientByCode(){
      this.infoList = await this.getInfoTitle(this.$route.params.id === undefined ? this.PatientCode : this.$route.params.id);
      if(this.infoList.length > 0){
        this.PatientTitle.programName = '';
        this.PatientTitle.admitDate = '';
        this.PatientTitle.dischargeDate = '';

        const { dob, firstname, lastname, age, ageFormatted } = this.infoList[0]; // Destructure for easier access
        if (age) this.PatientTitle.age =  age === 0 ? "0" : age;
        if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
        if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
        if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;            
      }
    },

         formatterDateToString(date){
            return Utils.formatterDateToString(date);
        },

    getDiagnoses(){            
             PatientService.getDiagnoses(this.$route.params.id === undefined ? this.PatientCode : this.$route.params.id)
                .then((response) => {
                     if(response.status == '200' && response.data){
                        this.$emit('load', false);
                        this.items= response.data;                    
                            this.items.forEach(element => {                     
                                element.dateFrom = element.dateFrom ? this.formatterDateToString(element.dateFrom) : "";
                                element.dateTo = element.dateTo ? this.formatterDateToString(element.dateTo) :  this.$t('Shared.Active');
                                                         
                              if(element.programName !== this.$t('Diagnosis.CurrentDiagnosis')){
                                element.title = this.$t('MdPatientDiagnosis.Diagnosis')+" - "+element.programName;                                  
                                element.VisibleEdit = true;
                                if(element.dateFrom){
                                    element.title += ' - ' + element.dateFrom;
                                } 
                                if(element.dateTo){
                                    element.title += ' - ' + element.dateTo;
                                } 
                              }else{
                                element.title = element.programName; 
                                element.VisibleEdit = false;
                              }
                             
                            });
                     }             
                })
                .catch((error) => {            
                     if(error.response.status === 400 ){
                        this.showMessage(this.$t('MdPatientDiagnosis.Diagnosis'),error.response.data.errorMessage,"error");
                    }else{
                        this.showMessage(this.$t('MdPatientDiagnosis.Diagnosis'),error,"error");
                    }  
                    this.$emit('load', false);
                });  
             },
             
    PopulatePatientTitle(){
        const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access

		if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
		if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	

        if(this.$route.name.includes('Clinical-diagnosis')){
            if (programName) this.PatientTitle.programName = programName; 
            if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
            if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
            if (patientName) this.PatientTitle.fullName = patientName;
            if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
            if (age) this.PatientTitle.age = age === 0 ? "0" : age;
            if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
        }

        if(this.$route.name.includes('patient-diagnosis') || this.$route.name.includes('appointments-diagnosis')){  
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';

          if(this.infoList.length > 0){
            const { dob, firstname, lastname } = this.infoList[0]; // Destructure for easier access
            if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
            if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;            
          }
          
        }
    },
    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }
  },
  async mounted() {
    this.$emit('load', true);  
    this.OptionsRightMenu.visible = true;      
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-diagnosis') ? EnumService.ClinicalItems : this.$route.name.includes('appointments-diagnosis') ? EnumService.AppointmentItems : EnumService.PatientItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);   
    this.getDiagnoses();     
    this.isReadOnly = this.$store.state.isPatientDiagnosisEHRReadOnly;    
    if(this.$route.name.includes('patient-diagnosis') || this.$route.name.includes('appointments-diagnosis')){ 
        this.GetPatientByCode();        
     } 

    if(this.$route.name.includes('Clinical-diagnosis')){  
        
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
        const { dateTo, age } = this.RegistrationInfo; // Destructure for easier access

		if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;        
        this.IsClinical = dateTo ? false : true;
    }

    this.PopulatePatientTitle();

    if (this.$route.name.includes('appointments-diagnosis'))
      await this.getCurrentAppointment()

  },
  created(){
    EventBus.$on("onSaveDiagnoses", function (obj) {        
        this.UpdateDiagnose(obj);        
    }.bind(this));
  },

  beforeDestroy () {     
        EventBus.$off('onSaveDiagnoses');       
    },
   
};
</script>


