<template>
    <div>
      <MdTitle v-bind:Text="$t('Nurses.PatientMars')" />

      <div class="flex-container">
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('dcs.month')"
              v-bind:Options="MonthList"
              v-bind:ModelArray="month"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'value'"
              v-bind:Multiple="false"
              v-bind:RequireEvent="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownMonths"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('dcs.year')"
              v-bind:Options="YearList"
              v-bind:ModelArray="year"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'value'"
              v-bind:Multiple="false"
              v-bind:RequireEvent="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownYear"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Program',2)"
              v-bind:Options="ProgramList"
              v-bind:Model="ProgramVmodel"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Placeholder="selectPlaceholder"
              v-bind:Multiple="false"
              v-bind:RequireEvent="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownPrograms"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="PatientList"
              v-bind:Model="Patients"
              v-bind:FieldDescription="'patientname'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Multiple="false"                       
              :Input="ChangeDropDownPatients"/> 
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.Building')"
              v-bind:Options="BuildinsList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="buildings"   
              v-bind:Multiple="false"
              :Input="ChangeDropDownBuilding"/>
          </b-col>
          <b-col md="3" lg="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.Level')"
              v-bind:Options="LevelList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="level"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownLevel"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.Room')"
              v-bind:Options="RoomList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'" 
              v-bind:UseResetModel="true"                 
              v-bind:Model="room"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownRoom"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('modalMarsMed.Mode')"
              v-bind:Options="ModeList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'" 
              v-bind:UseResetModel="true"                 
              v-bind:ModelArray="mode"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownMode"/>
          </b-col>
        </b-row>
      </div>

       <MdMarsViewBox v-bind:Items="this.items"/>
       <div class="space-actions"></div>
        <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Back')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="reportList"
            v-bind:Name="'btn_ReportList'"/>
          <MdButton
            v-bind:Label="$t('Mars.ViewData')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClickViewData"
            v-bind:Name="'btn_Print'"/>   
          <MdButton
            v-bind:Label="$t('Shared.Report')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClickShowReport"
            v-bind:Name="'btn_Print'"/>   
        </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="reportTitle"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="Mars"
          :ReportName="'MarsRep'"
          :ReportTitle="reportTitle"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import Utils from '../../../common/utils'
    import DBFunctions from '@/common/DBFunctions.js'
    import ClinicalService from '@/services/clinical.service'
    import PatientService from '@/services/patient.service'
    import AdmissionService from '@/services/admission.service'
    import GlobalService from '@/services/global.service'
    import MarsService from '@/services/mars.service' 
    import EnumService from "@/services/enum.service";

    export default Vue.extend({
        name: "PatientMarsRep",
        mixins: [ ShowMessage, Utils, DBFunctions ],
        components: { DxPopup },
        data() {
            return {
                isReportVisible: false,
                reportParams: [],
                showFilters: true,
                ProgramVmodel: [],
                ProgramList: [],
                Patients: "",
                PatientList: [],
                buildings:"",
                BuildinsList:[],
                level:"",
                LevelList:[],
                room:"",
                RoomList:[],
                month:{},
                MonthList: EnumService.Months,
                year: {'name':new Date().getFullYear(),'value': new Date().getFullYear()},
                YearList: [],
                mode:{},
                ModeList: EnumService.MarsMode,
                parameter:{  
                  Month: null,
                  Year: null,
                  PatientList:[],
                  LevelList:[],
                  BuildingList:[],
                  RoomList:[],
                  ProgramList:[],
                  SearchCriteria:null,
                  PageSize:10,
                  PageNumber:1
                },
                selectPlaceholder: this.$t('Clinical.SelectOptions'),
                reportTitle: this.$t('Nurses.PatientMars'),

                fullParam: {
                  PageSize:0,
                  PageNumber:0
                },
                items: []
            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.isReportVisible = false
            },

            reportList() {
              this.checkExistFavorite('/app/reports/patients');
              this.$router.push({ name: "reportListPatient" }).catch(()=>{});
            },

            onClickShowReport() {
              this.reportParams = []

              this.reportParams.push({ paramName: 'dateFrom', paramValue: this.dateFrom})

              this.isReportVisible = true
              this.$refs.Mars.buildDocument(this.reportParams)
            },

          async ChangeDropDownPrograms(item){ 
              this.parameter.ProgramList = [];      
              item ? this.parameter.ProgramList.push(item.code) : this.parameter.ProgramList = [];
          },

          ChangeDropDownPatients(item){
              this.parameter.PatientList = [];
              item ? this.parameter.PatientList.push(item.patientcode) : this.parameter.PatientList = [];
          },

          async ChangeDropDownBuilding(item){
              this.parameter.BuildingList = [];
              item ? this.parameter.BuildingList.push(item.code) : this.parameter.BuildingList = [];
 
              await this.getRomms({BuildingCodeList:this.parameter.BuildingList});
              this.parameter.RoomList = [];
              this.room ? this.parameter.RoomList.push(this.room) : this.parameter.RoomList;
          }, 

          async ChangeDropDownLevel(item){
              this.parameter.LevelList = [];
              item ? this.parameter.LevelList.push(item.code) : this.parameter.LevelList = [];
          },

          ChangeDropDownRoom(item){
              this.parameter.RoomList = [];
              item ? this.parameter.RoomList.push(item.code) : this.parameter.RoomList = [];
          },

          ChangeDropDownMonths(item) {
              this.parameter.Month = null;
              item ? this.parameter.Month = item.value : this.parameter.Month = null;
          },

          ChangeDropDownYear(item) {
              this.parameter.Year = null;
              item ? this.parameter.Year = item.value : this.parameter.Year = null;
          },

          ChangeDropDownMode(item) {
              this.parameter.Mode = null;
              item ? this.parameter.Mode = item.value : this.parameter.Mode = null;
          },

          async getRomms(build){       
            await MarsService.getMarsRooms(build)
              .then((response) => {
                if(response.status == '200' && response.data.length > 0){                         
                  this.RoomList = response.data;
                  if(this.RoomList.length === 1){
                    this.room = this.RoomList[0].code;
                  }
                }                             
              })
              .catch((error) => {
                if (error.response) {
                  this.showMessage(this.$t('Admission.AdmissionSource'),error,"error");
                }
              }); 
          },

          async getLevel(){
            let param = { CodeType:"RoomLevel" };
            await GlobalService.getGlobalCustom(param)
              .then((response) => {
                if(response.status == '200' && response.data.length > 0){                          
                  this.LevelList = response.data;
                }                             
              })
              .catch((error) => {
                if (error.response) {
                  this.showMessage(this.$t('Admission.AdmissionSource'),error,"error");
                }
              }); 
          },

          async getPrograms(){
            await ClinicalService.getPrograms()
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){                        
                  this.ProgramList = response.data;                                          
                }               
              })
              .catch((error) => {
                if (error.response) {
                  this.showMessage(this.$t('Clinical.ClinicalProgramFilter'),error,"error");
                }
              });  
          },

          async getPatients(){   
            await PatientService.getAll(this.fullParam)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0) {               
                  this.PatientList = response.data;                          
                    this.PatientList.forEach(function(element) {	
                      element.patientname = element.firstname + " " + element.lastname + " - "+element.patientcode;   
                      });       
                  }       
              })
              .catch(error => {      
                if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.retrieveInfo'),"error");
                } else {
                  this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                }
              });    
          },

          async getBuildings(){
            await AdmissionService.getBuildings()
              .then((response) => {
                if(response.status == '200'){                       
                  this.BuildinsList = response.data;                       
                }                             
              })
              .catch((error) => {
                if (error.response) {
                  this.showMessage(this.$t('Admission.AdmissionBuildings'),error,"error");
                }
              });
          },

          getYears() {
            const currentYear = new Date().getFullYear();
            const ayears = Array.from({ length: 100 }, (_, i) =>  currentYear - i);
            let years = []

            ayears.forEach(year => {
              years.push({'name': year, 'value': year})
            });

            this.parameter.Year = currentYear
            this.YearList = years
          },
          
          getCurrMonth() {
            const aCurrMonth = new Date().getMonth() + 1;
            const currmonth = this.MonthList.find(el => el.value == aCurrMonth)
            
            this.parameter.Month = aCurrMonth
            this.month = currmonth
          },

          setDefaultMode() {
            const defaultMode = this.ModeList.find(el => el.value == 'all')
            
            this.parameter.Mode = defaultMode.value
            this.mode = defaultMode
          },

          onClickViewData() {  
            let param = {  
                Month: this.parameter.Month,             
                Year: this.parameter.Year, 
            };
            this.$emit('load', true);      

            MarsService.repViewPatientMars(param)
              .then((response) => {
                if(response.status == '200' && response.data){
                  this.items = response.data
                  this.$emit('load', false);  
                }  
                          
              })
              .catch((error) => {                   
                this.$emit('load', false);               
                  if (!error.response) {
                    this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.retrieveInfo'),"error");
                  }                      
                  else if(error.response.status === 400 ){
                    this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                  }                    
                  else if(error.response.status === 500){
                    this.showMessage(this.$t('Clinical.ClinicalListing'),this.$t('Msg.ThereWereSomeError'),"error");
                  } else{
                    this.showMessage(this.$t('Clinical.ClinicalListing'),error.response.data.errorMessage,"error");
                  } 
                            
              }); 
          },
      },

        
        async created(){
        },
        
        mounted () {
          this.getYears()
          this.getCurrMonth()
          this.setDefaultMode()
          this.getPrograms(),
          this.getPatients()
          this.getBuildings()
          this.getLevel()
          this.StatusModel = {"value":"ACTIVE","name":"Active"}
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
