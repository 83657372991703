<template>
   <b-modal size="xl" id="bv-modal-preferences" 
      :title="$t('UserPreferences.PrefTitle')"
      :no-close-on-esc="true" 
      :no-close-on-backdrop="true"
      @shown="onShown"
      style="text-align: -webkit-center"
      height="100%"
      @close="onClose"
     >
     
    <Loading v-bind:IsLoading="this.Loading" />
    <div class="preferences">
     
      <b-card no-body class="mx-2">
        <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false">
          <div class="tab-content tab-content-no-padding tab-content-custom-pill" id="pills-tabContent-custom">
            <div class="tab-pane fade show active" id="general" role="tabpanel">
              <b-card-body>
                <!-- <b-row>
                  <b-col>
                    <b-form-checkbox :disabled="true" v-model="checked" name="leftMenu" switch>
                      Show left menu on start.
                    </b-form-checkbox>
                  </b-col>
                </b-row> -->
                <b-row  v-if="providerList && providerList.length > 1">
                  <b-col>
                    <b-dropdown aria-role="list"  v-bind:text="$t('UserPreferences.Dp') + (selectedProvider.code == 0 
                                                  ? $t('UserPreferences.Ns') 
                                                  : selectedProvider.code + ' - ' + selectedProvider.company)" 
                                variant="link" 
                                class="ml-2 mb-2"
                                toggle-class="text-decoration-none" 
                                no-caret>
                      <b-dropdown-header>{{ $t('UserPreferences.PROVIDERS') }}</b-dropdown-header>
                      <div role="separator" class="dropdown-divider"></div>
                      <b-dropdown-item @click="setItem('prov', noSelectedPro, '0')">{{ $t('UserPreferences.Ns') }}</b-dropdown-item>
                      <b-dropdown-item aria-role="list" @click="setItem('prov', provider, provider.code)" 
                                      v-for="(provider, key) in providerList" :key="key">
                        {{ provider.code }} -
                        {{ provider.company }}  
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-dropdown aria-role="list"  v-bind:text=" $t('UserPreferences.Dl') + (selectedLan.value == null
                                                  ?  $t('UserPreferences.Ns') 
                                                  : selectedLan.title)" 
                                variant="link" 
                                class="ml-2 mb-2"
                                toggle-class="text-decoration-none" 
                                no-caret>
                      <b-dropdown-header>{{ $t('UserPreferences.LANGUAGES') }}</b-dropdown-header>
                      <div role="separator" class="dropdown-divider"></div>
                      <b-dropdown-item @click="setItem('lang', noSelectedLan, true)">{{ $t('UserPreferences.Ns') }}</b-dropdown-item>
                      <b-dropdown-item aria-role="list" @click="setItem('lang', lang, true)" 
                                      v-for="(lang, key) in lanList" :key="key">
                        {{ lang.title }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-col>
                </b-row>
                <!-- <b-row>
                  <b-col>
                    <b-dropdown aria-role="list"  v-bind:text="$t('UserPreferences.Df') + (selectedDateFormat.value == '' 
                                                  ? $t('UserPreferences.Ns') 
                                                  : selectedDateFormat.dateFormat)" 
                                variant="link" 
                                class="ml-2 mb-2"
                                toggle-class="text-decoration-none" 
                                no-caret>
                    <b-dropdown-header>{{ $t('UserPreferences.dFormats') }}</b-dropdown-header>
                    <div role="separator" class="dropdown-divider"></div>
                    <b-dropdown-item @click="setItem('format', noSelectedDateFormat, true)">{{ $t('UserPreferences.Ns') }}</b-dropdown-item>
                    <b-dropdown-item aria-role="list" @click="setItem('format', format, dateFormat.value)" 
                                    v-for="(format, key) in dateFormatList" :key="key">
                      {{ format.dateFormat }}
                    </b-dropdown-item>
                  </b-dropdown>
                  </b-col>
                </b-row>                 -->
                <b-row>
                  <b-col>
                    <b-form-checkbox v-model="fullScreenMode" name="fullScreen" @change="UpdateFullScreenStatus($event)" switch>
                      {{ $t('UserPreferences.ShowFullScreen') }}
                      <!--
                          Note: Internet Explorer 10 and earlier versions do not support fullscreen mode.
                      -->
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-checkbox v-model="showTimeTaskbar" name="showTime"  @change="ShowTimeTaskbar($event)" switch>
                     {{ $t('UserPreferences.ShowTime') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row class="ml-2">
                  <b-col>
                    <b-form-checkbox :disabled="!showTimeTaskbar" @change="ChangeTimeFormat($event)" v-model="timeFormat" name="24Hours" switch>
                      {{ $t('UserPreferences.TimeFormat') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
            <div class="tab-pane fade" id="homePage" role="tabpanel">
              <b-card-body>
                <b-row aria-role="list" v-for="(box, key) in userBoxesList " :key="key">
                  <b-col>
                    <b-form-checkbox @change="UpdateUserBoxStatus($event, box)" v-model="box.check" name="leftMenu" switch>
                    {{ $t('Shared.Show') }} {{ $t(`UserPreferences.`+ box.description) }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row v-if="userBoxesList.length == 0">
                  <div  style="font-size:larger" class="text-muted">
                    <b>PUT SOMETHING HERE WHEN NO DATA .... ?</b>
                  </div>
                </b-row>
              </b-card-body>
            </div>
            <!-- Prefereces Favorites (If we decide to use it in the furure) 
            Change v-if value from 'false' to this.shown
            -->
            <div class="tab-pane fade" id="contentFavorites" role="tabpanel">
              <PreferecesFavorites v-if="false"/>
            </div>
            <!-- End -->
          </div>
        </MdTabList>
      </b-card>
    </div>
    <div slot="modal-footer">
      <b-row>
        <b-col style="text-align: end;">
          <MdButton
            v-bind:Label="$t('UserPreferences.Close')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClose"
            v-bind:Name="'preferences_btn_close'"/>
          <!-- <b-btn id="preferences_btn_close" variant="primary" 
                @click="onClose">
            {{ $t('UserPreferences.Close') }}
          </b-btn> -->
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>

import StorageService from '@/services/storage.service'
import Loading from '@/components/template/loading/Loading'
import UserPreferences from '@/services/userpreferences.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
 /** Uncommnet If we decide to use Prefereces Favorites */
// import PreferecesFavorites from "@/components/template/favorites/PreferecesFavorites";

export default {
  components: { 
    Loading,
     /** Uncommnet If we decide to use Prefereces Favorites */
    // PreferecesFavorites
  },
  name: 'UserPreferences',
  mixins: [ShowMessage, DBFunctions],  
  data() {
      return {  
       Loading:false,
       timeFormat: false,
       showTimeTaskbar: false,
       fullScreenMode: false,
       UserId: StorageService.getUserId(), 
       FullScreenPreference: {'FullScreenStatus':false},
       TimeTaskbarPreference: {'ShowTimeTaskbar':false},
       TimeFormatPreference: {'TimeFormat':false},
       ProviderCodePreference: {'ProviderCode':''},
       LocalePreference: {'Locale':''},
       DateFormatPreference: {'DateFormat':''},
       tablist: [
            { title: this.$t('Shared.General'), link: '#general', active: true},
            { title: this.$t('UserPreferences.TabHome'), link: '#homePage'},
            /** Uncommnet If we decide to use Prefereces Favorites */
            // { title: this.$t('Layout.Favorites.title'), link: '#contentFavorites'}
        ],
        providerList:[],
        selectedProvider: {},
        selectedLan: {},
        selectedDateFormat: {},
        noSelectedLan: { value: null, title:'', dateFormat: null},
        noSelectedPro: { code: 0, company:'company_0'},
        noSelectedDateFormat: { value: null, dateFormat: null},
        lanList:[
          { value:'en', title:  this.$t('UserPreferences.en'), dateFormat:'MM/DD/YYYY'},
          { value:'es', title:  this.$t('UserPreferences.es'), dateFormat:'DD/MM/YYYY'}
        ] ,
        dateFormatList:[
          { value:'MM/DD/YYYY', dateFormat:'MM/DD/YYYY' },
          { value:'DD/MM/YYYY', dateFormat:'DD/MM/YYYY' }
        ],
        mDateNow: '',
        userBoxesList:[],
        selected: [],
        shown: false,
        orderChange:false,
        lanChange: false,
        dFormatChange: false
      }
  },
  watch:{
    fullScreenMode: function(){
      if(this.fullScreenMode == true){
        this.openFullscreen();
      }
      else{
        this.closeFullscreen();
      }
    }
  },
  methods: {

    reloadPageAfterChange() {
      window.location.reload();
    },

    setItem (opt, obj, upd) {
      switch (opt) {
        case 'lang':
            if(this.selectedLan.value != obj.value ){
              this.selectedLan = obj;
              this.$i18n.locale = obj.value;
              if( upd ) { 
                this.UpdateLocalePreference(obj);
                this.setItem ('format', obj, true)
                this.lanChange = true;
              }
            }
            break;
        case 'prov':
            if(this.selectedProvider.code != obj.code){           
              this.selectedProvider = obj;
              if( upd ) {
                this.UpdateProviderCodeStatus(upd === 0 ? '' : upd);
              }           
            }
            break;
        case 'format':
              this.selectedDateFormat = { 
                value: obj.dateFormat !== null ? obj.dateFormat : null, 
                format: obj.dateFormat !== null ? obj.dateFormat : null
              };
              if( upd ) {
                  this.ChangeDateFormat(obj)
                  this.dFormatChange = true;
              }         
            break;
        }
    },

    UpdateFullScreenStatus(value){
      this.FullScreenPreference.FullScreenStatus = this.statusToBoolean(value);

      UserPreferences.UpdateFullScreenStatus(this.UserId, this.FullScreenPreference)
      .then((response) => {
            if (response.status == '200') {
              setTimeout(() => 
                  this.$bvToast.toast(this.$t('UserPreferences.AcctionB'), {
                  title: this.$t('UserPreferences.UPTitle'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                  , 50); 
            }
          })
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('UserPreferences.UPTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },
    UpdateLocalePreference(obj){
      this.LocalePreference.Locale = obj.value;
      localStorage.setItem('language', obj.value)
   
      UserPreferences.UpdateLocalePreference(this.UserId, this.LocalePreference)
        .then((response) => {
            if (response.status == '200') {
              UserPreferences.getUserRegionalFormat(this.UserId)
                .then((response) => { 
                  var dataResponse = response.data;
                  if( obj.value == null){
                    localStorage.setItem('language', dataResponse.language)
                    localStorage.setItem('dateFormat', dataResponse.dateFormat);
                  }
                })

              setTimeout(() => 
                  this.$bvToast.toast(this.$t('UserPreferences.AcctionLanMsg'), {
                  title: this.$t('UserPreferences.UPTitle'),
                    autoHideDelay: 2000,
                    variant: "success"
                  })
                  , 50); 
            }
        })
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('UserPreferences.UPTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('UserPreferences.UPTitle'), this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },
    statusToBoolean: function(string){
      switch(string){
          case "true": case true: return true;
          case "false": case false:  case null: return false;
          default: return Boolean(string);
      }
    },
    async getUserPreference(){
        const userid = StorageService.getUserId();
        
        await UserPreferences.getUserPreference(userid)
          .then((response) => { 
            if(response.data && response.data.length > 0){
              /* preferences fullScreenMode */
              let fullScreenStatus = response.data[0].fullScreenStatus ;
              this.fullScreenMode = fullScreenStatus == null ? false : fullScreenStatus

              /* preferences showTimeTaskbar */
              let showTimeTaskbar = response.data[0].showTimeTaskbar ;
              this.showTimeTaskbar = showTimeTaskbar == null ? false : showTimeTaskbar

              /* preferences Provider */
              let loadProvider = { code: 0, company:'company_0'};
              if( response.data[0].providerCode &&  response.data[0].company) {
                loadProvider = { code: response.data[0].providerCode, company:response.data[0].company}
                this.selectedProvider = loadProvider
                this.setItem('prov', loadProvider )
              } else { this.selectedProvider = loadProvider }
             
             
             /* preferences Language*/
              let loadLan = { value: null, title:''};
              if(response.data[0].locale && response.data[0].locale != null){
                  let lanFound = this.lanList.find(obj => {
                    return obj.value === response.data[0].locale
                  })
                  loadLan = { value: response.data[0].locale, title: lanFound.title}
                  this.selectedLan = loadLan 
                  this.setItem('lang', loadLan, false) 
              } else { this.selectedLan = loadLan } 

              /* preferences Date Format*/
              let loadDateFormat = { value: null, dateFormat: null};
              
              if(response.data[0].dateFormat) {

                let formatFound = this.dateFormatList.find(formatObj => {
                  return formatObj.value == response.data[0].dateFormat;
                });

                if(formatFound) {
                  loadDateFormat = { value: response.data[0].dateFormat, dateFormat: formatFound.format }
                  this.selectedDateFormat = loadDateFormat
                  this.setItem('format', loadDateFormat, false)
                } 
              } else { 
                  this.selectedDateFormat = loadDateFormat 
              } 
              
              /* preferences hoursFormat */
              if ( this.showTimeTaskbar ) {
                let timeFormat = response.data[0].timeFormat;
                this.timeFormat = timeFormat == null ? false : timeFormat;
                EventBus.$emit('m24Format', this.timeFormat); 
              } 
            }    
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error,"error");
            } 
          });  
      },
      async onShown(){
        const allPromise = Promise.all([this.getUserPreference(),this.getFullBoxesList()]);
        this.shown = true;
        allPromise.then(() => {
            this.getUserPreferenceWebBoxesVisible();
        });
        
      },
      UpdateProviderCodeStatus(provCode){
        this.ProviderCodePreference.ProviderCode = provCode;

        UserPreferences.UpdateProviderCodeStatus(this.UserId, this.ProviderCodePreference)
          .then((response) => {
            if (response.status == '200') {
              setTimeout(() => 
                  this.$bvToast.toast(this.$t('UserPreferences.DpMsg'), {
                  title: this.$t('UserPreferences.UPTitle'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                  , 50); 
            }
          })
          .catch((error) => {
            if (!error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
            }          
            else if (error.response.data && error.response.data.errorMessage) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error.response.data.errorMessage,"error");
            }
            else {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            }
          });
      },
      ShowTimeTaskbar(status){
        EventBus.$emit('showTimeTaskbar', status); 
        this.TimeTaskbarPreference.ShowTimeTaskbar = status;

        UserPreferences.UpdateShowTimePreference(this.UserId, this.TimeTaskbarPreference)
          .then((response) => {
              if (response.status == '200') {
                setTimeout(() => 
                    this.$bvToast.toast(this.$t('UserPreferences.AcctionClockMsg'), {
                    title: this.$t('UserPreferences.UPTitle'),
                      autoHideDelay: 5000,
                      variant: "success"
                    })
                  , 50); 
              }
            })
          .catch((error) => {
            if (!error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
            }          
            else if (error.response.data && error.response.data.errorMessage) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error.response.data.errorMessage,"error");
            }
            else {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            }
          });
      },

      ChangeTimeFormat(status){
        EventBus.$emit('m24Format', status); 
        this.TimeFormatPreference.TimeFormat = status;

        UserPreferences.ChangeTimeFormat(this.UserId, this.TimeFormatPreference)
          .then((response) => {
              if (response.status == '200') {
                setTimeout(() => 
                    this.$bvToast.toast(this.$t('UserPreferences.TFormatMsg'), {
                    title: this.$t('UserPreferences.UPTitle'),
                      autoHideDelay: 5000,
                      variant: "success"
                    })
                  , 50); 
              }
            })
          .catch((error) => {
            if (!error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
            }          
            else if (error.response.data && error.response.data.errorMessage) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error.response.data.errorMessage,"error");
            }
            else {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            }
          });
      },

      ChangeDateFormat(obj){
        this.DateFormatPreference.DateFormat = obj.dateFormat;
        localStorage.setItem('dateFormat', obj.dateFormat);
      },
       
      getFullBoxesList(){
    
        UserPreferences.getUserPreferenceWebBoxes()
          .then((response) => {
              if(response.data && response.data.length > 0){
                this.userBoxesList = response.data.map(box => {
                  let newDescription = box.description; // Valor inicial

                  switch (box.description) {
                    case "Today's Appointments":
                      newDescription = "AppointmentssAt";
                      break;
                    case "Today's Admissions":
                      newDescription = "AdmissionsAt";
                      break;
                    // Agrega más casos según sea necesario
                    default:
                      break; // Mantén la descripción original si no coincide con ningún caso
                  }
                  return { ...box, description: newDescription }; // Retorna una copia del objeto con la descripción actualizada.
                });
              }
          })
          .catch((error) => {
            if (!error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
            }          
            else if (error.response.data && error.response.data.errorMessage) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error.response.data.errorMessage,"error");
            }
            else {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            }
        });
      },
      getUserPreferenceWebBoxesVisible(){
        UserPreferences.getUserPreferenceWebBoxesVisible(this.UserId)
          .then((response) => {
              if(response.data && response.data.length > 0){
                const data = response.data,
                      boxes = this.userBoxesList,
                result = boxes.map(box => 
                  data.find(d => d.boxId == box.recId) 
                    ? { ...box, check : true, boxRecId: data.find(d => d.boxId == box.recId).recId } 
                    : box
                );
                if (result.length > 0) {
                  this.userBoxesList = result;
                }
              }
          })
          .catch((error) => {
            if (!error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
            }          
            else if (error.response.data && error.response.data.errorMessage) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error.response.data.errorMessage,"error");
            }
            else {
              this.showMessage(this.$t('UserPreferences.UPTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            }
        });
      },

      UpdateUserBoxStatus(value, _box){
        try {
          if(_box.boxRecId) {
              UserPreferences.deleteUserWebBox(_box.boxRecId)
          } else {
            let box = {
                userId: this.UserId,
                boxId: _box.recId
            }
            UserPreferences.addUserWebBox(box)
          }
        } catch(error) {
            this.showMessage(this.$t('UserPreferences.UPTitle'),error,"error");
        }
      },
      onClose(){
        this.$bvModal.hide('bv-modal-preferences')
        this.shown = false;
        if( this.orderChange ){
          setTimeout(() => 
              this.$bvToast.toast(this.$t('Layout.Favorites.oChange'), {
                title: this.$t('Layout.Favorites.title'),
                autoHideDelay: 5000,
                variant: "success"
              }), 50);
            this.orderChange = false;   
        }

        if( this.lanChange || this.dFormatChange ){
          setTimeout(() => 
              this.reloadPageAfterChange(), 50);
            this.lanChange = false; 
            this.dFormatChange = false;  
        }
      }
  },
  mounted() {
      this.providerList = this.$store.state.ProvidersList;
  },
  created() {

    /** Uncommnet If we decide to use Prefereces Favorites */
    /*EventBus.$on('orderChange', data => {
      this.orderChange = data
    });*/
  },
  beforeDestroy () {
     /** Uncommnet If we decide to use Prefereces Favorites */     
    // EventBus.$off('orderChange');
  },
}
</script>
<style>
#bv-modal-preferences___BV_modal_content_{
    max-height: 400px;
    max-width: 600px;
}
#bv-modal-preferences___BV_modal_body_{
  padding: 0;
}
#bv-modal-preferences{
    text-align: -webkit-center;
}
.modal-footer {
  padding: 5px 10px !important;
}
.modal-footer > div{
  width: 100%;
}
.preferences .dropdown button{
  color: #3e3c4f !important;
  font-weight: normal;
  padding: 0;
  left: -10px;
  
  border-radius: 5px;
  border: #19bee8 solid 0em;
  border-image: linear-gradient(to right, #19bee8, #FFF) 1 100%;
  padding-left: 0px; 

  background-image: 
  linear-gradient(127deg, #3e3c4f, #3e3c4f 50%, #19bee8 96%, #FFF 97%, #3e3c4f);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;  
  animation: link-animation 2000s linear infinite;
  transition: all 0.3s ease-in-out;
}
.preferences .dropdown button:hover{
  background-image: 
  linear-gradient(127deg, #19bee8, #19bee8);
  padding-left: 10px;  
  border-left: #19bee8 solid 1em;
}

@keyframes link-animation {
    to {
        background-position: 4500vh;
    }
}

.tab-content-no-padding {
  padding: 0 0 !important;
}

</style>