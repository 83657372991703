<template>
    <div class="notes several">
        <h4 class="note-title text-truncate" v-b-tooltip.hover >
            <span :class="icon"></span>
            <span> {{this.title}} </span> 
        </h4>

        <b-icon
            icon="x-circle-fill"
            v-on:click="Close"
            class="rounded-circle notes-close"
            scale=".5"
            font-scale="1.8"
            > <!-- create the CloseNote action. Open notes should be closed with the HIDDEN class -->
        </b-icon>

        <!-- QUICK ACCESS STICKY BAR -->
        <div class="NotesStickyBar">
            <div class="ml-1 mt-0">
                <b-button-toolbar>
                    <b-button-group class="mr-1">
                        <b-button v-on:click="handlePrint()" 
                            v-b-tooltip.hover :title="$t('Shared.Print')" 
                            size="sm" class="ikon med-print-hollow" variant="outline-primary">
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </div>
        </div>
        <!-- END OF QUICK ACCESS STICKY BAR -->

        <div class="cutescroll pl-1">
            <div style="position:relative;border: solid 1px transparent;">
                <div class="row">
                    <div class="col-12 mt-2">
                        <div :data-label="$t('Shared.Notes')" class="textlabel"></div>
                        <MdRichEdit
                            v-model="activity.memoInfo"
                            v-bind:isReadOnly="true"
                            v-bind:Height="'56vh'"
                            v-bind:id="'edit_memoInfo'"
                            v-bind:isRequired="false"
                            v-bind:HideToolBar="true"
                            v-bind:StartupFocus="false"
                        />
                    </div>
                </div>
                <div class="flex-container">
                    <b-row>
                        <b-col md="6" lg="6" sm="12">
                            <MdCheckBox
                                    v-bind:Label="$t('Activities.ShowToPatient')"
                                    v-bind:Name="'checkbox-showToPatient'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="activity.showToPatient"
                                    v-bind:isReadOnly="true"
                            />
                        </b-col>
                        <b-col lg="6" md="6" sm="12">
                            <MdEdit 
                                v-bind:Label="$tc('Shared.Employee')"
                                v-bind:Name="'text'"
                                v-bind:TypeProp="'text'" 
                                v-model="activity.therapistName"
                                v-bind:isReadOnly="true">                                     
                            </MdEdit>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import LocalStorage from '@/services/storage.service'
import Utils from '@/common/utils'

export default {
    name: 'ActivityChartReview',
    props: {
        activity: Object,
        reportEvent: Function
    },

    data: function() {
        return { 
            icon:"ikon med-doc-complete mr-2",
            isReadOnly: true,
            UserId: LocalStorage.getUserId()
        }
    },


    computed: {
        title() {
            return Utils.formatterDateToShortLocaleString(this.activity.transDate);
        }
    },

    methods: {
        async Close() {   
            this.$emit('remove');
            EventBus.$emit('CloseDocument');
        },

        handlePrint() {
            if (this.reportEvent) {
                this.reportEvent({recId: this.activity.transCode, userName: this.activity.therapistName});
            }
        }
    }
}
</script>