<template>
    <div>
      <MdTitle v-bind:Text="$t('Admission.Admission')" />

      <div class="flex-container">
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
              v-bind:Label="$t('Shared.datefrom')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'datefrom'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateFrom">                                     
            </MdEdit>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
              v-bind:Label="$t('Shared.dateTo')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'dateto'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateTo">                                     
            </MdEdit>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="PatientsOptions"
              v-bind:Model="PatientModel"
              v-bind:FieldDescription="'patientname'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Multiple="false"
              v-bind:AllowEmpty="true"
              :Input="ChangePatients"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.Status')"
              v-bind:Options="StatusOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'value'"
              v-bind:AllowEmpty="false"
              v-bind:Multiple="false"
              v-bind:ModelArray="StatusModel"  
              :Input="ChangeStatus"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Program')"
              v-bind:Options="ProgramOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:AllowEmpty="true"
              v-bind:Multiple="true"
              v-bind:Model="ProgramModel"  
              :Input="ChangeProgram"/>
          </b-col>
          <b-col md="3" lg="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.Building')"
              v-bind:Options="BuildingOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="BuildingModel"                       
              v-bind:Multiple="false"
              :Input="ChangeBuilding"
            />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Grouping')"
              v-bind:Options="GroupingOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:AllowEmpty="true"
              v-bind:Multiple="false"
              v-bind:Model="GroupingModel"  
              :Input="ChangeGrouping"/>
          </b-col>
        </b-row>
        </div>

        <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Back')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="reportList"
            v-bind:Name="'btn_ReportList'"/>
          <MdButton
            v-bind:Label="$t('Shared.Report')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClickShowReport"
            v-bind:Name="'btn_Print'"/>   
        </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="reportTitle"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="Admissions"
          :ReportName="'AdmissionsView'"
          :ReportTitle="reportTitle"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import EnumService from "@/services/enum.service";
    import PatientService from '@/services/patient.service'
    import ProgramService from '@/services/programs.services'
    import Utils from '../../../common/utils'
    import DBFunctions from '@/common/DBFunctions.js'
    import AdmissionService from '@/services/admission.service'

    export default Vue.extend({
        name: "AdmissionsViewRep",
        mixins: [ ShowMessage, Utils, DBFunctions ],
        components: { DxPopup },
        data() {
            return {
                isReportVisible: false,
                reportParams: [],
                showFilters: true,
                PatientsOptions: [],
                ProgramOptions: [],
                BuildingOptions: [],
                StatusOptions: EnumService.AdmissionsStatusOptions,
                StatusModel: {},
                PatientModel: '',
                ProgramModel: '',
                BuildingModel: '',
                GroupingModel: '',
                parameter:{
                  SearchCriteria:'',
                  PageNumber : null,
                  PageSize:null,
                  Status: true,
                },
                paramStatus: '',
                reportTitle: this.$t('Admission.Admission'),
                paramPatient: '',
                paramProgram: '',
                paramBuilding: '',
                fullParam: {
                  PageSize:0,
                  PageNumber:0
                },
                GroupingOptions: [{"value":"programCode","name":"Program"}],
                dateFrom: null,
                dateTo: null,
            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.isReportVisible = false
            },

            reportList() {
              this.checkExistFavorite('/app/reports/patients');
              this.$router.push({ name: "reportListPatient" }).catch(()=>{});
            },

            onClickShowReport() {
              this.reportParams = []

              this.reportParams.push({ paramName: 'dateFrom', paramValue: this.dateFrom})
              this.reportParams.push({ paramName: 'dateTo', paramValue: this.dateTo})
              this.reportParams.push({ paramName: 'building', paramValue: this.paramBuilding})
              this.reportParams.push({ paramName: 'program', paramValue: this.paramProgram})
              this.reportParams.push({ paramName: 'status', paramValue: this.StatusModel.value})
              this.reportParams.push({ paramName: 'patientCode', paramValue: this.paramPatient})
              this.reportParams.push({ paramName: 'grouping', paramValue: this.GroupingModel ? this.GroupingModel.value : null})

              this.isReportVisible = true
              this.$refs.Admissions.buildDocument(this.reportParams)
            },

            getPatients(){   
              PatientService.getAll(this.fullParam)
              .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0) {
                this.PatientsOptions = response.data;                 
                this.PatientsOptions.forEach(function(element) {	
                    element.patientname = element.firstname + " " + element.lastname + " - "+element.patientcode;   
                });       
              }       
              })
              .catch(error => {      
                if (!error.response) {
                    // network error
                    this.showMessage(this.$t('Admission.Patients'),this.$t('Msg.retrieveInfo'),"error");
                } else {
                    this.showMessage(this.$t('Admission.Patients'),error,"error");
                }
              });    
            },

            getPrograms(){   
              ProgramService.getAll(this.fullParam)
              .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0) {
                this.ProgramOptions = response.data;                  
              }       
              })
              .catch(error => {      
                if (!error.response) {
                    // network error
                    this.showMessage(this.$t('Admission.Programs'),this.$t('Msg.retrieveInfo'),"error");
                } else {
                    this.showMessage(this.$t('Admission.Programs'),error,"error");
                }
              });    
            },

            getBuildings(){
              AdmissionService.getBuildings()
                .then((response) => {
                    if(response.status == '200'){
                        this.BuildingOptions = response.data; 
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionBuildings'),error,"error");
                    }
                });
            },


            ChangeStatus(item) {
              if(item != null){
                this.StatusModel = item;
                this.paramStatus = item.code
              }else{
                this.StatusModel = null;
              }
            },

            ChangePatients(item) {
              if(item != null){
                this.PatientModel = item;
                this.paramPatient = item.patientcode
              }else{
                this.PatientModel = null;
                this.paramPatient = ''
              }
            },

            ChangeGrouping(item) {
              if(item != null){
                this.GroupingModel = item;
              }else{
                this.GroupingModel = null;
              }
            },

            ChangeProgram(item) {
              if(item != null){
                this.ProgramModel = item;

                this.paramProgram = [];
                let selectedProgram = [];
                item.forEach(function(item) {   
                  selectedProgram.push(item.code);               
                });
                this.paramProgram = selectedProgram;
              }else{
                this.ProgramModel = null;
              }
            },

            ChangeBuilding(item) {
              if(item != null){
                this.BuildingModel = item;
                this.paramBuilding = item.code
              }else{
                this.BuildingModel = null;
                this.paramBuilding = ''
              }
            }
        },

        async created(){
        },
        
        mounted () {
          this.getPatients()
          this.getPrograms()
          this.getBuildings()
          this.StatusModel = {"value":"ACTIVE","name":"Active"}
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
