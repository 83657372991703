import ApiService from '@/services/api.service'

const AllergySetupService = {
    getAll( parameter) { 
        const requestData = {
            method: 'get',
            url: "/AllergySetUps/",
            headers: {
            },
            params: { 
                SearchCriteria: parameter.SearchCriteria,
                PageSize: parameter.PageSize,          
                PageNumber : parameter.PageNumber,
                PatientCode : parameter.PatientCode
            }
        }     
        return ApiService.customRequest(requestData);         
    },

    get( id) {
        return ApiService.get(`/AllergySetUps/${id}`);
    },

    addAllergy(data){
        return ApiService.post(`/AllergySetUps`,data)
    },

    deleteAllergy(id){
        return ApiService.delete(`/AllergySetUps/${id}`)
    },

    updateAllergy(Code,Allergy){
        return ApiService.put(`/AllergySetUps/${Code}`,Allergy)
    },

    getAllergyByCode(Code) {
        return ApiService.get(`/AllergySetUps/?Code=${Code}`);
    }
}
  
  export default AllergySetupService;