import ApiService from '@/services/api.service'

const ActivitiesService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/Activities/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber: parameter.PageNumber,
          PatientCode: parameter.PatientCode,
          IsAlert: parameter.IsAlert,
          ByShowTo: parameter.ByShowTo,
          DateFrom: parameter.DateFrom,
          DateTo: parameter.DateTo,
          Active: parameter.Active,
          notShowMeMore: parameter.NotShowMeMore
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/Activities/${id}`);
    },

    add(data){
      return ApiService.post(`/Activities`,data)
    },

    delete(id){
      return ApiService.delete(`/Activities/${id}`)
    },

    update(id,data){
      return ApiService.put(`/Activities/${id}`,data)
    },

    resetAlerts(parameter) {
        const data = {
            patientCode: parameter.PatientCode,
            notShowMeMore: parameter.NotShowMeMore,
            recId: parameter.RecId
        };

        return ApiService.put(`/Activities/ResetAlerts`,data)
    },

    existAlerts(patientCode) {
        return ApiService.get(`/Activities/ExistAlerts/${patientCode}`);
    }
}
  
  export default ActivitiesService;