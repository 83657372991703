<template>
    <b-modal
        :no-close-on-backdrop="true" 
        scrollable 
        centered           
        size="xl"  
        style="border: 10px solid green !important;" 
        :id="ModalIdPatientAlerts" 
        :title="this.$t('PatientAlertsInfoBox.PatientAlerts')"
        @hidden="resetModal"
    >
        <MdTitle v-bind:Text="$t('PatientAlertsInfoBox.PatientAlerts')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

        <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite">
            <div class="text-center my-2">{{$t('MdTable.nRecShow')}}</div>
        </div>

        <div class="container-alerts">
            <div class="card-container">
                <div class="card mb-3 innerParentContainer" v-for="(it, index) in PatientAlerts" :key="`it.patientCode-${index}`">
                    <div class="card-body overdue-card activeCard">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="innerHeader">{{ it.userName }} <span class="innerDivider">▪</span> {{ it.datechanged }}</div>
                                <div v-html="it.memoinfo" class="mt-1"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="row">
                <b-col lg="10" md="10" sm="12">
                    <div class="row">
                        <b-col lg="10" md="10" sm="12">
                            <MdCheckBox
                                v-bind:Label="$t('PatientAlerts.DontShowMeAgain')"
                                v-bind:Name="'checkboxDoNotShow'" 
                                v-bind:CheckedValue="true"
                                v-bind:UncheckedValue="false"              
                                v-model="doNotShow"
                                v-on:change="handleDoNotShowAllAlerts"
                                v-bind:Tag="'DoNotShowAlerts'"   
                            />
                        </b-col>
                    </div>
                </b-col>
                
                <b-col lg="2" md="2" sm="12" class="text-right">
                    <MdButton
                        v-bind:Label="$t('Shared.Close')"
                        v-bind:ClassProp="'primary btn-login mx-2'"
                        v-on:click="handleCancel"
                        v-bind:Name="'btn_cancel_alerts'"
                    />
                </b-col>
            </div>
        </template>
    </b-modal>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import ActivitiesService from '@/services/activities.service'
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';

export default {
    name: 'PatientAlertsInfoModal',
    mixins: [DBFunctions],

    props: {
        PatientCode: String,
        AutoShow: Boolean,
        ShowModal: Boolean,
        CloseEvent: Function
    },

    data() {
        return {
            ModalIdPatientAlerts:'PatientAlertsInfoModalID',
            isReadOnly: false,
            doNotShow: false,
            showEmptyRecords: true,

            PatientAlerts: [],
            emptyRecordsStyle: {
                backgroundColor: '#CF0',
                fontWeight: 'bold !important',
                textTransform: 'uppercase',
                padding: '2px',
                borderRadius: '50px',
                animation: 'fadeOut 10s ease-in-out infinite'
            },
        }
    },

    watch: {
        ShowModal(newVal) {
            if (newVal) {
                this.showToPatientAlerts();
            }
        },

        PatientCode(newVal) {
            if (newVal && this.AutoShow) {
                this.verifyAndAutoShowToPatientAlerts();
            }
        }
    },

    methods: {
        getListing() {
            const param = {
                PatientCode: this.PatientCode,
                IsAlert: true,
                DateTo: Utils.formatterToISO(this.DateTo, Utils.getDateTimeFormat()),
                DateFrom: Utils.formatterToISO(this.DateFrom, Utils.getDateTimeFormat()),
                SearchCriteria: this.SearchCriteria,
                Active: true,
                ByShowTo: true,
                NotShowMeMore: false
            }

            ActivitiesService
                .getAll(param)
                .then(response => {
                    if (response.status == "200" && response.data) {
                        this.PatientAlerts = response.data.map(data => ({
                            userName: data.userName,
                            datechanged: Utils.formatterDateTimeToString(data.datechanged),
                            memoinfo: data.memoinfoPlainText ?? data.memoinfo,
                            recId: data.recId,
                            doNotShow: false
                        }));

                        this.showEmptyRecords = this.PatientAlerts.length == 0;
                        this.$emit('showEmptyRecords', this.showEmptyRecords);
                    }
                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                    this.$emit('load', false);
                });
        },

        SaveOrEdit() {
            this.$emit('load', true);
            let param = {
                PatientCode: this.PatientCode,
                NotShowMeMore: this.doNotShow
            }

            ActivitiesService
                .resetAlerts(param)
                .then(response => {
                    if (response.status == "200") {
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('PatientAlerts.SavedSuccessfully'), {
                                title: this.$t('PatientAlerts.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                        , 50);
                    }
                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                    this.$emit('load', false);
                });
        },

        async verifyAndAutoShowToPatientAlerts() {
            if (await this.IsPatientWithActiveAlerts(this.PatientCode)) {
                this.showToPatientAlerts();
            }
        },

        showToPatientAlerts() {
            if (this.PatientCode) {
                this.$emit('load', true);
                this.getListing();
            }

            this.$bvModal.show(this.ModalIdPatientAlerts);
        },

        handleCancel() {
            this.$bvModal.hide(this.ModalIdPatientAlerts);
            if (this.CloseEvent) {
                this.CloseEvent();
            }
        },

        handleDoNotShowAllAlerts() {
            setTimeout(() => {
                this.SaveOrEdit();
            }, 50, this)
        },

        async closeModal(){
            this.$bvModal.hide(this.ModalIdPatientAlerts);
            if (this.CloseEvent) {
                this.CloseEvent();
            }
        },

        resetModal() {
            if (this.CloseEvent) {
                this.CloseEvent();
            }
        }
    },

    created(){
        EventBus.$on(this.ModalIdPatientAlerts, function () {        
            this.closeModal(); 		
        }.bind(this));
    },

    mounted() {        
        if (this.AutoShow && this.PatientCode) {
            setTimeout(() => {
                this.verifyAndAutoShowToPatientAlerts();
            }, 50, this);
        }
    },

    beforeDestroy () {     
        EventBus.$off(this.ModalIdPatientAlerts);        
    },
}
</script>
<style>
#PatientAlertsInfoModalID___BV_modal_body_ h4{
    margin-top: 0;
}
</style>