<template> 
      <div :id="targetId" oncontextmenu="return false;">
        <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
          <ejs-contextmenu  :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
        </div>
        <input
            v-mask="this.maskValue"
            v-b-tooltip.hover :title="Hint"
            :type="Type"
            :maxlength="this.$attrs.control.controlMaxLenght"          
            :readonly="this.$attrs.control.isReadOnly || NoteIsVoid || this.$attrs.control.IsChartReview || this.isReadOnly"
            :min="Min"
            :max="Max"           
            :step="StepProp"
            :ref="RefProp"          
            :class="classValue" 
            :formatter="OnFormatter"
            v-bind:value="Value"
            v-on:blur="OnBlur($event)"
            v-on:change="OnChange"
            v-on:keyup.enter="$emit('keyup', $event)"
            :aria-describedby="this.$attrs.control.controlID"           
            v-on:keypress="$emit('keypress', $event)"
            v-on:input="Input"
            v-on:focus="$emit('focus', $event.target.value)"
            v-bind:style="style"
            :id="idControl"
            >
            <!-- <VueContext ref="menu">
                <a class="dropdown-item" @click="showModalGetLastAnswers()">
                  Get 10 Last Answers
                </a>
            </VueContext> -->
            <MdGetLastAnswers v-if="showGetLastAnswers"
              v-bind:ShowPopup="showGetLastAnswers"
              @getCancel="onCancelGetLastAnswers($event)"
              @getOK="onOKGetLastAnswers($event)"
              v-bind:ControlId="controlID"
              v-bind:PatientCode="PatientCode"
              v-bind:TransDate="TransDate"
            />
      </div>         
</template>

<script>
import { EventBus } from '../../event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

Vue.use(ContextMenuPlugin);
export default {
  name: 'MdMaskEdit',
  mixins: [EventBus],
   props: {   
     StepProp:String,    
     isReadOnly:Boolean,
     Formatter:Function,
     Min:String,
     Max:String,
     Change:Function,
     RefProp:String,
     KeyEnter:Function,
     KeyPress:Function,
     FeedBackText:String,
     Name:String,
     value:[String, Number],
     TransDate: String,
     NoteId: String,
     id: [String, Number],
     PatientCode: String,
     IsCommonPart: Boolean,
     IsGroupContainer: Boolean
   },

   data(){
     return{
      classValue:"form-control cursor-context-menu",
      NoteIsVoid:false,
      CountInput:0,
      style:{
        position:'absolute',
        top: this.$attrs.control.controlTop+'px',
        width:this.$attrs.control.controlWidth+'px',
        height:this.$attrs.control.controlHeight+'px',
        left:this.$attrs.control.controlLeft+'px',
        fontSize:'',
        fontWeight: '',
        fontFamily:'',
        color:'',
        backgroundColor:'',
        textDecorationLine:'',
        fontStyle:'',
        textTransform:'',
        borderColor: '',
        textAlign: 'left'
      },
      Hint: this.$attrs.control.controlHint,
      Type: 'text',
      isRequired: this.$attrs.control.isRequired,
      showGetLastAnswers: false,
      controlID:String(this.$attrs.control.controlID),
      //PatientCode: this.$route.params.id,
      Value: this.value,
      maskValue:'',
      menuItems:[],
      targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      idControl:this.id.toString()
     }
   },


methods:{
     OnChange(evt){
       this.$emit('change',evt.target.value)
       this.style.borderColor = evt.target.value.length == 0 && this.isRequired ? '#dc3545 !important': ''
     },

     OnFormatter(valueIn,valueOu) {
       this.Formatter(valueIn,valueOu);
       this.$emit('Formatter',valueOu)
     },

     OnBlur(evt){
       this.$emit('blur',evt.target.value)
       this.style.borderColor = evt.target.value.length == 0 && this.isRequired ? '#dc3545 !important': ''
     },

     setControlType() {
       let controlDataType = this.$attrs.control.controlDataType;
       if(controlDataType === 'Integer' || controlDataType === 'Money')
        this.Type = 'number'
       if(controlDataType === 'String')
        this.Type = 'text'
     },

     setControlCharCase() {
       let controlCharCase = this.$attrs.control.controlCharCase;
       if(controlCharCase === 'ecUpperCase')
        this.style.textTransform = 'uppercase'
       if(controlCharCase === 'ecLowerCase')
        this.style.textTransform = 'lowercase'
     },

     setAlignment() {
       if(this.$attrs.control.alignment) {
          if(this.$attrs.control.alignment === 'taCenter')
            this.style.textAlign = 'center'
          if(this.$attrs.control.alignment === 'taLeftJustify')
            this.style.textAlign = 'left'
          if(this.$attrs.control.alignment === 'taRightJustify')
            this.style.textAlign = 'right'
       }
     },

     InitializeVariables(){
       if(this.$attrs.control.controlColorForHtml)
          this.style.backgroundColor = this.$attrs.control.controlColorForHtml;
       else if(this.$attrs.control.isReadOnly || this.isReadOnly)
          this.style.backgroundColor = '#e9ecef !important'
       if(this.$attrs.control.controlFontForHtml){         
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
          var size = parseInt(this.controlFont[1]) + 2;
          this.style.fontFamily = this.controlFont[0];
          this.style.fontSize = size+'px !important';
          this.style.color = this.controlFont[3];
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
       if(this.$attrs.control.controlMask){
         this.maskValue = this.getMaskValue(this.$attrs.control.controlMask)
       }
       this.setControlType();
       this.setControlCharCase();
       this.setAlignment()
    },

    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    Input(w){     
      this.Value = w.target.value;
      this.$emit('input', w.target.value);
      this.CountInput++;
      if(w.target.value.length > 0 && this.CountInput > 1){
        var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
        }
        EventBus.$emit('onControlWasChanged', changed);
      }
    },

    onOKGetLastAnswers(value) {
      this.Value = value;
      this.$emit('input', value);
       var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
        }
      EventBus.$emit('onControlWasChanged', changed);
      this.showGetLastAnswers = false;
    },

    handlerClick(evt){
      if(!this.$attrs.control.isReadOnly && !this.isReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state){
        this.style.borderColor = state ? '#dc3545 !important': ''
    },

    getMaskValue(controlMask){
      let maskValue = ''
      if(controlMask == '!99/99/0000;1;_') {
        maskValue = '##/##/####'
      }
      else if(controlMask == '!90:00;1;_') {
        maskValue = '##:##'
      }
      else if(controlMask == '!90:00> LL;1;_') {
        maskValue = '##:## AA'
      }
      else if(controlMask == '!90:00:00> LL;1;_') {
        maskValue = '##:##:## AA'
      }
      else if(controlMask == '!99/99/0000 !90:00> LL;1;_') {
        maskValue = '##/##/#### ##:## AA'
      }
      return maskValue
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
    }
},

created(){

    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));

  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }

},
mounted(){
  this.InitializeVariables();
  this.loadMenuItems()

  if (!this.IsCommonPart && this.IsGroupContainer && this.$attrs.control.isCommonControlNote) {
    this.Hint = this.$t('GroupNotesDashboard.ToCompletedInGroupFocus')
  }
}
};

</script>

<style>
 @import '../../assets/css/Patients/Patient.css';
 .scroll{
	overflow: hidden !important;
}
</style>


