<template>
   <div class="row page-height">
      <div class="col-12">
        <h4 v-if="this.Mode" class="page-title text-truncate"> {{$t('userHeader.Edit')}}</h4>
        <h4 v-if="!this.Mode" class="page-title text-truncate"> {{$t('userHeader.Add')}}</h4>



        <b-row>
            <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('employee.UserID')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'UserId'"
                     v-bind:MaxLengthProp="6"
                     v-bind:isRequired="true"
                     v-bind:isValid="isUserIdValid"
                     v-bind:isReadOnly="this.Mode"
                     v-bind:Id="'input_userid_id'"
                     v-on:blur="OnUserIdBlur(User.userId)"
                     v-model="User.userId">                                     
               </MdEdit>
            </b-col>
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('Shared.FirstName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'FirstName'"
                     v-bind:Id="'input_firstname_id'"
                     v-bind:MaxLengthProp="64"                               
                     v-model="User.firstname">                                     
               </MdEdit>
            </b-col>
             <b-col md="3" lg="3" sm="12">
              <MdEdit 
                     v-bind:Label="$t('Shared.LastName')"
                     v-bind:TypeProp="'text'" 
                     v-bind:Name="'LastName'"
                     v-bind:Id="'input_lastname_id'"
                     v-bind:MaxLengthProp="64"                     
                     v-model="User.lastname">                                     
               </MdEdit>
            </b-col>
             <b-col sm="12" md="3" lg="3">  
                 <MdSelect
                     v-bind:Label="$t('userHeader.Role')"
                     data-field="Role"
                     v-bind:Options="RoleList"
                     v-bind:FieldDescription="'description'"
                     v-bind:FieldCode="'code'"
                     v-bind:Model="User.roleCode" 
                     v-bind:FeedBackText="$t('userHeader.rRequiredMsg')" 
                     v-bind:isValid="isRoleValid"     
                     v-bind:isRequired="true"                      
                     v-bind:Multiple="false"
                     :Input="ChangeRol"
                     v-bind:IsDisabled="isReadOnly"
               />
             </b-col>
          </b-row>
          <b-row>
         <b-col md="3" sm="12" v-if="!this.Mode">
          <MdPassword
            v-bind:Label="$t('insuranceSetup.Password')"
            v-bind:TypeProp="'password'" 
            v-bind:Name="'Password'"           
            v-bind:MaxLengthProp="45"
            v-bind:isRequired="true"
            v-bind:isValid="isPasswordValid"
            v-model="User.password"
            v-bind:Autocomplete="false">                                     
          </MdPassword>
        </b-col>
        <b-col md="3" sm="12" v-if="!this.Mode">
          <MdPassword
            v-bind:Label="$t('ChangePassWord.ConfirmPass')"
            v-bind:TypeProp="'password'"          
            v-bind:Name="'Verify'"
            v-bind:MaxLengthProp="45"
            v-bind:isRequired="true"
            v-bind:isValid="isConfirmPasswordValid"
            v-model="User.verify"
            v-bind:Autocomplete="false">                                     
          </MdPassword>
        </b-col>
        <b-col lg="3" md="3" sm="12">
              <MdEdit 
            v-bind:Label="$t('userHeader.pExpDate')"
            v-bind:Name="'ExpDate'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/####'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="User.passwordExpirationDate"           
          /> 
            </b-col>          
          </b-row>
          <b-row>
            <b-col md="3" lg="3" sm="12">
              <MdCheckBox
                   v-bind:Label="$t('userHeader.IsSupervisor')"
                   v-bind:Name="'checkbox-isSupervisor'"
                   v-bind:ClassProp="'AlignCheck'"
                   v-bind:CheckedValue="true"
                   v-bind:UncheckedValue="false"
                   v-model="User.isSupervisor"
              />
         </b-col>
          <b-col md="3" lg="3" sm="12">
              <MdCheckBox
                   v-bind:Label="$t('userHeader.ccAlert')"
                   v-bind:Name="'checkbox-iscancreatealert'"
                   v-bind:ClassProp="'AlignCheck'"
                   v-bind:CheckedValue="true"
                   v-bind:UncheckedValue="false"
                   v-model="User.canCreateAlerts"
              />
         </b-col>
                  <b-col lg="3" md="3" sm="12">             
                      <MdRadioGroup
                          v-bind:Label="$t('userHeader.aUser')"
                          v-model="User.isActive"
                          v-bind:Options="activeUserlist"
                          v-bind:Name="'Active_User'"
                          v-bind:isReadOnly="this.isReadOnly"
                        />
                    </b-col>
          </b-row>



           <ModalUserResetPassword
            v-bind:TitleWindow="$t('userHeader.rPassword')"          
            @getCodeConditionOK="OnReturnDataModalResetPassword($event)"
            v-bind:ModalId="modalResetPasswordId"
            @getCodeConditionCancel="OnReturnCancelModalResetPassword($event)">
         </ModalUserResetPassword>
                  
         <div class="page-actions">
            <MdButton
               v-bind:Label="$t('userHeader.uList')"
               v-bind:Name="'btnuserlist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>   
              <MdButton
               v-bind:Label="$t('Shared.Save')"
               v-bind:Name="'btnSaveUser'"
               v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"
               v-on:click="SaveOrEdit"
               />   
                <MdButton v-if="this.Mode"
               v-bind:Label="$t('userHeader.rPassword')"
               v-bind:Name="'btnresetpassUser'"
               v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"
               v-on:click="CallModalResetPassword"/>         
         </div>
         
      </div>
   </div>
</template>

<script>


import EnumService from '@/services/enum.service' 
import Utils from '@/common/utils.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import CompanieService from '@/services/companies.service'
import GlobalService from '@/services/global.service'
import { EventBus } from '@/event-bus.js';
import UserService from '@/services/user.service'
import moment from 'moment'

let elemSaveBtn = '';
export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

   beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('userHeader.uSetup'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.checkExistFavorite('/app/users');
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],
   name: 'UserDetails',  
    data() {
      return {  
      CodeExist:false,
      modalResetPasswordId:"ModalResetPasswordId",      
      RoleList:[],
      permission:[],
      Menu : this.$store.state.leftMenu,  
      activeUserlist: EnumService.YesNoOptionsInverted, 
       MaskDateFrom:"##/##/####",  
       isUserIdValid:true,
       isRoleValid:true,
       isPasswordValid:true,
       isConfirmPasswordValid:true,
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
      ProviderItems:[],
      isFirstNameValid: true,
      isLastNameValid:true,
      Userdescription:"",    
      User:{
        passwordExpirationDate:""
      }, 
      DataUser:{},
      Companies:[],     
      UserId:null,
      
         fields: [
            { key: 'Insurance', sortable: true, label: this.$tc('Insurance.Insurance'), class: 'text-rigth' },
            { key: 'ProviderId', sortable: true, label: this.$t('employee.ProviderId'), class: 'text-rigth' },
          ],   
        Mode:this.$attrs.editMode,  
        Loading:true,
        isReadOnly:false,   
        pendingChanges: false,
        emailValid:false,
        reg: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
      }
    },

    methods:{

    validate_entries() {
        return true;
    },

     CanView(){
      return this.permission[0].roleCanView;
  },

    OnUserIdBlur(value){
       if(!this.Mode){
          if(value.trim() !== '') {
            UserService.getUserByCode(value)
                .then((response) => {
                    if(response.status == '200' && response.data){                   
                       if(response.data.length > 0){
                          this.showMessage("User",this.$t('userHeader.uExists'),"error");
                          this.CodeExist = true;
                       }else{
                           this.CodeExist = false;
                       } 
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false);                  
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('userHeader.uSetup'),error,"error");
                    }
                  }
                    
                });  
            }
               }
    },

    OnReturnDataModalResetPassword(data){
      this.pendingChanges = false;
      this.ResetPassword(data);
    },
    
     CallModalResetPassword(){
      this.$emit('load', true);
      this.$bvModal.show(this.modalResetPasswordId);
      this.$emit('load', false);
     },


    DiscardChanges(){
        if(!this.pendingChanges)
          this.checkExistFavorite('/app/users');
        this.$router.push({name: "users"});
    },

    getUser(){
      UserService.get(this.UserId)
            .then((response) => {      
               if (response.status == '200' && response.data) {   
                 var data = response.data;
                 data.isActive = data.isActive ? "N" : "Y"; 
                 data.passwordExpirationDate = Utils.formatterDateToString(data.passwordExpirationDate); 
                 this.User = data;
                 this.$emit('load', false); 
               }  
         })
         .catch(error => { 
           this.$emit('load', false);     
            if(error.response.status === 400 ){
                 this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('userHeader.uSetup'),error,"error");
            }
         });    
    },

      ResetPassword(data){
        this.$emit('load', true); 
      UserService.resetPassword(this.UserId,data)
            .then((response) => {      
               if (response.status == '200') {   
                    this.$emit('load', false);              
                    setTimeout(() => 
                        this.$bvToast.toast('Password changed successfully.', {
                         title: 'Reset Password',
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
               }  
         })
         .catch(error => { 
           this.$emit('load', false);     
            if(error.response.status === 400 ){
                 this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('userHeader.uSetup'),error,"error");
            }
         });    
    },

    AddUser(){
       if(!this.CodeExist){
        UserService.add(this.DataUser)
            .then((response) => {      
               if (response.status == '200') {   
                  this.pendingChanges = false; 
                  this.$emit('load', false);
                  this.$router.push({name: "users"});    
                    setTimeout(() => 
                        this.$bvToast.toast('User saved successfully.', {
                         title: 'Saving User',
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
               }  
         })
         .catch(error => { 
           if (!error.response) {
                this.showMessage(this.$t('userHeader.uSetup'),this.$t('Msg.NotAbleToSave'),"error");
            }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('userHeader.uSetup'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled"); 
                    this.$emit('load', false);
         });   
       }else{
         this.$emit('load', false);
         //this.isUserIdValid = false;
          this.showMessage(this.$t('userHeader.uExists'),"error");
       } 
    },

    ChangeRol(e){
      this.User.roleCode = e ? e.code : "";
    },

    UpdateUser(){
      UserService.update(this.UserId, this.DataUser)
            .then((response) => {      
               if (response.status == '200') {   
                  this.pendingChanges = false; 
                  this.$emit('load', false);
                  this.$router.push({name: "users"});    
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
               }  
         })
         .catch(error => { 
           if (!error.response) {
                this.showMessage(this.$t('userHeader.uSetup'),this.$t('Msg.NotAbleToSave'),"error");
            }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('userHeader.uSetup'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled");    
                    this.$emit('load', false);
         });    
    },
       
    async getRols(){
       await UserService.getRols()
            .then((response) => {      
               if (response.status == '200' && response.data) {              
                  this.RoleList = response.data;
               }  
         })
         .catch(error => {     
            if(error.response.status === 400 ){
                 this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('userHeader.uSetup'),error,"error");
            }
         });    
    },

     getDateTime() { 
        GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {     
                 var d = Utils.formatterDateToString(response.data);
                 var f = moment(d, Utils.getShortDateFormat()).add('days',this.Companies[0].passwordDays);            
                 var DateGenerated = Utils.formatterDateToString(f);
                this.User.passwordExpirationDate =DateGenerated;
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('userHeader.uSetup'),error,"error");
            }
         }); 
      },

    getCompanies(){
      CompanieService.getAll()
            .then((response) => {      
               if (response.status == '200' && response.data && response.data.length > 0) {              
                  this.Companies = response.data;
                  if(this.Companies[0].passwordDays > 0){
                    this.getDateTime();                    
                  }
                  
               }  
         })
         .catch(error => {     
            if(error.response.status === 400 ){
                 this.showMessage(this.$t('userHeader.uSetup'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('userHeader.uSetup'),error,"error");
            }
         });    
    },
        
    PrepareUser(){
         this.DataUser.isActive = this.User.isActive == "N" ? true : false;
         this.DataUser.userId = this.User.userId;       
         this.DataUser.firstname = this.User.firstname;
         this.DataUser.lastname = this.User.lastname;
         this.DataUser.roleCode = this.User.roleCode;
         this.DataUser.password = this.User.password;
         this.DataUser.confirmPassword = this.User.verify;
         this.DataUser.isSupervisor = this.User.isSupervisor;
         this.DataUser.canCreateAlerts = this.User.canCreateAlerts;        
         if(this.User.passwordExpirationDate){
            this.DataUser.passwordExpirationDate = Utils.formatterToISO(this.User.passwordExpirationDate, Utils.getShortDateFormat());
         } 
    },
 
    SaveOrEdit(){
      this.pendingChanges = false;
        if(!this.isReadOnly) {
           if(this.validate_entries()){
            if (this.validate_required()) {     
            this.$emit('load', true); 
            this.PrepareUser();      
            if (this.Mode) { 
               this.UpdateUser();         
            }else{  
              if(this.validatePasswordsFiels()){
                 this.AddUser();
              }else{
                this.$emit('load', false); 
                this.showMessage(this.$t('userHeader.uSetup'), this.$t('ModalUserResetPassword.nMatchMsg'), "errorAutoHide");
              }          
              
            }
          } else {
           this.showMessage(this.$t('userHeader.uSetup'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");           
          }
           }else{
              this.showMessage(this.$t('userHeader.uSetup'),this.$t('Msg.InvalidFieldValuesDetected'),"errorAutoHide");
           }         
        } 
      },


      validate_required() {
        this.isUserIdValid = this.User.userId ? true : false;
        this.isRoleValid = this.User.roleCode && this.User.roleCode.length > 0 ? true : false;
        if(!this.Mode){
          this.isPasswordValid = this.User.password && this.User.password.length > 0 ? true : false;
          this.isConfirmPasswordValid = this.User.verify && this.User.verify.length > 0 ? true : false;
        }else{
          this.isPasswordValid = true;
          this.isConfirmPasswordValid = true;
        }
        
        return this.isUserIdValid && this.isRoleValid && this.isPasswordValid && this.isConfirmPasswordValid;
      },

      validatePasswordsFiels(){
        var c = this.User.password === this.User.verify;       
        this.isConfirmPasswordValid = !c?false:true;
        return c;
      },

      async Initialize(){
        await this.getRols();
      this.User.isActive = "N";
      this.UserId = this.$route.params.id;
      if(this.Mode){
        this.$emit('load', true); 
         this.getUser();
      }else{
        this.getCompanies();
      }
      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		},
      checkUserAction() {
      if (elemSaveBtn.disabled) {
        elemSaveBtn.removeAttribute('disabled');
      }
    }
    },

     async mounted(){       
      if(this.permission.length == 0){
          this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
      }else{
        if(this.Mode){
          if(!this.permission[0].roleCanEdit){
            this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
         this.$emit('load', false); 
          }else{
           await this.Initialize();
          }
        }else{
          if(!this.permission[0].roleCanAdd){
            this.$router.push({name: "Main"});    
            setTimeout(() => 
              this.showPermissionMessage()
            , 50); 
            this.$emit('load', false); 
          }else{
            await this.Initialize();
          }
        }
      }
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btnSaveUser');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );  
    },

    created(){
       this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
       this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);  
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed;
          this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  }
</script>


