import ApiService from '@/services/api.service'

const MenuService = {

    getMenu(){
        return ApiService.get(`/Menus?Device=WEB&LoadUserSelectedModule=true&Operation=LoadingMainMenu`);
    },

    getTestgMenu(){
        return [ {
            "type": "header",
            "code": "442373854011698",
            "header": false,
            "hiddenOnCollapse": false,
            "title": "Dashboards",
            "href": null,
            "icon": "med-isologo",
            "child": [
              {
                  "type": "menu",
                  "code": "442366253323153",
                  "header": false,
                  "hiddenOnCollapse": false,
                  "title": "Patient Dashboard",
                  "href": "/app/patients",
                  "icon": null,
                  "child": []
              },
              {
                  "type": "menu",
                  "code": "442366253323153",
                  "header": false,
                  "hiddenOnCollapse": false,
                  "title": "Clinical Dashboard",
                  "href": "/app/clinical",
                  "icon": null,
                  "child": []
              }            
          ]
      } , 
      {
          "type": "header",
          "code": "442373854011698",
          "header": false,
          "hiddenOnCollapse": false,
          "title": "Reports",
          "href": null,
          "icon": "mdi mdi-arrow-right-drop-circle",
          "child": [
              {
                  "type": "category",
                  "code": "442373856283458",
                  "header": true,
                  "hiddenOnCollapse": false,
                  "title": "Financial",
                  "href": null,
                  "icon": null,
                  "child": null
              },
              {
                  "type": "menu",
                  "code": "442366253323153",
                  "header": false,
                  "hiddenOnCollapse": false,
                  "title": "Patient",
                  "href": null,
                  "icon": null,
                  "child": [
                          {
                            "type": "menu",
                            "code": "442366253323153",
                            "header": false,
                            "hiddenOnCollapse": false,
                            "title": "Patient Census",
                            "href": null,
                            "icon": null,
                            "child": null
                          },
                          {
                            "type": "menu",
                            "code": "442366253323153",
                            "header": false,
                            "hiddenOnCollapse": false,
                            "title": "Patient Admissions",
                            "href": null,
                            "icon": null,
                            "child": null
                          },                              
                  ]
              },
              {
                  "type": "menu",
                  "code": "442366253323153",
                  "header": false,
                  "hiddenOnCollapse": false,
                  "title": "Employee",
                  "href": null,
                  "icon": null,
                  "child": [
                          {
                            "type": "menu",
                            "code": "442366253323153",
                            "header": false,
                            "hiddenOnCollapse": false,
                            "title": "Employee Census",
                            "href": null,
                            "icon": null,
                            "child": null
                          },
                        {
                            "type": "menu",
                            "code": "442366253323153",
                            "header": false,
                            "hiddenOnCollapse": false,
                            "title": "Employee Admissions",
                            "href": null,
                            "icon": null,
                            "child": null
                          },                              
                  ]
              },
            {
                  "type": "category",
                  "code": "442373856283458",
                  "header": true,
                  "hiddenOnCollapse": false,
                  "title": "Statisticals",
                  "href": null,
                  "icon": null,
                  "child": null
              },
              {
                  "type": "menu",
                  "code": "442366253323153",
                  "header": false,
                  "hiddenOnCollapse": false,
                  "title": "Patient Stats",
                  "href": null,
                  "icon": null,
                  "child": [
                          {
                            "type": "menu",
                            "code": "442366253323153",
                            "header": false,
                            "hiddenOnCollapse": false,
                            "title": "Patient Census Stats",
                            "href": null,
                            "icon": null,
                            "child": null
                          },
                          {
                            "type": "menu",
                            "code": "442366253323153",
                            "header": false,
                            "hiddenOnCollapse": false,
                            "title": "Patient Admissions Stats",
                            "href": null,
                            "icon": null,
                            "child": null
                          },                              
                        ]
                    },
                    {
                        "type": "menu",
                        "code": "442366253323153",
                        "header": false,
                        "hiddenOnCollapse": false,
                        "title": "Employee Stats",
                        "href": null,
                        "icon": null,
                        "child": [
                            {
                              "type": "menu",
                              "code": "442366253323153",
                              "header": false,
                              "hiddenOnCollapse": false,
                              "title": "Employee Census Stats",
                              "href": null,
                              "icon": null,
                              "child": null
                            },
                          {
                              "type": "menu",
                              "code": "442366253323153",
                              "header": false,
                              "hiddenOnCollapse": false,
                              "title": "Employee Admissions Stats",
                              "href": null,
                              "icon": null,
                              "child": null
                            },                              
                        ]
                    }, 
                    {
                        "type": "category",
                        "code": "442373856283458",
                        "header": true,
                        "hiddenOnCollapse": false,
                        "title": "OTHERS",
                        "href": null,
                        "icon": null,
                        "child": null
                    },                                   
                    {
                        "type": "menu",
                        "code": "442366253323153",
                        "header": false,
                        "hiddenOnCollapse": false,
                        "title": "Discharge Report",
                        "href": null,
                        "icon": null,
                        "child": []
                    },
                    {
                        "type": "menu",
                        "code": "442366253323153",
                        "header": false,
                        "hiddenOnCollapse": false,
                        "title": "Scheduler Report",
                        "href": null,
                        "icon": null,
                        "child": []
                    },            
                ]
            }    
          ]
    }
}

export default MenuService;