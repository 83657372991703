<template>
  <div :id="targetId" oncontextmenu="return false;">
    <div v-if="!ReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview">
      <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
    </div>
    <b-form-checkbox
       v-b-tooltip.hover :title="Hint"
       v-bind:style="style"
       size="sm"
       class="cursor-context-menu"
       :value="true"
       :unchecked-value="false"
       :checked="valuemodel"
       :disabled="ReadOnly || NoteIsVoid || this.$attrs.IsChartReview"
       v-on:change="OnChange"
       :ControlId="'CheckBox-'+this.$attrs.control.controlID"
       v-on:input="onInput"
       :id="idControl"
      >
      {{this.$attrs.control.controlCaption}}
    </b-form-checkbox>
    <MdGetLastAnswers v-if="showGetLastAnswers"
      v-bind:ShowPopup="showGetLastAnswers"
      @getCancel="onCancelGetLastAnswers($event)"
      @getOK="onOKGetLastAnswers($event)"
      v-bind:ControlId="controlID"
      v-bind:PatientCode="PatientCode"
      v-bind:TransDate="TransDate"
    />  
    <!-- <VueContext ref="menuBox">
      <a class="dropdown-item" @click="showModalGetLastAnswers()">
        Get Last 10 Answers
      </a>
    </VueContext> -->
  </div>
</template>

<script>
import { EventBus } from '../../event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);

  export default {
    name:"MdCheckBoxCN",
    mixins: [EventBus],
    props: {
      Label:         String,
      Name:          String,
      ClassProp:     String,
      isReadOnly:    Boolean,
      value:         [String, Boolean,Array],
      TransDate: String,
      NoteId: String,
      id: [String, Number],
      PatientCode: String,
      IsCommonPart: Boolean,
      IsGroupContainer: Boolean
    },
    data() {
      return {
      NoteIsVoid:false,
       style: {
            position:'absolute',
            top: this.$attrs.control.controlTop+'px',
            width:(this.$attrs.control.controlWidth)+'px',
            height:this.$attrs.control.controlHeight+'px',
            left:this.$attrs.control.controlLeft+'px',
            fontSize:'',
            fontWeight: '',
            color:'',
            background:'',
            textDecorationLine:'',
            fontStyle:'',
            border: '',        
        },
        ReadOnly:this.$attrs.control.isReadOnly || this.isReadOnly ? true : false,
        isRequired: this.$attrs.control.isRequired,
        Hint: this.$attrs.control.controlHint,
        valuemodel: this.value,
        showGetLastAnswers: false,
        controlID:String(this.$attrs.control.controlID),
        //PatientCode: this.$route.params.id,
        menuItems:[],
        targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
        menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
        idControl:this.id.toString()
      }
    },
  
  methods:{
     InitializeVariables(){
       if(this.$attrs.control.controlFontForHtml){
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
          var size = parseInt(this.controlFont[1]) + 1;
          this.style.fontWeight = this.controlFont[0];
          this.style.fontSize = size+'px !important';
          this.style.color = this.controlFont[3];
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
    },
    
    OnChange(value){
      this.$emit('change', value);
      this.$emit('input', value);
      this.style.border = value === false &&  this.isRequired ? 'solid 1px #dc3545 !important': ''
    },
    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    onOKGetLastAnswers(value) {
      this.valuemodel = value === 'Y' ? true : false;
      document.getElementById(this.idControl).checked = this.valuemodel;
      this.$emit('input', this.valuemodel);
      this.showGetLastAnswers = false;
    //  this.OnChange(this.valuemodel);
      //this.$emit('input', this.valuemodel);
      
    },

    handlerClick(evt){
      if(!this.ReadOnly) {
        this.$refs.menuBox.open(evt)
      }
    },

    setValid(state){
        this.style.border = state ? '': 'solid 1px #dc3545 !important'
    },

    onInput(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
    }
  },

      created(){
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));
  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }

},

  mounted() {
    this.InitializeVariables();
    this.Value = this.$attrs.control.checked === 'Y' && !this.Value ? true : false
    this.loadMenuItems()
    
    if (!this.IsCommonPart && this.IsGroupContainer && this.$attrs.control.isCommonControlNote) {
      this.Hint = this.$t('GroupNotesDashboard.ToCompletedInGroupFocus')
    }
  }
  
  }
</script>

<style>
.custom-checkbox.b-custom-control-sm, .input-group-sm .custom-checkbox {
    font-size: 0.875rem;
    line-height: 2.2 !important;
    padding-left: 1.3125rem;
}
.scroll{
	overflow: hidden !important;
}
</style>

