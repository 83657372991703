<template>
    <div id="ContainerPatientAlerts">
        <div class="row">
            <div class="col-12">
                <MdTitleRegInfo
                    v-bind:RegistrationInfo="PatientTitle" 
                    v-bind:titlePrefix=" $t('PatientAlerts.PatientAlerts')" 
                    v-bind:IsClinical="IsClinical"
                    v-bind:titleRef="'titleElementPatientAlertList'"
                    v-bind:patientCode="this.$route.params.id">
                </MdTitleRegInfo>

                <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
                    <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                    <div class="flex-grow-1">
                        <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
                    </div>
                </div>

                <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="!canCreateAlerts">
                    <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                    <div class="flex-grow-1">
                        <span>{{ $t('PatientAlerts.CanCreateAlerts') }}</span>
                    </div>
                </div>

                <b-row>
                    <div class="col-sm-12 col-md-3">                      
                        <MdButton
                            v-bind:Label= "$t('PatientAlerts.AddAlert')" 
                            v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                            v-bind:IconClass="'ti-plus pr-1'"
                            v-on:click="AddAlert"
                            v-bind:isReadOnly="this.isReadOnly || !this.canCreateAlerts"
                            v-bind:Name="'btn_add_alert'">
                        </MdButton>
                    </div>

                    <div class="col-sm-12 col-md-9 mb-4">
                        <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                            <div class="input-group">
                                <input type="text" class="form-control" v-model="SearchCriteria"
                                    @keyup.enter="SearchAlerts" />

                                <div class="input-group-append">
                                    <MdButton v-bind:Name="'btnsearchinput'" v-bind:VariantProp="'btn btn-sm search-attached'"
                                        v-bind:IconClass="'mdi mdi-magnify'" v-on:click="SearchAlerts">
                                    </MdButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-row>

                <b-row>
                    <b-col lg="3" md="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Shared.datefrom')"                      
                            v-bind:TypeProp="'date'" 
                            v-bind:Min="'1900-01-01'"
                            v-bind:OnPressEnter="SearchAlerts"
                            v-bind:Max="'2200-12-31'"
                            v-bind:isValid="isDateFromValid" 
                            v-bind:Name="'DateFrom'"
                            ref="DateFrom"                        
                            v-model="DateFrom"/>          
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Shared.dateTo')"                      
                            v-bind:TypeProp="'date'" 
                            v-bind:OnPressEnter="SearchAlerts"
                            v-bind:Min="'1900-01-01'"
                            v-bind:Max="'2200-12-31'"   
                            v-bind:isValid="isDateToValid"  
                            v-bind:Name="'DateTo'" 
                            ref="DateTo"                     
                            v-model="DateTo"/>  
                    </b-col>

                    <b-col lg="3" md="3" sm="12">             
                        <MdSelect
                            v-bind:Label="$t('Shared.Status')"
                            v-bind:Options="ListStatus"
                            v-bind:ModelArray="status"
                            v-bind:FieldDescription="'text'"
                            v-bind:FieldCode="'value'"                      
                            :Input="ChangeStatus"/>
                    </b-col>

                    <b-col md="3">
                        <div class="mt-3">
                            <button 
                                v-on:click="SearchAlerts"
                                type="button"
                                class="btn btn-primary btn-login mx-2"												
                                id="btnsearchinput">
                                {{ $t('Shared.Search') }}
                            </button>
                        </div>
                    </b-col>
                </b-row>

                <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

                <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite">
                    <div class="text-center my-2">{{$t('MdTable.nRecShow')}}</div>
                </div>

                <div class="container-alerts">
                    <div class="card-container">
                        <div v-for="(it, index) in PatientAlerts" :key="`it.patientCode-${index}`">
                            
                            <div class="row dailyNotes">
                            <div class="col-2 mt-1">
                                <b-tooltip :target="'EditIcon-'+it.recId"  placement="top" :delay="{ show: 1500, hide: 50 }">
                                    {{$t('Shared.Edit')}}
                                </b-tooltip>
                                <b-tooltip v-if="!isReadOnly" :target="'DelIcon-'+it.recId"  placement="top" :delay="{ show: 1500, hide: 50 }">
                                    {{$t('Shared.Delete')}}
                                </b-tooltip>
                                <b-tooltip :target="'PrintIcon-'+it.recId"  placement="top" :delay="{ show: 1500, hide: 50 }">
                                    {{$t('Shared.Print')}}
                                </b-tooltip>
                                
                                <b-icon :id="'EditIcon-'+it.recId"  icon="pencil-fill" @click="editAlert(it)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                                <b-icon :id="'PrintIcon-'+it.recId"  icon="printer-fill" @click="printAlert(it)" class="rounded-circle tableActionBTN mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                                <b-icon :id="'DelIcon-'+it.recId" v-if="!isReadOnly && canCreateAlerts" icon="trash-fill" @click="deleteAlert(it)" class="rounded-circle tableActionBTN-danger mr-2" scale=".5" font-scale="1.8" variant="light"></b-icon>
                            </div>
                            <div class="col-3">
                                <small>{{ $t('PatientAlerts.DateAndTime') }}</small><br>{{ it.datechanged }}
                            </div>
                            <div class="col-3">
                                <small>{{ $t('PatientAlerts.User') }}</small><br>{{ it.userName }}
                            </div>
                            <div class="col-2">
                                <small>{{ $t('PatientAlerts.DontShowMeAgain') }}</small><br>{{it.notShowMeMoreTranslated}}
                            </div>
                            <div class="col-2">
                                <small>{{ $t('PatientAlerts.Active') }}</small><br>{{it.isActiveTranslated}}
                            </div>
                            </div>

                            <div class="row dailyNotesContent">
                                <div v-html="it.memoinfo" class="col-12"></div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <hr/>
                <div class="row mt-4">
                    <div class="my-1 col-sm-7 col-md-6">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            @change="onChange"
                            pills
                            size="lg"
                        ></b-pagination>
                    </div>
                    <div align="right" class="mr-2 col">
                        {{ $t('Shared.Show') }}
                        <b-form-select 
                            v-model="perPage" 
                            :options="options" 
                            size="sm" 
                            id="perPageSelect"
                            v-bind:style="perPageStyle"
                            @change="perPageChange">
                        </b-form-select>
                        {{ $t('Shared.Of') }} {{totalRows}}
                    </div>
                </div>

            </div>
        </div>
        <div class="space-actions"></div>
        <div class="page-actions">
        <MdButton
            v-bind:isReadOnly="PatientAlerts.length == 0"
            v-bind:Label="$t('Shared.Report')"
            v-bind:Name="'btndailyreport'"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-on:click="printAlert()"/>
        </div>

        <PatientAlertRep v-if="isReportVisible" 
            :closeWindow="closeParamRepWindow"
            :patientCode="this.$route.params.id"
            :patientDob="this.PatientTitle.dob"
            :recNo="recNoToReport"
            :patientName="this.PatientTitle.fullName"
            :userName="userName"
        />
    </div>
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from '@/common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import ActivitiesService from '@/services/activities.service'
import StorageService from '@/services/storage.service'
import UserService from '@/services/user.service'
import LocalStorage from '@/services/storage.service'

export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
    },

    name: 'PatientAlertList',
    mixins: [DBFunctions],

    data() {
        return {
            Menu : this.$store.state.leftMenu,
            InfoPatient:{},
            PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:''
            }, 
            OptionsRightMenu:{
				visible:true,
				ItemsList:[],
				btnvisible:true,
				itemdisabled: false,
				PatientCode:''
			},
            IsClinical:false,
            SearchCriteria:'',
            DateFrom:null,
            DateTo:null,
            isReadOnly:false,
            isDateFromValid:true,
            isDateToValid:true,
            ListStatus:EnumService.PatientAlertListStatus,
            status: {
                selected:true,
                value:'Y',
                text:this.$t('Shared.Active')
            }, 
            permission:[],

            PatientAlerts: [],
            IsSupervisor: StorageService.IsSupervisor(),
            
            Header:{},
            currentPage: 1,
            perPage: 5,
            totalRows: 0, 
            options: [
                { value: 5, text: '5' },
                { value: 10, text: '10' },
                { value: 15, text: '15' },
            ],
            perPageStyle: {
                width: 'auto'
            },
            showEmptyRecords: true,
            emptyRecordsStyle: {
                backgroundColor: '#CF0',
                fontWeight: 'bold !important',
                textTransform: 'uppercase',
                padding: '2px',
                borderRadius: '50px',
                animation: 'fadeOut 10s ease-in-out infinite'
            },
            canCreateAlerts: true,
            isReportVisible: false,
            recNoToReport: null,
            userName:''
        }
    },

    methods: {
        CanView(){
            return this.permission[0].roleCanView;
        },

        getListing() {
            const param = {
                PatientCode: this.$route.params.id,
                IsAlert: true,
                PageNumber: this.currentPage,
                PageSize: this.perPage,
                DateTo: Utils.formatterToISO(this.DateTo),
                DateFrom: Utils.formatterToISO(this.DateFrom),
                SearchCriteria: this.SearchCriteria,
                Active: !this.status.value ? null : (this.status.value == 'Y' ? true : false),
            }

            ActivitiesService
                .getAll(param)
                .then(response => {
                    if (response.status == "200" && response.data) {
                        this.PatientAlerts = response.data.map(data => ({
                            userid: data.userid,
                            datechanged: Utils.formatterDateTimeToString(data.datechanged),
                            memoinfo: data.memoinfo,
                            recId: data.recId,
                            doNotShow: false,
                            userName: data.userName,
                            isActiveTranslated: data.isActiveTranslated,
                            notShowMeMoreTranslated: data.notShowMeMoreTranslated
                        }));

                        let headerString = response.headers["x-pagination"];
                        this.Header = JSON.parse(headerString);
                        this.totalRows = this.Header.TotalCount;
                        this.showEmptyRecords = response.data && response.data.length == 0 ? true : false
                        this.$emit('showEmptyRecords', this.showEmptyRecords);
                    }
                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                    this.$emit('load', false);
                });
        },

        SearchAlerts() {
            if (this.validateDate()) {
                this.$emit('load', true);
                this.getListing();
            } else {
                this.showMessage(this.$t('PatientAlerts.PatientAlerts'), 
                    this.$t('Msg.InvalidDate') + ' ' + this.$t('Shared.Format') + ' '+ Utils.getShortDateFormat(), "errorAutoHide");          
            }
        },

        AddAlert() {
            if (this.$route.name.includes('Clinical')) {	
                this.$router.push({ name: "Clinical-patient-alert-add", params: { id: this.$route.params.id, recordid:this.$route.params.recordid } });
            } else if (this.$route.name.includes('appointments-')) {
                this.$router.push({ name: "appointments-alert-add", params: { id: this.$route.params.id, actId: this.$route.params.actId} });
            } else {  
                this.$router.push({ name: "patient-alert-add", params: { id: this.$route.params.id } });
            }
        },

        editAlert(item) {
            if (this.$route.name.includes('Clinical')) {	
                this.$router.push({ name: "Clinical-patient-alert-edit", params: { id: this.$route.params.id, recordid:this.$route.params.recordid, code: item.recId } });
            } else if (this.$route.name.includes('appointments-')) {
                this.$router.push({ name: "appointments-alert-edit", params: { id: this.$route.params.id, actId: this.$route.params.actId, code: item.recId } });
            } else {  
                this.$router.push({ name: "patient-alert-edit", params: { id: this.$route.params.id, code: item.recId } });
            }
        },

        deleteAlert(item) {
            const alertId = item.recId
            this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('PatientAlerts.DeleteAlertTitle'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
                    id:'modal_delete_alert'
				})
                .then(value => {
                    if(value) {
                        this.$emit('load', true);
                        ActivitiesService
                            .delete(alertId)
                            .then(() => {
                                this.getListing();
                            })
                            .catch((error) => {
                                this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                                this.$emit('load', false);
                            });
                    }
                })
        },
        closeParamRepWindow() {
            this.isReportVisible = false
            this.recNoToReport = null
        },
        
        printAlert(item) {
            if(this.PatientAlerts.length > 0){
                if (item && item.recId) {
                    this.recNoToReport = item.recId
                    this.userName = item.userName
                }
                this.isReportVisible = true
            } else {
                setTimeout(() => 
                this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
                    title:  this.$t('PatientAlerts.PatientAlerts'),
                    autoHideDelay: 3000,
                    variant: "success"
                }),
                50);
            }
        },

        perPageChange(value) {
            this.$emit('load', true);
            this.perPage = value;
            this.getListing();
        },
    
        onChange(page) {
            this.$emit('load', true);
            this.currentPage = page;
            this.getListing();
        },

        validateDate(){
            this.isDateFromValid = this.DateFrom == null || this.$refs["DateFrom"].editIsValid();
            this.isDateToValid = this.DateTo == null || this.$refs["DateTo"].editIsValid();
            return this.isDateFromValid && this.isDateToValid;
        },

        ChangeStatus(status){
            this.status = { value: null, text: this.$t('Shared.All') };
            if (status) {
                this.status.value = status.value;
                this.status.text = status.text;
                this.status.selected = status.selected;
            }

            setTimeout(() => {
                this.SearchAlerts();
            }, 50, this);
        },

        async getUseCanCreateAlerts() {
            UserService.getUserByCode(LocalStorage.getUserId())
                .then((response) => {
                    if(response.status == '200' && response.data){    
                        if(response.data.length > 0){
                            this.canCreateAlerts = response.data[0].canCreateAlerts;
                        }else{
                            this.canCreateAlerts = false;
                        } 
                    }  
                    
                    this.$emit('load', false);                          
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                    this.$emit('load', false);
                });
        },

        async GetPatientByCode() {
            var infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0){
                this.PatientTitle.programName = '';
                this.PatientTitle.admitDate = '';
                this.PatientTitle.dischargeDate = '';
                
                const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

                if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age;
                if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
            }
        },

        PopulatePatientTitle(){
            const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
            if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
            if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
            
            if(this.$route.name.includes('Clinical-patient-alerts')){ 
                if (programName) this.PatientTitle.programName = programName; 
                if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
                if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
                if (patientName) this.PatientTitle.fullName = patientName;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age === 0 ? "0" : age;
                if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
            } else if (this.$route.name.includes('patient-alerts')) {         
                this.PatientTitle.programName = '';
                this.PatientTitle.dateFrom = '';
                this.PatientTitle.dateTo = '';
            }
        },
        

        async PopulateTitle() {
            if(!this.$route.name.includes('Clinical-patient-alerts')){  
                await this.GetPatientByCode();
            } else {      
                this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
                const { dateTo, age } = this.RegistrationInfo; // Destructure for easier access

                this.IsClinical = dateTo ? false : true;
                if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
            }
            
            this.PopulatePatientTitle();
        },

        async getCurrentAppointment() {
            const currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
            this.PatientTitle.activitytypename  = currentAppointmentDB.activitytypename
            this.PatientTitle.startdatetime = `${Utils.formatterDateToString(currentAppointmentDB.transdate)} ${currentAppointmentDB.timein}`  
        }
    },

    created() {
        this.getPermissionLeftMenu(this.Menu, this.$route.path, this.permission);
    },

    async mounted() {
        this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-patient-alerts') ? EnumService.ClinicalItems : 
            this.$route.name.includes('appointments-alerts') ? EnumService.AppointmentItems : EnumService.PatientItems;
		this.OptionsRightMenu.Mode = '';
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.active_el = this.$route.name.includes('Clinical-patient-alerts') ? 'ClinicalAlerts' : 'PatientAlerts';
		this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.isReadOnly = this.$store.state.isPatientAlertsEHRReadOnly;

        this.$emit('load', true);
        await this.PopulateTitle();
        if (this.$route.name.includes('appointment'))
            await this.getCurrentAppointment();
            
        await this.getUseCanCreateAlerts();
        this.getListing();
    },

    destroyed() {
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },
}
</script>