<template>
    <div>
      <b-modal :no-stacking="this.Stacked" :ref="modalId" :id="modalId" size="xl" scrollable centered :no-close-on-backdrop="true" :no-close-on-esc="true"  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal" :cancel-title="$t('Shared.BtnCancel')"> 
        <Loading v-bind:IsLoading="this.Load" /> 
        <b-row>
          <b-col md="3">
            <MdSelect
              v-bind:Label="$t('Shared.Filter')"
              v-bind:Options="this.OptionParamList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:AllowEmpty="false"
              v-bind:Model="modelFilter"                                                
              v-bind:Multiple="false"
              :Input="ChangeDropDownFilter"
             
         />
          </b-col>
          <b-col md="9">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
             
              <div class="input-group">
                  <input 
                    type="text" 
                    class="form-control"
                      v-model="parameter.SearchCriteria"
                      @keyup.enter="Search()"
                      ref="inputSearchCriteria"
                      id="inputSearchCriteria"
                  >
                  <div class="input-group-append">
                      <button class="btn btn-sm search-attached" type="button" v-on:click="Search()"
                        id="buttonSearchCriteria"
                      >
                          <i class="mdi mdi-magnify"></i>
                      </button>
                  </div>
              </div>
            </div>
          </b-col>   
        </b-row>
        <b-row>
          <b-col md="12">
            <MdTable 
            v-bind:fields="fields"
            v-bind:items="items"
            v-bind:totalRows="totalRows"
            v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
            v-bind:currentPage="currentPage"
            v-bind:pageOptions="pageOptions"
            v-bind:refTable="refTable"
            v-bind:perPage="perPage"
            :onRowSelected="onRowSelected"
            :ondblClick="ondblClicked"
            :onFiltered="onFiltered"
            :pageChanged="pageChanged"
            :perPageFunction="PerPageFunction"
            :empty-text="$t('MdTable.nRecShow')">
          </MdTable>
          </b-col>
        </b-row>
      </b-modal>
    </div>
</template>
  
  <script>
  
  import ModalService from '@/services/modal.service'
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import Loading from '@/components/template/loading/Loading'
  import Utils from '@/common/utils'
  import { EventBus } from '@/event-bus.js';
    export default {
      mixins: [ShowMessage],
      components: {
          Loading
      },
      props: {
       Fields: Array,
       TypeOption: String,
       Resource:String,
       TitleWindow:String,
       FieldCode:String,    
       FieldDescription:String,
       ModalId: String,
       OptionParamList:Array,
       AuxFields:Array,
       Stacked:[Boolean],
       FieldsDate: Array,
       UseFilter:Boolean,
       modelFilter:Object,    
       FilterChange:Function,
     },
     watch:{
      ModalId(newId){
        if(newId){
          this.modalId = newId;
        }
  
      }
  
     },
      data() {
        return {
          modalId: this.ModalId ? this.ModalId : "bv-modal-table-paginationServerMultiple",
          ShowMessageIsEmpty:false,
          parameter:{
            SearchCriteria:'',
            PageNumber : 0,
            PageSize:0,
          },    
          Api:this.Resource,
          fields:this.Fields, 
          Header:{},          
          pageOptions: [5, 10, 15 ],
          totalRows: 1,
          currentPage: 1,
          perPage: 5,
          filter: null,
          filterOn: [],
          items: [],
          selected: [],
          rowSelected:{
            code:'',
            description:'',
            FieldName:''
          },
          modelFilterParam:this.modelFilter,
          OptionList:this.OptionParamList,
          refTable: 'selectableTable',
          Load:true,
        }
      },
      
      methods:{
        emitEventOK(/*bvModalEvt*/){ 
         // bvModalEvt.preventDefault();
          this.$emit('getCodeConditionOK', this.rowSelected);
          this.ResetProps();
          this.parameter.SearchCriteria="";
            if(this.rowSelected.code !== '')
              EventBus.$emit('IsCorrectCode', true);   
        },
  
        emitEventCancel(){
          this.$emit('getCodeConditionCancel');
          this.ResetProps();
        }, 
  
        ChangeDropDownFilter(m){ 
          this.currentPage = 1;        
          this.modelFilterParam = m;
          this.FilterChange(m);
          this.Load = true;    
          setTimeout(() =>  this.getData(), 100);       
        },
  
        ondblClicked(item){      
           this.rowSelected = {
            FieldName:"",
            code:"",
            description:"",
            AuxFields:[]
          };      
          this.rowSelected.FieldName = this.TypeOption;
          this.rowSelected.code = item ? item[this.FieldCode] : null;
          this.rowSelected.description = item ? item[this.FieldDescription]: null;
       if(this.AuxFields !== undefined){
          if(this.AuxFields.length > 0){
            for(var i = 0; i < this.AuxFields.length; i++){
              if(item){
                this.rowSelected.AuxFields.push(item[this.AuxFields[i]]);
              }            
            }
          }
       } 
       this.emitEventOK();
        },
  
        resetModal(){
          this.parameter.SearchCriteria="";
        },
  
        ResetProps(){
           this.items = [];
        },
  
        pageChanged (page) {         
          this.currentPage = page;        
          this.getData();
        },
  
        PerPageFunction(perpage){
         this.perPage = perpage;
         this.currentPage = 1;
         this.getData();
        },
  
        Search(){
          this.currentPage = 1;
          this.parameter.PageNumber = 1;
          this.getData();
        },
  
        getData() {
          this.parameter.PageNumber = this.currentPage;              
          this.parameter.PageSize = this.perPage; 
          if(this.Resource != ''){
            this.Load = true; 
            ModalService.getAll(this.Resource,this.parameter)
              .then((response) => {
                if (response.status == '200' && response.data) {   
                  this.items = response.data;                    
                  this.items.forEach(element => {
                    if(this.FieldsDate) {
                      this.FieldsDate.forEach(fieldDate => {
                        if (Utils.isValidDate(element[fieldDate])){
                          element[fieldDate] = Utils.formatterDateToString(element[fieldDate])
                        }
                      });
                    }
                      /*if(this.TypeOption === 'Program'){
                          element.inPatient = element.isInPatient ? 'Y' : 'N';
                      } */                  
                  });
                  let headerString = response.headers["x-pagination"];
                  this.Header = JSON.parse(headerString);       
                  this.totalRows = this.Header.TotalCount;
                  if(this.items.length === 0) {
                      this.ShowMessageIsEmpty = true;
                  }           
                }
                this.Load = false;          
              })
              .catch(error => {
                this.Load = false;
                if (!error.response) {
                      // network error
                  this.showMessage(this.TitleWindow,this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.TitleWindow,error,"error");
                }
              }); 
          }     
        },
  
        onRowSelected(item) {  
          this.rowSelected = {
            FieldName:"",
            code:"",
            description:"",
            AuxFields:[]
          };      
          this.rowSelected.FieldName = this.TypeOption;
          this.rowSelected.code = item[0] ? item[0][this.FieldCode] : null;
          this.rowSelected.description = item[0] ? item[0][this.FieldDescription]: null;
       if(this.AuxFields !== undefined){
          if(this.AuxFields.length > 0){
            for(var i = 0; i < this.AuxFields.length; i++){
              if(item[0]){
                this.rowSelected.AuxFields.push(item[0][this.AuxFields[i]]);
              }            
            }
          }
       }     
        },
  
        selectAllRows() {
          this.$refs.selectableTable.selectAllRows()
        },
  
        clearSelected() {
          this.$refs.selectableTable.clearSelected()
        },
  
        selectThirdRow() {
          // Rows are indexed from 0, so the third row is index 2
          this.$refs.selectableTable.selectRow(2)
        },
  
        unselectThirdRow() {
          // Rows are indexed from 0, so the third row is index 2
          this.$refs.selectableTable.unselectRow(2)
        },
  
        onFiltered(filteredItems) {
          // Trigger pagination to update the number of buttons/pages due to filtering
          this.totalRows = filteredItems.length
          this.currentPage = 1
        }
        ,
        focusInput() {
          if (this.$refs.inputSearchCriteria)
            this.$refs.inputSearchCriteria.focus();
        },
  
        setZIndex(){
          let editorZIndex = document.getElementById('Schedule_dialog_wrapper') && 
                             document.getElementById('Schedule_dialog_wrapper').style ?
                             Number(document.getElementById('Schedule_dialog_wrapper').style.zIndex) + 1 : null
          if (document.getElementById('ee-334-5rt654___BV_modal_outer_') && document.getElementById('ee-334-5rt654___BV_modal_outer_').style && editorZIndex) {
            document.getElementById('ee-334-5rt654___BV_modal_outer_').style.zIndex = editorZIndex;
          }
        }  
      },
  
      mounted() {
        this.modelFilterParam = this.modelFilter;    
        this.$root.$on('bv::modal::shown', (bvEvent, _modalId) => {
          
        if(_modalId === this.modalId){ 
         this.getData();
        }        
          
        this.currentPage = 1;
        this.setZIndex()     
        this.$nextTick(() => {
          setTimeout(() =>  this.focusInput(), 500);
        });
      });
    },

      beforeDestroy() {
        this.$root.$off("bv::modal::shown", null);
      },
       /*created () {
          EventBus.$on("OndblClicked", function (item) {       
            this.rowSelected = item;  
            this.rowSelected.FieldName = this.TypeOption;
            console.log(this.TypeOption);
            this.rowSelected.description = item ? item[this.FieldDescription]: null;
            this.rowSelected.AuxFields = [];
              if(this.AuxFields !== undefined){
          if(this.AuxFields.length > 0){
            for(var i = 0; i < this.AuxFields.length; i++){
              if(item){   
                this.rowSelected.AuxFields.push(item[this.AuxFields[i]]);
              }            
            }
          }
       }  
            this.emitEventOK();
          }.bind(this));  
       },*/
      /*beforeDestroy () {  
        console.log('Destroyed');   
        EventBus.$off('OndblClicked');
      },*/
    }
  
  </script>