<template>
 <div id="ContainerAdmissionList">
  <div class="row">
      <div class="col-12">
        <MdTitleRegInfo
          v-bind:RegistrationInfo="PatientTitle" 
          v-bind:titlePrefix="$t('AdmissionList.PatientAdmissionTitle')" 
          v-bind:titleRef="'titleElementAdmissionList'"
          v-bind:patientCode="this.$route.params.id">
        </MdTitleRegInfo> 

        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('AdmissionList.AddAdmission')" 
              v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"
              v-on:click="AddAdmission"
              v-bind:isReadOnly=this.isReadOnly
              v-bind:Name="'btn_add_admission'">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="parameter.SearchCriteria"   
                  @keyup.enter="SearchAdmission">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinput'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="SearchAdmission">
                      </MdButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>              
        </div>
      </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchElements')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fields"
              v-bind:items="items"
              v-bind:totalRows="totalRows"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:pageOptions="pageOptions"
              :editIconEvent="EditAdmission"
              v-bind:VisibleEditIcon="true"
              :deleteIconEvent="RemoveAdmission"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelected"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction"
              :otherIcon="'printer-fill'"
              :otherIconEvent="showReport">
            </MdTable>
          </div>
        </div>
      </div>
    </div>
    <div class="space-actions"></div>
    <div class="page-actions">
      <MdButton
        v-bind:isReadOnly="items.length == 0"
        v-bind:Label="$t('Shared.Report')"
        v-bind:Name="'btnadmisionreport'"
        v-bind:ClassProp="'secondary mx-2 btn-login'"
        v-on:click="showReport()"/>
    </div>

    <AdmissionsRep v-if="isReportVisible" 
      :closeWindow="closeParamRepWindow"
      :patientCode="this.$route.params.id"
      :recNo="recNoToReport"
      :patientName="this.PatientTitle.fullName"
      />
 </div>
</template>

<script>
import EnumService from '@/services/enum.service' 
import ClinicalService from '@/services/clinical.service'
import Utils from '@/common/utils'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import AdmissionService from '@/services/admission.service'

export default {
  name: 'AdmissionList',
 mixins: [ShowMessage, DBFunctions],
  data(){
      return {
        FieldsDateArr:['dateFrom','dateTo'],
        AdmissionId:'',
        isReadOnly:false,
        Loading:true,
        Mode:'',     
        parameter:{
        SearchCriteria:'',
        PageNumber : 1,
        PageSize:0 ,
         
      },
       OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
        },
      PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:'',
       activitytypename:'',
       startdatetime:'',
       ageFormatted:''
     },  
      ShowMessageIsEmpty:false,
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],         
      fields: [
        { key: 'programName', sortable: true, label: this.$tc('Shared.Program'), class: 'text-rigth' },
        { key: 'dateFrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth' },
        { key: 'dateTo', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth' },   
        { key: 'transactionType', sortable: true, label: this.$t('AdmissionList.TransactionType'), class: 'text-rigth' },       
        { key: 'programCode', sortable: true, label: this.$t('Shared.ProgramCode'), class: 'text-center hidden' },
        { key: 'status', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth' },
        { key: 'recordId', sortable: true, label: this.$t('AdmissionList.RecordId'), class: 'text-center hidden' } ,
        { key: 'chartNo', sortable: true, label: this.$t('AdmissionList.ChartNo'), class: 'text-center' },       
        { key: 'isInpatient', sortable: true, label: this.$t('Shared.Inpatient'), class: 'text-center' },
        { key: 'buildingName', sortable: true, label: this.$t('Shared.Building'), class: 'text-center' },
        { key: 'roomName', sortable: true, label: this.$t('Shared.Room'), class: 'text-center' },              
        ],
        currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee:{
            firstname:"",
            lastname:""
          }
        },
        isReportVisible: false,
        recNoToReport: null,
      }
  },
  methods:{

    AddAdmission(){
        this.Mode = "Add";
          if (this.$route.name.includes('appointments-admissionList')){
            this.$router.push({ name: "appointments-admission-add", params: { id: this.$route.params.id,recordid: this.$route.params.recordid, actId: this.$route.params.actId} });
          } else
          this.$router.push({ name: "patient-admission-add", params: { id: this.$route.params.id,recordid: this.$route.params.recordid } });
    },

    async GetPatientByCode(){
      let infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';

          const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

          if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
          if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
          if (age) this.PatientTitle.age = age;
          if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
      }
    },

    SearchAdmission(){
      this.Loading = true;
      this.$emit('load', this.Loading)        
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.$route.params.id;
      ClinicalService.getAllByPatientCode(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data){
            this.items = response.data;
            if(response.data.length > 0){                        
            this.items.forEach(function(element) {
                let { dob, dateFrom, dateTo } = element
                if (dob) element.dob = Utils.formatterDateToString(dob);  
                if (dateFrom) element.dateFrom = Utils.formatterDateToString(dateFrom);        
                if (dateTo) element.dateTo =  Utils.formatterDateToString(dateTo);           
            });
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
            }
          }  
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch((error) => {
            this.Loading = false;
            this.$emit('load', this.Loading);
            if (!error.response) {
              this.showMessage(this.$t('AdmissionList.ClinicalProgramFilter'),this.$t('Msg.retrieveInfo'),"error");
            } else {
              this.showMessage(this.$t('AdmissionList.ClinicalProgramFilter'),error,"error");
            }
        }); 
    },

    EditAdmission(row){
        this.Mode = "Edit";
        if (this.$route.name.includes('appointments-admissionList')){
            this.$router.push({ name: "appointments-admission-edit", params: { id: this.$route.params.id, recordid: row.recordId, actId: this.$route.params.actId} });
        } else
        this.$router.push({ name: "patient-admission-edit", params: {id:this.$route.params.id, recordid: row.recordId } });
    },

    RemoveAdmission(row){            
           const RecordId = row.recordId;
           this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('AdmissionList.DeleteAdmission'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id: 'modal_delete_admission'
				})
          .then(value => {
            if(value){
              this.$emit('load', true);  
               AdmissionService.DeleteAdmission(RecordId)
                .then(() => {
                  const index = this.items.findIndex(item => item.recordId === RecordId) 
                  if (~index) {
                    this.items.splice(index, 1) 
                    this.SearchAdmission(); 
                  }
                })
                .catch((error) => {
                  this.$emit('load', false);  
                    if(error.response.status === 400 ){
                     this.showMessage(this.$t('AdmissionList.DeleteAdmission'),error.response.data.errorMessage,"error");
                     }else{
                        this.showMessage(this.$t('AdmissionList.DeleteAdmission'),error,"error");
                     }  
                  /*if (!error.response) {
                    // network error
                    this.showMessage("Delete Admission",this.$t('Msg.NotAbleToDelete'),"error");
                  } else {
                    this.showMessage("Delete Admission",error,"error");
                  }*/
                });
            }
          })
    },
   
    pageChanged (page) {            
      this.currentPage = page;    
      this.SearchAdmission();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.SearchAdmission();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    },

    showReport(item) {
      if(this.items.length > 0){
        if (item && item.recordId) {
          this.recNoToReport = item.recordId
        }
        this.isReportVisible = true
      } else {
        setTimeout(() => 
          this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
            title:  this.$t('DailyNoteList.DailyNotesTitle'),
            autoHideDelay: 3000,
            variant: "success"
          }),
        50);
      }
    },

    closeParamRepWindow() {
      this.isReportVisible = false
      this.recNoToReport = null
    },

  },

 async mounted() {
    this.GetPatientByCode();
    if (this.$route.name.includes('appointments-admissionList'))
      await this.getCurrentAppointment()
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-admissionList') ? EnumService.AppointmentItems : EnumService.PatientItems;
    //this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.isReadOnly = this.$store.state.isPatientAdmissionEHRReadOnly; 
    this.SearchAdmission();
  },
   
};
</script>


