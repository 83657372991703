<template>
    <div id="modalEditProblem">
        <!--
            Eliminar los comentarios innecesarios tras resolver cada tarea.
            Ya puedes eliminar este. Cumpliste con leerlo. Jajaja.
        //-->

        <!-- Esta ventana debería tener los botones [Cancel][Save] en lugar de [Cancel][OK] -->
        <b-modal id="modalIdEditProblem" size="xl" scrollable :title="TitleWindow" @ok="this.emitEventOK" :cancel-title="$t('Shared.BtnCancel')"
            style="height:100%">

            <!----------------------->
            <!-- R E F E R E N C E -->
            <!----------------------->

            <!-- Revisar los parámetros de este tag. No sé por qué tantas cosas. -->
            <MdTitle v-bind:IconAddDescription="$t('modalEditProblem.bDescription')" v-bind:Text="$t('modalEditProblem.Reference')" v-bind:isSubtitle="true"
                v-bind:Name="'Behaviorals'" class="shrinkMargin" />
            <b-row class="mr-0">
                <b-col>
                    <div class="textlabel" :data-label="$t('modalEditProblem.Problem')"></div>
                    <div class="ml-3">
                        <!-- Actualizar el problema -->
                        <p>Fake Data: Attention-Deficit/Hyperactivity Disorder (ADHD) - Adolescent</p>
                    </div>
                </b-col>
                <b-col>
                    <div class="textlabel" :data-label="$t('MdPatientDiagCodes.Diagnosis')"></div>
                    <div class="ml-3">
                    <!-- Actualizar el diagnóstico -->
                        <p>Fake Data: Moderate or Severe Alcohol Use Disorder With Alcohol-Induced Major Neurocognitive
                            Disorder, Non-amnestic-Confabulatory Type</p>
                    </div>
                </b-col>
            </b-row>

            <!--------------------------->
            <!-- B E H A V I O R A L S -->
            <!--------------------------->

            <div class="subtitleButtons">
                <div class="containerBoxTitle">
                    <!-- Añadir las acciones (@click) reales a todos los botones. Estas son copias falsas. -->
                    <b-icon icon="plus-circle-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddBlankBehaviorTT')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                    <b-icon icon="shield-fill-plus" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddBehaviorWiTT')" scale=".5" font-scale="1.6" variant="light"></b-icon>
                </div>
            </div>
            <h4 class="page-subtitle text-truncate">{{$t('modalEditProblem.behav')}}</h4>

            <!-- Si no hay Behaviors, ocultar esta línea -->
            <!-- Si hay uno solo, usar la palabra Description -->
            <!-- Si hay más de uno, usar la palabra en plural: Descriptions -->
            <div class="textlabel" :data-label="$t('Shared.Description')"></div>
            <b-row v-for="(behavioral, behaindex) in this.SelectedObj.problemDefinitions" :key="'Behavioral_' + behaindex">
                <b-col sm="10" lg="11">
                    <MdMemo v-bind:Name="'BehavioralDefinitions'" v-bind:Rows="'4'" v-bind:UseDinamicClass="true"
                        v-bind:DynamicClass="'modalTextarea ml-2'" v-bind:MaxRows="'6'"
                        v-bind:MaxLength="'500'" v-model="behavioral.definitionDescription" />
                </b-col>
                <b-col sm="2" lg="1" class="d-flex justify-content-end">
                    <b-icon @click="DeleteBehavioral(behaindex)" icon="trash-fill" aria-label="trash fill"
                        v-b-tooltip.hover.top="$t('modalEditProblem.DelbehaviorTT') + (behaindex+1)"
                        class="rounded-circle bg-secondary editTitleButton p-2 mb-3" font-scale="2.2" variant="light">
                    </b-icon>
                </b-col>
            </b-row>
            <b-row class="d-flex align-items-center">
                <b-col class="infoLabel">
                    <!-- Adiciona la clase "hidden" si hay Behaviors que mostrar -->
                    <span class="m-2 "><b-icon icon="info-circle-fill" scale="1" class="mx-1"></b-icon> {{$t('modalEditProblem.noActivityText', {'param':'Behavioral'}) }}
                    <span class="d-none d-lg-inline-block">{{$t('modalEditProblem.rightButtonsText') }}</span></span>
                </b-col>
            </b-row>
            <!-- Eliminar si no lo vas a usar más...

                <b-icon 
                @click="AddBehavioralWiley"
                icon="file-earmark-plus-fill" 
                font-scale="3" 
                v-b-tooltip.hover.top="'Add Wiley Behavioral'"
                class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                variant="light">
            </b-icon>
            <b-icon 
                @click="AddBehavioral"
                icon="plus-circle-fill" 
                font-scale="3" 
                v-b-tooltip.hover.top="'Add Blank Behavioral'"
                class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                variant="light">
            </b-icon>-->

            <!--------------->
            <!-- G O A L S -->
            <!--------------->

            <div v-for="(g, goalindex) in this.SelectedObj.goals" :key="'Goal_' + goalindex" class=" mb-2">
                <div class="subtitleButtons">
                    <div class="containerBoxTitle">
                        <!-- Actualizar acciones @click y controlar el tamaño de los íconos con la escala (default .5) -->
                        <b-icon icon="plus-circle-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddBlankGoalTT')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                        <b-icon icon="shield-fill-plus" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddGoalWiTT')" scale=".5" font-scale="1.6" variant="light"></b-icon>
                        <!-- Este botón (add objective) se verá sólo si ya hay un goal creado previamente -->
                        <b-icon icon="node-plus-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddObjetiveTT')" scale=".7" font-scale="1.6" variant="light"></b-icon>
                        <!-- Este botón (delete goal #) se verá sólo si ya hay un goal creado previamente -->
                        <b-icon icon="trash-fill"  @click="DeleteProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.DelGolaTT')+ (goalindex + 1)" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                    </div>
                </div>
                <!-- Este título (Goal #) se verá sólo si ya hay un goal creado previamente -->
                <!-- si no existe, debe mostrar la palabra Goals -->
                <h4 class="page-subtitle text-truncate">Goal {{goalindex + 1}}</h4>
                
                <b-row>
                    <b-col>
                        <div class="textlabel" :data-label="$t('Shared.Description')"></div>
                        <MdMemo v-bind:Name="'goaldescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="true"
                            v-bind:DynamicClass="'modalTextarea ml-2'" v-bind:MaxRows="'6'" v-bind:MaxLength="'500'"
                            v-model="g.goal.goalDesc" />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="3">
                        <MdEdit v-bind:Label="$t('modalEditGoal.dIdentified')" v-bind:Name="'DateIndentified'" v-bind:TypeProp="'text'"
                            v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                            v-model="g.goal.dateSet" />
                    </b-col>
                    <b-col md="3">
                        <MdEdit v-bind:Label="$t('modalEditGoal.eDate')" v-bind:Name="'ExpectedDate'" v-bind:TypeProp="'text'"
                            v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                            v-model="g.goal.dateExp" />
                    </b-col>
                    <b-col md="3">
                        <MdEdit v-bind:Label="$t('modalEditGoal.aDate')" v-bind:Name="'AchievedDate'" v-bind:TypeProp="'text'"
                            v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                            v-model="g.goal.dateAchieve" />
                    </b-col>
                    <b-col md="3">
                        <MdSelect v-bind:Label="$t('Shared.Status')" v-bind:Options="StatusList"
                            v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                            v-bind:Model="g.goal.statusobj" v-bind:Multiple="false" :Input="ChangeDropDownStatus"
                            v-bind:Name="'status_select'" :id="'status_select_'" />
                    </b-col>
                </b-row>

                <!------------------------->
                <!-- O B J E C T I V E S -->
                <!------------------------->

                <div class="mb-2 ml-3" v-for="(o, oindex) in g.objectives" :key="'objective' + oindex">
                    <div class="subtitleButtons">
                        <div class="containerBoxTitle">
                            <!-- Actualizar acciones @click y controlar el tamaño de los íconos con la escala (default .5) -->
                            <b-icon icon="plus-circle-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddBlankObjTT')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                            <b-icon icon="shield-fill-plus" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddObjWiTT')" scale=".5" font-scale="1.6" variant="light"></b-icon>
                            <!-- Este botón (add Intervention) se verá sólo si ya hay un objective creado previamente -->
                            <b-icon icon="node-plus-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddInterTT')" scale=".7" font-scale="1.6" variant="light"></b-icon>
                            <!-- Este botón (delete objective #) se verá sólo si ya hay un objective creado previamente -->
                            <b-icon icon="trash-fill"  @click="DeleteProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.DelObjTT')+ (oindex + 1)" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                        </div>
                    </div>
                    <!-- Este título (Objective # (Goal #)) se verá sólo si ya hay un Objective creado previamente -->
                    <!-- si no existe, NO debe mostrarse este bloque -->
                    <h4 class="page-subtitle text-truncate">{{$t('modalEditObjective.Objective')}} {{oindex + 1}} ({{$t('modalEditGoal.goal')}} {{goalindex + 1}})</h4>

                    <b-row>
                        <b-col>
                            <div class="textlabel" :data-label="$t('Shared.Description')"></div>
                            <MdMemo v-bind:Name="'objectivedescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="true"
                                v-bind:DynamicClass="'modalTextarea ml-2'" v-bind:MaxRows="'6'" v-bind:MaxLength="'500'"
                                v-model="o.objective.objDesc" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col md="3">
                            <MdEdit v-bind:Label="$t('modalEditGoal.dIdentified')" v-bind:Name="'DateIndentified'" v-bind:TypeProp="'text'"
                                v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                                v-model="o.objective.dateSet" />
                        </b-col>
                        <b-col md="3">
                            <MdEdit v-bind:Label="$t('modalEditGoal.eDate')" v-bind:Name="'ExpectedDate'" v-bind:TypeProp="'text'"
                                v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                                v-model="o.objective.dateExp" />
                        </b-col>
                        <b-col md="3">
                            <MdEdit v-bind:Label="$t('modalEditGoal.aDate')" v-bind:Name="'AchievedDate'" v-bind:TypeProp="'text'"
                                v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                                v-model="o.objective.dateAchieve" />
                        </b-col>
                        <b-col md="3">
                            <MdSelect v-bind:Label="$t('Shared.Status')" v-bind:Options="StatusList"
                                v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                                v-bind:Model="o.objective.statusobj" v-bind:Multiple="false"
                                :Input="ChangeDropDownObjectiveStatus" v-bind:Name="'status_objective_select'"
                                :id="'status_objective_select'" />
                        </b-col>
                    </b-row>

                    <!------------------------------->
                    <!-- I N T E R V E N T I O N S -->
                    <!------------------------------->

                    <div class="mb-2 ml-3" v-for="(i, iindex) in o.interventions" :key="'intervention' + iindex">
                        <div class="subtitleButtons">
                            <div class="containerBoxTitle">
                                <!-- Actualizar acciones @click y controlar el tamaño de los íconos con la escala (default .5) -->
                                <b-icon icon="plus-circle-fill" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddBlankIntTT')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                                <b-icon icon="shield-fill-plus" @click="EditProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.AddIntWiTT')" scale=".5" font-scale="1.6" variant="light"></b-icon>
                                <!-- Este botón (delete intervention #) se verá sólo si ya hay una intervention creada previamente -->
                                <b-icon icon="trash-fill"  @click="DeleteProblem(item)" v-b-tooltip.hover.top="$t('modalEditProblem.DelIntTT')+ (iindex + 1)" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                            </div>
                        </div>
                        <!-- Este título (Intervention # (Goal #: obj. #) se verá sólo si ya hay una Intervention creada previamente -->
                        <!-- si no existe, NO debe mostrarse este bloque -->
                        <h4 class="page-subtitle text-truncate">{{$t('modalEditIntervention.interV')}} {{iindex + 1}} ({{$t('modalEditGoal.goal')}} {{goalindex + 1}}: obj. {{ oindex + 1 }})</h4>

                        <b-row>
                            <b-col>
                                <div class="textlabel" :data-label="$t('Shared.Description')"></div>
                                <MdMemo v-bind:Name="'intvdescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="true"
                                    v-bind:DynamicClass="'modalTextarea ml-2'" v-bind:MaxRows="'6'" v-bind:MaxLength="'500'"
                                    v-model="i.intDesc" />
                            </b-col>
                        </b-row>
                        <b-row> 
                            <b-col lg="3">
                                <MdEdit v-bind:Label="$t('GroupNotes.StartDate')" v-bind:Name="'StartDate'" v-bind:TypeProp="'text'"
                                    v-bind:Mask="'##/##/####'" v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"
                                    v-model="i.startDate" />
                            </b-col>
                            <b-col lg="3">
                                <MdEdit v-bind:Label="'No'" v-bind:TypeProp="'number'" v-bind:Name="'No'"
                                    v-model.number="i.noAmt">
                                </MdEdit>
                            </b-col>

                            <b-col lg="3">
                                <MdSelect v-bind:Label="$t('modalEditIntervention.amount')" v-bind:Options="AmountList"
                                    v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                                    v-bind:Model="i.amount" v-bind:Multiple="false" :Input="ChangeDropDownAmount"
                                    v-bind:Name="'status_amount_select'" :id="'status_amount_select'" />
                            </b-col>
                            <b-col lg="3">
                                <MdEdit v-bind:Label="'No'" v-bind:TypeProp="'number'" v-bind:Name="'No'"
                                    v-model.number="i.noFrequency">
                                </MdEdit>
                            </b-col>

                            <b-col lg="3">
                                <MdSelect v-bind:Label="$t('Shared.Frequency')" v-bind:Options="FrequencyList"
                                    v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                                    v-bind:Model="i.frequency" v-bind:Multiple="false" :Input="ChangeDropDownFrequency"
                                    v-bind:Name="'status_frequency_select'" :id="'status_frequency_select'" />
                            </b-col>
                        
                            <b-col lg="3">
                                <MdEdit v-bind:Label="'No'" v-bind:TypeProp="'number'" v-bind:Name="'No'"
                                    v-model.number="i.noDuration">
                                </MdEdit>
                            </b-col>

                            <b-col lg="3">
                                <MdSelect v-bind:Label="$t('MdPainAssessmentNotesOutCome.Duration')" v-bind:Options="DurationList"
                                    v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                                    v-bind:Model="i.duration" v-bind:Multiple="false" :Input="ChangeDropDownDuration"
                                    v-bind:Name="'status_duration_select'" :id="'status_duration_select'" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <div class="textlabel" :data-label="$t('modalEditIntervention.Modalities')"></div>
                                <!-- No sé cómo funciona este componente -->
                                <MdCheckListBox2 class="mx-1" v-model="i.therapyCodes" v-bind:Options="TherapyList"
                                    v-bind:OnChangeProp="OnChangeModalities" v-bind:columnCount="1" :textField="'name'"
                                    :valueField="'code'" />
                            </b-col>
                        </b-row>
                    </div>
                    <!--<div class="mx-5">
                        <b-icon 
                        @click="AddInterventionWiley"
                        icon="file-earmark-plus-fill" 
                        font-scale="3" 
                        v-b-tooltip.hover.top="'Add Intervention Wiley'"
                        class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                        variant="light">
                    </b-icon>
                    <b-icon 
                        @click="AddIntervention(goalindex,oindex)"
                        icon="plus-circle-fill" 
                        font-scale="3" 
                        v-b-tooltip.hover.top="'Add Blank Intervention'"
                        class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                        variant="light">
                    </b-icon>
                    </div>-->

                    <!-- CUANDO YA FUNCIONE TODO, REVISAMOS SI ESTO ESTÁ EN EL ORDEN EN QUE VA -->
                    <!-- PORQUE NO ME QUEDA CLARO SU ESTRUCTURA ANIDADA DESDE EL EJEMPLO ACTUAL -->

                    <b-row class="d-flex align-items-center ml-1">
                        <b-col class="infoLabel">
                        <!-- Adiciona la clase "hidden" si hay Interventions que mostrar -->
                            <span class="m-2 "><b-icon icon="info-circle-fill" scale="1" class="mx-1"></b-icon> {{$t('modalEditProblem.noActivityText', {'param':'Interventions'}) }}
                            <span class="d-none d-lg-inline-block">{{$t('modalEditProblem.rightButtonsText') }}</span></span>
                        </b-col>
                    </b-row>
                    <b-row class="d-flex align-items-center">
                        <b-col class="infoLabel">
                            <!-- Adiciona la clase "hidden" si hay Objectives que mostrar -->
                            <span class="m-2 "><b-icon icon="info-circle-fill" scale="1" class="mx-1"></b-icon> {{$t('modalEditProblem.noActivityText', {'param':'Objetives'}) }}
                            <span class="d-none d-lg-inline-block">{{$t('modalEditProblem.rightButtonsText') }}</span></span>
                        </b-col>
                    </b-row>
                </div>

                <!-- Eliminar si ya no lo usas

                    <b-icon 
                    @click="AddObjectivelWiley"
                    icon="file-earmark-plus-fill" 
                    font-scale="3" 
                    v-b-tooltip.hover.top="'Add Objective Wiley'"
                    class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                    variant="light">
                </b-icon>
                <b-icon 
                    @click="AddObjective(goalindex)"
                    icon="plus-circle-fill" 
                    font-scale="3" 
                    v-b-tooltip.hover.top="'Add Blank Objective'"
                    class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                    variant="light">
                </b-icon>-->
                <b-row class="d-flex align-items-center">
                    <b-col class="infoLabel">
                        <!-- Adiciona la clase "hidden" si hay Goals que mostrar -->
                        <span class="m-2 "><b-icon icon="info-circle-fill" scale="1" class="mx-1"></b-icon> {{$t('modalEditProblem.noActivityText', {'param':'Goals'}) }}
                        <span class="d-none d-lg-inline-block">{{$t('modalEditProblem.rightButtonsText') }}</span></span>
                    </b-col>
                </b-row>
            </div>
            <!-- Eliminar si ya no lo usas
                
                <b-icon 
                @click="AddGoalWiley"
                icon="file-earmark-plus-fill" 
                font-scale="3" 
                v-b-tooltip.hover.top="'Add Goal Behavioral'"
                class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                variant="light">
            </b-icon>
            <LookupFromIconPlus
                v-bind:Tooltip="'Add Goal Wiley'"
                v-bind:RefCode="'InsuranceCode'"
                v-bind:Api="'Insurances'"
                v-bind:FieldCode="'insurancecode'"
                v-bind:FieldDescription="'company'"
                :GetValues="getValuesGoalWiley"
                
            />
            <b-icon 
                @click="AddGoal"
                icon="plus-circle-fill" 
                font-scale="3" 
                v-b-tooltip.hover.top="'Add Blank Goal'"
                class="rounded-circle bg-secondary editTitleButton p-2 mt-2" 
                variant="light">
            </b-icon>-->
        </b-modal>
    </div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
import TherapyService from '@/services/therapy.service'
export default {
    name: 'ModalEditProblem',
    props: {
        ModalId: String,
        TitleWindow: String,
        SelectedObj: Object

    },
    mixins: [DBFunctions, EventBus],
    data() {
        return {
            test: [],
            TherapyList: [],
            DateIndentified: null,
            FileContents: "",
            fields: [{ first: '', last: '' }],
            problem: [],
            StatusList: [
                { code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS') },
                { code: "MET", description: this.$t('modalEditGoal.MET') },
                { code: "UNMET", description: this.$t('modalEditGoal.UNMET') },
                { code: "REVISED", description: this.$t('modalEditGoal.REVISED') },
                { code: "EXTENDED", description: this.$t('modalEditGoal.EXTENDED') },
                { code: "DEFERRED", description: this.$t('modalEditGoal.DEFERRED') },
                { code: "REFERRED", description: this.$t('modalEditGoal.REFERRED') }
            ],
            AmountList: [
                { code: "SEC", description: this.$tc('modalEditIntervention.SEC') },
                { code: "MIN", description: this.$tc('modalEditIntervention.MIN') },
                { code: "HOUR", description: this.$tc('modalEditIntervention.HOUR')},
                { code: "DAY", description: this.$tc('modalEditIntervention.DAY') },
            ],
            DurationList: [
                { code: "SEC(S)", description: this.$tc('modalEditIntervention.SEC',2) },
                { code: "MIN(S)", description: this.$tc('modalEditIntervention.MIN',2)},
                { code: "HOUR(S)", description: this.$tc('modalEditIntervention.HOUR',2) },
                { code: "DAY(S)", description: this.$tc('modalEditIntervention.DAY',2) },
                { code: "WEEK(S)", description: this.$tc('modalEditIntervention.WEEK',2) },
                { code: "MONTH(S)", description: this.$tc('modalEditIntervention.MONTH',2) },
                { code: "YEAR(S)", description: this.$tc('modalEditIntervention.YEAR',2) },
            ],
            FrequencyList: [
                { code: "HOUR", description: this.$tc('modalEditIntervention.HOUR') },
                { code: "DAY", description: this.$tc('modalEditIntervention.DAY') },
                { code: "WEEK", description: this.$tc('modalEditIntervention.WEEK') },
                { code: "BIWEEK", description: this.$t('modalEditIntervention.BIWEEK') },
                { code: "MONTH", description: this.$tc('modalEditIntervention.MONTH') },
                { code: "QUARTER", description: this.$t('modalEditIntervention.QUARTER') },
                { code: "YEAR", description: this.$tc('modalEditIntervention.YEAR') },
                { code: "AS ORDER", description: this.$t('modalEditIntervention.aORDER')},
            ],
            status: { code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS') },
            goaldescription: "",
            Behavioraldescription: "",
        }
    },

    methods: {

        getValuesGoalWiley(value) {
            console.log('Paso por Lookup From Icon Plus');
            console.log(value);
        },

        AddField: function () {
            this.fields.push({ first: '', last: '' });
        },

        DeleteBehavioral(index) {
            this.SelectedObj.problemDefinitions.splice(index, 1);
        },

        OnChangeModalities() {

        },

        AddBehavioralWiley() {

        },

        AddObjectivelWiley() {

        },

        DeleteIntervention() {

        },

        AddInterventionWiley() {

        },

        AddIntervention(gindex, oindex) {
            this.SelectedObj.goals[gindex].objectives[oindex].interventions.push(
                {
                    amount: 0,
                    coSignedDate: "",
                    coTherapistCode: "",
                    coTherapistName: "",
                    dateTimeCreate: "",
                    duration: "",
                    frequency: "",
                    goalRecId: "",
                    intCode: "",
                    intDesc: "",
                    intDescPlainText: "",
                    intRecId: "",
                    intTokens: "",
                    isEvidenceBased: false,
                    noAmt: 0,
                    noDuration: 0,
                    noFrequency: 0,
                    objectiveRecId: "",
                    probRecId: "",
                    signedDate: null,
                    startDate: null,
                    sysUser: "",
                    therapistCode: "",
                    therapistName: "",
                    therapyCode: "",
                    therapyCodes: [],
                    userName: "",
                }
            )
        },

        async getTherapies() {
            this.$emit('load', true);
            await TherapyService.getTherapyTreatmentPlan()
                .then((response) => {
                    if (response.status == '200' && response.data) {
                        this.TherapyList = response.data;
                    }
                    this.$emit('load', false);
                })
                .catch(error => {
                    this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                    }
                });
        },

        AddObjective(gindex) {
            this.SelectedObj.goals[gindex].objectives.push(
                {
                    interventions: [],
                    objective: {
                        dateAchieve: null,
                        dateExp: null,
                        dateSet: null,
                        dateTimeCreate: null,
                        goalRecId: "",
                        isEvidenceBased: false,
                        objCode: 0,
                        objDesc: "",
                        objDescPlainText: null,
                        objRecId: "",
                        objTokens: null,
                        probRecId: "",
                        status: "",
                        statusobj: { code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS') },
                        sysUser: "",
                        userName: ""
                    }
                }
            )
        },

        AddGoalWiley() {

        },

        DeleteObjective(gindex, oindex) {
            this.SelectedObj.goals[gindex].objectives.splice(oindex, 1);
        },

        ChangeDropDownObjectiveStatus() {

        },

        ChangeDropDownFrequency() {

        },
        ChangeDropDownDuration() {

        },

        AddBehavioral() {
            this.SelectedObj.problemDefinitions.push(
                {
                    definitionDescription: "",
                    definitionDescriptionPlainText: null,
                    definitionNumber: 0,
                    definitionRecId: "",
                    probRecId: ""
                }
            );
        },

        AddGoal() {
            this.SelectedObj.goals.push(
                {
                    goal:
                    {
                        dateAchieve: null,
                        dateExp: null,
                        dateSet: null,
                        dateTimeCreate: null,
                        goalCode: 0,
                        goalDesc: "",
                        goalDescPlainText: null,
                        goalRecId: null,
                        goalTokens: null,
                        probRecId: null,
                        status: "INPROGRESS",
                        statusobj: { code: "INPROGRESS", description: this.$t('modalEditGoal.INPROGRESS') },
                        sysUser: "",

                    },
                    objectives: [],
                }

            );


        },

        DeleteGoal(index) {
            this.SelectedObj.goals.splice(index, 1);
        },

        resetModal() {

        },

        ChangeDropDownAmount() {

        },

        ChangeDropDownStatus() {

        },


        handleOk() {

        },



        emitEventCancel() {

        },



        emitEventOK() {


        },
    },

    mounted() {
        this.getTherapies();
    },

    created() {

    }
}


</script>

<style>
.GoalEdit {
    border-style: solid;
    border-color: darkcyan;
}

.ObjectiveEdit {
    border-style: solid;
    border-color: darkolivegreen;
}

.boxObjective {
    padding-top: 0;
    padding-right: 30px;
    padding-bottom: 40px;

}

.containerGoal {
    padding: 20px;
}

.boxintv {
    padding-top: 0;
    padding-right: 30px;
    padding-bottom: 40px;

}

.containerintv {
    padding: 20px;
}
</style>


