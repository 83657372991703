import ApiService from '@/services/api.service'

const TreatmentPlanService = {
    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/TreatmentPlanProblems/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
              AdmissionId:parameter.AdmissionId,
             // Code:parameter.Code,
             // HeaderId:parameter.HeaderId,
            }
          }       
          return ApiService.customRequest(requestData);
    },

    deleteGoal(id){
      return ApiService.delete(`/TreatmentPlanGoals/${id}`);
    },

    UpdateGoal(id,data){      
      return ApiService.put(`/TreatmentPlanGoals/${id}`,data);      
    },

    UpdateObjective(id,data){      
      return ApiService.put(`/TreatmentPlanObjectives/${id}`,data);      
    },

    UpdateIntervention(id,data){      
      return ApiService.put(`/TreatmentPlanInterventions/${id}`,data);      
    },

    deleteObjective(id){
      return ApiService.delete(`/TreatmentPlanObjectives/${id}`);
    },

    deleteIntervention(id){
      return ApiService.delete(`/TreatmentPlanInterventions/${id}`);
    },

    AddGoal(data){
      return ApiService.post(`/TreatmentPlanGoals`, data);
    },

    AddObjective(data){
      return ApiService.post(`/TreatmentPlanObjectives`, data);
    },

    AddIntervention(data){
      return ApiService.post(`/TreatmentPlanInterventions`, data);
    },

    AddSignature(data){
      return ApiService.post(`/TreatmentPlanSignatures`, data);
    },

    UpdateSignature(id,data){    
      return ApiService.put(`/TreatmentPlanSignatures/${id}`,data);      
    },

    deleteSignature(id){
      return ApiService.delete(`/TreatmentPlanSignatures/${id}`);
    },

    // Wiley Treatment Plan

    getWileyTxProblemGroups(ICD10Code){
      return ApiService.get(`/WileyTxProblemGroups?ICD10Code=${ICD10Code}`);
    },

    getWileyTxProblems(parameter){
      return ApiService.get(`/WileyTxProblems?GroupId=${parameter.GroupId}&AdmissionId=${parameter.AdmissionId}&NotesId=${parameter.NotesId}`);
    },

    getWileyTxBehavioralDefinitions(parameter){
      return ApiService.get(`/WileyTxBehavioralDefinitions?GroupId=${parameter.GroupId}&ProblemNumber=${parameter.ProblemNumber}&ExcludeWithHeaderId=${parameter.HeaderId}`);
    },

    getWileyTxGoals(parameter){
      return ApiService.get(`/WileyTxGoals?GroupId=${parameter.GroupId}&ProblemNumber=${parameter.ProblemNumber}&ExcludeWithHeaderId=${parameter.HeaderId}`);
    },

    getWileyTxObjectives(parameter){
      const params = {      
        GroupId : parameter.GroupId,
        ProblemNumber : parameter.ProblemNumber,
        GoalNumberList : parameter.GoalNumberList,
        ExcludeWithHeaderId: parameter.IsWizard ? "" : parameter.ExcludeWithHeaderId,      
      }
      return ApiService.getWithArray('/WileyTxObjectives/',params); 
    },

    getWileyTxInterventions(parameter){
      const params = {      
        GroupId : parameter.GroupId,
        ProblemNumber : parameter.ProblemNumber,
        GoalNumberList:parameter.GoalNumberList,
        ObjectiveNumberList : parameter.ObjectiveNumberList,
        ExcludeWithHeaderId: parameter.IsWizard ? "" : parameter.ExcludeWithHeaderId,        
      }
      return ApiService.getWithArray('/WileyTxInterventions/',params); 
    },

    getDiagnosestx(parameter){
      const requestData = {
        method: 'get',
        url: "/WileyTxDiagnoses/",       
        params: {
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,
          PageNumber: parameter.PageNumber,
          DSM:5
        }
      }       
      return ApiService.customRequest(requestData);
    },

    // MedEZ Treatment Plan

    getMedTxProblems(parameter){
      return ApiService.get(`/MedTxProblems?AdmissionId=${parameter.AdmissionId}&NotesId=${parameter.NotesId}`);
    },

    getMedTxProblemAggregates(parameter){
      return ApiService.get(`/MedTxProblemAggregates?Code=${parameter.ProblemNumber}&ExcludeWithHeaderId=${parameter.HeaderId ?? ""}`);
    },

    getMedTxGoals(parameter){
      return ApiService.get(`/MedTxGoals?ProblemCode=${parameter.ProblemNumber}&ExcludeWithHeaderId=${parameter.HeaderId ?? ""}`);
    },

    getMedTxObjectives(parameter){
      const params = {      
        ProblemCode : parameter.ProblemCode,
        GoalCodeList : parameter.GoalCodeList,
        ExcludeWithHeaderId: parameter.IsWizard ? "" : parameter.ExcludeWithHeaderId,      
      }
      return ApiService.getWithArray('/MedTxObjectives/',params); 
    },

    getMedTxInterventions(parameter){
      const params = {      
        ProblemCode : parameter.ProblemCode,
        GoalCodeList:parameter.GoalCodeList,
        ObjectiveCodeList : parameter.ObjectiveCodeList,
        ExcludeWithHeaderId: parameter.IsWizard ? "" : parameter.ExcludeWithHeaderId,        
      }
      return ApiService.getWithArray('/MedTxInterventions/',params);
    },

    UpdateProblem(id,data){      
        return ApiService.put(`/TreatmentPlanProblems/${id}`,data);      
    },

    UpdateDefinitions(id,data){      
      return ApiService.put(`/TreatmentPlanProblemDefinitions/${id}`,data);      
    },

    AddBlankBehavioral(data){
      return ApiService.post(`/TreatmentPlanProblemDefinitions`, data);
    },

    Add(data){
      return ApiService.post(`/TreatmentPlans`, data);
    },

    getTherapyTreatmentPlan(admid) {
      return ApiService.get(`/TreatmentPlans/Therapies/${admid}`);
    },

    getProblemsByAdmId(admissionId, notesid,Version, SearchCriteria, probCode = null) {
      let fm = notesid ? "NotesId=" + notesid : "";
      let vs = Version ? "&Version="+Version : "";
      let sc = SearchCriteria ? "&SearchCriteria=" + SearchCriteria : "";
      let pc = probCode ? "&ProbCode=" + probCode : "";
      return ApiService.get(`/TreatmentPlans/${admissionId}` + `?${fm}${sc}${vs}${pc}`);
    },   

    getProblemsByAdmIdTherapyHeader(admissionId, TherapyCode) {
      return ApiService.get(`/TreatmentPlans/Note/${admissionId}?TherapyCode=${TherapyCode ?? ""}`);
    },

    VoidTP(id, data){      
      return ApiService.put(`/TreatmentPlans/Void/${id}`, data);
    },

    UpdateProblemAggregates(id,data){      
      return ApiService.put(`/TreatmentPlanProblemAggregates/${id}`,data);      
    },
    getTPList(parameter){
      return ApiService.get(`/NotesTypes?IsTreatmentPlan=${parameter.IsTreatmentPlan}&showAll=${parameter.showAll}`);    
    },

    getAppointmentByActivity(parameter){
      return ApiService.get(`/Appointments?ActivityId=${parameter.ActivityId}`);    
    },
  }
  
  export default TreatmentPlanService;