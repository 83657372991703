<template>
   <div id="ContainerContact">
      <div class="row page-height">
         <div class="col-12">
          <MdTitleRegInfo
              v-bind:RegistrationInfo="PatientTitle" 
              v-bind:titlePrefix="title" 
              v-bind:titleRef="'titleElementAppoEditor'"
              v-bind:patientCode="editMode ? this.$route.params.id: this.patientCode">
            </MdTitleRegInfo>
         </div>
      </div>
      <b-row>
            <b-col md="6" lg="6" sm="12">
              <div class="textlabel" :data-label="this.$tc('Shared.Patient')">
                <span class="e-input-group e-control-wrapper">
                  <input
                    placeholder=""
                    id="subject"
                    class="e-field e-lib e-control e-input e-keyboard"
                    type="text"
                    value=""
                    name="subject"
                    ref="subject"
                  />
                  <span
                    v-on:click="CallModalTable()"
                    class="e-input-group-icon e-icons mdi mdi-magnify iconLupa"
                    id="btnsearchinput"
                  ></span>
                </span>
              </div>
              <input
                  id="patientCode" 
                  class="e-field e-input" 
                  type="hidden" 
                  value=""
                  name="patientCode"
                  ref="PatientCode"
                >
            <input
                  id="patientName" 
                  class="e-field e-input" 
                  type="hidden" 
                  value=""
                  name="patientName"
                  ref="PatientName"
                >
             <input
                  id="activityId" 
                  class="e-field e-input" 
                  type="hidden" 
                  value=""
                  name="activityId"
                  ref="activityId"
                >
            </b-col>
            <b-col md="6" lg="6" sm="12">
              <MdSelect
                v-bind:Label="$tc('Shared.AppointmentType')"
                v-bind:Options="AppoinmentTypeList"
                v-bind:ModelArray="AppoinmentTypeVmodel"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'code'"
                v-bind:Placeholder="selectPlaceholder"
                v-bind:Multiple="false"
                v-bind:RequireEvent="true"
                v-bind:IfUsevalidation="false"
                :Input="ChangeDropDownAppoinmentType"/>
            </b-col>
        </b-row>
        <b-row>  
            <b-col md="6" lg="6" sm="12">
              <MdSelect
                  v-bind:Label="$tc('Shared.Therapist')"
                  v-bind:Options="TherapistList"
                  v-bind:ModelArray="TherapistVmodel"
                  v-bind:FieldDescription="'employeeName'"
                  v-bind:FieldCode="'employeeCode'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="false"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownTherapist"/> 
            </b-col>
            <b-col md="6" lg="6" sm="12">
              <MdSelect
                  v-bind:Label="$tc('Shared.Program',2)"
                  v-bind:Options="ProgramList"
                  v-bind:ModelArray="ProgramVmodel"
                  v-bind:FieldDescription="'programNameFormated'"
                  v-bind:FieldCode="'recordId'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="false"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownPrograms"/>
            </b-col>
        </b-row>
        <MdTitle v-bind:Text="$t('schedule-editor.Timing')"  v-bind:isSubtitle="true" v-bind:Name="'T_Timing'"/>
        <b-row>
            <b-col md="6" lg="6" sm="12">
              <MdEdit 
                 v-bind:Label="$t('MdCurrentAppointment.StarTime')"
                 v-bind:TypeProp="'datetime-local'" 
                 v-bind:Name="'startTime'"
                 v-bind:Min="'1900-01-01'"
                 v-bind:Max="'2200-12-31'"
                 v-model="startTime"
                 v-bind:isRequired="true"
                 v-bind:isValid="this.isStartTime"
                 v-on:blur="changeStartTime"/>                                     
            </b-col>
            <b-col md="6" lg="6" sm="12">
              <MdEdit 
                 v-bind:Label="$t('MdCurrentAppointment.EndTime')"
                 v-bind:TypeProp="'datetime-local'" 
                 v-bind:Name="'endTime'"
                 v-bind:Min="'1900-01-01'"
                 v-bind:Max="'2200-12-31'"
                 v-model="endTime"
                 v-bind:isRequired="true"
                 v-bind:isValid="this.isEndTime"
                 v-on:blur="changeEndTime"/>                                     
            </b-col>
        </b-row>
        <b-row>
          <b-col md="6" lg="6" sm="12">
            <ejs-recurrenceeditor ref="EditorObj" :change='recurrenceChange' :locale=locale></ejs-recurrenceeditor>
          </b-col>
        </b-row>
        <MdTitle v-bind:Text="$t('schedule-editor.AppotStatus')"  v-bind:isSubtitle="true" v-bind:Name="'T_AppotStatus'"/>
        <b-row>
          <b-col md="6" lg="6" sm="12">
            <MdCheckBox
              v-bind:Label="$t('schedule_sample.pConfirmed')"
              v-bind:Name="'checkbox-patientConfirmed'"
              v-bind:ClassProp="'AlignCheck'"
              v-bind:CheckedValue="true"
              v-bind:UncheckedValue="false"
              v-model="patientConfirmed"
              v-on:change="onPatientConfirmed"/>
          </b-col>
          <b-col md="6" lg="6" sm="12">
            <MdCheckBox
              v-bind:Label="$t('schedule_sample.pShowedUp')"
              v-bind:Name="'checkbox-patientShowedUp'"
              v-bind:ClassProp="'AlignCheck'"
              v-bind:CheckedValue="true"
              v-bind:UncheckedValue="false"
              v-model="patientShowedUp"
              v-on:change="onPatientShowedUp"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12" lg="12" sm="12">
            <MdSelect
                v-bind:Label="$tc('schedulerDailyRep.Reason')"
                v-bind:Options="reasonList"
                v-bind:ModelArray="reasonVmodel"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                v-bind:Placeholder="selectPlaceholder"
                v-bind:RequireEvent="true"
                v-bind:IfUsevalidation="false"
                :Input="ChangeDropDownReason"/> 
          </b-col>
        </b-row>
        <MdTitle v-bind:Text="$t('schedule-editor.Notes')"  v-bind:isSubtitle="true" v-bind:Name="'T_Notes'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
             v-bind:Name="'notes'"
             v-bind:Rows="'6'"
             v-bind:MaxRows="'6'"
             v-model="notesVmodel"/>
        </b-col>
      </b-row>

      <ModalTablePaginationServer
      v-if="showTablePaginationServer"
      v-bind:Resource="this.ResourceParameter"
      v-bind:TypeOption="this.TypeOption"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCodeConditionCancel="onCodeConditionCancel($event)"
      v-bind:Fields="this.fields"
      v-bind:FieldCode="this.fieldCode"
      v-bind:FieldDescription="this.fieldDescription"
      v-bind:TitleWindow="$t('schedule-editor.FindPatient')"
      v-bind:ModalId="this.modalId"
      v-bind:AuxFields="this.AuxFields"
      v-bind:FieldsDate="fieldsDate"
      v-bind:valueToSearch="this.$refs.subject.value">
    </ModalTablePaginationServer>

    <div class="space-actions"></div>
      <div class="page-actions">
        <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="this.editMode ? 'secondary mx-2 hidden':'secondary mx-2 btn-login'"
          v-on:click="goAppointmentList"
        />
        <MdButton
          v-bind:Label="$t('Shared.Save')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="Save"
          v-bind:Name="'btn_save_appointment'"
        />
      </div>
   </div>
</template>

<script>
const momentTZ = require('moment-timezone');
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import ClinicalService from '@/services/clinical.service'
import ReasonService from '@/services/reason.service'
import AppoinmentTypeService from '@/services/appointmenttypes.service'
import CalendarResourcesService from '@/services/calendarresources.service'
import DBFunctions from '@/common/DBFunctions.js'
import SchedulerService from '@/services/schedulers.service'
import GlobalService from '@/services/global.service'
import EnumService from "@/services/enum.service";
import { RecurrenceEditorComponent } from "@syncfusion/ej2-vue-schedule";

export default {

   beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isAppointments;
            if(iUA) next()
            else next('/accessdenied');
        }) 
   },

   beforeRouteLeave(to, from, next) {
	if (this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Appointments.appointmentEditor'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
                /** Add for Fovorite button states */
              this.checkExistFavorite('/app/appointments');  
              next();
            } else {
              this.Save();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],
   props: ["editMode"],  
   name: 'AppointmentEditor',
   components: {
    "ejs-recurrenceeditor": RecurrenceEditorComponent
  },
    data() {
      return {
        fields: [
          {
            key: "lastname",
            sortable: true,
            label: this.$t('Shared.LastName'),
            class: "text-rigth",
          },
          {
            key: "firstname",
            sortable: true,
            label: this.$t('Shared.FirstName'),
            class: "text-rigth",
          },
          { key: "dob", sortable: true, label: this.$t('Shared.Dob'), class: "text-rigth" },
          {
            key: "patientcode",
            sortable: true,
            label: this.$t('Shared.PatientCode'),
            class: "text-rigth",
          },
          {
            key: "patientid",
            sortable: true,
            label: this.$t('Patient.PatientId'),
            class: "text-rigth",
          },
          {
            key: "cellPhone",
            sortable: true,
            label: this.$t('Shared.CellPhone'),
            class: "text-rigth",
          },
          {
            key: "dayPhone",
            sortable: true,
            label: this.$t('Shared.DayPhone'),
            class: "text-rigth",
          },
          {
            key: "nightPhone",
            sortable: true,
            label: this.$t('Shared.NightPhone'),
            class: "text-rigth",
          },
          {
            key: "email",
            sortable: true,
            label: this.$t('Shared.Email'),
            class: "text-rigth",
          },
          { key: "zip", sortable: true, label: this.$t('Shared.Zip'), class: "text-rigth" },
        ],
        fieldCode: "patientcode",
        fieldDescription: "firstname", 
        PatientTitle:{      
          programName:'',
          fullName:'',
          admitDate:'',
          dischargeDate:'',
          dob:'',
          activitytypename:'',
          startdatetime:'',
          ageFormatted:'',
          appointmentType: '',
        },
        fullParam: {
            PageSize:0,
            PageNumber:0
        },
        patientCode: '',
        patientName: '',
        nameAppointmentType: '',
        startDateTime: '',
        fieldsDate: ['dob'],
        AppoinmentTypeList:  [],
        TherapistList:[],
        ProgramList:[],
        reasonList: [],
        AppoinmentTypeVmodel: {},
        TherapistVmodel: {},
        ProgramVmodel: {},
        reasonVmodel: {},
        notesVmodel: "",
        selectPlaceholder: this.$t('ClinicalNotes.SelectOptions'),
        startTime: null,
        isStartTime: true,
        endTime: null,
        isEndTime: true,
        patientConfirmed: false,
        patientShowedUp: false,
        currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee:{
            firstname:"",
            lastname:""
          }
        },
        subject: null,
        showTablePaginationServer: false,
        ResourceParameter: "",
        TypeOption: "",
        modalId: "",
        AuxFields: ["lastname", "cellPhone", "dayPhone", "nightPhone"],
        refCode: "PatientName",
        api: "patients",
        phone: null,
        pendingChanges: false,
        title: this.editMode ? this.$t('Appointments.editAppointment') : this.$t('Appointments.addAppointment'),
        prepareData: {
          StartDate: null,
          EndDate: null,
          action: '',
          key: '',
          added: {},
          changed: {},
          deleted: {}
        },
        timezone:'',
        OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,
        },
        locale: localStorage.language
      }
    },

    methods:{

      onPatientConfirmed(item) {
        let subject = this.$refs.subject.value
        if (subject.endsWith("- CANCELLED")) {
          subject = subject.replaceAll(/ - CANCELLED/g, "");
        } else if (subject.endsWith("- CONFIRMED")) {
          subject = subject.replaceAll(/ - CONFIRMED/g, "");
        } else if (subject.endsWith("- SHOWED UP")) {
          subject = subject.replaceAll(/ - SHOWED UP/g, "");
        }

        if (item && this.patientShowedUp) {
          subject = `${subject} - SHOWED UP`;
        } else if (item) {
          subject = `${subject} - CONFIRMED`;
        } else if (this.patientShowedUp) {
          subject = `${subject} - SHOWED UP`;
        }
        
        this.$refs.subject.value = subject
      },

      onPatientShowedUp(item) {
        let subject = this.$refs.subject.value
        if (subject.endsWith("- CANCELLED")) {
          subject = subject.replaceAll(/ - CANCELLED/g, "");
        } else if (subject.endsWith("- CONFIRMED")) {
          subject = subject.replaceAll(/ - CONFIRMED/g, "");
        } else if (subject.endsWith("- SHOWED UP")) {
          subject = subject.replaceAll(/ - SHOWED UP/g, "");
        }

        if (item && this.patientConfirmed) {
          subject = `${subject} - SHOWED UP`;
          this.reasonVmodel = {}
        } else if (this.patientConfirmed) {
          subject = `${subject} - CONFIRMED`;
        } else if (item) {
          subject = `${subject} - SHOWED UP`;
          this.reasonVmodel = {} 
        }
        this.$refs.subject.value = subject
      },

      async getTimeZone() { 
         await GlobalService.getTimeZone()
            .then((response) => {      
               if (response.data) {
                this.timezone = response.data.id
               }  
            })
            .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage(this.$t('schedule-editor.Scheduler'),error.response.data.errorMessage,"error");
              }else{
                this.showMessage(this.$t('schedule-editor.Scheduler'),error,"error");
              }
            });   
      },

      setPrepareData() {
        let startDateWithZone =  momentTZ.tz(Utils.formatterDate(this.startTime)+'T00:00:00', this.timezone)
        let endDateWithZone = momentTZ.tz(Utils.formatterDate(this.endTime)+'T00:00:00', this.timezone)

        this.prepareData = {
          StartDate: startDateWithZone.toISOString(),
          EndDate:  endDateWithZone.toISOString(),
          action: this.editMode ? 'update' : 'insert',
          added: this.editMode === false ? this.getData() : [],
          changed: this.editMode === true ? this.getData() : [],
          deleted: []
        }
      },

      getData() {
        let data = [{
          Id: this.editMode ? this.currentAppointmentDB.activityid : '',
          StartTime: momentTZ.tz(this.startTime, this.timezone),
          EndTime: momentTZ.tz(this.endTime, this.timezone),
          Subject: this.$refs.subject.value,
          Type: this.AppoinmentTypeVmodel && this.AppoinmentTypeVmodel.code ? this.AppoinmentTypeVmodel.code : null,
          employeeCode: this.TherapistVmodel && this.TherapistVmodel.employeeCode ? this.TherapistVmodel.employeeCode : null,
          patientCode: this.$refs.PatientCode.value,
          Notes: this.notesVmodel,
          AdmissionRecordId: this.ProgramVmodel && this.ProgramVmodel.recordId ? this.ProgramVmodel.recordId : null,
          Reason: this.reasonVmodel && this.reasonVmodel.code ? this.reasonVmodel.code : null,
          PatientShowedUp: this.patientShowedUp,
          PatientName: this.$refs.PatientName.value,
          PatientConfirmed: this.patientConfirmed,
          RecurrenceRule: this.currentAppointmentDB && this.currentAppointmentDB.recurrenceRule ? this.currentAppointmentDB.recurrenceRule : null
        }]
        return data
      },

      goAppointmentList() {
        this.checkExistFavorite('/app/appointments');
        this.$router.push({ name: "appointments" });
      },

      async Save() {
        this.pendingChanges = false;
        this.setPrepareData()
      
        await SchedulerService.schedulerUpdAdd(this.prepareData)
          .then((response) => {
            if(response.status == '200'){
              this.goAppointmentList()
            } 
          })
          .catch((error) => {
            this.Load = false;
            if(error.response) {
              this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
            }
          });
      },

      getPhoneNumber(cellPhone, dayPhone, nightPhone) {
        if (cellPhone) return cellPhone;
        if (dayPhone) return dayPhone;
        if (nightPhone) return nightPhone;
        return "";
      },

      onCodeConditionCancel() {
        this.ResourceParameter = "";
        this.showTablePaginationServer = false;
      },

      setValid(value) {
        this.classValue = value ? "form-control" : "form-control is-invalid";
      },

      async onCodeConditionOk(result) {
        var type = result.FieldName;
        if (type.indexOf(this.refCode) !== -1 && result.code) {
          this.$refs.PatientCode.value = result.code;
          this.PatientCode = result.code;
          
          this.phone = result.AuxFields && this.getPhoneNumber(result.AuxFields[1],result.AuxFields[2],result.AuxFields[3])
          this.$refs.PatientName.value = `${result.description} ${result.AuxFields && result.AuxFields[0] ? result.AuxFields[0] : ''}`
          this.$refs.subject.value = `${this.$refs.PatientName.value} ${this.phone}`
          await this.getPrograms(result.code)
          
          this.ProgramVmodel = this.ProgramList.length > 0 ? this.ProgramList[0] : {}

          let changed = {
            "Changed":true,
            "ComponentName":'PatientCode'
          } 
          EventBus.$emit('onChanged', changed);

          this.setValid(true)

          if (result) {
            this.getValues(result);
          }
          this.PopulatePatientTitle(result.code)
        }

        this.ResourceParameter = "";
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId)
        })
        this.showTablePaginationServer = false
      },

      showModalTablePaginationServer() {
        this.showTablePaginationServer = true;
      },

      CallModalTable: async function() {
        if (this.refCode) {
          this.$emit("load", true);
          this.FieldsParameter = [this.fieldCode, this.fieldDescription];
          this.ResourceParameter = "/" + this.api;
          this.TypeOption = this.refCode;
          await this.showModalTablePaginationServer();
          this.$bvModal.show(this.modalId);
          this.$emit("load", false);
        }
      },

      changeStartTime() {
        let AppTypeDuration = 15

        this.startDateTime = this.PatientTitle.startdatetime = Utils.formatterDateTimeToString(this.startTime)
        
        if(this.AppoinmentTypeVmodel.duration)
          AppTypeDuration = this.AppoinmentTypeVmodel.duration
        
        let startTimeObj = new Date(this.startTime)
        let endTimeObj = new Date(startTimeObj.getTime() + AppTypeDuration*60000)
        this.endTime = Utils.formatDateTime(endTimeObj)
      },

      changeEndTime() {

      },

      ChangeDropDownReason(item) {
        this.reasonVmodel = item
        let subject = this.$refs.subject.value

        if (subject.endsWith("- CANCELLED")) {
          subject = subject.replaceAll(/ - CANCELLED/g, "");
        } else if (subject.endsWith("- CONFIRMED")) {
          subject = subject.replaceAll(/ - CONFIRMED/g, "");
        } else if (subject.endsWith("- SHOWED UP")) {
          subject = subject.replaceAll(/ - SHOWED UP/g, "");
        }
        
        if (item.code) {
          subject = `${subject} - CANCELLED`;
          this.patientShowedUp = false
        }
        else if (this.patientConfirmed && this.patientShowedUp) {
          subject = `${subject} - SHOWED UP`;
        } 
        else if (this.patientConfirmed) {
          subject = `${subject} - CONFIRMED`;
        } 
        else if (this.patientShowedUp) {
          subject = `${subject} - SHOWED UP`;
        }
        
        this.$refs.subject.value = subject
      },

      setEndTime(astartTime,duration) {
        let startTimeObj = new Date(astartTime)
        let endTimeObj = new Date(startTimeObj.getTime() + duration*60000)
        return Utils.formatDateTime(endTimeObj)
      },

      ChangeDropDownAppoinmentType(appttypes){
        this.AppoinmentTypeVmodel = appttypes;
        this.nameAppointmentType = this.PatientTitle.appointmentType = appttypes.description

        let duration = appttypes.duration;

        if (!this.editMode)
          this.startTime = Utils.formatterToISO(new Date(),Utils.getSchedulerDateTimeFormat())                          

        this.endTime = this.setEndTime(this.startTime,duration) 
      },

      ChangeDropDownTherapist(therapists){
        this.TherapistVmodel = therapists;
      },

      ChangeDropDownPrograms(programs){		
        this.ProgramVmodel = programs;
      },

      async getReasons() { 
        await ReasonService.getAll()
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.reasonList = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('ModalCancelAppointmentReason.Reason'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('ModalCancelAppointmentReason.Reason'),error,"error");
            }
          });
        },

      async getPrograms(patientCode) { 
        await ClinicalService.getPatientRegistrationsScheduler(patientCode ? patientCode : this.$route.params.id)
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.ProgramList = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$tc('Shared.Program',2),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$tc('Shared.Program',2),error,"error");
            }
          });
        },

      async getAppointmentTypes() { 
        await AppoinmentTypeService.getListing()
            .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0){
                this.AppoinmentTypeList = response.data;
              }
            })
            .catch(error => {
              if (!error.response) {
                this.showMessage(this.$t('Appointments.appointmentEditor'),this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.$t('Appointments.appointmentEditor'),error,"error");
              }
            });
      },

      async getEmployees() { 
        await CalendarResourcesService.getAll(this.fullParam)
            .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0){
                this.TherapistList = response.data;
              }
            })
            .catch(error => {
              if (!error.response) {
                this.showMessage(this.$t('Appointments.appointmentEditor'),this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.$t('Appointments.appointmentEditor'),error,"error");
              }
            });
        },

      async PopulatePatientTitle(patientCode) {
        let infoList = await this.getInfoTitle(patientCode ? patientCode : this.$route.params.id);
        if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

          if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
          if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
          if (age) this.PatientTitle.age = age;
          this.PatientTitle.fullName = this.editMode || patientCode ? firstname + ' '+ lastname : this.patientName;     
         
        }
      },
      
      getValues: function (value) {
        if(value.code && value.description) {
          this.patientCode = value.code;
          this.patientName = `${value.description} ${value.AuxFields && value.AuxFields[0] ? value.AuxFields[0] : ''}`
        } else {
          this.patientCode = ''
        }
      },

      populateData() {
        let recObject = this.$refs.EditorObj;
        recObject.setRecurrenceRule(this.currentAppointmentDB.recurrenceRule);

        this.$refs.PatientCode.value = this.currentAppointmentDB.patientcode
        this.$refs.PatientName.value = this.currentAppointmentDB.patientcode
        this.$refs.subject.value = this.currentAppointmentDB.subject
        this.AppoinmentTypeVmodel = this.AppoinmentTypeList.find(element => element.code === this.currentAppointmentDB.activitytypecode) 
        this.TherapistVmodel = this.TherapistList.find(element => element.employeeCode === this.currentAppointmentDB.therapistcode)
        this.notesVmodel = this.currentAppointmentDB.notes
        this.reasonVmodel = this.reasonList.find(element => element.code === this.currentAppointmentDB.reason) 
        this.patientConfirmed = this.currentAppointmentDB.isConfirmed
        this.patientShowedUp = this.currentAppointmentDB.private
        this.startTime =  Utils.setTimeToDateTime(this.currentAppointmentDB.transdate, Utils.formatTime3(this.currentAppointmentDB.timein),'YYYY-MM-DD[T]HH:mm:ss')
        this.endTime =  Utils.setTimeToDateTime(this.currentAppointmentDB.enddate, Utils.formatTime3(this.currentAppointmentDB.timeout),'YYYY-MM-DD[T]HH:mm:ss')
        this.ProgramVmodel = this.ProgramList.find(element => element.recordId === this.currentAppointmentDB.admissionRecordId)         
      },
      

      beforeWindowUnload(e) {
        if (this.pendingChanges) {
          e.preventDefault()
          e.returnValue = ''	
        }
      },
      async getCurrentAppointment() {
        this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
        this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
        this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
      },

      recurrenceChange: function (args) {
        this.currentAppointmentDB.recurrenceRule = args.value
      }
    },

    async mounted(){
      this.OptionsRightMenu.visible = this.$route.name == 'Appointment-Editor-Add' ? false : true;    
      this.OptionsRightMenu.btnvisible = this.$route.name == 'Appointment-Editor-Add' ? false : true;
      this.OptionsRightMenu.itemdisabled = false;
      this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems;
      this.$emit('getVisibleMenu', this.OptionsRightMenu);

      this.PopulatePatientTitle()
    },

    async created () {
      this.modalId = 'ee-334-5rt654';
      await this.getReasons()
      await this.getPrograms()
      await this.getEmployees()
      await this.getAppointmentTypes()
      await this.getTimeZone()
      if (this.editMode) {
        await this.getCurrentAppointment()
        this.populateData()
      } else {
        this.startTime = Utils.formatterToISO(new Date(),'YYYY-MM-DD[T]HH:mm')
        this.endTime = this.setEndTime(new Date(),15)
      }
      EventBus.$on("onChanged", function (e) { 
          this.pendingChanges = e.Changed;
     }.bind(this));
    },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
      EventBus.$off('onChanged');
    }
  } 
</script>


