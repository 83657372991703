<template>
    <div>
      <MdTextSpeech />
      <body class="sidebar-fixed">    
          <div class="container-scroller">
          <div class="container-fluid page-body-wrapper full-page-wrapper">
            <div class="content-wrapper d-flex align-items-center auth px-0">
              <div class="row w-100 mx-0">
                <div class="col-lg-4 mx-auto">
                  <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div class="brand-logo">
                      <img src="../../../assets/images/logo.svg" alt="logo">
                    </div>
                    <div id="ContainerLogin" >
                      <div class="form-group">                  
                        <p class="text-muted">{{$t('Unauthorized.TextEmail')}}</p>
                        <hr>
                      </div>

                      <div v-if="errorMessage" class="pt-3">
                        <div id="alert" class="alert alert-fill-danger" role="alert">
                          <i class="mdi mdi-alert-circle"></i>
                          {{ errorMessage }}
                        </div>
                    </div>

                    <div class="pt-3" v-if="MessageOK">
                      <div id="alert" class="alert alert-fill-success" role="alert">                     
                        {{ $t('AccountExpired.MessageForgotPassOK') + EmailMask}}
                      </div>
                  </div>

                    <!--<b-alert show variant="success">Success Alert</b-alert>-->

                    <Loading v-bind:IsLoading="this.Load" />  
                    <MdEdit 
                        v-bind:Label="''"                       
                        v-bind:Placeholder="$t('Login.cId')" 
                        v-bind:Name="'ClientID'"
                        v-bind:MaxLengthProp="45"
                        v-model="ClientId"
                        v-bind:isReadOnly="false"
                        v-bind:OnFocus="onFocus"
                        v-on:blur="OnBlur(ClientId)"
                        v-bind:Id="'ClientID'"
                        v-bind:TypeProp="'text'"  
                        v-on:change="onChange($event)">                                     
                    </MdEdit>

                    <MdEdit 
                        v-bind:Label="''"                       
                        v-bind:Placeholder="$t('employee.UserID')" 
                        v-bind:Name="'UserId'"
                        v-bind:MaxLengthProp="45"
                        v-model="UserId"
                        v-bind:isReadOnly="false"
                        v-on:blur="OnBlur(UserId)"
                        v-bind:Id="'UserId'"
                        v-bind:OnPressEnter="onEnter"
                        v-bind:OnFocus="onFocus"
                        v-bind:TypeProp="'text'"  
                        v-on:change="onChange($event)">                                     
                    </MdEdit>

                     <!-- <div id="ContainerLogin" >
                        <div class="form-group">
                          <input 
                            type="text" 
                            ref="uClient" 
                            class="form-control form-control-lg" 
                            id="uClientId" 
                            v-on:focus="onFocus"
                            v-on:blur="OnBlur"
                            :placeholder="$t('Login.cId')" 
                            autofocus>
                        </div>
                  
                        <div class="form-group">
                          <input 
                            type="text" 
                            ref="uName" 
                            v-on:focus="onFocus"
                            v-on:blur="OnBlur"
                            v-on:keyup.enter="onEnter" 
                            class="form-control form-control-lg" 
                            id="uName" 
                            :placeholder="$t('Login.UserName')" 
                            maxlength="6">
                        </div>
                        </div>-->

                      <div class="mt-3">
                        <div class="row">
                          <div class="col-12 text-right">
                            <MdButton 
                              v-bind:Label="$t('AccountExpired.BtLogin')"
                              v-bind:Name="'btnSignIn'"
                              v-bind:ClassProp="'btn btn-login auth-form-btn'"
                              v-on:click="gotologin">
                            </MdButton>
                            <MdButton 
                              v-bind:isReadOnly="(ClientId === '' || UserId === '' || Disabled) ? true : false"
                              v-bind:Label="$t('AccountExpired.SendEmail')"
                              v-bind:Name="'btnSignIn'"
                              v-bind:ClassProp="'btn btn-login btn-primary auth-form-btn ml-3'"
                              v-on:click="SendEmail">
                          </MdButton>
                          </div>
                        </div>
                      </div>
                        <!--
                        <div class="row">
                          <div class="col-6"> 
                            <MdButton 
                              v-bind:Label="$t('AccountExpired.BtLogin')"
                              v-bind:Name="'btnSignIn'"
                              v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                              v-on:click="gotologin">
                            </MdButton>
                          </div>
                          <div class="col-6">
                            <MdButton 
                              v-bind:isReadOnly="(ClientId === '' || UserId === '' || Disabled) ? true : false"
                              v-bind:Label="$t('AccountExpired.SendEmail')"
                              v-bind:Name="'btnSignIn'"
                              v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                              v-on:click="SendEmail">
                            </MdButton>
                          </div>
                        </div>
                       -->
                       
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>     
          </div> 
      </body>
    </div>
    </template>
    
    <script>
    import UserService from '@/services/user.service'
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import Loading from '@/components/template/loading/Loading'
    export default {
      name: 'ForgotPassword',
      components: {Loading},
      mixins: [ShowMessage],
      data(){
        return{
          ClientId:"",
          EmailMask:"",
          UserId:"",
          Disabled:false,         
          Load:false,
          MessageOK:false,
          errorMessage:"",
        }
      },

     
      methods:{
    
        gotologin(){
           this.$router.push('/login').catch(()=>{});
        },

        onFocus(){
          this.errorMessage = "";
          this.MessageOK = false;
        },

        OnBlur(){
         
        },

        onChange(){

        },

        onEnter(){
          this.SendEmail();
        },

        SendEmail(){        
          
        if( this.ClientId !== '' && this.UserId !== '' ){
           this.Load = true;
           this.$emit('load', this.Load);
           let data = {
            ClientId: this.ClientId,
            UserId: this.UserId
          };
         UserService.SendEmail(data)
            .then((response) => {
              if(response.status == '200'){ 
                this.EmailMask = response.data.maskedEmail;
                this.Disabled = true;
                this.Load = false; 
                this.MessageOK = true;  
                this.errorMessage = "";           
                this.$emit('load', this.Load);             
              }
            })
            .catch((error) => {    
              this.Load = false;              
              this.errorMessage = error.response.data.errorMessage;            
                this.$emit('load', this.Load);
                if (!error.response) {
                  this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),this.$t('ChangePassWord.NoAbleToChange'),"error");
                } else {
                    this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),error.response.data.message,"error");
                }
            });
        }
      
        }
    
      },

      mounted(){
       this.ClientId = this.$route.params.ClientId ? this.$route.params.ClientId : "";
       this.UserId = this.$route.params.UserId ? this.$route.params.UserId : "";
       
      }
       
    };
    </script>
    <style>
    .URLName{
      font-weight: bold;
      color:  #19BEE8;
      -webkit-font-smoothing: antialiased;
    }
    </style>
    