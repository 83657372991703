<template>
   <nav class="sidebar mb-0" style="min-height:0px !important;">
          <div class="sidebar-profile bg-gradient-corporate">
            <div class="d-flex align-items-center">
              <!--<img src="@/assets/images/faces/face006.jpg" alt="profile">-->
              <div :id="targetId" oncontextmenu="return false;" tabindex="0">
                <div id="user-frame">
                  <ejs-contextmenu :target="menuTargetId" :id="targetId" :items='menuItems' :select='onSelect'></ejs-contextmenu>
                  <input type="file" ref="input" hidden value="" @change="addImage($event)"/>
                  <img v-on:click="OnCamera" v-if="this.Photo.photo !== null && this.Photo.photo !== ''" id="user-photo" v-bind:src="'data:image/'+this.Photo.fileExtension+';base64,'+this.Photo.photo" data-toggle="modal">
                  <img v-on:click="OnCamera" v-if="this.Photo.photo === null || this.Photo.photo === ''" id="user-photo" src="@/assets/images/faces/nopic-male.jpg" data-toggle="modal">             
                </div>
              </div>
              <div class="profile-desc">
                <div class="name">{{this.Name}}</div>
                <!-- <div class="designation">{{this.EmployeeTypeDescription}}</div> -->
                <div class="designation" data-text="Change provider" v-on:click="GoToProvidersList">@{{this.provider}}</div> <!-- v-b-tooltip.right="{ variant: 'light', title: 'Change provider...', delay: { 'show': 2000, 'hide': 1 } }" -->
              </div>
            </div>
          </div>
          
      <div class="bloodhound">
        <input class="typeahead menu-search" type="text" ref="search"
        onfocus="this.removeAttribute('readonly');"
        onblur="this.setAttribute('readonly',true);"  readonly
        :placeholder="$t('HeaderLeftMenu.sMenu')" v-on:keydown="KeyDown($event)">
        <a v-on:click="resetNow()" tabindex="-1"><i class="center-icons ikon med-cross"></i></a>
      </div>
      <div id="btnuponelevel" class="hidden">
                      <a id="upOneLevel" class="nav-link upOneLevel mt-3 mb-2 mx-2 text-truncate" href="#">
                          <i class="fa fa-level-up"></i>
                          <span class="menu-title ml-2">{{$t('HeaderLeftMenu.pFolder')}}</span>
                      </a>
       </div>

       <ModalCamera
          v-bind:TitleWindow="this.$t('GroupNotesRightBar.TakePic')"
          v-bind:ModalId="this.userModalIdCamera"                
          >
        </ModalCamera>
    </nav>
    
</template>

<script>
import LocalStorage from '@/services/storage.service'
import UserService from '@/services/user.service'
import EmployeeService from '@/services/employee.service'
import UserPhotoService from '@/services/userphoto.service' 
import Utils from '../../../common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);



export default {

  name: 'HeaderLeftMenu',
  mixins: [DBFunctions],
  data(){
    return{
        Name:'',
        provider:"",
        EmployeeTypeDescription:'',
        timeout:0,
        userModalIdCamera:"userModalIdCamera",
        menuItems:[],
        menuTargetId:"#target-menu-header",
        targetId:"target-menu-header",
        NotPhoto:true,
        Photo:{      
          photo:"",
          fileExtension:"", 
          fileName:"",   
        },
        msg: this.$t('Msg.SavedSuccessfully')
    }
  },


  methods:{
    loadMenuItems(from) {
      const indexToRemove = this.menuItems.findIndex(item => item.id === 'RemoveUserPicture');
      if(!from){
        this.menuItems.push({text: this.$t('GroupNotesRightBar.LoadPic'),id:"LoadPicture"})
        this.menuItems.push({text: this.$t('GroupNotesRightBar.TakePic'),id:"TakePicture"})
      }
      if(this.Photo.photo){
        if( indexToRemove == -1){
          this.menuItems.push({text: this.$t('GroupNotesRightBar.RemovePic'), id: "RemoveUserPicture"})
        } 
      } else {
          if (indexToRemove !== -1) {
              this.menuItems.splice(indexToRemove, 1);
          }
      } 
    },
     GoToProvidersList() {
      this.$bvModal
        .msgBoxConfirm(this.$t('Main.ChangeProvMsg'), {
          title: this.$t('Main.ChangeProvTitle'),
          okTitle: this.$t('Main.CHANGE'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
        })
        .then((value) => {
          if (value) {
            this.$router.push("/provider").catch(() => {});
          }
        });
    },
    
    KeyDown(e){
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {      
      EventBus.$emit('onFilterMenu', e.target.value);
     
      clearTimeout(this.timeout)
  },100)
    },
    GoToPatientList(){
       this.$router.push('/app/patients').catch(()=>{});
    },

    GoToClinicalDashboard(){
      this.$router.push('/app/clinical').catch(()=>{});
    },

    getName(){
      this.Name = Utils.strToTitleCase(LocalStorage.getName());
    },

    getEmployeeTypeDescription(){
      const UserId = LocalStorage.getUserId();
      EmployeeService.getEmployees(UserId)
            .then((response) => { 
              if(response.data && response.data.length > 0){
                // LocalStorage.saveEmployeeCode(response.data[0].code);               
                this.$store.commit('setEmployeeCode',response.data[0].code);
                this.EmployeeTypeDescription = Utils.strToTitleCase(response.data[0].employeeType); 
              }             
            })
            .catch((error) => {
              if (error.response) {
               this.showMessage(this.$tc('Shared.Employee'),error,"error");
              }
            });
    },
    OnCamera(){ 
      this.$bvModal.show(this.userModalIdCamera);   
    },
    SyncFileReader(file) {
      let self = this;
      let ready = false;
      let result = '';

      const sleep = function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      self.readAsDataURL = async function() {
          while (ready === false) {
            await sleep(100);
          }
          return result;
      }    

      const reader = new FileReader();
      reader.onloadend = function(evt) {
          result = evt.target.result;
          ready = true;
      };
      reader.readAsDataURL(file);
    },
    async addImage(e){
      this.msg = this.$t('Msg.LoadedSuccessfully')
      let droppedFiles = e.type === 'drop'?e.dataTransfer.files:e.target.files;  
      //var extension = "";
      if(!droppedFiles) return;     
      if(droppedFiles.length === 1){
        var filename = droppedFiles[0].name;
        this.Photo.fileName = filename;
        const fileReader = new this.SyncFileReader(droppedFiles[0]);
        const arrayBuffer = await fileReader.readAsDataURL();
        if(arrayBuffer){
          const img = document.getElementById("user-photo");
          img.src = arrayBuffer;
        }    

        if(arrayBuffer.indexOf(',') !== -1){
           var b = arrayBuffer.split(',');    
           this.Photo.photo = b[1];  
           //this.files.push({"name":fileNameNoExtension,"Image":Image,"extension":extension,"base64":b[1],"description":"","code":code,"type":type,"new":true,"isreadonly":true});
        }
        var pos = filename.lastIndexOf('.');
        if(pos !== -1){          
          this.Photo.fileExtension = filename.substring(pos).toUpperCase();
        }
        this.SaveUserPhoto();        
      }
    },

    LoadPicture(){       
      this.$refs.input.click();
      this.$refs.input.value = "";       
    },

    onSelect: function(args) {
      const actions = {
          'LoadPicture': this.LoadPicture,
          'TakePicture': this.OnCamera,
          'logout': this.logout,
          'RemoveUserPicture': this.RemovePicture,
      };

      const action = actions[args.item.id];
      if (action) {
          action.call(this); // Call the method in the context of the component
      } else {
          console.warn(`Unknown action: ${args.item.id}`);
      }
    },

    SaveUserPhoto(){  
      try {
        !this.NotPhoto ? this.UpdatePhoto() : this.InsertPhoto();
      } catch (error) {
          console.error("Error saving photo:", error); // Error handling
      } finally {
          this.loadMenuItems(true); // Ensure this is always called
      }
    },

    InsertPhoto(){
      var data = {
        Photo:this.Photo.photo,
        FileExtension:this.Photo.fileExtension,  
        Userid : LocalStorage.getUserId(),
        FileName:this.Photo.fileName,
      };
      UserPhotoService.AddUserPhoto(data)
        .then((response) => {
           if(response.status == '200'){
            this.Photo = response.data;
            this.NotPhoto = false;
             setTimeout(() => 
                  this.showToast(
                    this.$t('Msg.Saving'),
                    this.msg
                ), 50); 
            }                           
        })
        .catch((error) => {
            if (error.response) {
                this.showMessage(this.$t('HeaderLeftMenu.uPhoto'),error,"error");
            }
        });  
    },

    UpdatePhoto(){
      var data = {
        Photo:this.Photo.photo,
        FileExtension:this.Photo.fileExtension, 
        FileName:this.Photo.fileName,   
      };

      UserPhotoService.UpdateUserPhoto(this.Photo.userid,data)
        .then((response) => {
           if(response.status == '200'){
              setTimeout(() => 
                  this.showToast(
                    this.$t('HeaderLeftMenu.uPhoto'),
                    this.msg
                ), 50); 
            }                           
        })
        .catch((error) => {
            if (error.response) {
                this.showMessage(this.$t('HeaderLeftMenu.uPhoto'),error,"error");
            }
        });  
    },
    

    async getPhoto(){ 
      const userid = LocalStorage.getUserId();
      await UserPhotoService.GetUserPhoto(userid)
          .then((response) => {
              if(response.status == '200' && response.data){
                  this.Photo = response.data;
                  if(this.Photo.userid !== null && this.Photo.userid !== ""){
                    this.NotPhoto = false;
                  }
                }
          })
          .catch((error) => {
              if (error.response) {
                  this.showMessage(this.$t('HeaderLeftMenu.uPhoto'),error,"error");
              }
          });  
    },
    logout(){
     
       this.$bvModal.msgBoxConfirm(this.$t('Header.lOutMsg'), {
          title: this.$t('Header.ConfirmTitle'),
          okTitle: this.$t('Shared.TitleYes'),
          cancelTitle: 'NO',
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
       })
          .then(value => {
            if(value){
              UserService.logout()
               .then((response) => {
                 if(response.status == '200'){
                  this.$store.commit('setAccessToken','');
                  localStorage.removeItem("vuex");
                  localStorage.removeItem("_secure__ls__metadata");
                  this.$router.push('/login');
                 }
               })
                 .catch((error) => {
                   if (!error.response) {
                      this.showMessage(this.$t('Layout.LoggingOut'),this.$t('SelectProvider.NoAbleToLogOutMsg'),"error");
                  } else {
                      this.showMessage(this.$t('Layout.LoggingOut'),error,"error");
                  }
                 });
            }
          })
    },
    resetNow(){
      this.$refs["search"].value = "";
      const e = {
        target: {
          value: ""
        }
      }
      this.KeyDown(e); 
    },
    RemovePicture(){
      if(this.NotPhoto)return;      
      this.Photo.photo = '';
      this.Photo.fileExtension = '';
      this.Photo.fileName = '';
      this.msg = this.$t('Msg.RemoveSuccessfully')
      this.SaveUserPhoto();
    },
     // Helper function to display toast
    showToast(titleKey, msgKey, variant = "success") {
        this.$bvToast.toast( msgKey, {
            title: titleKey,
            autoHideDelay: 4000,
            variant: variant
        });
    },
  },
  
  created(){
    EventBus.$on("UserPhotoWasTaken", function (data) {
      const img = document.getElementById("user-photo");
      img.src = data;
      if(data.indexOf(',') !== -1){
           var b = data.split(',');              
           if(b[0].indexOf(';') !== -1){
            var c = b[0].split(';');  
            if(c[0].indexOf('/') !== -1){
              var d = c[0].split('/');
              this.Photo.fileExtension = "."+d[1]; 
              this.Photo.photo = b[1]; 
              this.Photo.fileName = "";
              this.msg = this.$t('Msg.SavedSuccessfully')            
              this.SaveUserPhoto();
            }
           } 
          
      }
    }.bind(this));
  },
    mounted(){
      this.getName();
      this.getPhoto()
        .then(() => {
          // First function has finished, call the second function
          this.loadMenuItems();     
      });
      this.getEmployeeTypeDescription();
      this.provider = this.$store.state.provider;
  },
};
</script>


