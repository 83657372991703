

import ApiService from '@/services/api.service'

const PainAssessmentService = {
   getAll( parameter) { 
        const requestData = {
          method: 'get',
          url: "/NotePainAssessments/",
          headers: {
          },
          params: { 
            SearchCriteria: parameter.SearchCriteria,
            PageSize: parameter.PageSize,          
            PageNumber : parameter.PageNumber,
            HeaderId : parameter.RecordId
          }
        }     
        return ApiService.customRequest(requestData);         
      },

    get(id) {
      return ApiService.get(`/NotePainAssessments/${id}`);
    },

    Add(data){
      return ApiService.post(`/NotePainAssessments`, data);
    },

    Delete(Id){      
      return ApiService.delete(`/NotePainAssessments/${Id}`);
    },
    
    Update(Id,data){
      return ApiService.put(`/NotePainAssessments/${Id}`,data);
    },  

  
}
  


export default PainAssessmentService;