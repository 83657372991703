<template>
    <div id="treatmentplan">
      <div class="row">
        <div class="col-12">
            <MdTitleRegInfo
                  v-bind:RegistrationInfo="RegistrationInfo" 
                  v-bind:titlePrefix="$t('treatmentplan.Treatmentplan')" 
                  v-bind:IsClinical="true"
                  v-bind:titleRef="'titleElementTreatmentplanMd'"
                  v-bind:patientCode="this.$route.params.id">
              </MdTitleRegInfo>
          </div>
        </div>
      <vue-good-wizard 
      ref="wizard"
      :steps="steps"
      :finalStepLabel="$t('treatmentplan.finish')" 
      :nextStepLabel="$t('treatmentplan.Next')"
      :previousStepLabel="$t('Shared.Back')"     
      :onNext="nextClicked" 
      :onBack="backClicked"
      >    
      <div slot="Problems">        
        <MdTitle v-bind:Text="ProblemLabel" v-bind:isSubtitle="true" v-bind:Name="'Problem'" class="subtitleWizard"/>
        <b-row v-if="this.itemsProblems.length > 0">
          <b-col md="12">
            <RadioGroup 
              v-model="Problem"
              v-bind:Options="itemsProblems"
              v-bind:LabelProp="$t('treatmentplan.Problems')"
              v-bind:OnChangeProp="OnChangeProblem"
              v-bind:columnCount="3"
              :textField="'name'"
              :valueField="'code'"/> 
          </b-col>
        </b-row> 
      </div>
      <div slot="Behavioral">
        <MdTitle v-bind:Text="BehavioralLabel" v-bind:isSubtitle="true" v-bind:Name="'Behavioral'" class="subtitleWizard"/>
            <b-row v-for="(item, index) in this.problemAggregateList" :key="index">
                <span class="InterventionByObjWizard">{{item.name}}</span>
                <b-col lg="12" md="12" sm="12" class="mb-3">
                    <MdCheckListBox2 
                        v-model="item.model"
                        v-bind:Options="item.list"
                        v-bind:OnChangeProp="OnChangeBehavioral"
                        v-bind:columnCount="2"
                        :textField="'name'"
                        :valueField="'id'"/>
                </b-col>
            </b-row>
      </div>
      <div slot="Goals">
        <MdTitle v-bind:Text="GoalLabel" v-bind:isSubtitle="true" v-bind:Name="'Goal'" class="subtitleWizard"/>
        <b-row>
          <b-col lg="12" md="12" sm="12">           
            <MdCheckListBox2 
              v-model="GoalsModel"
              v-bind:Options="GoalsList"            
              v-bind:OnChangeProp="OnChangeGoals"
              v-bind:columnCount="1"
              :textField="'description'"
              :valueField="'code'"/> 
          </b-col>                                   
        </b-row>
      </div>
      <div slot="Objectives">
        <MdTitle v-bind:Text="ObjectiveLabel" v-bind:isSubtitle="true" v-bind:Name="'Objective'" class="subtitleWizard"/>
        <b-row v-for="(item, index) in ObjectiveModel" :key="index">
             <span class="InterventionByObjWizard">{{$t('modalAddGoal.goals')}} {{index+1}} - {{item.goalDescription}}</span>
            <b-col lg="12" md="12" sm="12" class="mb-3">
                <MdCheckListBoxByCycle
                    v-model="item.selectedCode"
                    v-bind:Options="item.objectives"
                    v-bind:OnChangeProp="OnChangeObjectives"             
                    v-bind:columnCount="1"
                    :textField="'description'"
                    :valueField="'code'"
                /> 
             </b-col>
        </b-row>
      </div>
      <div slot="Interventions">
        <MdTitle v-bind:Text="InterventionLabel" v-bind:isSubtitle="true" v-bind:Name="'Intervention'" class="subtitleWizard"/>
        <b-row v-for="(item, index) in InterventionsModel" :key="index">
            <span class="InterventionByObjWizard rounded" v-if="item.objectives.length > 0">{{$t('modalAddGoal.goals')}} {{index+1}} - {{item.goalDescription}}</span>
                <div v-for="(obj, i) in item.objectives" :key="i" class="w-100">
                    <div class="ObjsWizard">{{$t('modalEditObjective.Objective')}} {{i+1}} - {{obj.description}}</div>
                    <MdCheckListBoxByCycle 
                        class="ml-3 mx-4"
                        v-model="obj.interventionsSelected"
                        v-bind:Options="obj.interventions"
                        v-bind:OnChangeProp="OnChangeInterventions"
                        v-bind:columnCount="1"
                        :textField="'description'"
                        :valueField="'code'"/> 
                </div>
        
        </b-row>
      </div>     
      <div slot="Summary">
        <MdTitle v-bind:Text="$t('dailyServiceCharge.Summary')" v-bind:isSubtitle="true" v-bind:Name="'Summary'" class="subtitleWizard"/>
       
        <div id="summaryProblem" class="wizardSummary">
          <div class="wizardSumLabel"><span class="wizardSumNum"><b-icon icon="check"></b-icon></span>{{$t('modalEditProblem.Problem')}}</div>
          <div class="ml-3 mt-2">{{this.ProblemD}}</div>
        </div>  
        <br>

        <div id="summarybehavioral" class="wizardSummary">
          <div class="wizardSumLabel"><span class="wizardSumNum"><b-icon icon="check"></b-icon></span>{{$t('treatmentplanList.mdeditB')}}</div>
          <div class="ml-3 mt-2">
            <div class="wizObjectives" v-for="(item, index) in this.problemAggregateList" :key="index">
                <b-icon icon="check" class="wizardCheckObj"></b-icon> <span> {{item.name}} </span>
                <div class="ml-3 mt-2">
                    <ul>							
                        <li v-for="(it, indexit) in limitSelectedAggregates(item)" :key="indexit"> {{it.name}}</li>						
                    </ul>
                </div>
            </div>
          </div>
        </div>
        <br>
        
        <div id="summarygoals" class="wizardSummary">
            <div v-for="(item, index) in this.InterventionsModel" :key="index">

                <div class="wizardSumLabel">
                    <span class="wizardSumNum">
                        <b-icon icon="check"></b-icon>
                    </span>{{$t('modalEditGoal.goal')}} {{index +1}}               
                </div>
                <div class="wizardGoalsDesc">
                    {{item.goalDescription}} 
                </div>

                <div class="ml-3 mt-2 mb-4">
                    <div class="wizObjectives" v-for="(obj, indexobj) in item.objectives" :key="indexobj">
                        <b-icon icon="check" class="wizardCheckObj"></b-icon> <span>{{$t('modalEditObjective.Objective')}} {{indexobj+1}} - {{obj.description}}</span>
                        <div class="ml-3 mt-2">
                            <ul>							
                                <li v-for="(itv, indexitv) in obj.itvDdescriptionSelected" :key="indexitv">{{$t('modalEditIntervention.interV')}} {{indexitv+1}} - {{itv.description}}</li>						
                            </ul>
                          </div>
                    </div>
                </div>
            </div>
        </div>
        <br>

        <MdTitle v-bind:Text="$t('modalAddSignature.sign', {'label': ''})" v-bind:isSubtitle="true" v-bind:Name="'Signature'" class="subtitleWizard"/>
        <div class="card bg-light">
            <div class="card-body">                   
                <b-row>        
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$t('modalProviderSelector.Provider')"
                            v-bind:Options="ProviderList"
                            v-bind:FieldDescription="'therapistName'"
                            v-bind:FieldCode="'therapistCode'"
                            v-bind:Model="ProviderModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownProvider"
                        />                           
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'checkbox-Sign'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"              
                        v-model="SignProvider"
                        v-on:change="onSignatureTP"
                        v-bind:Tag="'SignTreatmentPlanSignature'"          
                        />
                    </b-col>                         
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$t('MdNote.Cosignedby')"
                            v-bind:Options="CoProviderList"
                            v-bind:FieldDescription="'coTherapistName'"
                            v-bind:FieldCode="'coTherapistCode'"
                            v-bind:Model="CoProviderModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownCoProvider"
                        />
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'Co-checkbox-Sign'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"              
                        v-model="SignCoProvider"
                        v-on:change="onCoSignatureTP"
                        v-bind:Tag="'CoSignTreatmentPlanSignature'"          
                        />
                    </b-col> 
              
                    <b-col lg="3" md="3" sm="12">         
                        <MdSelect
                            v-bind:Label="$tc('Incident.IncPartClient')"
                            v-bind:Options="ClientList"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ClientModel"                                 
                            v-bind:Multiple="false"
                            :Input="ChangeDropDownClient"
                        />
                    </b-col>
                    <b-col lg="1" sm="1" md="12" >
                        <MdCheckBox
                        v-bind:Label="$t('MdNote.Sign')"
                        v-bind:Name="'checkbox-Client'" 
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"   
                        v-bind:isReadOnly="disabledCheckClient"                      
                        v-model="ClientCheckModel"                  
                        v-bind:UncheckedValue="false" 
                        v-on:change="onSignatureClient($event)"
                        v-bind:Tag="'TreatmentPlanSignatureClient'"          
                        /> 
                    </b-col>               
                </b-row>              
            </div>
        </div>


        <hr class="mt-3">
    </div> 
    </vue-good-wizard>
    <div class="space-actions"></div>
      <div class="page-actions">
                <MdButton
                v-bind:Label="$t('Shared.Back')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="goTreatmentList"
            />            
    </div>

    <MdPasswordDlg v-if="showSignPassword" 
    v-bind:Type="TypePassWord"
    v-bind:EmployeeCode="EmployeeCodeSelected"
    @getModalPassword="onModalPasswordResult($event)"
/>
<modalSignPatientGuardian
    @ModalTPSignPatientGuardian="OnReturnSign($event)" 
    @ModalTPSignPatientGuardianWizardOK="OnReturnOKWizard($event)"
    v-if="showModalSignPatientGuardian" 
    v-bind:Option="option"
    v-bind:ModalId="'modalIdSignPatientGuardian'" 
    v-bind:ItemChecked="itemChecked" 
    v-bind:TitleWindow="labelSign"
    v-bind:ShowGuardianDrop="ShowGuardianDrop"
/>

    </div>
</template>

<script>
import EmployeeService from '@/services/employee.service'
import LocalStorage from '@/services/storage.service'
import EnumService from '@/services/enum.service' 
import DBFunctions from '@/common/DBFunctions.js'
import TreatmentPlanService from '@/services/treatmentplan.service' 
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';

export default {
    beforeRouteLeave(to, from, next) {
        if (this.pendingChanges) {
                this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
                title: this.$t('Admission.Admission'),
                okTitle: this.$t('Shared.BtnSave'),
                cancelTitle: this.$t('Shared.BtnDismiss'),
                footerClass: 'p-2',
                titleClass: 'modal-title modal-type-warning'
            })
            .then(async value => {
                if(!value){
                    next();
                } else {
                    await this.SaveProblem();
                    if (!this.pendingChanges) {
                        next();
                    }
                        
                }
            })		
        } else {
            next()
        }
    },

    name: 'TreatmentPlan',    
    mixins: [DBFunctions],
    data() {
        return {
            EmployeeCode:"",          
            option:"",
            labelSign:"",
            DataModalGuardianSig:{},
            showSignPassword: false, 
            EmployeeCodeSelected:"",
            ProviderList:[],
            itemChecked:{},
            CoProviderList:[],
            ContactList:[],
            ClientModel:{code:"",name:""},
            ClientList:[
                {code:"PatientSigned",name:this.$t('modalAddSignature.pSigned')},
                {code:"PatientRefused",name:this.$t('modalAddSignature.pRefused')},
                {code:"GuardianSigned",name:this.$t('modalAddSignature.gSigned')},
            ], 
            problemAggregateList:[],
            ContactModel:"",
            CoProviderModel:{coTherapistCode:"",coTherapistName:""},
            showModalSignPatientGuardian:false,
            ProviderModel:{therapistCode:"",therapistName:""},
            SignProvider:false,
            SignCoProvider:false,
            ClientCheckModel:false,
            disabledCheckClient:false,
            SignPatient:false,
            SignRefused:false,
            SignContact:false,
            BaseFields: [
                { key: 'description', sortable: true, label: this.$t('Shared.Description') },
                { key: 'icd10code', sortable: true, label: this.$t('Shared.Code') }
            ],
            ObjData:{
                admissionId:this.$route.params.recordid,
                patientCode:this.$route.params.id,
                problem: {
                    probCode: "",
                    probDesc: "",
                    dsm5: "",
                    dsm5description: "",
                    problemGroup: "",
                    groupId: 0
                },
               
                problemDefinitions:[

                ],
                goals:[],
            },

            RegistrationInfo: {},
            diagCode: "",
            diagDesc: "",
            ProblemIndexSelected: 0,
            ProblemD: "",
            ProblemGroupModel: { "description": "", "groupId": 0 },
            behavioralModel: [],
            ObjectiveList: [],
            ObjectiveModel: [],
            GoalCodeList:[],
            ObjectiveCodeList:[],
            GoalsList: [],
            Problem: -1,
            GoalsModel: [],
            SelectedProblem: { "description": "", "problemNumber": "", "groupId": 0, "customProblem": "" },
            SelectedBehavioral: [],
            SelectedGoals: [],
            SelectedIntervention: [],
            ProblemGroupOptions: [],
            InterventionsList: [],
            InterventionsModel: [],
            HeaderDiag: {},
            HeaderProb: {},
            pageOptionsProblems: [5, 10, 15, 20, 25, 30],
            totalRowsProblems: 1,
            currentPageProblems: 1,
            perPageProblems: 5,
            ShowMessageIsEmptyProblems: false,
            refTableProblems: 'RefTableTreatmentProblems',
            parameterDiag: {
                SearchCriteria: '',
                PageNumber: 0,
                PageSize: 0,
            },
            parameterProb: {
                SearchCriteria: '',
                PageNumber: 0,
                PageSize: 0,
                GroupId: 0,
            },
            ProblemLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')}),
            BehavioralLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('treatmentplan.PsychosocialEnvironmental')}),
            ObjectiveLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddObjective.objectives')}),
            InterventionLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddIntervention.intervention')}),
            GoalLabel: this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddGoal.goals')}),
            itemsProblems: [],
            OptionsRightMenu: {
                visible: true,
                ItemsList: [],
                btnvisible: true,
                itemdisabled: false,
            },
            ShowGuardianDrop:false,
            steps: [
                {
                    label: this.$t('modalEditProblem.Problem'),
                    slot: 'Problems',
                },
                {
                    label: this.$t('treatmentplan.Subproblem'),
                    slot: 'Behavioral',
                },
                {
                    label: this.$t('modalAddGoal.goals'),
                    slot: 'Goals',
                },
                {
                    label: this.$t('modalAddObjective.objectives'),
                    slot: 'Objectives',
                },
                {
                    label: this.$t('modalAddIntervention.intervention'),
                    slot: 'Interventions',
                },
                {
                    label: this.$t('dailyServiceCharge.Summary'),
                    slot: 'Summary',
                }
            ],
            pendingChanges: false,
        };
    },
    methods: {

        limitSelectedAggregates (item) {
            if (item && item.list && item.list.length) {
                return item.list.filter(v => item.model.some(m => m == v.id));
            }

            return null;
        },

        ChangeDropDownClient(value){ 
            this.ClientModel = value ? value : {code:"",name:""};      
            this.showModalSignPatientGuardian = false;       
            if(value){
                if(value.code === "PatientRefused"){                  
                    this.disabledCheckClient = true;
                    this.option = this.$tc('Shared.Patient');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                    this.ClientCheckModel = false;
                }else{
                    this.disabledCheckClient = false;
                } 

                if(value.code === "GuardianSigned"){
                    this.option = this.$t('modalAddSignature.guardian');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$t('modalAddSignature.guardian')});
                    this.ShowGuardianDrop = true;
                }

                if(value.code === "PatientSigned"){
                    this.option = this.$tc('Shared.Patient');
                    this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                    this.ShowGuardianDrop = false;
                }
                
                if(value.code === "GuardianSigned" && this.ClientCheckModel){
                    this.showModalSignPatientGuardian = true;
                }

                else if(value.code === "PatientSigned" && this.ClientCheckModel){
                    this.showModalSignPatientGuardian = true;
                }
                
                else{
                    this.showModalSignPatientGuardian = false;
                }



            }
        },

        onModalPasswordResult(result){ 
          if(result.type === 'Sign'){
            if(result.Event === "Ok"){
                if(result.status !== true) {
                    this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");   
                    this.SignProvider = false;                  
                }                   
            }else{
                this.SignProvider = false;
            }
                  
        }
        if(result.type === 'Cosign'){
            if(result.Event === "Ok"){
                if(result.status !== true) {
                    this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");  
                    this.SignCoProvider = false;          
                }               
            }
            else{
                this.SignCoProvider = false;       
            }
        } 

        this.showSignPassword = false;
    },

    OnReturnOKWizard(data){        
        this.DataModalGuardianSig = data;
    },

    OnReturnSign(data){       
        if(data.option === this.$tc('Shared.Patient')){
            data.item.isPatientSigned = false; 
            data.item.CheckGuardianReadOnly = false;
            //CheckPatientReadOnly
            data.item.CheckRefuseReadOnly = false;
            data.item.SelectGuardianReadOnly = false;
        }
        if(data.option === this.$t('modalAddSignature.guardian')){
            data.item.guardianSigned = false;            
            data.item.CheckPatientReadOnly = false;
            data.item.CheckRefuseReadOnly = false;
            data.item.SelectGuardianReadOnly = false;
        }
       
        this.showModalSignPatientGuardian = false;
    },

    onCoSignatureTP(value){
        this.TypePassWord = 'Cosign'; 
        this.EmployeeCodeSelected = this.CoProviderModel.coTherapistCode;
        this.showSignPassword = value;
    },

    onSignatureTP(value){  
        this.TypePassWord = 'Sign';       
        this.EmployeeCodeSelected = this.ProviderModel.therapistCode;
        this.showSignPassword = value;
    },

        onSignatureClient(value){          
            this.showModalSignPatientGuardian = value && (this.ClientModel.code === "GuardianSigned" || this.ClientModel.code === "PatientSigned") ? true : false;
        },

        ChangeDropDownProvider(value){
            this.ProviderModel = value ? value : {therapistCode:"",therapistName:""};
        },

        onSignatureGuardian(){

        },

        ChangeDropDownGuardian(){

        },

        onSignatureRefused(){

        },

        onSignaturePatient(){

        },

        ChangeDropDownCoProvider(value){
            this.CoProviderModel = value ? value : {coTherapistCode:"",coTherapistName:""};
        },

        async AddTX() {                    
            this.PrepareData();
            this.$emit('load', true);  
            if(this.ObjData.signature.therapistCode !== "" && this.ObjData.signature.therapistCode !== null){
                if(this.ObjData.signature.isSigned){
                    await TreatmentPlanService.Add(this.ObjData)
                        .then((response) => {
                            if (response.status == '200') {
                                this.pendingChanges = false;
                                this.$router.push({
                                        name: "MdTreatmentplan-Clinical",
                                        params: { id:  this.$route.params.id,recordid:this.$route.params.recordid },
                                });

                                setTimeout(() => 
                                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                    title: this.$t('modalEditProblem.Problem'),
                                    autoHideDelay: 5000,
                                    variant: "success"
                                })
                                , 50);
                            }
                            
                            this.$emit('load', false);
                        })
                        .catch(error => {
                            this.$emit('load', false);
                            if (!error.response) {
                                this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.retrieveInfo'), "error");
                            }
                            else if (error.response.status === 400) {
                                this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                            }
                            else if (error.response.status === 500) {
                                this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.ThereWereSomeError'), "error");
                            }
                            else {
                                this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                            }
                        });
                }else{
                    this.$emit('load', false);  
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.pSignMsg'), "error")
                    return;
                }
            }else{
                this.$emit('load', false);  
                this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.pSelectMsg'), "error")
                return;
            }
        },

        PrepareData(){
            this.ObjData.problem.probCode = this.Problem.toString();
            this.ObjData.problem.dsm5 = this.diagCode;
            this.ObjData.problem.probDesc = this.ProblemD;
            this.ObjData.problem.dsm5description = this.diagDesc;
            this.ObjData.problem.problemGroup = this.ProblemGroupModel.description;
            this.ObjData.problem.groupId = this.ProblemGroupModel.groupId;
            this.ObjData.NotesId = this.$route.params.notesid;
            
            this.ObjData.signature = {
                therapistCode : this.ProviderModel.therapistCode,
                coTherapistCode : this.CoProviderModel.coTherapistCode,
                patientCode : this.$route.params.id,
                isSigned : this.SignProvider,
                isCosigned : this.SignCoProvider,
                isPatientSigned : this.ClientCheckModel === true && this.ClientModel.code === "PatientSigned" ? true : false,
                guardianSigned : this.ClientCheckModel === true && this.ClientModel.code === "GuardianSigned" ? true : false,
                refuseSigned : this.ClientModel.code === "PatientRefused" ? true : false,
                image : this.DataModalGuardianSig.base64,
                contactRecNo : this.DataModalGuardianSig.guardianCode !== "" && this.DataModalGuardianSig.guardianCode !== null  ? parseInt(this.DataModalGuardianSig.guardianCode) :null,
            };
            
            this.ObjData.problemAggregates = [];

            for (let i = 0; i < this.problemAggregateList.length; i++) {
                let agg = this.problemAggregateList[i];
                let selected = [];
                for (let j = 0; j < agg.model.length; j++) {
                    let selIndex = agg.model[j];
                    if (selIndex >= 0 && selIndex < agg.list.length) {
                        selected.push(agg.list[selIndex].name);
                    }
                }
                this.ObjData.problemAggregates.push(
                {                   
                    aggName: agg.name,
                    selectionValues: selected,
                });
            }

            this.ObjData.problemDefinitions = [];
            this.ObjData.goals = [];
            let Dataobjectives = [];
            let Datainterventions = [];

            for(var j = 0; j < this.InterventionsModel.length; j++){
                let goalCode = this.InterventionsModel[j].goalCode;
                let goalDesc = this.InterventionsModel[j].goalDescription;
                for(var k = 0; k < this.InterventionsModel[j].objectives.length; k++){
                    let objCode = this.InterventionsModel[j].objectives[k].code;
                    let objDesc = this.InterventionsModel[j].objectives[k].description;
                   
                    for(var l = 0; l < this.InterventionsModel[j].objectives[k].interventionsSelected.length; l++){
                        let intCode = this.InterventionsModel[j].objectives[k].interventionsSelected[l].toString();
                        let intDesc = this.InterventionsModel[j].objectives[k].itvDdescriptionSelected[l].description;

                        Datainterventions.push({intCode:intCode,intDesc:intDesc})
                    
                    }
                    Dataobjectives.push({objCode:objCode,objDesc:objDesc,interventions:Datainterventions});
                    Datainterventions = [];
                }
                this.ObjData.goals.push({goalCode:goalCode,goalDesc:goalDesc,objectives:Dataobjectives});
                Dataobjectives = [];               
            }
        },

        nextClicked(currentPage) {           
           if (currentPage === 0) {
                if (this.Problem !== -1) {
                    this.getMedTxProblemAggregates();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.proSelectMsg'), "warning");
                    return false;
                }
            }          
            else if (currentPage === 1) {
                if (this.problemAggregateList.length == 0 || this.problemAggregateList.every((item) => item.model.length > 0)) {
                    this.getMedTxGoals();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.bSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 2) {
                if (this.GoalsModel.length > 0) {
                    this.getMedTxObjectives();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.gSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 3) {
                if (this.IsSelectedObjectives()) {
                    this.getMedTxInterventions();
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.oSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 4) {             
                if (this.IsSelectedInterventions()) {                  
                    return true;
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('treatmentplan.iSelectMsg'), "warning");
                    return false;
                }
            }
            else if (currentPage === 5) {
                this.SaveProblem();
                return true;
            }
        },


        IsSelectedInterventions(){
            let result = true;
            for(var i = 0; i < this.InterventionsModel.length; i++){
                for(var j = 0; j < this.InterventionsModel[i].objectives.length; j++){
                    if(this.InterventionsModel[i].objectives[j].interventionsSelected.length === 0){
                        result = false;
                    }
                }
            }
            return result;
        },

        IsSelectedObjectives(){
            //let count = 0;
            let result = true;
            this.ObjectiveModel.forEach(element => { 
               // count += element.selectedCode.length;
               if(element.selectedCode.length === 0){
                    result = false;
               }
            })
            //return count > 0;
            return result;
        },
        EmitSetRowProblem() {
            setTimeout(() => this.$refs.RefTableTreatmentProblems.selectRow(this.ProblemIndexSelected), 300);
        },
        async SaveProblem() {
           // alert("Saving data, waiting to deploy");
            await this.AddTX();
        },
        backClicked() {
            return true; //return false if you want to prevent moving to previous page
        },
        GoNext() {
            this.$refs.wizard.goNext(true);
        },
        onCodeConditionOk(item) {

            if(this.diagCode !== item.code){
                this.behavioralModel = [];
                this.GoalsModel = [];
                this.ObjectiveModel = [];
                this.InterventionsModel = [];
            }
        },

        OnChangeProblem(code) {
            for (var i = 0; i < this.itemsProblems.length; i++) {
                if (this.itemsProblems[i].code === code) {
                    this.ProblemD = this.itemsProblems[i].name;
                }
            }
            if (this.Problem !== -1) {
                this.ProblemLabel = 'Problem - ' + this.ProblemD;
            }
            else {
                this.ProblemLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')});
            }

            this.behavioralModel = [];
            this.GoalsModel = [];
            this.ObjectiveModel = [];
            this.InterventionsModel = [];
        },
        GoNextProblem() {
            this.getMedTxProblemAggregates();
            this.$refs.wizard.goNext(true);
        },
        Save() {
        },
        onRowClickedProblem(item, index) {
            this.ProblemIndexSelected = index;
        },
        getBehavioralDescriptionByCode(code) {
            // for (var i = 0; i < this.BehavioralList.length; i++) {
            //     if (this.BehavioralList[i].definitionNumber === code) {
            //         return this.BehavioralList[i].description;
            //     }
            // }
            return code;
        },
        getGoalsDescriptionByCode(code) {
            for (var i = 0; i < this.GoalsList.length; i++) {
                if (this.GoalsList[i].code === code) {
                    return this.GoalsList[i].description;
                }
            }
            return "";
        },

        getInteventionDescriptionByCode(arr, code) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].code === code) {
                    return arr[i].description;
                }
            }
            return "";
        },

        getObjectiveDescriptionByCode(arr, code) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].code === code) {
                    return arr[i].description;
                }
            }
            return "";
        },

        OnChangeBehavioral() {
            if (this.problemAggregateList.length > 0) {
                this.SelectedBehavioral = [];
                for (let i = 0; i < this.problemAggregateList.length; i++) {
                    let agg = this.problemAggregateList[i];
                    for (let j = 0; j < agg.model.length; j++) {
                        let selIndex = agg.model[j];
                        if (selIndex >= 0 && selIndex < agg.list.length) {
                            this.SelectedBehavioral.push(agg.name + " " + agg.list[selIndex].name);
                        }
                    }
                }
            }
            else {
                this.SelectedBehavioral = [];
            }
        },
        
        OnChangeGoals(items) {
            if (items.length > 0) {
                this.SelectedGoals = [];
                for (var i = 0; i < items.length; i++) {
                    this.SelectedGoals.push(this.getGoalsDescriptionByCode(items[i]));
                }
            }
            else {
                this.SelectedGoals = [];
            }
            if (items.length === 0) {
                this.GoalLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddGoal.goals')});
            }
            else if (items.length === 1) {
                this.GoalLabel = this.$t('modalAddGoal.goals')+'- ' + items.length + ' '+this.$tc('treatmentplan.iSelected');
            }
            else {
                this.GoalLabel = this.$t('modalAddGoal.goals')+'- ' + items.length + ' '+this.$tc('treatmentplan.iSelected', 2);
            }
        },
        OnChangeObjectives() {             
            var l = this.ObjectiveModel.length;
            let count = 0;
            for (var i = 0; i < l; i++) {
                count += this.ObjectiveModel[i].selectedCode.length;
                if (this.ObjectiveModel[i].selectedCode.length > 0) {
                    this.ObjectiveModel[i].selectedDesc = [];
                    for (var j = 0; j < this.ObjectiveModel[i].selectedCode.length; j++) {
                        let result = this.getObjSelectedObjective(i,j);                       
                        this.ObjectiveModel[i].selectedDesc.push(
                           {description:this.getObjectiveDescriptionByCode(this.ObjectiveModel[i].objectives, 
                            this.ObjectiveModel[i].selectedCode[j]),
                            class:result.class
                           }  
                        );
                    }
                }
                else {
                    this.ObjectiveModel[i].selectedDesc = [];
                }
            }

           
            if (count === 0) {
                this.ObjectiveLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddObjective.objectives')});
            }
            else if (count === 1) {
                this.ObjectiveLabel = this.$t('modalAddObjective.objectives')+'- ' + count + ' '+this.$tc('treatmentplan.iSelected');
            }
            else {
                this.ObjectiveLabel = this.$t('modalAddObjective.objectives')+'- ' + count + ' '+this.$tc('treatmentplan.iSelected',2);
            }
        },

        getObjSelected(i,j,k){
            let code = this.InterventionsModel[i].objectives[j].interventionsSelected[k];
            let result = {class:""};
            for(var n = 0; n < this.InterventionsModel[i].objectives[j].interventions.length; n++){
                if(this.InterventionsModel[i].objectives[j].interventions[n].code === code){
                    result.class = this.InterventionsModel[i].objectives[j].interventions[n].class;
                }
            }
            return result;
        },

        getObjSelectedObjective(i,pos){
            let code = this.ObjectiveModel[i].selectedCode[pos];
            let result = {class:""};
            for(var j = 0; j < this.ObjectiveModel[i].objectives.length; j++){
                if(this.ObjectiveModel[i].objectives[j].code === code){
                    result.class = this.ObjectiveList[i].objectives[j].class;
                }
            }
            return result;
        },

        OnChangeInterventions() {
            var l = this.InterventionsModel.length;
            let count = 0;
            for(var i = 0; i < l; i++){
                for(var j = 0; j < this.InterventionsModel[i].objectives.length; j++){
                    count += this.InterventionsModel[i].objectives[j].interventionsSelected.length;
                    if(this.InterventionsModel[i].objectives[j].interventionsSelected.length > 0){
                        this.InterventionsModel[i].objectives[j].itvDdescriptionSelected = [];
                        for(var k = 0; k < this.InterventionsModel[i].objectives[j].interventionsSelected.length; k++){
                            let result = this.getObjSelected(i,j,k);                        
                            this.InterventionsModel[i].objectives[j].itvDdescriptionSelected.push(
                                {description:this.getInteventionDescriptionByCode(this.InterventionsModel[i].objectives[j].interventions, 
                                    this.InterventionsModel[i].objectives[j].interventionsSelected[k]),
                                    class:result.class
                                });
                        }
                    }
                    else {
                        this.InterventionsModel[i].objectives[j].itvDdescriptionSelected = [];
                    }
                }
            }

            if (count === 0) {
                this.InterventionLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalAddIntervention.intervention')});
            }
            else if (count === 1) {
                this.InterventionLabel = this.$t('modalAddIntervention.intervention')+'- ' + count + ' '+this.$tc('treatmentplan.iSelected');
            }
            else {
                this.InterventionLabel = this.$t('modalAddIntervention.intervention')+'- ' + count + ' '+this.$tc('treatmentplan.iSelected',2);
            }
        },
        onRowSelectedProblems(item) {
            if (item.length > 0) {
                this.SelectedProblem = item[0];
                this.ProblemLabel = "Problem - " + item[0].description;
            }
            else {
                this.SelectedProblem = { "description": "", "problemNumber": "", "groupId": 0, "customProblem": "" };
                this.ProblemLabel = this.$t('treatmentplan.labelSelect',{'label': this.$t('modalEditProblem.Problem')});
            }
        },
        onFilteredProblems() {
        },
        pageChangedProblems(page) {
            this.currentPageProblems = page;
            this.getMedTxProblems();
        },
        PerPageFunctionProblems(perpage) {
            this.perPageProblems = perpage;
            this.currentPageProblems = 1;
            this.getMedTxProblems();
        },
        goTreatmentList() {           
            if(this.$route.name.includes('MdTreatmentplan-add-Clinical')){
                this.$router.push({ name: "MdTreatmentplan-Clinical" });
            }else{              
                this.$router.push({ name: "MdTreatmentplan-Appointment", params: { id: this.$route.params.id,recordid:this.$route.params.recordid , actId: this.$route.params.actId, notesid: this.$route.params.notesid } });               
            }          
        },
        SearchProblems() {
            this.itemsProblems = [];
            this.currentPageProblems = 1;
            this.parameterProb.PageNumber = 1;
            this.totalRowsProblems = this.itemsProblems.length;
            this.getMedTxProblems();
        },
        getMedTxProblems() {
            this.$emit('load', true);
            /* this.parameterProb.PageNumber = this.currentPageProblems;
             this.parameterProb.PageSize = this.perPageProblems;  */
            var parameter = {
                AdmissionId:this.$route.params.recordid,
                NotesId:this.$route.params.notesid
            };
            TreatmentPlanService.getMedTxProblems(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.itemsProblems = response.data;
                    let headerString = response.headers["x-pagination"];
                    this.HeaderProb = JSON.parse(headerString);
                    this.totalRowsProblems = this.HeaderProb.TotalCount;
                }
                if (this.itemsProblems.length === 0) {
                    this.ShowMessageIsEmptyProblems = true;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.Treatmentplan'), error.response.data.errorMessage, "error");
                }
            });
        },
        getMedTxProblemAggregates() {
            this.$emit('load', true);
            var parameter = {
                ProblemNumber: this.Problem,
                HeaderId: null
            };
            TreatmentPlanService.getMedTxProblemAggregates(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.problemAggregateList = response.data.map(item => ({ ...item, model: [] }));
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanBeh'), error.response.data.errorMessage, "error");
                }
            });
        },
        getMedTxGoals() {
            this.$emit('load', true);
            var parameter = {
                ProblemNumber: this.Problem,
            };
            TreatmentPlanService.getMedTxGoals(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.GoalsList = response.data;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
            });
        },
        getMedTxObjectives() {
            this.$emit('load', true);
            var parameter = {
                IsWizard:true,
                ProblemCode: this.Problem,
                GoalCodeList : this.GoalsModel
            };
            TreatmentPlanService.getMedTxObjectives(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.ObjectiveList = response.data;

                    let arrpos = [];
                    for (var i = 0; i < this.ObjectiveModel.length; i++) {                       
                        if (!this.ExistObjetiveinGoalsList(this.ObjectiveModel[i].goalCode)) {
                            arrpos.push(i);
                        }
                    }                    
                    arrpos.reverse();                    
                    for (var j = 0; j < arrpos.length; j++) { 
                        this.ObjectiveModel.splice(arrpos[j], 1);                          
                    }

                    this.ObjectiveList.forEach(element => {                      
                        element.class = "InterventionByObjWizard";
                        element.classSummary = "";

                        if (!this.ExistObjectiveInGoals(element.goalCode)) {
                            this.ObjectiveModel.push({
                                goalDescription: element.goalDescription,
                                goalCode: element.goalCode,
                                objectives: element.objectives,
                                selectedCode: element.selectedCode = [],
                                selectedDesc: element.selectedDesc = []                               
                            });
                        
                        }
                    });
                  
                    this.OnChangeObjectives();
                    this.$emit('load', false);
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanGoals'), error.response.data.errorMessage, "error");
                }
            });
        },

        EmployeeCodeIsinList(EmpCode){
            let result = false;
            for(var i = 0; i < this.ProviderList.length; i++){
                if(this.ProviderList[i].therapistCode === EmpCode){
                    result = true;
                    break;
                }                    
            }
            return result;
        },

        getEmployeeProvider(){
      const UserId = LocalStorage.getUserId();
      EmployeeService.getEmployees(UserId)
            .then((response) => { 
              if(response.data){              
                let result = response.data;                    
                for(var i = 0; i < result.length; i++){
                    this.ProviderList.push({therapistCode:result[i].code,therapistName:result[i].name});
                }
               
                if(this.EmployeeCodeIsinList(this.EmployeeCode)){
                    this.ProviderModel.therapistCode = this.EmployeeCode;
                    this.ProviderModel.therapistName = LocalStorage.getName();
                }                
                 
              }             
            })
            .catch((error) => {
                this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    }
            });
    },

    getEmployeeCoProvider(){      
      EmployeeService.getEmployeeCosigner()
            .then((response) => { 
              if(response.data){               
                let result = response.data;                    
                for(var i = 0; i < result.length; i++){
                    this.CoProviderList.push({coTherapistCode:result[i].code,coTherapistName:result[i].name});
                }
                 
              }             
            })
            .catch((error) => {
                this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                    }
            });
    },

        ExistObjectiveInGoals(objGoal) {          
            var l = this.ObjectiveModel.length;
            for (var i = 0; i < l; i++) {            
                if (this.ObjectiveModel[i].goalCode === objGoal) {
                    return true;
                }
                
            }
            return false;
        },

        ExistGoal(goalCode) {
            var l = this.InterventionsModel.length;
            for (var i = 0; i < l; i++) {
                if (this.InterventionsModel[i].goalCode === goalCode) {
                    return true;
                }
            }
            return false;
        },
        ExistObjetiveinInterventionsList(ObjCode) {
            let result = false;
            var l = this.ObjectiveModel.length;
            for (var i = 0; i < l; i++) {
                for(var j = 0; j < this.ObjectiveModel[i].selectedCode.length; j++){
                    if (this.ObjectiveModel[i].selectedCode[j] === ObjCode) {
                        result = true;
                    }     
                }
                /*if (this.ObjectiveModel[i] === ObjCode) {
                    return true;
                }*/
            }
            return result;
        },

        ExistObjetiveinGoalsList(ObjGoal) {
            var l = this.GoalsModel.length;
            for (var i = 0; i < l; i++) {
                if (this.GoalsModel[i] === ObjGoal) {
                    return true;
                }
            }
            return false;
        },

        PopulateListRequestIntervention(){
            this.GoalCodeList = [];
            this.ObjectiveCodeList = [];
            this.ObjectiveModel.forEach(element => {
                if(element.selectedCode.length > 0){
                    for(var i = 0; i < element.selectedCode.length; i++){
                        this.GoalCodeList.push(element.goalCode);
                        this.ObjectiveCodeList.push(element.selectedCode[i]);
                    }
                }
            })
        },

        ExistGoalinIntervention(goalCode){        
            var arr = this.GoalsModel;
            let l = arr.length;
            for(var i = 0; i < l; i++){
                if(arr[i] === goalCode){
                    return true;
                }
            }
            return false;
        },

        RemoveEmptyObjective(){
            var arrGoals = [];          
            for(var j = 0 ; j < this.InterventionsModel.length; j++){
                if(this.InterventionsModel[j].objectives.length == 0){
                    arrGoals.push(this.InterventionsModel[j].goalCode);            
                }
            }          
            var temp = [];
            for(var i = 0; i < arrGoals.length; i++){               
                temp =  this.InterventionsModel.filter(item => item.goalCode !== arrGoals[i]);
            }           
            this.InterventionsModel = temp.length > 0 ?  temp : this.InterventionsModel;
        },

        esCereza(fruta) {
            return fruta.nombre === "cerezas";
        },



        IsIntInGoals(goalCode){
            for(var j = 0; j < this.GoalsModel.length; j++){
                if(this.GoalsModel[j] === goalCode){
                    return true;
                }
            }
            return false;  
        },
        RemoveGoalNotChecked(){
            let arrtemp = [];          
            for(var i = 0; i < this.InterventionsModel.length; i++){
                if(this.IsIntInGoals(this.InterventionsModel[i].goalCode)){
                    arrtemp.push(this.InterventionsModel[i]);
                }
            }

            this.InterventionsModel = arrtemp.length > 0 ?  arrtemp : this.InterventionsModel;
        },

        IsIntInObjectives(goalCode, objectiveCode){        
            var objectives = this.ObjectiveModel.filter(item => item.goalCode === goalCode);
            if(objectives.length > 0){
                var codes = objectives[0].selectedCode;
                for(var i = 0; i < codes.length; i++){
                    if(codes[i] === objectiveCode)
                        return true;
                }
            }           
            return false;            
        },


        FindObjectiveInintevList(goalCode,objCode){
            var itv = this.InterventionsList.filter(item => item.goalCode === goalCode);
            var objtemp = {};
            if(itv.length > 0){
                let itvtmp = itv[0];
                for(var i = 0; i < itvtmp.objectives.length; i++){
                    if(itvtmp.objectives[i].code === objCode){
                        objtemp = itvtmp.objectives[i];
                    }
                }
            }         

            return objtemp
        },

        AddObj(goalCode,obj){      
            if(obj !== null){
                for(var i = 0; i < this.InterventionsModel.length; i++){
                    if(this.InterventionsModel[i].goalCode === goalCode){
                        this.InterventionsModel[i].objectives.push(obj);
                    }
                }
            }
          
        },

        ObjectiveIsInInterv(goalCode,objCode){
            var objectives = this.InterventionsModel.filter(item => item.goalCode === goalCode);
            if(objectives.length > 0){
                var objs = objectives[0].objectives;
                for(var i = 0; i < objs.length; i++){
                    if(objs[i].code === objCode)
                        return true;
                }
            }
            return false;
        },


        AddNewObjectives(){            
            for(var i = 0; i < this.ObjectiveModel.length; i++){
                for(var j = 0; j < this.ObjectiveModel[i].selectedCode.length; j++){                    
                    if(!this.ObjectiveIsInInterv(this.ObjectiveModel[i].goalCode,this.ObjectiveModel[i].selectedCode[j])){
                        var obj = this.FindObjectiveInintevList(this.ObjectiveModel[i].goalCode,this.ObjectiveModel[i].selectedCode[j]);
                        if(obj !== null){
                            this.AddObj(this.ObjectiveModel[i].goalCode,obj);
                        }
                    }
                }
            }
        },

        RemoveObjectiveNotChecked(){
            let arrtemp = [];
            let arrposdelete = [];
            for(var i = 0; i < this.InterventionsModel.length; i++){
                for(var j = 0; j < this.InterventionsModel[i].objectives.length; j++){ 
                    if(this.IsIntInObjectives(this.InterventionsModel[i].goalCode,this.InterventionsModel[i].objectives[j].code)){
                        arrtemp.push(this.InterventionsModel[i].objectives[j]);
                    }else{
                        arrposdelete.push({goalpos:i,objectivepos:j}); 
                    }
                }               
                this.InterventionsModel[i].objectives = arrtemp.length > 0 ?  arrtemp : this.InterventionsModel[i].objectives;               
                arrposdelete.reverse();
                for(var k = 0; k < arrposdelete.length; k++){                   
                    this.InterventionsModel[arrposdelete[k].goalpos].objectives.splice(arrposdelete[k].objectivepos, 1);    
                }
                arrtemp = [];
                arrposdelete = [];
            }
            
        },

        getMedTxInterventions() {
            this.PopulateListRequestIntervention();
            this.$emit('load', true);
            var parameter = {
                ProblemCode: this.Problem,
                GoalCodeList: this.GoalCodeList,
                ObjectiveCodeList: this.ObjectiveCodeList,
                IsWizard:true
            };
            TreatmentPlanService.getMedTxInterventions(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.InterventionsList = response.data;

                    this.InterventionsList.forEach(element => { 

                        for(var i = 0; i < element.objectives.length; i++){

                            element.objectives[i].class = "InterventionByObjWizard";
                            element.objectives[i].classSummary = "";
                            element.objectives[i].interventionsSelected = element.objectives[i].interventionsSelected = [];
                            element.objectives[i].itvDdescriptionSelected = element.objectives[i].itvDdescriptionSelected = [];

                            for(var j = 0; j < element.objectives[i].interventions.length; j++){
                                element.objectives[i].interventions[j].class = "";
                            }
                        }

                        if (!this.ExistGoal(element.goalCode)) {                           
                            this.InterventionsModel.push({
                                goalCode:element.goalCode,
                                goalDescription:element.goalDescription,
                                objectives:element.objectives
                            });
                        }
                    })

                    this.RemoveGoalNotChecked();
                    this.RemoveObjectiveNotChecked();
                    this.AddNewObjectives();
                   
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('treatmentplan.tPlanInter'), error.response.data.errorMessage, "error");
                }
            });
        },

        beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
    },
    created() {
        this.EmployeeCode = this.$store.state.employeeCode; 
        this.getEmployeeProvider();
        this.getEmployeeCoProvider();
        window.addEventListener('beforeunload', this.beforeWindowUnload);
        EventBus.$on("onChanged", function (e) {      
            this.pendingChanges = e.Changed
        }.bind(this));
    },
    async mounted() {
        this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.$emit('getBtnRightMenu', this.OptionsRightMenu);
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
        const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

		if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
		if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
		if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
		if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
        //this.getDiagTx();
        this.getMedTxProblems();
    },
    
    beforeDestroy () {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
};
</script>


