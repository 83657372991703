<template>
  <div v-show="!isLoading">
    <b-modal :id="ModalId" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :title="TitleWindow" :visible="!NotOpenByDefault ? true : false"  :ok-disabled="isEditMode"  @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')">
      <Loading v-bind:IsLoading="this.Load" />   
  
      <div v-if="isListing" id="AssessmentList">
        <div class="row">
          <div class="card" style="width:100%;">
              <div class="card-body">
                  <MdTitle v-bind:Text="$tc('Assessment.AssessmentList',2)" />  
                  <div class="row">
                      <div class="col-sm-12 col-md-3">                      
                          <MdButton
                            v-bind:Label="$t('Assessment.AddAssessment')" 
                            v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                            v-bind:IconClass="'ti-plus pr-1'"
                            v-on:click="AddAssessment"
                            v-bind:isReadOnly=this.isReadOnly>
                          </MdButton>
                      </div>
                      <div class="col-sm-12 col-md-9 mb-4">
                          <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                              <div class="input-group">
                                  <input 
                                    type="text" 
                                    class="form-control"
                                    v-model="parameter.SearchCriteria"   
                                     @keyup.enter="SearchAssessment"                            
                                  >
                                  <div class="input-group-append">
                                      <div class="input-group-append">
                                      <MdButton 
                                        v-bind:Name="'btnsearchinput'"
                                        v-bind:VariantProp="'btn btn-sm search-attached'"
                                        v-bind:IconClass="'mdi mdi-magnify'"
                                        v-on:click="SearchAssessment">
                                      </MdButton>
                                  </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>              
              </div>
          </div>
        </div>
        <div class="row mb-2">
          <div class="card" style="width:100%;">
              <div class="card-body" style="margin-top:-30px !important;">
                  <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                  <div class="row">
                      <div class="col-md-12">
                      
                       <div>
  
                         <b-col lg="6" class="my-1">
        </b-col>
         <MdTable 
            v-bind:fields="fields"
            v-bind:items="items"
            v-bind:totalRows="totalRows"
            v-bind:isReadOnly="isReadOnly"
            v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
            v-bind:currentPage="currentPage"
            v-bind:EnabledRowClicked="true"
            v-bind:perPage="perPage"
            :onRowClicked="RowClicked"
            v-bind:pageOptions="pageOptions"
            :editIconEvent="EditAssessment"
            v-bind:VisibleEditIcon="true"
            :deleteIconEvent="RemoveAssessment"
            v-bind:VisibleDeleteIcon="true"
            :onRowSelected="onRowSelected"
            :onFiltered="onFiltered"
            :pageChanged="pageChanged"
            :perPageFunction="PerPageFunction">
          </MdTable>
        <!--  <MdTablePaginationClient v-bind:fields="fields" v-bind:items="items"
													v-bind:totalRows="totalRows" v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
													v-bind:currentPage="currentPage" v-bind:perPage="perPage"
													v-bind:SelectAll="true" v-bind:ShowDetails="true"
													v-bind:pageOptions="pageOptions" :editIconEvent="EditAssessment"
                          v-bind:VisibleEditIcon="true" :deleteIconEvent="RemoveAssessment"
                          v-bind:VisibleDeleteIcon="true" :tbodyRowAttr="tbodyRowClass"
													:onRowSelected="onRowSelected" :onFiltered="onFiltered"
													:pageChanged="pageChanged" :RowClick="RowClicked"
													:perPageFunction="PerPageFunction">
												</MdTablePaginationClient>-->





                      <!--  <MdTablePaginationClient
                            v-bind:fields="fields"           
                            v-bind:items="items"
                            v-bind:totalRows="totalRows"  
                            v-bind:refTable="refTable"           
                            v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                            v-bind:currentPage="currentPage"
                            v-bind:perPage="perPage"
                            v-bind:SelectAll="UseSelectTableAll"
                            v-bind:ShowDetails="true"                       
                            v-bind:pageOptions="pageOptions" 
                            :editIconEvent="EditAssessment"
                            v-bind:VisibleEditIcon="true" 
                            :deleteIconEvent="RemoveAssessment"
                            v-bind:VisibleDeleteIcon="true" 
                            :onRowSelected="onRowSelected" 
                            :onFiltered="onFiltered"
                            :pageChanged="pageChanged"
                            :RowClick="RowClicked"
                            :perPageFunction="PerPageFunction">
                          </MdTablePaginationClient>-->







    </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
     </div>

     <div v-show="isEditMode" id="AssessmentDetail">
      <div id="ContainerPainAssessment">     
         <b-row class="mt-2">
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                    v-bind:Label="$t('Shared.WrittenBy')"
                    data-field="therapist"
                    v-bind:Options="therapistList"
                    v-bind:FieldDescription="'fullName'"
                    v-bind:FieldCode="'code'"
                    v-bind:IsDisabled="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:Model="painAssessment.therapistCode"                       
                    v-bind:Multiple="false"
                    :Input="ChangeDropDownTherapistCode"
                />
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdCheckBox
                    v-bind:Label="$t('MdNote.Sign')"
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:Name="'checkbox-SignAssessment'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-model="painAssessment.isSigned"
                    v-on:change="onSignPassword"
                />
            </b-col>
         </b-row>
         <MdTitle v-bind:Text="$t('MdPainAssessmentNotesOutCome.ASSESSMENT')" v-bind:isSubtitle="true" v-bind:Name="'T_Assessment'"/>
         <b-row>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Shared.Date')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'date'"
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-model="painAssessment.assessmentDate">                                     
                </MdEdit>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Incident.IncTime')"
                    v-bind:TypeProp="'time'"
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null" 
                    v-bind:Name="'time'"
                    v-model="painAssessment.assessmentTime">                                     
                </MdEdit>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <div class="textlabel" :data-label="this.$t('MdPainAssessmentNotesOutCome.Intensity')">
                  <b-form-spinbutton id="Intensity" v-model="painAssessment.painIntensity" min="1" max="100"></b-form-spinbutton>
                </div>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('VitalSign.Location')"
                    v-bind:TypeProp="'text'" 
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:Name="'Location'"
                    v-model="painAssessment.location"
                    v-bind:Id="'input_location_id'">                                     
                </MdEdit>
            </b-col>
         </b-row>
         <b-row>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                    v-bind:Label="$t('MdPainAssessmentNotesOutCome.Character')"
                    data-field="Character"
                    v-bind:Options="charactertList"
                    v-bind:FieldDescription="'name'"
                    v-bind:IsDisabled="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:FieldCode="'value'"
                    v-bind:Model="painAssessment.painCharacter"                       
                    v-bind:Multiple="false"
                    :Input="ChangeDropDownCharacter"
                />
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                    v-bind:Label="$t('Shared.Frequency')"
                    data-field="Frequency"
                    v-bind:Options="frequencyList"
                    v-bind:FieldDescription="'name'"
                    v-bind:IsDisabled="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:FieldCode="'value'"
                    v-bind:Model="painAssessment.frequency"                       
                    v-bind:Multiple="false"
                    :Input="ChangeDropDownFrequency"
                />
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('MdPainAssessmentNotesOutCome.Duration')"
                    v-bind:TypeProp="'number'" 
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:Name="'Duration'"
                    v-model.number="painAssessment.duration">                                     
                </MdEdit>
            </b-col>
         </b-row>
         <MdTitle v-bind:Text="$t('MdPainAssessmentNotesOutCome.Interv')" v-bind:isSubtitle="true" v-bind:Name="'T_Interventions'"/>
         <b-row>       
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('MdPainAssessmentNotesOutCome.Other')"
                    v-bind:TypeProp="'text'" 
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:Name="'Other'"
                    v-model="painAssessment.otherIntervention">                                     
                </MdEdit>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Incident.IncTime')"
                    v-bind:TypeProp="'number'" 
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:Name="'Time'"
                    v-model.number="painAssessment.interventionTime">                                     
                </MdEdit>
            </b-col>
         </b-row>
         <b-row>
          <b-col md="4" lg="4" sm="12">       
                  <MdCheckListBox2 
                    v-model="painAssessment.interventionList"
                    v-bind:OnChangeProp="OnChangeIntervention"
                    v-bind:Options="interventionList"
                    v-bind:Height="'150px'"
                    v-bind:columnCount="2"
                    :textField="'name'"
                    :valueField="'code'"> 
                  </MdCheckListBox2>
        </b-col>
         </b-row>
         <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_Notes'"/>
         <b-row>
            <b-col md="12" lg="12" sm="12">
                <MdMemo
                    v-bind:Name="'textarea_notes'"
                    v-bind:Rows="'3'"
                    v-bind:isReadOnly="painAssessment.painAssessment === '' || painAssessment.painAssessment === null"
                    v-bind:MaxRows="'6'"
                    v-bind:MaxLength="'500'"
                    v-model="painAssessment.notes"
                />
            </b-col>
         </b-row>
       </div>

       <b-row class="mt-2">
        <b-col md="12">
            <div class="text-center text-md-right mt-3 container-button">
              <MdButton
              v-bind:Label="$t('Shared.Back')"
              v-bind:ClassProp="'secondary mx-2 btn-login'"
              v-on:click="showListing(true)"
          />
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:VariantProp="'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
            />          
            </div>
        </b-col>
    </b-row>
    
     </div>
       
     <template #modal-footer v-if="isEditMode">
      <div class="w-100">       
       
      </div>
    </template>

    <MdPasswordDlg v-if="showSignPassword"
      v-bind:UserId="UserId"
      v-bind:ShowPopup="showSignPassword"
      v-bind:EmployeeCode="this.$store.state.employeeCode"
      @getModalPassword="onModalPasswordResult($event)"
    />
      
     
   </b-modal>  
  </div>
</template>

<script>
import AssessmentService from '@/services/painassessment.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'
import Loading from '@/components/template/loading/Loading'
import LocalStorage from '@/services/storage.service';
import EnumService from "@/services/enum.service";
import TherapistService from '@/services/therapist.service'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'ListingPainAssessment',
  props: {
     ModalId: String,
     PatientCode:String,
     TitleWindow: String,
     outComeCode:String,
     NotOpenByDefault:Boolean,
     ForUsefromMars:Boolean,
     MarsParam:Object,
     RecordId:String
   },
   mixins: [ShowMessage, DBFunctions],
   components: {
      Loading
    },
  data(){
    return{
      UseSelectTableAll:false,
      refTable: 'selectableTablePainAssessment',
      Load:false,
      recNo:null,
      isAdd:false,
      UserId:LocalStorage.getUserId(),
      isLoading:true,
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0 ,
            RecordId:this.RecordId,           
        }, 
        Header:{}, 
        isReadOnly: false,
        therapistList: [],
        charactertList: EnumService.Characterts,
        frequencyList: EnumService.Frequencys,
        interventionList: EnumService.Interventions,
        painAssessment: {
          therapistCode:"",
          isSigned:false,
          assessmentDate:null,
          assessmentTime:null,
          painIntensity:null,
          location:"",
          painCharacter:"",
          duration:null,
          frequency:"",
          interventionList:[],
          otherIntervention:"",
          interventionTime:null,
          notes:"",
        },
        showSignPassword: false,
        pendingChanges: false,
        clickOK: false,      
        ShowMessageIsEmpty:false,
        isEditMode:false,
        isListing: true,      
        items:[],
        pageOptions: [5, 10, 15 ],
        totalRows: 0,
        currentPage: 1,
        SelectedRecords:0, 
        perPage: 10,
        filter: null,
        filterOn: [],      
        selected: [], 
        isSelectFirst:false,     
        //this.$tc('Shared.Program') 
        fields:[
          
          { key: 'assessmentDate', sortable: true, label: 'Assessment Date', class: 'text-rigth' },
          { key: 'assessmentTime', sortable: true, label: 'Assessment Time', class: 'text-rigth' },
          {key:'recNo', sortable: true, label: 'Assessment Time', class: 'hidden' },
          { key: 'painIntensity', sortable: true, label: 'Pain Intensity', class: 'text-rigth' },
          { key: 'location', sortable: true, label: 'Location', class: 'text-rigth' },
          { key: 'selected', sortable: true, label: '', class: 'text-rigth hidden' },     
          { key: 'painCharacter', sortable: true, label: 'Pain Character', class: 'text-rigth' },
          { key: 'frequency', sortable: true, label: 'Frequency', class: 'text-rigth' },
          { key: 'duration', sortable: true, label: 'Duration', class: 'text-rigth' },
          { key: 'intervention', sortable: true, label: 'Intervention', class: 'text-rigth' },
          { key: 'otherIntervention', sortable: true, label: 'Other Intervention', class: 'text-rigth' },
          { key: 'therapistCode', sortable: true, label: 'Therapist Code', class: 'text-rigth' },
          { key: 'sysUser', sortable: true, label: 'Sys User', class: 'text-rigth' },
          { key: 'sysDate', sortable: true, label: 'Date', class: 'text-rigth' },
        ],

    }
  },



  methods:{
    tbodyRowClass(item) { 
      if(item != null){
         if(item.selected){
          return ["b-table-row-selected", "table-active", "cursor-pointer"]
        } else {
          return ["cursor-pointer"]
        }
      }
      },
      getTextResult() {
         let textResult = ''  
         let keys = [];     
         if(this.selected.length > 0){
            var item = this.selected[0];  
            keys = Object.keys(this.items[0]);
            keys.forEach(element => {             
               if(item[element] != '' && item[element] != '0'  && item[element] != null) {  
                  if(element != 'headerId' && element != 'sysDate' && element != 'sysUser' && element != 'recNo' && element != 'titleRecNo' && element != 'selected'){
                    textResult +=  element + ":"+item[element]+" ";
                  }                 
               }
               });
               textResult = textResult+"\r\n";
         }
         else{
            if (this.items.length > 0) {
            this.items.forEach(item => {
               keys = Object.keys(item)
               keys.forEach(element => {
               if(item[element] != '' && item[element] != '0' && item[element] != null) {
                  if(element != 'headerId' && element != 'sysDate' && element != 'sysUser' && element != 'recNo' && element != 'titleRecNo' && element != 'selected'){
                    textResult +=  element + ":"+item[element]+" ";
                  }
               }
               });
               textResult = textResult + "\r\n";
            });            
         }
         }
     

         return textResult
      },

    tbodyRowAttr() {
			return {style: 'cursor: pointer'}
		},
    emitEventHide(handled){
        if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
               if(this.ForUsefromMars){
                  let param = {
                     info:this.getTextResult(),
                     outComeCode:this.outComeCode,
                     MarsParam:this.MarsParam 
                  }
                  this.$emit('getCodeConditionOK', param);
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';
               }else{
                  this.$emit('getCodeConditionOK', this.getTextResult());
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';
               }
               
            } else {
                this.$emit('getCancel');
                this.ResetProps();
            }
        }
      },
    emitEventOK(){      
      this.clickOK = true
      },

    RowClicked(item){ 
        if(item.selected){      
          this.$set(item, 'selected', false); 
        }else{
          this.$set(item, 'selected', true);  
        }
        EventBus.$emit('rowClicked', item);
      // EventBus.$emit('rowWasClicked', item);
    },

   /* RowClicked(item){ 
      console.log(item);
        if(item.selected){      
          this.$set(item, 'selected', false);         
        }else{
          this.$set(item, 'selected', true);       
        }
       // EventBus.$emit('rowClicked', item);
       EventBus.$emit('rowWasClicked', item);
    },*/

    showListing(show) {
        this.isEditMode = show ? false : true;
        this.isListing = show ? true : false;
      },

      ResetProps(){
        this.parameter.SearchCriteria = '';
        this.showListing(true);
      },

    SearchAssessment(){         
      this.parameter.PageSize = this.perPage;    
      AssessmentService.getAll(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data){
            this.items = response.data;
          //  let arr = [];
           
          if (this.items.length === 0) {
            this.ShowMessageIsEmpty = true;                      
          }
            this.isLoading = false;          
         //   document.getElementById("MarsMedicationModalPainAssessmentList___BV_modal_body_").className = !this.isEditMode ? '' : 'modal-body'; 
            if(response.data.length > 0){                        
              this.items.forEach(function(element) { 
                element.selected = false;          
                if (element.assessmentDate) element.assessmentDate = Utils.formatterDateToString(element.assessmentDate);  
                if (element.assessmentTime) element.assessmentTime = Utils.formatTimeHm(element.assessmentTime);        
                if (element.sysDate) element.sysDate =  Utils.formatterDateToString(element.sysDate);           
            });


            if(this.items.length > 0 && this.isSelectFirst){          
             this.RowClicked(this.items[0]);
              this.isSelectFirst = false;
            }


            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
            }
          } 
          this.isLoading = false;     
          //this.Loading = false;
        //  this.$emit('load', this.Loading);
        })
        .catch((error) => {
          this.isLoading = false;    
          //  this.Loading = false;
          //  this.$emit('load', this.Loading);
            if (!error.response) {
              this.showMessage(this.$t('AdmissionList.ClinicalProgramFilter'),this.$t('Msg.retrieveInfo'),"error");
            } else {
              this.showMessage(this.$t('AdmissionList.ClinicalProgramFilter'),error,"error");
            }
        }); 
    },

    ChangeDropDownTherapistCode(item){
        this.painAssessment.therapistCode = item ? item.code : '';
    },

    ChangeDropDownCharacter(item){
        this.painAssessment.painCharacter = item ? item.value : '';
    },

    ChangeDropDownFrequency(item){
        this.painAssessment.frequency = item ? item.value : '';
    },

    OnChangeIntervention(){

    },

    getAssessment(){
        AssessmentService.get(this.recNo)
          .then((response) => { 
            if(response.data){   
              this.painAssessment = response.data;
              if(this.painAssessment.assessmentDate)
                this.painAssessment.assessmentDate = Utils.formatterDate(this.painAssessment.assessmentDate); 

                if(this.painAssessment.assessmentTime) 
                  this.painAssessment.assessmentTime = Utils.formatTimeHm(this.painAssessment.assessmentTime);
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdPainAssessmentNotesOutCome.GetTherapistsMsg'),error,"error");
            } 
          });  
      },

    getTherapists(){
        TherapistService.getAllTherapist()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              let arr = response.data;
              for(var i = 0; i<arr.length; i++){
                arr[i].fullName = arr[i].firstName+" "+arr[i].lastName;
              }
              this.therapistList = arr;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdPainAssessmentNotesOutCome.GetTherapistsMsg'),error,"error");
            } 
          });  
      },

      showToast(titleKey, msgKey, variant = "success") {
        this.$bvToast.toast( msgKey, {
            title: titleKey,
            autoHideDelay: 4000,
            variant: variant
        });
    },

      onSignPassword(value) {
        if(this.painAssessment.therapistCode) {
            this.showSignPassword = value;
        } else {
            this.painAssessment.isSigned = false
            this.showMessage(this.$t('Shared.VerifyPassword'),this.$t('MdPainAssessmentNotesOutCome.TheraRequiredMsg'),"error");
        }
      },

      onModalPasswordResult(result) {      
        if(result.status != true) {
          this.showMessage(this.$t('Shared.VerifyPassword'),result.message,"error");
          this.painAssessment.isSigned = false;
        }

        this.showSignPassword = false;
      },

      onCodeDescriptionOk(result){
        this.Allergy.allergyCode = result.code;
        this.getLookupFieldsDesc(result.code);
      },

      formatterDate(value){
         return Utils.formatterDate(value);
      },

      SaveOrEdit(){
        if(this.painAssessment.isSigned) { 
          this.pendingChanges = false;  
          this.SaveAssessment();        
        } else {
            this.showMessage(this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),
                          this.$t('MdPainAssessmentNotesOutCome.SingBeforeMsg'),"error");
        }
      },

     async SaveAssessment(){ 
        let data = {
          HeaderId:this.RecordId,
          TherapistCode:this.painAssessment.therapistCode,
          IsSigned:this.painAssessment.isSigned,
          AssessmentDate:this.painAssessment.assessmentDate,
          AssessmentTime:this.painAssessment.assessmentTime ? Utils.getDefaultDate()+this.painAssessment.assessmentTime:null,
          PainIntensity:this.painAssessment.painIntensity,
          Location:this.painAssessment.location,
          PainCharacter:this.painAssessment.painCharacter,
          Duration:this.painAssessment.duration,
          Frequency:this.painAssessment.frequency,
          Intervention:this.painAssessment.interventionList,
          OtherIntervention:this.painAssessment.otherIntervention,
          InterventionTime:this.painAssessment.interventionTime,
          Notes:this.painAssessment.notes,
          PatientCode:this.PatientCode,          
        }
        if(this.isAdd){       
          AssessmentService.Add(data)
        .then((response) => {
           if(response.status == '200'){   
                this.isListing = true;
                this.isEditMode = false;   
                this.isSelectFirst = true;
                this.SearchAssessment();
             setTimeout(() => 
                  this.showToast(
                    this.$t('Msg.Saving'),
                    this.msg
                ), 1500); 
            }                           
        })
        .catch((error) => {
            if (error.response) {
                this.showMessage(this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),error,"error");
            }
        });
        }else{
          AssessmentService.Update(this.recNo,data)
        .then((response) => {
           if(response.status == '200'){   
            this.isListing = true;
            this.isEditMode = false;   
            this.SearchAssessment();
             setTimeout(() => 
                  this.showToast(
                    this.$t('Msg.Saving'),
                    this.msg
                ), 1500); 
            }                           
        })
        .catch((error) => {
            if (error.response) {
                this.showMessage(this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),error,"error");
            }
        });
        }
       
      },

      ResetObj(){
        this.painAssessment.therapistCode = "";          
        this.painAssessment.isSigned = false;
        this.painAssessment.assessmentDate = null;
        this.painAssessment.assessmentTime = null;
        this.painAssessment.painIntensity = null;
        this.painAssessment.location = "";
        this.painAssessment.painCharacter = "";
        this.painAssessment.duration = null;
        this.painAssessment.frequency = "";
        this.painAssessment.interventionList = [];
        this.painAssessment.otherIntervention = "";
        this.painAssessment.interventionTime = null;
        this.painAssessment.notes = "";  
      },

    AddAssessment(){
      //this.isNewContact = true;
     //  this.mountContactsEdit();
       this.ResetObj();
       this.isAdd = true;
       this.showListing(false);
    //   document.getElementById("MarsMedicationModalPainAssessmentList___BV_modal_body_").className = !this.isEditMode ? '' : 'modal-body'; 
       
       
    },

    RemoveAssessment(){

    },

    EditAssessment(row){
      //this.isNewContact = false;
      // this.mountContactsEdit(row.recordId); 
      this.isAdd = false;
      this.recNo = row.recNo;
      this.showListing(false);
    //  document.getElementById("MarsMedicationModalPainAssessmentList___BV_modal_body_").className = !this.isEditMode ? '' : 'modal-body';
      this.getAssessment();
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    /*onRowSelected(items) {    
     this.selected = items;
    },
*/
   /* onRowSelected(){ 	
   
     this.SelectedRecords = 0;
     if(this.items.length > 0){
    for(var i = 0; i < this.items.length; i++){
      if(this.items[i].selected){
        this.SelectedRecords++;
      }
    }
     }
    },*/

    onFiltered(){

    },
    pageChanged(){

    },
    PerPageFunction(){

    }
  },

  created(){
  },

  mounted(){
    this.getTherapists()
    this.SearchAssessment();
   
  }
   
};
</script>

<style scoped>
.modal-body{
  width: auto!important;
}
</style>