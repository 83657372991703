<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" scrollable centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalAddGoals" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
      
        <b-row>
            <b-col lg="12" md="12" sm="12">     
                <MdTitle v-bind:Text="$t('modalAddGoal.goals')" v-bind:isSubtitle="true" v-bind:Name="'Goals'" class="subtitleWizard"/>    
                <MdCheckListBox2 
                v-model="GoalsModel"
                v-bind:Options="GoalsList"            
                v-bind:OnChangeProp="OnChangeGoals"
                v-bind:columnCount="1"
                :textField="'description'"
                :valueField="'goalNumber'"/> 
            </b-col>                                   
          </b-row>  

        
        <b-row class="mt-2">
            
            <b-col md="12">
                <MdTitle v-bind:Text="$t('modalAddGoal.other')" v-bind:isSubtitle="true" v-bind:Name="'Other'"/>  
                <MdMemo v-bind:Name="'goaldescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="false"
                            v-bind:DynamicClass="'modalTextarea'" v-bind:MaxRows="'6'"
                            v-model="goaldescriptionBlank" />
            </b-col>
           </b-row>    
       
     </b-modal>  
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import TreatmentPlan from '@/services/treatmentplan.service'
 

 export default {
   name: 'ModalAddGoals',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String
   },
   
   data(){
     return {
        goaldescriptionBlank:"",
        GoalsList:[],
        GoalsModel:[],
        Goaldata:[],
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
      this.AddGoal();      
     },

     getDescrByCode(code){
        let result = "";
        for(var i = 0; i < this.GoalsList.length; i++){
           if(this.GoalsList[i].goalNumber === code){
            result = this.GoalsList[i].description;
            break;
           }
        }
        return result;
     },

     PrepareData(){
            this.Goaldata = [];         
            for(var i = 0; i < this.GoalsModel.length; i++){
                let descrp = this.getDescrByCode(this.GoalsModel[i]);
                this.Goaldata.push(
                    {
                        probRecId: this.ProblemRecId.toString(),
                        goalCode:this.GoalsModel[i].toString(),
                        goalDesc:descrp,
                    }
                    );
            }

            if(this.goaldescriptionBlank.length > 0){
                this.Goaldata.push(
                    {
                        probRecId: this.ProblemRecId.toString(),
                        goalCode:"0",
                        goalDesc:this.goaldescriptionBlank,
                    }
                    );
            }
        },

        AddGoal(){        
        this.PrepareData();
        if(this.Goaldata.length > 0){
            var objdata = {
              goals:this.Goaldata
            };
         TreatmentPlan.AddGoal(objdata)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.GoalSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 5); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalAddGoal.goals'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalAddGoal.goals'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalAddGoal.goals'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalAddGoal.goals'),error.response.data.errorMessage,"error");
                     }    
            });
        }
    },

     OnChangeGoals(){

     },

    async getWileyTxGoals() {
            this.$emit('load', true);
            var parameter = {               
                GroupId: this.groupId,
                ProblemNumber: this.probCode,
                HeaderId:this.headerId
            };
           await TreatmentPlan.getWileyTxGoals(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.GoalsList = response.data;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage("Treatment Plan Goals", this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage("Treatment Plan Goals", error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage("Treatment Plan Goals", this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage("Treatment Plan Goals", error.response.data.errorMessage, "error");
                }
            });
        },

     emitEventCancel(){
      this.$emit('ModalTPGoal');
    },    
   },
 
  async mounted(){
    await this.getWileyTxGoals();
   },
 };
 </script>

