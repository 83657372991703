<template>
      <div :class="UseColumnDinamic ? Column : 'col-md-3'">

		<b-modal
			v-if="activateModalDiagnosis"
            :no-close-on-backdrop="true" 
            scrollable 
            centered           
            size="xl"  
            @cancel="this.emitEventCancel" 
            :id="ModalIdDiagnosis" 
            :title="this.$t('Nurses.MarsMedication')"  
            :ok-title="$t('Shared.Save')" 
            @ok="handleOk" 
            style="padding:none!important;" 
            :cancel-title="$t('Shared.BtnCancel')"
            
        >
           <PatientDiagnosis v-bind:PatientCode="this.PatientCode"></PatientDiagnosis>
            
        </b-modal>
		<!--class="col-md-4 grid-margin stretch-card"-->
					<div class="card activeCard">
						<div class="card-body">
						<div v-if="isUsePatientInfo" class="d-flex align-items-start justify-content-between pointer">
							<h6 v-on:click="GoToPatientInfo" class="card-title flex-grow lower-line text-truncate">{{ $t('MdPatientDiagnosis.Diagnosis') }}</h6><i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="this.GoToPatientInfo"></i>
						</div>
						<div v-if="!isUsePatientInfo" class="d-flex align-items-start justify-content-between">
							<h6 class="card-title flex-grow lower-line text-truncate">{{ $t('MdPatientDiagnosis.Diagnosis') }}</h6>
						</div>
							<div class="text-muted p-fixed cutescroll">
						<p v-if="Diagnosis.length === 0">{{ $t('MdPatientDiagnosis.NoKnownDiagnosis') }}</p>
						<ul>							
							<li v-for="(it, index) in Diagnosis" :key="`it.diagCode-${index}`">{{it.diagName}}</li>						
						</ul>
						</div>
						</div>
						</div>
					</div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import PatientDiagnosis from '@/components/pages/patients/Diagnosis'
  export default {
    props: {
		PatientCode:String,
		UseColumnDinamic:Boolean,
		VisibleDiagnosis:Boolean,
		Column:String,
		ModalIdDiagnosis:String,
		actId: String
   },
   mixins: [DBFunctions],
   components:{PatientDiagnosis},
    data() {
      return {
		Diagnosis:[],
		activateModalDiagnosis:true,
		isUsePatientInfo: this.$store.state.isUsePatientInfo
      }
    },
    
  methods:{
	async getDiagnosis(){
		this.Diagnosis = await this.getPatientDiagnosis(this.PatientCode);		
	},
	emitEventCancel(){
		//this.activateModalDiagnosis = false;		
		this.$bvModal.hide(this.ModalIdDiagnosis);
	},
	handleOk(){

	},

	GoToPatientInfo(){
		if(!this.VisibleDiagnosis){
			if (this.actId)
				this.$router.push({ name: "appointments-diagnosis", params: { id: this.PatientCode, actId: this.actId } }).catch(()=>{});	
			else 
				this.$router.push({ name: "patient-diagnosis", params: { id: this.PatientCode } }).catch(()=>{});
		}else{
			//this.activateModalDiagnosis = true;
			this.$bvModal.show(this.ModalIdDiagnosis);
		}
        
    },
  },
   mounted() {
	this.getDiagnosis();
   }
}

</script>