import ApiService from '@/services/api.service'

const ChartReview = {

  getPatientRegistrationsDefaultDates(RecordId){
    return ApiService.get(`/PatientRegistrations/DefaultDates?RecordId=${RecordId}&IsChartReview=true`);  
  },
  

  // Note Type View Listing
  getNoteList(parameter) {
    var param = {};
    var _Url = '/NotesLists/ChartReviewByNote/';
    param.PageSize = parameter.PageSize;
    param.PageNumber = parameter.PageNumber;
    param.PatientCode = parameter.PatientCode;
    param.DateFrom = parameter.DateFrom;
    param.DateTo = parameter.DateTo;   
    param.UserId = parameter.UserId;

    param.NotesIdList = parameter.NotesIdList;
    param.AdmissionRecordIdList = parameter.AdmissionRecordIdList;
    param.TherapistCodesList = parameter.TherapistCodesList;
    param.CoTherapistCodesList = parameter.CoTherapistCodesList;
    param.DocumentTypeList = parameter.DocumentsIdList;
    param.TypesToInclude = parameter.typesToInclude;
    param.DailyNotesIdList = parameter.DailyNotesIdList;
    param.LogNotesIdList = parameter.LogNotesIdList;

    if(parameter.NoteStatus !== ""){
      param.NoteStatus = parameter.NoteStatus
    }
   
   
    /*const requestData = {
        method: 'post',
        url: _Url,
        params: param
    }*/
    return ApiService.post(_Url, param);
  },

  getAllDate(parameter) {
    var param = {};
    var _Url = '/NotesListDates/ChartReview/';
    param.PageSize = parameter.PageSize;
    param.PageNumber = parameter.PageNumber;
    param.PatientCode = parameter.PatientCode;
    param.DateFrom = parameter.DateFrom;
    param.DateTo = parameter.DateTo;
    param.UserId = parameter.UserId;
    param.NotesIdList = parameter.NotesIdList;
    param.TherapistCodesList = parameter.TherapistCodesList;
    param.CoTherapistCodesList = parameter.CoTherapistCodesList;
    param.DocumentTypeList = parameter.DocumentsIdList;
    param.TypesToInclude = parameter.typesToInclude;
    param.DailyNotesIdList = parameter.DailyNotesIdList;
    param.LogNotesIdList = parameter.LogNotesIdList;

    if(parameter.NoteStatus !== ""){
      param.NoteStatus = parameter.NoteStatus
    }
   
    /*const requestData = {
        method: 'post',
        url: _Url,
        params: param
    }
    return ApiService.customRequest(requestData);*/
    return ApiService.post(_Url, param);
  },


    
  }
  
  export default ChartReview;