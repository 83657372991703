<template>	
    <div id="DivModalClaimTypeOfBill">
    <b-modal id="modalIdClaimTypeOfBill" size="xl" centered scrollable  :title="TitleWindow" @ok="this.handleOk" @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
    <Loading v-bind:IsLoading="this.Load" />    
       <MdTableTypeOfBill
              v-bind:fields="fields"           
              v-bind:items="items"
              v-bind:totalRows="totalRows"  
              v-bind:refTable="refTable"           
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"            
              v-bind:ShowDetails="true"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:pageOptions="pageOptions" 
              :onRowSelected="onRowSelected" 
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"              
              :perPageFunction="PerPageFunction">
       </MdTableTypeOfBill>
    </b-modal>   
    </div>   
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
import ClaimFileCreations from '@/services/claimfilecreations.service';
import Loading from '@/components/template/loading/Loading'
import Utils from '@/common/utils'
export default {
  name: 'ModalTypeOfBill',
  props: {     
     TitleWindow:String,
      
   },
    mixins: [DBFunctions,EventBus],
    components: {
    Loading
  },
    data() {
		return {
            RecId:null,
            selected: 0, 
             Load:true,
            ProviderCode:"",
            SeqNoCommaSeparated:"",
            PatientCodeList:"",
            TypeOfBillList:"",
            items:[],
            pageOptions: [5, 10, 15],
            perPage: 10,           
            totalRows: 0,   
            refTable:"TableClaimTypeOfBill",         
            currentPage: 1,
            FieldsDateArr:['dateFrom','dateTo'],
            ShowMessageIsEmpty: false,
            fields: [
                { key: "patientCode", sortable: true, label: this.$t('Shared.PatientCode'), class: "text-rigth" },
                { key: "patientName", sortable: true, label: this.$t('Shared.PatientName'), class: "text-rigth" },                
                { key: "dateFrom", sortable: true, label: this.$t('Shared.datefrom'), class: "text-rigth" },
                { key: "dateTo", sortable: true, label: this.$t('Shared.dateTo'), class: "text-rigth" },
                { key: "typeOfBill", sortable: true, label: this.$t('modalTypeOfBill.TypeOfBill'), class: "text-rigth" }                    
            ],
		}
	},
	mounted() {  
   this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId == 'modalIdClaimTypeOfBill'){
       // this.getTypeOfBillListing(this.ProviderCode,this.SeqNoCommaSeparated);
       this.items = [];
      }
    });
      EventBus.$on("onGetTypeOfBill", function () {      
          this.Load = true; 
          this.getTypeOfBillListing(this.ProviderCode,this.SeqNoCommaSeparated);
      }.bind(this));
    },

    beforeDestroy () {     
      EventBus.$off('onGetTypeOfBill');  
      EventBus.$off('onCreateFile');  
    },
  created(){ 
      EventBus.$on("onSelectProvider", function (Code) {
        this.ProviderCode = Code;  
      }.bind(this));

      EventBus.$on("onSelectSeqNo", function (SeqNo) {
        this.SeqNoCommaSeparated = SeqNo;  
      }.bind(this));
      
  },
	methods:{
    onRowSelected(){
    },
    onFiltered(){
    },
    pageChanged(){
    },
    PerPageFunction(){
    },

    getdata(){
      this.PatientCodeList = "";
      this.TypeOfBillList = "";
      var l = this.items.length;
      for(var i = 0; i<l; i++){
        if(i < l-1){
          this.PatientCodeList += this.items[i].patientCode.trim()+",";
          this.TypeOfBillList += this.items[i].typeOfBill.trim()+",";
        }else{
          this.PatientCodeList += this.items[i].patientCode.trim();
          this.TypeOfBillList += this.items[i].typeOfBill.trim();
        }
      }
      EventBus.$emit("onCreateFile", this.ProviderCode,this.PatientCodeList,this.TypeOfBillList);
    },

      getTypeOfBillListing(Code,SeqNo) {
            ClaimFileCreations.getTypeofBill(Code,SeqNo)
                .then((response) => {
                if (response.data) {
                    this.items = response.data;                     
                  this.items.forEach(function (element) {
                        if (element.dateFrom)
                            element.dateFrom = Utils.formatterDateToString(element.dateFrom);
                        if (element.dateTo)
                            element.dateTo = Utils.formatterDateToString(element.dateTo);
                    });
                  this.Load = false; 
                }
            })
                .catch((error) => {
                this.Load = false; 
                if (error.response) {
                    this.showMessage(this.$t('modalTypeOfBill.TypeOfBill'), error, "error");
                }
            });
        },
    handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },
    emitEventCancel(){
      
      }, 
      handleSubmit() {       
        if(!this.validate_required()) {
          this.showMessage(this.$t('modalTypeOfBill.TypeOfBill'), this.$t('modalTypeOfBill.TofBRequired'), "errorAutoHide");
          return;
        }else{
          this.getdata();          
           this.$bvModal.hide('modalIdClaimTypeOfBill');
        }
        /*if(this.TemplateA && this.TypeOfBill){
          EventBus.$emit("onSelectProvider", this.ProviderModel.providerCode);
          this.$bvModal.show(this.modalIdTypeofBill);
        }*/
        this.$nextTick(() => {
          this.$bvModal.hide('modalIdClaimTypeOfBill');
        }) 
      },

      validate_required(){
        var result = true;  
        for(var i = 0; i < this.items.length; i++){
          if(this.items[i].typeOfBill.trim()==""){
           result = false;
           break;
          }
        }
        return result;
       /* if(result){
           this.showMessage("Type Of Bill", "The Type Of Bill column is required", "error");
        }*/
      },

     emitEventOK(){ 

      },
	},
}
</script>
