<template>
  <div>
   <b-modal size="xl" :id="ModalId" :visible="true" scrollable centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalAddGoals" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
    
    <b-row>
      <b-col lg="12" md="12" sm="12">     
        <MdTitle v-bind:Text="$t('modalBlankBehavioral.behaviorals')" v-bind:isSubtitle="true" v-bind:Name="'Behaviorals'" class="subtitleWizard"/>      
        <MdCheckListBox2 
          v-model="behavioralModel"
          v-bind:Options="BehavioralList"
          v-bind:OnChangeProp="OnChangeBehavioral"
          v-bind:columnCount="1"           
          :textField="'description'"
          :valueField="'definitionNumber'"/> 
      </b-col>                                   
    </b-row>  
  
  <b-row class="mt-2">            
      <b-col md="12">
        <MdTitle v-bind:Text="$t('modalAddGoal.other')" v-bind:isSubtitle="true" v-bind:Name="'Other'"/>  
          <MdMemo v-bind:Name="'behavioraldescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="false"
                      v-bind:DynamicClass="'modalTextarea'" v-bind:MaxRows="'6'"
                      v-model="behavioraldescriptionBlank" />
      </b-col>
     </b-row>     
     
   </b-modal>  
  </div>
</template>

<script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import TreatmentPlan from '@/services/treatmentplan.service'


export default {
 name: 'ModalBlankBehavioral',
 mixins: [ShowMessage],  
 props: {
    ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String
 },
 
 data(){
   return {
        behavioraldescriptionBlank:"",
        BehavioralList:[],
        behavioralModel:[],
        Behavioraldata:[],
   }
 },

 methods:{
  emitEventOK(/*bvModalEvent*/){      
       this.AddBlankBehavioral();
     },

     getDescrByCode(code){
        let result = "";
        for(var i = 0; i < this.BehavioralList.length; i++){
           if(this.BehavioralList[i].definitionNumber === code){
            result = this.BehavioralList[i].description;
            break;
           }
        }
        return result;
     },

     OnChangeBehavioral(){
       
     },

    async getWileyTxBehavioralDefinitions() {
            this.$emit('load', true);
            var parameter = {
                GroupId: this.groupId,
                ProblemNumber: this.probCode,
                HeaderId: this.headerId
            };
            await TreatmentPlan.getWileyTxBehavioralDefinitions(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    this.BehavioralList = response.data;
                    console.log(this.BehavioralList);
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('modalBlankBehavioral.behaviorals'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('modalBlankBehavioral.behaviorals'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('modalBlankBehavioral.behaviorals'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('modalBlankBehavioral.behaviorals'), error.response.data.errorMessage, "error");
                }
            });
        },

        PrepareData(){
            this.Behavioraldata = [];         
            for(var i = 0; i < this.behavioralModel.length; i++){
                let descrp = this.getDescrByCode(this.behavioralModel[i]);
                this.Behavioraldata.push(
                    {
                        probRecId: this.ProblemRecId.toString(),
                        definitionNumber:this.behavioralModel[i].toString(),
                        definitionDescription:descrp,
                        definitionRecId:"",
                        definitionDescriptionPlainText:"",
                    }
                    );
            }

            if(this.behavioraldescriptionBlank.length > 0){
                this.Behavioraldata.push(
                    {
                        probRecId: this.ProblemRecId.toString(),
                        definitionNumber:"0",
                        definitionDescription:this.behavioraldescriptionBlank,
                        definitionRecId:"",
                        definitionDescriptionPlainText:"",
                    }
                    );
            }
        },

     AddBlankBehavioral(){        
        this.PrepareData();
        if(this.Behavioraldata.length > 0){
            var objdata = {
                Definitions:this.Behavioraldata
            };
         TreatmentPlan.AddBlankBehavioral(objdata)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.BehavioralSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 20); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),error.response.data.errorMessage,"error");
                     }    
            });
        }
    },

   emitEventCancel(){
    this.$emit('ModalTPBlankBehavioral');
  },    
 },

async mounted(){
  await this.getWileyTxBehavioralDefinitions();
 },
};
</script>

