<template>
<div id="ContainerHcpcsCPT">
       <h4 class="page-title text-truncate">
                {{ $t('Incident.IncPartEmplType') }}         
        </h4>
        <b-row>
            <b-col lg="3" md="3" sm="12">
                <MdEdit
                     v-model="EmployeeType.code"                    
                     v-bind:Label="$t('Shared.Code')"          
                     v-bind:isReadOnly="this.$attrs.editMode"
                     v-bind:isRequired="true"
                     v-bind:isValid="isCodeValid"
                     :InvalidFeedback="this.$t('employeeType.EmplTypedCodeRequired')"
                     :SearchFieldCode="'Code'"
                     v-bind:Id="'input_e_code'"
                  />
            </b-col>  
            <b-col lg="6" md="6" sm="12">
                <MdEdit
                     v-model="EmployeeType.description"                    
                     v-bind:Label="$t('Shared.Description')"
                     v-bind:Id="'input_e_des'"  
                  />
            </b-col> 
          <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('employeeType.ShowInNotes')"
                  v-bind:Name="'showinNotes'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="EmployeeType.showInNotes"                 
               />
            </b-col> 
             
        </b-row>
       

    <div class="page-actions">      
     <MdButton
               v-bind:Label="$t('employeeType.List')"
               v-bind:Name="'btnbtn_save_EmployeeTypelist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>    
        <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnlyEmployeeType)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly="isReadOnlyEmployeeType"
            v-bind:Name="'btn_save_EmployeeType'"/>
    </div>

</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import EmployeeTypeService from '@/services/employeetypes.service'
import { EventBus } from '../../../../event-bus.js';
//import Utils from '@/common/utils'

let elemSaveBtn = '';
export default {
    beforeRouteLeave(to, from, next) {
        if (this.pendingChanges) {
                this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
                title: this.$t('Admission.Admission'),
                okTitle: this.$t('Shared.BtnSave'),
                cancelTitle: this.$t('Shared.BtnDismiss'),
                footerClass: 'p-2',
                titleClass: 'modal-title modal-type-warning'
            })
            .then(async value => {
                if(!value){
                    next();
                } else {
                    await this.SaveOrEdit();
                    next();
                }
            })		
        } else {
            next()
        }
    },

  name: 'EmployeeType',
 mixins: [ShowMessage, DBFunctions],
  data(){
    return{
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
       EmployeeType:{
        code:"",
        descrition:"",
        showInNotes:false      
       },
       pendingChanges:false,
        isReadOnlyEmployeeType:false,
      isCodeValid:true,
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

    }
  },

  methods:{

    DiscardChanges(){
       this.checkExistFavorite('/app/employeetypes');
       this.$router.push({name: "employeeTypeList"});
    },

    AddEmployeeType(){
      this.$emit('load', true);
      EmployeeTypeService.AddEmployeeType(this.EmployeeType)
                .then((response) => {
                    if(response.status == '200'){
                        this.EmployeeType = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "employeeTypeList"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })				
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('employeeType.EmplTypeTitle'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('employeeType.EmplTypeTitle'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('employeeType.EmplTypeTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('employeeType.EmplTypeTitle'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
    },


    EditEmployeeType(){
      this.$emit('load', true);
      EmployeeTypeService.UpdateEmployeeType(this.$route.params.id,this.EmployeeType)
                .then((response) => {
                    if(response.status == '200'){
                        this.EmployeeType = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "employeeTypeList"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('employeeType.EmplTypeTitle'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('employeeType.EmplTypeTitle'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('employeeType.EmplTypeTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('employeeType.EmplTypeTitle'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
    },

  getEmployeeType(){    
      if(this.$attrs.editMode){
        var ShNotes = false;
      this.$emit('load', true);     
      EmployeeTypeService.GetEmployeeType(this.$route.params.id)
        .then((response) => {
            if(response.status == '200' && response.data){
                if(response.data.showInNotes != null && response.data.showInNotes != "" && response.data.showInNotes.length > 0){
                    if(response.data.showInNotes == "Y"){
                        ShNotes = true;
                    }
                }
              this.EmployeeType = response.data;  
              this.EmployeeType.showInNotes = ShNotes;
            }   
              this.$emit('load', false);                     
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('employeeType.EmplTypeTitle'),this.$t('Msg.NotAbleToSave'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('employeeType.EmplTypeTitle'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('employeeType.EmplTypeTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('employeeType.EmplTypeTitle'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    
      }
    },

      DoAction(){     
        !this.$attrs.editMode ? this.AddEmployeeType() : this.EditEmployeeType(); 
      },

      SaveOrEdit(){ 
         if (this.validate_required()) {
            this.DoAction();
         } else {
            this.showMessage(this.$t('employeeType.EmplTypeTitle'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }

      },

      validate_required() {
        this.isCodeValid = this.EmployeeType.code.length > 0 ? true : false;   
        return this.isCodeValid;
      },
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      },

        beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
  },

    created(){
        EventBus.$on("onChanged", function (e) { 
            this.pendingChanges = e.Changed;      
            this.checkUserAction();
        }.bind(this));
        window.addEventListener('beforeunload', this.beforeWindowUnload);
    },

  mounted(){
          this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getEmployeeType();
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_EmployeeType');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );  
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
   
};
</script>


