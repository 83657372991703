<template>
    <div class="page-height">
        <MdTitleRegInfo
            v-bind:RegistrationInfo="PatientTitle" 
            v-bind:titlePrefix="this.editMode ? $t('Activities.EditActivity'): $t('Activities.AddActivity')" 
            v-bind:IsClinical="IsClinical"
            v-bind:titleRef="'titleElementEditActivity'"
            v-bind:patientCode="this.$route.params.id">
        </MdTitleRegInfo>

        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
                <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
        </div>

        <!--
        <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    -->
        
        <div class="row">
            <div class="col-12 mt-2">
                <div :data-label="$t('Shared.Notes')" class="textlabel"></div>
                <MdRichEdit
                    v-model="Activity.memoInfo"
                    v-bind:isReadOnly="isReadOnly"
                    v-bind:Height="'56vh'"
                    v-bind:id="'edit_memoInfo'"
                    ref="editor"
                    v-bind:isValid="isMemoInfoValid"
                    v-bind:isRequired="true"
                    v-bind:HideToolBar="true"
                    v-bind:StartupFocus="false"
                    @ready="onEditorReady"
                />
            </div>
        </div>

        <div class="flex-container">
            <div class="row">
                <b-col lg="3" md="3" sm="12">
                    <MdEdit 
                        v-bind:Label="$t('PatientAlerts.DateAndTime')"
                        v-bind:Name="'DateAndTime'"
                        v-bind:TypeProp="'text'" 
                        v-bind:Mask="'##/##/#### ##:## AA'"
                        v-bind:Min="'1900-01-01'"
                        v-bind:Max="'2200-12-31'"              
                        v-bind:isRequired="true"
                        v-bind:isValid="IsValidDateAndTime"
                        v-model="Activity.dateChanged"
                        v-bind:isReadOnly="isReadOnly"
                    >                                     
                    </MdEdit>
                </b-col>
                <b-col lg="6" md="6" sm="12">
                    <MdEdit 
                        v-bind:Label="$tc('Shared.Employee')"
                        v-bind:Name="'text'"
                        v-bind:TypeProp="'text'" 
                        v-model="employeeNameModel"
                        v-bind:isReadOnly="true">                                     
                    </MdEdit>
                </b-col>
            </div>

            <b-row>
                <b-col md="3" lg="3" sm="12">
                <MdCheckBox
                        v-bind:Label="$t('Activities.ShowToPatient')"
                        v-bind:Name="'checkbox-showToPatient'"
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"
                        v-model="Activity.showToPatient"
                        v-bind:isReadOnly="isReadOnly"
                />
                </b-col>
            </b-row>
        </div>

        <div class="space-actions"></div>
        <div class="page-actions">
            <MdButton
                v-bind:Label="$t('Shared.Back')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="goActivitiesList"
            />
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
                v-bind:Name="'btn_save_activity'"
            />
        </div>
    </div>
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from '@/common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import LocalStorage from '@/services/storage.service'
import { EventBus } from '@/event-bus.js';
import ActivitiesService from '@/services/activities.service'

let elemSaveBtn = '';
export default {
    beforeRouteLeave(to, from, next) {
        if (this.pendingChanges) {
            this.$bvModal
                .msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
                    title: this.$t('discharge.Discharge'),
                    okTitle: this.$t('Shared.BtnSave'),
                    cancelTitle: this.$t('Shared.BtnDismiss'),
                    footerClass: 'p-2',
                    titleClass: 'modal-title modal-type-warning'
                })
                .then(async value => {
                    if(!value){
                        next();
                    } else {
                        await this.SaveOrEdit();
                        if (!this.pendingChanges) {
                            next();
                        }
                    }
                })		
        } else {
            next()
        }
    },

    name: 'EditPatientActivity',
    mixins: [DBFunctions],
    props: ["editMode"],

    data() {
        return {
            InfoPatient:{},
            PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:''
            }, 
            OptionsRightMenu:{
				visible:true,
				ItemsList:[],
				btnvisible:true,
				itemdisabled: false,
				PatientCode:''
			},
            IsClinical:false,
            Activity: {
                memoInfo: '',
                dateChanged: Utils.formatterDateTimeToString(new Date()),
                showToUsers: null,
                showToEmployeeType: null,
                isNotify: null,
                showToPatient: false,
                userId: LocalStorage.getUserId(),
                patientCode: this.$route.params.id,
                isAlert: false,
                isActive: true
            },
            isReadOnly: false,
            isMemoInfoValid: true,
            TherapistSourceList:[],
            TherapistList:[],
            EmployeeTypeSourceList:[],
            EmployeeTypeList:[],
            pendingChanges: false,
            Mode:this.$attrs.editMode,
            loaded: false,
            IsValidDateAndTime: true,
            employeeNameModel: null,
        }
    },

    methods: {
        goActivitiesList() {
            if(this.$route.name.includes('clinical-')){ 
                this.$router.push({ name: "Clinical-activities" });
            } else if(this.$route.name.includes('schedulerdb-')){ 
                this.$router.push({ name: "schedulerdb-activitiesList" });
            } else {
                this.$router.push({ name: "patient-activities" });
            }
        },

        validate_required() {
            const plaintText =  this.$refs.editor.getPlaintText();
            this.IsValidDateAndTime = this.Activity.dateChanged ? true : false;
            this.isMemoInfoValid = plaintText.trim().length > 0;
            return this.IsValidDateAndTime && this.isMemoInfoValid;
        },

        validateDate(){
            this.IsValidDateAndTime = Utils.IsValidDateAndRange(this.Activity.dateChanged, Utils.getDateTimeFormat(), true) ? true : false;
            return this.IsValidDateAndTime;
        },

        SaveOrEdit() {
            if(!this.isReadOnly) {
                if (this.validate_required()) {
                    if(this.validateDate()){
                        this.$emit('load', true);
                        this.DoAction();
                    } else {
                        elemSaveBtn.removeAttribute("disabled");
                        this.showMessage(this.$t('Activities.Activity'), 
                            this.$t('Msg.InvalidDate') + ' ' + this.$t('Shared.Format') + ' '+ Utils.getDateTimeFormat(), "errorAutoHide");          
                    }
                } else {
                    this.showMessage(this.$t('Activities.Activity'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
                }
            }
        },

        DoAction() {
            let data = { ...this.Activity }
            data.dateChanged = Utils.formatterToISO(this.Activity.dateChanged, Utils.getDateTimeFormat());
            data.memoinfoPlainText = this.$refs.editor.getPlaintText();
            !this.editMode ? this.AddActivity(data) : this.EditActivity(data);
        },

        AddActivity(data) {
            ActivitiesService
                .add(data)
                .then(response => {
                    if (response.status == "200") {
                        this.pendingChanges = false;
                        this.goActivitiesList();    
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                            , 50); 
                    }

                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('Activities.Activity'));
                    this.$emit('load', false);
                });
        },

        EditActivity(data) {
            ActivitiesService
                .update(this.$route.params.code, data)
                .then(response => {
                    if (response.status == "200") {
                        this.pendingChanges = false;
                        this.goActivitiesList();    
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                            , 50); 
                    }

                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('Activities.Activity'));
                    this.$emit('load', false);
                });
        },

        onEditorReady() {
            if (!this.editMode) {
                this.$refs.editor.moveToLastPosition();
            }
        },

        getActivity() {
            if (this.editMode) {
                ActivitiesService
                    .get(this.$route.params.code)
                    .then(response => {
                        console.log(response.data)
                        if (response.status == "200" && response.data) {
                            const activity = {
                                memoInfo: response.data.memoinfo,
                                dateChanged: Utils.formatterDateTimeToString(response.data.datechanged),
                                showToUsers: response.data.showToUsers,
                                showToEmployeeType: response.data.showToEmployeeType,
                                isNotify: response.data.isNotify,
                                showToPatient: response.data.showToPatient,
                                userId: response.data.userid,
                                patientCode: response.data.patientcode,
                                isAlert: response.data.isAlert,
                                headerId: response.data.headerId,
                                isslineno: response.data.isslineno,
                                recId: response.data.recId,
                                showInMars: response.data.showInMars,
                                workflowRecId: response.data.workflowRecId,
                                isActive: response.data.isActive ?? false,
                                notShowMeMore: response.data.notShowMeMore ?? false,
                                employeeName: response.data.userName
                            };

                            this.Activity = activity;
                            this.employeeNameModel = this.Activity.employeeName;

                            setTimeout(() => {
                                this.loaded = true;
                            }, 100, this);
                        } else {
                            this.loaded = true;
                        }
                        this.$emit('load', false);
                    })
                    .catch(error => {
                        this.showResponseErrorMessage(error, this.$t('Activities.Activity'));
                        this.$emit('load', false);
                    });
            }
        },

        async GetPatientByCode() {
            var infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0){
                this.PatientTitle.programName = '';
                this.PatientTitle.admitDate = '';
                this.PatientTitle.dischargeDate = '';

                const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

                if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age;
                if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
            }
        },

        PopulatePatientTitle(){
            const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
            if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
            if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	

            if(this.$route.name.includes('clinical-')){ 
                if (programName) this.PatientTitle.programName = programName; 
                if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
                if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
                if (patientName) this.PatientTitle.fullName = patientName;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age === 0 ? "0" : age;
                if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
            } else if(this.$route.name.includes('patient-')) {         
                this.PatientTitle.programName = '';
                this.PatientTitle.dateFrom = '';
                this.PatientTitle.dateTo = '';
            }
        },

        async PopulateTitle() {
            if(!this.$route.name.includes('clinical-')){  
                await this.GetPatientByCode();
            } else {      
                this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
                const { dateTo, age } = this.RegistrationInfo; // Destructure for easier access

                this.IsClinical = dateTo ? false : true;
                if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
            }
            
            this.PopulatePatientTitle();
        },

        checkUserAction() {
            if (elemSaveBtn && elemSaveBtn.disabled && !this.isReadOnly) {
                elemSaveBtn.removeAttribute('disabled');
            }
        },

        beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
    },

    async created(){
        EventBus.$on("onChanged", function (e) {
            if (this.loaded) {
                this.pendingChanges = e.Changed
                this.checkUserAction();
            }
        }.bind(this));

        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    async mounted() {
        this.isReadOnly = this.$store.state.isPatientActivitiesEHRReadOnly;
        this.OptionsRightMenu.ItemsList = this.$route.name.includes('clinical-') ? EnumService.ClinicalItems : 
            this.$route.name.includes('schedulerdb-') ? EnumService.AppointmentItems : EnumService.PatientItems;
		this.OptionsRightMenu.Mode = '';
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.$emit('getVisibleMenu', this.OptionsRightMenu);

        this.$emit('load', true);
        await this.PopulateTitle();
        if (this.editMode) {
            await this.getActivity();
        } else {
            this.employeeNameModel = LocalStorage.getName();
            this.loaded = true;
            this.$emit('load', false);
        }

        setTimeout( function() {
            elemSaveBtn = document.getElementById('btn_save_activity');
            if (elemSaveBtn) {
                elemSaveBtn.setAttribute('disabled','');
            }           
        }.bind(this), 100 );
    },

    destroyed() {
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
}
</script>