import ApiService from '@/services/api.service'

const SpecialPrecautionService = {

   /* getSpecialPrecautionByCode(code){
        return ApiService.get(`/PatientSpecialPrecautions?PatientCode=${code}`);  
    },*/

    getSpecialPrecautionByCode(parameter) {              
          const requestData = {
            method: 'get',
            url: "/PatientSpecialPrecautions",
            headers: {
            },
            params: { 
              Code:parameter.PatientCode,           
              PageSize: parameter.PageSize,          
              PageNumber : parameter.PageNumber,
            }
          }     
          return ApiService.customRequest(requestData);         
        },

    Add(data){
        return ApiService.post(`/PatientSpecialPrecautions`, data);
    },
    Update(Code,data){
          return ApiService.put(`/PatientSpecialPrecautions/${Code}`,data)
    }

}

export default SpecialPrecautionService;