<template>
   <sidebar-menu 
   :relative=false 
   :theme="menuTheme" 
   :hideToggle=true 
   :collapsed="collapsedProp" 
   width="250px" 
   :menu="menu"  
   @toggle-collapse="onToggleCollapse"
   :showOneChild=true
   @item-click="onItemClick"
   />       
</template>

<script>
import MenuService from '@/services/menu.service'
import { EventBus } from '../../../event-bus.js';
import DBFunctions from '@/common/DBFunctions.js'
import $ from "jquery";

export default {
  props: {
    Collapsed: Boolean,      
  },     
  name:'MdMenu',
  mixins: [EventBus,DBFunctions],
  computed: {
    collapsedProp: {
      get() { return this.Collapsed}
    }
  },
  data() {
    return {  
      OptionsRightMenu:{
        btnvisible:true,
      },         
      menuTheme:'white-theme',
      collap: this.Collapsed,
      menu: [],
      filter:'',
      CopyMenu:[],
      message: '',
      currentRouteName: this.$route.name,
      arr: MenuService.getTestgMenu()
    }
  },
  methods:{
    onToggleCollapse (collapsed) { 
      this.Collapsed = collapsed
    },

    FilterMenu(e){
      let result = [];
      if(e !== ''){
        this.doFilter(this.CopyMenu, e, result);
        result = result.filter(item => item.href)
        this.menu = result;
      } else {
        this.menu = this.CopyMenu;
        this.CleanIcon(this.CopyMenu, e, result);
      }          
    },       

    RemoveChilds(array){
      for(var i = 0; i<array.length; i++){
        if(array[i].child.length === 0){
          delete array[i].child;
        }
      }
    },

    doFilter(array, filter, result, force=false) {
      if(!array) return;
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        let matches = item.title.toUpperCase().includes(filter.toUpperCase());
        let currentForce = force; // local variable for the current force
        
        if (item.type == "category") {
          currentForce = matches;
        }
        if(item.icon === null){
          item.icon = 'ikon med-zoom';
        }
        if (currentForce || matches) {
          // Create a copy of the object to avoid mutations
          const clone = { ...item };
          if (!currentForce) {
            delete clone.child;
          }
          result.push(clone);
        }
        if (!currentForce && item.child) {
          this.doFilter(item.child, filter, result, currentForce);
        }
      }
    },

    CleanIcon(array, filter, result, force=false) {
      if(!array) return;
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        let matches = item.title.toUpperCase().includes(filter.toUpperCase());
        let currentForce = force; // local variable for the current force

        if (item.type == "category") {
          currentForce = matches;
        }            
        if(item.icon === 'ikon med-zoom'){
          item.icon = null;
        }
        if (currentForce || matches) {
          const clone = {...item};
          if (!currentForce) {
            delete clone.child;
          }
          result.push(clone);
        }
        if (!currentForce && item.child) {
          this.CleanIcon(item.child, filter, result, currentForce);
        }
      }
    },
    AddLangMenuElemParentIcon(json, parentIcon) {
      json.forEach((item) => {
          /** necesary for favorites lang */
          item.parentIcon = parentIcon || item.icon;
          if (item.child && item.child.length > 0) {
              this.AddLangMenuElemParentIcon(item.child, item.parentIcon);
          }
      });
    },
    getMenu() {            
      MenuService.getMenu()
        .then((response) => { 
            if(response.data && response.data.length > 0){
              // Create a copy of response.data
              /*this.menu = this.arr; /* enable for testing menu */                   
              this.menu = JSON.parse(JSON.stringify(response.data)); /* disable for testing menu */
              this.AddLangMenuElemParentIcon(this.menu)
              
              this.$store.commit('setLeftMenu',this.menu);
                /*response.data.forEach(element => {
                this.$store.commit('setLeftMenu',element);
                });*/  
              this.CopyMenu = this.menu;
              //this.PupulateSelects();
            } 
            document.getElementById('leftLoader').style.display = 'none';
        })
        .catch((error) => {
            document.getElementById('leftLoader').style.display = 'none';
              if (error.response) {
                this.showMessage("Error loading menu",error,"error");
            }
        });
    },
    showSuccessToast(titleKey) {
      this.$bvToast.toast(this.message, {
        title: this.$t(titleKey),
        autoHideDelay: 4000,
        variant: "success"
      });
    },

    isOnRoute(item) {
      if(this.currentRouteName === this.$router.currentRoute.name){
          this.showSuccessToast(item.title)
      } else {
          this.currentRouteName = this.$router.currentRoute.name
      }
    },
    onItemClick( event, item ) {
      this.message = this.$t('Layout.displayedScreen');
      const isMovile = this.$store.state.isMovile;
      if ( isMovile ) {
        this.$store.commit('setlayoutStatus', 'L0R0');
        this.$emit('getActionFromItems', item); 
      }
      /* Send Data from clicked menu item to the main page*/
      this.$nextTick(() => {
        EventBus.$emit('getClickMenuItemData', item);
      });

      if(item?.child?.length === 0 || item.child === undefined || item.child === null){
        this.isOnRoute(item);
      }
    }
  },
  created: function () {
      EventBus.$on("onFilterMenu", function (e) {
          $('.vsm--item').css('color','red');
        this.FilterMenu(e);
      }.bind(this));

      /** Get current RouteName */
      EventBus.$on("onUpdatedMenu", function (e) {
          this.currentRouteName = e
      }.bind(this));
  },
  beforeDestroy () {     
    EventBus.$off('onUpdatedMenu');
  },
  mounted() {
    this.getMenu();
  }
}
</script>

<style>
  @import '../../../assets/css/all.css';
 

</style>
