import GlobalService from '@/services/global.service'
import EHRFeatures from '@/services/ehrfeatures.service'
import LocalStorage from '@/services/storage.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import PatientService from '@/services/patient.service'
import AdmissionService from '@/services/admission.service'
import ClinicalService from '@/services/clinical.service'
import Utils from '@/common/utils.js'
import RaceService from '@/services/race.service'
import SchedulerService from '@/services/schedulers.service'
import AppointmentService from '@/services/appointment.service'
import StorageService from "@/services/storage.service";
import UsersFavorites from '@/services/userfavorites.service'
import { EventBus } from '@/event-bus.js';
import SpecialPrecautionService from '@/services/specialprecaution.service'
import MarsAlertService from '@/services/marsalert.service'
import ActivitiesService from '@/services/activities.service'

//getPatientSpecialPrecaution

export default {
    mixins: [ShowMessage,Utils],
    data() {
        return {
            parameter:{
                NoteId:'',
                UserId:''         
              },
            isUseAsam: false,
            InfoList:[],
            Building:[],
            Discharge:[],
            PatientContacts:[],
            Notes:[],
            getAppointmentData:{},
            StatusDischarge:[],
            isActiveAdmission:false,
            EHRReadOnlyList: false,
            DataHeaderRightMenu:{},
            PatientInfo:{},
            RegistrationInfo:{},
            PatientAllergies:[],
            PatientSpecialPrecaution:[],
            Programs:[],
            TransferReason:[],
            PatientDiagnosis:[],
            HistoricalAppointment:[],
            currentAppointment:{},
            DateTimeNow:'',
            HeaderId:'',
            NoteOptions:{},
            PatientMarsAlert:[],
            PatientHasActiveAlerts:false
        }},
    methods: {

      getPermissionLeftMenu(array, router, result, force=false) {           
        if(!array) return;
        for (let i = 0; i < array.length; i++) {  
          let matches =  array[i].href && router.includes(array[i].href); 
          if (force || matches) {
           let clone = {...array[i]};
            if (!force) {
              delete clone.child;
            }
            result.push(clone);          
          }
          if (!force) {
            this.getPermissionLeftMenu(array[i].child, router, result, force);
          }
        }
     },

      async IsActiveAdmission(recordId) {
        await GlobalService.IsActiveAdmission(recordId)
                  .then((response) => {
                       if(response.status == '200' && response.data){
                          this.isActiveAdmission = response.data;
                       }
                  })
                  .catch((error) => {
                      if (error.response) {
                          this.showMessage(this.$t('DBFunctions.aAdmission'),error,"error");
                      }
                  });
                  return this.isActiveAdmission
      },

      async getNoteOptions() {
        await GlobalService.getNoteOptions()
                  .then((response) => {
                       if(response.status == '200' && response.data && response.data.length > 0){
                          this.NoteOptions = response.data[0];
                       }
                  })
                  .catch((error) => {
                      if (error.response) {
                          this.showMessage(this.$t('DBFunctions.nOptions'),error,"error");
                      }
                  });
                  return this.NoteOptions
      },
         async getUseASAM() {
            await GlobalService.getNoteOptions()
                      .then((response) => {
                           if(response.status == '200' && response.data && response.data.length > 0){
                              this.isUseAsam = response.data[0].useAsam;
                           }
                      })
                      .catch((error) => {
                          if (error.response) {
                              this.showMessage(this.$t('DBFunctions.aMenuMsg'),error,"error");
                          }
                      });
                      return this.isUseAsam
          },

          
          async getInfoTitle(PatientCode) {
            await PatientService.getInfoList(PatientCode)
                      .then((response) => {
                           if(response.status == '200' && response.data && response.data.length > 0){
                             const data = response.data;
                             this.InfoList = data.map(patient => ({
                              ...patient,
                              ageFormatted: this.formatAge(patient.ageFormatted.split(' ')[0], patient.ageFormatted.split(' ')[1])
                            }));
                           }
                      })
                      .catch((error) => {
                          if (error.response) {
                              this.showMessage(this.$t('DBFunctions.pListinginfoMsg'),error,"error");
                          }
                      });
                      return this.InfoList;
          },

          async getIsReadOnly(NoteId) {
            this.parameter.UserId = LocalStorage.getUserId();
            this.parameter.NoteId = NoteId;
            await EHRFeatures.getReadOnlyByUsers(this.parameter)
                      .then((response) => {
                        if(response.status == '200' && response.data && response.data.length > 0){
                            this.EHRReadOnlyList = response.data;
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('DBFunctions.readOnByUserMsg'),error,"error");
                        }
                      });
            return this.EHRReadOnlyList
          },

          async getData(PatientCode) {
            await PatientService.getDataHeaderRightMenu(PatientCode)
                      .then((response) => {
                        if(response.status == '200' && response.data && response.data.length > 0){
                            this.DataHeaderRightMenu = response.data[0];
                            if(this.DataHeaderRightMenu.dob){
                                this.DataHeaderRightMenu.dob = Utils.formatterDateToString(this.DataHeaderRightMenu.dob);
                              }		
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('DBFunctions.dHeaderMsg'),error,"error");
                        }
                      });
                      return this.DataHeaderRightMenu
          },
          async getPatientAllergy(PatientCode) {
            await PatientService.getPatientAllergy(PatientCode)
                      .then((response) => {
                        if(response.status == '200' && response.data && response.data.length > 0){
                            this.PatientAllergies = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
                        }
                      });
                      return this.PatientAllergies
          },

          async getPatientSpecialPrecaution(PatientCode) {
            let params = {
              PatientCode : PatientCode,
              PageSize:null,
              PageNumber:null
            }       
            await SpecialPrecautionService.getSpecialPrecautionByCode(params)
                      .then((response) => {
                        if(response.status == '200' && response.data && response.data.length > 0){
                            this.PatientSpecialPrecaution = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('SpecialPrecautions.PatientSpecialPrecautions'),error,"error");
                        }
                      });
                      return this.PatientSpecialPrecaution
          },

          async getPatientMarsAlert(PatientCode) {
            let params = {
              PatientCode : PatientCode,
              PageSize:null,
              PageNumber:null
            }       
            await MarsAlertService.getMarsAlertByCode(params)
                      .then((response) => {
                        if(response.status == '200' && response.data && response.data.length > 0){
                            this.PatientMarsAlert = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),error,"error");
                        }
                      });
                      return this.PatientMarsAlert
          },

          async getTransferReason() {
            await AdmissionService.getAssessment()
                      .then((response) => {
                        if(response.status == '200' && response.data){
                            this.TransferReason = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('transfer.ReasonForTransfer'),error,"error");
                        }
                      });
                      return this.TransferReason
          },

          async getPatientContacts(PatientCode) {
            await GlobalService.getPatientContacts(PatientCode)
                      .then((response) => {
                        if(response.status == '200' && response.data){
                            this.PatientContacts = response.data;   
                            for(var j = 0; j < this.PatientContacts.length; j++){
                              var firstname = this.PatientContacts[j].firstName ? this.PatientContacts[j].firstName : "";
                              var lastname = this.PatientContacts[j].lastName ? this.PatientContacts[j].lastName : "";
                              this.PatientContacts[j].name = firstname + ' '+lastname;  
                              this.PatientContacts[j].code = this.PatientContacts[j].recordId;                        
                           }                       	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$tc('Contact.PatientContact'),error,"error");
                        }
                      });
                      return this.PatientContacts;
          },
         
          async getStatusDischarge() {
            await AdmissionService.getStatusDischarge()
                      .then((response) => {
                        if(response.status == '200' && response.data){
                            this.StatusDischarge = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('discharge.GetDischarge'),error,"error");
                        }
                      });
                      return this.StatusDischarge
          },

          async getAssessmentDischarge() {
            await AdmissionService.getAssessmentDischarge()
                      .then((response) => {
                        if(response.status == '200' && response.data){
                            this.Discharge = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('transfer.ReasonForTransfer'),error,"error");
                        }
                      });
                      return this.Discharge
          },

          async getBuildings() {
            await AdmissionService.getBuildings()
                      .then((response) => {
                        if(response.status == '200' && response.data){
                            this.Building = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('Shared.Building'),error,"error");
                        }
                      });
                      return this.Building
          },          

          async getPatientDiagnosis(PatientCode) {
            await PatientService.getPatientDiagnosis(PatientCode)
                      .then((response) => {
                        if(response.status == '200' && response.data && response.data.length > 0){
                            this.PatientDiagnosis = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('MdPatientDiagnosis.Diagnosis'),error,"error");
                        }
                      });
                      return this.PatientDiagnosis
          },

          async geCurrentAppointment(actId) {
            await SchedulerService.get(actId)
                      .then((response) => {
                        if(response.status == '200'){
                            this.currentAppointment = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('MdCurrentAppointment.CurrentAppointment'),error,"error");
                        }
                      });
                      return this.currentAppointment
          },

          async getNotesByAppt(ApptType) {
            var param = {
              Code:ApptType
            };
            await AppointmentService.getNotesByApptType(param)
                      .then((response) => {
                        if(response.status == '200'){
                            this.Notes = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('MHisAppointment.apptType'),error,"error");
                        }
                      });
                      return this.Notes;
          },

          async getAppointmentByActId(actId) {         
            await SchedulerService.get(actId)
                      .then((response) => {
                        if(response.status == '200'){
                            this.getAppointmentData = response.data;                          	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('schedule-editor.Scheduler'),error,"error");
                        }
                      });
                      return this.getAppointmentData;
          },

          async geHistoricalAppointment(patientCode) {
            var parameter = {
              PatientCode:patientCode,
              OrderBy:"StartDateDesc"
            };
            await SchedulerService.getAppointmentsByPatient(parameter)
                      .then((response) => {
                        if(response.status == '200'){
                            this.HistoricalAppointment = response.data;   
                            this.HistoricalAppointment.forEach(function(element) {
                              if (element.startTime){
                                //element.transDate = Utils.formatterDateToString(element.startTime);
                                element.startTime = Utils.formatterDateTimeToString(element.startTime);
                              }	                               
                              if (element.endTime)	
                                element.endTime = Utils.formatterDateTimeToString(element.endTime);
                             
                            });	                       	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$t('MdHistoricalAppointment.Appointment'),error,"error");
                        }
                      });
                      return this.HistoricalAppointment
          },

          async getRegistrationInfo(RecordId) {
            await ClinicalService.getSummarieRegistrationInfoTitle(RecordId)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.RegistrationInfo = response.data[0];
                  const { admitDate, dischargeDate } = this.RegistrationInfo; // Destructure for easier access

                  if (admitDate) this.RegistrationInfo.admitDate = Utils.formatterDateToString(admitDate);
                  if (dischargeDate) this.RegistrationInfo.dischargeDate = Utils.formatterDateToString(dischargeDate);
                }
              })
              .catch((error) => {
                if(error.response) {
                    this.showMessage(this.$t('MdPatientRegistrationInfo.RegistrationInfo'),error,"error");
                }
              });
              return this.RegistrationInfo
          },


          async getRegistrationInfoTitle(RecordId) {
            await ClinicalService.getSummarieRegistrationInfoTitle(RecordId)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.RegistrationInfo = response.data[0]

                  const { admitDate, dischargeDate, ageFormatted } = this.RegistrationInfo; // Destructure for easier access

                  if (admitDate) this.RegistrationInfo.admitDate = Utils.formatterDateToString(admitDate);
                  if (dischargeDate) this.RegistrationInfo.dischargeDate = Utils.formatterDateToString(dischargeDate);	
                  if (ageFormatted){
                    const parts = ageFormatted.split(' ');
                    if(parts.length === 2){
                      this.RegistrationInfo.ageFormatted = this.formatAge(parts[0], parts[1]);
                    }
                  }
                }
              })
              .catch((error) => {
                if(error.response) {
                    this.showMessage(this.$t('MdPatientRegistrationInfo.RegistrationInfo'),error,"error");
                }
              });
              return this.RegistrationInfo
          },

          async getProgramsByPatient(PatientCode) {
            await GlobalService.getPrograms(PatientCode)
                      .then((response) => {
                        if(response.status == '200' && response.data && response.data.length > 0){
                            this.Programs = response.data;
                            this.Programs.forEach(function(element) {
                              if (element.admitDate)	
                                element.admitDate = Utils.formatterDateToString(element.admitDate);
                              if (element.dischargeDate)	
                                element.dischargeDate = Utils.formatterDateToString(element.dischargeDate);
                            });	
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                            this.showMessage(this.$tc('Shared.Program'),error,"error");
                        }
                      });
                      return this.Programs
          }, 
          
          PupulateSelects(){
            // Get Ethnicity List 
            GlobalService.getEthnicity()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initEthnicityList')
                  response.data.forEach(element => {
                    this.$store.commit('setEthnicityList',element);
                  });   
               }
           })
           .catch((error) => {
                if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Gender List 
            GlobalService.getGender()
           .then((response) => {
                 if(response.status == '200'){                     
                  this.$store.commit('initGenderList')
                  response.data.forEach(element => {
                    this.$store.commit('setGenderList',element);
                  });
                 }
          })          
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
           
           // Get Race List 
           RaceService.getAll()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initRaceList')
                response.data.forEach(element => {
                  this.$store.commit('setRaceList',element);
                });
               }         
           })
           .catch((error) => {
              if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get EyeColor List 
            GlobalService.getEyeColor()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initEyeColorList')
                response.data.forEach(element => {
                  this.$store.commit('setEyeColorList',element);
                });
               }
               })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
           
           // Get HairColor List 
            GlobalService.getHairColor()
           .then((response) => {
               if(response.status == '200'){                  
                this.$store.commit('initHairColorList')
                response.data.forEach(element => {
                  this.$store.commit('setHairColorList',element);
                });
               }           
           })
           .catch((error) => {
              if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get CommPreference List 
         GlobalService.getCommPreference()
           .then((response) => {
               if(response.status == '200'){                   
                this.$store.commit('initCommPreferenceList')
                response.data.forEach(element => {
                  this.$store.commit('setCommPreferenceList',element);
                });
               }            
           })
           .catch((error) => {
              if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Family Income List 
           GlobalService.getFamilyIncome()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initFamilyIncomeList')
                response.data.forEach(element => {
                  this.$store.commit('setFamilyIncomeList',element);
                });
               }             
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Personal Income List 
            GlobalService.getPersonalIncome()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initPersonalIncomeList')
                response.data.forEach(element => {
                  this.$store.commit('setPersonalIncomeList',element);
                });
               }             
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Major Language List 
            GlobalService.getMajorLanguage()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initMajorLanguageList')
                response.data.forEach(element => {
                  this.$store.commit('setMajorLanguageList',element);
                });
               }               
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Major Language Ability List 
           GlobalService.getMajorLanguageAbility()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initMajorLanguageAbilityList')
                response.data.forEach(element => {
                  this.$store.commit('setMajorLanguageAbilityList',element);
                });
               }              
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Major Religion List 
           GlobalService.getMajorReligion()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initMajorReligionList')
                response.data.forEach(element => {
                  this.$store.commit('setMajorReligionList',element);
                });
               }                
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Disability List 
           GlobalService.getDisability()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initDisabilityList')
                response.data.forEach(element => {
                  this.$store.commit('setDisabilityList',element);
                });
               }                 
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
           // Get Legal Status List 
           GlobalService.getLegalStatus()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initLegalStatusList')
                response.data.forEach(element => {
                  this.$store.commit('setLegalStatusList',element);
                });
               }                
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });
    
            // Get Education Status List 
            GlobalService.getEducationStatus()
           .then((response) => {
               if(response.status == '200'){
                this.$store.commit('initEducationStatusList')
                response.data.forEach(element => {
                  this.$store.commit('setEducationStatusList',element);
                });
               }          
           })
           .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Shared.Patient'),error,"error");
                }
           });




           AdmissionService.getAdmissionSources()
                .then((response) => {
                    if(response.status == '200'){                      
                       this.$store.commit('initSourcesList')
                       response.data.forEach(element => {
                         this.$store.commit('setSourceList',element);
                       });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionSource'),error,"error");
                    }
                }); 

            AdmissionService.getAdmissionTypes()
                .then((response) => {
                    if(response.status == '200'){                     
                      this.$store.commit('initTypeList')
                      response.data.forEach(element => {
                        this.$store.commit('setTypeList',element);
                      });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionType'),error,"error");
                    }
                }); 

             AdmissionService.getAdmissionClasses()
                .then((response) => {
                    if(response.status == '200'){
                      this.$store.commit('initClassList')
                      response.data.forEach(element => {
                        this.$store.commit('setClassList',element);
                      });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Class'),error,"error");
                    }
                });

             AdmissionService.getVocationalEffort()
                .then((response) => {
                    if(response.status == '200'){                      
                        this.$store.commit('initVocationalEffortList')
                        response.data.forEach(element => {
                          this.$store.commit('setVocationalEffortList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.VocationalEffort'),error,"error");
                    }
                });

             AdmissionService.getVocationalCategory()
                .then((response) => {
                    if(response.status == '200'){                    
                      this.$store.commit('initVocationalCategoryList')
                      response.data.forEach(element => {
                        this.$store.commit('setVocationalCategoryList',element);
                      });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.VocationalCategory'),error,"error");
                    }
                });

             AdmissionService.getAdmissionFrom()
                .then((response) => {
                    if(response.status == '200'){                      
                        this.$store.commit('initFromList')
                        response.data.forEach(element => {
                          this.$store.commit('setFromList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionFrom'),error,"error");
                    }
                });

                AdmissionService.getLivingWith()
                .then((response) => {
                    if(response.status == '200'){                     
                        this.$store.commit('initLivingWithList')
                        response.data.forEach(element => {
                          this.$store.commit('setLivingWithList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.LivingWith'),error,"error");
                    }
                });

               //if(!this.Mode){
             AdmissionService.getStatus()
                .then((response) => {
                    if(response.status == '200'){                    
                     this.$store.commit('initStatusList')
                     response.data.forEach(element => {
                       this.$store.commit('setStatusList',element);
                     });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.AdmissionStatus'),error,"error");
                    }
                });
              // }

             AdmissionService.getAssessment()
                .then((response) => {
                    if(response.status == '200'){                      
                       this.$store.commit('initAssessmentList')
                       response.data.forEach(element => {
                         this.$store.commit('setAssessmentList',element);
                       });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Assessment'),error,"error");
                    }
                });

             AdmissionService.getTrack()
                .then((response) => {
                    if(response.status == '200'){                      
                       this.$store.commit('initTrackList')
                       response.data.forEach(element => {
                         this.$store.commit('setTrackList',element);
                       });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Track'),error,"error");
                    }
                });

              AdmissionService.getReferrals()
                .then((response) => {
                    if(response.status == '200'){                       
                        this.$store.commit('initReferralsList')
                        response.data.forEach(element => {
                          this.$store.commit('setReferralsList',element);
                        });
                    }                             
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('Admission.Referal'),error,"error");
                    }
                });
          },
          openFullscreen:function()  {
            var elem = document.documentElement;
            if (elem.requestFullscreen) {
              elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { 
              elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { 
              elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { 
              elem.msRequestFullscreen();
            }    
            
          },
        
          closeFullscreen:function() {
            
            if (document.exitFullscreen) {
              document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { 
              document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { 
              document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { 
              document.msExitFullscreen();
            }
          
          },
          /** Only To Check if url already is a favorite
             * When click on button Back in Add
            */
          checkExistFavorite( href ){
            if( href ){
              var send = {
                  isVisible: true,
                  isFav:false
              };
              UsersFavorites.GetUserFavorite(StorageService.getUserId(), href)
                  .then((response) => {
                      if(response.data && response.data.userid !== null){
                          send = {
                              isVisible: true,
                              isFav:true
                          }  
                      }
                      EventBus.$emit('shwoHideFavorite', send);
                      this.$store.commit('setBtnFavoriteState', send.isVisible);                            
                  })
                  .catch((error) => {
                      if (error.response) {
                          this.showMessage(this.$t('Layout.Favorites.title'),error,"error");
                      }
                  });
            }
          },
          /** Only To Send data for Favorites
             * When click on button Add
            */
          emitDataforFavorites(){
            EventBus.$emit('shwoHideFavorite', false);
            this.$store.commit('setBtnFavoriteState', false);  
          },
          /**End */
          
          formatAge(age, unit){
            if (unit === 'years') {
              return `${age} ${this.$tc('modalEditIntervention.YEAR',2)}`;
            } else if (unit === 'months') {
              return `${age} ${this.$tc('modalEditIntervention.MONTH',2)}`;
            } else if (unit === 'days') {
              return `${age} ${this.$tc('modalEditIntervention.DAY',2)}}`;
            } else {
              return age;
            }  
          },

          searchInMenuItems(arr, value = '', field = 'text') {
            if (arr && arr.length) {
              if (value == '') {
                return arr;
              }
              value = value.toLowerCase()
              return arr.filter(e => ((field in e && e[field]) ? e[field].toLowerCase().includes(value) : null));
            }
              return null;
          },

          async IsPatientWithActiveAlerts(patientCode) {
            this.PatientHasActiveAlerts = false;
            await ActivitiesService
                    .existAlerts(patientCode) 
                    .then(response => {
                    if (response.status == "200") {
                        this.PatientHasActiveAlerts = response.data
                    }
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                });
            return this.PatientHasActiveAlerts;
          }
    }
}