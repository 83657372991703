import ApiService from '@/services/api.service'

const IndicationService = {
  
    getIndications() {
      return ApiService.get(`/MdRxIndications`);
    },
 
}
  
  export default IndicationService;