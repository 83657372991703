<template>
   <div id="ContainerDischarge">
    <div class="row">
      <div class="col-12">
        <MdTitleRegInfo
            v-bind:RegistrationInfo="RegistrationInfo" 
            v-bind:titlePrefix="$t('discharge.Discharge')" 
            v-bind:titleRef="'titleElementDischarge'"
            v-bind:patientCode="this.$route.params.id">
        </MdTitleRegInfo>
    
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
              <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
          </div>
          </div>
        </div>
        <!-- !! Important ,Added for testing purpose -->
          <div style='display:none;'><MdEdit 
                v-bind:TypeProp="'text'" 
                v-model="this.$route.params.recordid "
                v-bind:Id="'input_discharge_id'">                                     
              </MdEdit>
          </div>
          <!-- End -->
    <b-row>   
     <b-col lg="4" md="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.Program')"
            v-bind:Name="'Program'"
            v-bind:TypeProp="'text'"       
            v-model="currentProgram"
            v-bind:isReadOnly="true"
            v-bind:Id="'input_dprogram_id'"
          /> 
        </b-col>    
       <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:Name="'DATEFROM'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/####'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="DateFrom"
            v-bind:isReadOnly="true"
          /> 
        </b-col>        
         <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.LastTransactionDate')"
            v-bind:Name="'LastTransactionDate'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/####'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="LastTransactionDate"
            v-bind:isReadOnly="true"
             v-bind:Id="'last_tr'"
          /> 
        </b-col>
    </b-row>
    <MdTitle v-bind:Text="$t('discharge.DischargeINFO')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row> 
    <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.DISCHARGEDATEFROM')"
            v-bind:Name="'DISCHARGEDATEANDTIME'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/#### ##:## AA'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="DateTo"
            v-bind:isValid="isDischargeDateToValid"
            v-bind:isRequired="true"
            v-bind:isReadOnly="isReadOnly || !isEditable"
            v-bind:Id="'d_date_discharge'"
          /> 
    </b-col>     
    <b-col lg="4" md="4" sm="12">
          <MdSelect
            v-bind:Label="$t('discharge.ASSESSMENT')"
            v-bind:Options="Assessement"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="discharge.assessmentCode"                       
            v-bind:Multiple="false"
            :Input="ChangeDropDownAssessement"
            :IsDisabled="isReadOnly || !isEditable"
            v-bind:Name="'assessement_select'"
            id="assessement_select"
          />
     </b-col> 
      <b-col lg="4" md="4" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Status')"
            v-bind:Options="Status"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="discharge.statusCode"                       
            v-bind:Multiple="false"
            :Input="ChangeDropDownStatus"
            :IsDisabled="isReadOnly || !isEditable" 
            v-bind:isRequired="true"
            v-bind:isValid="this.isStatusValid"
            v-bind:FeedBackText="$t('discharge.StatusRequired')"
            v-bind:Name="'status_select'"
            id="status_select"
          />
     </b-col> 
    </b-row>
    <b-row>
       <b-col lg="4" md="4" sm="12">
          <MdSelect
            v-bind:Label="$t('discharge.PERSONALEFFECTS')"
            v-bind:Options="PersonalEffects"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Model="discharge.patientContactId"                       
            v-bind:Multiple="false"
            :Input="ChangeDropDownPersonalEffects"
            :IsDisabled="isReadOnly || !isEditable"
          />
     </b-col> 
     <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.PERSONALEFFECTSSENT')"
            v-bind:Name="'PersonalEffectsOn'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/#### ##:## AA'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="PersonalEffectDate"
            v-bind:isReadOnly="isReadOnly || !isEditable"
          /> 
    </b-col>    
    </b-row>
    <MdTitle v-bind:Text="$t('discharge.DISCHARGENOTES')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row>
       <b-col lg="12" md="12" sm="12">
               <MdMemo
                 v-bind:Name="'textarea_discharge'"
                 v-bind:Rows="'5'"
                 v-bind:MaxRows="'6'"
                 v-bind:MaxLength="'500'"
                 v-model="discharge.dischargeNotes"
                 v-bind:isReadOnly="isReadOnly || !isEditable"
               />                                     
            </b-col>
    </b-row>

    <MdTitle v-bind:Text="$t('discharge.DISCHARGEDOCUMENTS')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row>
        <b-col lg="12" md="12" sm="12">
            <LoadDocument 
                v-bind:Records="this.files" 
                v-bind:ModeEdit="this.$attrs.editMode"
                v-bind:DocType="this.DocumentTypesVModel.description" 
                v-bind:DocCode="this.DocumentTypesVModel.code"
                v-bind:UserId="UserId"
                v-bind:DocumentTypeList="OptionDocumentTypes"
                @UpdateFiles="OnUpdateDocumentFile($event)" 
                @FileChanged="OnFileChanged($event)" 
                v-bind:IsReadOnly="isReadOnly || !this.isEditable"
            />
        </b-col>
    </b-row>

    <div class="page-actions">
            <MdButton
                 v-if="this.$route.name.includes('patient-discharge-')"
                  v-bind:Label="$t('Shared.Back')"
                  v-bind:Name="'btnpatientlist'"               
                  v-bind:ClassProp="'primary mx-2 btn-login'"
                  v-on:click="GotoDischargeList"/>
              <MdButton
                v-bind:Label="$t('discharge.UNDODISCHARGE')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="Undo"
                v-bind:isReadOnly="isReadOnly || this.discharge.status === 'A' || !isSupervisor"
                v-bind:Name="'btn_patient_undodischarge'"
              />
              <MdButton
                v-bind:Label="this.discharge.status === 'A' ? $t('discharge.Discharge') : $t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="Save"
                v-bind:isReadOnly="isReadOnly || this.DisabledBtnSaveDischarge"
                v-bind:Name="'btn_save_patient_discharge'"
              />
      </div>
   </div>
</template>

<script>
import EnumService from '@/services/enum.service' 
import DBFunctions from '@/common/DBFunctions.js'
import GlobalService from '@/services/global.service' 
import StorageService from '@/services/storage.service'
import LocalStorage from '@/services/storage.service'
import DischargeService from '@/services/discharge.service' 
import DocumentType from '@/services/documenttype.service'
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';

let elemSaveBtn = '';
export default {
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('discharge.Discharge'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(async value => {
            if(!value){
              next();
            } else {
               await this.Save();
               if (!this.pendingChanges) {
                next();
               }
            }
          })		
	} else {
		next()
	}
    },
  name: 'Discharge',
  mixins: [DBFunctions],
   data(){
       return {
        permission:[],
        isSupervisor:StorageService.IsSupervisor(),
        Menu : this.$store.state.leftMenu,
        RegistrationInfo:{},
        Assessement:[],
        Status:[],
        PersonalEffects:[],
        LastTransactionDate:"",
        DateFrom:"",
         Required:true,
        currentProgram:"",
        DateTo:null,
        DisabledBtnSaveDischarge:false,
        isDischargeDateToValid:false,
        isStatusValid:true,
        PersonalEffectDate:null,
        discharge:{  
          "status":"",
          "recordId":this.$route.params.recordid,        
          "dateTo":null,
          "assessmentCode":"",
          "statusCode":"",
          "patientContactId":"",
          "personalEffectSentDate":null,
          "dischargeNotes":""
        },
        OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,         
        },  
        isReadOnly:false,
        pendingChanges: false,
        files:[],
        DocumentTypesVModel:{code:'',description:''},
        UserId:LocalStorage.getUserId(),
        OptionDocumentTypes:[],
        statusBeforeSave:""
       }
   },
   computed: {
    isEditable() {
        return this.discharge.status === 'A' || this.$route.name.includes('patient-discharge-');
    }
   },
   methods:{
    formatterDate(value){
         return Utils.formatterDateTimeToString(value);
    },
    CanView(){
      return this.permission[0].roleCanView;
    },

    validate_required(){
       this.isDischargeDateToValid = this.DateTo ? true : false;
        this.isStatusValid = this.discharge.statusCode && this.discharge.statusCode.length > 0 ? true : false
       // this.isProgramCodeValid = this.transfer.programcode && this.transfer.programcode.length > 0 && this.ProgramCodeWrittedIsValid? true : false;         
        return this.isDischargeDateToValid && this.isStatusValid;
    },

    validateDate(){
      return Utils.IsValidDateAndRange(this.DateTo, Utils.getShortDateFormat()) ? true : false;
    },

     async Save(){
    
       this.$emit('load', true);
       this.statusBeforeSave = this.discharge.status;
       this.discharge.status = "I";
       if(this.validate_required()){
          elemSaveBtn.setAttribute("disabled","disabled");
         if(this.validateDate()){
           await this.UpdateDischarge();
         }else{
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            this.showMessage(this.$t('discharge.Discharge'), this.$t('Msg.InvalidDate'), "errorAutoHide");          
         }
         
       }else{
         this.$emit('load', false);
         elemSaveBtn.removeAttribute("disabled");
         this.showMessage(this.$t('discharge.Discharge'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
      }  
       
    },

    clearFiles() {
        while(this.files.length > 0) {
            this.files.pop();
        }
    },

    Undo(){
        this.statusBeforeSave = this.discharge.status;
        this.discharge.status = "A";
        this.UpdateDischarge();   
    },

    async GetAssessementDischarge(){
      this.Assessement = await this.getAssessmentDischarge();
    },

    async GetStatusDischarge(){
      this.Status = await this.getStatusDischarge();
    },
   
    async GetPersonalEffects(){
      this.PersonalEffects = await this.getPatientContacts(this.$route.params.id);
    },

     getLastTransactionDate() {       
          GlobalService.getAdmissionLastTransactionDate(this.$route.params.recordid)
            .then((response) => {      
               if (response.data) {              
                this.LastTransactionDate = response.data ? this.formatterDate(response.data): ""; 
               }  
         })
         .catch(error => {
           if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.LastTransactionDate'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('discharge.LastTransactionDate'),error,"error");
            }
         });         
      },

        getDateTime() {        
             GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                 this.DateTo = this.formatterDate(response.data);
               }  
         })
         .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.GetDateTime'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('discharge.GetDateTime'),error,"error");
            }
         }); 
         
      },

      
    async UpdateDischarge(){    
      //this.discharge.dateTo =  Utils.formatterToISO(this.DateTo, Utils.getShortDateFormat());
      //this.discharge.personalEffectSentDate =  Utils.formatterToISO(this.PersonalEffectDate, Utils.getDateTimeFormat());
      this.discharge.request = this.files;
        var data = {
            status: this.discharge.status,
            recordId:this.discharge.recordId,        
            dateTo: Utils.formatterToISO(this.DateTo, Utils.getShortDateFormat()),
            assessmentCode:this.discharge.assessmentCode,
            statusCode:this.discharge.statusCode,
            patientContactId:this.discharge.patientContactId,
            personalEffectSentDate:Utils.formatterToISO(this.PersonalEffectDate, Utils.getDateTimeFormat()),
            dischargeNotes:this.discharge.dischargeNotes,
            files: this.files,
            patientEdit: this.$route.name.includes('patient-discharge-') && this.discharge.status == "I"
        }
        
        data.files.forEach(element => {
            element.doccode = 'DISC';
            element.documentLink = this.$route.params.recordid;
        });
        
        
      await DischargeService.UpdateDischarge(this.$route.params.recordid, data)
        .then((response) => {
              if(response.status == '200'){
                this.pendingChanges = false;                
                this.DisabledBtnSaveDischarge = this.discharge.status === "A" ? false : true; 
                this.files.forEach(element => {
                    element.isNew = false;
                });

                this.$emit('load', false);  
                if(this.discharge.status === "A"){                
                setTimeout(() => 
                  this.$bvToast.toast(this.$t('discharge.DischargeUndoSuccessfully'), {
                    title: this.$t('discharge.SavingDischarge'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                , 50); 
                }else{
                    setTimeout(() => 
                  this.$bvToast.toast(this.$t('discharge.DischargeSavedSuccessfully'), {
                    title: this.$t('discharge.SavingDischarge'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                , 50); 
                }

                if (this.$route.name.includes('patient-discharge-')) {
                    this.GotoDischargeList();
                }
              }                             
            })
            .catch((error) => {
                this.discharge.status = this.statusBeforeSave;
              elemSaveBtn.removeAttribute("disabled");
               if (!error.response) {
                   this.showMessage(this.$t('discharge.AddDischarge'),this.$t('Msg.NotAbleToInsert'),"error");
                 } else if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.AddDischarge'),error.response.data.errorMessage,"error");
              }else if(error.response.status === 500){
                this.showMessage(this.$t('discharge.AddDischarge'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
                this.showMessage(this.$t('discharge.AddDischarge'),error.response.data.errorMessage,"error");
              }   
              this.$emit('load', false);
              //this.discharge.dateTo = Utils.formatterDate(this.discharge.dateTo);
              //this.discharge.personalEffectSentDate = Utils.formatterDateTimeToString(this.discharge.personalEffectSentDate);
          });
    },

    getDischarge(){
      DischargeService.getDischarge(this.$route.params.recordid)
        .then((response) => {
              if(response.status == '200'){
                this.discharge = response.data;
                this.PersonalEffectDate = this.discharge.personalEffectSentDate ? Utils.formatterDateTimeToString(this.discharge.personalEffectSentDate) : null;
                this.DateTo = this.discharge.dateTo ? this.formatterDate(this.discharge.dateTo) : this.getDateTime();
                if (response.data.documents) {
                    response.data.documents.forEach(element => {
                        element.IsNew = false;
                        element.Image = Utils.getType(element.extension);      
                        this.files.push(element);

                        this.DocumentTypesVModel.code =  element.doccode;   
                        this.DocumentTypesVModel.description =  element.description; 
                    });
                }
                
                this.$emit('load', false); 
              }                             
            })
            .catch((error) => {  
               if (!error.response) {
                   this.showMessage(this.$t('discharge.GetDischarge'),this.$t('Msg.NotAbleToInsert'),"error");
                 }              
              else if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.GetDischarge'),error.response.data.errorMessage,"error");
              } else{
                  this.showMessage(this.$t('discharge.GetDischarge'),error,"error");
              }  
              this.$emit('load', false);
          });
    },

    ChangeDropDownAssessement(e){
      this.discharge.assessmentCode = e !== null ? e.code : ""; 
    },

    ChangeDropDownStatus(e){
      this.discharge.statusCode = e !== null ? e.code : ""; 
    },

    ChangeDropDownPersonalEffects(e){
      this.discharge.patientContactId = e !== null ? e.code : ""; 
    },

    checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
    },

    OnUpdateDocumentFile(file){   
        this.pendingChanges = true;    
        this.files = file;
        this.checkUserAction();
    },

    OnFileChanged(){     
        this.changesPending = true;
    },

    getDocumentTypes() {  
        DocumentType.getAll(this.parameter)
                    .then((response) => {
                        if(response.status == '200'){           
                            this.OptionDocumentTypes = response.data; 
                        }
                    })
                    .catch(error => {
                        if (!error.response) {                      
                            this.showMessage(this.$t('discharge.GetDischarge'),this.$t('Msg.retrieveInfo'),"error");
                        } else if(error.response.status === 400 ){
                            this.showMessage(this.$t('discharge.GetDischarge'),error.response.data.errorMessage,"error");
                        } else if(error.response.status === 500){
                            this.showMessage(this.$t('discharge.GetDischarge'),this.$t('Msg.ThereWereSomeError'),"error");
                        } else{
                            this.showMessage(this.$t('discharge.GetDischarge'),error.response.data.errorMessage,"error");
                        } 
                    });
    },

    GotoDischargeList(){
        if (this.$route.name.includes('patient-discharge-')) {
            this.$router.push({ name: "patient-discharges", params: { id: this.$route.params.id } })
        }
    },

    },

  async mounted() { 
    this.DisabledBtnSaveDischarge = false;
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-discharge') ? EnumService.AppointmentItems : EnumService.ClinicalItems;
    this.OptionsRightMenu.visible = true;      
    this.OptionsRightMenu.active_el = "Discharge";
    this.$emit('getVisibleMenu', this.OptionsRightMenu);

    this.isReadOnly = await this.$store.state.isClinicalDischargeEHRReadOnly;
    this.$emit('load', true);   
    this.getLastTransactionDate();
    this.GetAssessementDischarge();
    this.GetStatusDischarge();
    this.GetPersonalEffects();
    this.getDocumentTypes(); 
   
    // this.$emit('getBtnRightMenu', this.OptionsRightMenu);  

    this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
    const { dob, dateFrom, dateTo, age, programName } = this.RegistrationInfo; // Destructure for easier access

    if (dateFrom) this.RegistrationInfo.dateFrom = this.DateFrom = Utils.formatterDateToString(dateFrom);
    if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
    if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
    if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
    if (programName) this.currentProgram = programName;
   
    this.getDischarge();
   
    setTimeout( function() {
      elemSaveBtn = document.getElementById('btn_save_patient_discharge');
      elemSaveBtn.setAttribute('disabled','');
    }, 50, this );
   },

    created(){   
      this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);  
      EventBus.$on("onChanged", function (e) { 
         this.pendingChanges = e.Changed
         this.checkUserAction();
      }.bind(this));

     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.OptionsRightMenu.active_el = "";
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload);
     // EventBus.$off('onChanged');
    },
};
</script>


