<template>
    <div id="Container_ePharmacyBase">
      <MdTitleRegInfo
        v-bind:RegistrationInfo="PatientTitle" 
        v-bind:titlePrefix="$t('ePharmacyBase.ePharmacyTitle')" 
        v-bind:titleRef="'titleElementePharmacy'"
        v-bind:patientCode="this.$route.params.id">
      </MdTitleRegInfo>
     
      <iframe 
          allow-same-origin 
          allow-forms 
          id="iframe" 
          name="iframe" 
          width="100%" 
          height="800px"  
          frameborder="0"/>
      
    </div>
</template>
 
 <script>
 import EnumService from '@/services/enum.service';
 import DBFunctions from '@/common/DBFunctions.js'
 import Utils from "@/common/utils";
 import NewCropService from '@/services/newcrop.service' 
 import ShowMessage from '@/components/messages/ShowMessage.js';

export default {
  name: 'ePharmacyBase',
  props: {Origin:String},
  mixins: [DBFunctions, ShowMessage],


  data(){
    return{
      PatientTitle:{      
        programName:'',
        fullName:'',
        admitDate:'',
        dischargeDate:'',
        age:"",
        dob:'',
        activitytypename:'',
        startdatetime:'',
        ageFormatted:''
      },
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
      },
      currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee:{
          firstname:"",
              lastname:""
          }
      },
    }
  },

  methods: {
    async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
        this.PatientTitle.programName = '';
        this.PatientTitle.admitDate = '';
        this.PatientTitle.dischargeDate = '';

        const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

        if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
        if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
        if (age) this.PatientTitle.age = age;
        if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;               
      }
    },

    async CreateNewCrop(){
      this.$emit('load', true);
      await NewCropService.sendRequest(this.$route.params.id).then(response => {
        const form = document.createElement('form');
        let iframe = document.getElementById("iframe");
        form.method = response.data.method;
        form.target = "iframe";
        form.action = response.data.url
        const hiddenField = document.createElement('textarea');
        hiddenField.style.visibility = "hidden";
        hiddenField.name = "RxInput";
        hiddenField.id = "RxInput";
        hiddenField.value = response.data.xml;
        form.appendChild(hiddenField);
        iframe.appendChild(form);
        form.submit();		 
      }).catch(error => {
        if (!error.response) {                      
          this.showMessage(this.$t('ePharmacyBase.ePharmacyTitle'),this.$t('Msg.retrieveInfo'),"error");
        }                      
        else if(error.response.status === 400 ){
          this.showMessage(this.$t('ePharmacyBase.ePharmacyTitle'),error.response.data,"error");
        } else if(error.response.status === 500){
          this.showMessage(this.$t('ePharmacyBase.ePharmacyTitle'),this.$t('Msg.ThereWereSomeError'),"error");
        } else{
          this.showMessage(this.$t('ePharmacyBase.ePharmacyTitle'),error.response.data,"error");
        }
      })
    },
    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }
  },
  async created() {
    this.GetPatientByCode();
    if (this.$route.name.includes('appointments-epharmacy'))
      await this.getCurrentAppointment()
    this.CreateNewCrop();
  },
  async mounted(){

    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-epharmacy') ? EnumService.AppointmentItems : EnumService.ClinicalItems
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
  }
}
 </script>
 
 
 