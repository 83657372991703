<template>
    <div class="notes several">
        <h4 class="note-title text-truncate" v-b-tooltip.hover >
            <span :class="icon"></span>
            <span> {{this.title}} </span> 
        </h4>

        <b-icon
            icon="x-circle-fill"
            v-on:click="Close"
            class="rounded-circle notes-close"
            scale=".5"
            font-scale="1.8"
            > <!-- create the CloseNote action. Open notes should be closed with the HIDDEN class -->
        </b-icon>

        <!-- QUICK ACCESS STICKY BAR -->
        <div class="NotesStickyBar">
            <div class="ml-1 mt-0">
                <b-button-toolbar>
                    <b-button-group class="mr-1">
                        <b-button v-on:click="handlePrint()" 
                            v-b-tooltip.hover :title="$t('Shared.Print')" 
                            size="sm" class="ikon med-print-hollow" variant="outline-primary">
                        </b-button>
                    </b-button-group>
                </b-button-toolbar>
            </div>
        </div>
        <!-- END OF QUICK ACCESS STICKY BAR -->

        <div class="cutescroll pl-1" v-if="note">
            <div style="position:relative;border: solid 1px transparent;">
                <MdTitle v-bind:Text="logNote.description" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <b-row>
                    <b-col lg="6" md="6" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Shared.Date')"
                            v-bind:Name="'date'"
                            v-bind:TypeProp="'text'" 
                            v-model="intakeDate"
                            v-bind:isReadOnly="true">                                     
                        </MdEdit>
                    </b-col>
                    <b-col lg="6" md="6" sm="12">
                        <MdEdit 
                            v-bind:Label="$tc('Shared.Employee')"
                            v-bind:Name="'text'"
                            v-bind:TypeProp="'text'" 
                            v-model="note.employeeName"
                            v-bind:isReadOnly="true">                                     
                        </MdEdit>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col v-for="item in note.fields" v-bind:key="item.code" md="12" lg="12">      
                        <!--                                       
                        <MdSelect v-if="item.valueType == 'P'"
                            :Id="item.code"
                            v-bind:Label="item.description"
                            v-bind:Options="getOptions(item.pickListArray)"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'value'"
                            v-bind:Multiple="false" 
                            :ref="'Ref_'+item.code"
                            v-bind:isRequired="item.isRequired"   
                            v-bind:AllowEmpty="false"
                            v-bind:Model="modelList[item.code]"
                            v-bind:Name="item.code"
                            v-bind:Tag="item.code"
                            v-bind:IsDisabled="true"
                        />
                    -->
                        <MdEdit
                            :Id="item.code"
                            v-bind:Label="item.description"
                            v-bind:Name="item.code"
                            :ref="'Ref_'+item.code"
                            v-bind:Model="modelList[item.code]"
                            v-bind:TypeProp="getControlType(item.valueType)" 
                            :value="item.intakeValueFormatted"                     
                            v-bind:Tag="item.code"
                            v-bind:isReadOnly="true">
                        </MdEdit>
                        <b-tooltip :target="item.code" triggers="hover" v-if="item.comments">
                            {{item.comments}}
                        </b-tooltip>  
                    </b-col>
                </b-row>


                <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'Notes'"/>
                <b-row>
                    <b-col md="12" lg="12">
                        <MdMemo 
                            v-bind:Label="$t('LogNote.LnList')"
                            v-bind:TypeProp="'text'" 
                            v-bind:Name="'notes'"
                            v-bind:Rows="'22'"
                            v-model="note.comments"
                            v-bind:isReadOnly="true">                                     
                        </MdMemo>
                    </b-col>
                </b-row> 
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import LocalStorage from '@/services/storage.service'
import Utils from '@/common/utils'
import MedicalSignNotesService from "@/services/medicalsignnotes.service";
import ShowMessage from '@/components/messages/ShowMessage.js';
import DBFunctions from '@/common/DBFunctions.js'

export default {
    name: 'LogNoteChartReview',
    mixins: [DBFunctions, ShowMessage],
    props: {
        logNote: Object,
        reportEvent: Function
    },

    data: function() {
        return { 
            icon:"ikon med-doc-complete mr-2",
            isReadOnly: true,
            UserId: LocalStorage.getUserId(),
            note: null,
            modelList: {},
        }
    },


    computed: {
        title() {
            return Utils.formatterDateToShortLocaleString(this.logNote.intakeDate) + " - " + this.logNote.description;
        },

        intakeDate() {
            return Utils.formatterDateTimeToString(this.note.intakeDate)
        }
    },

    methods: {
        async Close() {   
            this.$emit('remove');
            EventBus.$emit('CloseDocument');
        },

        async getLogNoteById(){
            const logNotesParams = {
                IntakeDate : this.logNote.intakeDate,
                NotesID: this.logNote.notesId,
                HeaderID: this.logNote.Id
            }

            await MedicalSignNotesService.getAll(logNotesParams)
                .then((response) => {
                    if (response.status == "200" && response.data && response.data.length > 0) {
                        this.note = response.data[0];        
                        this.note.fields.forEach(element => {
                            this.modelList[element.code] = element.intakeValue ? element.intakeValue : ''
                        }); 
                    }
                    EventBus.$emit('ShowWeel', false); 
                })
                .catch((error) => {
                    EventBus.$emit('ShowWeel', false); 
                    this.showResponseErrorMessage(error, this.$t('ChartReview.CHARTREVIEW'));
                });
        },

        getControlType(value) {
            switch(value) {
                case 'A':
                    return 'date'
                case 'T':
                    return 'time'
                case 'D':
                    return 'datetime-local'
                case 'F':
                    return 'text'
                case 'S':
                    return 'text'
                case 'I':
                    return 'number'
                case 'M':
                    return 'text'
                default:
                    return 'text'
            }
        },

        handlePrint() {
            if (this.reportEvent) {
                this.reportEvent({recId: this.logNote.Id, modelLogNoteType: this.logNote.notesId, intakeDate: this.note.intakeDate, headerId: this.note.headerId   });
            }
        },

        getOptions(pickListArray) {      
            let obj = {}
            let arrOptions = []           
            pickListArray.forEach(element => {
                obj = {}            
                obj['description'] = element
                obj['value'] = element
                arrOptions.push(obj)
            });        

            return arrOptions         
        },
    },

    mounted() {
        EventBus.$emit('ShowWeel', true); 
        this.getLogNoteById();
    }
}
</script>