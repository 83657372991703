<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <div class="textlabel" :data-label="this.$tc('Shared.Patient')">
          <span class="e-input-group e-control-wrapper">
            <input
              placeholder=""
              id="subject"
              class="e-field e-lib e-control e-input e-keyboard"
              type="text"
              value=""
              name="subject"
              ref="subject"
              v-on:dblclick="CallModalTable()"
            />
            <span
              v-on:click="CallModalTable()"
              class="e-input-group-icon e-icons mdi mdi-magnify iconLupa"
              id="btnsearchinput"
            ></span>
          </span>
        </div>
           <input
                  id="patientCode" 
                  class="e-field e-input" 
                  type="hidden" 
                  value=""
                  name="patientCode"
                  ref="PatientCode"
                >
            <input
                  id="patientName" 
                  class="e-field e-input" 
                  type="hidden" 
                  value=""
                  name="patientName"
                  ref="PatientName"
                >
             <input
                  id="activityId" 
                  class="e-field e-input" 
                  type="hidden" 
                  value=""
                  name="activityId"
                  ref="activityId"
                >
      </div>

      <div class="col-md-4">
        <div>
          <b-button
            v-b-toggle.collapse-1
            size="sm"
            class="w-100 morePatInfo"
             id="btnCollapsedPatientInfo" 
            ref="btnCollapsedPatientInfo"          
            v-on:click="GetAppointmentEditorPatientInfo"
          >
          <b-icon icon="eye"></b-icon> {{ $t('schedule-editor.ToggleInfo') }}</b-button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <b-collapse id="collapse-1" class="mb-4 collapse show">
          <b-card
            class="patInfoCard"
            id="load"
          >
            <div class="float-left col-md-4 text-muted p-item">
              <small>{{ $t('Shared.PatientCode') }}</small><br /><b>{{this.PatientCode}}</b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-if="PatientInfoObject.dob != null">
              <small>{{ $t('Shared.Dob') }}</small><br /><b>{{PatientInfoObject.dob}}</b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('Shared.Dob') }}</small><br /><b><span>{{ $t('Shared.Notdefined') }}</span></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-if="PatientInfoObject.email != null">
              <small>{{ $t('Shared.Email') }}</small><br /><b><a :href="'mailto:'+PatientInfoObject.email" class="dashLink dashMail">{{PatientInfoObject.email}}</a></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('Shared.Email') }}</small><br /><b><span class="dashLink dashMail">{{ $t('Shared.Notdefined') }}</span></b>
            </div>  
            <div class="float-left col-md-4 text-muted p-item" v-if="PatientInfoObject.cellPhone != ''">
              <small>{{ $t('Shared.CellPhone') }}</small><br /><b><a :href="'tel:'+PatientInfoObject.cellPhone" class="dashLink dashCell">{{PatientInfoObject.cellPhone}}</a></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('Shared.CellPhone') }}</small><br /><b><span class="dashLink dashCell">{{ $t('Shared.Notdefined') }}</span></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-if="PatientInfoObject.dayPhone != ''">
              <small>{{ $t('Shared.DayPhone') }}</small><br /><b><a :href="'tel:'+PatientInfoObject.dayPhone" class="dashLink dashCell">{{PatientInfoObject.dayPhone}}</a></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('Shared.DayPhone') }}</small><br /><b><span class="dashLink dashCell">{{ $t('Shared.Notdefined') }}</span></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-if="PatientInfoObject.nightPhone != ''">
              <small>{{ $t('Shared.NightPhone') }}</small><br /><b><a :href="'tel:'+PatientInfoObject.nightPhone" class="dashLink dashCell">{{PatientInfoObject.nightPhone}}</a></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('Shared.NightPhone') }}</small><br /><b><span class="dashLink dashCell">{{ $t('Shared.Notdefined') }}</span></b>
            </div>           
            <div class="float-left col-md-4 text-muted p-item text-truncate" v-if="PatientInfoObject.insuranceName != ''">
              <small>{{ $t('Insurance.colInsuranceName') }}</small><br /><b v-b-tooltip.hover :title="PatientInfoObject.insuranceName">{{PatientInfoObject.insuranceName}}</b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('Insurance.colInsuranceName') }}</small><br /><b><span>{{ $t('Shared.Notdefined') }}</span></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-if="PatientInfoObject.policyNo != ''">
              <small>{{ $t('Insurance.PolicyNo') }}</small><br /><b>{{PatientInfoObject.policyNo}}</b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('Insurance.PolicyNo') }}</small><br /><b><span>{{ $t('Shared.Notdefined') }}</span></b>
            </div>
            <div class="float-left col-md-4 text-muted p-item">
              <small>{{ $t('Insurance.CoPay') }}</small><br /><b>{{PatientInfoObject.copay}}</b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-if="PatientInfoObject.alert != ''">
              <small>{{ $t('ActList.Alert') }}</small><br /><i class="mdi mdi-alert text-warning"></i> <b>{{PatientInfoObject.alert}}</b>
            </div>
            <div class="float-left col-md-4 text-muted p-item" v-else>
              <small>{{ $t('ActList.Alert') }}</small><br /><b><span>{{ $t('Shared.Notdefined') }}</span></b>
            </div>
            <div id="createdById" class="float-left col-md-4 text-muted p-item" >
              <small>{{ $t('schedule-editor.AppoCreatedBy') }}</small><br /><b id="createdBy" class="createdByClass" name="createdBy" ref="createdBy"></b>
            </div>
            <div id="creationDateId" class="float-left col-md-4 text-muted p-item" >
              <small>{{ $t('schedule-editor.AppoCreatedOn') }}</small><br /><b id="creationDate" class="creationDateClass" name="creationDate" ref="creationDate"></b>
            </div>
            <div class="float-left col-md-8 text-muted p-item" v-if="PatientInfoObject.zoomStartUrl != null">
              <small>{{ $t('schedule-editor.tHealth') }}</small><br />
              <a :href=PatientInfoObject.zoomStartUrl target="_blank" class="dashLink dashURL"><i class="ikon med-open-new mr-1"></i>{{ $t('schedule-editor.sMeetingNow') }} </a>
              <i class="ikon med-copy ml-2 pointer inlineIcon" v-on:click="copied()" v-b-tooltip.hover :title="tooltipCopy" @mouseover="tooltipCopy = $t('schedule-editor.cMeetingLink')"></i>
              <i class="ikon med-notify ml-2 pointer inlineIcon" v-on:click="sendReminder()" v-b-tooltip.hover :title="tooltipSendMeeting"></i>
            </div>

            <div class="float-left col-md-8 text-muted p-item" v-if="PatientInfoObject.zoomStartUrl === null && TeleHealthPlatform === 'Zoom'">
              <small>{{ $t('schedule-editor.tHealth') }}</small><br />
              <a :href="'#'" v-on:click="CreateMeetingOnDemand" class="dashLink dashURL"><i class="ikon med-open-new mr-1"></i>{{ $t('schedule-editor.cMeeting') }} </a>             
            </div>


          </b-card>
        </b-collapse>
      </div>
    </div>

    <div class="row">
      <div class="col-md-8">
        <div class="textlabel" :data-label="this.$tc('Shared.AppointmentType')">
          <input
            id="type"
            class="e-field e-input"
            type="text"
            value=""
            name="type"
          />
        </div>
      </div>
      <div class="col-md-4">
        <div class="textlabel" :data-label="this.$tc('Shared.Employee')">
          <input
            id="EmployeeCode"
            class="e-EmployeeId e-field e-input"
            type="text"
            value=""
            name="EmployeeCode"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="textlabel" :data-label="this.$tc('Shared.Program',1)">
          <input
            id="admissionRecordId"
            class="e-field e-input"
            type="text"
            value=""
            name="admissionRecordId"
          />
        </div>
      </div>
    </div>
    <h4 class="page-subtitle">{{$t('schedule-editor.Timing')}}</h4>
    <div class="row">
      <div class="col-md-6  mb-4">
        <div class="textlabel" :data-label="this.$t('MdCurrentAppointment.StarTime')">
          <input id="startTime" class="e-field" type="text" name="startTime" />
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="textlabel" :data-label="this.$t('MdCurrentAppointment.EndTime')">
          <input id="endTime" class="e-field" type="text" name="endTime" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12" id="RecurrenceBlock">
        <ejs-recurrenceeditor
          id="RecurrenceEditor"
          ref="EditorObj"
          :locale="locale"
        ></ejs-recurrenceeditor>
      </div>
    </div>
    <h4 class="page-subtitle">{{$t('schedule-editor.AppotStatus')}}</h4>
    <div class="row">
      <div class="col-md-6 mb-3">
        <input
          id="patientConfirmed"
          class="e-field"
          type="checkbox"
          name="patientConfirmed"
        />
      </div>
      <div class="col-md-6 mb-3">
        <input
          id="patientShowedUp"
          class="e-field"
          type="checkbox"
          ref="checkPatientShow"
          name="patientShowedUp"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="textlabel" :data-label="this.$t('schedulerDailyRep.Reason')">
          <input
            id="reason"
            class="e-field e-input"
            type="text"
            value=""
            name="reason"
            ref="dropdownReason"
          />
        </div>
      </div>
    </div>
    <h4 class="page-subtitle">{{$t('schedule-editor.Notes')}}</h4>
    <div class="row">
      <div class="col-12">
        <textarea
          id="notes"
          class="e-field e-input theNotes"
          name="notes"
          rows="5"
          cols="50"
          style="width: 100%; height: 60px !important; resize: vertical"
          ref="notes"
          value=""
        >
        </textarea>
      </div>
    </div>
    
    <button hidden name="btnNotify" class="e-field" id="btnNotify"></button>
    <ejs-contextmenu id='contextmenunotify' :items="itemsBtn" :select='onSelect'></ejs-contextmenu>

    <ModalTablePaginationServer
      v-if="showTablePaginationServer"
      v-bind:Resource="this.ResourceParameter"
      v-bind:TypeOption="this.TypeOption"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCodeConditionCancel="onCodeConditionCancel($event)"
      v-bind:Fields="this.fields"
      v-bind:FieldCode="this.fieldCode"
      v-bind:FieldDescription="this.fieldDescription"
      v-bind:TitleWindow="$t('schedule-editor.FindPatient')"
      v-bind:ModalId="this.modalId"
      v-bind:AuxFields="this.AuxFields"
      v-bind:FieldsDate="fieldsDate"
      v-bind:valueToSearch="this.$refs.subject.value"
    >
    </ModalTablePaginationServer>
  </div>
</template>

<script>
import Vue from "vue";
import Utils from "@/common/utils";
import ShowMessage from "@/components/messages/ShowMessage.js";
import { RecurrenceEditorPlugin } from "@syncfusion/ej2-vue-schedule";
import SchedulerService from '@/services/schedulers.service'
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import MessageTemplateSetupsService from '@/services/messagetemplatesetups.service'
import MessageNotificationService from '@/services/messagenotification.service'
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
import StorageService from '@/services/storage.service'
import Patient from '@/services/patient.service'
import Employee from '@/services/employee.service'

Vue.use(ContextMenuPlugin);

Vue.use(RecurrenceEditorPlugin);
export default {
  name: "Schedule-Editor",
  mixins: [ShowMessage],
  data() {
    return {
      fields: [
        {
          key: "lastname",
          sortable: true,
          label: this.$t('Shared.LastName'),
          class: "text-rigth",
        },
        {
          key: "firstname",
          sortable: true,
          label: this.$t('Shared.FirstName'),
          class: "text-rigth",
        },
        { key: "dob", sortable: true, label: this.$t('Shared.Dob'), class: "text-rigth" },
        {
          key: "patientcode",
          sortable: true,
          label: this.$t('Shared.PatientCode'),
          class: "text-rigth",
        },
        {
          key: "patientid",
          sortable: true,
          label: this.$t('Patient.PatientId'),
          class: "text-rigth",
        },
        {
          key: "cellPhone",
          sortable: true,
          label: this.$t('Shared.CellPhone'),
          class: "text-rigth",
        },
        {
          key: "dayPhone",
          sortable: true,
          label: this.$t('Shared.DayPhone'),
          class: "text-rigth",
        },
        {
          key: "nightPhone",
          sortable: true,
          label: this.$t('Shared.NightPhone'),
          class: "text-rigth",
        },
        {
          key: "email",
          sortable: true,
          label: this.$t('Shared.Email'),
          class: "text-rigth",
        },
        { key: "zip", sortable: true, label: this.$t('Shared.Zip'), class: "text-rigth" },
      ],
      TeleHealthPlatform:null,
      tempdataReason: [],
      dataReasonWasSaved: false,
      modelCode: this.ModelCode,
      modelDescription: "",
      fieldCode: "patientcode",
      fieldDescription: "firstname",
      FieldsParameter: [],
      ResourceParameter: "",
      TypeOption: "",
      refCode: "PatientName",
      modalId: "",
      searchFieldCode: "patientcode",
      parameter: {
        SearchCriteria: "",
        PageNumber: 1,
        PageSize: 5,
        Active: true,
      },
      api: "patients",
      showTablePaginationServer: false,
      AuxFields: ["lastname", "cellPhone", "dayPhone", "nightPhone"],
      fieldsDate: ["dob"],
      reason: null,
      subject: null,
      phone: null,
      ToggleButtonDisabled:true,
      PatientCode:"",
      PatientInfoObject:{},
      paramsApptEditorNotes: {
        PatientCode: "",
      },
      tooltipCopy: this.$t('schedule-editor.cStartM'),
      tooltipSendMeeting: this.$t('schedule-editor.sMeeInv'),
      itemsBtn: [
         { text: this.$tc('Shared.Patient'), id: 'patient'},
         { text: this.$tc('Shared.Employee'), id: 'employee'},
         { text: this.$tc('Contact.PatientContact'), id: 'patientcontact'},
      ],
      showIn: 'SCHEDULER',
      options2: [],
      locale: localStorage.language
    };
  },
  methods: {

    onSelect: async function(args) {
      if (args.item.messageType) 
        await this.SendNotificationMessage(
                                          args.item.messageType,
                                          args.item.notificationType, 
                                          args.item.sendTo, 
                                          args.item.cellPhone, 
                                          args.item.email
                                        )
    },

    async sendReminder() {
      let param = {
        activityId: this.$refs.activityId && this.$refs.activityId.value ? this.$refs.activityId.value : null,
        patientCode: this.$refs.PatientCode && this.$refs.PatientCode.value ? this.$refs.PatientCode.value : null
      }
      await SchedulerService.notifyPatientOnDemand(param)
        .then((response) => {
          if(response.status == '200' && response.data ){
            this.showMessage(this.$t('schedule-editor.sReminder'),this.$t('schedule-editor.rMsg'));
          }    
        })
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),this.$t('Msg.NotAbleToInsert'),"error");
          }          
          else if(error.response.status === 400 ){
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),error.response.data.errorMessage,"error");
          }else if(error.response.status === 500){
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          } else{
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),error.response.data.errorMessage,"error");
          }  
        });
    },

   async CreateMeetingOnDemand(){
    this.ShowSpinner();
      let param = {
        activityId: this.$refs.activityId && this.$refs.activityId.value ? this.$refs.activityId.value : null,
        patientCode: this.$refs.PatientCode && this.$refs.PatientCode.value ? this.$refs.PatientCode.value : null
      }
      await SchedulerService.getCreateMeetingOnDemand(param)
        .then((response) => {
          if(response.status == '200' && response.data ){           
            this.PatientInfoObject.zoomStartUrl = response.data.zoomStartUrl;
            this.$bvToast.toast(this.$t('schedule-editor.CreateMeetingOnDemand'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        });
             this.HideSpinner();
          }    
          this.HideSpinner();
        })
        .catch((error) => {
          this.HideSpinner();
          if (!error.response) {
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),this.$t('Msg.NotAbleToInsert'),"error");
          }          
          else if(error.response.status === 400 ){
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),error.response.data.errorMessage,"error");
          }else if(error.response.status === 500){
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          } else{
            this.showMessage(this.$t('schedule-editor.SInfoTitle'),error.response.data.errorMessage,"error");
          }  
        });
    },

    copied() {
      navigator.clipboard.writeText(this.PatientInfoObject.zoomStartUrl);
      this.tooltipCopy = this.$t('schedule-editor.uCopied')
    },

    showModalTablePaginationServer() {
      this.showTablePaginationServer = true;
    },

    setValid(value) {
      this.classValue = value ? "form-control" : "form-control is-invalid";
    },

    getPhoneNumber(cellPhone, dayPhone, nightPhone) {
      if (cellPhone) return cellPhone;
      if (dayPhone) return dayPhone;
      if (nightPhone) return nightPhone;
      return "";
    },

    ShowSpinner :function(){       
       showSpinner(document.getElementById('load'));           
    },

    HideSpinner :function(){
      hideSpinner(document.getElementById('load'));
    },


    CallModalTable: async function() {
      if (this.refCode) {
        this.$emit("load", true);
        this.FieldsParameter = [this.fieldCode, this.fieldDescription];
        this.ResourceParameter = "/" + this.api;
        this.TypeOption = this.refCode;
        await this.showModalTablePaginationServer();
        this.$bvModal.show(this.modalId);
        this.$emit("load", false);
      }
    },

  GetAppointmentEditorPatientInfo(updatedPatient){ 
    let el = document.getElementById('btnCollapsedPatientInfo');         
    if(el.className === 'btn w-100 morePatInfo btn-secondary btn-sm collapsed' || updatedPatient){ 
       if(this.$refs.PatientCode.value){ 
         this.ShowSpinner();
         this.PatientCode = this.$refs.PatientCode.value;
          SchedulerService.getAppointmentEditorPatientInfo(this.PatientCode, this.$refs.activityId && this.$refs.activityId.value ? this.$refs.activityId.value : null)
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.PatientInfoObject = response.data;                 
                 if(this.PatientInfoObject.dob){
                  this.PatientInfoObject.dob = Utils.formatterDateToString(this.PatientInfoObject.dob);
                 }                
                this.HideSpinner();
                 if(this.PatientInfoObject.copay == -1){
                   this.PatientInfoObject.copay = "Not defined";
                 }else{
                   this.PatientInfoObject.copay = "$"+this.PatientInfoObject.copay.toFixed(2);
                 }                                 
               }
            })
            .catch((error) => {
              this.HideSpinner();
              if (!error.response) {
                this.showMessage(this.$t('schedule-editor.SInfoTitle'),this.$t('Msg.NotAbleToInsert'),"error");
              }          
              else if(error.response.status === 400 ){
                 this.showMessage(this.$t('schedule-editor.SInfoTitle'),error.response.data.errorMessage,"error");
              }else if(error.response.status === 500){
                this.showMessage(this.$t('schedule-editor.SInfoTitle'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
                  this.showMessage(this.$t('schedule-editor.SInfoTitle'),error.response.data.errorMessage,"error");
              }              
            });
       }

     }
    },

      onCodeConditionOk(result) {
        var type = result.FieldName;
        if (type.indexOf(this.refCode) !== -1 && result.code) {
          this.$refs.PatientCode.value = result.code;
          this.PatientCode = result.code;
          
          if(this.PatientCode){
             var btnPatientInfo = this.$refs.btnCollapsedPatientInfo;
             btnPatientInfo.disabled = false;
          }   
          this.phone = result.AuxFields && this.getPhoneNumber(result.AuxFields[1],result.AuxFields[2],result.AuxFields[3])
          this.$refs.PatientName.value = `${result.description} ${result.AuxFields && result.AuxFields[0] ? result.AuxFields[0] : ''}`
          this.$refs.subject.ej2_instances[0].value = `${this.$refs.PatientName.value} ${this.phone}`
          this.setValid(true)
          if (this.GetValues) {
            this.GetValues(result);
          }
          let el = document.getElementById('btnCollapsedPatientInfo');         
          if(el.className != 'btn w-100 morePatInfo btn-secondary btn-sm collapsed')
            this.GetAppointmentEditorPatientInfo(true)
        }
        this.ResourceParameter = "";
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId)
        })
        this.showTablePaginationServer = false
      },

    onCodeConditionCancel() {
      this.ResourceParameter = "";
      this.showTablePaginationServer = false;
    },

    uuidv4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
      );
    },

    async SendNotificationMessage(messageType, notificationType, sendTo, cellPhone, email) {
      let params = {
        recipientCode: this.$store.state.schEdipatientCode,
        recipientType: sendTo ,
        entityCode: this.$store.state.schEdiActivityId,
        entityType: 'ACTIVITYID',
        messageType: messageType,
        notificationType: notificationType.toUpperCase(),
        cellPhoneNumber:  cellPhone,
        email: email,
        module: 'SCHEDULER',
        action: "SendByUser",
        sysUser: StorageService.getUserId(),
      }
      
      await MessageNotificationService.SendNotificationMessage(params)
        .then((response) => {
          if(response.status == '200'){
            this.Load = false;
            if (response.data.success) {
              this.showMessage(this.$t('scheduleNotify.noti'),this.$t('scheduleNotify.msgSent'));
              this.closeModal = true
              this.onClose() 
            } else {
              this.showMessage(this.$t('scheduleNotify.noti'),response.data.errorMessage,"error");
            }	
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
      },

    getPlatformType(){
      let PCode = this.$store.state.providerCode;
      let PList = this.$store.state.ProvidersList;

      const arrfilter = PList.filter((e) => e.code === PCode);
      if(arrfilter.length > 0){
        this.TeleHealthPlatform = arrfilter[0].teleHealthPlatform;
      }
    },

    async getOptions(SendTo) {
      let params = {
        SendTo: SendTo,
        ShowIn: this.showIn
      }

      let patientInfo = {}
      let employeeInfo = {}

      if (this.data.patientCode != undefined) {
        await Patient.get(this.data.patientCode)
          .then((response) => {
            if(response.status == '200'){
              patientInfo = response.data
            }
          })
          .catch((error) => {
            if(error.response) {
              this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
            }
          });
        }
      
        if (this.data.id && this.data.employeeCode != undefined) {
          await Employee.getEmployeeByCode({code: this.data.employeeCode})
          .then((response) => {
            if(response.status == '200'){
              employeeInfo = response.data
            }
          })
          .catch((error) => {
            if(error.response) {
              this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
            }
          });
        }

      if (this.data.id) {
        await MessageTemplateSetupsService.getAll(params)
        .then((response) => {
          if(response.status == '200'){
              this.options2 = response.data;
              this.itemsBtn.forEach(it => {
                let items = []
                let itemObject = document.getElementById('contextmenunotify').ej2_instances[0].items.find(element => element.id === it.id)
                this.options2.forEach(el => {
                if (el.sendTo == it.id.toUpperCase()) {
                  items.push({
                    text: `${el.description} - ${el.notificationType}`, 
                    messageType: el.messageType, 
                    notificationType: el.notificationType,
                    sendTo: el.sendTo,
                    cellPhone: el.sendTo == 'PATIENT' ? patientInfo.cellPhone : employeeInfo.cellPhone,
                    email: el.sendTo == 'PATIENT' ? patientInfo.email : employeeInfo.eMail,
                  })
                }
              });

              if (items.length == 0) {
                items.push({
                    text: this.$t('schedule-editor.nNoFound'), 
                })
              }
              
              itemObject.items = items
              });
              
              this.Load = false;
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
      }
    },    

    async getMenuNotifyItems() {
      await this.getOptions()
    }

  },
  created() {
    this.modalId = 'ee-334-5rt654';
    this.getPlatformType();
  },
  
   mounted: async function() {    
    createSpinner({  
      target: document.getElementById('load')
    });
    await this.getMenuNotifyItems()
  },
};
</script>
<style>
.e-tooltip-wrap.e-schedule-error {
  margin-left: 100px;
}
button.e-control.e-btn.e-lib.e-primary.e-event-save.e-flat {
  float: right;
}
#Schedule_dialog_wrapper {
  width: 100% !important;
  max-width: 800px !important;
  height: 100% !important;
}
.e-float-text {
  font-weight: bold !important;
  font-size: 10px !important;
  text-transform: uppercase !important;
  margin: 0 0 3px 10px !important;
  display: block;
}
.e-recurrenceeditor .e-form-left {
    padding: 0 8px 2px 0 !important;
}
.morePatInfo{
  margin-top:22px;
  border: 1px solid #EEE !important;
  font-size: 0.7em !important;
  font-weight: bold;
  color: #3f3d4f !important;
}
.morePatInfo:focus{
  border: 1px solid white;
}
#btnCollapsedPatientInfo.collapsed {
  background: linear-gradient(to bottom, white, #e7ebf0);
}
#btnCollapsedPatientInfo.not-collapsed {
  background: linear-gradient(to top, #f8fdff, #b5eaff 95%);
  color: #0799b9 !important;
  border: 1px solid #99e2f8 !important;
  border-bottom: 0 !important;
}
#btnCollapsedPatientInfo.not-collapsed:hover {
  color: black !important;
}

.patInfoCard{
  background-image: linear-gradient(173deg, rgb(217, 244, 251), rgb(255, 255, 255) 30%);
  transition: all 0.3s ease-out;
}
.theNotes{
  font-size: 0.9em !important;
  color: #666 !important;
}
.e-schedule .e-schedule-table > tbody > tr > td {
  white-space: break-spaces !important;
}

.e-dropdownbase .e-list-item {
    padding: 12px 0 !important;
    min-height: 40px !important;
    line-height: 16px !important;
    color: #3E3C4F !important;
}

.e-dropdownbase .e-list-item.e-hover {
  color: white !important;
  background: linear-gradient(90deg, #19BEE8, #19BEE8);
}
.e-dropdownbase .e-list-item.e-active{
  background: linear-gradient(90deg, #19BEE8 0px, #19BEE8 4px, #EEE 4px, #FFF);
  color: #3E3C4F !important;
}
.e-dropdownbase .e-list-item.e-active.e-hover {
  background: linear-gradient(90deg, #19BEE8 0px, #19BEE8 4px, #EEE 4px, #FFF);
  padding-left: 6px !important;
  color: #19BEE8 !important;
}
.e-dropdownbase .e-list-item{
  transition: all 0.3s ease;
  min-width: 130px;
}
.e-back-icon.e-icons, .e-save-icon.e-icons{
  color: white;
}
@media (max-width: 767px) {
  .e-title-text{
    margin-left: 30px; 
    max-width: 10px; 
  }
}
</style>