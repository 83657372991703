<template>
    <div id="ReportPatientContact">
      <div v-if="this.filterView">
        <MdTitle v-bind:Text="$t('patientContactsRep.RepTitle')" />
        <b-row>
          <b-col lg="3" md="3" sm="12">         
            <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="PatientsList"
              v-bind:Model="PatientCodeModel"
              v-bind:FieldDescription="'patientName'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Multiple="true"             
              :Input="ChangeDropDownPatients"
              :CustomLabel="customLabelPatients"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Contact.ActiveReleaseOnFile')"
              v-bind:Options="ActiveReleaseOnFile"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'"
              v-bind:Model="releaseOnFile"  
              v-bind:Multiple="false" 
              :Input="ChangeDropDownFile"
              />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Contact.MayHavePhoneCalls')"
              v-bind:Options="YesNoOptionsNumericThree"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'"
              v-bind:Model="mayHavePhoneCalls"  
              v-bind:Multiple="false" 
              :Input="ChangeDropDownPhoneCalls"
             />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Contact.SupervisedPhoneCalls')"
                v-bind:Options="YesNoOptionsNumericThree"
                v-bind:FieldDescription="'text'"
                v-bind:FieldCode="'value'"
                v-bind:Model="supervisedPhoneCalls"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownSupervisedCalls"
                />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Contact.VisitationPermission')"
              v-bind:Options="YesNoOptionsNumericTwo"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'"
              v-bind:Model="visitationPerm"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownVisitationPerm"
              />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Contact.MayVisit')"
              v-bind:Options="YesNoOptionsNumericThree"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'"
              v-bind:Model="mayVisit"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownMayVisit"
              />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Contact.UnsupervisedVisitsOnGrounds')"
              v-bind:Options="YesNoOptionsNumericThree"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'"
              v-bind:Model="unsupVisitsOnGrounds"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownVisitsOnGrounds"
                />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Contact.UnsupervisedVisitsOffGrounds')"
              v-bind:Options="YesNoOptionsNumericThree"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'"
              v-bind:Model="unsupVisitsOffGrounds"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownVisitsOffGrounds"
              />
            </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Contact.NeedProbationForOffGrounds')"
                v-bind:Options="YesNoOptionsNumericThree"
                v-bind:FieldDescription="'text'"
                v-bind:FieldCode="'value'"
                v-bind:Model="needProbApprForOffGrounds"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownApprForOffGrounds"
                />
          </b-col>
        </b-row>
        <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Back')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="reportList"
            v-bind:Name="'btn_ReportList'"/>
          <MdButton
            v-bind:Label="$t('Shared.Report')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClickShowReport"
            v-bind:Name="'btn_Print'"/>   
        </div>
      </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('patientContactsRep.RepTitle')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="patientContacts"
          :ReportName="'PatientContacts'"
          :ReportTitle="$tc('Contact.PatientContact',2)"
          :IsAnAPIReport="true"
        />
    </DxPopup>
  </div>
</template>

<script>
import Vue from 'vue'
import { DxPopup } from 'devextreme-vue/popup';
import ShowMessage from '@/components/messages/ShowMessage.js';
import PatientService from '@/services/patient.service';
import Utils from '@/common/utils';
import EnumService from '@/services/enum.service'
import DBFunctions from '@/common/DBFunctions.js'

export default Vue.extend({
    name: "PatientContactsRep",
    mixins: [ ShowMessage, Utils, DBFunctions ],
    components: { DxPopup },
    props: {
      patientCode: String,
      closeWindow: Function,
      recNo: String,
      patientName: String,
      dob: String,
      filterView: Boolean
    },
    data() {
        return {
          OptionsRightMenu:{
              visible:false,
              ItemsList:[],
              btnvisible:false,
              itemdisabled: false,
              PatientCode:'',
              Mode:this.edit_mode,
              favButtonAtion:false          
            },
            isParamVisible: true,
            isReportVisible: false,
            reportParams: [],
            PatientsList:[],
            params: {
              "PatientCodeList": []
            },
            parameter:{
              Status: true,
            },
            PatientCodeModel:'',
            releaseOnFile:"",
            mayHavePhoneCalls:"",
            supervisedPhoneCalls:"",
            visitationPerm:"",
            mayVisit:"",
            unsupVisitsOnGrounds:"",
            unsupVisitsOffGrounds:"",
            needProbApprForOffGrounds:"",
            ActiveReleaseOnFile: EnumService.YesNoOptions,
            YesNoOptionsNumericThree: EnumService.YesNoOptionsNumericThree,
            YesNoOptionsNumericTwo: EnumService.YesNoOptionsNumericTwo,
        }
    },
    methods: {

      onShowing(e) {
        let contentElement = e.component.content();
        let titleElement = e.component._$title[0]
        contentElement.style.padding = '0px';
        titleElement.style.padding = '0px 20px'
      },

      onHiddenReport() {
        this.isReportVisible = false
        if(!this.filterView)
          this.closeWindow();
      },

      onClickClose() {
        this.closeWindow()
        this.isParamVisible = false;
      },

      reportList() {
        this.checkExistFavorite('/app/reports/patients');
        this.$router.push({ name: "reportListPatient" }).catch(()=>{});
      },
      onClickShowReport() {
        this.isParamVisible = false

        this.reportParams = []
        this.reportParams.push({ paramName: 'patientCode', paramValue: this.patientCode})
        this.reportParams.push({ paramName: 'recNo', paramValue: this.recNo})
        this.reportParams.push({ paramName: 'patientName', paramValue: this.patientName})
        this.reportParams.push({ paramName: 'patientCodeList',
           paramValue: (this.params.PatientCodeList.length == 0) ? this.patientCode :this.params.PatientCodeList })

        this.reportParams.push({ paramName: 'releaseOnFile', paramValue: this.releaseOnFile})
        this.reportParams.push({ paramName: 'mayHavePhoneCalls', paramValue: this.mayHavePhoneCalls})
        this.reportParams.push({ paramName: 'supervisedPhoneCalls', paramValue: this.supervisedPhoneCalls})
        this.reportParams.push({ paramName: 'visitationPerm', paramValue: this.visitationPerm})
        this.reportParams.push({ paramName: 'mayVisit', paramValue: this.mayVisit})
        this.reportParams.push({ paramName: 'unsupVisitsOnGrounds', paramValue: this.unsupVisitsOnGrounds})
        this.reportParams.push({ paramName: 'unsupVisitsOffGrounds', paramValue: this.unsupVisitsOffGrounds})
        this.reportParams.push({ paramName: 'needProbApprForOffGrounds', paramValue: this.needProbApprForOffGrounds})
        this.isReportVisible = true
        this.$refs.patientContacts.buildDocument(this.reportParams)
      },
      getItemsByComma(values, field) {
        let len = values.length;
        let valuesSeparated = [];
        if (len > 0){
          var i = 0;
          values.forEach(function(e) {   
            if(i < len-1){
              valuesSeparated += e[field]+",";
            }else{
              valuesSeparated += e[field];
            }
            i++;                    
          });
          return valuesSeparated;
        } else {
          return [];
        }
      },

      ChangeDropDownPatients(values) {
        this.params.PatientCodeList = this.getItemsByComma(values, 'patientcode')
      },

      customLabelPatients ({ patientName, patientcode }) {
        return `${patientName} – ${patientcode}`
      },
      ChangeDropDownFile(value){
         this.releaseOnFile = value ? value.value : '';
      },
      ChangeDropDownPhoneCalls(value){
         this.mayHavePhoneCalls = value ? value.value : '';
      },
      ChangeDropDownSupervisedCalls(value){
         this.supervisedPhoneCalls = value ? value.value : '';
      },
      ChangeDropDownVisitationPerm(value){
         this.visitationPerm = value ? value.value : '';
      },
      ChangeDropDownMayVisit(value){
         this.mayVisit = value ? value.value : '';
      },
      ChangeDropDownVisitsOnGrounds(value){
         this.unsupVisitsOnGrounds = value ? value.value : '';
      },
      ChangeDropDownVisitsOffGrounds(value){
         this.unsupVisitsOffGrounds = value ? value.value : '';
      },
      ChangeDropDownApprForOffGrounds (value){
         this.needProbApprForOffGrounds = value ? value.value : '';
      },
      getPatients() {              
          PatientService.getAll(this.parameter)
          .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.PatientsList = response.data; 
            this.PatientsList.forEach(function(element) {
                element.patientName = element.firstname + " " + element.lastname;   
              });
            }      
            this.$emit('load', false);
          })
          .catch(error => {
            this.$emit('load', false);
            if (!error.response) {
                // network error
                this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.retrieveInfo'),"error");
            } else {
                this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
            }
          });
      }         
    },
    created() {
      if(this.filterView)
        this.getPatients();
    },
    mounted () {
      if(!this.filterView)
        // this.isReportVisible = true
        this.onClickShowReport();

      this.OptionsRightMenu.ItemsList = [];
      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
    },

  })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
