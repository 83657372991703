<template>
    <div>
      <body class="sidebar-fixed">    
          <div class="container-scroller">
          <div class="container-fluid page-body-wrapper full-page-wrapper">
            <div class="content-wrapper d-flex align-items-center auth px-0">
              <div class="row w-100 mx-0">
                <div class="col-lg-4 mx-auto">
                  <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div class="brand-logo">
                      <img src="../../../assets/images/logo.svg" alt="logo">
                    </div>

                    <div v-if="errorMessage" class="pt-3">
                      <div id="alert" class="alert alert-fill-danger" role="alert">
                        <i class="mdi mdi-alert-circle"></i>
                        {{ errorMessage }}
                      </div>
                  </div>

                  <div class="pt-3" v-if="MessageOK">
                    <div id="alert" class="alert alert-fill-success" role="alert">                     
                      {{ $t('AccountExpired.MessageResetPasswordOK') }}
                    </div>
                </div>

                    <Loading v-bind:IsLoading="this.Load" />  
            
                    <b-row>
                      <b-col md="12">
                        <MdPassword
                          v-bind:Label="$t('Login.NewPassword')"
                          v-bind:TypeProp="'password'" 
                          v-bind:Name="'NewPassword'"           
                          v-bind:MaxLengthProp="45"
                          ref="inputnewpassword"
                          v-bind:TabIndex="'1'"
                          v-bind:isRequired="true"                         
                          v-bind:isEndControl="false"           
                          v-model="newPasswd"            
                          v-bind:Autocomplete="false">                                     
                        </MdPassword>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                         <MdPassword
                          v-bind:Label="$t('Login.ConfirmPassword')"
                          v-bind:TypeProp="'password'" 
                          v-bind:Name="'confirmPassword'"           
                          v-bind:MaxLengthProp="45"
                          ref="inputconfirmpassword"           
                          v-bind:TabIndex="'2'"
                          v-bind:EndControlFunction="onEnter"
                          v-bind:isRequired="true"                         
                          v-bind:isEndControl="true"           
                          v-model="confirmPass"            
                          v-bind:Autocomplete="false">                                     
                        </MdPassword>
                      </b-col>
                    </b-row>
                      <div class="mt-3">
                        <div class="row">
                          <div class="col-6"> 
                            <MdButton 
                              v-bind:Label="$t('AccountExpired.BtLogin')"
                              v-bind:Name="'btnSignIn'"
                              v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                              v-on:click="gotologin">
                            </MdButton>
                        </div>
                          <div class="col-6">
                            <MdButton 
                            v-bind:isReadOnly="(newPasswd === '' || confirmPass === '' || Disabled) ? true : false"
                            v-bind:Label="$t('Login.ChangePassword')"
                            v-bind:Name="'btnSignIn'"
                            v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                            v-on:click="ChangePassword">
                         </MdButton>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>     
          </div> 
      </body>
    </div>
    </template>
    
    <script>
    import UserService from '@/services/user.service'
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import Loading from '@/components/template/loading/Loading'
    import ProviderService from '@/services/provider.service'
    import Companies from '@/services/companies.service'
    import StorageService from '@/services/storage.service'
    import UserPreferences from '@/services/userpreferences.service'

    export default {
      name: 'RecoveryPassword',
      components: {Loading},
      mixins: [ShowMessage],
      data(){
        return{
          newPasswd:"",
          confirmPass:"",
          Disabled:false,
            errorMessage:"",
            showPassword: false,
            showPointer: false,
            Load:false,
            MessageOK:false,        
        }
      },

      props: {
        token: {
          type: String,
          default: null,
        }
      },
    
      methods:{
    
        gotologin(){
           this.$router.push('/login').catch(()=>{});
        },

      comparePassword() {
        if(this.newPasswd === this.confirmPass) {
          return true
        }
        return false
      },

        parseJwt() {
            var base64Url = this.token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);}).join(''));
            return JSON.parse(jsonPayload);
        },

        ChangePassword(){

          if(this.comparePassword()) {
              let param = this.parseJwt();
              this.Load = true;           
                this.$emit('load', this.Load);  
                let data = {
                  ClientId:param.ClientId,
                  UserId:param.id,
                  NewPassword:this.newPasswd,
                  Token:this.token
                };

              UserService.ChangePassword(data)
                  .then((response) => {
                    if(response.status == '200'){ 
                      this.Load = false; 
                      this.MessageOK = true;
                      this.errorMessage = "";
                      this.Disabled = true;             
                      this.$emit('load', this.Load);             
                    }
                  })
                  .catch((error) => {    
                    this.Load = false;              
                    this.errorMessage = error.response.data.errorMessage;            
                      this.$emit('load', this.Load);
                      if (!error.response) {
                        this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),this.$t('ChangePassWord.NoAbleToChange'),"error");
                      } else {
                          this.showMessage(this.$t('ChangePassWord.ChangePassTitle'),error.response.data.message,"error");
                      }
                  });
                }else{
                  this.errorMessage = this.$t('ChangePassWord.DontMatch');                 
                }


        },




        async login() {
      this.Load = true;
      this.errorMessage = '';
      const clientid = this.$refs.uClient.value;
      const user = this.$refs.uName.value;
      const password = this.$refs.uPass.value;
      if( clientid !== '' && user !== '' && password !== '' ){
       var result = await UserService.login( clientid, user, password,false);
       if(result.expired){
          this.Load = false;
          this.$router.push({name: 'AccountExpired', params: {clientId: this.$refs.uClient.value, userId: this.$refs.uName.value}})
       }
       else if(result.disabled){         
         this.errorMessage = this.$t('Login.AccDisabled');
         this.Load = false;
       }
       else if(result.neterror){
         this.errorMessage = this.$t('AccountExpired.SerdownMsg');
         this.Load = false;
       }
       else if(result.InUse){
         this.Load = false;
          this.$bvModal.msgBoxConfirm(this.$t('Login.OtherSessOpenMsg'), {
            id:'modal_session_is_open',
            title: this.$t('Login.LoginTitle'),
            okTitle: this.$t('Login.LogOut'),
            cancelTitle: this.$t('Shared.BtnCancel'),
            footerClass: 'p-2',
            titleClass: 'modal-title modal-type-warning'
          })
              .then(async value => {
                  this.IsOk = value;
                    if(this.IsOk){ 
                      this.Load = true;                     
                      await UserService.login( clientid, user, password,true); 
                      if (UserService.IsLoged()) { 
                         this.Load = false;                       
                        this.SaveCompanies();
                        this.getProviders();
                        // this.$router.push('/provider'); 
                        this.$store.commit('setGroupNotesFiltersInitialize',[]);               
                      }
                    else {  
                       this.Load = false;             
                       this.errorMessage = this.$t('Login.LoginIncMsg');
                  }
            }
          })
       }       
       else{
          if (UserService.IsLoged()) {
             this.Load = false;
             this.SaveCompanies();
             this.getProviders();
             this.$store.commit('setGroupNotesFiltersInitialize',[]);
            //  this.$router.push('/provider');
        }
        else { 
           this.Load = false;         
          this.errorMessage = this.$t('Login.LoginIncMsg');
        }
       }
      }
      else {
         this.Load = false;
      this.errorMessage = this.$t('Login.AllRequiredMsg');
      }
    },

    SaveCompanies(){
       Companies.getAll()
                .then((response) => {
                  var data=response.data;
                  if(data && data.length > 0){
                    var companies = {
                    "Country":data[0].country  
                    };
                     this.$store.commit('setCompanies',companies);
                    //  StorageService.saveCompanie(JSON.stringify(companies));
                  }
                })
                .catch((error) => {
                    if (error.response) {
                      this.showMessage(this.$t('Login.LoginTitle'),error,"error");
                  }
                });  
    },

    getUserPreference( uid ){
        UserPreferences.getUserPreference(uid)
          .then((response) => { 
            if(response.data && response.data.length > 0){
              var dataResponse = response.data[0];
              if (dataResponse.fullScreenStatus){
                this.openFullscreen();
              }
              if(dataResponse.locale && dataResponse.locale != 'nan'){
                  this.$root.$options.i18n.locale = dataResponse.locale;
              } 
            }     
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('UserPreferences.UPTitle'),error,"error");
            } 
          });  
      },

    getProviders(){
      const uid  = StorageService.getUserId();
      ProviderService.getAll(uid)
      .then((response) => {      
          this.providerList = response.data;
          this.$store.commit('setProviderList',this.providerList);
          
          let countProviderNotTraining = 0; 
          var l = this.providerList.length;
          for(var i = 0; i < l; i++){
              if(!this.providerList[i].isTraining){
                countProviderNotTraining++;
              }
          }
          this.getUserPreference( uid );
          this.getUserRegionalFormat( uid );
          if(countProviderNotTraining === 1){
              var obj = this.getPrviderCode(this.providerList);
              this.CodeSelected.ProviderId = obj.code;
              this.$store.commit('setProviderCode',obj.code);
              this.$store.commit('setProvider',obj.company);
              this.SelectProvider();
          }else{
              this.$router.push('/provider');
          }
      })
      .catch(error => {
        if (error.response) {
          this.showMessage(this.$t('Login.LoginTitle'),error,"error");
        }
      });  
    },

        onFocus(){
            this.errorMessage = "";
            this.MessageOK = false;
        },

        onEnter(){
          this.ChangePassword();
        },
    
      }
       
    };
    </script>
    <style>
    .URLName{
      font-weight: bold;
      color:  #19BEE8;
      -webkit-font-smoothing: antialiased;
    }
    </style>
    