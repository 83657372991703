<template>
<div>
  <b-modal :id="this.ModalId" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :title="TitleWindow" :visible="!NotOpenByDefault ? true : false" @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')">
   <div id="ContainerPainAssessment">
    <b-row class="mt-2">
        <b-col md="12">
            <div class="text-center text-md-right mt-3 container-button">
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:VariantProp="'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
            />
            </div>
        </b-col>
    </b-row>
     <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Shared.WrittenBy')"
                data-field="therapist"
                v-bind:Options="therapistList"
                v-bind:FieldDescription="'fullName'"
                v-bind:FieldCode="'code'"
                v-bind:Model="painAssessment.TherapistCode"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownTherapistCode"
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdCheckBox
                v-bind:Label="$t('MdNote.Sign')"
                v-bind:Name="'checkbox-SignAssessment'"
                v-bind:ClassProp="'AlignCheck'"
                v-bind:CheckedValue="true"
                v-bind:UncheckedValue="false"
                v-model="painAssessment.IsSigned"
                v-on:change="onSignPassword"
            />
        </b-col>
     </b-row>
     <MdTitle v-bind:Text="$t('MdPainAssessmentNotesOutCome.ASSESSMENT')" v-bind:isSubtitle="true" v-bind:Name="'T_Assessment'"/>
     <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Shared.Date')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'date'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="painAssessment.AssessmentDate">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Incident.IncTime')"
                v-bind:TypeProp="'time'" 
                v-bind:Name="'time'"
                v-model="painAssessment.AssessmentTime">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <div class="textlabel" :data-label="this.$t('MdPainAssessmentNotesOutCome.Intensity')">
              <b-form-spinbutton id="Intensity" v-model="painAssessment.PainIntensity" min="1" max="100"></b-form-spinbutton>
            </div>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('VitalSign.Location')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'Location'"
                v-model="painAssessment.Location"
                v-bind:Id="'input_location_id'">                                     
            </MdEdit>
        </b-col>
     </b-row>
     <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('MdPainAssessmentNotesOutCome.Character')"
                data-field="Character"
                v-bind:Options="charactertList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'value'"
                v-bind:Model="painAssessment.PainCharacter"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownCharacter"
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Shared.Frequency')"
                data-field="Frequency"
                v-bind:Options="frequencyList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'value'"
                v-bind:Model="painAssessment.Frequency"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownFrequency"
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdPainAssessmentNotesOutCome.Duration')"
                v-bind:TypeProp="'number'" 
                v-bind:Name="'Duration'"
                v-model="painAssessment.Duration">                                     
            </MdEdit>
        </b-col>
     </b-row>
     <MdTitle v-bind:Text="$t('MdPainAssessmentNotesOutCome.Interv')" v-bind:isSubtitle="true" v-bind:Name="'T_Interventions'"/>
     <b-row>       
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdPainAssessmentNotesOutCome.Other')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'Other'"
                v-model="painAssessment.OtherIntervention">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Incident.IncTime')"
                v-bind:TypeProp="'number'" 
                v-bind:Name="'Time'"
                v-model="painAssessment.InterventionTime">                                     
            </MdEdit>
        </b-col>
     </b-row>
     <b-row>
      <b-col md="4" lg="4" sm="12">       
              <MdCheckListBox2 
                v-model="painAssessment.Intervention"
                v-bind:OnChangeProp="OnChangeIntervention"
                v-bind:Options="interventionList"
                v-bind:Height="'150px'"
                v-bind:columnCount="2"
                :textField="'name'"
                :valueField="'code'"> 
              </MdCheckListBox2>
    </b-col>
     </b-row>
     <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_Notes'"/>
     <b-row>
        <b-col md="12" lg="12" sm="12">
            <MdMemo
                v-bind:Name="'textarea_notes'"
                v-bind:Rows="'3'"
                v-bind:MaxRows="'6'"
                v-bind:MaxLength="'500'"
                v-model="painAssessment.Notes"
            />
        </b-col>
     </b-row>
   </div>

  </b-modal>
  <MdPasswordDlg v-if="showSignPassword"
    v-bind:UserId="UserId"
    v-bind:ShowPopup="showSignPassword"
    v-bind:EmployeeCode="this.$store.state.employeeCode"
    @getModalPassword="onModalPasswordResult($event)"
  />
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import LocalStorage from '@/services/storage.service';
import Utils from '@/common/utils.js'
import EnumService from "@/services/enum.service";
import TherapistService from '@/services/therapist.service'
import { EventBus } from '@/event-bus.js';

export default {
  name: 'PainAssessmentNotes', 
  mixins: [ShowMessage, DBFunctions,LocalStorage],
  props: {
     ModalId: String,
     TitleWindow: String,
     NotOpenByDefault:Boolean,
     MarsParam:Object
   },   
  data() {
    return {
      ShowMessageIsEmpty:false,
      Loading:true,
      UserId:LocalStorage.getUserId(),
      parameter:{
        PageNumber : 0,
        PageSize:0 ,       
      },
      // fields: [
      //   { key: 'allergy', sortable: true, label: 'Allergy', class: 'text-rigth' },                  
      //   ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      therapistList: [],
      charactertList: EnumService.Characterts,
      frequencyList: EnumService.Frequencys,
      interventionList: EnumService.Interventions,
      painAssessment: {
        TherapistCode:"",
        IsSigned:false,
        AssessmentDate:null,
        AssessmentTime:null,
        PainIntensity:null,
        Location:"",
        PainCharacter:"",
        Duration:"",
        Frequency:"",
        Intervention:[],
        OtherIntervention:"",
        InterventionTime:null,
        Notes:"",
      },
      showSignPassword: false,
      pendingChanges: false,
      clickOK: false
    }
  },

  methods: {
    emitEventOK(){ 

      let param = {
        info:this.getTextResult(),
        MarsParam:this.MarsParam 
      }
      this.$emit('getCodeConditionOK', param);  
       // this.clickOK = true
      },

      emitEventHide(/*handled*/){
        /*if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
              this.$emit('getCodeConditionOK', this.getTextResult());
              this.$emit('input',this.getTextResult());
            } else {
              this.$emit('getCancel');
              this.ResetProps();
            }
        }*/
      },

      ResetProps(){
        this.parameter.SearchCriteria = '';
        // this.showListing(true);
      },

      getTextResult() {      
        let textResult = '';
        textResult +=  this.$t('modalMarsMed.TherapistCode') +" "+ this.painAssessment.TherapistCode +"\n";

        textResult +=  this.$t('modalMarsMed.IsSigned') +" "+ this.painAssessment.IsSigned +"\n";

        textResult +=  this.$t('modalMarsMed.AssessmentDate') +" "+ this.painAssessment.AssessmentDate +"\n";
        textResult +=  this.$t('modalMarsMed.AssessmentTime') +" "+ this.painAssessment.AssessmentTime +"\n";
        textResult +=  this.$t('modalMarsMed.PainIntensity') +" "+ this.painAssessment.PainIntensity +"\n";
        textResult +=  this.$t('modalMarsMed.PainCharacter') +" "+ this.painAssessment.PainCharacter +"\n";
        textResult +=  this.$t('modalMarsMed.Location') +" "+ this.painAssessment.Location +"\n";
        textResult +=  this.$t('modalMarsMed.Frequency') +" "+ this.painAssessment.Frequency +"\n";
        textResult +=  this.$t('modalMarsMed.Duration') +" "+ this.painAssessment.Duration +"\n";
        textResult +=  this.$t('modalMarsMed.OtherIntervention') +" "+ this.painAssessment.OtherIntervention +"\n";
        textResult +=  this.$t('modalMarsMed.InterventionTime') +" "+ this.painAssessment.InterventionTime +"\n";

        textResult +=  this.$t('modalMarsMed.Notes') +" "+ this.painAssessment.Notes +"\n";

        textResult+="\n";
        textResult+= this.$t('modalMarsMed.Intervention') +"\n";
        textResult += this.painAssessment.Intervention.join(",") +"\n";

        


        return textResult
      },

    ChangeDropDownTherapistCode(item){
        this.painAssessment.TherapistCode = item ? item.code : '';
    },

    ChangeDropDownCharacter(item){
        this.painAssessment.PainCharacter = item ? item.value : '';
    },

    ChangeDropDownFrequency(item){
        this.painAssessment.Frequency = item ? item.value : '';
    },

    OnChangeIntervention(){

    },

    getTherapists(){
        TherapistService.getAllTherapist()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              let arr = response.data;
              for(var i = 0; i<arr.length; i++){
                arr[i].fullName = arr[i].firstName+" "+arr[i].lastName;
              }
              this.therapistList = arr;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('MdPainAssessmentNotesOutCome.GetTherapistsMsg'),error,"error");
            } 
          });  
      },

      onSignPassword(value) {
        if(this.painAssessment.TherapistCode) {
            this.showSignPassword = value;
        } else {
            this.painAssessment.IsSigned = false
            this.showMessage(this.$t('Shared.VerifyPassword'),this.$t('MdPainAssessmentNotesOutCome.TheraRequiredMsg'),"error");
        }
      },

      onModalPasswordResult(result) {
       // console.log(result);
       // this.painAssessment.IsSigned = result;
        if(result.status != true) {
          this.showMessage(this.$t('Shared.VerifyPassword'),result,"error");
        }
        this.showSignPassword = false;
      },

    pageChanged (page) {            
      this.currentPage = page; 
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

      onCodeDescriptionOk(result){
        this.Allergy.allergyCode = result.code;
        this.getLookupFieldsDesc(result.code);
      },

      formatterDate(value){
         return Utils.formatterDate(value);
      },

      SaveOrEdit(){
        if(this.painAssessment.IsSigned) { 
          this.pendingChanges = false;          
        } else {
            this.showMessage(this.$t('MdPainAssessmentNotesOutCome.PAssessmenTitle'),
                          this.$t('MdPainAssessmentNotesOutCome.SingBeforeMsg'),"error");
        }
      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
  },

  mounted() {
    this.getTherapists()
    this.$emit('load', this.Loading); 
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
};

</script>