<template>
    <div>
     <b-modal :id="ModalId" :visible="true" scrollable centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalSignPatientGuardian" @close="this.emitEventCancel" @ok="this.emitEventOK" @cancel="this.emitEventCancel" style="padding:0.5px 20px" :cancel-title="$t('Shared.BtnCancel')"> 
        <!--<div v-for="(s, sigindex) in this.Signatures" :key="'signature_'+sigindex">-->
            <!--<div class="card bg-light">
                <div class="card-body">  
                  
                 
                </div>
            </div> --> 
            <b-row v-if="ShowGuardianDrop">
              <b-col lg="12" md="12" sm="12">         
                <MdSelect
                    v-bind:Label="$tc('Contact.Contact')"
                    v-bind:Options="GuardianList"
                    v-bind:FieldDescription="'contactName'"
                    v-bind:FieldCode="'recNo'"                      
                    v-bind:Model="GuardianModel"                                 
                    v-bind:Multiple="false"
                    :Input="ChangeDropDownGuardian"
                />
            </b-col>
            </b-row>
            <MdSignature              
              v-bind:Data64="this.Image" 
              v-bind:Id="'Patient_Contact_Signature'" 
              v-bind:Title="this.Option" 
              v-bind:VisibleLoadImage="false">

            </MdSignature>        
        <!--</div>  -->      
     </b-modal>        
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import ContactService from '@/services/contact.service'
 import { EventBus } from '@/event-bus.js';

 
 export default {
   name: 'mdModalSignPatientGuardian',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     VisibleGuardian:Boolean,
     TitleWindow: String,  
     Option: String,
     Image:String,  
     ItemChecked:Object, 
     ShowGuardianDrop:Boolean
   },
   
   data(){
     return {  
      GuardianList:[], 
      GuardianModel:{contactName:"",recNo:""},  
      Base64:"",   
     }
   },
 
   methods:{   
     emitEventOK(/*bvModalEvent*/){

      let data = {};
      if(this.ShowGuardianDrop){
        data.base64 = this.Base64;
        data.guardianCode = this.GuardianModel.recNo;
      }else{
        data.base64 = this.Base64;
        data.guardianCode = "";
      }
      this.$emit('ModalTPSignPatientGuardianWizardOK',data);
     },
       
     emitEventCancel(){
        let data = {
            item:this.ItemChecked,
            option:this.Option
        }
      this.$emit('ModalTPSignPatientGuardian',data);
    }, 
    ChangeDropDownGuardian(value){      
      this.GuardianModel = value ? value :{};
    },

    async getContactByPatient() {
            this.$emit('load', true);
            await ContactService.getByCode(this.$route.params.id)
                .then((response) => {
                    if (response.status == '200' && response.data) {
                        this.GuardianList = response.data;      
                        
                        if(this.GuardianList.length === 1){
                          this.GuardianModel.contactName = this.GuardianList[0].contactName;
                          this.GuardianModel.recNo = this.GuardianList[0].recNo;
                        }
                    }
                    this.$emit('load', false);
                })
                .catch(error => {
                    this.$emit('load', false);
                    if (!error.response) {
                        this.showMessage(this.$t('modalSignPatientGuardian.tPlanGuar'), this.$t('Msg.retrieveInfo'), "error");
                    }
                    else if (error.response.status === 400) {
                        this.showMessage(this.$t('modalSignPatientGuardian.tPlanGuar'), error.response.data.errorMessage, "error");
                    } else if (error.response.status === 500) {
                        this.showMessage(this.$t('modalSignPatientGuardian.tPlanGuar'), this.$t('Msg.ThereWereSomeError'), "error");
                    } else {
                        this.showMessage(this.$t('modalSignPatientGuardian.tPlanGuar'), error.response.data.errorMessage, "error");
                    }
                });
        },
 
   
   },

   created(){
    EventBus.$on("onMouseStop", function (image) { 
      this.Base64 = image ? image.split(",")[1] : "";      
     }.bind(this));
   },

  async mounted(){
    if(this.ShowGuardianDrop){
      await this.getContactByPatient();
    }
   },

   beforeDestroy () {     
    EventBus.$off('onMouseStop');    
  },
 };
 </script>