<template>
  <div>
      <b-row>
        <b-col md="12">   
            <b-table
                :ref="refTable"
                selectable
                :small="small"
                :sticky-header="stickyHeader"
                class="medezTables cutescroll"
                :select-mode="'multi'"               
                :items="items"
                :fields="fFields"
                :current-page="cPage"
                :show-empty="showMessageIsEmpty"
                @row-selected="rowSelected"            
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"
                @row-dblclicked="ondblClicked"
                :tbody-tr-class="trClassFn"
                :empty-text="$t('MdTable.nRecShow')"
            >     
               
                <template v-if="editIconEvent || deleteIconEvent || viewIconEvent || otherIconEvent" #cell(aaaa)="row">              
                    <b-icon v-if="editIconEvent && isReadOnly" icon="eye-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="editIconEvent && !isReadOnly" icon="pencil-fill" @click="editButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" v-b-tooltip.hover.top="$t('Shared.Delete')" class="rounded-circle tableActionBTN-danger ml-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="viewIconEvent" icon="play-fill" @click="viewButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.View')" class="rounded-circle tableActionBTN" scale=".5" font-scale="1.8" variant="light"></b-icon>
                    <b-icon v-if="otherIconEvent" :icon="otherIcon" @click="otherButton(row.item, $event.target)" v-b-tooltip.hover.top="$t('Shared.Report')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>                     
                </template>


                <template v-if="ShowDetails" #cell(ErrorDetails)="row">
                  <!--<b-icon v-b-tooltip.hover :title="row.item.ErrorDetails" icon="eye-fill" @click="CallModalTable(row.item)" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>-->
                   <b-icon icon="eye-fill" @click="CallModalTable(row.item)" v-b-tooltip.hover.top="$t('Shared.Edit')" class="rounded-circle tableActionBTN mr-1" scale=".5" font-scale="1.8" variant="light"></b-icon>
                </template>
                

                <template v-if="ShowDetails" #cell(Details)="row">
                  <b-form-checkbox class="expandNotes" v-model="row.detailsShowing" @change="row.toggleDetails" @input="Check($event,row)"></b-form-checkbox>                 
                </template>

           

                 <template v-if="ShowDetails" #row-details="row">   
                    <b-table
                      :ref="refTable"
                      selectable
                      :small="small"
                      sticky-header
                      class="medezTables TableClaimBatchDetailts cutescroll"
                      :select-mode="'multi'"               
                      :items="row.item.attendanceDetailList"
                      :fields="FieldsDetails"
                      :current-page="cPage"                     
                      :show-empty="showMessageIsEmpty"
                      @row-selected="rowSelected"
                      @row-clicked="rowClicked"
                      responsive
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="filtered"
                      @row-dblclicked="ondblClicked"
                      :tbody-tr-class="trClassFn"
                      :empty-text="$t('MdTable.nRecShow')"
                    >   
                    </b-table>  





                     <!--<div class="flex-container">
                      <div class="row">
                         <div class="col-md-4" v-for="(i,index) in row.item.details" :key="index">
                            <div class="col-md-12 grid-margin stretch-card">
                              <div class="card activeCard">
                                <div class="card-body">
                                  <div class="d-flex align-items-start justify-content-between pointer">
                                      <h6  class="card-title flex-grow lower-line text-truncate"><input type="checkbox" v-model="i.Checked"/>Details</h6>
                                      <i class="mdi mdi-arrow-right-drop-circle text-corporate"></i>
                                  </div>                     
                                <div class="text-muted p-fixed cutescroll">                                 
                                  <ul>							
                                    <li>Code: {{i.Code}}</li>		
                                    <li>Date: {{i.Date}}</li>		
                                    <li>Therapy: {{i.Therapy}}</li>				
                                    <li>Therapist: {{i.Therapist}}</li>				
                                    <li>IN: {{i.IN}}</li>	
                                    <li>Billed: {{i.Billed}}</li>				
                                    <li>Unit: {{i.Unit}}</li>												
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>   
                      </div>
                     </div>  -->
                  <b-button class="btn-login" size="sm" @click="row.toggleDetails">{{$t('MdTable.HideDetailsTxt')}}</b-button>   
                </template>
            </b-table>
        </b-col>    
    </b-row>
   
    <ModalClaimBatchError                   
            @getCodeConditionOK="OnReturnData($event)"
             v-bind:TitleWindow="$t('MdTableClaimBatch.ErrorDetailsMsg')"   
            >
   </ModalClaimBatchError>

  </div>
</template>

<script>


import ShowMessage from '@/components/messages/ShowMessage.js'
//import moment from 'moment'
//import GlobalService from '@/services/global.service'
import { EventBus } from '@/event-bus.js';

  export default {
    mixins: [ShowMessage],
    props: {
     items:  Array,   
     fields: Array,
     FieldsDetails: Array,
     totalRows: Number,
     totalRowsSubItems:Number, 
     editIconEvent: Function,
     deleteIconEvent: Function,
     showMessageIsEmpty: Boolean,
     ShowDetails:Boolean,
     onRowSelected: Function,
     ondblClick:Function,
     currentPage: Number,
     currentPageSubItems:Number,
     onFiltered: Function,
     perPage: Number,
     perPageSubItems:Number,
     pageChanged: Function,
     pageChangedSubItems:Function,
     pageOptions: Array,
     pageOptionsSubItems: Array,
     perPageFunction: Function,
     perPageFunctionSubItems: Function,
     otherIconEvent: Function,
     otherIcon: String,
     viewIconEvent: Function,
     refTable: String,
     subItems: Array,
     multiSelect: Boolean,
     avisar:Function,
     CheckClick:Function,
     small: Boolean,
     stickyHeader: [Boolean,String],
     isReadOnly:Boolean,
     trClassFn: Function,
     RowClicked: Function,
     SelectAll: Boolean,

   },
    data() {
      return {
        modalId:'ModalErrorDetailsClaimBatch',
        ActionsList:[{'code':1,'name':this.$t('Shared.Doctors')},
                      {'code':2,'name':this.$tc('Insurance.Insurance')},
                      {'code':3,'name':this.$tc('MdPatientDiagCodes.Diagnosis')},
                      {'code':4,'name':this.$tc('MdPatientInfoBox.PatientInfo')}],
          filter: null,
          filterOn: [],          
          pPage: this.perPage,
          pPageSubItems: this.perPageSubItems,
          cPage: this.currentPage,
          cPageSubItems: this.currentPageSubItems,         
          fFields: this.fields,         
          CountNotesOpened:0,
          CountSubItems:0,
          DateTimeNow:null,
          TRSubItems:0,
          CPSubItems:0,
          CurrentPage : 1          
      }
    },
    
    methods:{

    editButton(row) {
        this.editIconEvent(row);
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },

    rowSelected(items) {     
        this.onRowSelected(items);
    },

    CallModalTable: async function() {         
      this.$emit('load', true);
      this.$bvModal.show("ModalErrorClaimBatch");
      this.$emit('load', false);
     },

     OnReturnData(){

     },

    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },
     fpageChangedSubItems(page) {
        this.pageChangedSubItems(page);
    },

    SubItemsPageChanged(e){     
    this.pageChangedSubItems(e);
    },

    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },
    pageFunctionSubItems(perpage) {
        this.perPageFunctionSubItems(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    viewButton(row) {
      this.viewIconEvent(row);
      EventBus.$emit('shwoHideFavorite', false);
      EventBus.$emit('onUpdatedMenu', '');
    },

    ondblClicked(item){    
       this.ondblClick(item);
    },
    rowClicked(){
      this.RowClicked();
    },

    Check(e,row){
      if(e){
         this.IsCheckBoxClicked(row);
      }
    },

      async IsCheckBoxClicked(row) {     
      var SendObject = {       
        notesId:row.item.notesId,
        currentPage:1,
        perPage:10      
      }       
        this.CheckClick(SendObject);
      },



     
 },

 watch: {
  SelectAll: function (val) {      
       val ? this.$refs[this.refTable].selectAllRows() : this.$refs[this.refTable].clearSelected();
    }
  },

  created() {  
     //this.fFields.unshift({ key: 'Selected', label: "<input type='checkbox'>"});  
   /* if(this.subItems) {
      this.fFields.unshift({ key: 'show_details'});
    }    

    if(this.editIconEvent || this.deleteIconEvent || this.viewIconEvent || this.otherIconEvent) {
      if(!this.isReadOnly){
        this.fFields.unshift({ key: 'actions', label: 'Actions'});
      }      
    }
    EventBus.$on("onSetReadOnly", function (readOnly) {       
      if(!readOnly){
        this.fFields.unshift({ key: 'actions', label: 'Actions'});
      } 
     }.bind(this));  
    this.fMultiSelect = this.multiSelect ? 'multi' : 'single';*/
  }, 
}

</script>

<style>
.TimeNotes{
 border: 1px solid #F3F3F3;
 background-color: #f9fbfc;
 color: #999;
 padding: 8px;
 border-radius: 6px;
 margin: 4px;
 cursor: pointer;
 transition: all 0.1s ease-out;
}
.addNotesBtn{
  background-color:#19BEE8;
  margin-right: 4px;
	border-radius:10px 2px;
	cursor:pointer;
  vertical-align: top;
	color:#ffffff;
	padding:4px 11px;
	text-decoration:none;
  border: 0;
}
.addNotesBtn:focus{
	background-color: #19BEE8 !important;
  color: #FFF !important;
}
</style>
