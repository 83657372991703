<template>
  <div id="ContainerReportList">
    <div class="row">
        <div class="col-12">
          <MdTitle v-bind:Text= "$t('Shared.Reports')"/>
          <div class="row">
              <div class="col-sm-12 col-md-8 mb-4">
                  <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                      <div class="input-group">
                          <input 
                            type="text" 
                            class="form-control"
                            v-model="paramReports.SearchCriteria"
                            @keyup.enter="SearchReports()"
                          >
                          <div class="input-group-append">
                              <MdButton 
                                v-bind:Name="'btnsearchinput'"
                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                v-bind:IconClass="'mdi mdi-magnify'"
                                v-on:click="SearchReports">
                              </MdButton>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-sm-12 col-md-4">                      
                  <MdSelect
                  v-bind:Label="$t('ReportList.RepCategory')"
                  v-bind:Options="CategoryOptions"
                  v-bind:ModelArray="ModelCategory"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"                      
                  :Input="ChangeCategory"
                  v-bind:Multiple="false"
                  v-bind:AllowEmpty="false"/>
              </div>
          </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div>
              <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <MdTable 
                  v-bind:fields="fields"
                  v-bind:items="items"
                  v-bind:totalRows="totalRows"
                  v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                  v-bind:currentPage="currentPage"
                  v-bind:perPage="perPage"
                  v-bind:pageOptions="pageOptions"
                  v-bind:refTable="refTable"
                  :viewIconEvent="GoToReport"
                  :onRowSelected="onRowSelected"
                  :onFiltered="onFiltered"
                  :pageChanged="pageChanged"
                  :perPageFunction="PerPageFunction">
                </MdTable>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import ReportByCategoriesService from '@/services/reportbycategories.service'
import ReportCategoriesService from '@/services/reportcategories.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  // beforeRouteEnter(to, from, next) {
  //       next(async vm => {
  //           const iPI = await vm.CanView();
  //           if(iPI) next()
  //           else next('/accessdenied');
  //       }) 
  // },
  mixins: [ShowMessage, DBFunctions],
  name: 'ReportList',
  components: {},
  props: {
    category: String
  },
  data() {
    return { 
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
      },
      ShowMessageIsEmpty:false,
      ModelCategory:null,
      CategoryOptions:[],       
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
      },
      paramReports:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        CategoryCode:''
      },
      fields: [
        { key: 'name', sortable: true, label: this.$t('Shared.Name'), class: 'text-rigth' },         
        { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth' },        
        { key: 'category', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' },
      ],

      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      items: [],
      selected: [],
      Header:{},
      refTable: 'selectableTable',
    }
  },

  watch: {
    category(newValue) {
      this.loadCategory(newValue)
    }
  },
 
  methods: {

    loadCategory(category) {
      if(category) {
        this.ModelCategory = this.findNoteTypeName(this.CategoryOptions,category)
        this.paramReports.CategoryCode = this.ModelCategory.code
      }
      this.getReports()
    },
    
    pageChanged (page) {            
      this.currentPage = page;
      this.getReports();
    },

    PerPageFunction(perpage){
      this.perPage = perpage;
      this.currentPage = 1;
      this.getReports();
    },

    getReports() {
      this.$emit('load', true)               
      this.paramReports.PageSize = this.perPage;
      this.paramReports.PageNumber = this.currentPage;
      if (this.paramReports.CategoryCode == "Power BI"){
        this.$router.push({name: "reportListPowerBI"}).catch(()=>{})
      }
      else {
        ReportByCategoriesService.getAll(this.paramReports)
        .then((response) => {
         if(response.status == '200' ){
            this.items = response.data;
            this.$emit('load', false);
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
         }
          this.$emit('load', false);
          if(this.items.length === 0){
            this.ShowMessageIsEmpty = true;
          } 
        })
        .catch(error => {
          this.$emit('load', false);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('Shared.ReportList'),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$t('Shared.ReportList'),error,"error");
          }
        });
      }   
    },

    async getReportCategories() {               
      this.$emit('load', true)
      await ReportCategoriesService.getAll(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
          this.CategoryOptions = response.data;
          this.CategoryOptions.unshift({code: "ALL", name: this.$t('Shared.All')}) 
          this.$emit('load', false);
       }
        this.$emit('load', false);
      })
      .catch(error => {
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('Shared.ReportList'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('Shared.ReportList'),error,"error");
        }
      });
    },

    GoToReport(item) {
      this.$router.push(item.itemLauncher).catch(()=>{});
    },

    onRowSelected(items) {
      this.selected = items
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    SearchReports(){
      this.items = []
      this.currentPage = 1;
      this.paramReports.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getReports();
    },

    ChangeCategory(item){
      this.totalRows = 0;
      this.ModelCategory = item ? item : ''
      
      if (item.code === 'ALL')
        this.paramReports.CategoryCode = ''
      else
        this.paramReports.CategoryCode = item.code
      
      this.SearchReports();
    },

    findNoteTypeName (array, code) {
      return array.find(element => element.code === code) ?? -1;
    },
  },
  async mounted() {
    await this.getReportCategories()
    this.loadCategory(this.category)
    
    this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }    
  },
};
</script>

