<template>
  <div class="fileDropArea" v-cloak @drop.prevent="addFileDragAndDrop($event)" @dragover.prevent>
  <div style="display:flex; justify-content:start; flex-wrap:wrap; padding:10px;">
    <div v-if="!ReadOnly" class="dropfiles" @click="AreaGreenClick"><input type="file" id="upload" ref="input" hidden multiple="multiple" @change="addFileDragAndDrop($event)"/><i class="icon-cloud-upload"></i> {{$t('PatientDocument.DaDmsg')}}</div>
    <Loading v-bind:IsLoading="this.Load" />   
  <div class="fileGroup" v-for="(file,index) in files" :key="index">
      <div class="marquee fileInfo"><p>► {{file.Image}} ({{file.extension}}): {{/*file.description == '' ? */Text/* : file.description*/}}</p></div>
    <b-row class="uploaded_files text-center" align-v="center" :data-extension="file.extension">
      <b-icon icon="file-earmark-arrow-down" v-on:click="OpenDocument(file)" font-scale="3" :class="file.Image + ' rounded-circle p-2 pointer'" variant="light"></b-icon>
    
      <b-dropdown split :text="file.fileName" class="ml-1" variant="light" @input="FileChanged()" menu-class="filesdropmenu" v-on:click="PatientDocumentView(file)">
        <b-dropdown-form v-on:submit.prevent>

  
          <MdEdit 
          v-bind:Label="$t('HouseFile.FileName')"
          v-bind:TypeProp="'text'" 
          v-bind:Name="'fileName'"
          v-model="file.fileName"
          @input="FileChanged()"
          v-bind:isReadOnly="ReadOnly"
          v-bind:Id="'input_fileName'">                                     
        </MdEdit>
             
         
          <b-button href="#" variant="secondary" class="mt-2" @click="removeFile(file)" :disabled="ReadOnly">{{$t('Shared.Delete')}}</b-button>
        
        </b-dropdown-form>
      </b-dropdown>
    </b-row>
  </div>


  <MdPasswordDlg v-if="showSignPassword && ModeEdit" 
    v-bind:Type="TypePassWord"
    v-bind:VerificationType="'SupervisorPassword'"
    @getModalPassword="onModalPasswordResult($event)"
  />

        <DxPoPup v-if="isVisible" 
:Title="'Patient Documents'"
:closeWindow="closeRepWindow"
:isVisible="isVisible"
:Url="this.FullUrl"
/>
  </div>
  </div>
</template>
  
  <script>
  import { EventBus } from '@/event-bus.js';
  import DocumentManagement from '@/services/documentmanagement.service'
  import Utils from '@/common/utils'
  import documents from "../../../assets/images/filetypes/documents.jpg"
  import graphics from "../../../assets/images/filetypes/graphics.jpg"
  import compressed from "../../../assets/images/filetypes/compressed.jpg"
  import audio from "../../../assets/images/filetypes/audio.jpg"
  import video from "../../../assets/images/filetypes/video.jpg"
  import models from "../../../assets/images/filetypes/models.jpg"
  import spreadsheets from "../../../assets/images/filetypes/spreadsheets.jpg"
  import diskimages from "../../../assets/images/filetypes/diskimages.jpg"
  import unknown from "../../../assets/images/filetypes/unknown.jpg"
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import Loading from '@/components/template/loading/Loading'
  //import ApiService from '@/services/api.service'
    export default {
      props:{
        Records:Array,
        IsReadOnly:[Boolean],  
        DocType:String,
        DocCode:String,
        UserId:String,
        DocumentTypeList:Array,
        ModeEdit:Boolean
        },
          mixins: [ShowMessage],
      components: {
      Loading
    },
      data: function () {
        return {
          Text:'',
          Load:false,
          FullUrl:"",
          UserIdSelected:'',
          TypePassWord:'Sign',
          showSignPassword:false,
          ExpirationDate:null,
          files:this.Records,
          fileSelected:{},
          isVisible:false,
          Doc:{},
          ReadOnly:this.IsReadOnly,
          OptionExpired:[], 
          modalIdDocuments:"modalIdPatientDocument", 
          ExpiredVModel:"",      
          documents: documents
        };
      },
      watch: {
        IsReadOnly(value) {
            this.ReadOnly = value;
        }
      },
      methods: {
        EditName(file){
          document.getElementById(file.code).readOnly = false;
          document.getElementById(file.code).focus();
        },
        ReturnImage(img){
          if(img === 'documents')
            return documents;
          else if(img === 'graphics')
            return graphics;
          else if(img === 'compressed')
            return compressed;
          else if(img === 'audio')
            return audio;
          else if(img === 'video')
            return video;
          else if(img === 'models')
            return models;
          else if(img === 'spreadsheets')
            return spreadsheets;
          else if(img === 'diskimages')
            return diskimages;
          else
            return unknown;
        },

        closeRepWindow() {
        this.isVisible = false;
      },
  
        RemoveFocus(file){
          document.getElementById(file.code).readOnly = true;
        },
  
        AreaGreenClick(){
          this.$refs.input.click();
          this.$refs.input.value = "";
        },

        ChangeDropDownDocumentTypes(e,i){        
          this.files[i].doccode = e.code;
          this.files[i].description = e.description;
        },

  
   SyncFileReader(file) {
      let self = this;
      let ready = false;
      let result = '';
  
      const sleep = function (ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
  
      self.readAsDataURL = async function() {
          while (ready === false) {
            await sleep(100);
          }
          return result;
      }    
  
      const reader = new FileReader();
      reader.onloadend = function(evt) {
          result = evt.target.result;
          ready = true;
      };
      reader.readAsDataURL(file);
    },
  
    ExistFile(name){
      var l = this.files.length;
     for(var i = 0; i < l; i++){
          var extension = this.files[i].extension;
          var fullname = this.files[i].name+extension.toLowerCase();
          if(name === fullname){
            return true;
          }
          
      }
      return false;
    },

    OpenModal(file){     
      this.Doc = file;
      this.$bvModal.show(this.modalIdDocuments);       
    },

    VerifyExtension(ext){
      if(ext){
        if(ext.toLowerCase() === ".pdf" || ext.toLowerCase() === ".jpeg" ||
       ext.toLowerCase() === ".jpg" || ext.toLowerCase() === ".png" || 
       ext.toLowerCase() === ".bmp" || ext.toLowerCase() === ".svg" ||
       ext.toLowerCase() === ".gif" || ext.toLowerCase() === ".eps"){
        return true;
       }
       return false;
      }
      return false;
    },
  
   async addFileDragAndDrop(e) {
       let droppedFiles = e.type === 'drop'?e.dataTransfer.files:e.target.files;     
       var extension = "";
        if(!droppedFiles) return;
        if(this.ReadOnly){
          this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.NoteRead-only'),"error");
        }else{
          for (var i = 0; i < droppedFiles.length; i++) {
          var filename = droppedFiles[i].name;
          var tmppath = URL.createObjectURL(droppedFiles[i]);
          var size = droppedFiles[i].size;
          if(size !== 0){
            if(!this.ExistFile(filename)){
          
          //var fileNameNoExtension;
          var pos = filename.lastIndexOf('.');
          if(pos !== -1){
            //fileNameNoExtension = filename.substring(0, pos);
            extension = filename.substring(pos).toUpperCase();
          }
          var Image = Utils.getType(extension);
          var type = droppedFiles[i].type;
          var code = this.getUniqueID();
          const fileReader = new this.SyncFileReader(droppedFiles[i]);
          const arrayBuffer = await fileReader.readAsDataURL();
          if(arrayBuffer.indexOf(',') !== -1){
             var b = arrayBuffer.split(',');         
             this.files.push({comments:null,expirationDate:null,Url:tmppath,sysuser:this.UserId,entitytype:"P",doccode:this.DocCode,"fileName":filename,entitycode:this.$route.params.id,"Image":Image,"extension":extension,"contents":b[1],"description":this.DocType,"code":code,"type":type,"IsNew":true,"isreadonly":true});
             this.$emit('UpdateFiles', this.files);
             this.FileChanged();
          }
          }else{
             this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.DuplicateFieldMsg'),"error");
          }
          }else{
             this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.FileNoBytesMsg'),"error");
          }  
        }
        }
        
      },
  
      getUniqueID(){
        return Date.now() + ( (Math.random()*100000).toFixed());
      },

      async Download(url, filename) {          
           const data = await fetch(url)
           const blob = await data.blob()
           const objectUrl = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.setAttribute('href', objectUrl)
          link.setAttribute('download', filename)
          link.style.display = 'none'
          document.body.appendChild(link)                          
          link.click()                          
          document.body.removeChild(link)
      },


      getPatientDocumentUrl(row,operation){
        var baseurl = this.$store.state.apiurl.replace('/api/', '');   
        if(!row.IsNew){
                  DocumentManagement.GetPatientUrl(row.seqno)
              .then((response) => {
                if(response.status == '200'){ 
                  var data = response.data  
                  this.FullUrl = baseurl + data.url;        
                  if(operation === 'Open'){  
                    this.Doc = row; 
                      if(this.VerifyExtension(row.extension)){          
                          this.isVisible = true;  
                          this.Load = false;
                        }else{                        
                              let a = document.createElement("a");
                              a.href = this.FullUrl;              
                              a.click();
                        }           
                  }else{ 
                    this.Download(this.FullUrl,row.fileName);
                  }
                }
                this.$emit('load', false);
          
              })
              .catch(error => {
                if (!error.response) {                      
                              this.showMessage(this.$t('Msg.GetDocument'),this.$t('Msg.retrieveInfo'),"error");
                            }                      
                            else if(error.response.status === 400 ){
                              this.showMessage(this.$t('Msg.GetDocument'),error.response.data.errorMessage,"error");
                            } else if(error.response.status === 500){
                                this.showMessage(this.$t('Msg.GetDocument'),this.$t('Msg.ThereWereSomeError'),"error");
                            } else{
                                this.showMessage(this.$t('Msg.GetDocument'),error.response.data.errorMessage,"error");
                            } 
                            this.$emit('load', false);
              });
        }else{        
              if(this.VerifyExtension(row.extension)){
                if(operation !== 'Download'){
                  this.FullUrl = row.Url; 
                  this.isVisible = true;  
                  this.Load = false;
                }else{
                  this.Download(this.FullUrl,row.fileName);
                }
                 
                }else{
                      var data64 = row.contents;             
                      let a = document.createElement("a");
                      a.href = "data:"+row.type+";base64," + data64;
                      a.download = row.fileName+row.extension;
                      a.click();
                      this.Load = false;
                }
        }       
      },

      onModalPasswordResult(result){      
      if(result.Event === 'Ok'){   
        if(result.status === true) {
         this.$emit('load', true);         
          const index = this.files.findIndex(item => item.code === this.fileSelected.code); 
                  if (~index) {                   
                    this.files.splice(index, 1);                   
                  }
                  
                  this.$emit('UpdateFiles', this.files);
                  this.FileChanged();


        }
      }else{
        this.showMessage(this.$t('PatientDocumentList.DeleteDocument'), this.$t('Msg.InvalidSupervisorPass'),"error");
      }
      this.showSignPassword = false;
    },
  
      removeFile(file){
          this.$bvModal.msgBoxConfirm(this.$t('MdNotesInFiles.DeletFileMsg'), {
            title: this.$t('MdNotesInFiles.DeletFile'),
            okTitle: this.$t('Shared.TitleDelete'),
            cancelTitle: this.$t('Shared.BtnCancel'),
            footerClass: 'p-2',
            titleClass: 'modal-title modal-type-warning',
            id:'modal_delete_file'
         })
            .then(value => {
              this.fileSelected = file;
              if(value){
                if(this.ModeEdit){
                  this.showSignPassword = true;  
                }                 
                else{
                  this.files = this.files.filter(item => item !== file);
                  this.$emit('UpdateFiles', this.files);
                  this.FileChanged();
                }                  
              }
            })     
      },
   
    openBase64InNewTab (data, mimeType) {
      var byteCharacters = atob(data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: mimeType + ';base64' });
      var fileURL = URL.createObjectURL(file);   
      window.open(fileURL);
  },
  

  PatientDocumentView(row){       
      this.seqNo = row.seqno;   
      this.getPatientDocumentUrl(row,'Open');
  },
  
  
    OpenDocument(file){  
      this.getPatientDocumentUrl(file,'Download'); 
    },
  
    SendFiles(files){
      this.$emit('UploadFiles', files);
    },
  
    FileChanged(){
      this.$emit('FileChanged', true);
    }
  
      },
      created(){
        EventBus.$on("onChangedFiles", function (files) {       
           this.files = files;
      }.bind(this));
      },

      mounted(){    
        this.Text = this.$t('MdNotesInFiles.Text2');
      }
    };
  </script>
  
  <style>
  .ng-invalid {
    border: 1px solid red !important;
  }
  
  .AutoHeightContainerMoreFiles{
  height: calc(100%  + 250px) !important;
  }
  
  .AutoHeightContainerMinusFiles{
  height: 550px!important;
  }
  
  .fileDropArea{
    height: calc(100vh - 224px) !important;
  }
  </style>
  