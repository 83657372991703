<template>
    <div id="ContainerAllergySetup page-height">
        <h4 v-if="EditMode" class="page-title text-truncate">
            {{$t('AllergySetup.Edit')}}
        </h4>
        <h4 v-if="!EditMode" class="page-title text-truncate">
            {{$t('AllergySetup.Add')}}        
        </h4>

        <b-row>
            <b-col lg="4" md="4" sm="12">
                <MdEdit
                    v-model="Allergy.code"                    
                    v-bind:Label="$t('Shared.Code')"          
                    v-bind:isReadOnly="EditMode || IsReadOnly"
                    v-bind:isRequired="true"
                    v-bind:MaxLengthProp="16"
                    v-bind:isValid="isCodeValid"
                    v-on:blur="OnCodeBlur(Allergy.code)"
                    :InvalidFeedback="$t('AllergySetup.CodeRequired')"
                    :SearchFieldCode="'code'"
                    v-bind:Id="'input_allergy'"/>
            </b-col>
            <b-col lg="8" md="8" sm="12">
                <MdEdit
                    v-model="Allergy.description"                    
                    v-bind:Label="$t('Shared.Description')"          
                    v-bind:isReadOnly="IsReadOnly"
                    v-bind:isRequired="true"
                    v-bind:MaxLengthProp="100"
                    v-bind:isValid="isDescriptionValid"
                    :InvalidFeedback="$t('AllergySetup.NotesRequired')"
                    :SearchFieldCode="'description'"
                    v-bind:Id="'input_description'"/>
            </b-col>
        </b-row>

        <b-row>
            <b-col lg="4" md="4" sm="12">
                <MdSelect
                    v-bind:Label="$t('Shared.Category')"
                    v-bind:Options="CategoryList"
                    v-bind:FieldDescription="'displayName'"
                    v-bind:FieldCode="'code'"
                    v-bind:Model="Allergy.categoryCode"                       
                    v-bind:Multiple="false"
                    v-bind:isRequired="true"
                    v-bind:IfUsevalidation="false"
                    :Input="ChangeDropDownCategory"
                    :IsDisabled="IsReadOnly"
                    v-bind:isValid="isCategoryValid"
                    v-bind:FeedBackText="$t('AllergySetup.CategoryRequired')"
                />
            </b-col>
            <b-col lg="8" md="8" sm="12">
                <MdLookup
                    v-bind:Label="$t('AllergySetup.Substance')"
                    v-bind:ModelCode="Allergy.drugCode"
                    v-bind:ModelDescription="Allergy.drugName"
                    v-bind:RefCode="'Drugs'"
                    v-bind:Api="'Drugs'"
                    v-bind:FieldCode="'drugCode'"
                    v-bind:FieldDescription="'drugname'"
                    :IsDisabled="IsReadOnly"    
                    :SearchTitle="$t('AllergySetup.Substance')"
                    :GetValues="onCodeConditionOk"
                    :InvalidFeedback="$t('AllergySetup.InvalidSubstanceCode')"
                    :SearchFieldCode="'drugname'"
                    v-bind:Fields="BaseFieldsDrugs"                     
                />
            </b-col>
        </b-row>

        <b-row>
            <b-col lg="12" md="12" sm="12">
                <MdLookup
                    v-bind:Label="$t('AllergiesList.Problem')"
                    v-bind:ModelCode="Allergy.problemId"
                    v-bind:ModelDescription="Allergy.problemDescription"
                    v-bind:RefCode="'AllergyProblem'"
                    v-bind:Api="'AllergyProblems'"
                    v-bind:FieldCode="'problemId'"
                    v-bind:FieldDescription="'displayName'"
                    :IsDisabled="IsReadOnly"    
                    :SearchTitle="$t('AllergiesList.Problem')"
                    :GetValues="onCodeConditionOk"
                    :InvalidFeedback="$t('AllergySetup.InvalidProblemCode')"
                    :SearchFieldCode="'DisplayName'"
                    v-bind:Fields="BaseFieldsProblems"                   
                ></MdLookup>
            </b-col>
        </b-row>

        <div class="space-actions"></div>
        <div class="page-actions">      
            <MdButton
                v-bind:Label="LabelCancel"
                v-bind:Name="'btnallergylist'"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="DiscardChanges"/>    
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(IsReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
                v-bind:isReadOnly="IsReadOnly"
                v-bind:Name="'btn_save_allergy'"/>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import AllergySetupService from '@/services/allergiesetup.service'
import AllergyAdverseEventTypeService from '@/services/allergyadverseeventtype.service'

let elemSaveBtn = '';
export default {
    mixins: [ShowMessage, DBFunctions],
    name: 'MdEditAllergySetup',

    props: {  
        EditMode: Boolean,  
        IsReadOnly: Boolean,
        LabelCancel: String,
        value: Object,
        SaveEventName: String,
        CancelEventName: String
    },

    data() {
        return {
            Allergy: {
                code: '',
                category: '',
                drugCode: '',
                drugName: '',
                problemId: '',
                problemDescription: '',
                description: '',
            },
            isCodeValid: true,
            isCategoryValid: true,
            isDescriptionValid: true,
            CategoryList: [],
            BaseFieldsDrugs:[
                { key: 'drugname', sortable: true, label: this.$t('drug.Drugname'), class: 'text-rigth' },     
                { key: 'form', sortable: true, label: this.$t('Medication.Form'), class: 'text-rigth'}, 
                { key: 'dosage', sortable: true, label: this.$t('Medication.Dosage'), class: 'text-rigth' },  
                { key: 'activeingred', sortable: true, label: this.$t('drug.ActIngre'), class: 'text-rigth' }, 
            ],
            BaseFieldsProblems: [
                { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth' },  
                { key: 'displayName', sortable: true, label: this.$t('Allergy.ProblemDescription'), class: 'text-rigth' },  
            ],
        }
    },

    watch: {
        value(newValue) {
            this.Allergy = newValue;
        }
    },

    methods: {
        DiscardChanges() {
            EventBus.$emit(this.CancelEventName);
        },

        SaveOrEdit(){ 
            if (this.validate_required()) {
                this.prepareItem();
                this.DoAction();
            } else {
                this.showMessage(this.$t('Allergy.Allergy'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
            }
        },

        validate_required() {       
            this.isCodeValid = this.Allergy.code.trim().length > 0 ? true : false;  
            this.isCategoryValid = this.Allergy.category?.trim().length > 0 ? true : false;
            this.isDescriptionValid = this.Allergy.description?.trim().length > 0 ? true : false;
            return this.isCodeValid && this.isCategoryValid && this.isDescriptionValid;
        },

        prepareItem() {
            if (!this.Allergy.problemId) {
                this.Allergy.problemId = null;
            }
        },

        DoAction(){       
            !this.EditMode ? this.AddAllergy() : this.EditAllergy(); 
        },

        AddAllergy(){
            if(!this.CodeExist){
                this.$emit('load', true);
                AllergySetupService
                    .addAllergy(this.Allergy)
                    .then((response) => {
                        if(response.status == '200'){
                            this.Allergy = response.data; 
                            this.pendingChanges = false;   
                            this.$emit('load', false);
                            EventBus.$emit(this.SaveEventName, this.Allergy);
                            setTimeout(() => 
                                this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                    title: this.$t('Msg.Saving'),
                                    autoHideDelay: 5000,
                                    variant: "success"
                                })
                            , 50); 
                        }                             
                    })
                    .catch((error) => {   
                        if (!error.response) {
                            this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.retrieveInfo'),"error");
                        }                      
                        else if(error.response.status === 400 ){
                            this.showMessage(this.$t('Allergy.Allergy'),error.response.data.errorMessage,"error");
                        } else if(error.response.status === 500){
                            this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.ThereWereSomeError'),"error");
                        } else{
                            this.showMessage(this.$t('Allergy.Allergy'),error.response.data.errorMessage,"error");
                        }
                        elemSaveBtn.removeAttribute("disabled");  
                        this.$emit('load', false);
                    }); 
            }else{
                this.showMessage(this.$t('Allergy.Allergy'),this.$t('AllergySetup.AllergyCodeExist'),"error");
            }
        },

        EditAllergy(){
            this.$emit('load', true);
            AllergySetupService
                .updateAllergy(this.$route.params.id,this.Allergy)
                .then((response) => {
                    if(response.status == '200'){
                        this.Allergy = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);                         
                        EventBus.$emit(this.SaveEventName, this.Allergy);                   
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                        this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Allergy.Allergy'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Allergy.Allergy'),error.response.data.errorMessage,"error");
                    }
                    elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
        },

        OnCodeBlur(value){           
            if (!this.EditMode) {           
                if (value.trim()!== '') {          
                    AllergySetupService
                        .getAllergyByCode(value)
                        .then((response) => {
                            if(response.status == '200' && response.data){
                                if(response.data.length > 0){
                                    this.showMessage(this.$t('Allergy.Allergy'),this.$t('AllergySetup.AllergyCodeExist'),"error");
                                    this.CodeExist = true;
                                }else{
                                    this.CodeExist = false;
                                } 
                            }  
                            this.$emit('load', false);                          
                        })
                        .catch((error) => {
                            this.$emit('load', false);                  
                            if(error.response.status !== 404 && error.response.status !== 500){
                                if(error.response) {
                                    this.showMessage(this.$t('Allergy.Allergy'),error,"error");
                                }
                            }
                        });  
                }
            }

            this.isCodeValid = this.Allergy.code.trim().length > 0 ? true : false; 
        },

        ChangeDropDownCategory(value) {
            this.Allergy.category = value ? value.displayName : null;
            this.Allergy.categoryCode = value ? value.code : null;
        },

        ChangeDropDownProblems(value) {
            this.Allergy.problemId = value ? value.code : null;
        },

        checkUserAction() {
            if (elemSaveBtn.disabled) {
                elemSaveBtn.removeAttribute('disabled');
            }
        },

        getAllergyCategories(){
            AllergyAdverseEventTypeService
                .getAll()
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.CategoryList = response.data;
                    }
                })
                .catch((error) => {
                    if (!error.response) {
                        this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                        this.showMessage(this.$t('Allergy.Allergy'),error,"error");
                    }
                });
        },

        onCodeConditionOk(result) {
            var type = result.FieldName;
            if (type.indexOf('Drugs') >= 0) {
                this.Allergy.drugName = result.description;
                this.Allergy.drugCode = result.code;
            } else if (type.indexOf('AllergyProblem') >= 0) {
                this.Allergy.problemDescription = result.description;
                this.Allergy.problemId = result.code.toString();
            }
        }
    },

    created(){
        EventBus.$on("onChanged", function () {       
            this.checkUserAction();
        }.bind(this));
    },

    mounted() {
        this.getAllergyCategories();
        setTimeout( function() {
            elemSaveBtn = document.getElementById('btn_save_allergy');
            elemSaveBtn.setAttribute('disabled','');
        }, 50, this );
    }
}
</script>