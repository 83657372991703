<template>
    <div id="ContainerIncident">
       <div class="row page-height">
          <div class="col-12">            
                <h4 class="page-title text-truncate" v-b-tooltip.hover >
                    <span>{{ModeEdit ? $t('Incident.IncEdit') : $t('Incident.IncAdd')}}</span> 
                    <span v-if="IncidentDateHeader"> - {{IncidentDateHeader}}</span> 
                    <span v-if="this.CategoryName.length > 0" > - {{this.CategoryName}}</span>
                </h4>
            <div class="alert alert-fill-warning" role="alert" v-if="IncidentItems.header.isVoid">
                <i class="mdi mdi-alert-circle"></i>
                {{ $t('Incident.IncIsVoidMsg') }}
            </div>       
       </div>
    </div>

    <div class="row">       
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Incident.IncDate')"
                v-bind:TypeProp="'date'" 
                v-bind:isRequired="true"    
                v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"           
                v-bind:isValid="isIncidentDateValid"
                v-bind:Name="'IncidentDate'"
                v-model="IncidentItems.header.incidentDate"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Incident.IncTime')"
                v-bind:TypeProp="'time'" 
                v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
                v-bind:Name="'IncidentTime'"
                v-model="incidentTimeFormatted"
                v-bind:isRequired="true" 
                v-bind:isValid="isIncidentTimeValid"
                v-on:change="ChangeIncidentTime">                                 
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Incident.IncReporter')"
                v-bind:Options="EmployeeList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                v-bind:ModelArray="Employee"   
                v-bind:Multiple="false"
                :Input="ChangeDropDownReporter"
            />
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Incident.IncReportedDate')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'ReportedDate'"
                v-model="IncidentItems.header.reportedDate"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-bind:isReadOnly="true">                                     
            </MdEdit>
        </b-col>        
    </div>
    <b-row>
        <b-col lg="3" md="3" sm="12">         
            <MdSelect
                v-bind:Label="$t('Shared.Rooms')"
                v-bind:Options="RoomList"
                v-bind:FieldDescription="'roomAndBuildingName'"
                v-bind:FieldCode="'code'"
                v-bind:isRequired="true" 
                v-bind:IsDisabled="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
                v-bind:FeedBackText="$t('rooms.roomRequired')"   
                v-bind:isValid="isRoomValid"                  
                v-bind:Model="IncidentItems.header.roomNo"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownRoom"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
            <MdSelect
                v-bind:Label="$t('Incident.IncLocation')"
                v-bind:Options="LocationList"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'code'"
                v-bind:isRequired="true" 
                v-bind:IsDisabled="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
                v-bind:FeedBackText="$t('Msg.RequiredField')"   
                v-bind:isValid="isLocationValid"                  
                v-bind:Model="IncidentItems.header.location"                       
                v-bind:Multiple="false"
                :Input="ChangeDropDownLocation"/>
            </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Incident.IncAditLocation')"
            v-bind:TypeProp="'text'" 
            v-bind:Name="'AdditionalLocation'"
            v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:MaxLengthProp="500"
            v-model="IncidentItems.header.addlLocation"
            v-bind:Id="'input_incLocation_id'">                                 
        </MdEdit>
        </b-col>  
        <b-col md="3" lg="3" sm="12" v-if="this.ModeEdit">
            <MdEdit 
                v-bind:Label="$t('Shared.Status')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'Status'"
                v-bind:isReadOnly="true"              
                v-model="IncidentItems.header.status"
                v-bind:Id="'input_incAdStatus_id'">                                 
            </MdEdit>
            </b-col>          
    </b-row>
    <MdTitle v-bind:Text="$t('ClinicalNotes.CATEGORIES')" v-bind:isSubtitle="true"/>
    <div class="row">
        <div class="col-sm-12 col-md-3">                      
        <MdButton
            v-bind:Label="$t('Incident.IncAddCategory')"   
            v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"              
            v-bind:IconClass="'ti-plus pr-1'"
            v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
            v-on:click="AddCategory"
            v-bind:Name="'btn_add_category'">
        </MdButton>
        </div>
        <div class="col-sm-12 col-md-9 mb-4">
        <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
        <div class="input-group">
            <input 
            type="text" 
            class="form-control"
            v-model="parameter.SearchCriteria">
            <div class="input-group-append">
            <MdButton 
                v-bind:Name="'btnsearchinput'"
                v-bind:VariantProp="'btn btn-sm search-attached'"
                v-bind:IconClass="'mdi mdi-magnify'">
            </MdButton>
            </div>
        </div>
        </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">                         
         <!-- <div v-if="showEmptyRecords" v-bind:style="emptyRecordsStyle" role="alert" aria-live="polite"><div class="text-center my-2">{{$t('MdTable.nRecShow')}}</div></div>-->
            <!-- div Container Boxs -->
            <div v-for="(item,index) in searchCategories(this.CategoriesItems, this.parameter.SearchCriteria)" :key="index" class="IncidentContainerBox">
                <div class="row">
                    <div class="col-md-12">
                        <div class="pull-right containerBoxTitle" v-if="IncidentItems.header.status !== 'Finalized' && IncidentItems.header.status !== 'Voided'">
                            <b-icon icon="pencil-fill" @click="EditItem(item)" v-b-tooltip.hover.top="$t('Shared.Edit')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                            <b-icon icon="trash-fill"  @click="DeleteItem(item)" v-b-tooltip.hover.top="$t('Shared.Delete')" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="grid-margin stretch-card">
                            <div class="card activeCard">
                                <div class="card-body">
                                    <div class="d-flex align-items-start justify-content-between">
                                    <h6 class="card-title flex-grow lower-line text-truncate">{{ item.categoryName }}</h6>                                        
                                    </div>							
                                    <div class="flex-container">
                                        <div class="row">
                                            <div class="col-md-3" >
                                                <div class="float-left col text-muted p-item">
                                                    <small>{{ $t('Incident.IncPrimary') }}</small><br>
                                                    <b>{{item.isPrimary ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="float-left col text-muted p-item">
                                                    <small>{{ $t('Incident.IncSeverity') }}</small><br>
                                                    <b v-if="item.severityName === '' || item.severityName === null">{{ $t('Incident.IncNoSeverityMsg') }}</b>   
                                                    <b>{{ item.severityName }}</b>                                                     
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="float-left col text-muted p-item">                                                        
                                                    <b><small>{{ $t('Incident.IncType') }}</small></b><br>                                                   
                                                    <ul>							
                                                        <li v-for="(it, index) in item.incidentTypes" :key="`it.code-${index}`">{{it}}</li>						
                                                    </ul>   
                                                </div>
                                            </div>                                              								
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="grid-margin stretch-card">
                            <div class="card activeCard">
                                <div class="card-body">
                                    <div class="d-flex align-items-start justify-content-between">
                                    <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Shared.Description') }}</h6>                                        
                                    </div>							
                                    <div class="text-muted p-fixed cutescroll autoH">
                                        <p v-if="item.incidentDescription.length === 0">{{ $t('Incident.IncNoDescMsg') }}</p>
                                        <div v-if="item.incidentDescription.length != 0" v-html="item.incidentDescription"></div>
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                    <div class="col-md-6">
                        <div class="grid-margin stretch-card">
                            <div class="card activeCard">
                                <div class="card-body">   
                                    <div class="d-flex align-items-start justify-content-between">
                                        <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncInter') }}</h6>                                        
                                        </div>                               
                                    <div class="text-muted p-fixed cutescroll autoH">
                                        <p v-if="item.interventions.length === 0 && !item.intervention">{{ $t('Incident.IncNoIntercMsg') }}</p>
                                            <ul>							
                                                <li v-for="(it, index) in item.interventions" :key="`it.code-${index}`">{{it.name}}</li>
                                                <li v-if="item.intervention">{{getOtherIntervention(item.intervention)}}</li>						
                                            </ul>
                                    </div>
                                </div>
                            </div>
                        </div>                                   
                    </div>
                </div>
                
            </div>                          
        </div>
    </div>

    <MdTitle v-bind:Text="$tc('Incident.IncParticipant',2)"  v-bind:isSubtitle="true" />

    <MdTabList v-bind:TabList="tablist">
        <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
            <div class="tab-pane fade show active" id="contentClients" role="tabpanel">
            <div class="row">
            <div class="col-sm-12 col-md-3">                      
                <MdButton
                v-bind:Label="$t('Incident.IncClientAdd')" 
                v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"                
                v-bind:IconClass="'ti-plus pr-1'"
                v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                v-on:click="AddClient"
                v-bind:Name="'btn_add_client'">
                </MdButton>
            </div>
            <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                <input 
                    type="text" 
                    class="form-control"
                    v-model="participantClientSearch">

                <div class="input-group-append">
                    <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'">
                    </MdButton>
                </div>
                </div>
            </div>
            </div>
            </div>
           
            <div class="row">
                <div class="col-12">  
                         <!-- div Container Boxs -->
                    <div v-for="(item,index) in this.searchParticipant(this.ClientItems, participantClientSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="pull-right containerBoxTitle" v-if="IncidentItems.header.status !== 'Finalized' && IncidentItems.header.status !== 'Voided'">
                                    <b-icon icon="pencil-fill" @click="EditClient(item)" v-b-tooltip.hover.top="$t('Shared.Edit')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                                    <b-icon icon="trash-fill"  @click="DeleteClient(item)" v-b-tooltip.hover.top="$t('Shared.Delete')" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }} - {{ item.entityCode }} - {{$t('Shared.Dob')}} {{item.dob}} </h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $tc('Shared.Program') }}</small><br>
                                                            <b v-if="item.programName === null || item.programName === ''"> No Program </b>     
                                                            <b> {{item.programName}} </b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>                                                   
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ? $t('Shared.TitleYes') : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>	
                                            
                                            <!--
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null">{{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>-->

                                            <div class="text-muted p-fixed cutescroll autoH">
                                                <b v-if="item.personalStatement === null">{{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                {{ item.personalStatement }}
                                            </div>


                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartComments') }}</h6>                                        
                                            </div>

                                            <!--<div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoObserMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>-->

                                            <div class="text-muted p-fixed cutescroll autoH">
                                                <b v-if="item.observation === null"> {{ $t('Incident.IncNoObserMsg') }}</b>                                                
                                                {{ item.observation }}
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>  

            </div>
            <div class="tab-pane fade" id="contentEmployees" role="tabpanel">
            <div class="row">
            <div class="col-sm-12 col-md-3">                      
                <MdButton
                v-bind:Label="$t('Incident.IncEmployeeAdd')" 
                v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"                
                v-bind:IconClass="'ti-plus pr-1'"
                v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                v-on:click="AddEmployee"
                v-bind:Name="'btn_add_employee'">
                </MdButton>
            </div>
            <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                <input 
                    type="text" 
                    class="form-control"
                    v-model="participantEmployeeSearch">

                <div class="input-group-append">
                    <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'">
                    </MdButton>
                </div>
                </div>
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col-12">  
                     <!-- div Container Boxs -->  
                    <div v-for="(item,index) in this.searchParticipant(this.EmployeeItems,participantEmployeeSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="pull-right containerBoxTitle" v-if="IncidentItems.header.status !== 'Finalized' && IncidentItems.header.status !== 'Voided'">
                                    <b-icon icon="pencil-fill" @click="EditEmployee(item)" v-b-tooltip.hover.top="$t('Shared.Edit')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                                    <b-icon icon="trash-fill"  @click="DeleteEmployee(item)" v-b-tooltip.hover.top="$t('Shared.Delete')" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-md-6">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ?  $t('Shared.TitleYes')  : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>	

                                            <!--
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>-->
                                            <div class="text-muted p-fixed cutescroll autoH">
                                                <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                {{ item.personalStatement }}
                                            </div>


                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartComments') }}</h6>                                        
                                            </div>	

                                            <!--<div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoObserMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>-->
                                            <div class="text-muted p-fixed cutescroll autoH">
                                                <b v-if="item.observation === null"> {{ $t('Incident.IncNoObserMsg') }}</b>                                                
                                                {{ item.observation }}
                                            </div>

                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>
           

            </div>
            <div class="tab-pane fade" id="contentContacts" role="tabpanel">
            <div class="row">
            <div class="col-sm-12 col-md-3">                      
                <MdButton
                v-bind:Label="$t('ContactsList.AddContact')"  
                v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"               
                v-bind:IconClass="'ti-plus pr-1'"
                v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                v-on:click="AddContact"
                v-bind:Name="'btn_add_contact'">
                </MdButton>
            </div>
            <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                <input 
                    type="text" 
                    class="form-control"
                    v-model="participantContactSearch">

                <div class="input-group-append">
                    <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'">
                    </MdButton>
                </div>
                </div>
            </div>
            </div>
            </div>


            <div class="row">
                <div class="col-12">  
                     <!-- div Container Boxs -->    
                    <div v-for="(item,index) in this.searchParticipant(this.ContactItems,participantContactSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="pull-right containerBoxTitle" v-if="IncidentItems.header.status !== 'Finalized' && IncidentItems.header.status !== 'Voided'">
                                    <b-icon icon="pencil-fill" @click="EditContact(item)" v-b-tooltip.hover.top="$t('Shared.Edit')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                                    <b-icon icon="trash-fill"  @click="DeleteContact(item)" v-b-tooltip.hover.top="$t('Shared.Delete')" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? $t('Shared.TitleYes') : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>
                                                   
                                                    <div class="col-md-6">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ? $t('Shared.TitleYes') : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>	

                                            <!--<div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>-->
                                            <div class="text-muted p-fixed cutescroll autoH">
                                                <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                {{ item.personalStatement }}
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartConRecommendations') }}</h6>                                        
                                            </div>		

                                            <!--<div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoRecomMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>-->
                                            <div class="text-muted p-fixed cutescroll autoH">
                                                <b v-if="item.observation === null"> {{ $t('Incident.IncNoRecomMsg') }}</b>                                                
                                                {{ item.observation }}
                                            </div>

                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>


            </div>
            <div class="tab-pane fade" id="cotentVisitors" role="tabpanel">
            <div class="row">
            <div class="col-sm-12 col-md-3">                      
                <MdButton
                v-bind:Label="$t('Incident.IncVisitorAdd')"   
                v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"              
                v-bind:IconClass="'ti-plus pr-1'"
                v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                v-on:click="AddVisitor"
                v-bind:Name="'btn_add_visitor'">
                </MdButton>
            </div>
            <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                <input 
                    type="text" 
                    class="form-control"
                    v-model="participantVisitorSearch">

                <div class="input-group-append">
                    <MdButton 
                    v-bind:Name="'btnsearchinput'"
                    v-bind:VariantProp="'btn btn-sm search-attached'"
                    v-bind:IconClass="'mdi mdi-magnify'">
                    </MdButton>
                </div>
                </div>
            </div>
            </div>
            </div>

            <div class="row">
                <div class="col-12">   
                     <!-- div Container Boxs -->   
                    <div v-for="(item,index) in this.searchParticipant(this.VisitorItems,participantVisitorSearch)" :key="index" class="IncidentContainerBox">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="pull-right containerBoxTitle" v-if="IncidentItems.header.status !== 'Finalized' && IncidentItems.header.status !== 'Voided'">
                                    <b-icon icon="pencil-fill" @click="EditVisitor(item)" v-b-tooltip.hover.top="$t('Shared.Edit')" scale=".5" font-scale="1.6" variant="light"></b-icon> 
                                    <b-icon icon="trash-fill"  @click="DeleteVisitor(item)" v-b-tooltip.hover.top="$t('Shared.Delete')" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ item.entityName }}</h6>                                        
                                            </div>							
                                            <div class="flex-container">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartType') }}</small><br>
                                                            <b>{{ item.participationDescription }}</b>                                                     
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3" >
                                                        <div class="float-left col text-muted p-item">
                                                            <small>{{ $t('Incident.IncPartArrest') }}</small><br>
                                                            <b>{{item.arrestMade ? 'Yes' : 'No'}}</b>                                                     
                                                        </div>
                                                    </div>                                                    
                                                    <div class="col-md-6">
                                                        <div class="float-left col text-muted p-item">                                                        
                                                            <small>{{ $t('Incident.IncVisiToER') }}</small><br>
                                                            <b>{{item.ervisit ? $t('Shared.TitleYes') : 'No'}}</b>   
                                                        </div>
                                                    </div>                                              								
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                   
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartPersonalSta') }} - {{item.statementDate}}</h6>                                        
                                            </div>	

                                            <!--
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">   
                                                            <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                            <b>{{ item.personalStatement }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>-->

                                            <div class="text-muted p-fixed cutescroll autoH">
                                                <b v-if="item.personalStatement === null"> {{ $t('Incident.IncNoPersStaMsg') }}</b>                                                    
                                                {{ item.personalStatement }}
                                            </div>


                                        </div>
                                    </div>
                                </div>                                   
                            </div>  
                            <div class="col-md-6">
                                <div class="grid-margin stretch-card">
                                    <div class="card activeCard">
                                        <div class="card-body">
                                            <div class="d-flex align-items-start justify-content-between">
                                            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Incident.IncPartConRecommendations') }}</h6>                                        
                                            </div>
                                            <!--
                                            <div class="flex-container">
                                                <div class="row">                                     
                                                    <div class="col-md-12">
                                                        <div class="float-left col text-muted p-item">                                                          
                                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoRecomMsg') }}</b>                                                
                                                            <b>{{ item.observation }}</b>                                                     
                                                        </div>
                                                    </div>                               								
                                                </div>
                                            </div>
                                        -->
                                        <div class="text-muted p-fixed cutescroll autoH">
                                            <b v-if="item.observation === null"> {{ $t('Incident.IncNoRecomMsg') }}</b>                                                
                                            {{ item.observation }}
                                        </div>

                                        </div>
                                    </div>
                                </div>                                   
                            </div>                        
                        </div>                        
                    </div>                          
                </div>
            </div>

           
            </div>
        </div>
    </MdTabList>

    <ModalCategory v-if="showModalCategory"
        v-bind:TitleWindow="$t('Shared.Category')" 
        @getCodeConditionOK="OnReturnCategory($event)"
        @getCodeConditionCANCEL="OnReturnCategoryCancel($event)"
        v-bind:ModalId="modalIdCategory"
        v-bind:CategoryItems="CategoryItems"       
        v-bind:minIncidentDescription="this.IncidentReportOptionsItems[0].minIncidentDescription"
        v-bind:IncidentID="IncidentId"
        v-bind:ModeEdit="ModeEditCategory"
        v-bind:IntervCode="IntervCode">
    </ModalCategory>

    <MdTitle v-bind:Text="$t('Incident.IncPolice')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

    <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdCheckBox
                v-bind:Label="$t('Incident.IncPoliceInvolved')"
                v-bind:Name="'PoliceInvolved'"
                v-bind:ClassProp="'AlignCheck'"
                v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
                v-bind:CheckedValue="true"
                v-bind:UncheckedValue="false"
                v-model="IncidentItems.police.isPoliceInvolved"
                v-on:change="ChangePoliceInvolved"/>
            </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdCheckBox
            v-bind:Label="$t('Incident.IncPoliceNotified')"
            v-bind:Name="'WereThePoliceNotified'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:UncheckedValue="false"
            v-model="IncidentItems.police.wasPoliceNotified"
            v-on:change="ChangeWasPoliceNotified"/>
        </b-col>  
        <b-col md="3" lg="3" sm="12">
        <MdCheckBox
            v-bind:Label="$t('Incident.IncPartArrest')"
            v-bind:Name="'PoliceArrest'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="IncidentItems.police.arrestMade"
            v-on:change="ChangePoliceArrest"/>
        </b-col>
        </b-row> 
    <b-row>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Shared.Name')"
            v-bind:TypeProp="'text'"
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'PoliceName'"
            v-bind:isRequired="IncidentItems.police.isPoliceInvolved" 
            v-bind:isValid="ispoliceNameValid"
            v-bind:MaxLengthProp="100"
            v-model="IncidentItems.police.policeName"
            v-bind:Id="'input_incPolicename_id'">                                     
        </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Incident.IncBadge')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'PoliceBadge'"
            v-bind:MaxLengthProp="20"
            v-model="IncidentItems.police.badgeNumber"
            v-bind:Id="'input_incPolicebage_id'">                                     
        </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Incident.IncReportNo')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'PoliceReportNo'"
            v-bind:MaxLengthProp="20"
            v-model="IncidentItems.police.reportNumber"
            v-bind:Id="'input_incPoliceReportNo_id'">                                     
        </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Shared.Phone')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'PolicePhone'"
            v-bind:MaxLengthProp="20"
            v-model="IncidentItems.police.phoneNumber"
            v-bind:Id="'input_incPolicePhone_id'">                                     
        </MdEdit>
        </b-col>
    </b-row>
    <b-row>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Incident.IncStation')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'PoliceStation'"
            v-bind:MaxLengthProp="100"
            v-model="IncidentItems.police.stationName"
            v-bind:Id="'input_IncStation_id'">                                     
        </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('PatientDocument.Comments')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisablePolice || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'PoliceComments'"
            v-bind:MaxLengthProp="250"
            v-model="IncidentItems.police.comments"
            v-bind:Id="'input_IncPoliceComments_id'">                                     
        </MdEdit>
        </b-col>   
    </b-row>

    <MdTitle v-bind:Text="$t('Incident.IncChild')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    
    <b-row>
        <b-col md="3" lg="3" sm="12">
        <MdCheckBox
            v-bind:Label="$t('Incident.IncCPSInvolved')"
            v-bind:Name="'CPSInvolved'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="IncidentItems.cps.isCpsinvolved"
            v-on:change="ChangeCPSInvolved"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdCheckBox
            v-bind:Label="$t('Incident.IncScreenOut')"
            v-bind:Name="'ScreenOut'"
            v-bind:isReadOnly="DisableChildProtective || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="IncidentItems.cps.screenOut"
            v-on:change="ChangeCPSScreenOut"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdCheckBox
            v-bind:Label="$t('Incident.IncScreenIn')"
            v-bind:Name="'ScreenIn'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:isReadOnly="DisableChildProtective || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="IncidentItems.cps.screenIn"
            v-on:change="ChangeCPSScreenIn"/>
        </b-col>  
    </b-row> 
    <b-row>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Shared.Name')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisableChildProtective || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'CPSRepresentative'"
            v-bind:isRequired="IncidentItems.cps.isCpsinvolved" 
            v-bind:isValid="isCPSRepresentativeValid"
            v-bind:MaxLengthProp="100"
            v-model="IncidentItems.cps.representativeName"
            v-bind:Id="'input_IncCPSRepresentative_id'">                                     
        </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Incident.IncReportNo')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisableChildProtective || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'CPSReportNo'"
            v-bind:MaxLengthProp="20"
            v-model="IncidentItems.cps.reportNumber"
            v-bind:Id="'input_IncReportNo_id'">                                     
        </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('Shared.Phone')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisableChildProtective || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'CPSPhone'"
            v-bind:MaxLengthProp="20"
            v-model="IncidentItems.cps.phoneNumber"
            v-bind:Id="'input_IncPhone_id'">                                     
        </MdEdit>
        </b-col>        
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('PatientDocument.Comments')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="DisableChildProtective || IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'CPSComments'"
            v-bind:MaxLengthProp="250"
            v-model="IncidentItems.cps.comments"
            v-bind:Id="'input_IncComments_id'">                                     
        </MdEdit>
        </b-col>
    </b-row>

    <MdTitle v-bind:Text="$t('Incident.IncEmeRoom')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row>
        <b-col md="3" lg="3" sm="12">
        <MdCheckBox
            v-bind:Label="$t('Incident.IncPartVisit')"
            v-bind:Name="'VisittoER'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="IncidentItems.header.ervisit"
            v-on:change="ChangeVisittoER"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
        <MdEdit 
            v-bind:Label="$t('PatientDocument.Comments')"
            v-bind:TypeProp="'text'" 
            v-bind:isReadOnly="IncidentItems.header.status === 'Finalized' || IncidentItems.header.status === 'Voided'"
            v-bind:Name="'Comments'"
            v-bind:MaxLengthProp="500"
            v-model="IncidentItems.header.comments"
            v-bind:Id="'input_IncPatComments_id'">                                     
        </MdEdit>
        </b-col>
    </b-row>

    <MdPasswordDlg v-if="showSignPassword" 
    v-bind:Type="$t('MdNote.Sign')"
    v-bind:Operation="Operation"
    v-bind:VerificationType="'SupervisorPassword'"
    @getModalPassword="onModalPasswordResult($event)"
  />


    <ModalParticipantClient v-if="showModalParticipantClient"
        v-bind:TitleWindow="$t('Incident.IncParticipantClient')" 
        @getCodeConditionOK="OnReturnParticipantClient($event)"
        @getCodeConditionCANCEL="OnReturnParticipantClientCancel($event)"
        v-bind:ModalId="this.modalIdParticipantClient"
        v-bind:IncidentID="IncidentId"       
        v-bind:IncidentDate="IncidentItems.header.incidentDate"
        v-bind:minObsevationStatement="this.IncidentReportOptionsItems[0].minObsevationStatement"
        v-bind:minPersonalStatement="this.IncidentReportOptionsItems[0].minPersonalStatement"        
        v-bind:ModeEdit="ModeEditClient"
        v-bind:ParticipantItems="ParticipantItems">
    </ModalParticipantClient>
    <ModalParticipantEmployee v-if="showModalParticipantEmployee"
        v-bind:TitleWindow="$t('Incident.IncPartEmployee')" 
        @getCodeConditionOK="OnReturnParticipantEmployee($event)"
        @getCodeConditionCANCEL="OnReturnParticipantEmployeeCancel($event)"
        v-bind:ModalId="this.modalIdParticipantEmployee"
        v-bind:IncidentID="IncidentId"
        v-bind:minObsevationStatement="this.IncidentReportOptionsItems[0].minObsevationStatement"
        v-bind:minPersonalStatement="this.IncidentReportOptionsItems[0].minPersonalStatement"      
        v-bind:ModeEdit="ModeEditEmployee"
        v-bind:EmployeeItems="ParticipantItems">
    </ModalParticipantEmployee>
    <ModalParticipantContact v-if="showModalParticipantContact"
        v-bind:TitleWindow="$t('Incident.IncParticipantContact')" 
        @getCodeConditionOK="OnReturnParticipantContact($event)"
        @getCodeConditionCANCEL="OnReturnParticipantContactCancel($event)"
        v-bind:IncidentID="IncidentId"
        v-bind:minObsevationStatement="this.IncidentReportOptionsItems[0].minObsevationStatement"
        v-bind:minPersonalStatement="this.IncidentReportOptionsItems[0].minPersonalStatement"       
        v-bind:ModeEdit="ModeEditContact"
        v-bind:Patients="patientList()"
        v-bind:ContactItems="ParticipantItems">
    </ModalParticipantContact>
    <ModalParticipantVisitor v-if="showModalParticipantVisitor"
        v-bind:TitleWindow="$t('Incident.IncParticipantVisitor')" 
        @getCodeConditionOK="OnReturnParticipantVisitor($event)"
        @getCodeConditionCANCEL="OnReturnParticipantVisitorCancel($event)"
        v-bind:ModalId="this.modalIdParticipantVisitor"
        v-bind:IncidentID="IncidentId"
        v-bind:minObsevationStatement="this.IncidentReportOptionsItems[0].minObsevationStatement"
        v-bind:minPersonalStatement="this.IncidentReportOptionsItems[0].minPersonalStatement"       
        v-bind:ModeEdit="ModeEditVisitor"
        v-bind:VisitorItems="ParticipantItems">
    </ModalParticipantVisitor>
    <ICRMultipleRep v-if="isReportVisible" 
        :closeWindow="closeParamRepWindow"
        :IncidentID="IncidentId"
        :reportTitle="$t('Incident.IncReportTitle')"
      />

    <div class="space-actions"></div>
    <div class="page-actions">
            <MdButton
                v-bind:Label="$t('Shared.Back')"
                v-bind:Name="'btnincidentlist'"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="GoToIncidentList">
            </MdButton>             
          <MdButton v-if="!this.ModeEdit"
                v-bind:Label="$t('Shared.Save')"
                style="z-index:99999"
                v-bind:ClassProp="'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"                
                v-bind:isReadOnly="false"               
                v-bind:Name="'btn_save_incident'">
            </MdButton>
            <b-dropdown :disabled="DisabledSave" split :text="$t('Shared.Save')" class="mx-2" variant="primary" v-on:click="SaveOrEdit" v-if="this.ModeEdit">
             
                <b-dropdown-item-button v-if="ModeEdit && CompleteDraftRO && !this.IncidentItems.header.isDraftComplete && !this.IncidentItems.header.isVoid" v-on:click="completeDraft" >
                    {{ $t('Incident.IncCompleteDraft') }}                         
                </b-dropdown-item-button>
                <b-dropdown-item-button v-if="ModeEdit && this.IncidentItems.header.isDraftComplete && !this.IncidentItems.header.isVoid  && this.SeeIncidentAdmin" v-on:click="SetBackTD" >
                    {{ $t('Incident.IncSetBacktoDraft') }}                          
                </b-dropdown-item-button>

                <b-dropdown-item-button v-if="ModeEdit && !this.IncidentItems.header.isVoid" v-on:click="voidIncident" :disabled="VoidIncidentRO">
                    {{ $t('Incident.IncVoidOpt') }} 
                </b-dropdown-item-button>

                <b-dropdown-item-button v-if="ModeEdit && !this.pendingChanges" v-on:click="showReport" :disabled="ReportRO">
                    {{ $t('Shared.Report') }} 
                </b-dropdown-item-button>
            </b-dropdown>
    </div>
    </div>
</template>
 
 <script>
 
 import Utils from '@/common/utils'
 import IncidentReport from '@/services/incidentreport.service'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import GlobalService from '@/services/global.service'
 import IncidentReportOptions from '@/services/incidentreportoptions.service'
 import StorageService from '@/services/storage.service'
 import RoomsService from '@/services/rooms.service'
 import EmployeeService from '@/services/employee.service'
 import { EventBus } from '@/event-bus.js';
 import moment from 'moment'
import DBFunctions from '@/common/DBFunctions.js'

 export default {
 
    beforeRouteLeave(to, from, next) {
	if (this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('Incident.IncReportTitle'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
},
 
    mixins: [ShowMessage, DBFunctions],  
    props: {
        editMode: Boolean       
    },
    name: 'IncidentReportContent',
     data() {
       return {
        idIncident:this.$route.params.IncidentID, 
        SeeIncidentAdmin:false,
        Operation:"",
        VisibleCompleteDraf:false,
        isLocationValid:true, 
        showSignPassword:false,
        isIncidentDateValid:true,
        icon:"",
        DisablePolice:true,
        DisableChildProtective:true,
        CategoryName:"",
        IncidentDateHeader:null,
        ModeEdit:this.editMode,  
        ModeEditCategory:false,  
        ModeEditClient:false,  
        ModeEditEmployee:false,  
        DisabledSave:true,
        isCPSRepresentativeValid:true,
        ispoliceNameValid:true,
        isIncidentTimeValid:true,
        ModeEditContact:false,  
        ModeEditVisitor:false, 
        IncidentReportOptionsItems: [],
        HaveRequiredParticipant: false,
        PrimaryExist: false,
        IRDetails: [],
        ParticipantItems: {},
        NewVer: 0,
        CompleteDraftRO: true,
        VoidIncidentRO: true,
        isReportVisible: false,
        CompleteDraftLabel: this.$t('Incident.IncCompleteDraft'),
        incidentTimeFormatted: null,
        ReportRO: false,
        isRoomValid: true,
        LocationList: [],
        CategoriesItems: [],      
        IncidentId: this.$route.params.IncidentID,
        showEmptyRecords: false,
        showModalCategory: false,
        modalIdCategory: 'modalIdCategory',    
        IntervCode: '',
        pendingChanges:false,
        
        HeaderItems:{
            addlLocation:'',
            draftCompleteDate:null,
            draftCompleteUser:'',
            ervisit:'N',
            finalizeDate:null,
            finalizeUser:null,
            incidentDate:null,
            incidentId:'',
            incidentTime:null,
            isDraftComplete:'',
            isFinalize:null,
            isSigned:'N',
            isVoid:null,
            lastEditDateTime:null,
            location:'',
            reportedDate:null,
            reporter:'',
            reporterName:'',
            roomNo:'',
            severity:'',
            severityName:'',
            signedDate:null,
            sysUser:'',
            version:1,
            voidDate:null,
            voidUser:null,
            wasPoliceNotified:'N'
        },
        ClientShowEmptyRecords: false,
        EmployeeShowEmptyRecords: false,
        ContactShowEmptyRecords: false,
        VisitorShowEmptyRecords: false,
        showModalParticipantClient: false,
        showModalParticipantEmployee: false,
        showModalParticipantContact: false,
        showModalParticipantVisitor: false,

        modalIdParticipantClient: 'ParticipantClient',
        modalIdParticipantEmployee: 'ParticipantEmployee',
        modalIdParticipantContact: 'ParticipantContact',
        modalIdParticipantVisitor: 'ParticipantVisitor',

        tablist:[
            {title:this.$tc('Incident.IncPartClient',2),link:'#contentClients', active: true},
            {title:this.$tc('Shared.Employee', 2),link:'#contentEmployees'},
            {title:this.$tc('Contact.Contact',2),link:'#contentContacts'},
            {title:this.$t('Incident.IncVisitorsTap'),link:'#cotentVisitors'}
        ],
        parameter:{
            SearchCriteria:'',
            PageNumber : 0,
            PageSize:0,
        },
        FieldsRooms:[
            { key: 'name', sortable: true, label: this.$t('Incident.RoomName') },
            { key: 'buildingName', sortable: true, label: this.$t('Shared.BuildingName') },
            { key: 'code', sortable: true, label: this.$t('Shared.Code') },
        ],
        OptionsRightMenu:{
                visible:false,
                ItemsList:[],
                btnvisible:false,
                itemdisabled: false, 
                favButtonAtion:false          
        },
        APIDateTime: null,
        CategoryItems: {},
        ClientItems: [],
        EmployeeItems: [],
        ContactItems: [],
        VisitorItems: [],
        paramsParticipants:{
            SearchCriteria:'',
            PageNumber : 0,
            PageSize:0 ,
            EntityType: '',           
            IncidentId: this.$route.params.IncidentID
        },
        IncidentItems: {
                header:{
                    reporter:this.$store.state.employeeCode,
                    incidentDate:null,                    
                    addlLocation:"",
                    incidentTime:null,
                    reportedDate:null,
                    roomNo:"",
                    location:"",                    
                },
                categories:[],
                participants:[],
                police:{
                    policeName:"",
                    badgeNumber:"",
                    reportNumber:"",
                    stationName:"",
                    phoneNumber:"",
                    arrestMade:false,
                    isPoliceInvolved:false,
                    comments:"",
                    WasPoliceNotified:false
                },
                cps:{
                    screenIn:false,
                    screenOut:false,
                    representativeName:"",
                    phoneNumber:"",
                    isCpsinvolved:false,
                    reportNumber:"",
                    comments:""
                },
                Employee:this.SeeIncidentAdmin,
            },
        RoomList: [],
        participantClientSearch: '',
        participantEmployeeSearch: '',
        participantContactSearch: '',
        participantVisitorSearch: '',
        EmployeeList: [],
        Employee: {}
       }    
        
     },
 
 
     methods:{

        getOtherIntervention(value) {
            return Utils.HTML2PlainText(value)
        },
        
        putUpPrimaryCategory() {
            this.CategoriesItems.sort(function (a) {
                if (a.isPrimary) {
                    return -1;
                }
            }); 
        },

        searchCategories(arr, value = '') {
            if (arr && arr.length) {
                if (value == '') {
                    return arr;
                }
                value = value.toLowerCase()
                return arr.filter(e => (e.incidentDescription ? e.incidentDescription.toLowerCase().includes(value) : null) 
                                    || (e.categoryName ? e.categoryName.toLowerCase().includes(value) : null)
                                    || (e.intervention ? e.intervention.toLowerCase().includes(value) : null));
            }
            return null;
        },

        searchParticipant(arr, value = '') {
            if (arr && arr.length) {
                if (value == '') {
                    return arr;
                }
                value = value.toLowerCase()
                return arr.filter(e => (e.entityName ? e.entityName.toLowerCase().includes(value) : null) 
                                    || (e.participationDescription ? e.participationDescription.toLowerCase().includes(value) : null)
                                    || (e.personalStatement ? e.personalStatement.toLowerCase().includes(value) : null)
                                    || (e.statementDate ? e.statementDate.toLowerCase().includes(value) : null)
                                    || (e.participationType ? e.participationType.toLowerCase().includes(value) : null)
                                    || (e.observation ? e.observation.toLowerCase().includes(value) : null)
                                    || (e.programName ? e.programName.toLowerCase().includes(value) : null));
            }
            return null;
        },

        DiscardChanges() {

        },
        OnReturnParticipantClientCancel() {
            this.showModalParticipantClient = false
        },
        OnReturnParticipantVisitorCancel() {
            this.showModalParticipantVisitor = false
        },
        OnReturnParticipantEmployeeCancel() {
            this.showModalParticipantEmployee = false
        },
        OnReturnParticipantContactCancel() {
            this.showModalParticipantContact = false
        },
        OnReturnParticipantEmployee(data) {
            this.showModalParticipantEmployee = false
            if (this.ModeEditEmployee) {
                let selected = this.EmployeeItems.find(el => el.id === data.id)
                this.UpdateParticipant(selected,data)
                this.ChangesMade()
            } else {
                data.statementDate = Utils.formatterDateToString(data.statementDate);
                this.EmployeeItems.push(data)
                this.ChangesMade()
            }
        },
        OnReturnParticipantContact(data) {
            this.showModalParticipantContact = false

            if (this.ModeEditContact) {
                let selected = this.ContactItems.find(el => el.id === data.id)
                this.UpdateParticipant(selected,data)
                this.ChangesMade()
            } else {
                data.statementDate = Utils.formatterDateToString(data.statementDate);
                this.ContactItems.push(data)
                this.ChangesMade()
            }
        },
        OnReturnParticipantVisitor(data) {
            this.showModalParticipantVisitor = false

            if (this.ModeEditVisitor) {
                let selected = this.VisitorItems.find(el => el.id === data.id)
                this.UpdateParticipant(selected,data)
                this.ChangesMade()
            } else {
                data.statementDate = Utils.formatterDateToString(data.statementDate);
                this.VisitorItems.push(data)
                this.ChangesMade()
            }
        },
        patientList() {
            let patientList = ''
            this.ClientItems.forEach(function(element,index) {
                if (index == 0)
                    patientList = `?PatientCodeList=${element.entityCode}`
                else
                    patientList = `${patientList}&PatientCodeList=${element.entityCode}`
            });
            return patientList 
        },

        SetIcon(Status){           
			if(Status == 'Voided'){
                this.icon = 'ikon med-doc-cancelled mr-2';
			}
            else if(Status == 'Finalized'){
                this.icon = 'ikon med-doc-complete mr-2';
            }
            else if(Status == 'Completed Draft'){
                this.icon = 'ikon med-doc-signed mr-2';
            }
            else{
                this.icon = 'ikon med-doc-pending-signature mr-2';
            }
    },

        UpdateParticipant(selected, data) {  
            selected.arrestMade = data.arrestMade
            selected.ervisit = data.ervisit
            selected.observation = data.observation
            selected.participationType = data.participationType
            selected.participationDescription = data.participationDescription
            selected.entityName = data.entityName
            selected.programName = data.programName

            if(data.entityType == 'C'){
                selected.Recno = data.Recno
                selected.RecordId = data.RecordId
            }  
            
            if(data.entityType == 'P'){
                selected.isRequired = data.isRequired 
                selected.dob = data.dob              
            } 

            selected.personalStatement = data.personalStatement
            selected.statementDate = Utils.formatterDateToString(data.statementDate)
            selected.entityCode = data.entityCode
            selected.entityType = data.entityType
            selected.incidentId = data.incidentId
            selected.id = data.id
            selected.recordId = data.recordId
        },

        OnReturnParticipantClient(data) {
            this.showModalParticipantClient = false

            if (this.ModeEditClient) {
                let selected = this.ClientItems.find(el => el.id === data.id)
                this.UpdateParticipant(selected,data)
                this.ChangesMade()
            } else {
                data.statementDate = Utils.formatterDateToString(data.statementDate);
                this.ClientItems.push(data)
                this.ChangesMade()
            }
        },
        ChangePoliceInvolved(value) {
            //this.IncidentItems.police.arrestMade = value.code 
            this.DisablePolice = value ? false : true;

            if(!value){
                //this.IncidentItems.header.wasPoliceNotified = false;
                this.IncidentItems.police.wasPoliceNotified = false;
                this.IncidentItems.police.arrestMade = false;
                this.IncidentItems.police.policeName = "";
                this.IncidentItems.police.badgeNumber = "";
                this.IncidentItems.police.reportNumber = "";
                this.IncidentItems.police.phoneNumber = "";
                this.IncidentItems.police.stationName = "";
                this.IncidentItems.police.comments = "";
            }
        },
        ChangePoliceArrest(value) {
            this.IncidentItems.police.arrestMade = value.code           
        },
        ChangeWasPoliceNotified(value) {
            this.IncidentItems.police.wasPoliceNotified = value.code
        },
        ChangeVisittoER(value) {
            this.IncidentItems.header.ervisit = value.code
        },
        ChangeCPSInvolved(value) {
            this.DisableChildProtective =  value ? false : true;

            if(!value){
                this.IncidentItems.cps.screenOut = false;
                this.IncidentItems.cps.screenIn = false;
                this.IncidentItems.cps.representativeName = "";
                this.IncidentItems.cps.reportNumber = "";
                this.IncidentItems.cps.phoneNumber = "";
                this.IncidentItems.cps.comments = "";
            }
        },
        ChangeCPSScreenOut() {

        },

        ChangeCPSScreenIn() {

        },
        AddCategory() {
            this.showModalCategory = true
            this.ModeEditCategory = false
        },
        AddEmployee() {
            this.ModeEditEmployee = false
            this.showModalParticipantEmployee = true
        },
        AddContact() {
            if (this.ClientItems && this.ClientItems.length > 0) {
                this.ModeEditContact = false
                this.showModalParticipantContact = true
            } else {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredPatientMsg'),"warning");
            }
        },
        OnReturnCategoryCancel() {
            this.showModalCategory = false;
        },
        DeleteItem(Item) {
            let selected = this.CategoriesItems.find(el => el.id === Item.id)
            this.CategoriesItems.splice(this.CategoriesItems.indexOf(selected),1)
            this.ChangesMade()
        },
        DeleteClient(Item) {
            let selected = this.ClientItems.find(el => el.id === Item.id)
            this.ClientItems.splice(this.ClientItems.indexOf(selected),1)
            this.ChangesMade()
        },
        DeleteEmployee(Item) {
            let selected = this.EmployeeItems.find(el => el.id === Item.id)
            this.EmployeeItems.splice(this.EmployeeItems.indexOf(selected),1)
            this.ChangesMade()
        },
        DeleteContact(Item) {
            let selected = this.ContactItems.find(el => el.id === Item.id)
            this.ContactItems.splice(this.ContactItems.indexOf(selected),1)
            this.ChangesMade()
        },
        EditVisitor(Item) {
           this.ModeEditVisitor = true
            this.ParticipantItems = this.VisitorItems.find(el => el.id === Item.id)
            this.showModalParticipantVisitor = true
        },
        EditContact(Item) {
           this.ModeEditContact = true
            this.ParticipantItems = this.ContactItems.find(el => el.id === Item.id)
            this.showModalParticipantContact = true
        },
        EditEmployee(Item) {
            this.ModeEditEmployee = true
            this.ParticipantItems = this.EmployeeItems.find(el => el.id === Item.id)
            this.showModalParticipantEmployee = true
        },
        EditClient(Item) {
            this.ModeEditClient = true
            this.ParticipantItems = this.ClientItems.find(el => el.id === Item.id)
            this.showModalParticipantClient = true
        },
        DeleteVisitor(Item) {
            let selected = this.VisitorItems.find(el => el.id === Item.id)
            this.VisitorItems.splice(this.VisitorItems.indexOf(selected),1)
            this.ChangesMade()
        },
        UpdateCategory(selected, data) {
            selected.id = data.id
            selected.categoryCode = data.categoryCode
            selected.categoryName = data.categoryName
            selected.isPrimary = data.isPrimary
            selected.incidentDescription = data.incidentDescription
            selected.incidentTypes = data.incidentTypes
            selected.interventions = data.interventions
            selected.intervention = data.intervention
            selected.severityCode = data.severityCode
            selected.severityName = data.severityName
        },


        GetCategoryNameifPrimary(){
           for(var i = 0; i<this.CategoriesItems.length; i++){
             if(this.CategoriesItems[i].isPrimary == true){
                this.CategoryName = this.CategoriesItems[i].categoryName;
                break;
             }
           }
        },


        OnReturnCategory(data) {
            this.showModalCategory = false;
            if (data.isPrimary &&  data.isPrimary && data.categoryCode)
                this.IncidentItems.header.severity = data.severity

            if (this.ModeEditCategory) {
                let selected = this.CategoriesItems.find(el => el.id === data.id)
                this.UpdateCategory(selected,data)
                this.ChangesMade()
            } else {
                this.CategoriesItems.push(data)
                this.ChangesMade()
            }
            this.GetCategoryNameifPrimary();
            this.putUpPrimaryCategory()
        },
        AddVisitor() {
            this.ModeEditVisitor = false
            this.showModalParticipantVisitor = true
        },
       
        ChangesMade() {
            this.pendingChanges = true;
        },

        showReport() {
            this.isReportVisible = true
        },

        GoToIncidentList(){
            this.checkExistFavorite('/app/incidents');
            this.$router.push({ name: "incident-report-back"}).catch(()=>{});
        },

        onModalPasswordResult(result){                
        this.pendingChanges = false;
      if(result.Event === 'Ok'){   
        if(result.status === true) {
         this.$emit('load', true); 
         if(result.Operation === "Void"){
          IncidentReport.SetVoid(this.IncidentId)
              .then((response) => {
                if(response.status == '200'){
                    this.IncidentItems.header.isVoid = true; 
                    this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncVoidMsg'));                    
                    this.UpdateIncidentStatus();                  
                }
                
                this.$emit('load', false);               
              })
              .catch((error) => {
                this.$emit('load', false);         
                if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
              });        
         } 
         else if(result.Operation === "SetBackToDraft"){
          IncidentReport.setBackToDraft(this.IncidentId)
                .then((response) => {  
                    if(response.status == '200'){                
                        this.pendingChanges = false;
                        this.IncidentItems.header.isDraftComplete = false;
                        this.CompleteDraftLabel = this.$t('Incident.IncCompleteDraft');
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncSetBackToDraftMsg') );
                        this.UpdateIncidentStatus(); 
                    }
                    this.$emit('load', false);         
                })
                .catch((error) => {
                    this.$emit('load', false);         
                    if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                });
         }
         else if(result.Operation === "CompleteDraft"){
            IncidentReport.completeDraft(this.IncidentId, this.IncidentItems.header.reporter)
                    .then(() => {
                        this.IncidentItems.header.isDraftComplete = true;
                        this.$emit('load', false);         
                        this.CompleteDraftLabel = 'Set Back to Draft';
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncCompleteDrafMsg'));
                        this.UpdateIncidentStatus(); 
                    })
                    .catch((error) => {
                        this.$emit('load', false);  
                        if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }         
                    /*if (!error.response) {
                        this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                    } else {
                        this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                    }*/
                    });
         }      
        
        }
        else{
          this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.InvalidSupervisorPass'),"error");
        }
      }    
    this.showSignPassword = false;
    },

    SetBackTD(){
      if (this.IncidentItems.header.isVoid == false) {
        this.$bvModal.msgBoxConfirm(this.$t('Incident.IncConfirmBackDrafMsg'), {
          title: this.$t('Incident.IncReportTitle'),
          okTitle: this.$t('Incident.IncSetBacktoDraft'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning',
          id:'modal_SBTD_incident'
        })
        .then(value => {
          if(value) {
            this.showSignPassword = true;  
            this.Operation = "SetBackToDraft";   
          }
        })
      }
    },

    voidIncident() {
      if (this.IncidentItems.header.isVoid == false) {
        this.$bvModal.msgBoxConfirm(this.$t('Incident.IncConfirmVoidMsg'), {
          title: this.$t('Incident.IncReportTitle'),
          okTitle: this.$t('Incident.IncVoidOpt'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning',
          id:'modal_incidentVoid'
        })
        .then(value => {
          if(value) {
            this.showSignPassword = true;  
            this.Operation = "Void";   
          }
        })
      }
    },

    UpdateIncidentStatus(){
        IncidentReport.IncidentStatus(this.IncidentId)
                    .then((response) => {
                       if(response.status == '200'){                       
                        if(response.data){
                            this.IncidentItems.header.status =  response.data.status;
                        }
                        
                       }
                    })
                    .catch((error) => {                          
                        if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                    });
    },
      
        completeDraft() {
            this.$emit('load', true);
            if (this.IncidentItems.header.isDraftComplete == false && this.$store.state.isUseCompleteDraft) {
                IncidentReport.completeDraft(this.IncidentId, this.IncidentItems.header.reporter)
                    .then((response) => {
                        if(response.status == '200'){
                            this.IncidentItems.header.isDraftComplete = true;                           
                            this.CompleteDraftLabel = 'Set Back to Draft';
                            this.$emit('load', false);
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncCompleteDrafMsg'));
                            this.UpdateIncidentStatus(); 
                        }                        
                    })
                    .catch((error) => {
                    this.$emit('load', false);
                    if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                    });
                } else if (this.IncidentItems.header.isDraftComplete && this.SeeIncidentAdmin) {
                this.$bvModal.msgBoxConfirm(this.$t('Incident.IncConfirmBackDrafMsg'), {
                    title: this.$t('Incident.IncReportTitle'),
                    okTitle: this.$t('Incident.IncSetBacktoDraft'),
                    cancelTitle: this.$t('Shared.BtnCancel'),
                    footerClass: 'p-2',
                    titleClass: 'modal-title modal-type-warning',
                    id:'modal_delete_vitals'
                })
                .then(value => {
                    if(value) {
                    this.$emit('load', true);
                    IncidentReport.setBackToDraft(this.IncidentId)
                        .then((response) => {
                            if(response.status == '200'){
                                this.IncidentItems.header.isDraftComplete = false;
                                this.CompleteDraftLabel = this.$t('Incident.IncCompleteDraft');                               
                                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncSetBackToDraftMsg'));
                                this.UpdateIncidentStatus(); 
                            }                         
                            this.$emit('load', false);
                        })
                        .catch((error) => {
                        this.$emit('load', false);
                        if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                        });
                    }
                })
                }
        },

        PrepareIncident(){          
           var time = this.IncidentItems.header.incidentDate + " " + this.incidentTimeFormatted;
           this.IncidentItems.header.incidentTime = Utils.formatterToISO(time);
           this.IncidentItems.categories = [];
           this.IncidentItems.participants = [];

           if(this.CategoriesItems.length > 0){
                this.CategoriesItems.forEach(element => {
                var catItem = {};       
                let intCodes = [];
                catItem.categoryCode = element.categoryCode;
                catItem.isPrimary = element.isPrimary;
                catItem.incidentTypes = element.incidentTypes;
                catItem.severityCode = element.severityCode;               
                catItem.intervention = element.intervention;
                catItem.incidentDescription = element.incidentDescription;
                
                if(element.interventions.length > 0){
                    element.interventions.forEach(element => {
                        intCodes.push(element.code);                  
                    });
                }
              

                catItem.interventionsCodes = intCodes;

                this.IncidentItems.categories.push(catItem);
            });
           }else{
            this.IncidentItems.categories = [];
           }

           if(this.ClientItems.length > 0){           
            this.ClientItems.forEach(element => {
                let partClient = {}; 
                partClient.entityType = element.entityType;
                partClient.entityCode = element.entityCode;
                partClient.entityName = element.entityName;
                partClient.participationType = element.participationType;
                partClient.observation = element.observation;
                partClient.personalStatement = element.personalStatement;
                partClient.statementDate = Utils.formatterToISO(element.statementDate, Utils.getShortDateFormat());
                partClient.ervisit = element.ervisit;
                partClient.arrestMade = element.arrestMade;
                partClient.admissionRecordId = element.recordId;
                this.IncidentItems.participants.push(partClient);
            });
           }

           if(this.EmployeeItems.length > 0){           
            this.EmployeeItems.forEach(element => {
                let partClient = {}; 
                partClient.entityType = element.entityType;
                partClient.entityCode = element.entityCode;
                partClient.entityName = element.entityName;
                partClient.participationType = element.participationType;
                partClient.observation = element.observation;
                partClient.personalStatement = element.personalStatement;
                partClient.statementDate = Utils.formatterToISO(element.statementDate, Utils.getShortDateFormat());
                partClient.ervisit = element.ervisit;
                partClient.arrestMade = element.arrestMade;
                this.IncidentItems.participants.push(partClient);
            });
           }

           if(this.ContactItems.length > 0){           
            this.ContactItems.forEach(element => {
                let partClient = {}; 
                partClient.entityType = element.entityType;
                partClient.entityCode = element.entityCode.toString();
                partClient.entityName = element.entityName;
                partClient.participationType = element.participationType;
                partClient.observation = element.observation;
                partClient.RecNo = element.RecNo;
                partClient.RecordId = element.RecordId;
                partClient.personalStatement = element.personalStatement;
                partClient.statementDate = Utils.formatterToISO(element.statementDate, Utils.getShortDateFormat());
                partClient.ervisit = element.ervisit;
                partClient.arrestMade = element.arrestMade;
                this.IncidentItems.participants.push(partClient);
            });
           }

           if(this.VisitorItems.length > 0){           
            this.VisitorItems.forEach(element => {
                let partClient = {}; 
                partClient.entityType = element.entityType;
                partClient.entityCode = element.entityCode;
                partClient.entityName = element.entityName;
                partClient.participationType = element.participationType;
                partClient.observation = element.observation;
                partClient.personalStatement = element.personalStatement;
                partClient.statementDate = Utils.formatterToISO(element.statementDate, Utils.getShortDateFormat());
                partClient.ervisit = element.ervisit;
                partClient.arrestMade = element.arrestMade;
                this.IncidentItems.participants.push(partClient);
            });
           }


        },

        UpdateIncident(){             
            if(this.IncidentItems.header.status === 'Finalized' || this.IncidentItems.header.status === 'Voided'){
                this.$bvToast.toast(this.$t('Incident.IncNoAbleToEdit'), {
                         title: this.$t('Incident.Inc'),
                          autoHideDelay: 5000,
                          variant: "warning"
                        });
                        this.$emit('load', false);
                        this.DisabledSave = false;
            }else{
            this.$emit('load', true);
           // var id = this.$route.params.IncidentID.toString();     
           var id = this.idIncident;             
            IncidentReport.UpdateIncident(id,this.IncidentItems)
                .then((response) => { 
                    if(response.data){
                        if(response.status == '200'){
                            this.pendingChanges = false;
                            this.checkExistFavorite('/app/incidents');  
                            this.$router.push({ name: "incident-report-back"}).catch(()=>{});                          
                            this.$emit('load', false);                                  
                            setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                             })
                            , 50);
                        }
                    }  
                    this.$emit("load", false);    
                    this.DisabledSave = false;       
                })
                .catch((error) => {
                    this.$emit("load", false);
                    this.DisabledSave = false;
                    if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                });
            }
   
        },
        
        AddIncident(){            
            this.$emit('load', true);
            IncidentReport.AddIncident(this.IncidentItems)
                .then((response) => { 
                    if(response.data){
                        if(response.status == '200'){
                            this.pendingChanges = false;
                            this.checkExistFavorite('/app/incidents');  
                            this.$router.push({ name: "incident-report-back"}).catch(()=>{});                         
                            this.$emit('load', false);                                  
                            setTimeout(() => 
                            this.$bvToast.toast('Incident saved successfully.', {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                             })
                            , 50);
                        }
                        
                    }  
                    this.$emit("load", false);  
                    this.DisabledSave = false;         
                })
                .catch((error) => {
                    this.$emit("load", false);
                    this.DisabledSave = false;
                    if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                });
        },

        validate_required(){
            this.isLocationValid = this.IncidentItems.header.location && this.IncidentItems.header.location.length > 0 || this.IncidentItems.header.roomNo && this.IncidentItems.header.roomNo.length > 0 ? true : false;   
                    
            if(this.IncidentItems.police.isPoliceInvolved === true && (this.IncidentItems.police.policeName === null || this.IncidentItems.police.policeName === "")){        
                this.ispoliceNameValid = false;
            }else{      
                this.ispoliceNameValid = true;
            }

            if(this.IncidentItems.cps.isCpsinvolved === true && (this.IncidentItems.cps.representativeName === null || this.IncidentItems.cps.representativeName === "")){        
                this.isCPSRepresentativeValid = false;
            }else{      
                this.isCPSRepresentativeValid = true;
            }

            if(this.incidentTimeFormatted === null || this.incidentTimeFormatted === "" ){
                this.isIncidentTimeValid = false;
            }else{
                this.isIncidentTimeValid = true;
            }
           
           return this.isLocationValid && this.ispoliceNameValid && this.isCPSRepresentativeValid && this.isIncidentTimeValid;
        },

        async SaveOrEdit(){

        if(this.validate_required()) {
            if(await this.canSaveEntry()){
                this.DisabledSave = true;
                this.$emit("load", true);
                this.PrepareIncident();
                if(this.ModeEdit){               
                    this.UpdateIncident();
                }else{ 
                    this.AddIncident();
                }
            }  
        }else{           
			this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.CheckRequiredFields'),"errorAutoHide");			
        }          
        },
        AddClient() {
            this.ModeEditClient = false
            this.ParticipantItems = {}
            this.showModalParticipantClient = true
        },

        EditItem(Item) {
            this.ModeEditCategory = true;
            this.IntervCode = Item.categoryCode
            this.CategoryItems = this.CategoriesItems.find(el => el.id === Item.id)
            if (this.CategoryItems.isPrimary  && this.CategoryItems.categoryCode)
                this.CategoryItems.severity = this.HeaderItems.severity
            this.showModalCategory = true
        },

        loadParticipants() {
            this.IncidentItems.participants.forEach(element => {
                element.statementDate = Utils.formatterDateToString(element.statementDate);
                if (element.entityType == 'P')
                    this.ClientItems.push(element)
                else if (element.entityType == 'C')
                    this.ContactItems.push(element)
                else if (element.entityType == 'E')
                    this.EmployeeItems.push(element)
                 else if (element.entityType == 'V')
                    this.VisitorItems.push(element)
            });
        },

        getDateTime() { 
         if(!this.Mode){
             GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                this.APIDateTime = Utils.formatterDateTimeToString(response.data);
               }  
         })
         .catch(error => {
            if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
         }); 
         }        
        },

        loadCategories() {
            this.IncidentItems.categories.forEach(element => {
                this.CategoriesItems.push(element)
            });
        },

        async getLocation() {
            await IncidentReport.getLocation()
                .then((response) => {
                if(response.status == '200') {  
                    this.LocationList = response.data;
                }                             
                })
                .catch((error) => {
                    if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                });
        },

        async getIncidentReportOptions() {
        await IncidentReportOptions.getAll()
            .then((response) => { 
            if(response.data){
                this.IncidentReportOptionsItems = response.data;
            }             
            })
            .catch((error) => {
            this.$emit("load", false);
            if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
            });
        },

        beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
        },

        async getHaveRequiredParticipant() {
            this.HaveRequiredParticipant = false;
            let Required, RParticipant = false;
            this.ClientItems.forEach(function(element) {
                Required = element.isRequired;
                if (Required){
                    RParticipant = true;
                }
                    
            });   
            
            this.HaveRequiredParticipant = RParticipant;
        },

        getCheckIfPrimaryExist() {
            this.PrimaryExist = false

            this.CategoriesItems.forEach(element => {
                if (element.isPrimary)
                    this.PrimaryExist = true
            });
        },

        CheckIfPrimaryExistForCurrent(){
            let count  = 0;
            this.CategoriesItems.forEach(element => {
                if (element.isPrimary){
                    count++;
                }                    
            });
            return count > 1;
        },


        HaveSeverity(){
           let count,countSeverity = 0; 
           //let result = false;         
            this.CategoriesItems.forEach(element => {
                if(element.isPrimary){
                   /* result = true;
                    return result;*/
                    count++;
                    if(element.severityCode.length > 0){
                        countSeverity++;
                    }
                }
            });


           return count == countSeverity;
           //return result;
        },

        async canSaveEntry() {
            let result = true;            
            await this.getHaveRequiredParticipant()
            await this.getCheckIfPrimaryExist()

           if(this.CheckIfPrimaryExistForCurrent()){
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncPrimaryCategMsg'),"error");
                result = false;    
           }
           
            if (this.IncidentReportOptionsItems[0].enforceRequiredParticipants && this.HaveRequiredParticipant === false) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncAtLeastPartiMsg'),"error");
                result = false;                
            }

            if (result && this.IncidentItems.header.roomNo == '' && this.IncidentItems.header.location == '') {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredLocationMsg'),"error");
                result = false;
            }

            if (result && this.IncidentItems.header.reporter == '') {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredReporterMsg'),"error");
                result = false;
            }

           /* if (this.IncidentReportOptionsItems[0].canSaveWithoutSigning && this.IncidentItems.header.isSigned == false) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredSingMsg'),"error");
                result = false;
            }*/

            /*if (result && Number(this.IncidentItems.header.severity) < 1 ) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredseverityMsg'),"error");
                result = false;
            }*/
            if (result && this.HaveSeverity()) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredseverityMsg'),"error");
                result = false;
            }

            if (result && (this.IncidentItems.header.incidentDate == null ||  this.IncidentItems.header.incidentDate == "")) {
                this.isIncidentDateValid = false; 
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredDateMsg'),"error");
                result = false;
            }else{
                this.isIncidentDateValid = true; 
            }

            if (result && this.IncidentItems.header.incidentDate > Utils.formatterDate(Date(), "LLLL") ) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncFutureDateMsg'),"error");
                result = false;
            }

            if (result && this.CategoriesItems.length < 0 ) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredCategoryMsg'),"error");
                result = false;
            }

            if (this.PrimaryExist == false ) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredPrymaryCategoryMsg'),"error");
                result = false;
            } 
            
            if (this.IncidentItems.police.isPoliceInvolved && this.IncidentItems.police.policeName == '') {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredPolNameMsg'),"error");
                result = false;
            }

            if (this.IncidentItems.cps.isCpsinvolved && this.IncidentItems.cps.representativeName == '' ) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredCPSMsg'),"error");
                result = false;
            }


            if (this.IncidentItems.cps.isCpsinvolved && this.IncidentItems.cps.representativeName == '' ) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredCPSMsg'),"error");
                result = false;
            }
            if (this.IncidentItems.cps.isCpsinvolved && this.IncidentItems.cps.representativeName == '' ) {
                this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncRequiredCPSMsg'),"error");
                result = false;
            }

            return result;

            /*if (result) {
                 this.$emit('load', true);
                
                if (this.UpdateProcess == 0)
                    await this.AddIncidentReportHeader() 
                if (this.UpdateProcess > 0 && this.PoliceInfo.isPoliceInvolved == 'Y')
                    await this.AddIncidentReportPoliceInfo()
                if (this.UpdateProcess > 0 && this.CPSInfo.isCpsinvolved == 'Y')
                    await this.AddIncidentReportCPSInfo()
                if (this.UpdateProcess > 0)
                    await this.AddIncidentReportParticipants()
                if (this.UpdateProcess > 0)
                    await this.AddIncidentReportCategories()

                this.UpdateProcess = 0
                
                this.$emit('load', false);

                this.pendingChanges = false;

                this.$router.push({ name: "incident-report", params: { DateFrom: this.$route.params.DateFrom, DateTo: this.$route.params.DateTo, 
                                    IncCat: this.$route.params.IncCat, Reporter: this.$route.params.Reporter, Severity: this.$route.params.Severity,
                                    Building: this.$route.params.Building, Status: this.$route.params.Status }}) 
            }*/
        },

        async getIncidentReport() {            
            await IncidentReport.Get(this.IncidentId)
                .then((response) => { 
                    if(response.data){
                        this.IncidentItems = response.data
                        this.IncidentItems.header.incidentDate = Utils.formatterDate(this.IncidentItems.header.incidentDate)
                        this.IncidentItems.header.reportedDate = Utils.formatterDate(this.IncidentItems.header.reportedDate)
                        this.IncidentItems.header.signedDate = Utils.formatterDate(this.IncidentItems.header.signedDate)
                        this.incidentTimeFormatted = Utils.formatTimeHm(this.IncidentItems.header.incidentTime)
                        this.DisabledSave = false;
                        this.SetIcon(this.IncidentItems.header.status); 
                        
                        this.IncidentItems.participants.forEach(function(element) {
                            if (element.entityType === "P" && element.dob)	
                                element.dob = Utils.formatterDateToString(element.dob);                         
                                 
                        });
                        this.loadParticipants();
                        this.loadCategories();
                    }                             
                })
                .catch((error) => {                 
                    if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
                });
        },

        ChangeDropDownLocation(value) {
            this.IncidentItems.header.location = value ? value.code : ""; 
        },

        ChangeDropDownRoom(result) {
            this.IncidentItems.header.roomNo = result ? result.code :"";
        },

        ChangeIncidentTime(value) {            
            this.IncidentItems.header.incidentTime = Utils.setTimeToDateTime(this.IncidentItems.header.incidentTime,value)
        },

        async ExecuteAllCalls(){
            this.$emit("load", true);
            this.getLocation();
            this.getRooms();
            await this.getAllEmployee()
            if(this.ModeEdit == false){
                this.getDateTime();
            }else{
                await this.getIncidentReport();
                this.Employee = this.EmployeeList.find(el => el.code === this.IncidentItems.header.reporter)
            }
        },

        async CanSeeIncidentAdmin() {
     await IncidentReport.CanSeeIncidentAdmin()
          .then((response) => { 
            if(response.data){   
                 this.SeeIncidentAdmin = response.data;        
            }
          })
          .catch(error => {
            if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
          });  
    },

        async getRooms() { 
            await RoomsService.getAllRooms()
                .then((response) => {      
                if (response.data) {
                    this.RoomList = response.data;
                }  
            })
            .catch(error => {
                if (!error.response) {
                            this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
                        }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
                     }    
            }); 
         },

        closeParamRepWindow() {
            this.isReportVisible = false
        }, 
        
        DisplayBtn(){           
           // this.$emit('UpdateBtn', false);      
            EventBus.$emit('UpdateBtn', false);        
        },

        async getAllEmployee(){
        await EmployeeService.getAllEmployees()
            .then((response) => { 
               if(response.data && response.data.length > 0){
                this.EmployeeList = response.data
               }             
            })
            .catch((error) => {
               if (error.response) {
                  this.showMessage(this.$tc('Shared.Employee'),error,"error");
               }
            });
        },

        ChangeDropDownReporter(value){
            this.Employee = value
            this.IncidentItems.header.reporter = value ? value.code : '';
            this.IncidentItems.header.reporterName = value ? value.name : '';
        },
    },
 
 async mounted(){    
  /*  this.OptionsRightMenu.ItemsList =[]; 
    this.OptionsRightMenu.visible = true;  
   // this.OptionsRightMenu.btnvisible = true;       
    this.OptionsRightMenu.itemdisabled = false;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);   
    this.OptionsRightMenu.btnvisible = false;     
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);*/

    let layoutStatus = this.$store.state.layoutStatus;
    switch(layoutStatus) {
      case 'L0R1':
        this.OptionsRightMenu.favButtonAtion = true;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      case 'L1R0':
      case 'L1R1':
          this.OptionsRightMenu.btnvisible = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      default:
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
  
    
    this.VisibleCompleteDraf = this.$store.state.isUseCompleteDraft;  
    await this.getIncidentReportOptions();

    const allPromise = Promise.all([await this.ExecuteAllCalls()]);
    await allPromise.then(() => {
       this.$emit("load", false);
    }).catch(error => {       
        this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
    });

        if (this.ModeEdit == false) {    
            this.LastEditDateTime = Utils.formatterDate(Date(this.APIDateTime))
            this.SetIcon("New");   
            this.IncidentItems.header.reporterName = StorageService.getName()
            this.IncidentItems.header.reporter = this.$store.state.employeeCode
            this.IncidentItems.header.reportedDate = Utils.formatterDate(Date(this.LastEditDateTime))
            this.IncidentItems.header.incidentDate = Utils.formatterDate(Date(this.LastEditDateTime))            
            this.IncidentItems.header.incidentTime = Utils.formatterDate(Date(this.LastEditDateTime))
            this.IncidentItems.header.signedDate = Utils.formatterDate(Date(this.LastEditDateTime))
            this.IncidentItems.header.isSigned = true
            this.incidentTimeFormatted = Utils.formatTimeHm(Date(this.LastEditDateTime))
            this.Employee = this.EmployeeList.find(el => el.code == this.$store.state.employeeCode)
        } /*else { 
          
        }*/

        if (this.IncidentItems.header.isDraftComplete) {
            this.CompleteDraftLabel = 'Set Back to Draft'
            //this.CompleteDraftRO = this.Employee ? true : false
        } 
        
        if (this.IncidentItems.header.isDraftComplete == false) {
            this.CompleteDraftLabel = this.$t('Incident.IncCompleteDraft')
            this.CompleteDraftRO = this.$store.state.isUseCompleteDraft ? true : false
        }
        
        this.VoidIncidentRO = this.IncidentItems.header.isVoid || this.ModeEdit == false ? true : false
        this.ReportRO = this.ModeEdit ? false : true
        this.GetCategoryNameifPrimary();
        this.IncidentDateHeader = moment(this.IncidentItems.header.incidentDate)
                                        .locale(localStorage.language )
                                        .format("ddd, ll");

        if(this.IncidentItems.police.isPoliceInvolved == false){
            this.DisablePolice = true;
        }else{
            this.DisablePolice = false;
        }

        if(this.IncidentItems.cps.isCpsinvolved == false){
            this.DisableChildProtective = true;
        }else{
            this.DisableChildProtective = false;
        }

        this.DisplayBtn();
       
     },
 
    async created () {
        await this.CanSeeIncidentAdmin();
        EventBus.$on("onChanged", function (e) {                   
          this.pendingChanges = e.Changed          
        }.bind(this));
        window.addEventListener('beforeunload', this.beforeWindowUnload) 
     },

     destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
   } 
 </script>
 

 <style>

</style>
 
 