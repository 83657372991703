<template>
    <div id="mars-alerts">
        <div class="row">
            <div class="col-12">
                <MdTitleRegInfo
                    v-bind:RegistrationInfo="PatientTitle" 
                    v-bind:titlePrefix="!this.editMode ? $t('MdPatientMarsAlert.AddMarsAlert') : $t('MdPatientMarsAlert.EditMarsAlert')" 
                    v-bind:titleRef="'titleElementMarsAlert'"
                    v-bind:patientCode="this.$route.params.id">
                </MdTitleRegInfo>

                <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
                    <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                    <div class="flex-grow-1">
                        <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
                    </div>
                </div>
            </div>
        </div>

        <MdTitle v-bind:Text="$t('Shared.Description')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <b-row>
            <b-col md="12" lg="12">
                <MdMemo
                    v-bind:Name="'marsalertstextarea_description'"
                    v-bind:Rows="'20'"
                    v-bind:MaxRows="'10'"           
                    v-model="MarsAlertDescription"
                    v-bind:isReadOnly="isReadOnly"
                />                                     
            </b-col>
        </b-row>
        
        <div class="page-actions">
            <MdButton
                v-bind:isReadOnly="!editMode"
                v-bind:Label="$t('Shared.Report')"
                v-bind:Name="'btnpatientlist'"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="showReport()"/>
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="Save"
                v-bind:isReadOnly="isReadOnly || DisabledBtnSave"
                v-bind:Name="'btn_save_patient_mars_alerts'"
                v-bind:id="'btn_save_patient_mars_alerts'"
            />
        </div>
        <PatientMarsAlertsRep v-if="isReportVisible" 
            :closeWindow="closeParamRepWindow"
            :patientCode="this.$route.params.id"
            :patientName="this.PatientTitle.fullName"
            :patientDOB ="this.PatientTitle.dob"
        />     
    </div>
</template>

<script>
import Utils from "@/common/utils";
import DBFunctions from '@/common/DBFunctions.js'
import ShowMessage from '@/components/messages/ShowMessage.js'
import EnumService from '@/services/enum.service' 
import MarsAlertService from '@/services/marsalert.service'
import { EventBus } from '@/event-bus.js';

let elemSaveBtn = '';
export default {
    mixins: [ShowMessage, DBFunctions],
    name: 'EditMarsAlert',
    props: {
    },

    data() {
        return {
            patient:{},
            RegistrationInfo:{},
            PatientInfo:{},
            PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:'',
                activitytypename:'',
                startdatetime:'',
                ageFormatted:''
            },
            Menu : this.$store.state.leftMenu,
            permission:[],
            OptionsRightMenu:{
                visible:true,
                ItemsList:[],
                btnvisible:true,
                itemdisabled: false,
                PatientCode:''         
            },
            MarsAlertDescription:"",
            Loading:true,
            isReadOnly: false,
            pendingChanges: false,
            DisabledBtnSave:false,
            editMode: false,
            isReportVisible: false,
            recNoToReport: null,
            MarsAlert: []
        }
    },

    methods: {
        Save() {
            if (!this.isReadOnly) {
                this.$emit('load', true);
                elemSaveBtn.setAttribute("disabled",'');
                let param = {
                    PatientCode:this.$route.params.id,
                    Description:this.MarsAlertDescription
                }
            
                if (this.MarsAlert.length === 0){
                    MarsAlertService.Add(param)
                        .then((response) => {      
                            if (response.status == '200') {  
                                this.pendingChanges = false;
                                setTimeout(() => 
                                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                        title: this.$t('Msg.Saving'),
                                        autoHideDelay: 5000,
                                        variant: "success"
                                    })
                                    , 50
                                ); 
                                this.getMarsAlert();
                            } else {
                                this.$emit('load', false);  
                            }
                        })
                        .catch(error => {
                            elemSaveBtn.removeAttribute("disabled");
                            this.$emit('load', false);
                            if (!error.response) {            
                                this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),this.$t('Msg.RetrieveInformation'),"error");
                            } else {
                                this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),error,"error");
                            }
                        }); 
                } else {
                    MarsAlertService.Update(this.MarsAlert[0].patientCode,param)
                        .then((response) => {      
                            if (response.status == '200') {  
                                this.pendingChanges = false;
                                setTimeout(() => 
                                    this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                        title: this.$t('Msg.Saving'),
                                        autoHideDelay: 5000,
                                        variant: "success"
                                    })
                                    , 50
                                ); 
                                this.getMarsAlert();
                            } else {
                                this.$emit('load', false);
                            }
                        }
                    )
                    .catch(error => {
                        elemSaveBtn.removeAttribute("disabled");
                        this.$emit('load', false)
                        if (!error.response) {            
                            this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),this.$t('Msg.RetrieveInformation'),"error");
                        } else {
                            this.showMessage(this.$t('MdPatientMarsAlert.PatientMarsAlert'),error,"error");
                        }
                    }); 
                }
            }
        },

        async getMarsAlert() {
            this.MarsAlert = await this.getPatientMarsAlert(this.$route.params.id);
            this.MarsAlertDescription = this.MarsAlert.length > 0 ? this.MarsAlert[0].description : "";
            this.verifyEditMarsAlert();
            this.Loading = false;
            this.$emit('load', this.Loading);
        },

        async GetPatientByCode(){
            var infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0) {
                this.PatientTitle.programName = '';
                this.PatientTitle.admitDate = '';
                this.PatientTitle.dischargeDate = '';

                const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

                if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age;
                if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
            }
        },

        PopulatePatientTitle(){
            const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
            if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
            if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
            
            if(this.$route.name.includes('Clinical-')){ 
                if (programName) this.PatientTitle.programName = programName; 
                if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
                if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
                if (patientName) this.PatientTitle.fullName = patientName;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age === 0 ? "0" : age;
                if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
            }

            if(this.$route.name.includes('patient-')){         
                this.PatientTitle.programName = '';
                this.PatientTitle.dateFrom = '';
                this.PatientTitle.dateTo = '';
                this.PatientTitle.dob = this.patient.dob;             
                this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
            }
        },

        verifyEditMarsAlert() {
            const specialPrec = this.MarsAlert?.filter(x => x.description && x.description !== "");
            this.editMode = !(specialPrec && (specialPrec.length === 0));
        },

        beforeWindowUnload(e) {
            if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		},

        checkUserAction() {
            if (elemSaveBtn.disabled) {
                elemSaveBtn.removeAttribute('disabled');
            }
        },
        showReport() {
            this.isReportVisible = true
        },
        closeParamRepWindow() {
            this.isReportVisible = false
        },  
    },

    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUsePatientSpecialPr;
            if(iUA) next()
            else next('/accessdenied');
        }) 
    },

    beforeRouteLeave(to, from, next) {
        if (!this.isReadOnly && this.pendingChanges) {
            this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
                title: this.$tc('MdPatientMarsAlert.PatientMarsAlert'),
                okTitle: this.$t('Shared.Save'),
                cancelTitle: this.$t('Shared.BtnDismiss'),
                footerClass: 'p-2',
                titleClass: 'modal-title modal-type-warning'
            })
            .then(value => {
                if(!value){             
                    next();
                } else {
                    this.Save();
                    next();              
                }
            })		
        } else {
            next()
        }
    },

    async mounted() {
        this.$emit('load', this.Loading);  

        this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-') ? 
            EnumService.ClinicalItems : this.$route.name.includes('appointments-') ? EnumService.AppointmentItems : EnumService.PatientItems;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.isReadOnly = this.$store.state.isPatientMarsAlertEHRReadOnly; 

        if(this.$route.name.includes('patient-') || this.$route.name.includes('appointments-')){  
            this.GetPatientByCode();
        }
        
        if(this.$route.name.includes('Clinical-')){      
            this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
            this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
        }

        this.PopulatePatientTitle();
        await this.getMarsAlert();

        setTimeout( function() {
            elemSaveBtn = document.getElementById('btn_save_patient_mars_alerts');
            elemSaveBtn.setAttribute('disabled','');
        }, 50, this );
    },

    created(){
        this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
        EventBus.$on("onChanged", function (e) {       
            this.pendingChanges = e.Changed;
            this.checkUserAction()
        }.bind(this));
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    destroyed() {
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    }
}
</script>