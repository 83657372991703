<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditSignature" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="this.$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
       
        <div v-for="(behavioral, behaindex) in this.ModalBehavioralsList" :key="'Behavioral_' + behaindex">
          <b-row class="mt-2">         
            <b-col md="11">
                <MdMemo v-bind:Name="'behavioraldescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="true"
                            v-bind:DynamicClass="'modalTextarea ml-2'" v-bind:MaxRows="'6'"
                            v-model="behavioral.definitionDescription" />
            </b-col>
            <b-col lg="1" md="1" sm="12">
              <div class="subtitleButtons">
                  <div class="containerBoxTitle" style="top:0px !important;">                                   
                      <b-icon icon="trash-fill" v-on:click="DeleteBehavioral(behaindex)" v-b-tooltip.hover.top="$t('Shared.Delete')" scale=".5" font-scale="1.6" variant="light"></b-icon>                                 
                  </div>
              </div>
          </b-col>
           </b-row>  
        </div>
       
       
     </b-modal>  
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 //import IncidentReportInterventionsSetups from '@/services/incidentreportinterventionssetups.service'
 //import IncidentReportSeveritySetup from '@/services/incidentreportseveritysetup.service'
 //import IncidentSetup from '@/services/incidentsetup.service'
 import TreatmentPlan from '@/services/treatmentplan.service'

 export default {
   name: 'ModalEditBehavioral',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     BehavioralsProp:Array,
     ProblemRecId:String,
   },
   
   data(){
     return {
        behavioraldescription:"",       
        ModalBehavioralsList: [...this.BehavioralsProp]       
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
       /*if (this.validateFields()) {
         this.categoryItems.incidentTypes = this.IncidentCodes
         this.setInterventions()
         if (this.ModeEdit == false) {
           this.categoryItems.id = Utils.generateRecID()
         }               
         this.$emit('getCodeConditionOK',this.categoryItems);
       } else {       
         bvModalEvent.preventDefault()
       }    */  
       this.UpdateBehavioral();
     },

     UpdateBehavioral(){        
          let datadef = {
            definitions:this.ModalBehavioralsList
          };
         TreatmentPlan.UpdateDefinitions(this.ProblemRecId,datadef)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.BehavioralSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 20); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalBlankBehavioral.behaviorals'),error.response.data.errorMessage,"error");
                     }    
            });
       
    },

     DeleteBehavioral(index){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecordOnly'), {
					title: 'Delete item',
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_tp_behavioral'
				})
        .then(value => {
          if(value){
            this.ModalBehavioralsList.splice(index, 1);            
          } 
        }) 
     },

       
     emitEventCancel(){
       this.$emit('ModalTPEditBehavioralCANCEL');
    },    
   },
 
  mounted(){
  
   },
 };
 </script>

