<template>

<div>
	<div class="row mt-2" v-for="Item in Items" :key="Item.patientCode">
		<div class="float-left col-md-4 text-muted" style="background-color: lightgray;">
			<small>patientName</small><br>
			<b>{{Item.patientName}}</b>
		</div>
		<div class="float-left col-md-4 text-muted" style="background-color: lightgray;">
			<small>DOB</small><br>
			<b>{{formatDOB(Item.dob)}}</b>
		</div>
		<div class="float-left col-md-4 text-muted" style="background-color: lightgray;">
			<small>SEX</small><br>
			<b>{{Item.sex}}</b>
		</div>
		<div class="row mt-2" v-for="Item2 in Item.timedAdministrations" :key="Item2.recordID" style="margin-left: 0px;">
			
					<div class="float-left col-md-2 text-muted">
						<b>{{Item2.drugCode}}</b>
					</div>
					<div class="float-left col-md-10 text-muted">
						<b>{{Item2.sig}}</b>
					</div>
		</div>
	</div>
</div>
</template>

<script>
import Utils from '@/common/utils'
export default {
    props: {
	Items: Array    
   },
    data() {
      return {
      }
    },
    
  methods:{
	formatDOB(dob) {
		return Utils.formatterDate(dob)
	}
  },
  mounted() {

  }
}

</script>