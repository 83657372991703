<template>
  <div id="ContainerLogNotes">
    <div class="row">
      <div class="col-12">
        <MdTitleRegInfo
            v-bind:RegistrationInfo="RegistrationInfo" 
            v-bind:titlePrefix="$t('LogNoteList.LogNotesTitle')" 
            v-bind:titleRef="'titleElementLogNotes'"
            v-bind:patientCode="this.$route.params.id">
        </MdTitleRegInfo>
      
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
          <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
          <div class="flex-grow-1">
            <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-3">
            <MdButton
              v-bind:Label="$t('LogNoteList.AddNote')" 
              v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"
              v-on:click="Add"
              v-bind:isReadOnly="this.isReadOnly || this.AddButtonDisabled"
              v-bind:Name="'btn_add_lognote'">
            </MdButton>  
          </div>
          <div class="col-sm-12 col-md-3">  
            <MdSelect
              v-bind:Label="$t('Shared.NoteType')"
              v-bind:Options="NoteTypesList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'notesId'"
              v-bind:Model="ModelNoteType"
              v-bind:Multiple="false"              
              :Input="ChangeDropDownNodeType"
              v-bind:isValid="this.isNoteTypeValid"
              v-bind:AllowEmpty="false"
              v-bind:Name="'note_type_select'"
              id="note_type_select"
            />
          </div>
          <div class="col-sm-12 col-md-6 mb-4">
              <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                  <div class="input-group">
                      <input 
                        type="text" 
                        class="form-control"
                        v-model="LogNotesParams.SearchCriteria"
                        @keyup.enter="getListing"
                      >
                      <div class="input-group-append">
                            <MdButton 
                            v-bind:Name="'btnsearchinput'"
                            v-bind:VariantProp="'btn btn-sm search-attached'"
                            v-bind:IconClass="'mdi mdi-magnify'"
                            v-on:click="getListing">
                          </MdButton>
                      </div>
                  </div>
              </div>
          </div>
        </div>            
        </div>
    </div>
    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <MdTable 
            v-bind:fields="fields"
            v-bind:items="items"
            v-bind:totalRows="totalRows"
            v-bind:isReadOnly="isReadOnly"
            v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
            v-bind:currentPage="currentPage"
            v-bind:perPage="perPage"
            v-bind:pageOptions="pageOptions"
            v-bind:VisibleEditIcon="true"          
            v-bind:VisibleDeleteIcon="true"
            v-bind:BtnValidateEmployee="true"
            :onFiltered="onFiltered"
            :pageChanged="pageChanged"
            :perPageFunction="PerPageFunction"
            :onRowSelected="onRowSelected"
            :editIconEvent="Edit"
            :deleteIconEvent="deleteItem"
            :otherIcon="'printer-fill'"
            :otherIconEvent="showReport">
          </MdTable>
      </div>
    </div>

    <div class="space-actions"></div>
    <div class="page-actions">
      <MdButton
        v-bind:isReadOnly="items.length == 0"
        v-bind:Label="$t('Shared.Report')"
        v-bind:Name="'btnlogreport'"
        v-bind:ClassProp="'secondary mx-2 btn-login'"
        v-on:click="showReport()"/>
    </div>

    <LogNotesRep v-if="isReportVisible" 
      :closeWindow="closeParamRepWindow"
      :patientCode="this.$route.params.id"
      :intakeDate="intakeDate"
      :noteTypeName="getNoteTypeName()"
      :patientName="this.RegistrationInfo.patientName"
      :headerID="this.$route.params.recordid"
      :notesID="this.ModelNoteType"
      />
  </div>
</template>



<script>
import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'
import BioSetupService from '@/services/biosetup.service'
import LocalStorage from '@/services/storage.service';
import MedicalSignNotesService from "@/services/medicalsignnotes.service";
import UserPreference from "@/services/quickhelp.service";
import { EventBus } from '@/event-bus.js';

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUseLogNotes;
            if(iUA) next()
            else next('/accessdenied');
        }) 
  },

  mixins: [ShowMessage, DBFunctions],
  name: 'LogNotesList',
  data() {
    return {      
      fields: [
      { key: 'comments', sortable: true, label: this.$tc('Shared.Note', 2), class: 'text-wrap', generated:false },
        { key: 'intakeDate', sortable: true, label: 'Intake Date', class: 'hidden', generated:false},
        { key: 'employeeId', sortable: true, label: 'employeeId', class: 'hidden', generated:false},
        { key: 'employeeName', sortable: true, label: this.$tc('Shared.Employee'), class: '', generated:false}, 
      ],
      Loading:true,
      EmployeeCode:"",
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
      },
      NotesTitlesParams:{
        SearchCriteria: '',
        PageNumber : 0,
        PageSize: 0,
        NoteType: 'TFrmMdLogNotes'      
      },
      LogNotesParams:{
        SearchCriteria:'',
        PageNumber : 3,
        PageSize: 3,
        HeaderID:null,
        NotesID:null        
      },                        

      isReadOnly: false,
      RegistrationInfo:{},
      NoteTypesList: [],
      ModelNoteType: null,
      items: [],
      UserId: LocalStorage.getUserId(),
      LogNotesPreference: {'lastLogNote':''},
      isNoteTypeValid: true,
      LogNotesIsVoid: {'isVoid':'','reasonName':''},
      showReason: false,
      idLogNote: null,
      canInsert: false,
      AddButtonDisabled: false,
      emptyRecordsStyle: {
        backgroundColor: '#CF0',
        fontWeight: 'bold !important',
        textTransform: 'uppercase',
        padding: '2px',
        borderRadius: '50px',
        animation: 'fadeOut 10s ease-in-out infinite'
      },
      showEmptyRecords: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 0, 
      Header:{},
      options: [
          { value: 5, text: '5' },
          { value: 10, text: '10' },
          { value: 15, text: '15' },
      ],
      perPageStyle: {
        width: 'auto'
      },
      isReportVisible: false,
      intakeDate: null,
      LogNotesRows: [],
      ShowMessageIsEmpty: false,  
      pageOptions: [5,10,20,30,40,50],	   
      selected: [],      
    }
  },

  methods: {
    
    deleteItem(item) {
      if (item && item.employeeId) {
        if (item.employeeId.toUpperCase() == this.EmployeeCode.toUpperCase()) {
          this.DeleteLogNote(item)
        } else {
          this.showMessage(this.$t('LogNoteList.LogNotesTitle'), this.$t('LogNoteList.NotAbleToDelete'), "warning");
        }
      }
    },
    
    onRowSelected() {},

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.getLogNotes() 
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getLogNotes()    
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    
    closeParamRepWindow() {
      this.isReportVisible = false
      this.intakeDate = null
    },
    
    showReport(item) {
      if(this.items.length > 0){
        if (item && item.intakeDate) {
          this.intakeDate = Utils.formatDateTime(item.intakeDate, Utils.getFullDateTimeFormat());
        }
        this.isReportVisible = true
      } else {
        setTimeout(() => 
          this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
            title:  this.$t('LogNoteList.LogNotesTitle'),
            autoHideDelay: 3000,
            variant: "success"
          }),
        50);
      }
    },

    perPageChange(value) {
      this.perPage = value
      this.getLogNotes()
    },
    
    onChange(page) {
      this.currentPage = page
      this.getLogNotes()
    },

    async Add() {
      if (!this.ModelNoteType) {
        this.showMessage(this.$t('LogNoteList.LogNotesTitle'),this.$t('LogNoteList.MandatoryValue'),"errorAutoHide");
        this.isNoteTypeValid = false
      }
      else {
        let NoteTypeName = this.findNoteTypeName(this.NoteTypesList, this.ModelNoteType).description
        this.$router.push({ name: "lognote-add", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, lognoteid: this.ModelNoteType, noteTypeName: NoteTypeName} });
      }
    },

    getNoteTypeName() {
      return this.findNoteTypeName(this.NoteTypesList, this.ModelNoteType).description
    },

    Edit(item) {           
      if (item && item.employeeId) {
        if (item.employeeId.toUpperCase() == this.EmployeeCode.toUpperCase()) {
          this.$router.push({ name: "lognote-details", params: { id: this.$route.params.id, recordid: this.$route.params.recordid, lognoteid: this.ModelNoteType, intakedate:  Utils.formatDateTime(item.intakeDate, Utils.getFullDateTimeFormat()), noteTypeName: this.getNoteTypeName()}});
        } else {
          this.showMessage(this.$t('LogNoteList.LogNotesTitle'), this.$t('LogNoteList.NotAbleToEdit'), "warning");
        }
      }      
    
    },

    findNoteTypeName (array, code) {
      return array.find(element => element.notesId === code) ?? -1;
    },

    getListing() {
      this.$emit('load', true);
      this.LogNotesRows = [] 
      this.currentPage = 1
      this.totalRows = this.LogNotesRows.length
      this.LogNotesParams.PageNumber = 1;
      this.getLogNotes()
    },

    ChangeDropDownNodeType(value) {
      this.DeleteDinamicField();
      this.AddButtonDisabled = false;
      this.ModelNoteType = value ? value.notesId : ''
      this.isNoteTypeValid = value ? true : ''
      this.UpdatePreference(value ? value.notesId : null)
      this.getLogNotes()
    },

    generateFields(item){
      this.fields.splice(0, 0, { key: 'intakeDateFormatted', sortable: true, label: this.$t('Shared.Date'), class: '', generated:false});
      let arrfields = item.fields;
      let reverse = arrfields.slice().reverse();    
      reverse.forEach(fitem => {  
        this.fields.splice(1, 0, { key:fitem.description , sortable: true, label: fitem.description, class: 'text-wrap', generated:true });
      });      
      this.OnSetField();
    },

    OnSetField(){  
      EventBus.$emit('onSetField', this.fields);   
    },

    DeleteDinamicField(){
      var arr = this.fields;
      let temp = [];
      arr.forEach(item => {        
          if(!item.generated){
            temp.push(item);
          }
      });
      this.fields = temp;
      this.OnSetField(); 
    },


    PopulateItems(arr){     
      this.items = [];
      arr.forEach(item => {
        let obj = {}      
        obj['intakeDate'] = Utils.formatterDateTimeAndSecondsToString(item['intakeDate']);
        obj['intakeDateFormatted'] = Utils.formatterDateTimeToString(item['intakeDateFormatted']);
        //obj['intakeDate'] = Utils.formatterDateTimeToString(item['intakeDate']) + ' ' +  Utils.formatTimeWSec(item['intakeDate']);
        obj['employeeName'] = item['employeeName'];       
        obj['employeeId'] = item['employeeId'];  
        let fields = item['fields'];

       /* for(var i = fields.length; i<0; i--){
          obj[fields[i].description] = fields[i].intakeValue
        }*/
       fields.forEach(f => {
          obj[f.description] = f.intakeValue
        });
        
        obj['comments'] = item['comments'];  
        this.items.push(obj)
      });    
    },
    
    createRows() {
      this.LogNotesRows = []
      this.fields = []
      this.items.forEach(item => {
        let obj = {}

        obj['actions'] = ''
        obj['Date'] = Utils.formatterDateToString(item['intakeDate']) + ' ' +  Utils.formatTimeWSec(item['intakeDate']);
        obj['Employee'] = item['employeeName']
        
        let fields = item['fields']
        fields.forEach(f => {
          obj[f.description] = f.intakeValue
        });        
        obj['Notes'] = item['comments']
        this.LogNotesRows.push(obj)
      });
    },

    async getNotesTitles() {
      await BioSetupService.getAll(this.NotesTitlesParams)
        .then((response) => {
          if (response.status == "200" && response.data && response.data.length > 0) {
            this.NoteTypesList = response.data;
          }
        })
        .catch((error) => {
          if (!error.response) {
            // network error
            this.showMessage(
              this.$t('LogNoteList.LogNotesTitle'),
              this.$t('Msg.retrieveInfo'),
              "error"
            );
          } else {
            this.showMessage(this.$t('LogNoteList.LogNotesTitle'), error, "error");
          }
        });
    },

    DeleteLogNote(item) {
      let IntakeDate = Utils.formatDateTime(item.intakeDate)
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$t('LogNoteList.LogNotesTitle'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle:this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning',
        id:'modal_delete_vitals'
      })
      .then(value => {
        if(value) {
          MedicalSignNotesService.delete(this.$route.params.recordid,IntakeDate)
          .then((response) => {
            if (response.status == "200") {
              this.getLogNotes()
            }
          })
          .catch((error) => {
            if (!error.response) {
            // network error
              this.showMessage(
              this.$t('LogNoteList.LogNotesTitle'),
              this.$t('Msg.retrieveInfo'),
              "error"
              );
            } else {
              this.showMessage(this.$t('LogNoteList.LogNotesTitle'), error, "error");
            }
          });
        }
      })
    },

   

    async getLogNotes() { 
      this.LogNotesRows = [];     
      this.LogNotesParams.PageNumber = this.currentPage;
      this.LogNotesParams.PageSize = this.perPage;
      this.LogNotesParams.HeaderID = this.$route.params.recordid;
      this.LogNotesParams.NotesID = this.ModelNoteType;

      
      this.$emit('load', true);      
      if(this.ModelNoteType){
      await MedicalSignNotesService.getAll(this.LogNotesParams)
        .then((response) => {
          if (response.status == "200" && response.data && response.data.length > 0) {           
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
            this.ShowMessageIsEmpty = response.data && response.data.length == 0 ? true : false;
            this.generateFields(response.data[0]);
            this.PopulateItems(response.data);          
            this.$emit("load", false);
          }else{
            this.items = [];          
            this.DeleteDinamicField();
            this.totalRows = 0;
            this.currentPage = 1;
            this.ShowMessageIsEmpty = true;          
          }
          this.$emit("load", false);
        })
        .catch((error) => {
          this.$emit("load", false);
          if (!error.response) {            
            this.showMessage(
              this.$t('LogNoteList.LogNotesTitle'),
              this.$t('Msg.retrieveInfo'),
              "error"
            );
          } else {
            this.showMessage(this.$t('LogNoteList.LogNotesTitle'), error, "error");
          }
        });
    
      }else{
        //this.fields = [];
        //EventBus.$emit('onSetField', this.fields);  
        this.DeleteDinamicField();
        this.items = [];
        this.$emit('load', false);
        this.totalRows = 0;
        this.ShowMessageIsEmpty = true;
        this.currentPage = 1;
      }
      
      },

    async getUserPreference() {
      await UserPreference.getQhUserPreference(this.UserId)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            let LastLogNote = response.data[0].lastLogNote ;
            LastLogNote = LastLogNote == null ? '' : LastLogNote
            this.AddButtonDisabled = LastLogNote ? false : true
            if (LastLogNote) {
              this.ModelNoteType = this.findNoteTypeName(this.NoteTypesList, LastLogNote).notesId
            }
          } 
        })
        .catch((error) => {
          if (error.errorMessage) {
            this.showMessage(this.$t('LogNoteList.LogNotesTitle'), error, "error");
          }
        });
    },

    UpdatePreference(value){
      this.LogNotesPreference.lastLogNote = value
      UserPreference.UpdateLastLogNotes(this.UserId, this.LogNotesPreference)
        .catch((error) => {
          if (!error.response) {
            this.showMessage(this.$t('LogNoteList.LogNotesTitle'),this.$t('Msg.NotAbleToUpdate'),"error");
          }          
          else if (error.response.data && error.response.data.errorMessage) {
            this.showMessage(this.$t('LogNoteList.LogNotesTitle'),error.response.data.errorMessage,"error");
          }
          else {
            this.showMessage(this.$t('LogNoteList.LogNotesTitle'),this.$t('Msg.ThereWereSomeError'),"error");
          }
        });
    },

  },

  created(){   
    this.EmployeeCode = this.$store.state.employeeCode;
  },


  async mounted() {
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
    this.isReadOnly = this.$store.state.isLogNotesEHRReadOnly;
    await this.getNotesTitles()
    await this.getUserPreference()
    if(this.ModelNoteType === null || this.ModelNoteType === ""){      
      this.ModelNoteType = this.NoteTypesList.length > 0 ? this.NoteTypesList[0].notesId : "";
      this.AddButtonDisabled = false;
    }
    this.getLogNotes()

    this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);   
    const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

		if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
		if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
		if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
		if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
  },
};

</script>