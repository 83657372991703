<template>	
    <div id="patientDocumentAttachment">
    <b-modal id="modalIdDocumentAttachment" size="xl"  ok-only ok-variant="secondary" :ok-title="$t('Shared.Close')" scrollable  :title="TitleWindow" :cancel-title="$t('Shared.BtnCancel')"
    
    @close="Close"
    @hidden="resetModal"    
    > 
   
   <!--<iframe id="iframe" v-if="this.Document.extension === '.pdf' || this.Document.extension === '.PDF'" :src="this.Url"  width="100%" height="500px"  frameborder="0"></iframe>--> 
    <iframe id="iframe" :src="this.Url" width="100%" height="500px"  frameborder="0"></iframe>
   
    

        <!--<iframe id="serviceFrameSend" src="http://imagenes.medezweb.com/88206.jpg" width="1000" height="1000"  frameborder="0"></iframe>-->
       <!-- <iframe id="serviceFrameSend" src="http://docs.medezweb.com/MedEZISAPI_IIS Install.docx" width="1000" height="1000"  frameborder="0"></iframe>  -->

        
       <!--<template #modal-footer="{ ok, cancel }">
          <b-button @click="cancel()">
            Cancel
          </b-button>
          <b-button v-if="isCameraOpen" variant="primary" @click="takePhoto">
            <span v-if="isPhotoTaken">Renew Picture</span>
             <span v-else>Take Picture</span>
           </b-button>
           <b-button v-if="!isCameraOpen" :variant="'primary'" @click="toggleCamera">
             <span>Open Camera</span>          
         </b-button>
          <b-button v-if="isPhotoTaken" variant="primary" @click="ok">
            Save Picture
          </b-button>
        
        </template>-->
  
  
  
    </b-modal>   
    </div>   
</template>
  
  <script>
//import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
  import DBFunctions from '@/common/DBFunctions.js'
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import { EventBus } from '../../event-bus.js';
  
  export default {
  name: 'modalIdDocumentAttachment',
  props: {     
    TitleWindow:String,
    Url:String,
    Document:Object
   },
   /*components: {
    VuePdfEmbed,
  },*/
    mixins: [DBFunctions,EventBus,ShowMessage],
    data() {
    return {
     // source: '',
     /*source: {
        data: btoa(this.Document.contents),
      },*/
    }
  },
  
  mounted() {  
   //  var d =  document.getElementById('iframe');
   //  d.src='data:application/pdf;base64,'+this.Document.contents;
  // this.source = 'data:application/pdf;base64,'+this.Document.contents;
    },
  
  created(){ 
   
  },
  
  beforeDestroy () {     
     // EventBus.$off('PhotoWasTaken');  
    },
  
  methods:{
  
    resetModal(){
     
    },

    getType(){

    },

    Close(){

    },
  
  
    handleOk(/*bvModalEvt*/) { 
   
      },
  
  
  
  
    emitEventCancel(){
    
      }, 
  
      handleSubmit() {   

      },
  
     emitEventOK(){  
         
      },
  },
  
  }
  
  
  </script>
  