<template>
    <div>
     <b-modal :id="ModalId" :visible="true" no-close-on-backdrop no-close-on-esc size="md" :hide-footer="this.ReadOnly" :title="TitleWindow" ref="modalparticipantclient" @close="this.emitEventCancel" @ok="this.emitEventOK" @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')">        
       <b-row>
         <b-col md="12" lg="12" sm="12">
           <MdMemo
             v-bind:Name="'description_addendums'"
             v-bind:Rows="'8'"
             v-bind:MaxRows="'6'"
             v-bind:isReadOnly="this.ReadOnly"
             v-bind:Tag="this.Tag"
             v-model="Addendums"/>
         </b-col>
       </b-row>

       <b-row v-if="this.ReadOnly">
        <b-col md="12">
            <b-button class="mt-4 pull-right btn-login" @click="$bvModal.hide('modalIdAddendumsDesc')">Close</b-button>         
        </b-col>
    </b-row>
     </b-modal>   
    </div>
</template>
 
 <script>
 import ShowMessage from '@/components/messages/ShowMessage.js'
 //import GlobalService from '@/services/global.service'
 //import Utils from '@/common/utils'
 
 export default {
   name: 'ModalAddendums',
     mixins: [ShowMessage],  
    props: {
      Tag:String,
      ModalId: String,
      TitleWindow: String, 
      ReadOnly:Boolean,
      AddendumsDesc:[String,Object,null]
    },
    data(){
       return {
        Addendums:this.AddendumsDesc,
       }
    },
 
    methods:{
 
       
       emitEventOK(/*bvModalEvent*/){    
        /*if (this.validateFields()) {
          if (this.ModeEdit == false) {
            this.participantItems.entityType = 'P'           
            this.participantItems.id = Utils.generateRecID()
          }                    
          this.$emit('getCodeConditionOK',this.participantItems);
        } else {
          bvModalEvent.preventDefault()
        }   */ 
        let obj = {description:this.Addendums};
        this.$emit('getCodeConditionOK',obj);
       },
       
       emitEventCancel(){
         this.$emit('getCodeConditionCANCEL');
       }, 
 
    },
 
   mounted(){ 


   },
 };
 </script>