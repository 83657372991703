<template>
    <div>
      <MdTitle v-bind:Text="$t('dcs.dpOfChildren')"/>
      <vue-good-wizard
        ref="wizard"
        :steps="steps"
        :finalStepLabel="''"
        :onNext="nextClicked" 
        :onBack="backClicked">
        <div slot="filter">
       <b-row>
        <b-col lg="3" md="3" sm="12">         
            <MdSelect
            v-bind:Label="$t('dcs.month')"
            v-bind:Options="MonthList"
            v-bind:Model="MonthModel"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="false"            
            :Input="ChangeDropDownMonth"/>
            <b-badge variant="danger" v-if="inputMonthError != ''">{{ inputMonthError }}</b-badge>
          </b-col>

          <b-col lg="3" md="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('dcs.year')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'Year'"
                v-model="YearModel"               
            >                                     
            </MdEdit>
            <b-badge variant="danger" v-if="inputYearError != ''">{{ inputYearError }}</b-badge>
          </b-col>

          <b-col lg="3" md="3" sm="12">         
            <MdSelect
            v-bind:Label="$t('dcs.mode')"
            v-bind:Options="ModeList"
            v-bind:Model="ModeModel"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:AllowEmpty="false"
            v-bind:Multiple="false"             
            :Input="ChangeDropDownMode"/>
            <b-badge variant="danger" v-if="inputModeError != ''">{{ inputModeError }}</b-badge>
          </b-col>

          <b-col lg="3" md="3" sm="12">         
            <MdSelect
            v-bind:Label="$t('dcs.sortOpt')"
            v-bind:Options="SortList"
            v-bind:Model="SortModel"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="false"             
            :Input="ChangeDropDownSort"/>
          </b-col>
       </b-row>
        </div>
        <div slot="general">
         <b-row>
            <b-col lg="6" md="6" sm="12"> 
                <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <MdCheckListBox2 
                  v-model="NotesModel"
                  v-bind:Options="NotesList"
                  v-bind:OnChangeProp="OnChangeNotes"
                  v-bind:Height="'300px'"
                  v-bind:columnCount="1"
                  :textField="'name'"
                  :valueField="'code'"/> 
              </b-col>    
              <b-col lg="6" md="6" sm="12"> 
                <MdTitle v-bind:Text="$t('dcs.tables')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <MdCheckListBox2 
                  v-model="TablesModel"
                  v-bind:Options="TablesList"
                  v-bind:OnChangeProp="OnChangeTable"
                  v-bind:Height="'300px'"
                  v-bind:columnCount="1"
                  :textField="'name'"
                  :valueField="'code'"/> 
              </b-col>   
         </b-row>
        </div>
        <div slot="production">
          <b-button-group>
            <MdButton
                v-bind:Label="$t('dcs.vSpreadsheet')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="ViewDCS"
                v-bind:isReadOnly="errorSystem != '' || errorMessage != '' || disabledButton == true"
            />
            <MdButton
                v-bind:Label="$t('dcs.vExceptions')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="ViewException"
                v-if="exceptionList.length != 0"
            />
            <MdButton
                v-bind:Label="$t('dcs.dDCF')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="DownloadDCS"
                v-bind:isReadOnly="errorSystem != '' || errorMessage != '' || disabledButton == true"
            />
            <MdButton
                v-bind:Label="$t('dcs.vBatchErrors')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="ViewBatchErr"
                v-if="errorMessage != '' && warningMessage != ''"
            />
          </b-button-group>
          <DxPoPupTable v-if="isVisible" 
              :Title="$t('dcs.review')"
              :closeWindow="closeRepWindow"
              :isVisible="isVisible"
              :items = "this.items"
              :ShowMessageIsEmpty="showMessageIsEmpty"
              :onRowSelected="onRowSelected"
          />
        </div>
        <div slot="review">
          <MdTitle v-bind:Text="$t('dcs.rvError')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <MdButton
              v-bind:Label="$t('dsc.vSpreadsheet')"
              v-bind:ClassProp="'secondary mx-2 btn-login'"
              v-on:click="ViewDCS"
              v-bind:isReadOnly="errorSystem != '' || errorMessage != '' || disabledButton == true"
            />
            <MdButton
              v-bind:Label="$t('dsc.vExceptions')"
              v-bind:ClassProp="'secondary mx-2 btn-login'"
              v-on:click="ViewException"
              v-if="exceptionList.length != 0"
            />
            <MdButton
                v-bind:Label="$t('dcs.dDCF')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="DownloadDCS"
                v-bind:isReadOnly="errorSystem != '' || errorMessage != '' || disabledButton == true"
            />
            <DxPoPupTable v-if="isVisible" 
              :Title="$tc('dcs.review')"
              :closeWindow="closeRepWindow"
              :isVisible="isVisible"
              :items = "this.items"
              :ShowMessageIsEmpty="showMessageIsEmpty"
              :onRowSelected="onRowSelected"
            />
        </div>
      </vue-good-wizard>
      <div class="space-actions"></div>
      <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Back')"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-on:click="GotoDcsList"
          />
      </div>
      <b-modal ref="progress-modal" size="md" hide-footer :title="$t('dsc.vSpreadsheet')">
        <MdProgress
          v-bind:ShowProgress="true"
          :value="progressVal"
          v-bind:Max="100"
          v-bind:Label="label"
        />
      </b-modal>
      <b-modal ref="batch-modal" size="md" hide-footer title="Claim Batch Error">
        <div class="d-block text-center">
          <h5 v-if="errorMessage != ''">{{$tc('dsc.error')}}: {{ errorMessage }}</h5>
          <h5 v-if="warningMessage != ''">{{$t('dsc.warnings')}}: {{ warningMessage }}</h5>
          <div class="text-center my-2" v-if="errorMessage == '' && warningMessage == ''">
            <b-badge pill variant="light" style="font-size: medium;">{{$t('dsc.nErrorWarmsg')}}</b-badge>
          </div>
        </div>
      </b-modal>
    </div>
</template>
  
  <script>
  import DCSService from '../../../services/dcs.service';
  import ShowMessage from '@/components/messages/ShowMessage.js';
  import DBFunctions from '@/common/DBFunctions.js';
  import * as CSV from 'csv-string';
  
  export default {
    mixins: [ShowMessage, DBFunctions],
    name: 'DCS',
    data(){
      return {
        MonthModel:"",
        ModeModel:{},
        SortModel:"",
        YearModel:"",
        NotesModel:[],
        TablesModel:[],
        perPage: 10,
        currentPage: 1,
        items:[],
        fields:[],
        SelectedPage : 0,
        totalRows: 0,
        MonthList:[
            {name: this.$t('Layout.dMonth.January'),code:1},
            {name: this.$t('Layout.dMonth.February'),code:2},
            {name: this.$t('Layout.dMonth.March'),code:3},
            {name: this.$t('Layout.dMonth.April'),code:4},
            {name: this.$t('Layout.dMonth.May'),code:5},
            {name: this.$t('Layout.dMonth.June'),code:6},
            {name: this.$t('Layout.dMonth.July'),code:7},
            {name: this.$t('Layout.dMonth.August'),code:8},
            {name: this.$t('Layout.dMonth.September'),code:9},
            {name: this.$t('Layout.dMonth.October'),code:10},
            {name: this.$t('Layout.dMonth.November'),code:11},
            {name: this.$t('Layout.dMonth.December'),code:12},
        ],
        ModeList:[
            {name:this.$t('dcs.review'),code:1},
            {name:this.$t('dcs.production'),code:2}
        ],
        SortList:[
          {name:"TherapyCode, PatientCode, ServiceDate",code:0},
          {name:"PatientCode, ServiceDate, TherapyCode",code:1},
          {name:"InvoiceNumber, ServiceDate", code:2}
        ],
        NotesList:[],
        TablesList:[],
        TempTable:[],
        pageOptions: [5, 10, 15 ],
        steps: [
          {
            label: this.$t('dcs.filters'),
            slot: 'filter',
          },
          {
            label: this.$t('dcs.dcsItems'),
            slot: 'general',
          },
          {
            label: this.$t('dcs.rvError'),
            slot: 'review',
          }
        ],
        noteKeys: [],
        tableKeys: [],
        datasheet: [],
        tableControlList: [],
        errorSystem: "",
        url: "",
        isVisible: false,
        errorMessage: "",
        warningMessage: "",
        inputMonthError: '',
        inputYearError: '',
        inputModeError: '',
        disabledButton: false,
        exceptionList: [],
        downloadfile: [],
        version: '',
        showProgress: false,
        progressVal: 0,
        interval: '',
        label: "",
        tableError: false,
        ComparedTable: [],
        TempTableModel: [],
        showMessageIsEmpty: false
      };
    },
    methods: {
      nextClicked(currentPage) {
        const _this = this
        this.inputMonthError = ""
        this.inputModeError = ""
        this.inputYearError = ""
        if(currentPage == 0){
          if((_this.YearModel != "") && (_this.MonthModel.name != undefined) && (_this.ModeModel.name != undefined)){
            this.PopulateNotes()
            _this.$refs.wizard.goTo(currentPage)
          }
          else {
            if(_this.YearModel == ""){
              this.inputYearError = this.$t('dcs.mInMsg')
            }
            if(_this.MonthModel.name == undefined) {
              this.inputMonthError = this.$t('dcs.mInMsg')
            }
            if(_this.ModeModel.name == undefined){
              this.inputModeError = this.$t('dcs.mInMsg')
            }
            this.showMessage(this.$t('DCS Dashboard'), this.$t('dcs.mInMsg'), "error")
            return false;
          }
        }
        
        if(currentPage == 1){
          if((_this.NotesModel.length != 0) && (_this.TablesModel.length != 0)){
            _this.$refs.wizard.goTo(currentPage)
            if(_this.ModeModel.code == 1){
              this.$refs['progress-modal'].show()
              this.showProgress = true
              this.DCSReview(_this.TablesModel).then(() => {
                this.$refs['progress-modal'].hide()
                if(this.errorSystem == ''){
                  this.ViewDCS()
                }
              })
            }
            if(_this.ModeModel.code == 2){
              this.$refs['progress-modal'].show()
              this.showProgress = true
              this.DCSProduction(_this.TablesModel).then(() => {
                this.$refs['progress-modal'].hide()
                if(this.errorSystem == ''){
                  this.ViewDCS()
                }
              })
            }
          }
          else {
            this.showMessage(this.$t('DCS Dashboard'), this.$t('dcs.mInMsg'), "error")
            return false;
          }
        }
        return true
      },
      backClicked(currentPage) {
        if(currentPage == 2){
          this.TablesModel = []
          this.NotesModel = []
          this.TablesList = []
          this.progressVal = 0
        }
        if(currentPage == 1){
          this.NotesList = []
          this.TablesModel = []
          this.NotesModel = []
          this.TablesList = []
          this.tableControlList = []
        }
        return true; //return false if you want to prevent moving to previous page
      },
      ChangeDropDownMonth(month){
        if(month){
          this.MonthModel = month
        }
      },
      onFiltered(){

      },

      OnChangeNotes(){
        this.TablesList = this.tableControlList.filter((item) => this.NotesModel.includes(item.note))
        this.TablesModel = []
        this.TablesList.forEach(element => {
          this.TablesModel.push(element.code)
        });
        if(this.NotesModel.length == 0){
          this.TablesList = []
        }
      },

      OnChangeTable(){
        this.TempTableModel = this.TablesList.filter((item) => this.TablesModel.includes(item.code))
        this.ComparedTable = []
        this.TempTableModel.forEach(element => {
          this.ComparedTable.push(element.note)        
        });
        this.TempNoteList = this.NotesList.filter((item) => this.ComparedTable.includes(item.code))
        this.NotesModel = []
        this.TempNoteList.forEach(element => {
          this.NotesModel.push(element.code)
        });
        if(this.TablesModel.length == 0){
          this.NotesModel = []
        }
      },

      onRowSelected(){

      },

      pageChanged(){

      },

      Error(){

      },

      RowClicked(){

      },

      PerPageFunction(){

      },

      ViewException(){
        this.items = this.exceptionList
        this.isVisible = true
      },

      ViewBatchErr(){
        this.$refs['batch-modal'].show()
      },

      ViewDCS(){
        if(this.tableError == true){
          this.isVisible = false
        }
        else{
          this.items = this.datasheet
          this.isVisible = true
        }
      },

      closeRepWindow(){
        this.isVisible = false
      },

      ChangeDropDownMode(mode){
        if(mode){
           this.ModeModel = mode;
        }

        if(this.ModeModel.code === 2){
           // this.steps.pop();
           this.steps.splice(2, 1);
           this.steps.splice(2, 0, {label:this.$t('dcs.proError'),slot:"production"})
           // this.steps.push({label:"Production Errors",slot:"production"});
        }
        if(this.ModeModel.code === 1){
            //this.steps.pop();
            this.steps.splice(2, 1);
           // this.steps.push({label:"Review Errors",slot:"review"});
            this.steps.splice(2, 0, {label:this.$t('dcs.rvError'),slot:"review"})
        }
      },
      ChangeDropDownSort(sort){
        this.SortModel = sort
      },
      GotoDcsList(){
        this.checkExistFavorite('/app/dcs');
        this.$router.push({name: "dcs-list"});
      },
      async PopulateNotes(){
        this.$emit('load', true)
        let params = {
          "month": this.MonthModel.code.toString(),
          "year": this.YearModel.toString() 
        }
        await DCSService.populateNotes(params).then(response => {
          let notes = []
          let values = JSON.parse("[" + response.data.noteDescriptionList + "]")
          this.noteKeys = JSON.parse("[" + response.data.noteIDList + "]")
          this.noteKeys.forEach((key, index) => {
            notes.push({"code": key, "name": key + " - " + values[index]})
          })
          this.NotesList = notes
        }).catch(error => {
          this.$emit('load', false);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('dcs.nTable'),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$t('dcs.nTable'),error,"error");
          }
        })
        for(var i = 0; i < this.NotesList.length; i++){
          await this.TableControls(this.NotesList[i].code)
        }
        this.$emit('load', false)
      },

      async TableControls(noteID){
        let params = {
          "month": this.MonthModel.code.toString(),
          "year": this.YearModel.toString(),
          "noteID": noteID.toString()
        }
        await DCSService.tableControl(params).then(response => {
          let tables = {}
          
          let values = JSON.parse("[" + response.data.constrolDescriptionList + "]")
          this.tableKeys = JSON.parse("[" + response.data.controlIDList.substring(response.data.controlIDList.indexOf("=") + 1) + "]")
          let noteIDs = JSON.parse("[" + response.data.controlIDList.substring(0, response.data.controlIDList.indexOf("=")) + "]")
          let newValues = []
          values.forEach(description => {
            let newDes = description.substring(description.indexOf("- ") + 1)
            newValues.push(newDes)
          });
          this.tableKeys.forEach((key, index) => {
            tables = {"code": key, "name": key + " - " + newValues[index], "note": noteIDs[index]}
            this.tableControlList.push(tables)
          })
        }).catch(error => {
          this.$emit('load', false);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('dcs.tables'),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$t('dcs.tables'),error,"error");
          }
        })
      },

      async DCSReview(list){
        //this.$emit('load', true)
        if(this.SortModel == ''){
          this.SortModel = 2
        }
        let params = {
          "month": this.MonthModel.code.toString(),
          "year": this.YearModel.toString(),
          "controlIDs": list.toString(),
          "orderByIDX": this.SortModel.code
        }
        console.log(this.SortModel)
        this.label = this.$t('dcs.gReExcelMsg')
        this.interval = setInterval(() => {
          if(this.progressVal == 100) {
            this.progressVal = 0;
            clearInterval(this.interval);
            this.showProgress = false;
          }
          const newProgressVal = this.progressVal + Math.floor(Math.random() * 20);
          this.progressVal = this.newProgressVal <= 100 ? newProgressVal : 100;
        }, 1000);
        await DCSService.dcsReview(params).then(response => {
          let content = response.data.xcelContent
          this.errorSystem = response.data.systemErrors
          if(this.errorSystem != ""){
            this.showMessage(this.$t('dcs.review'),this.$t('dcs.cSupportMsg'),"error");
          }
          else {
            this.generateTable(content)
            this.DCSException()
          }
        }).catch(error => {
          this.disabledButton = true
          if (!error.response) {
              // network error
              this.showMessage(this.$t('dcs.review'),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$t('dcs.review'),error,"error");
          }
        })
      },

      async DCSProduction(list){
        //this.$emit('load', true)
        if(this.SortModel == ''){
          this.SortModel = 2
        }
        let params = {
          "month": this.MonthModel.code.toString(),
          "year": this.YearModel.toString(),
          "controlIDs": list.toString(),
          "orderByIDX": this.SortModel.code
        }
       
        this.label = this.$t('dcs.gExcelMsg')
        this.progressVal = 0
        this.interval = setInterval(() => {
          if(this.progressVal === 100) {
            this.progressVal = 0;
            clearInterval(this.interval);
            this.showProgress = false;
          }
          const newProgressVal = this.progressVal + Math.floor(Math.random() * 20);
          this.progressVal = this.newProgressVal <= 100 ? newProgressVal : 100;
        }, 1000);
        await DCSService.dcsProduction(params).then((response) => {
          this.errorSystem = response.data.systemErrors
          this.errorMessage = response.data.errorMessages
          this.warningMessage = response.data.warningMessages
          this.version = response.data.version
          let content = response.data.xcelContent
          if(response.data.systemErrors != ""){
            this.showMessage(this.$t('dcs.review'),this.$t('dcs.cSupportMsg'),"error");
          }
          else {
            this.generateTable(content)
            this.DCSException()
          }
          //this.$emit('load', false)
        }).catch(error => {
          //this.$emit('load', false);
          this.disabledButton = true
          if (!error.response) {
              // network error
              this.showMessage(this.$t('dcs.production'),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$t('dcs.production'),error,"error");
          }
        })
      },

      async DCSException(){
        this.$emit('load', true);
        let params = {
          "groupCreationID": "DCS" + this.MonthModel.code.toString() + this.YearModel.toString(),
          "mode": this.ModeModel.name,
          "version": (this.ModeModel.name == "production"? this.version : 1)
        }
        await DCSService.dcsException(params).then(response => {
          this.exceptionList = response.data
          this.$emit('load', false);
          if(this.exceptionList.length == 0){
            this.showMessageIsEmpty = true
          }
        }).catch(error => {
          this.$emit('load', false);
          this.disabledButton = true
          if (!error.response) {
              // network error
              this.showMessage(this.$t('dcs.vExceptions'),this.$t('Msg.retrieveInfo'),"error");
          } else {
              this.showMessage(this.$t('dcs.vExceptions'),error,"error");
          }
        })
      },

      generateTable(content){
        try{
          let rows = CSV.parse(content, {output: 'objects'})
          //rows = JSON.parse(rows)
          this.datasheet = rows
          this.downloadfile = rows
          this.items = rows
          this.items.forEach(element => {
            element.amount = Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(element.amount)
          });
        }catch(error){
          this.tableError = true
          this.showMessage(this.$t('dcs.vSpreadsheet'),this.$t('dcs.rExcelMsg'),"error");
        }
      },

      jsonToCsv(jsonData) {
          let csv = '';
          // Get the headers
          let headers = Object.keys(jsonData[0]);
          csv += headers.join(',') + '\n';
          // Add the data
          jsonData.forEach(function (row) {
              let data = headers.map(header => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
              csv += data + '\n';
          });
          csv = csv.replace(/\\"/g, "")
          return csv;
      },

      DownloadDCS() {
        try{
          let csv = this.jsonToCsv(this.downloadfile)
          const dataUri = window.URL.createObjectURL(
            new Blob( [csv], { type: "text/csv" } )
          )
          //window.open(dataUri)
          const anchor = document.createElement('a');
          anchor.href = dataUri
          anchor.target = '_blank';
          if(this.ModeModel.code == 1){
            anchor.download = 'DCS' + this.MonthModel.code + this.YearModel + 'Review' + '.csv';
          }
          else {
            anchor.download = 'DCS' + this.MonthModel.code + this.YearModel + 'ver' + this.version + '.csv';
          }
          anchor.click();
        }catch(error) {
          this.$emit('load', false);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('Shared.Download'),this.$t('dcs.dFaildMsg'),"error");
          } else {
              this.showMessage(this.$t('Shared.Download'),error,"error");
          }
          console.log(error)
        }
      }
    }
  };
  </script>