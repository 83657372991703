<template>
    <div class="row page-height">
		<div class="col-md-12">
            <MdTitleRegInfo
              v-bind:RegistrationInfo="PatientTitle" 
              v-bind:titlePrefix="$t('AppointmentDashboard.ApDashTitle')" 
              v-bind:titleRef="'titleElementApDash'"
              v-bind:patientCode="this.$route.params.id">
            </MdTitleRegInfo>
			
			<div class="row">
				<MdCurrentAppointment v-if="this.currentAppointmentDB.guid !== ''"
					v-bind:ActivityId="this.$route.params.actId"
					v-bind:currentAppointmentObj="this.currentAppointmentDB"
					v-bind:ExecuteCall="true">
				</MdCurrentAppointment>

				<MdPatientInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientInfoBox>

                <MdPatientAllergies v-if="isUsePatientAllergies"
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientAllergies>

                <MdPatientSpecialPrecautions v-if="isUsePatientSpecialPr"						
					v-bind:PatientCode="this.$route.params.id">		
				</MdPatientSpecialPrecautions>

                <MdPatientDiagnosis v-if="isUsePatientDiagnosis"
					v-bind:PatientCode="this.$route.params.id"
                    v-bind:actId="this.$route.params.actId">						
				</MdPatientDiagnosis>

				<MdHistoricalAppointment
					v-bind:PatientCode="this.$route.params.id" v-bind:UseColumnDinamic="true" v-bind:Column="'col-md-4'">
				</MdHistoricalAppointment>

                <!--<MdPatientAlertsInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientAlertsInfoBox>

				<MdNotesByAppt v-if="this.currentAppointmentDB.activitytypecode !== ''"
					v-bind:ApptType="this.currentAppointmentDB.activitytypecode">
				</MdNotesByAppt>-->
			</div>
		</div>

		<ModalAppointmentReason 
			v-bind:TitleWindow="$t('AppointmentDashboard.ReasonForNoShow')"  
			v-bind:ModalId="this.modalReasonId"
			v-bind:ClickedText="this.ClickedText" 
		/>     
		
	</div>
   
</template>

<script>
import EnumService from '@/services/enum.service'
//import SchedulerService from '@/services/schedulers.service'
import Utils from '@/common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
export default {
	
  name: 'AppointmentDashboard',
   mixins: [DBFunctions],
  data() {
      return {		
            Loading:false,
            modalReasonId:"modalIdAppointmentReason",
            ClickedText:"",
            OptionsRightMenu:{
                visible:true,
                ItemsList:[],
                btnvisible:true,
                itemdisabled: false,		
            },		
            currentAppointmentDB:{
                guid:'',
                activitytypecode:'',
                employee:{
                    firstname:"",
                    lastname:""
                }
            },
            Appointments:[],
            PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:''
            }, 
            isUsePatientAllergies: this.$store.state.isUsePatientAllergies,
            isUsePatientSpecialPr: this.$store.state.isUsePatientSpecialPr,
            isUsePatientDiagnosis: this.$store.state.isUsePatientDiagnosis,
            isUsePatientMarsAlert: this.$store.state.isUsePatientMarsAlert,
		}
	},
	methods:{
        async GetPatientByCode(){
            var infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0){
                this.PatientTitle.programName = '';
                this.PatientTitle.admitDate = '';
                this.PatientTitle.dischargeDate = '';
                
                const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

                if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age;
                if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
            }
        },
    },


	async created(){
		this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);	
      if(this.currentAppointmentDB.transdate)	
        this.currentAppointmentDB.transdate = Utils.formatterDateToString(this.currentAppointmentDB.transdate);		

		EventBus.$on("onAppointmentReasonModal", function (reason) { 
			this.ClickedText  = reason;     
			this.$bvModal.show(this.modalReasonId);    
		}.bind(this));
	},

	async mounted(){
		this.GetPatientByCode();
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems
		this.$emit('getVisibleMenu', this.OptionsRightMenu); 

		//this.getAppoinments();
	
	},
    destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
};
</script>


