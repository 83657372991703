<template>
<div>
  <b-modal :id="ModalId" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :title="TitleWindow" :visible="!NotOpenByDefault ? true : false" :ok-disabled="isEditMode" @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')">
    <div v-if="isListing" id="ContainerPatientVitalSign">
      <div class="row mb-2">
        <div class="card" style="width:100%;">
          <div class="card-body">
            <MdTitle v-bind:Text="$t('VitalSignList.PatientVitalSign')+' - ' + this.patient.firstname +' '+ this.patient.lastname + ' - ' + this.PatientCode" />  
            <div class="row">
              <div class="col-sm-12 col-md-3">                      
                <MdButton
                  v-bind:Label="$t('VitalSignList.AddVitalsSigns')" 
                  v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                  v-bind:IconClass="'ti-plus pr-1'"
                  v-on:click="AddVitalSign"
                  v-bind:isReadOnly=this.isReadOnly>
                </MdButton>
              </div>
              <div class="col-sm-12 col-md-9 mb-4">
                <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                  <div class="input-group">
                    <input 
                      type="text" 
                      class="form-control"
                      v-model="parameter.SearchCriteria"   
                      @keyup.enter="SearchVitalSign">

                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinput'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="SearchVitalSign">
                      </MdButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>              
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="card" style="width:100%;">
          <div class="card-body" style="margin-top:-30px !important;">
            <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <div class="row">
              <div class="col-md-12">
                <div>
                  <b-col lg="6" class="my-1"></b-col>
                  <MdTable 
                    v-bind:fields="fields"
                    v-bind:items="items"
                    v-bind:totalRows="totalRows"
                    v-bind:isReadOnly="isReadOnly"
                    v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                    v-bind:currentPage="currentPage"
                    v-bind:perPage="perPage"
                    v-bind:pageOptions="pageOptions"
                    :editIconEvent="EditVS"
                    v-bind:VisibleEditIcon="true"
                    :deleteIconEvent="RemoveVS"
                    v-bind:VisibleDeleteIcon="true"
                    :onRowSelected="onRowSelected"
                    :onFiltered="onFiltered"
                    :pageChanged="pageChanged"
                    :perPageFunction="PerPageFunction">
                  </MdTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   
    <div v-show="isEditMode" id="ContainerVitalsSigns">
      <div id="ContainerAddVitalsSigns">
         <div class="card" style="width:100%;">
            <div class="card-body">
               <MdTitle 
                  v-bind:Text="$t('VitalSign.EditPatientVitalSign')+' - ' + this.patient.firstname +' '+ this.patient.lastname + ' - ' + this.PatientCode"
                  v-bind:isHide="!this.Mode"
                  v-bind:Name="'IdPatient'"
               />
               <MdTitle 
                  v-bind:Text="$t('VitalSign.AddPatientVitalSign')+' - ' + this.patient.firstname +' '+ this.patient.lastname + ' - ' + this.PatientCode"
                  v-bind:isHide="this.Mode"
               />
               <b-row>
                  <b-col md="3" lg="3" sm="12">
                     <MdSelect
                        v-bind:Label="$tc('Shared.Employee')"
                        v-bind:Options="Employees"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'code'"
                        v-bind:Model="vitalObj.therapistCode"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownEmployees"
                         v-bind:IsDisabled="isReadOnly"
                     />
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.DateAndTime')"
                        v-bind:Name="'transDate'"
                        v-bind:TypeProp="'date'" 
                        v-bind:Min="'1900-01-01'"
                        v-bind:Max="'2200-12-31'"
                        v-model="vitalObj.transDate"
                        v-bind:isReadOnly="isReadOnly"
                     >                                     
                     </MdEdit>
                  </b-col>
               </b-row>
               <b-row>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Temperature')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'temp'"
                        v-model.number="vitalObj.temp"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdSelect
                           v-bind:Label="$t('VitalSign.Method')"
                           v-bind:Options="OptionTempMethod"
                           v-bind:FieldDescription="'name'"
                           v-bind:FieldCode="'value'"
                           v-bind:Model="vitalObj.tempMethod"                       
                           v-bind:Multiple="false"
                           :Input="ChangeDropDowntempMethod"
                           v-bind:IsDisabled="isReadOnly"
                     />
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Pulse')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'pulse'"
                        v-model.number="vitalObj.pulse"
                        v-bind:isReadOnly="isReadOnly"
                     >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdSelect
                        v-bind:Label="$t('VitalSign.Position')"
                        v-bind:Options="OptionPulsePosition"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="vitalObj.pulsePosition"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownPulsePosition"
                         v-bind:IsDisabled="isReadOnly"
                     />
                  </b-col>
               </b-row>
               <b-row>
                  
                  <b-col lg="3" md="3" sm="12">
                     <MdSelect
                        v-bind:Label="$t('VitalSign.Location')"
                        v-bind:Options="OptionPulseLocation"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="vitalObj.pulseLocation"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownPulseLocation"
                         v-bind:IsDisabled="isReadOnly"
                     />
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Systolic')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'systolic'"
                        v-model.number="vitalObj.systolic"
                        v-bind:isReadOnly="isReadOnly"
                     >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Diastolic')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'diastolic'"
                        v-model.number="vitalObj.diastolic"
                        v-bind:isReadOnly="isReadOnly"
                     >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdSelect
                        v-bind:Label="$t('VitalSign.Position')"
                        v-bind:Options="OptionPulsePosition"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="vitalObj.bpposition"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownBpposition"
                         v-bind:IsDisabled="isReadOnly"
                     />
                  </b-col>
               </b-row>
               <b-row>
                  <b-col lg="3" md="3" sm="12">
                     <MdSelect
                        v-bind:Label="$t('VitalSign.CuffLocation')"
                        v-bind:Options="OptionCuffLocation"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="vitalObj.cuffLocation"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownCuffLocation"
                         v-bind:IsDisabled="isReadOnly"
                     />
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Resp')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'resp'"
                        v-model.number="vitalObj.resp"
                        v-bind:isReadOnly="isReadOnly"
                     >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.O2Sat')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'o2sat'"
                        v-model.number="vitalObj.o2sat"
                        v-bind:isReadOnly="isReadOnly"
                     >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdSelect
                        v-bind:Label="$t('VitalSign.O2delivery')"
                        v-bind:Options="Optiono2delivery"
                        v-bind:FieldDescription="'name'"
                        v-bind:FieldCode="'value'"
                        v-bind:Model="vitalObj.o2delivery"                       
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownOptiono2delivery"
                         v-bind:IsDisabled="isReadOnly"
                     />
                  </b-col>
               </b-row>
               <b-row>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Weightlbs')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'weight1'"
                        v-model.number="vitalObj.weight1"
                        @blur="calcBmiBsa"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Weightoz')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'weight2'"
                        v-model.number="vitalObj.weight2"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Weightft')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'height1'"
                        v-model.number="vitalObj.height1"
                        @blur="calcBmiBsa"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.Heightin')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'height1'"
                        v-model.number="vitalObj.height2"
                        @blur="calcBmiBsa"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
               </b-row>
               <b-row>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.InhaledO2')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'o2inhaled'"
                        v-model.number="vitalObj.o2inhaled"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.HeadCirin')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'headCir'"
                        v-model.number="vitalObj.headCir"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.BSAM2')"
                        v-bind:TypeProp="'text'" 
                        v-bind:Name="'bsa'"
                        v-model="vitalObj.bsa"
                        v-bind:isReadOnly="true"
                        v-bind:Id="'input_BSAM2_id'"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.BMIlbsin2')"
                        v-bind:TypeProp="'text'" 
                        v-bind:Name="'bmi'"
                        v-model="vitalObj.bmi"
                        v-bind:isReadOnly="true"
                        v-bind:Id="'input_BMIlbsin2_id'"
                        >                                     
                     </MdEdit>
                  </b-col>
                  <b-col lg="3" md="3" sm="12">
                     <MdEdit 
                        v-bind:Label="$t('VitalSign.SugarLevelmgdL')"
                        v-bind:TypeProp="'number'" 
                        v-bind:Name="'sugarLevel'"
                        v-model.number="vitalObj.sugarLevel"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdEdit>
                  </b-col>
               </b-row>
               <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
               <b-row>
                  <b-col md="12" lg="12">
                     <MdMemo 
                        v-bind:Label="$t('Shared.Notes')"
                        v-bind:TypeProp="'text'" 
                        v-bind:Name="'notes'"
                        v-bind:Rows="'3'"
                        v-bind:MaxRows="'6'"
                        v-bind:MaxLength="'500'"
                        v-model="vitalObj.notes"
                        v-bind:isReadOnly="isReadOnly"
                        >                                     
                     </MdMemo>
                  </b-col>
               </b-row>
               <b-row class="mt-2">
                  <b-col md="12">
                     <div class="text-center text-md-right mt-3 container-button">
                        <MdButton
                           v-bind:Label="$t('VitalSign.VITALSSIGNSLIST')"
                           v-bind:Name="'btnpatientlist'"
                           v-bind:ClassProp="'secondary mx-2'"
                           v-on:click="DiscardChanges"/>
                        <MdButton
                        v-bind:Label="$t('Shared.Save')"
                        v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                        v-on:click="SaveOrEdit"
                        />
                     </div>
                  </b-col>
               </b-row>
            </div>
         </div>
      </div>
   </div>
  </b-modal>
 </div>
</template>

<script>


import EnumService from '@/services/enum.service' 
import PatientService from '@/services/patient.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import GlobalService from '@/services/global.service' 
// import LocalStorage from '@/services/storage.service'
import EmployeeService from '@/services/employee.service'
import VitalSignService from '@/services/vitalsigns.service'
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';

export default {
  name: 'VitalsSigns', 
  mixins: [ShowMessage, DBFunctions],
  props: {
     ModalId: String,
     PatientCode:String,
     TitleWindow: String,
     ComponentKind: String,
     NotOpenByDefault:Boolean,
     ForUsefromMars:Boolean,
     MarsParam:Object
   },
  data() {
    return {
      patient:{},
      ShowMessageIsEmpty:false,
      Loading:true,
      OptionsRightMenu:{
        visible:true,
        ItemsList:[],
        btnvisible:true,
        itemdisabled: false,
        PatientCode:''         
      },  
      vsId:'',   
      Mode:'',         
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'transDate', sortable: true, label: this.$t('Shared.Date'), class: 'text-rigth' },    
        { key: 'temp', sortable: true, label: this.$t('VitalSign.VITALSSIGNSLIST'), class: 'text-rigth' },       
        { key: 'pulse', sortable: true, label: this.$t('VitalSign.Pulse'), class: 'text-rigth'},       
        { key: 'systolic', sortable: true, label: this.$t('VitalSign.Systolic'), class: 'text-rigth' },      
        { key: 'diastolic', sortable: true, label: this.$t('VitalSign.Diastolic'), class: 'text-rigth' },
        { key: 'resp', sortable: true, label: this.$t('VitalSign.Resp'), class: 'text-rigth' }, 
        { key: 'o2sat', sortable: true, label: this.$t('VitalSign.O2Sat'), class: 'text-rigth' }, 
        { key: 'o2inhaled', sortable: true, label: this.$t('VitalSign.InhaledO2'), class: 'text-rigth' },
        { key: 'recNo', sortable: true, label: 'RecNo', class: 'hidden'}, 
        { key: 'sugarLevel', sortable: true, label: this.$t('VitalSign.SugarLevelmgdL'), class: 'text-rigth' },       
      ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      //vars use in Allergies Add/Edit
      Employees: [],
        EmployeeCode:'',
        OptionTempMethod: EnumService.TempMethod,
        OptionPulsePosition: EnumService.Position,
        OptionPulseLocation: EnumService.Location,
        OptionCuffLocation: EnumService.CuffLocation,
        Optiono2delivery: EnumService.O2Delivery,
        vitalObj:{
          recNo: '',
          patientCode: '',
          therapistCode: '',
          transDate: null,
          headerId:'',
          unitSys: '',
          temp: 0,
          tempMethod: '',
          pulse:0,
          pulsePosition:'',
          pulseLocation: '',
          systolic: 0,
          diastolic: 0,
          bpposition: '',
          cuffLocation: '',
          resp: 0,
          o2sat: 0,
          o2delivery: '',
          weight1: 0,
          weight2: 0,
          height1: 0,
          height2: 0,
          headCir: 0,
          notes: '',
          bmi: '',
          bsa: '',
          o2inhaled: 0,
          sugarLevel: 0
        },
      isListing: true,
      isEditMode:false,
      isNewVitalSign:true,
      pendingChanges: false,
      clickOK: false
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      },
  },
    
  methods: {
    emitEventOK(){      
      this.clickOK = true
      },

      emitEventHide(handled){
        if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('VitalSign.PatientVitalsSigns'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
               if(this.ForUsefromMars){
                  let param = {
                     info:this.getTextResult(),
                     MarsParam:this.MarsParam 
                  }
                  this.$emit('getCodeConditionOK', param);
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';
               }else{
                  this.$emit('getCodeConditionOK', this.getTextResult());
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';
               }
               
            } else {
                this.$emit('getCancel');
                this.ResetProps();
            }
        }
      },
      
     showListing(show) {
        this.isEditMode = show ? false : true;
        this.isListing = show ? true : false;
      },

      ResetProps(){
        this.parameter.SearchCriteria = '';
        this.showListing(true);
      },

      getTitleByFieldName(fieldName) {
         let title = ''
         if(fieldName == 'transDate') {
            title = this.$t('Shared.Date')
         }
         else if(fieldName == 'unitSys') {
            title = 'Unit'
         }
         else if(fieldName == 'temp') {
            title = 'Temp'
         }
         else if(fieldName == 'tempMethod') {
            title = 'Method'
         }
         else if(fieldName == 'pulse') {
            title = 'Pulse'
         }
         else if(fieldName == 'pulsePosition') {
            title = 'Position'
         }
         else if(fieldName == 'pulseLocation') {
            title = 'Location'
         }
         else if(fieldName == 'systolic') {
            title = 'Systolic'
         }
         else if(fieldName == 'diastolic') {
            title = 'Diastolic'
         }
         else if(fieldName == 'bpposition') {
            title = 'Position'
         }
         else if(fieldName == 'cuffLocation') {
            title = 'Location'
         }
         else if(fieldName == 'resp') {
            title = 'Resp'
         }
         else if(fieldName == 'o2sat') {
            title = 'O2 Sat'
         }
         else if(fieldName == 'o2delivery') {
            title = 'O2 Del'
         }
         else if(fieldName == 'weight1') {
            title = 'WGT_1'
         }
         else if(fieldName == 'weight2') {
            title = 'WGT_2'
         }
         else if(fieldName == 'height1') {
            title = 'HGT_1'
         }
         else if(fieldName == 'height2') {
            title = 'HGT_2'
         }
         else if(fieldName == 'headCir') {
            title = 'Head'
         }
         else if(fieldName == 'sugarLevel') {
            title = 'Sugar Level'
         }
         return title
      },

      getTextResult() {
         let textResult = ''  
         let keys = [];     
         if(this.selected.length > 0){
            var item = this.selected[0];  
            keys = Object.keys(this.items[0]);
            keys.forEach(element => {
               if(item[element] != '' && item[element] != '0' 
                  && item[element] != null) {
                  if(element != 'therapistCode' && element != 'recNo' && element != 'notes'
                     && element != 'bmi' && element != 'bsa' && element != 'o2inhaled') {
                        textResult = textResult + this.appendUnitofMeasure(item.unitSys, 
                                   this.getTitleByFieldName(element),item[element]) + " "
                  }
               }
               });
               textResult = textResult + (this.ComponentKind === 'Memo' ? "\n" : "<br>");
         }
         else{
            if (this.items.length > 0) {
            this.items.forEach(item => {
               keys = Object.keys(item)
               keys.forEach(element => {
               if(item[element] != '' && item[element] != '0' 
                  && item[element] != null) {
                  if(element != 'therapistCode' && element != 'recNo' && element != 'notes'
                     && element != 'bmi' && element != 'bsa' && element != 'o2inhaled') {
                        textResult = textResult + this.appendUnitofMeasure(item.unitSys, 
                                   this.getTitleByFieldName(element),item[element]) + " "
                  }
               }
               });
               textResult = textResult + (this.ComponentKind === 'Memo' ? "\n" : "<br>")
            });            
         }
         }
     

         return textResult
      },

    getPatient() { 
      PatientService.get(this.PatientCode)
      .then((response) => {
          if(response.status == '200' && response.data){ 
            this.patient = response.data;
          }           
      })
      .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
          }
      }); 
    },

    SearchVitalSign(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getVitalSign();
    },

    getVitalSign() { 
      this.parameter = {
        PageNumber: this.currentPage, 
        PageSize: this.perPage,
        PatientCode: this.PatientCode         
      }
      VitalSignService.getAll(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.items = response.data;
            let headerString = response.headers["x-pagination"];
            this.Header = JSON.parse(headerString);
            this.totalRows = this.Header.TotalCount;
            this.items.forEach(function(item) {
              if (item.transDate)	
                  item.transDate = Utils.formatterDateTimeToString(new Date(item.transDate));
            });
          }
          if(this.items.length === 0){
            this.ShowMessageIsEmpty = true;
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch(error => {
          this.Loading = false;
          this.$emit('load', this.Loading);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
          }
        });
    },

    EditVS(row){
       this.isNewVitalSign = false;
       this.mountVitalEdit(row.recNo); 
       this.showListing(false)
    },

    RemoveVS(row){
      const recNo = row.recNo
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$t('VitalSignList.DeleteVitalSign'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value) {
          VitalSignService.deleteVital(recNo)
            .then(() => {
               const index = this.items.findIndex(item => item.recNo === recNo) // find the post index 
               if (~index) {// if the post exists in array 
                  this.items.splice(index, 1) //delete the post and refresh view
                  this.getVitalSign(); //refresh listing
                  this.showListing(true)
                }
            })
            .catch((error) => {
              if (!error.response) {
                this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.NotAbleToDelete'),"error");
              } else {
                this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
              }
            });
        }
      })
    },
   
    AddVitalSign(){
       this.isNewVitalSign = true;
       this.mountVitalEdit();
       this.showListing(false)
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getVitalSign();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.getVitalSign();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    //Use in Allergy Add/Edit

      DiscardChanges() {
         if(this.pendingChanges){
          this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('VitalSign.PatientVitalsSigns'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.pendingChanges = false
              this.getVitalSign();
              this.showListing(true)
            }
          })
        } else {
         this.getVitalSign();
         this.showListing(true)
        }
      },

     getDateTime() { 
         GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                  if (!this.VitalSId) 
                     this.vitalObj.transDate = this.formatterDate(response.data);
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
            }
         }); 
      },

      getDateTimeToTranform(dateToParse){
         const dateArray = dateToParse.split('T').slice(0,2);
         const time = dateArray[1];
         const date = dateArray[0];
         this.vitalObj.transDate =  date + 'T' + time;
      },

      getAllEmployee(){
         EmployeeService.getAllEmployees()
            .then((response) => { 
               if(response.data && response.data.length > 0){
                  this.Employees = response.data;
                  this.EmployeeCode = this.$store.state.employeeCode //LocalStorage.getEmployeeCode();
                  if (!this.VitalSId)  
                     this.vitalObj.therapistCode = this.EmployeeCode;
               }             
            })
            .catch((error) => {
               if (error.response) {
                  this.showMessage("Employee ",error,"error");
               }
            });
      },

      formatterDate(value){
         return Utils.formatterDate(value);
      },

      getVitalSignById(){
         if (this.VitalSId) {
            VitalSignService.get( this.VitalSId )
               .then((response) => {
                  if(response.status == '200' && response.data){
                     this.vitalObj = response.data;
                     this.vitalObj.transDate = this.formatterDate(this.vitalObj.transDate);
                  }
               })
               .catch((error) => {
                  if (!error.response) {
                     this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.RetrieveInformation'),"error");
                  } else {
                     this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
                  }
               });
         }

      },

      CreateVitalSign(){
         this.vitalObj.patientCode = this.PatientCode
         GlobalService.getDateTime()
            .then((response) => {
               this.getDateTimeToTranform(response.data);
               VitalSignService.addVital(this.vitalObj)
                  .then(() => {
                    this.showMessage(this.$t('VitalSign.SavingVitals'), this.$t('VitalSign.VitalsSavedSuccessfully'), "success");
                    this.getVitalSign()
                    this.showListing(true)
                  })
                  .catch((error) => {
                     if (!error.response) {
                        this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.NotAbleToInsert'),"error");
                     } else {
                        this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
                     }
               });
            })
            .catch(error => {
               if (error.response) {
                  this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
               }
         });   
         
      },

      UpdateVitalSign(){
        this.vitalObj.patientCode = this.PatientCode; 
        VitalSignService.update(this.VitalSId, this.vitalObj)
        .then(() => {
              this.showMessage(this.$t('VitalSign.SavingVitals'), this.$t('VitalSign.VitalsSavedSuccessfully'), "success");
              this.getVitalSign();
              this.showListing(true)
            })
        .catch((error) => {
          if (!error.response) {
              this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),this.$t('Msg.NotAbleToUpdate'),"error");
          } else {
              this.showMessage(this.$t('VitalSign.PatientVitalsSigns'),error,"error");
          }
        });
      },

      calcBmiBsa(){
        if (this.vitalObj.weight1 > 0 && this.vitalObj.height1 > 0) {
           this.vitalObj.bmi = ((( this.vitalObj.weight1 ) * 703 )/ Math.pow( (this.vitalObj.height1 * 12 ),2)).toFixed(2);
           this.vitalObj.bsa = (Math.sqrt(((this.vitalObj.height1) * 0.3048 * ((this.vitalObj.weight1 ) / 2.2046 ) )/3600 ) ).toFixed(2);
           if ( this.vitalObj.height2 > 0) {
              this.vitalObj.bmi = ((( this.vitalObj.weight1 ) * 703 )/ Math.pow( ((this.vitalObj.height1 * 12 ) + this.vitalObj.height2),2)).toFixed(2)
           }
           else {
              this.vitalObj.bsa = '';
               this.vitalObj.bmi = '';
           }
        }
        else {
            this.vitalObj.bsa = '';
            this.vitalObj.bmi = '';
        } 
      },

      SaveOrEdit(){
        if(!this.isReadOnly) { 
            this.pendingChanges = false       
            if (!this.isNewVitalSign) { 
               this.UpdateVitalSign()           
            }else{            
               this.CreateVitalSign()
            }
        }
      },

      ChangeDropDowntempMethod(value){
         this.vitalObj.tempMethod = value ? value.value : '';
      },
      ChangeDropDownPulsePosition(value){
         this.vitalObj.pulsePosition = value ? value.value : '';
      },
      ChangeDropDownPulseLocation(value){
        this.vitalObj.pulseLocation = value ? value.value : '';
      },
      ChangeDropDownBpposition(value){
         this.vitalObj.bpposition = value ? value.value : '';
      },
      ChangeDropDownCuffLocation(value){
         this.vitalObj.cuffLocation = value ? value.value : '';
      },
       ChangeDropDownOptiono2delivery(value){
         this.vitalObj.o2delivery = value ? value.value : '';
      },
      ChangeDropDownEmployees(value){
         this.vitalObj.therapistCode = value ? value.code : '';
      },

      async loadContents() {
         this.getPatient();
         this.getDateTime();
         this.getAllEmployee();
         this.getVitalSignById();
      },

      mountVitalEdit(recNo) {
        this.$emit('load', this.Loading);
        this.VitalSId =  recNo;
        this.loadContents()
      },

      appendUnitofMeasure(unitSystem, title, value) {
         let uMeasure = ''
         if(unitSystem == 'U') {
            if(title == 'Temp'){
               uMeasure = ' F'
            }
            if(title == 'WGT_1'){
               uMeasure = ' Lbs'
            }
            if(title == 'WGT_2'){
               uMeasure = ' oz'
            }
            if(title == 'HGT_1'){
               uMeasure = ' ft'
            }
            if(title == 'HGT_2' || title == 'Head'){
               uMeasure = ' in'
            }
            if(title == 'Sugar Level'){
               uMeasure = ' mg/dL'
            }
         } else {
            if(title == 'Temp'){
               uMeasure = ' C'
            }
            if(title == 'WGT_1'){
               uMeasure = ' Kg'
            }
            if(title == 'HGT_1' || title == 'Head'){
               uMeasure = ' cm'
            }
            if(title == 'Sugar Level'){
               uMeasure = ' nM'
            }
         }
         return title + ' ' + value + uMeasure
      },

      beforeWindowUnload(e) {
       if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
  },

   mounted() {
     this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems;
     this.getPatient();
     this.$emit('load', this.Loading);    
     this.getVitalSign(); 
     this.getVitalSignById();
     this.getDateTime();
     this.getAllEmployee(); 
     this.isReadOnly = this.$store.state.isVitalSignEHRReadOnly
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
};

</script>