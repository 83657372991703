<template>
<div>
   <b-modal :id="ModalId" size="xl" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :title="TitleWindow" :visible="!NotOpenByDefault ? true : false" @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')">
   <div id="Containergenericoutcome">
      <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdTable 
                id="tablegeneric"
                v-bind:stickyHeader="'250px'"
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                :onRowSelected="onRowSelected"
                :trClassFn="trClassFn">
            </MdTable>
          </b-col>
      </b-row>
      <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdRadioGroup
               v-bind:Label="$t('MdGenericOutCome.Anwers')"
               v-model="anwers"
               v-bind:Options="options"
               v-bind:Name="'radio'"
               v-bind:Stacked="true"
               v-on:change="setValueResultList($event)"
            />
          </b-col>
      </b-row>
      <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdGenericOutCome.CurrentScore')"
                v-bind:TypeProp="'number'"
                v-bind:Name="'currentscore'"
                v-model="currentScore"
                v-bind:isReadOnly="true">                                     
            </MdEdit>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdGenericOutCome.OutOf')"
                v-bind:TypeProp="'number'"
                v-bind:Name="'outOf1'"
                v-model="outOf1"
                v-bind:isReadOnly="true">                                     
            </MdEdit>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdGenericOutCome.Answered')"
                v-bind:TypeProp="'number'"
                v-bind:Name="'answered'"
                v-model="answered"
                v-bind:isReadOnly="true">                                     
            </MdEdit>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('MdGenericOutCome.OutOf')"
                v-bind:TypeProp="'number'"
                v-bind:Name="'outOf2'"
                v-model="outOf2"
                v-bind:isReadOnly="true">                                     
            </MdEdit>
          </b-col>
      </b-row>
   </div>
  </b-modal>
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import OutComeStudyTitlesService from '@/services/outcomestudytitles.service'
import OutComeStudyAnswersService from '@/services/outcomestudyanswers.service'
export default {
  name: 'GenericOutCome', 
  mixins: [ShowMessage, DBFunctions],
  props: {
     ModalId: String,
     TitleWindow: String,
     outComeCode: String,
     outComeDescription: String,
     NotOpenByDefault:Boolean,
     ForUsefromMars:Boolean,
     MarsParam:Object
   },
  data() {
    return {
      ShowMessageIsEmpty:true,
      Loading:true,
      currentPage: 1,
      items: [],
      selected: 0,
      options: [],
      anwers: '',
      currentScore:0,
      outOf1:0,
      answered:0,
      outOf2:0,
      fields: [
        { key: "sectiondesc", label: this.$t('Shared.Description'), class: "text-rigth" },
      ],
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:999 ,
        TestCode:'',
        SectionCode:''             
      },
      resultList: [],
      clickOK: false
    }
  },

  methods: {
      emitEventOK(){      
        this.clickOK = true
      },

      emitEventHide(handled){
        if (this.answered > 0 && !this.clickOK) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('MdGenericOutCome.OGenericTitle'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
              if(this.ForUsefromMars){
                  let param = {
                     info:this.getTextResult(),
                     MarsParam:this.MarsParam 
                  }
                  this.$emit('getCodeConditionOK', param); 
                  this.$emit('input',this.getTextResult());                 
                }else{
                  this.$emit('getCodeConditionOK', this.getTextResult());
                  this.$emit('input',this.getTextResult());
                }                
            } else {
                this.$emit('getCancel');
                this.ResetProps();
            }
        }
      },

      ResetProps()
      {

      },

      getTextResult() {
        let textResult = '';
        let description = '';
        let value = 0;

        this.resultList.map(item => (
            description = item.sectiondesc ? item.sectiondesc: '',
            value = item.value == -1 ? 0 : item.value,
            textResult = textResult + description+' = '+value +"\n"
        ))
        textResult = textResult + this.outComeDescription+' : '+this.currentScore
        return textResult
      },

      onRowSelected(items) {
      if(items[0]){
        this.selected = items[0].recOrder
        this.getOutComeStudyAnswers(items[0].sectioncode)
        this.loadValueResultList()
      }
    },
    
      async loadValueResultList(){
        let itemSel = this.selected
        let getValue = -1
        this.resultList.map(function(item){ 
          if(item.recOrder == itemSel && item.value != -1){
              getValue = item.value
          }
        })
        this.anwers = getValue != -1 ? getValue : null
      },

      setValueResultList(value){
        let itemSel = this.selected
        let answered = 0
        let currentScore = 0
        this.resultList.map(function(item){ 
          if(item.recOrder == itemSel){
              item.value = Number(value)
          }
          if(item.value != -1) {
            answered = answered + 1
            currentScore =  currentScore + item.value
          }
          return item;
        })
        this.answered = answered
        this.currentScore = currentScore
      },

      fillResultList(items){
        this.resultList = ( items != undefined) ? items.map(function(item){ 
              let optionItem = {};
              optionItem['recOrder'] = item.recOrder;
              optionItem['sectiondesc'] = item.sectiondesc;
              optionItem['value'] = -1;
              return optionItem;
        }): null;
      },

      loadRadioGroupOptions(optionsList) {
        this.options = ( optionsList != undefined) ? optionsList.map(function(option){ 
              let optionItem = {};
              optionItem['text'] = option.answerdesc;
              optionItem['value'] = String(option.answerpts);
              return optionItem;
        }): null;
      },

      getOutComeStudyTitles() { 
        this.parameter.PageNumber = this.currentPage;           
        this.parameter.TestCode = this.outComeCode;
        OutComeStudyTitlesService.getAll(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
          this.items = response.data;
          this.fillResultList(response.data)
          this.outOf2 = response.data.length
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch(error => {
          this.Loading = false;
          this.$emit('load', this.Loading);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('MdGenericOutCome.OGenericTitle'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('MdGenericOutCome.OGenericTitle'),error,"error");
          }
        });
      },

      getOutComeStudyAnswers(SectionCode) { 
        this.parameter.PageNumber = this.currentPage;           
        this.parameter.TestCode = this.outComeCode;
        this.parameter.SectionCode = SectionCode;
        OutComeStudyAnswersService.getAll(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
          this.loadRadioGroupOptions(response.data)
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch(error => {
          this.Loading = false;
          this.$emit('load', this.Loading);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('MdGenericOutCome.OGenericTitle'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('MdGenericOutCome.OGenericTitle'),error,"error");
          }
        });
      },

      getTotalTestScore() {          
        this.parameter.TestCode = this.outComeCode;
        OutComeStudyAnswersService.getTotalTestScore(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data){
          this.outOf1 = response.data
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch(error => {
          this.Loading = false;
          this.$emit('load', this.Loading);
          if (!error.response) {
              // network error
              this.showMessage(this.$t('MdGenericOutCome.OGenericTitle'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('MdGenericOutCome.OGenericTitle'),error,"error");
          }
        });
      },

      isAnswered(recOrder){
        let answeredItem = false
        this.resultList.forEach(element => {
          if(element.recOrder == recOrder && element.value > -1) {
            answeredItem = true
          }
        });
        return answeredItem
      },

      trClassFn(item, rowType){
        if (rowType === 'row' && this.isAnswered(item.recOrder)) {
          return "answered"
        }
      },

      beforeWindowUnload(e) {
        if (this.answered > 0) {
          e.preventDefault()
          e.returnValue = ''	
        }
      }
  },

  mounted() {
    this.$emit('load', this.Loading);
    this.getOutComeStudyTitles()
    this.getTotalTestScore()
  },

  created () {
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
};

</script>