<template>
   <div>
    <b-modal :id="modalId" size="sm" ref="modalResetPass" centered scrollable no-close-on-backdrop no-close-on-esc :title="TitleWindow" @ok="this.handleOk" @cancel="this.emitEventCancel" @hide="resetModal" :cancel-title="$t('Shared.BtnCancel')">
    <form ref="form" @submit.stop.prevent="handleSubmit"> 
      <b-row>  
        <b-col md="12" sm="12">
          <MdPassword
            v-bind:Label="$t('insuranceSetup.Password')"
            v-bind:TypeProp="'password'" 
            v-bind:Name="'Password'"           
            v-bind:MaxLengthProp="45"
            v-bind:isRequired="true"
            v-bind:TabIndex="'1'"
            v-bind:isEndControl="false"
            v-bind:isValid="isPasswordValid"
            v-model="User.password"            
            v-bind:Autocomplete="false">                                     
          </MdPassword>
        </b-col>        
     </b-row>
     <b-row>
         <b-col md="12" sm="12">
          <MdPassword
            v-bind:Label="$t('ChangePassWord.ConfirmPass')"
            v-bind:TypeProp="'password'"          
            v-bind:Name="'Verify'"
            v-bind:MaxLengthProp="45"
            v-bind:TabIndex="'2'"
            v-bind:isRequired="true"
            v-bind:isValid="isConfirmPasswordValid"
            v-model="User.verify"
            :EndControlFunction="EndFunction"
            v-bind:isEndControl="true"
            v-bind:Autocomplete="false">                                     
          </MdPassword>
        </b-col>   
     </b-row>
    </form>
    </b-modal>
   </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
export default {
  name: 'ModalUserResetPassword',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow:String
   },
   data(){
       return {
         modalId:this.ModalId,  
         isConfirmPasswordValid:true,
         isPasswordValid:true,
         isReadOnly:false,
         User:{},
       }
   },

   methods:{

    checkFormValidity() {
      this.isPasswordValid = this.User.password && this.User.password.length > 0 ? true : false;
      this.isConfirmPasswordValid =  this.User.verify && this.User.verify.length > 0 ? true : false;
       return this.isPasswordValid && this.isConfirmPasswordValid;
      },

      handleSubmit() {        
        if(!this.checkFormValidity()) {          
          return;
        }else{
           this.emitEventOK();
        }       
      },

       handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault(); 
      },

      handleOKFunction(){
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
        this.handleSubmit();
      },

      EndFunction(){
        this.handleOKFunction();
      },

    emitEventOK(){
        if(this.User.password === ''){
         this.showMessage(this.$t('insuranceSetup.Password'),this.$t('ModalUserResetPassword.pRequiredMsg'),"error");
       }else if(this.User.verify === ''){
         this.showMessage(this.$t('insuranceSetup.Password'),this.$t('ModalUserResetPassword.cpRequiredMsg'),"error");
       }else if(this.User.password !== this.User.verify){
           this.showMessage(this.$t('insuranceSetup.Password'),this.$t('ModalUserResetPassword.nMatchMsg'),"error");
       }else{
        var send = {
            "password":this.User.password,
            "confirmPassword":this.User.verify,
        };      
      
       this.$emit('getCodeConditionOK', send);
       this.$nextTick(() => {
          this.$bvModal.hide(this.ModalId);
        });
       }
    },
  
    emitEventCancel(){
      this.$emit('getCodeConditionCancel');
    },

    resetModal(){
      this.User = {
          "password":"",
          "verify":""
      } 
    },
   },

   mounted(){
      this.$root.$on('bv::modal::show', () => {   
        this.User = {
          "password":"",
          "verify":""
        }  
        this.$nextTick(() => {
          setTimeout(500);
      });
    });
   }
};
</script>