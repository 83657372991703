<template>
    <div>
        <b-row>
          <b-col md="12">               
              <b-table
                  :ref="refTable"
                  selectable                  
                  :small="small"
                  :sticky-header="stickyHeader"
                  class="medezTables cutescroll"
                  :select-mode="fMultiSelect"
                  :items="items"
                  :fields="fFields"
                  :sort-compare="Compare"
                  :current-page="cPage"
                  :show-empty="showMessageIsEmpty"
                  @row-selected="rowSelected"
                  @row-clicked="rowClicked"
                  responsive
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="filtered"
                  @row-dblclicked="ondblClicked"
                  :tbody-tr-class="trClassFn"
                  :empty-text="$t('MdTable.nRecShow')"
              >    

                  <template v-if="UseImage" #cell(actions)="">  
                    <b-img-lazy
                        blank
                        blank-color="#ccc"
                        width="100"
                        height="100"
                        fluid 
                        src="@/assets/images/faces/nopic-female.jpg" 
                        alt="Image 1">
                    </b-img-lazy>                     
                  </template> 
           
              </b-table>
          </b-col>    
      </b-row>
     
      <b-row>
        <b-col v-if="pageOptions" sm="7" md="6" class="my-1">
          <b-pagination 
            v-model="cPage"
            :total-rows="totalRows"
            :per-page="pPage"
            align="fill"
            size="sm"
            class="my-0"
            @change="fpageChanged"
          ></b-pagination>
        </b-col>
        <b-col v-if="pageOptions" align="right" class="mr-2">
            <b-form-group
              >{{ $t('Shared.Show') }}
                <b-form-select
                  v-model="pPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                  style="width:auto;"
                  @change="pageFunction($event)"
                ></b-form-select>
                {{ $t('Shared.Of') }} {{totalRows}}
              </b-form-group>
          </b-col>
        </b-row>
        <NotesRep v-if="isReportVisible" 
          :closeWindow="closeRepWindow"
          :HeaderId="this.repHeaderId"
          :FormId="this.repFormId"
          :PatientCode="this.repPatientCode"
          :isReportVisible="isReportVisible"
          :ReportTitle="this.repTitle"
          :Transdate="this.repTransdate" 
          :Notetime="this.repNotetime" 
          :Void="this.repVoid"
          :NoteName="this.repNoteName"
      />
    </div>
  </template>
  
  <script>
  
  
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import moment from 'moment'
  import { EventBus } from '@/event-bus.js';
  import Utils from '@/common/utils'
  
    export default {
      mixins: [ShowMessage],
      props: {
       items:  Array,   
       fields: Array,
       totalRows: Number,
       stickyHeader: [Boolean,String],
       totalRowsSubItems:Number, 
       editIconEvent: Function,
       VisibleEditIcon:Boolean,
       deleteIconEvent: Function,
       downloadIconEvent: Function,
       VisibleDeleteIcon:Boolean,
       VisibleDownloadIcon:Boolean,
       showMessageIsEmpty: Boolean,
       ShowDetails:Boolean,
       onRowSelected: Function,
       onRowClicked: Function,
       EnabledRowClicked:Boolean,
       ondblClick:Function,
       currentPage: Number,
       currentPageSubItems:Number,
       onFiltered: Function,
       perPage: Number,
       perPageSubItems:Number,
       pageChanged: Function,
       pageChangedSubItems:Function,
       pageOptions: Array,
       pageOptionsSubItems: Array,
       perPageFunction: Function,
       perPageFunctionSubItems: Function,
       otherIconEvent: Function,
       otherIcon: String,
       otherIconEvent2: Function,
       otherIcon2: String,
       IconEvent1:Function,
       IconEvent2:Function,
       IconEvent3:Function,
       viewIconEvent: Function,
       refTable: String,
       subItems: Array,
       multiSelect: Boolean,
       avisar:Function,
       CheckClick:Function,
       small: Boolean,
       isReadOnly:Boolean,
       trClassFn: Function,
       FieldsDate:Array,
       NotePatientInfo: Object,
       EventScheduler:Boolean,
       BtnValidateEmployee:Boolean,

       UseImage:Boolean

     },
      data() {
        return {
            filter: null,
            EmployeeCode:"",
            filterOn: [],          
            pPage: this.perPage,
            pPageSubItems: this.perPageSubItems,
            cPage: this.currentPage,
            cPageSubItems: this.currentPageSubItems,         
            fFields: this.fields,
            fMultiSelect: 'single',
            CountNotesOpened:0,
            CountSubItems:0,
            DateTimeNow:null,
            TRSubItems:0,
            CPSubItems:0,
            CurrentPage: 1,
            isReportVisible: false, 
            repHeaderId: '',
            repFormId: '',
            repPatientCode: '',
            repTransdate: '',
            repNotetime: '',
            repVoid: false,
            repTitle: '',
            repNoteName: '',
            noteStatus:{
              "Finalized":this.$t('Shared.IconDescF')
            },

            mainProps: {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: '#bbb',
                width: 600,
                height: 400,
                class: 'my-5'
            }
  
        }
      },
      
      methods:{
  
        cutNoteName(noteName) {
          let name = noteName
          if (noteName && noteName.length > 30 ) {
            name = noteName.substr(0, 30) + '...'
          }
          return name
        },

        getImageUrl(imageId) {
        const { width, height } = this.mainProps
        return `https://picsum.photos/${width}/${height}/?image=${imageId}`
      },
  
  
      editButton(row) {
          this.editIconEvent(row);
          this.sendDataForFavorite();
      },
  
      deleteButton(row) {
          this.deleteIconEvent(row);
      },
  
      rowSelected(items) {     
          this.onRowSelected(items);
      },
  
      rowClicked(item ,index) {
        if(this.EnabledRowClicked)
          this.onRowClicked(item,index);
      },
  
      filtered(filteredItems) {
          this.onFiltered(filteredItems);
      },
  
      fpageChanged(page) {
         //this.$refs[this.refTable].refresh();
          this.pageChanged(page);
      },
       fpageChangedSubItems(page) {
          this.pageChangedSubItems(page);
      },
  
      SubItemsPageChanged(e){     
      this.pageChangedSubItems(e);
      },
  
      pageFunction(perpage) {
          this.perPageFunction(perpage);
      },
      pageFunctionSubItems(perpage) {
          this.perPageFunctionSubItems(perpage);
      },
  
      otherButton(row) {
        this.otherIconEvent(row);
      },
  
      otherButton2(row) {
        this.otherIconEvent2(row);
      },
  
      ButtonIconEvent1(row){
        this.IconEvent1(row);
      },
  
      ButtonIconEvent2(row){
        this.IconEvent2(row);
      },
  
      DownloadButton(row){
        this.downloadIconEvent(row);
      },
  
      ButtonIconEvent3(row){
        this.IconEvent3(row);
      },
  
      viewButton(row,index) {
        this.viewIconEvent(row,index);
        this.sendDataForFavorite();
      },
  
  
      AddTime(SubItems){       
        this.getheader(SubItems);     
        
      },
  
      ondblClicked(item){    
         this.ondblClick(item);
      },
  
  
  
      Compare(itemA, itemB, key) {
        if(this.FieldsDate !== undefined && this.FieldsDate !== null){
          if(!this.FieldsDate.includes(key)){
            return false;
          } else {
            let a = (itemA[key] === null || itemA[key] === ""?'00/00/0000 00:00:00':itemA[key]);
            let b = (itemB[key] === null || itemA[key] === ""?'00/00/0000 00:00:00':itemB[key]);
            const dateA = new Date(moment(Utils.formatterDate(a, Utils.getFullDateTimeFormat())).format('MM/DD/YYYY HH:mm:ss')) 
            const dateB = new Date(moment(Utils.formatterDate(b, Utils.getFullDateTimeFormat())).format('MM/DD/YYYY HH:mm:ss'))
            return dateA - dateB;
          }
        }
      },

  
      Check(e,row){
        if(e){
           this.IsCheckBoxClicked(row);
        }
      },
  
        async IsCheckBoxClicked(row) {
          let SendObject = {}     
  
          if (this.IsSchedulerNotes) {
            SendObject = {       
              activityId:row.item.activityId,
              currentPage:1,
              perPage:10 
            } 
          } else {
            SendObject = {       
              notesId:row.item.notesId,
              currentPage:1,
              perPage:10 
            } 
          }       
          this.CheckClick(SendObject);
        },
       
  
        getReportTitle(note, firstname, lastname, patientcode) {
          let reportTitle = `${note.length > 50 ? note.substring(0,50) + '...' : note} - ${firstname} ${lastname}`
          reportTitle = reportTitle.length + patientcode.length <= 100 ? `${reportTitle} - ${patientcode}` : `${reportTitle}`
          return reportTitle
        },
  
        closeRepWindow() {
          this.isReportVisible = false
        },
  
        selectRow(row) {
          this.$refs[this.refTable].selectRow(row)
        },
          /**
         * Only for favorites button action
         */
        sendDataForFavorite(){
          var send = {
            isVisible: false,
            isFav:false
          };
          this.$store.commit('setBtnFavoriteState', send.isVisible);
          EventBus.$emit('shwoHideFavorite', send);
          EventBus.$emit('onUpdatedMenu', '');
        } /**End */
   },
    created() {    
     
    //  if(this.subItems) {
      
     // }    
  
      if(this.UseImage) {
        if(!this.isReadOnly && this.fFields){
          this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2),thClass: 'ActionsClass'});
        }      
      }
  
    
      this.fMultiSelect = this.multiSelect ? 'multi' : 'single';
    }, 
      /*beforeDestroy () {     
        EventBus.$off('onChangeNoteStatus');       
      },*/
  }
  
  </script>
  
  <style>
  
  .ActionsClass {
     width: 150px !important;
  }
  .TimeNotes{
   border: 1px solid #F3F3F3;
   background-color: #f9fbfc;
   color: #999;
   padding: 8px;
   border-radius: 6px;
   margin: 4px;
   cursor: pointer;
   transition: all 0.1s ease-out;
  }
  .addNotesBtn{
    background-color:#19BEE8;
    margin-right: 4px;
      border-radius:10px 2px;
      cursor:pointer;
    vertical-align: top;
      color:#ffffff;
      padding:4px 11px;
      text-decoration:none;
    border: 0;
  }
  .addNotesBtn:focus{
      background-color: #19BEE8 !important;
    color: #FFF !important;
  }
  
  .iconDisabled{
    opacity: 0.2;
    filter: grayscale(100%);
    }
  </style>
  