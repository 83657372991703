import ApiService from '@/services/api.service'


const MedicationService = {
    getAll( parameter) {          
      const requestData = {
        method: 'get',
        url: "/PatientMedications/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          PatientCode : parameter.PatientCode,
          Status : parameter.Status
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/PatientMedications/${id}`);
    },

    getPrescribers() {
      return ApiService.get(`/PatientMedications/Prescribers`);
    },
   
    getMedFreq() {
      return ApiService.get(`/VW_MdDrugFreqSetupHdrOnlyAdministrations`);
    },
    getMedIndications() {
      return ApiService.get(`/MdRxIndications`);
    },

    add(data){
      return ApiService.post(`/PatientMedications`,data)
    },

    delete(id){
      return ApiService.delete(`/PatientMedications/${id}`)
    },
    
    update(id,data){
      return ApiService.put(`/PatientMedications/${id}`,data)
    },

    getMarsTimes(Freq,Dosage) {
      return ApiService.get(`/PatientMedications/MarsTime?FrequencyCode=${Freq}&Dosage=${Dosage}`);
    },

    getDrugInfo(DrugCode,FreqCode,DailyAmount,SDTime,EDTime) {
      return ApiService.get(`/PatientMedications/DrugInfo?DrugCode=${DrugCode}&FrequencyCode=${FreqCode}&DailyAmount=${DailyAmount}&StartDateTime=${SDTime}&EndDateTime=${EDTime}`);
    },

    getQuantityToOrder(parameter) {
      const params = {      
        DrugCode:parameter.DrugCode,
        FrequencyCode:parameter.FreqCode,
        DailyAmount:parameter.DailyAmount,
        StartDateTime:parameter.SDTime,
        EndDateTime:parameter.EDTime,
        Day:parameter.Day,
        MarsAdminTime:parameter.MarsAdminTime,   
        MarsDosage:parameter.MarsDosage,           
      }
      return ApiService.getWithArray('/PatientMedications/QuantityToOrder',params); 
    },

    getDrugFrequencyByCode(code) {
      return ApiService.get(`/DrugFrequencyHeaders/${code}`);
    },

    getSig(parameter){
          const params = {      
            DrugCode:parameter.DrugCode,
            FrequencyCode:parameter.FreqCode,
            Day:parameter.Day,
            Dosage:parameter.Dosage,
            DailyAmount:parameter.DailyAmount,
            Action:parameter.Action,
            Route:parameter.Route,
            WhenSig:parameter.WhenSig,
            Reason:parameter.Reason,
            MarsAdminTime:parameter.MarsAdminTime,   
            MarsDosage:parameter.MarsDosage           
          }
          return ApiService.getWithArray('/PatientMedications/Sig',params); 
     },

     CopyOrdeer(data){
      return ApiService.post(`/PatientMedications/CopyOrdeer`,data)
    },

    InactiveOrders(data){
      return ApiService.post(`/PatientMedications/InactiveOrders`,data)
    },

    IsPrescriptionCurrent(parameter){
      const params = {      
        PatientCode:parameter.PatientCode,
        DrugCode:parameter.DrugCode,
        Mode:parameter.Mode,
        RecordId:parameter.RecordId,
        Action:parameter.Action,
        StartDateTime:parameter.StartDateTime,
        EndDateTime:parameter.EndDateTime,
        Day:parameter.Day        
      }
      return ApiService.getWithArray('/PatientMedications/IsPrescriptionCurrent',params); 
    },
    getDrugs() {
      return ApiService.get(`/PatientMedications/AllDrugs?`);
    }, 
   
}
  
  export default MedicationService;