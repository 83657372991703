<template>
    <div id="ContainerAllergySetupList">
        <div class="row">
            <div class="col-12">
                <h4 class="page-title text-truncate">{{$t('AllergySetupList.AllergySetupTitle')}}</h4>
                <div class="row">
                    <div class="col-sm-12 col-md-3">                      
                        <MdButton
                            v-bind:Label="$t('AllergySetupList.AddAllergySetup')" 
                            v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                            v-bind:IconClass="'ti-plus pr-1'"
                            v-on:click="AddAllergy"
                            v-bind:Name="'btn_add_allergy'">
                        </MdButton>
                    </div>

                    <div class="col-sm-12 col-md-9 mb-4">
                        <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                            <div class="input-group">
                                <input 
                                    type="text" 
                                    class="form-control"
                                    v-model="parameter.SearchCriteria"   
                                    @keyup.enter="SearchAllergy"                            
                                >
                                <div class="input-group-append">
                                    <div class="input-group-append">
                                        <MdButton 
                                            v-bind:Name="'btnsearchinput'"
                                            v-bind:VariantProp="'btn btn-sm search-attached'"
                                            v-bind:IconClass="'mdi mdi-magnify'"
                                            v-on:click="SearchAllergy">
                                        </MdButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-2">
                <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <div class="row">
                    <div class="col-md-12">
                        <MdTable 
                            v-bind:fields="fields"
                            v-bind:items="items"
                            v-bind:totalRows="totalRows"
                            v-bind:isReadOnly="isReadOnly"
                            v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                            v-bind:currentPage="currentPage"
                            v-bind:perPage="perPage"
                            v-bind:pageOptions="pageOptions"
                            :editIconEvent="EditAllergy"
                            v-bind:VisibleEditIcon="true"
                            :deleteIconEvent="RemoveAllergy"
                            v-bind:VisibleDeleteIcon="true"
                            :onRowSelected="onRowSelected"
                            :onFiltered="onFiltered"
                            :pageChanged="pageChanged"
                            :perPageFunction="PerPageFunction">
                        </MdTable>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AllergySetupService from '@/services/allergiesetup.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../../event-bus'

export default {
    mixins: [ShowMessage, DBFunctions],
    name: 'AllergySetupList',

    data() {
        return {
            ShowMessageIsEmpty:false,
            Loading:true,
            permission:[],
            Menu : this.$store.state.leftMenu,
            OptionsRightMenu:{
                visible:false,
                ItemsList:[],
                btnvisible:false,
                itemdisabled: false,
                PatientCode:'',
                Mode:this.edit_mode,
                favButtonAtion:false          
            },
            Mode:'',         
            parameter:{
                SearchCriteria:'',
                PageNumber : 0,
                PageSize:0 ,
            },
            fields: [
                { key: 'code', sortable: true, label: this.$t('Shared.Code'), class: 'text-rigth' }, 
                { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth'}, 
                { key: 'category', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth'},     
                { key: 'drugCode', sortable: true, label: this.$t('AllergySetup.Substance'), class: 'text-rigth' },  
                { key: 'drugName', sortable: true, label: this.$t('AllergySetup.SubstanceDescription'), class: 'text-rigth' }, 
                { key: 'problemCode', sortable: true, label: this.$t('AllergiesList.Problem'), class: 'text-rigth' }, 
                { key: 'problemDescription', sortable: true, label: this.$t('Allergy.ProblemDescription'), class: 'text-rigth' },
            ],
            pageOptions: [5, 10, 15 ],
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            filter: null,
            filterOn: [],
            items: [],
            selected: [],
            isReadOnly: false
        }
    },

    methods: {
        AddAllergy(){
            this.Mode = "Add";
            this.emitDataforFavorites();
            EventBus.$emit('onUpdatedMenu', '');    
            this.$router.push({ name: "allergy-setup-add", params: { id: this.$route.params.id } }).catch(()=>{});
        },

        EditAllergy(row){
            this.code = row.code;
            this.Mode = "Edit";
            this.$router.push({ name: "allergy-setup-details", params: { id: this.code } });
        },

        RemoveAllergy(row){
            const Id = row.code
            this.$bvModal
                .msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
                            title: this.$tc('Shared.DelRecord'),
                            okTitle: this.$t('Shared.TitleDelete'),
                            cancelTitle: this.$t('Shared.BtnCancel'),
                            footerClass: 'p-2',
                            titleClass: 'modal-title modal-type-warning',
                            id:'modal_delete_allergy'
                        })
                .then(value => {
                    if(value){
                        AllergySetupService
                            .deleteAllergy(Id)
                            .then(() => {
                                const index = this.items.findIndex(item => item.code === Id) // find the post index 
                                if (~index) {// if the post exists in array 
                                    this.items.splice(index, 1) //delete the post and refresh view
                                    this.getAllergyListing(); //refresh listing
                                }
                            })
                            .catch((error) => {
                                if (!error.response) {
                                    // network error
                                    this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.NotAbleToDelete'),"error");
                                } else {
                                    this.showMessage(this.$t('Allergy.Allergy'),error,"error");
                                }
                            });
                    }
                })
        },

        getAllergyListing(){
            this.parameter.PageNumber = this.currentPage;           
            this.parameter.PageSize = this.perPage;
            this.$emit('load', true);       
            AllergySetupService
                .getAll(this.parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.items = response.data;      
                        this.$emit('load', false);
                        let headerString = response.headers["x-pagination"];
                        this.totalRows = JSON.parse(headerString).TotalCount;
                    }
                    this.$emit('load', false);                
                })
                .catch((error) => {
                    this.$emit('load', false);
                    if (error.response) {
                        this.showMessage(this.$t('Allergy.Allergy'),error,"error");
                    }
                });  
        },

        pageChanged (page) {            
            this.currentPage = page;
            this.getAllergyListing();
        },

        PerPageFunction(perpage){
            this.perPage = perpage;
            this.currentPage = 1;
            this.getAllergyListing();
        },

        SearchAllergy() {
            this.$emit('load', true);
            this.items = [];
            this.currentPage = 1;
            this.parameter.PageNumber = 1;
            this.totalRows = this.items.length;
            this.getAllergyListing();
        },

        onRowSelected(items) {    
            this.selected = items;
        },

        selectAllRows() {
            this.$refs.selectableTable.selectAllRows()
        },

        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },

        selectThirdRow() {
            this.$refs.selectableTable.selectRow(2)
        },

        unselectThirdRow() {
            this.$refs.selectableTable.unselectRow(2)
        },

        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },

        CanView(){
            return this.permission[0].roleCanView;
        },
    },

    created(){
        this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);  
    },

    mounted() {
        this.OptionsRightMenu.ItemsList = [];
        let layoutStatus = this.$store.state.layoutStatus;
        switch(layoutStatus) {
            case 'L0R1':
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
                break;
            case 'L1R0':
            case 'L1R1':
                this.OptionsRightMenu.btnvisible = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
            break;
            default:
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
        }

        this.getAllergyListing();   
    },

    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
    },
}
</script>