<template>
    <div class="notes several">
        <h4 class="note-title text-truncate" v-b-tooltip.hover >
            <span :class="icon"></span>
            <span> {{this.title}} </span> 
        </h4>

        <b-icon
            icon="x-circle-fill"
            v-on:click="Close"
            class="rounded-circle notes-close"
            scale=".5"
            font-scale="1.8"
            > <!-- create the CloseNote action. Open notes should be closed with the HIDDEN class -->
        </b-icon>

        <div class="row"> 
                <div class="col-md-3">
                    <MdEdit 
                        v-bind:Label="$t('PatientDocument.DocumentType')"
                        v-bind:TypeProp="'text'" 
                        v-bind:Name="'document_typesDetail'"
                        v-model="document.description"
                        v-bind:isReadOnly="this.isReadOnly"
                        v-bind:Id="'document_typesDetail'">                                     
                    </MdEdit>
                </div>             
                <b-col class="col-md-3">
                    <MdEdit 
                        v-bind:Label="$t('PatientDocument.ExpirationDate')"
                        v-bind:TypeProp="'date'" 
                        v-bind:Name="'expirationDate'"
                        v-model="ExpirationDate"
                        v-bind:isReadOnly="this.isReadOnly"
                        v-bind:Id="'input_expirationdate'">                                     
                    </MdEdit>
                </b-col>   
                <div class="col-md-6">
                    <MdEdit 
                        v-bind:Label="$t('PatientDocument.Comments')"
                        v-bind:TypeProp="'text'" 
                        v-bind:Name="'comments'"                      
                        v-model="document.comments"
                        v-bind:isReadOnly="this.isReadOnly"
                        v-bind:Id="'comments'">                                     
                    </MdEdit>
                </div>             
            </div>

            <LoadDocument 
                v-bind:Records="this.files" 
                v-bind:ModeEdit="false"
                v-bind:DocType="this.DocumentTypesVModel.description" 
                v-bind:DocCode="this.DocumentTypesVModel.code"
                v-bind:UserId="UserId"
                v-bind:DocumentTypeList="OptionDocumentTypes"
                v-bind:IsReadOnly="this.isReadOnly"
            />
    </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import LocalStorage from '@/services/storage.service'
import Utils from '@/common/utils'

export default {
    name: 'DocumentChartReview',
    props: {
        document: Object,
    },

    data: function() {
        return { 
            icon:"ikon med-doc-complete mr-2",
            isReadOnly: true,
            OptionDocumentTypes:[],
            DocumentTypesVModel:{code:this.document.doccode,description:this.document.description}, 
            isDocumentTypeValid:true,
            UserId: LocalStorage.getUserId(),
            files:[this.document],
            ExpirationDate: Utils.formatterDate(this.document.expirationDate),
            Comments: this.document.comments
        }
    },

    computed: {
        title() {
            return Utils.formatterDateToShortLocaleString(this.document.scanneddate) + " - " + this.document.description;
        }
    },

    methods: {
        async Close() {   
            this.$emit('remove');
            EventBus.$emit('CloseDocument');
        }
    }
}
</script>