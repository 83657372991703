<template>
  <div id="m-navbar" class="m-layout m-navbar" @click="closeMenuClick()">
    <!-- BOTTOM NAVBAR START -->
    <ChangePassWord v-if="changePassDialogCmp" />
    <Preferences/>
    <b-row class="w-100">
      <b-col cols="8">
          <b-dropdown
          id="startMenuButton"
          :html="trademark()"
          variant="primary"
          align-h="start"
          size="sm"
          no-caret
          class="mr-3"
          @hide="beforeClose"
          @toggle="openMenuClick()"
        >
        <b-dropdown-header id="dropdown-header-label" align-v="center">
            <span class="text-primary"
              ><strong>{{ Name }}</strong></span
            >
        </b-dropdown-header>

        <ejs-menu style="box-shadow: none !important;" ref="menu" id="menu" :items='menuItems' orientation='Vertical' :beforeOpen='onBeforeOpen' :select='select'></ejs-menu>
            
        </b-dropdown>
        <!-- FAVORITES COMPONET -->
        <Favorites/>
      </b-col>
      <b-col cols="4" class="text-right">
        <b-button-group id="toogleMenuButtons" size="sm">
          <b-button
            v-b-tooltip.hover
            :title="leftButtonTitle"
            id="leftMenuButton"
            variant="outline-dark"
            v-on:click="updateLayoutLeft()"
            ><b-icon
              id="leftMenuIcon"
              :icon="leftIconName"
              aria-hidden="true"
            ></b-icon
          ></b-button>
          <b-button
            v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible || btnCNContainervisible"
            id="bothMenuButton"
            variant="outline-dark"
            v-on:click="updateLayoutBoth()"
            ><b-icon
              id="bothMenuIcon"
              :icon="middleIconName"
              aria-hidden="true"
            ></b-icon
          ></b-button>
          <b-button
            v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible || btnCNContainervisible"
            id="rightMenuButton"
            variant="outline-dark"
            v-on:click="updateLayoutRight()"
            ><b-icon
              id="rightMenuIcon"
              :icon="rightIconName"
              aria-hidden="true"
            ></b-icon
          ></b-button>

          <!-- <b-tooltip variant="outline-dark" target="leftMenuButton" triggers="hover" :title="leftButtonTitle"></b-tooltip> -->
          <b-tooltip
            v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible || btnCNContainervisible"
            variant="outline-dark"
            target="bothMenuButton"
            triggers="hover"
            :title="bothButtonTitle"
            style="z-index:0"
          ></b-tooltip>
          <b-tooltip
            v-if="btnvisible || btnGroupNotesvisible || btnIncidentWorkflowvisible || btnCNContainervisible"
            variant="outline-dark"
            target="rightMenuButton"
            triggers="hover"
            :title="rightButtonTitle"
            style="z-index:0"
          ></b-tooltip>
        </b-button-group>
        <!-- :title="mDateNow" -->
        <b-badge
          id="mClock"
          variant="dark"
          v-b-tooltip.hover
          class="ml-3 hideMobile d-none"
          v-on:click="changeTimeFormat()"
          >{{ mTimeNow }}</b-badge
        >
        <b-badge
          id="mLogOut"
          variant="success"
          v-b-tooltip.hover
          :title="logoutbtn"
          class="ml-1 hideMobile d-none"
          v-on:click="logout()"
          ><i class="ikon med-logout"></i></b-badge>
      </b-col>
    </b-row>
    <!-- VERTICAL WARNING -->
    <!--
    <div class="verticalWarning">
      <i class="ikon med-isologo"></i> best in landscape mode. please, rotate your device.
    </div>
    -->
    <!-- VERTICAL WARNING END -->
    <!-- BOTTOM NAVBAR END -->
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
import ShowMessage from "@/components/messages/ShowMessage.js";
import ChangePassWord from "@/components/pages/profile/ChangePassWord";
import UserService from "@/services/user.service";
import ProviderService from "@/services/provider.service";
import Favorites from "@/components/template/favorites/Favorites";
import UsersPreferences from '@/services/userpreferences.service'
import Preferences from "@/components/pages/preferences/userPreferences";
import StorageService from "@/services/storage.service";
import { MenuPlugin } from "@syncfusion/ej2-vue-navigations";
import $ from "jquery";
import { enableRipple, closest } from '@syncfusion/ej2-base';
enableRipple(true);
import Vue from 'vue';
Vue.use(MenuPlugin);


export default {
  components: { 
    ChangePassWord,
    Preferences,
    Favorites
  },
  props: {
    Name : String,
    btnvisible: Boolean,
    btnGroupNotesvisible:Boolean,
    btnCNContainervisible:Boolean,
    btnIncidentWorkflowvisible:Boolean,
    favButtonAtion: Boolean
  },
  name: 'BottomBar',
  mixins: [ShowMessage],
  data(){
    return{
      closeMenu: true,
      menuItems:[],
      leftButtonTitle: this.$t('Layout.HideLeftMenu'),
      rightButtonTitle: "",
      bothButtonTitle: "",
      middleIconName: "fullscreen",
      leftIconName: "arrow-bar-left",
      rightIconName: "arrow-bar-right",
      mDateNow: this.$t('Layout.TodayIs'),
      mTimeNow: this.$t('Layout.WELCOME'),
      mHdiv: ":",
      m24Format: true,
      mDateFormat: 1,
      providerList: [],
      changePassDialogCmp: false,
      userId : StorageService.getUserId(),
      TimeFormatPreference: {'TimeFormat':false},
      DateFormatPreference: {'DateFormat':''},
      layoutStatus: "",
      Infolayout: {
        L0R0: "L0R0",
        L1R0: "L1R0",
        L0R1: "L0R1",
        L1R1: "L1R1",
      },
      logoutbtn: this.$t('SelectProvider.LogOutTitle'),
    }
  },
  methods:{
    closeMenuClick() {
      this.closeMenu = true
    },

    trademark() {
      //return "MedEZ<sup>®</sup>";
      return "<i class='ikon med-isologo'></i> "+this.$t('Layout.Start');
    },
    beforeClose(event) {
      if (this.closeMenu == false) {
        event.preventDefault()
      }
    },
    openMenuClick() {
      this.closeMenu = !this.closeMenu
    },
    setProviderInMenu() {
      if (this.providerList && this.providerList.length > 1) {
        this.menuItems.splice(7,0, { id: 'provider', text: this.$t('Main.ChangeProvTitle'), iconCss: 'ikon med-change-item' })
      }
    },

    returnReactiveStartMenu(){
      this.menuItems = [
        { id: 'quickhelp', text: this.$t('Layout.QuickHelp'), iconCss: 'ikon med-help' },
        //{ id: 'knowledgecenter', text: this.$t('Layout.KnowCenter'), iconCss: 'ikon med-help' },
        //{ id: 'customersupport', text: this.$t('Layout.CusSupport'), iconCss: 'ikon med-help' },
        { id: 'assistance',text: this.$t('Layout.Assistance'), iconCss: 'ikon med-help',
              items: [
                { id: 'quickhelp2', text: this.$t('Layout.QuickHelp'), iconCss: 'ikon med-help' },
                //{ id: 'knowledgecenter2', text: this.$t('Layout.KnowCenter'), iconCss: 'ikon med-help' },
                //{ id: 'customersupport2', text: this.$t('Layout.CusSupport'), iconCss: 'ikon med-help' },
              ]
        },
        { separator: true },
        { id: 'homepage', text: this.$t('Layout.Homepage'), iconCss: 'ikon med-homepage' },
        { separator: true },
        { id: 'changepassword', text: this.$t('Layout.ChangePassword'), iconCss: 'ikon med-change-pass' },
        { separator: true },
        { id: 'preferences', text: this.$t('UserPreferences.PrefTitle'), iconCss: 'ikon med-preferences' },
        { separator: true },
        { id: 'logout', text: this.$t('SelectProvider.LogOutTitle'), iconCss: 'ikon med-logout' },
      ]
    },
    
    onBeforeOpen(args) {
      let relativeOffset = closest(args.event.target, '.e-menu-item').getBoundingClientRect();
      let subMenuEle = closest(args.element, '.e-menu-wrapper');
      subMenuEle.style.display = 'block';
      args.top = (relativeOffset.top - subMenuEle.getBoundingClientRect().height) + relativeOffset.height //pageYOffset;
      args.left = relativeOffset.left + relativeOffset.width //pageXOffset;
      subMenuEle.style.display = '';
    },
    select: function(args) {
      let menuId = args.item?.id || null;
      
      switch (menuId) {
        case 'preferences':
          this.UserPreferences();
          break;
        case 'changepassword':
          this.OpenChangePassWord();
          break;
        case 'provider':
          this.GoToProvidersList();
          break;
        case 'homepage':
          this.launchPage('Homepage', '/app/main', 'Main')
          /**Added ref to be able to close 
           * start menu when click in Homepage option*/
          this.clickParentDiv();
          break;
        case 'logout':
          this.logout()
          break;
        case 'knowledgecenter':
        case 'knowledgecenter2':
          this.Help()
          break;  
        case 'quickhelp':
        case 'quickhelp2':
          this.QuickHelp()
           /**Added ref to be able to close 
           * start menu when click in quickhelp option*/
          this.clickParentDiv();
          break;
      }
      this.closeMenu = this.closeMenu === true ? menuId !== 'assistance' : false
    },
    syncButtons(syncStore) {
      const buttonConfig = {
        [this.Infolayout.L0R0]: {
            leftIcon: "arrow-bar-right",
            leftTitle: this.$t('Layout.ShowLeftMenu'),
            rightIcon: "arrow-bar-left",
            rightTitle: this.$t('Layout.ShowRightMenu'),
            middleIcon: "fullscreen-exit",
            bothTitle: this.$t('Layout.ShowBothMenu'),
        },
        [this.Infolayout.L1R0]: {
            leftIcon: "arrow-bar-left",
            leftTitle: this.$t('Layout.HideLeftMenu'),
            rightIcon: "arrow-bar-left",
            rightTitle: this.$t('Layout.ShowRightMenu'),
            middleIcon: "fullscreen",
            bothTitle: this.$t('Layout.HideBothMenu'),
        },
        [this.Infolayout.L0R1]: {
            leftIcon: "arrow-bar-right",
            leftTitle: this.$t('Layout.ShowLeftMenu'),
            rightIcon: "arrow-bar-right",
            rightTitle: this.$t('Layout.HideRightMenu'),
            middleIcon: "fullscreen",
            bothTitle: this.$t('Layout.HideBothMenu'),
        },
        [this.Infolayout.L1R1]: {
            leftIcon: "arrow-bar-left",
            leftTitle: this.$t('Layout.HideLeftMenu'),
            rightIcon: "arrow-bar-right",
            rightTitle: this.$t('Layout.HideRightMenu'),
            middleIcon: "fullscreen",
            bothTitle: this.$t('Layout.HideBothMenu'),
        },
      };
      const config = buttonConfig[syncStore];
      if (config) {
        this.leftIconName = config.leftIcon;
        this.leftButtonTitle = config.leftTitle;
        this.rightIconName = config.rightIcon;
        this.rightButtonTitle = config.rightTitle;
        this.middleIconName = config.middleIcon;
        this.bothButtonTitle = config.bothTitle;
      }
    },

    updateLayoutLeft() {
      // Definimos las correspondencias entre estados
      const layoutMap = {
          [this.Infolayout.L0R0]: this.Infolayout.L1R0,
          [this.Infolayout.L1R0]: this.Infolayout.L0R0,
          [this.Infolayout.L0R1]: this.Infolayout.L1R1,
          [this.Infolayout.L1R1]: this.Infolayout.L0R1,
      };
      
      // Actualizamos layoutStatus según las correspondencias
      this.layoutStatus = layoutMap[this.layoutStatus] || this.Infolayout.L1R0;

      // Sincronizamos los botones y el estado en el store
      this.syncButtons(this.layoutStatus);
      this.$store.commit("setlayoutStatus", this.layoutStatus);
      
      // Emitimos el evento
      EventBus.$emit('syncLayout', this.$store.state.layoutStatus);
    },

    updateLayoutRight() {
      const layoutMap = {
          [this.Infolayout.L0R1]: this.Infolayout.L0R0,
          [this.Infolayout.L0R0]: this.Infolayout.L0R1,
          [this.Infolayout.L1R0]: this.Infolayout.L1R1,
          [this.Infolayout.L1R1]: this.Infolayout.L1R0,
      };

      this.layoutStatus = layoutMap[this.layoutStatus] || this.layoutStatus;

      this.syncButtons(this.layoutStatus);
      this.$store.commit("setlayoutStatus", this.layoutStatus);

      EventBus.$emit('syncLayout', this.$store.state.layoutStatus);
    },

    updateLayoutBoth() {
      const layoutMap = {
          [this.Infolayout.L0R1]: this.Infolayout.L0R0,
          [this.Infolayout.L1R0]: this.Infolayout.L0R0,
          [this.Infolayout.L1R1]: this.Infolayout.L0R0,
          [this.Infolayout.L0R0]: this.Infolayout.L1R1,
      };

      this.layoutStatus = layoutMap[this.layoutStatus] || this.layoutStatus;

      this.syncButtons(this.layoutStatus);
      this.$store.commit("setlayoutStatus", this.layoutStatus);

      EventBus.$emit('syncLayout', this.$store.state.layoutStatus);
    },
    async getProviders() {
      await ProviderService.getAll(this.userId)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            this.providerList = response.data;
          }
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('SelectProvider.Providers'), error, "error");
          }
        });
    },
    QuickHelp() {
      this.$bvModal.show("bv-modal-whatsnew");
    },

    Help(){
      window.open("/docs", '_blank');
    },

    UserPreferences() {
      this.$bvModal.show("bv-modal-preferences");
    },
    GoToProvidersList() {
      this.$bvModal
        .msgBoxConfirm(this.$t('Main.ChangeProvMsg'), {
          title: this.$t('Main.ChangeProvTitle'),
          okTitle: this.$t('Main.CHANGE'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
        })
        .then((value) => {
          if (value) {
            this.$router.push("/provider").catch(() => {});
          }
        });
    },
    logout() {
      this.$bvModal
        .msgBoxConfirm(this.$t('SelectProvider.CloseSessionMsg'), {
          id: "modal_logout",
          title: this.$t('SelectProvider.LogOutTitle'),
          okTitle: this.$t('Login.LogOut'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: "p-2",
          titleClass: "modal-title modal-type-warning",
        })
        .then((value) => {
          if (value) {
            UserService.logout()
              .then((response) => {
                if (response.status == "200") {
                  this.$store.commit("setAccessToken", "");
                  localStorage.removeItem("vuex");
                  localStorage.removeItem("_secure__ls__metadata");
                  this.$router.push("/login");
                }
              })
              .catch((error) => {
                  this.$store.commit("setAccessToken", "");
                  localStorage.removeItem("vuex");
                  localStorage.removeItem("_secure__ls__metadata");
                  this.$router.push("/login");
                if (!error.response) {
                  this.showMessage(
                    this.$t('Layout.LoggingOut'),
                    this.$t('SelectProvider.NoAbleToLogOutMsg'),
                    "error"
                  );
                } else {
                  this.showMessage(this.$t('Layout.LoggingOut'), error, "error");
                }
              });
          }
        });
    },
    getNow() {
      const today = new Date();
      const dDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
          .map(day => this.$t(`Layout.dDay.${day}`));
      const dMonth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
          .map(month => this.$t(`Layout.dMonth.${month}`));

      const Hr = today.getHours();
      const Mn = String(today.getMinutes()).padStart(2, '0');
      const Dw = today.getDay();
      const My = today.getMonth();
      const dayOfMonth = today.getDate();

      // Determinar el sufijo para el día
      const suffix = dayOfMonth === 1 || dayOfMonth === 21 || dayOfMonth === 31 ? 'st'
        : dayOfMonth === 2 || dayOfMonth === 22 ? 'nd'
        : dayOfMonth === 3 || dayOfMonth === 23 ? 'rd'
        : 'th';
      // Alternar el separador de hora
      this.mHdiv = this.mHdiv === " " ? ":" : " ";
       // Formatear hora
      const displayHour = this.m24Format ? String(Hr).padStart(2, '0') : (Hr % 12 || 12);
      const period = this.m24Format ? '' : (Hr >= 12 ? ' PM' : ' AM');  
      this.mTimeNow = `${displayHour}${this.mHdiv}${Mn}${this.m24Format ? ' h' : period}`;

       // Formatear fecha
      const dateFormatOptions = [
        () => `${dDay[Dw].substr(0, 3)}. ${dMonth[My].substr(0, 3)}. ${dayOfMonth}${suffix}, ${today.getFullYear()}`,
        () => `${dDay[Dw]} ${dMonth[My].substr(0, 3)}. ${dayOfMonth}${suffix}, ${today.getFullYear()}`,
        () => `${dDay[Dw]} ${dMonth[My]} ${dayOfMonth}${suffix}, ${today.getFullYear()}`,
        () => `${dDay[Dw].substr(0, 3)}. ${My + 1}/${dayOfMonth}/${today.getFullYear()}`,
        () => `${dDay[Dw]} ${My + 1}/${dayOfMonth}/${today.getFullYear()}`,
        () => `${dDay[Dw]} ${dMonth[My].substr(0, 3)}/${dayOfMonth}/${today.getFullYear()}`,
        () => `${dDay[Dw]} ${today.getFullYear()}/${String(My + 1).padStart(2, '0')}/${dayOfMonth}`,
    ];
  
    this.mDateNow = this.mDateFormat > 0 && this.mDateFormat <= dateFormatOptions.length
        ? dateFormatOptions[this.mDateFormat - 1]()
        : '';
    },

    changeTimeFormat() {
      this.m24Format = !this.m24Format;
      this.$store.commit("setClockTime", this.m24Format);

      /** Add for sync with user preferences */
      this.TimeFormatPreference.TimeFormat = this.m24Format;
      UsersPreferences.ChangeTimeFormat(this.userId, this.TimeFormatPreference)
      /** End */
      this.getNow();
    },

    changeDateFormat() {
      if (this.mDateFormat <= 7) {
        this.mDateFormat = this.mDateFormat + 1;
      } else {
        this.mDateFormat = 1;
      }
      localStorage.setItem('dateFormat', this.mDateFormat)
     
      /** Add for sync with user preferences */
      this.DateFormatPreference.DateFormat = this.mDateFormat.toString;
      /** End*/

      this.getNow();
    },
    launchPage(title, url, page) {
      if (this.$router.currentRoute.name == page ) {
        this.$bvToast.toast(
          this.$t('Layout.displayedScreen'),
          {
            title: this.$t('Layout.'+title),
            autoHideDelay: 4000,
            variant: "success",
          }
        );
      } else {
          this.$router.push(url).catch(() => {});
      }
      EventBus.$emit('onUpdatedMenu', page);
      
      if( page == 'Main') {
        var item = {
            "title": this.$t('Layout.'+title),
            "href": url,
            "child": [],
            "parentIcon": 'ikon med-homepage-fill',
            "icon": 'ikon med-homepage-fill',
            "originalTitle": title
        }
        EventBus.$emit('getClickMenuItemData', item);
       /* 
       // Favorites Accions hide  
        var send = {
            isVisible: true,
            isFav:false
          };
        this.$store.commit('setBtnFavoriteState', send.isVisible);
        EventBus.$emit('shwoHideFavorite', send); */
        
        // sync layout status
        this.$store.commit("setlayoutStatus", this.Infolayout.L1R0);
        this.layoutStatus = this.Infolayout.L1R0;
      }
    },
    OpenChangePassWord() {
      this.changePassDialogCmp = true;
      this.$forceUpdate();
      this.$nextTick(() => {
        setTimeout(() => this.$bvModal.show("bv-modal-ChangePassWord"), 500);
      });
    },
    getUserPreference() {
      UsersPreferences.getUserPreference(this.userId)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            response.data[0].showTimeTaskbar ?  
              $("#mClock").removeClass("d-none") : 
              $("#mClock").addClass("d-none")
          }
        })
        .catch((error) => {
          if (error.errorMessage) {
            this.showMessage(this.$t('UserPreferences.UPTitle'), error, "error");
          }
        });
    },
    clickParentDiv() {
      const parentDiv = this.$parent.$refs.mcontainer;
      if (parentDiv) {
        parentDiv.click();
      } else {
        console.error("Parent div not found");
        return false;
      }
    }
  }, 
  async mounted() {
    this.m24Format = this.$store.state.m24Format;
    this.mDateFormat = this.$store.state.dateFormat;

    await this.getProviders();
    this.returnReactiveStartMenu();
   
    this.$nextTick(() => {
      this.setProviderInMenu();
      this.layoutStatus = this.$store.state.layoutStatus
    });
  },
  created(){
    this.getUserPreference();
    setInterval(this.getNow, 1000);

    EventBus.$on("showTimeTaskbar", function (valid) {
      valid ? 
        $("#mClock").removeClass("d-none") : 
        $("#mClock").addClass("d-none")
    }.bind(this));

    EventBus.$on("m24Format", function (valid) {
      this.m24Format = valid;
      this.getNow();
      this.$store.commit("setClockTime", this.m24Format);
    }.bind(this));

    EventBus.$on("syncButtons", function (syncStore) {   
      this.syncButtons(syncStore);
      this.layoutStatus = syncStore
    }.bind(this));

    EventBus.$on("closeMenuLayout", function (state) {   
      this.closeMenu = state ;
    }.bind(this));
  }
};
</script>