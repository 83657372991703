<template>
    <div >
      <MdTitle v-bind:Text="$t('PatientAlerts.PatientAlerts')" />
      <b-row>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
              v-bind:Label="$t('Shared.datefrom')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'datefrom'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateFrom"
              v-bind:isRequired="true">                                     
            </MdEdit>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
              v-bind:Label="$t('Shared.dateTo')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'dateto'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateTo"
              v-bind:isRequired="true">                                     
            </MdEdit>
          </b-col>
          <b-col lg="3" md="3" sm="12">         
            <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="PatientsList"
              v-bind:Model="PatientCodeModel"
              v-bind:FieldDescription="'patientName'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Multiple="true"             
              :Input="ChangeDropDownPatients"
              :CustomLabel="customLabelPatients"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
                v-bind:Label="$t('PatientAlerts.AlertStatus')"
                v-bind:Options="showOptions"
                v-bind:Model="showAlertStatusModel"
                v-bind:FieldDescription="'text'"
                v-bind:FieldCode="'value'" 
                v-bind:Multiple="false"                      
                :Input="ChangeIsActiveAlert"
                v-bind:AllowEmpty="false"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
                v-bind:Label="$tc('Allergy.PatientStatus')"
                v-bind:Options="showOptions"
                v-bind:Model="showPatientIsActiveModel"
                v-bind:FieldDescription="'text'"
                v-bind:FieldCode="'value'" 
                v-bind:Multiple="false"                      
                :Input="ChangeIsActive"
                v-bind:AllowEmpty="false"/>
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$tc('Allergy.AdmissionStatus')"
              v-bind:Options="showOptions"
              v-bind:Model="showIsActiveAdmissionModel"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'" 
              v-bind:Multiple="false"                      
              :Input="ChangeIsActiveAdmission"
              v-bind:AllowEmpty="false"/>
          </b-col> 
     
        </b-row>

        <div class="page-actions">
          <MdButton
            v-bind:Label="$t('Shared.Back')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="reportList"
            v-bind:Name="'btn_ReportList'"/>
          <MdButton
            v-bind:Label="$t('Shared.Report')"
            v-bind:ClassProp="'primary btn-login mx-2'"
            v-on:click="onClickShowReport"
            v-bind:Name="'btn_Print'"/>   
        </div>

      
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('PatientAlerts.PatientAlerts')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="reportPatientAlertFilters"
          :ReportName="'PatientAlertFilters'"
          :ReportTitle="$t('PatientAlerts.PatientAlerts')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
  import Vue from 'vue'
  import { DxPopup } from 'devextreme-vue/popup';
  import PatientService from '@/services/patient.service'
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import DBFunctions from '@/common/DBFunctions.js'

  export default Vue.extend({
      name: "patientAlertFiltersRep",
      mixins: [ ShowMessage, DBFunctions ],
      components: { DxPopup },
      data() {
          return {
              isParamVisible: true,
              isReportVisible: false,
              dateFrom: null,
              dateTo: null,
              reportParams: [],
              PatientsList:[],
              PatientCodeModel:'',
              showOptions: [
                { value: "all", text: this.$t('Shared.All') },
                { value: "true", text:this.$t('Shared.Active') },
                { value: "false", text: this.$t('Shared.Inactive') },
              ],
              showPatientIsActiveModel: {
                "value":"true",
                "text":this.$t('Shared.Active')
              },
              showIsActiveAdmissionModel: {
                "value":"true",
                "text":this.$t('Shared.Active')
              },
              showAlertStatusModel: {
                "value":"true",
                "text":this.$t('Shared.Active')
              },           
              parameter:{
                SearchCriteria:'',
                PageNumber : 0,
                PageSize:0,
                Status: true,       
              },
              params: {
                "PatientCodeList": [],
                "IsActiveAlert": true,
                "IsActive": true,
                "IsActiveAdmission": true
              },
          }
      },
      methods: {

          onShowing(e) {
            let contentElement = e.component.content();
            let titleElement = e.component._$title[0]
            contentElement.style.padding = '0px';
            titleElement.style.padding = '0px 20px'
          },

          onHiddenReport() {
            this.isReportVisible = false
          },

          ChangeDropDownPatients(values) {
            this.params.PatientCodeList = this.getItemsByComma(values, 'patientcode')
          },
          customLabelPatients ({ PatientName, patientcode }) {
            return `${PatientName} – ${patientcode}`
          },
          
          getItemsByComma(values, field) {
            let len = values.length;
            let valuesSeparated = [];
            if (len > 0){
              var i = 0;
              values.forEach(function(e) {   
                if(i < len-1){
                  valuesSeparated += e[field]+",";
                }else{
                  valuesSeparated += e[field];
                }
                i++;                    
              });
              return valuesSeparated;
            } else {
              return [];
            }
          },

          ChangeIsActive(item) {
            this.params.IsActive = item.value
          },
          ChangeIsActiveAdmission(item) {
            this.params.IsActiveAdmission = item.value
          },
          ChangeIsActiveAlert(item) {
            this.params.IsActiveAlert = item.value
          },
          getPatients() { 
            PatientService.getPatientList(this.parameter)
            .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0){
                this.PatientsList = response.data;
                this.PatientsList.forEach(function(element) {
                  element.PatientName = element.firstname + " " + element.lastname;   
                });
              }
            })
            .catch(error => {
              if (!error.response) {
              // network error
                this.showMessage(this.$t('PatientAlerts.PatientAlerts'),this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.$t('PatientAlerts.PatientAlerts'),error,"error");
              }
            });
          },

          onClickClose() {
            this.isParamVisible = false;
          },

          reportList() {
              this.checkExistFavorite('/app/reports/patients');
              this.$router.push({ name: "reportListPatient" }).catch(()=>{});
          },

          onClickShowReport() {
            this.isParamVisible = false

            this.reportParams = []
            this.reportParams.push({ paramName: 'pStartDate', paramValue: this.dateFrom})
            this.reportParams.push({ paramName: 'pEndDate', paramValue: this.dateTo})
            this.reportParams.push({ paramName: 'pPatientCodeList',paramValue: (this.params.PatientCodeList.length == 0) ? this.patientCode :this.params.PatientCodeList })
            this.reportParams.push({ paramName: 'pAlertStatus', paramValue: this.params.IsActiveAlert})
            this.reportParams.push({ paramName: 'pPatientStatus', paramValue: this.params.IsActive})
            this.reportParams.push({ paramName: 'pAdmissionStatus', paramValue: this.params.IsActiveAdmission})

            this.isReportVisible = true
            this.$refs.reportPatientAlertFilters.buildDocument(this.reportParams)
          }
      },
      
      mounted () {
        this.getPatients();
      },
  })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
