<template>
    <div :class="UseColumnDynamic ? Column : 'col-md-3'">
        <PatientAlertsInfoModal
            v-bind:PatientCode = "PatientCode"
            v-bind:AutoShow = "false"
            v-bind:ShowModal = "showModal"
            v-bind:CloseEvent = "handleCloseEvent"
        >
        </PatientAlertsInfoModal>

        <div class="card activeCard">
            <div class="card-body">
                <div :class="hasAlerts ? 'd-flex align-items-start justify-content-between pointer' : 'd-flex align-items-start justify-content-between'">
                    <h6 v-on:click="showToPatientAlerts" class="card-title flex-grow lower-line text-truncate">
                        {{ $t('PatientAlertsInfoBox.PatientAlerts') }}
                    </h6>
                    <i :class="hasAlerts ? 'mdi mdi-arrow-right-drop-circle text-corporate' : 'mdi mdi-arrow-right-drop-circle text-corporate pointer'" v-on:click="goToPatientAlerts" ></i>
                </div>

                <div :class="NoUseClass ? 'text-muted cutescroll': UseFixedMiddleClass ? 'text-muted p-middle-fixed cutescroll' : 'text-muted p-fixed cutescroll'">
                    <p v-if="Alerts.length === 0">{{ $t('PatientAlertsInfoBox.NoPatientAlerts') }} </p>
                    <ul v-else>							
                        <li v-for="(it, index) in Alerts" :key="`it.patientCode-${index}`">
                            <p :ref="'memoInfo-'+ index" class="text-truncate" v-b-tooltip.hover :title="it.isTruncated ? it.description : ''">{{it.description}}</p>
						</li>					
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActivitiesService from '@/services/activities.service'
import DBFunctions from '@/common/DBFunctions.js'

export default {

    name: 'PatientAlertsInfoBox',
    mixins: [DBFunctions],

    props: {
        PatientCode: String,
        UseColumnDynamic:Boolean,
        NoUseClass:Boolean,
        UseFixedMiddleClass:Boolean
    },

    data() {
        return {
            Alerts:[],
            showModal: false,
            hasAlerts: false
        }
    },

    methods: {
        async getAlerts(){
            var param = {
                PatientCode: this.PatientCode,
                Active: true,
                IsAlert: true,
                ByShowTo: true
            }

            this.Alerts = [];
            ActivitiesService
                    .getAll(param)
                    .then(response => {
                        if (response.status == "200" && response.data) {
                            response.data.forEach(element => {
                                this.Alerts.push({
                                    description: element.memoinfoPlainText ?? element.memoinfo,
                                    isTruncated: true
                                })
                            });
                        }
                    })
                    .catch(error => {
                        this.showResponseErrorMessage(error, this.$t('PatientAlertsInfoBox.PatientAlerts'));
                    });		
        },

        async showToPatientAlerts() {
            if (this.hasAlerts) {
                this.showModal = true;
            }
        },

        goToPatientAlerts() {
            if(this.$route.name.includes('Clinical-')){ 
                this.$router.push({ name: "Clinical-patient-alerts" });
            } else if(this.$route.name.includes('appointment')){ 
                this.$router.push({ name: "appointments-alerts" });
            } else {
                this.$router.push({ name: "patient-alerts" });
            }
        },

        handleCloseEvent() {
            this.showModal = false;
        },

        checkTruncation() {
            
            this.Alerts.forEach((element, index) => {
                const alertRef = this.$refs[`memoInfo-${index}`];
                if (alertRef && alertRef.length > 0) {
                    element.isTruncated = alertRef[0].offsetWidth < alertRef[0].scrollWidth;
                } 
            });
        }
    },

    async mounted() {
        this.hasAlerts = await this.IsPatientWithActiveAlerts(this.PatientCode);
        if (this.hasAlerts) {
            this.getAlerts();
        }

        this.$el.addEventListener('mouseover', this.checkTruncation);
		this.$el.addEventListener('mouseout', this.checkTruncation);

        window.addEventListener('resize', this.checkTruncation);
    },

    beforeDestroy() {
        this.$el.removeEventListener('mouseover', this.checkTruncation);
        this.$el.removeEventListener('mouseout', this.checkTruncation);
    },

    destroyed(){
		window.removeEventListener('resize', this.checkTruncation);
	}
}
</script>