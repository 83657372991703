import ApiService from '@/services/api.service'

const MarsService = {

     getAllListing(parameter){
          const params = {      
            DateTimeFrom : parameter.DateTimeFrom,
            DateTimeTo : parameter.DateTimeTo,
            ProgramList : parameter.ProgramList,
            BuildingList : parameter.BuildingList,
            LevelList : parameter.LevelList,
            RoomList : parameter.RoomList,
            PatientList : parameter.PatientList, 
            SearchCriteria: parameter.SearchCriteria,
            PageSize: parameter.PageSize,          
            PageNumber : parameter.PageNumber,            
          }
          return ApiService.getWithArray('/PatientMars/Patients/',params); 
    },

    getMedListing(parameter){      
        const params = { 
          PatientCode : parameter.PatientCode,
          DateTimeFrom : parameter.DateFrom,
          DateTimeTo : parameter.DateTo,
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,
          Mode:parameter.Mode,
          PageNumber : parameter.PageNumber,            
        }
     // return ApiService.customRequest(requestData);     
        return ApiService.getWithArray('/PatientMars/MedicationsMars/',params); 
  },

    getMarsRooms(parameter){
        const params = {      
            BuildingCodes : parameter.BuildingCodeList,          
        }
        return ApiService.getWithArray('/Rooms/',params); 
  },

  getAssessmentListing(parameter){
    const params = {      
        MarsRecNo : parameter.MarsRecNo,    
        DrugCode : parameter.DrugCode,      
    }
    return ApiService.getWithArray('/PatientMars/Assessments/',params); 
}, 
  update(id,data){
    return ApiService.put(`/PatientMars/${id}`,data)
  },
  add(data){
    return ApiService.post(`/PatientMars`,data)
  },
  repViewPatientMars(parameter) {
      const params = {      
        Month : parameter.Month,
        Year : parameter.Year,          
      }
      return ApiService.getWithArray('/PatientMars/MedicationsMarsReport/',params); 
  }
}

export default MarsService;