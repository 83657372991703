<template>
<div>
  <b-modal :id="ModalId" size="lg" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered :title="TitleWindow" :visible="!NotOpenByDefault ? true : false" :ok-disabled="isEditMode" @ok="this.emitEventOK" @hide="emitEventHide" :cancel-title="$t('Shared.BtnCancel')">
   <div v-if="isListing" id="ContainerPatientContacts">
      <div class="row mb-2">
        <div class="card" style="width:100%;">
            <div class="card-body">
                <MdTitle v-bind:Text="$tc('Contact.PatientContact',2)" />  
                <div class="row">
                    <div class="col-sm-12 col-md-3">                      
                        <MdButton
                          v-bind:Label="$t('ContactsList.AddContact')" 
                          v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
                          v-bind:IconClass="'ti-plus pr-1'"
                          v-on:click="AddContact"
                          v-bind:isReadOnly=this.isReadOnly>
                        </MdButton>
                    </div>
                    <div class="col-sm-12 col-md-9 mb-4">
                        <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                            <div class="input-group">
                                <input 
                                  type="text" 
                                  class="form-control"
                                  v-model="parameter.SearchCriteria"   
                                   @keyup.enter="SearchContacts"                            
                                >
                                <div class="input-group-append">
                                    <div class="input-group-append">
                                    <MdButton 
                                      v-bind:Name="'btnsearchinput'"
                                      v-bind:VariantProp="'btn btn-sm search-attached'"
                                      v-bind:IconClass="'mdi mdi-magnify'"
                                      v-on:click="SearchContacts">
                                    </MdButton>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>              
            </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="card" style="width:100%;">
            <div class="card-body" style="margin-top:-30px !important;">
                <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <div class="row">
                    <div class="col-md-12">
                    
                     <div>

                       <b-col lg="6" class="my-1">
      </b-col>
        <MdTable 
          v-bind:fields="fields"
          v-bind:items="items"
          v-bind:totalRows="totalRows"
          v-bind:isReadOnly="isReadOnly"
          v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
          v-bind:currentPage="currentPage"
          v-bind:perPage="perPage"
          v-bind:pageOptions="pageOptions"
          :editIconEvent="EditContact"
          v-bind:VisibleEditIcon="true"
          :deleteIconEvent="RemoveContact"
          v-bind:VisibleDeleteIcon="true"
          :onRowSelected="onRowSelected"
          :onFiltered="onFiltered"
          :pageChanged="pageChanged"
          :perPageFunction="PerPageFunction">
        </MdTable>
  </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   </div>
   
   <div v-show="isEditMode" id="PatientContacts">
   <b-row class="mt-2">
      <b-col md="12">
        <div class="text-center text-md-right mt-3 container-button">
          <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly=this.isReadOnly
          />
        </div>
      </b-col>
   </b-row>
   <div class="card" style="width:100%;">
     <div class="card-body">
       <MdTitle v-if="!this.isNewContact"
         v-bind:Text="$t('Contact.EditPatientContact')+': ' + this.Contact.firstName +' '+ this.Contact.lastName"
         v-bind:Name="'IdPatient'"
       />
       <MdTitle v-if="this.isNewContact"
         v-bind:Text="$t('Contact.AddPatientContact')"
       />
       <b-row class="mt-3">
        <b-col md="3">
          <MdCheckBox
            v-bind:Label="$t('Contact.PersonResponsible')"
            v-bind:Name="'checkbox-1'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="Contact.primContact"
            v-bind:isReadOnly=this.isReadOnly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
              <MdEdit 
                  v-bind:Label="$t('Shared.FirstName')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'firstName'"
                  v-bind:MaxLengthProp="50"
                  v-bind:isRequired="true"
                  v-model="Contact.firstName"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:isValid="this.isFirstNameValid"
                  v-bind:Id="'input_cfirstname_id'">                                     
              </MdEdit>
         </b-col>
         <b-col md="3">
              <MdEdit 
                  v-bind:Label="$t('Shared.LastName')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'lastName'"
                  v-bind:MaxLengthProp="50"
                  v-bind:isRequired="true"
                  v-model="Contact.lastName"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:isValid="this.isLastNameValid"
                  v-bind:Id="'input_clastname_id'">                                     
              </MdEdit>
         </b-col>
         <b-col lg="3" md="3" sm="12">
            <b-input-group class="mb-3">
              <MdEdit 
                 v-bind:Label="$t('Shared.Dob')"
                 v-bind:TypeProp="'date'" 
                 v-bind:Name="'dob'"
                 v-bind:Autocomplete="true"
                 v-bind:Min="'1900-01-01'"
                 v-bind:Max="'2200-12-31'"
                 v-model="Contact.dob"
                 v-bind:isReadOnly=this.isReadOnly>                                     
              </MdEdit>
            </b-input-group>    
         </b-col>
         <b-col lg="3" md="3" sm="12">
             <MdSelect
              v-bind:Label="$t('Contact.ContactType')"
              v-bind:Options="ContactTypeList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="Contact.contactType"                       
              v-bind:Multiple="false"
              :IsDisabled=this.isReadOnly
              :Input="ChangeDropDownType"
              />
         </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
            <MdEdit 
                  v-bind:Label="$t('Shared.Relation')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'relation'"
                  v-bind:MaxLengthProp="50"
                  v-model="Contact.relation"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Id="'input_relation_id'">                                     
            </MdEdit>
        </b-col>
        <b-col md="3">
              <MdEdit 
                  v-bind:Label="$t('Contact.SSN')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'ssn'"
                  v-bind:MaxLengthProp="11"
                  v-model="Contact.ssn"
                  v-bind:isReadOnly=this.isReadOnly
                  v-bind:Mask="'###-##-####'"
                  v-bind:Id="'input_ssn_id'">                                     
            </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">             
             <MdRadioGroup
                v-bind:Label="$t('Patient.Sex')"
                v-model="Contact.sex"
                v-bind:Options="options"
                v-bind:Name="'radio-inline'"
                v-bind:isReadOnly=this.isReadOnly
             />
       </b-col>  
      </b-row>
      <MdTitle v-bind:Text="$t('Contact.ContactInformation')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.CellPhone')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'cellPhone'"
                 v-bind:MaxLengthProp="20"
                 v-model="Contact.cellPhone"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_cell_id'">                                     
              </MdEdit>
         </b-col>
         <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.DayPhone')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'dayPhone'"
                 v-bind:MaxLengthProp="20"
                 v-model="Contact.dayPhone"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_dayphone_id'">                                     
              </MdEdit>
         </b-col>
         <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.NightPhone')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'nightPhone'"
                 v-bind:MaxLengthProp="20"
                 v-model="Contact.nightPhone"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_nightphone_id'">                                     
              </MdEdit>
         </b-col>
         <b-col md="3">
               <MdEdit 
                 v-bind:Label="$t('Shared.Email')"
                 v-bind:TypeProp="'email'" 
                 v-bind:Name="'email'"
                 v-bind:MaxLengthProp="100"
                 v-model="Contact.email"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:isRequired="true"
                 v-bind:isValid="this.emailValid"
                v-bind:Id="'input_mail_id'">                                     
              </MdEdit>
         </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Contact.Address')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row class="mt-3">
        <b-col md="3">
          <MdCheckBox
            v-bind:Label="$t('Contact.UsePatientAddress')"
            v-bind:Name="'checkbox-adress'"
            v-model="checkedUsePatientAddress"
            v-bind:isReadOnly=this.isReadOnly
            v-on:change="UsePatientAddress"
          />
        </b-col>
          </b-row>
          <b-row>
             <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.Address1')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'address1'"
                 v-bind:MaxLengthProp="30"
                 v-model="Contact.address1"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_addr1_id'">                                     
              </MdEdit>
              </b-col>
             <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.Address2')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'address2'"
                 v-bind:MaxLengthProp="30"
                 v-model="Contact.address2"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_addr2_id'">                                     
              </MdEdit>
              </b-col>
              <b-col md="3">
              <MdEdit 
                v-bind:Label="$t('Shared.Zip')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'zip'"
                v-bind:MaxLengthProp="10"
                v-model="Contact.zip"
                v-bind:isReadOnly=this.isReadOnly
                v-on:blur="contactZipBlur(Contact.zip)"
                v-on:change="contactZipChange($event)"
                v-bind:Id="'input_zip_id'">                                     
              </MdEdit>
              </b-col>
              <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.City')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'city'"
                 v-bind:MaxLengthProp="25"
                 v-model="Contact.city"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_city_id'">                                     
              </MdEdit>
              </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.State')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'state'"
                 v-bind:MaxLengthProp="20"
                 v-model="Contact.state"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_state_id'">                                     
              </MdEdit>
              </b-col>
             <b-col md="3">
              <MdEdit 
                 v-bind:Label="$t('Shared.Country')"
                 v-bind:TypeProp="'text'" 
                 v-bind:Name="'country'"
                 v-bind:MaxLengthProp="25"
                 v-model="Contact.country"
                 v-bind:isReadOnly=this.isReadOnly
                 v-bind:Id="'input_country_id'">                                     
              </MdEdit>
              </b-col>
          </b-row>
          <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
            <b-col md="12" lg="12">
               <MdMemo
                 v-bind:Name="'textarea_notes'"
                 v-bind:Rows="'3'"
                 v-bind:MaxRows="'6'"
                 v-bind:MaxLength="'500'"
                 v-model="Contact.note"
                 v-bind:isReadOnly=this.isReadOnly
               />                                     
            </b-col>
          </b-row>
          <MdTitle v-bind:Text="$t('Contact.AditionalInformation')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
                                <b-col lg="3" md="3" sm="12">
                                   <MdSelect
                                       v-bind:Label="$t('Contact.ActiveReleaseOnFile')"
                                       v-bind:Options="ActiveReleaseOnFile"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.releaseOnFile"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownFile"
                                       />
                                </b-col>
                                  <b-col lg="3" md="3" sm="12">
                                  <MdSelect
                                       v-bind:Label="$t('Contact.MayHavePhoneCalls')"
                                       v-bind:Options="YesNoOptionsNumericThree"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.mayHavePhoneCalls"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownPhoneCalls"
                                       />
                                </b-col>
                                  <b-col lg="3" md="3" sm="12">
                                    <MdSelect
                                       v-bind:Label="$t('Contact.SupervisedPhoneCalls')"
                                       v-bind:Options="YesNoOptionsNumericThree"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.supervisedPhoneCalls"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownSupervisedCalls"
                                       />
                                </b-col>
                                  <b-col lg="3" md="3" sm="12">
                                    <MdSelect
                                       v-bind:Label="$t('Contact.VisitationPermission')"
                                       v-bind:Options="YesNoOptionsNumericTwo"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.visitationPerm"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownVisitationPerm"
                                       />
                                </b-col>
                                </b-row>
                                <b-row>
                                <b-col lg="3" md="3" sm="12">
                                  <MdSelect
                                       v-bind:Label="$t('Contact.MayVisit')"
                                       v-bind:Options="YesNoOptionsNumericThree"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.mayVisit"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownMayVisit"
                                       />
                                </b-col>
                                  <b-col lg="3" md="3" sm="12">
                                    <MdSelect
                                       v-bind:Label="$t('Contact.UnsupervisedVisitsOnGrounds')"
                                       v-bind:Options="YesNoOptionsNumericThree"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.unsupVisitsOnGrounds"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownVisitsOnGrounds"
                                       />
                                </b-col>
                                  <b-col lg="3" md="3" sm="12">
                                    <MdSelect
                                       v-bind:Label="$t('Contact.UnsupervisedVisitsOffGrounds')"
                                       v-bind:Options="YesNoOptionsNumericThree"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.unsupVisitsOffGrounds"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownVisitsOffGrounds"
                                       />
                                </b-col>
                                  <b-col lg="3" md="3" sm="12">
                                    <MdSelect
                                       v-bind:Label="$t('Contact.NeedProbationForOffGrounds')"
                                       v-bind:Options="YesNoOptionsNumericThree"
                                       v-bind:FieldDescription="'text'"
                                       v-bind:FieldCode="'value'"
                                       v-bind:Model="Contact.needProbApprForOffGrounds"                       
                                       v-bind:Multiple="false"
                                       :IsDisabled=this.isReadOnly
                                       :Input="ChangeDropDownApprForOffGrounds"
                                       />
                                </b-col>
                            </b-row>
          </div>
      </div>
   </div>  
  </b-modal>
 </div>
</template>

<script>
import ContactService from '@/services/contact.service'
import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import GlobalService from '@/services/global.service' 
import PatientService from '@/services/patient.service' 
import Utils from "@/common/utils";
import ExternalAPI from '@/services/external.service'
import { EventBus } from '@/event-bus.js';
export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUsePatientContact;
            if(iUA) next()
            else next('/accessdenied');
        }) 
  },

  name: 'Contacts', 
  mixins: [ShowMessage, DBFunctions],
  props: {
     ModalId: String,
     PatientCode:String,
     TitleWindow: String,
     NotOpenByDefault:Boolean,
     ForUsefromMars:Boolean,
     MarsParam:Object
   },
  data() {
    return {
      ShowMessageIsEmpty:false,
      Loading:true,
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },  
        ContactId:'',   
        Mode:'',         
        parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'contactname', sortable: true, label: this.$t('ContactsList.ContactName'), class: 'text-rigth' },       
        { key: 'cellPhone', sortable: true, label: this.$t('Shared.CellPhone'), class: 'text-rigth' },      
        { key: 'dayPhone', sortable: true, label:this.$t('Shared.DayPhone'), class: 'text-rigth' },       
        { key: 'email', sortable: true, label: this.$t('Shared.Email'), class: 'text-rigth' },
        { key: 'recordId', sortable: true, label: 'RecorId', class: 'hidden' },
        { key: 'relation', sortable: true, label: this.$t('Shared.Relation'), class: 'text-rigth' },  
        
        ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      destination:[],
      //var use in Contacts Add/Edit
      Contact:{
            firstName: '',
            lastName: '',
            primContact: false,
            dob: null,
            contactType: '',
            relation: '',
            ssn: '',
            sex: '',
            cellPhone: '',
            dayPhone: '',
            nightPhone: '',
            email: '',
            address1: '',
            address2: '',
            zip: '',
            city: '',
            state: '',
            country: '',
            note: '',
            releaseOnFile: '',
            mayHavePhoneCalls: '',
            supervisedPhoneCalls: '',
            visitationPerm: '',
            mayVisit: '',
            unsupVisitsOnGrounds: '',
            unsupVisitsOffGrounds: '',
            needProbApprForOffGrounds: ''
        },
        patient:{},        
        ContactTypeList:[],
        ActiveReleaseOnFile: EnumService.YesNoOptions,
        YesNoOptionsNumericThree: EnumService.YesNoOptionsNumericThree,
        YesNoOptionsNumericTwo: EnumService.YesNoOptionsNumericTwo,
        checkedUsePatientAddress: false,
        contactZipChanged:false,
        shippingData:[],
        options: [
          { text: this.$t('Shared.Male'), value: 'M' },
          { text: this.$t('Shared.Female'), value: 'F' }         
      ],
      formatted: '',
      isFirstNameValid: true,
      isLastNameValid: true,
      counterModelTypeList:0,
      isListing: true,
      isEditMode:false,
      isNewContact:true,
      //eslint-disable-next-line
      reg: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, 
      pendingChanges: false,
      clickOK: false,
      emailValid:false
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      },
  },
    
  methods: {
     isEmailValid: function() {
      return (this.Contact.email == "")? "" : (this.reg.test(this.Contact.email)) ? true : false;
    },
    emitEventOK(){
        this.clickOK = true       
    },

      emitEventHide(handled){
        if (this.pendingChanges) {
        handled.preventDefault()
        this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Contact.PatientContact',2),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              this.$emit('getCancel');
              this.ResetProps();
            }
          })		
        } else {
            if(this.clickOK) {
              if(this.ForUsefromMars){
                  let param = {
                     info:this.getTextResult(),
                     MarsParam:this.MarsParam 
                  }
                  this.$emit('getCodeConditionOK', param); 
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';  
                }else{
                  this.$emit('getCodeConditionOK', this.getTextResult());
                  this.$emit('input',this.getTextResult());
                  this.parameter.SearchCriteria = '';
                }                
            } else {
                this.$emit('getCancel');
                this.ResetProps();
            }
        }
      },
      
     showListing(show) {
        this.isEditMode = show ? false : true;
        this.isListing = show ? true : false;
      },

      ResetProps(){
        this.parameter.SearchCriteria = '';
        this.showListing(true);
      },

      ResetContact(){
        this.Contact.firstName = '';
        this.Contact.lastName = '';
        this.Contact.primContact = false;
        this.Contact.dob = null;
        this.Contact.contactType = '';
        this.Contact.relation = '';
        this.Contact.ssn = '';
        this.Contact.sex = '';
        this.Contact.cellPhone = '';
        this.Contact.dayPhone = '';
        this.Contact.nightPhone = '';
        this.Contact.email = '';
        this.Contact.address1 = '';
        this.Contact.address2 = '';
        this.Contact.zip = '';
        this.Contact.city = '';
        this.Contact.state = '';
        this.Contact.country = '';
        this.Contact.note = '';
        this.Contact.releaseOnFile = '';
        this.Contact.mayHavePhoneCalls = '';
        this.Contact.supervisedPhoneCalls = '';
        this.Contact.visitationPerm = '';
        this.Contact.mayVisit = '';
        this.Contact.unsupVisitsOnGrounds = '';
        this.Contact.unsupVisitsOffGrounds = '';
        this.Contact.needProbApprForOffGrounds = '';
      },

      getTextResult() {
        let textResult = '';
        let lastName = '';
        let firstName = '';
        let cellPhone = '';
        let dayPhone = '';
        let email = '';
        let contactType = '';
        this.items.map(item => (
            lastName = item.lastName ? item.lastName: '',
            firstName = item.firstName ? item.firstName: '',
            cellPhone = item.cellPhone ? item.cellPhone: '',
            dayPhone = item.dayPhone ? item.dayPhone: '',
            email = item.email ? item.email: '',
            contactType = item.contactType ? item.contactType: '',
            textResult = textResult + lastName+' '+firstName+' '+cellPhone+' '+dayPhone+' '+email+' '+contactType +"\n"
        ))
        return textResult
      },

    SearchContacts(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getContacts();
    },

    funcDestination(value) {
     this.destination=value;
   },

    OnUpdateContactList() {
      this.getContacts();
    },

    getContacts() { 
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.PatientCode;
      ContactService.getAll(this.parameter)
      .then((response) => {
        if(response.status == '200' && response.data && response.data.length > 0){
         this.items = response.data;
         let headerString = response.headers["x-pagination"];
         this.Header = JSON.parse(headerString);
         this.totalRows = this.Header.TotalCount;
         this.items.forEach(item => {
           item.contactname = (item.firstName?item.firstName:'') + " " + (item.lastName?item.lastName:'');     
         });
        }
        if(this.items.length === 0){
           this.ShowMessageIsEmpty = true;
         }
        this.Loading = false;
        this.$emit('load', this.Loading);
      })
      .catch(error => {
        this.Loading = false;
        this.$emit('load', this.Loading);
        if (!error.response) {
            // network error
            this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.RetrieveInformation'),"error");
        } else {
            this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
        }
      });
    },

    EditContact(row){          
       this.isNewContact = false;
       this.mountContactsEdit(row.recordId); 
       this.showListing(false) 
    },

    RemoveContact(row){
      const RecordId = row.recordId
      this.$bvModal.msgBoxConfirm('This contact will be deleted permanently', {
        title: this.$t('ContactsList.DeleteContact'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value){
            ContactService.deleteContact(RecordId)
            .then(() => {
              const index = this.items.findIndex(item => item.recordId === RecordId) // find the post index 
              if (~index) {// if the post exists in array 
                this.items.splice(index, 1) //delete the post and refresh view
                this.getContacts(); //refresh listing
                this.showListing(true)
              }
            })
            .catch((error) => {
              if (!error.response) {
                // network error
                this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.NotAbleToDelete'),"error");
              } else {
                this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
              }
            });
        }
      })
    },

    AddContact(){
       this.isNewContact = true;
       this.mountContactsEdit();
       this.showListing(false)
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getContacts();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.getContacts();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    //Use in Contacts Add/Edit
    ChangeDropDownType(value){
         this.Contact.contactType = value ? value.code : '';
      },

      ChangeDropDownFile(value){
         this.Contact.releaseOnFile = value ? value.value : '';
      },

      ChangeDropDownPhoneCalls(value){
         this.Contact.mayHavePhoneCalls = value ? value.value : '';
      },

      ChangeDropDownSupervisedCalls(value){
         this.Contact.supervisedPhoneCalls = value ? value.value : '';
      },

      ChangeDropDownVisitationPerm(value){
         this.Contact.visitationPerm = value ? value.value : '';
      },

      ChangeDropDownMayVisit(value){
         this.Contact.mayVisit = value ? value.value : '';
      },

      ChangeDropDownVisitsOnGrounds(value){
         this.Contact.unsupVisitsOnGrounds = value ? value.value : '';
      },

      ChangeDropDownVisitsOffGrounds(value){
         this.Contact.unsupVisitsOffGrounds = value ? value.value : '';
      },

      ChangeDropDownApprForOffGrounds (value){
         this.Contact.needProbApprForOffGrounds = value ? value.value : '';
      },

      UsePatientAddress(){
          this.checkedUsePatientAddress = !this.checkedUsePatientAddress;
          if(this.checkedUsePatientAddress){
            this.Contact.address1 = this.patient.address1;
            this.Contact.address2 = this.patient.address2;
            this.Contact.zip =  this.patient.zip;
            this.Contact.city = this.patient.city;        
            this.Contact.state = this.patient.state;
            this.Contact.country = this.patient.country;
          }else{
             this.Contact.address1 = "";
             this.Contact.address2 = "";
             this.Contact.zip = "";
             this.Contact.city = "";
             this.Contact.state = "";
             this.Contact.country = "";
          }
       },

       resetModal() {
         this.ResetContact();
      },   
      
      formatterDate(value){
         return Utils.formatterDate(value);
      },

     getPatient() { 
            PatientService.get(this.PatientCode)
            .then((response) => {
                if(response.status == '200' && response.data){ 
                 this.patient = response.data;                   
                }           
            })
            .catch((error) => {
                if (error.response) {
                  this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
                }
            }); 
      },

      getContactById(){
         if (this.ContactId) {
         ContactService.get( this.ContactId )
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.Contact = response.data;
                 this.Contact.dob = this.formatterDate(this.Contact.dob); 
               }
            })
            .catch((error) => {
               if (!error.response) {
                  // network error
                  this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.RetrieveInformation'),"error");
               } else {
                  this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
               }
               });
         }
      },

      CreateContact(){
         this.Contact.patientCode = this.PatientCode; 
         ContactService.addContact(this.Contact)
         .then(() => {
             this.showMessage("Adding contact", "Contact added successfully.", "success");
             this.getContacts();
             this.showListing(true)
            })
         .catch((error) => {
            if (!error.response) {
               this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.NotAbleToInsert'),"error");
            } else {
               this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
            }
        });
         this.checkedUsePatientAddress = false;
      },

      UpdateContact(){
         this.Contact.patientCode = this.PatientCode; 
         ContactService.update(this.ContactId, this.Contact)
        .then(() => {
              this.showMessage("Updating Contact", "Contact saved successfully.", "success");
              this.getContacts();
              this.showListing(true)
            })
        .catch((error) => {
           if (!error.response) {
               this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.NotAbleToUpdate'),"error");
           } else {
               this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
           }
        });
      },

     getContactType(){
       GlobalService.getContactType()
            .then((response) => {
               if(response.status == '200' && response.data){
                  this.ContactTypeList = response.data;
               }
                this.Loading = false;
                this.$emit('load', this.Loading);                
            })
            .catch((error) => {
               if (error.response) {
                 this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
               }
            });
    },

      SaveOrEdit(){
        if(!this.isReadOnly) {
          if(this.validate_entries()){
            if(this.validate_required()) {
                this.pendingChanges = false         
                if (!this.isNewContact) { 
                  this.UpdateContact()           
                }else{            
                  this.CreateContact()
                }
            } else {
                this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.CheckRedLabeledFields'),"errorAutoHide");
            }
          } else {
            this.showMessage(this.$tc('Contact.PatientContact',2),this.$t('Msg.InvalidFieldValuesDetected'),"errorAutoHide");
          }
        }
      },

      contactZipBlur: function (value) {
             if (this.contactZipChanged) {
                 ExternalAPI.getCityStateByZipCode(value)
                 .then((response) => {
                    this.shippingData = response;
                    if (this.shippingData.City) {
                        this.Contact.city = this.shippingData.City
                        this.Contact.state= this.shippingData.State
                    }
                    this.contactZipChanged = false;
                })
                 .catch((error) => {
                   this.showMessage(this.$tc('Contact.PatientContact',2),error,"error");
                 });
             }
        },

        contactZipChange: function() {
           this.contactZipChanged = true;
        },

        validate_required() {
         this.isFirstNameValid = this.Contact.firstName.length > 0 ? true : false;
         this.isLastNameValid = this.Contact.lastName.length > 0 ? true : false;
         return this.isFirstNameValid && this.isLastNameValid
      },
      validate_entries() {
         this.emailValid = (this.Contact.email == "" || this.Contact.email == null)? true : (this.reg.test(this.Contact.email)) ? true : false;       
         if( this.emailValid) {
               return true;
            } else {
               return false;   
            }
      },
      mountContactsEdit(recId) {
        this.$emit('load', this.Loading);
        this.getContactType();
        this.ContactId = recId;
        this.getPatient();
        this.getContactById();
        this.isReadOnly = this.$store.state.isPatientContactsEHRReadOnly;
      },

       beforeWindowUnload(e) {
       if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
  },

  mounted() {
    this.$emit('load', this.Loading);    
    this.getContacts();  
    this.isReadOnly = this.$store.state.isPatientContactsEHRReadOnly;  
  },

  created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
};

</script>