<template>
    <MdEditAllergySetup
        v-bind:EditMode="this.$attrs.editMode"
        v-bind:IsReadOnly="isReadOnly"
        v-bind:LabelCancel="$t('AllergySetup.AllergyList')"
        v-model="Allergy"
        v-bind:CancelEventName="'onCancelAllergySetup'"
        v-bind:SaveEventName="'onSaveAllergySetup'"
        ref="editAllergySetup"
    >

    </MdEditAllergySetup>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js';
import { EventBus } from '@/event-bus.js';
import AllergySetupService from '@/services/allergiesetup.service'

export default {
    mixins: [DBFunctions],
    name: 'AllergySetup',

    data() {
        return {
            pendingChanges:false,
            Allergy:{
                code: '',
                category: '',
                drugCode: '',
                drugName: '',
                problemId: '',
                problemDescription: '',
                description: '',
            },
            isReadOnly:false,
            permission:[],
            Menu : this.$store.state.leftMenu,
            OptionsRightMenu:{
                visible:false,
                ItemsList:[],
                btnvisible:false,
                itemdisabled: false,
                PatientCode:'',
                Mode:this.edit_mode,
                favButtonAtion:false          
            },
            loading: this.$attrs.editMode
        }
    },

    methods: {
        CanView(){
            return this.permission[0].roleCanView;
        },

        getAllergy(){
            if(this.$attrs.editMode) {
                AllergySetupService
                    .get(this.$route.params.id)
                    .then((response) => {
                        if(response.status == '200' && response.data){
                            this.Allergy = response.data;
                        }   
                        this.loading = false;
                        this.$emit('load', this.loading);                     
                    })
                    .catch((error) => {     
                        this.loading = false;   
                        this.$emit('load', this.loading);            
                        if (!error.response) {
                            this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.retrieveInfo'),"error");
                        }                      
                        else if(error.response.status === 400 ){
                            this.showMessage(this.$t('Allergy.Allergy'),error.response.data.errorMessage,"error");
                        }
                        else if(error.response.status === 405 ){             
                            this.$router.push({name: "Main"});    
                            setTimeout(() => 
                                this.showPermissionMessage()
                            , 50);            
                        } 
                        else if(error.response.status === 500){
                            this.showMessage(this.$t('Allergy.Allergy'),this.$t('Msg.ThereWereSomeError'),"error");
                        } else{
                            this.showMessage(this.$t('Allergy.Allergy'),error.response.data.errorMessage,"error");
                        }        
                    }); 
            }
        },

        beforeWindowUnload(e) {
            if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
    },

    created(){
        EventBus.$on("onChanged", function (e) {      
            this.pendingChanges = e.Changed;
        }.bind(this));
        EventBus.$on("onSaveAllergySetup", function () {
            this.pendingChanges = false;
            this.$router.push({name: "allergyList"}).catch(()=>{});
        }.bind(this));
        EventBus.$on("onCancelAllergySetup", function () {
            this.checkExistFavorite('/app/allergylist');
            this.$router.push({name: "allergyList"});
        }.bind(this));

        this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    mounted(){
        this.$emit('load', this.loading);
        this.OptionsRightMenu.ItemsList = [];
        let layoutStatus = this.$store.state.layoutStatus;
        switch(layoutStatus) {
            case 'L0R1':
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
                break;
            case 'L1R0':
            case 'L1R1':
                this.OptionsRightMenu.btnvisible = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
                break;
            default:
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
        }

        this.getAllergy();
    },

    beforeDestroy () {
        EventBus.$off("onChanged");
        EventBus.$off("onSaveAllergySetup");
        EventBus.$off("onCancelAllergySetup");
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },

    destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    /*beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.$store.state.isUsePatientAllergies;
            if(iPI) next()
            else next('/accessdenied');
        }) 
    },*/

    beforeRouteLeave(to, from, next) {
        if (!this.isReadOnly && this.pendingChanges) {
            this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
                title: this.$t('AllergySetupList.AllergySetupTitle'),
                okTitle: this.$t('Shared.BtnSave'),
                cancelTitle: this.$t('Shared.BtnDismiss'),
                footerClass: 'p-2',
                titleClass: 'modal-title modal-type-warning'
            })
            .then(value => {
                if(!value){
                    next();
                } else {
                    this.$refs.editAllergySetup.SaveOrEdit();
                    next();
                }
            })		
        } else {
            next()
        }
    },
}
</script>