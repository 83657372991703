<template>
   <fragment>
     <div :id="targetId" oncontextmenu="return false;">
      <div v-if="!isReadOnly && cmpLoaded">
        <ejs-contextmenu :id="targetId" :target="menuTargetId" :items='menuItems' :select='onSelect'></ejs-contextmenu>
      </div>  
      <ckeditor
          v-bind:value="value"
          :config="this.editorConfig"
          v-bind:read-only="this.readOnly"
          :id="this.id"
          @input="onEditorInput"
          @blur="onEditorBlur"
          :editor-url="editorUrl"
          @ready="onEditorReady"
          v-bind:style="style">
      </ckeditor>
     </div>
   </fragment>
</template>

<script>
  import { EventBus } from '@/event-bus.js';
  import GlobalService from '@/services/global.service'
  import Vue from 'vue';
  import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

  Vue.use(ContextMenuPlugin);
  export default {
    name: 'MdRichEdit',
   props: {     
     isReadOnly:Boolean,
     value:[String, Number],
     Name:String,
     Tag: String,
     Height: [String, Number],
     id: [String, Number],
     HideToolBar: Boolean,
     StartupFocus: {
      default: true,
      type: Boolean
     },
     isRequired: Boolean,
     isValid:Boolean,
   },
    watch: {
      isValid: function (newValue) {
        this.setValid(newValue)
      },

      isReadOnly: function (newValue) {
        if (this.editor && this.readOnly != newValue) {
            this.readOnly = newValue;
        }
      }
    },
    data() {
      return {
        readOnly: this.isReadOnly,
        editorUrl: '/ckeditor/ckeditor.js',
        editor: null,
        editorConfig: {
          hideTB: this.HideToolBar,
          on: {
                instanceReady : function ( evt )
                {
                  //Hide toolbar
                  if (evt.editor.config.hideTB) {
                    let edId = evt.editor.id.split('_')[1]
                    document.getElementById( `cke_${edId}_top` ).style.display = 'none';
                  }
                },
                
                paste: async function(evt) {
                  let rtfData = evt.data.dataTransfer.getData( 'text/rtf', true);
                  const filesCount = evt.data.dataTransfer.getFilesCount()
                  const textData = evt.data.dataTransfer.getData( 'Text', true);
                  const htmlData = evt.data.dataTransfer.getData( 'text/html', true);
                  let rtfContent= { rtf:[] }
                  if(!textData && !htmlData && filesCount > 0){
                    let imagen = evt.data.dataTransfer.getFile(0);
                    let reader = new FileReader();
                    reader.onload = function(e) {
                      let element = evt.editor.document.createElement('img', {
                        attributes: {
                          src: e.target.result
                        }
                      });
                      evt.editor.insertElement(element);             
                    };
                    reader.readAsDataURL(imagen);
                  }

                  if(filesCount == 0 && rtfData && !htmlData){
                    evt.cancel();
                    rtfData = rtfData.replaceAll(/\\\*\\fldinst HYPERLINK .*"/g,'');
                    rtfData = rtfData.replaceAll('>', '');
                    rtfData = rtfData.replaceAll('<', '');
                    rtfContent.rtf[0] = rtfData
                    await GlobalService.RtfToHtml(rtfContent)
                      .then(( response) => {
                        if(response.status == '200'){
                            this.htmlContent = response.data.html[0];
                            evt.editor.insertHtml(this.htmlContent);
                        }
                      })
                      .catch((error) => {
                        if(error.response) {
                          evt.cancel();
                        }
                      });
                  }
                }
              },
          title: false,
          toolbar: [ 
            {
              name: 'basicstyles',
              items: ['Bold','Italic','Underline','Strike','-','NumberedList','BulletedList']
            },
            {
              name: 'color',
              items: ['TextColor','BGColor']
            },
            {
              name: 'insert',
              items: ['Table','Link']
            },
           ],
          startupFocus: this.StartupFocus,
          height: this.Height ? this.Height : 'auto',
          extraPlugins: 'tableresize,divarea',
          removePlugins: 'save,smiley,forms,flash,templates,print,preview,iframe,find,exportpdf,\
                          specialchar,pagebreak,wsc,scayt,liststyle,div,a11yhelp,\
                          about,language,indentblock,font,bidi,removeformat,sourcearea,\
                          maximize,htmlwriter,horizontalrule,format,resize,showblocks,\
                          stylescombo,blockquote,copyformatting,selectall,newpage,justify,elementspath',
          removeButtons: 'Subscript,Superscript,Cut,Copy,Outdent,Indent,PasteText,PasteFromWord',
          readOnly: this.isReadOnly,
          extraAllowedContent : 'img(*){*}[*]'
        },
        isLoadingCkeditor: true,
        Invalid: false,
        style: {
          border: 'solid 1px #e0e0e0 !important',
          borderColor: '#e0e0e0 !important',
        },
        cmpLoaded: false,
        targetId: "target-"+this.id,
        menuTargetId:"#target-"+this.id,
        menuItems:[],
      }
    },

    methods:{
        getPlaintText() {
            return this.editor.editable().getText();
        },

        insertText(text) {
            this.editor.editable().insertText(text);
            this.editor.focus();

            this.$emit('input', this.editor.editable().getHtml());
            this.setChangedStatus();
        },

        appendText(text) {
            this.editor.editable().appendText(text);
            this.editor.focus();
            this.moveToLastPosition();

            this.$emit('input', this.editor.editable().getHtml());
            this.setChangedStatus();
        },

        insertHtml(text) {
            this.editor.editable().insertHtml(text);
            this.editor.focus();

            this.$emit('input', this.editor.editable().getHtml());
            this.setChangedStatus();
        },

        appendHtml(text) {
            this.editor.editable().appendHtml(text);
            this.editor.focus();
            this.moveToLastPosition();

            this.$emit('input', this.editor.editable().getHtml());
            this.setChangedStatus();
        },

        moveToLastPosition() {
            const range = this.editor.createRange();
            range.moveToElementEditablePosition(range.root, true);
            this.editor.getSelection().selectRanges([range]);
        },

      setChangedStatus(){
        var changed = {
          "Changed":true,
        }
        EventBus.$emit('onChanged', changed);
      },

      onEditorReady(editor){
        this.editor = editor;
        if (editor.contextMenu) {
          editor.removeMenuItem('cut');
          editor.removeMenuItem('copy');
          editor.removeMenuItem('paste');
        }
        this.isLoadingCkeditor = false
        if (this.editorConfig.readOnly != this.isReadOnly) {
            this.readOnly = this.isReadOnly;
        }
        
        this.$emit('ready',editor);
      },

      onEditorInput(value){
        this.$emit('input',value);
        if (!this.isLoadingCkeditor && !value.includes('rtf1')) {
          this.setChangedStatus()
        }
        
        const plaintText = this.editor ? this.getPlaintText() : value;
        this.Invalid = plaintText.trim().length == 0 && this.isRequired ? true : false;
        this.style.borderColor = this.Invalid ? '#dc3545 !important': '#e0e0e0 !important'
      },

      onEditorBlur(){
        this.$emit('blur',this.value)
        const plaintText = this.editor ? this.getPlaintText() : this.value;
        this.Invalid = plaintText.trim().length == 0 && this.isRequired ? true : false;
        this.style.borderColor = this.Invalid ? '#dc3545 !important': '#e0e0e0 !important'
      },

      setValid(state) {
        this.isValid = state;
        this.style.borderColor = state ? '#e0e0e0 !important' : '#dc3545 !important'
      },

    onSelect: function(args ) {
      if(args.item.id === 'recording') {
        EventBus.$emit('speechBarLabel', 'stop recording');
        this.$root.$refs.MdTextSpeech.toggleSpeechBar()
        this.$root.$refs.MdTextSpeech.startSpeechToText(this.Name);
      }
      if(args.item.id === 'speech') {
        EventBus.$emit('speechBarLabel', 'stop speech');
        this.$root.$refs.MdTextSpeech.toggleSpeechBar()
        this.$root.$refs.MdTextSpeech.startSpeech(this.value);
      }
    },
    loadMenuItems() {
      if(this.$root.$refs.MdTextSpeech.isSpeechRecognitionSupported) {
        this.menuItems.push({text: this.$t('MdTextSpeech.record'), id: "recording"})
      }
      this.menuItems.push({text: this.$t('MdTextSpeech.speech'), id: "speech"})
    },
    setValueS2T( sp ){
      let sp2t = ''
      if(this.value) {
        sp2t += Array.from (this.value +""+ sp).join("");
      } else {
        sp2t = Array.from (sp).join("");
      }
      this.Value = sp2t
      this.$emit('input', sp2t); 
      this.setChangedStatus( sp2t )
    }
    },
    mounted(){
      this.cmpLoaded = 'MdTextSpeech' in Vue.options.components
      if (this.cmpLoaded) this.loadMenuItems()
    },
    created() {
        EventBus.$on("receivedS2T", function (event) {
          if( event.ControlId == this.Name) { 
              this.setValueS2T( event.finalSentence )
          }
        }.bind(this))
    }
  }
</script>
<style scoped>
.scroll{
	overflow: hidden !important;
}
.cke_inner .cke_top {
  display: block;
}
.cke_inner, .cke_wysiwyg_frame, .cke_wysiwyg_div{
  background: transparent !important;
}
.cke_chrome {
  border: 0 !important;
}
</style>