<template>
  <div >
    <div v-if="this.showModal">
      <MdTitle v-bind:Text="$t('SpecialPrecautions.SpecialPrecautionsReport')" />
      <b-row>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$tc('Shared.Patient',2)"
            v-bind:Options="PatientsList"
            v-bind:Model="PatientCodeModel"
            v-bind:FieldDescription="'patientName'"
            v-bind:FieldCode="'patientcode'"
            v-bind:Multiple="true"             
            :Input="ChangeDropDownPatients"
            :CustomLabel="customLabelPatients"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
              v-bind:Label="$tc('Allergy.PatientStatus')"
              v-bind:Options="showOptions"
              v-bind:Model="showPatientIsActiveModel"
              v-bind:FieldDescription="'text'"
              v-bind:FieldCode="'value'" 
              v-bind:Multiple="false"                      
              :Input="ChangeIsActive"
              v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$tc('Allergy.AdmissionStatus')"
            v-bind:Options="showOptions"
            v-bind:Model="showIsActiveAdmissionModel"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'" 
            v-bind:Multiple="false"                      
            :Input="ChangeIsActiveAdmission"
            v-bind:AllowEmpty="false"/>
        </b-col> 
      </b-row>
      <div class="page-actions">
        <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="reportList"
          v-bind:Name="'btn_ReportList'"/>
        <MdButton
          v-bind:Label="$t('Shared.Report')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="onClickShowReport"
          v-bind:Name="'btn_Print'"/>   
      </div>
    </div>
    <DxPopup
      :visible="isReportVisible"
      :drag-enabled="true"
      :close-on-outside-click="false"
      :show-close-button="true"
      :show-title="true"
      :hide-on-parent-scroll="true"
      :title="$t('SpecialPrecautions.SpecialPrecautionsReport')"
      @hidden="onHiddenReport"
      :resize-enabled="true"
      shading-color="rgba(0, 0, 0, 0.5)"
      height="94vh"
      width="97vw"
      @showing="onShowing"
    >
      <ReportViewer
        ref="specialPrecautions"
        :ReportName="'SpecialPrecautions'"
        :ReportTitle="$t('SpecialPrecautions.SpecialPrecautionsReport')"
        :IsAnAPIReport="true"
      />
    </DxPopup>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { DxPopup } from 'devextreme-vue/popup';
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import PatientService from '@/services/patient.service';
  import DBFunctions from '@/common/DBFunctions.js'

  export default Vue.extend({
      name: "SpecialPrecautionsRep",
      mixins: [ ShowMessage, DBFunctions ],
      components: { DxPopup },
      props: {
        closeWindow: Function,
        patientCode: String,
        patientName: String,
        patientDOB: String,
        PatientCodeList: String,
        showModal: Boolean
      },
      data() {
          return {
              OptionsRightMenu:{
                visible:false,
                ItemsList:[],
                btnvisible:false,
                itemdisabled: false,
                PatientCode:'',
                Mode:this.edit_mode,
                favButtonAtion:false          
              },
              isParamVisible: true,
              isReportVisible: false,
              reportParams: [],
              PatientsList:[],
              params: {
                "PatientCodeList": [],
                "Show": 'all',
                "IsActive": true,
                "IsActiveAdmission": true
              },
              PatientCodeModel:'',
              parameter:{
                Status: true,
              },
              showOptions: [
                { value: "all", text: this.$t('Shared.All') },
                { value: "true", text:this.$t('Shared.Active') },
                { value: "false", text: this.$t('Shared.Inactive') },
              ],
              showPatientIsActiveModel: {
                "value":"true",
                "text":this.$t('Shared.Active')
              },
              showIsActiveAdmissionModel: {
                "value":"true",
                "text":this.$t('Shared.Active')
              }   
          }
      },
      methods: {

          onShowing(e) {
            let contentElement = e.component.content();
            let titleElement = e.component._$title[0]
            contentElement.style.padding = '0px';
            titleElement.style.padding = '0px 20px'
          },

          onHiddenReport() {
            this.isReportVisible = false
            if(!this.showModal)
              this.closeWindow();
          },

          onClickClose() {
            this.closeWindow()
            this.isParamVisible = false;
          },

          onClickShowReport() {
            this.isParamVisible = false

            this.reportParams = []
            this.reportParams.push({ paramName: 'pPatientCode', paramValue: this.patientCode})
            this.reportParams.push({ paramName: 'patientName', paramValue: this.patientName})
            this.reportParams.push({ paramName: 'patientDOB', paramValue: this.patientDOB})
            this.reportParams.push({ paramName: 'patientCodeList',paramValue: (this.params.PatientCodeList.length == 0) ? this.patientCode :this.params.PatientCodeList })
            this.reportParams.push({ paramName: 'isActive', paramValue: this.params.IsActive})
            this.reportParams.push({ paramName: 'isActiveAdmission', paramValue: this.params.IsActiveAdmission})
            this.reportParams.push({ paramName: 'show', paramValue: this.params.Show})

            this.isReportVisible = true
            this.$refs.specialPrecautions.buildDocument(this.reportParams)
          },

          ChangeDropDownPatients(values) {
            this.params.PatientCodeList = this.getItemsByComma(values, 'patientcode')
          },

          customLabelPatients ({ patientName, patientcode }) {
            return `${patientName} – ${patientcode}`
          },

          getPatients() {              
            PatientService.getPatientList(this.parameter)
              .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0){
                this.PatientsList = response.data; 
                this.PatientsList.forEach(function(element) {
                    element.patientName = element.firstname + " " + element.lastname;   
                  });
                }      
              })
          },
          reportList() {
              this.checkExistFavorite('/app/reports/patients');
              this.$router.push({ name: "reportListPatient" }).catch(()=>{});
          },
          getItemsByComma(values, field) {
            let len = values.length;
            let valuesSeparated = [];
            if (len > 0){
              var i = 0;
              values.forEach(function(e) {   
                if(i < len-1){
                  valuesSeparated += e[field]+",";
                }else{
                  valuesSeparated += e[field];
                }
                i++;                    
              });
              return valuesSeparated;
            } else {
              return [];
            }
        },
        ChangeIsActive(item) {
          this.params.IsActive = item.value
        },
        ChangeIsActiveAdmission(item) {
          this.params.IsActiveAdmission = item.value
        }
      },
      
  mounted () {
    if(!this.showModal) {
      this.onClickShowReport();
    } else {
      this.getPatients(); 
      this.OptionsRightMenu.ItemsList = [];
      let layoutStatus = this.$store.state.layoutStatus;
      switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
    }
  },
})
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
