<template>
    <div id="ContainerDailyNote">
        <div class="page-height">
            <div class="col-12">
                <MdTitleRegInfo 
                    v-bind:RegistrationInfo="isShedulerDB ? PatientTitle : RegistrationInfo" 
                    v-bind:titlePrefix="this.Mode ? $t('Medication.Edit') : $t('Medication.Add')" 
                    v-bind:titleRef="'titleElementMedication'"
                    v-bind:patientCode="this.$route.params.id">
                </MdTitleRegInfo>
                
                <b-row>
                    <b-col md="4" lg="4" sm="12">
                        <MdPatientAllergies
                            v-bind:NoUseClass="false"
                            v-bind:UseFixedMiddleClass="true"
                            v-bind:ModalIdAddAllergy="'AddAllergyMed'"
                            v-bind:VisibleAllergy="true"                    
                            v-bind:UseColumnDinamic="true"
                            v-bind:Column="'col-md-12'"                   
                            v-bind:PatientCode="this.$route.params.id"
                            v-bind:NoValidateChanges="true">						
                        </MdPatientAllergies>
                    </b-col>
                    <b-col md="4" lg="4" sm="12">
                        <MdPatientSpecialPrecautions	
                            v-bind:NoUseClass="false" 
                            v-bind:UseFixedMiddleClass="true"
                            v-bind:ModalIdAddSpecialPrecautions="'AddSpecialPrecautionMed'"                   
                            v-bind:VisibleSpecialPrecautions="true"
                            v-bind:UseColumnDinamic="true"
                            v-bind:Column="'col-md-12'"
                            v-bind:PatientCode="this.$route.params.id"
                            v-bind:NoValidateChanges="true">						
                        </MdPatientSpecialPrecautions>        
                    </b-col>
                    <b-col md="4" lg="4" sm="12">
                        <MdPatientMarsAlert 	
                            v-bind:NoUseClass="false" 
                            v-bind:UseFixedMiddleClass="true"
                            v-bind:ModalIdAddMarksAlert="'AddMarsAlertsMed'"
                            v-bind:VisibleMarsAlert="true"                   
                            v-bind:UseColumnDynamic="true"
                            v-bind:Column="'col-md-12'"
                            v-bind:PatientCode="this.$route.params.id"
                            v-bind:NoValidateChanges="true">						
                        </MdPatientMarsAlert>
                    </b-col>
                </b-row>

                <MdTitle v-bind:Text="CreatedBy" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>

                <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly && Medication.canEditMessage">
                    <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                    <div class="flex-grow-1">
                        <span>{{ Medication.canEditMessage }}</span>
                    </div>
                </div>

                <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="Mode && Medication.pharmacistNotes">
                    <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
                    <div class="flex-grow-1">
                        <span>{{$t('Medication.PharmacistNotes') +': '+Medication.pharmacistNotes}}</span>
                    </div>
                </div>
                
                <b-row>
                    <b-col md="3" lg="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Medication.Prescriber')"
                            v-bind:Options="PrescriberList"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'code'"
                            v-bind:AllowEmpty="false"
                            v-bind:Model="ModelPrescriber"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownPrescriber"
                            :IsDisabled="isReadOnly"
                        />
                    </b-col>
                    <b-col md="3" lg="3" sm="12" v-if="!this.PharmacyOption.byPassPhysicianSignature">
                        <MdCheckBox
                            v-bind:Label="$t('MdNote.Sign')"
                            v-bind:Name="'checkboxAdd-Sign'" 
                            v-bind:ClassProp="'AlignCheck'"
                            v-bind:CheckedValue="true"
                            v-bind:UncheckedValue="false"              
                            v-model="isSigned"
                            v-on:change="onSignature"
                            v-bind:Tag="'SignMedication'"  
                            :isReadOnly="isReadOnly || Medication.rxStatus == 'VOID' || Medication.isSigned"  
                        />
                    </b-col>
                    <b-col lg="3" md="3" sm="12">
                        <MdLookupMultiple
                            v-bind:ModelCode="Medication.drugCode"
                            v-bind:ModelDescription="Medication.drugName"
                            v-bind:Label="$t('Medication.Drugs')"                             
                            v-bind:RefCode="'Drugs'" 
                            v-bind:Api="DrupNameCall"
                            v-bind:OptionParamList="ParamList"                                                   
                            v-bind:FieldCode="'code'"   
                            v-bind:modelFilter="modelFilter"                         
                            v-bind:FieldDescription="'drugName'"
                            :IsDisabled="isReadOnly"    
                            :ChangeFilter="ChangeFilter"
                            :SearchTitle="$t('Medication.Drugs')"
                            :GetValues="onCodeConditionOk"                     
                            :SearchFieldCode="'Code'"
                            v-bind:Fields="BaseFieldsDrug"  
                            />
                    </b-col> 
                    <b-col md="3" lg="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Medication.Usage')"
                            v-bind:Options="UsageList"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'value'"
                            v-bind:AllowEmpty="false"
                            v-bind:Model="Medication.usage"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownUsage"
                            :IsDisabled="isReadOnly || usageIsReadOnly"
                        />
                    </b-col>             
                </b-row>

                <b-row>
                    <b-col lg="3" md="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Medication.Days')"
                            v-bind:Name="'Days'"
                            v-bind:TypeProp="'number'"                      
                            v-bind:Min="'1'"
                            v-bind:Max="'1000'"
                            v-model.number="Medication.day"
                            v-on:blur="OnBlurDay($event)"
                            v-on:change="onChangeDay($event)"
                            v-bind:isReadOnly="isReadOnly || frecuencyMode === 'STAT'"
                        />   
                    </b-col>    
                    <b-col lg="3" md="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Medication.Dosage')+unitOfMeasure"
                            v-bind:Name="'Dosage'"
                            v-bind:TypeProp="'number'"                      
                            v-bind:Min="'1'"
                            v-bind:Max="'1000'"
                            v-model.number="Medication.dosage"
                            v-on:change="onChangeDosage($event)"
                            v-bind:isReadOnly="isReadOnly"
                        />   
                    </b-col>  
                    <b-col lg="3" md="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Medication.DailyAmount')+unitOfMeasure"
                            v-bind:Name="'DailyAmount'"
                            v-bind:TypeProp="'number'"                      
                            v-bind:Min="'1'"
                            v-bind:Max="'1000'"
                            v-on:change="onChangeDailyAmount($event)"               
                            v-model.number="Medication.dailyAmount"              
                            v-bind:isReadOnly="isReadOnly"
                        />  
                    </b-col> 
                    <b-col lg="3" md="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Medication.TotalQty')"
                            v-bind:Name="'totalqty'"
                            v-bind:TypeProp="'number'"                      
                            v-bind:Min="'1'"
                            v-bind:Max="'1000'"
                            v-model.number="Medication.quantityOrdered"
                            v-bind:isReadOnly="true"
                        />   
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="3" lg="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Medication.StartDateAndTime')"
                            v-bind:Name="'sDate'"
                            v-bind:TypeProp="'text'" 
                            v-bind:Mask="'##/##/#### ##:## AA'"
                            v-bind:Min="'1900-01-01'"
                            v-bind:Max="'2200-12-31'"
                            v-model="startDateTime"
                            v-on:change="onChangeStartDateTime($event)"
                            v-bind:isReadOnly="isReadOnly"
                        />   
                    </b-col>
                    <b-col md="3" lg="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Medication.EndDateAndTime')"
                            v-bind:Name="'eDate'"
                            v-bind:TypeProp="'text'" 
                            v-bind:Mask="'##/##/#### ##:## AA'"
                            v-bind:Min="'1900-01-01'"
                            v-bind:Max="'2200-12-31'"
                            v-model="endDateTime"
                            v-on:change="onChangeEndDateTime($event)"               
                            v-bind:isReadOnly="isReadOnly"
                    />   
                    </b-col> 
                    <b-col md="3" lg="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Medication.RxStatus')"
                            v-bind:Options="RxStatusList"
                            v-bind:FieldDescription="'name'"
                            v-bind:FieldCode="'value'"
                                v-bind:AllowEmpty="false"
                            v-bind:Model="Medication.rxStatus"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownRxStatus"
                            :IsDisabled="isReadOnly"
                        />
                    </b-col> 
                    <b-col v-if="Medication.rxStatus == 'Inactive'"  md="3" lg="3" sm="12">
                        <MdEdit 
                            v-bind:Label="$t('Medication.stopDateTime')"
                            v-bind:Name="'eDate'"
                            v-bind:TypeProp="'text'" 
                            v-bind:Mask="'##/##/#### ##:## AA'"
                            v-bind:Min="'1900-01-01'"
                            v-bind:Max="'2200-12-31'"
                            v-model="Medication.stopDateTime"
                            v-on:change="onChangeStopDateTime($event)"               
                            v-bind:isReadOnly="isReadOnly"
                        />   
                    </b-col>                           
                </b-row>            

                <b-row>   
                    <b-col lg="3" md="3" sm="12">
                        <MdLookup
                            v-bind:ModelCode="Medication.frequencyCode"
                            v-bind:ModelDescription="Medication.frequencyDescription"
                            v-bind:Label="$t('Shared.Frequency')"
                            v-bind:RefCode="'Frequency'"  
                            v-bind:AuxFields="this.FrequencyAuxFields"                     
                            v-bind:Api="'DrugFrequencyHeaders?AdministrationFrequencies=true'"
                            v-bind:FieldCode="'code'"                        
                            v-bind:FieldDescription="'description'"
                            :IsDisabled="isReadOnly"    
                            :SearchTitle="$t('Shared.Frequency')"
                            :GetValues="onCodeConditionOk"                     
                            :SearchFieldCode="'Code'"
                            v-bind:Fields="BaseFields"
                        />
                    </b-col>     
                    <b-col lg="3" md="3" sm="12">
                        <MdButton
                            v-bind:Label="$t('Medication.Dosage-Time')"
                            v-bind:isReadOnly="(Medication.frequencyCode && (Medication.dosage || Medication.dailyAmount) && !isReadOnly)  ? false : true"
                            v-bind:Name="'btn_dosagetime'"
                            v-bind:ClassProp="'secondary mt-3 btn-login'"
                            v-on:click="OpenDosageTime"/>                    
                    </b-col> 
                    <b-col md="3" lg="3" sm="12">
                        <MdCheckBox
                            v-bind:Label="$t('Medication.PatientOwned')"
                            v-bind:Name="'patientowned'"
                            v-bind:ClassProp="'AlignCheck'"
                            v-bind:CheckedValue="true"
                            v-bind:UncheckedValue="false"
                            v-bind:isReadOnly="isReadOnly"
                            v-model="Medication.isPatientOwnMedication"
                            v-on:change="onChangePatientOwned"
                            v-bind:Tag="'SignMed'"
                        />
                    </b-col>   
                </b-row>  

                <MdTitle v-bind:Text="$t('Medication.Sig')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            
                <b-row>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$tc('Shared.ActionsTxt')"
                            v-bind:Options="ActionList"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ModelAction"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownAction"
                            :IsDisabled="isReadOnly"
                        />
                    </b-col>
                    <b-col md="3" lg="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Medication.RouteN')"
                            v-bind:Options="RouteNameList"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ModelRoute"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownRouteName"
                            :IsDisabled="isReadOnly"
                        />
                    </b-col>
                    <b-col md="3" lg="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Medication.When')"
                            v-bind:Options="WhenList"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ModelWhen"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownWhen"
                            :IsDisabled="isReadOnly"
                        />
                    </b-col>
                    <b-col md="3" lg="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Medication.Reason')"
                            v-bind:Options="ReasonList"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ModelReason"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownReason"
                            :IsDisabled="isReadOnly"
                        />
                    </b-col>
                </b-row> 

                <b-row>
                    <b-col md="12" lg="12">
                        <MdMemo
                            v-bind:Name="'textarea_notes'"
                            v-bind:Rows="'3'"
                            v-bind:MaxRows="'6'"
                            v-bind:MaxLength="'500'"
                            v-model="Medication.sig"
                            v-bind:isReadOnly="isReadOnly"
                        />                                     
                    </b-col>
                </b-row>

                <MdTitle v-bind:Text="$t('Medication.PrescriberNotes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            
                <b-row>
                    <b-col md="12" lg="12">
                        <MdMemo
                            v-bind:Name="'textarea_notes'"
                            v-bind:Rows="'3'"
                            v-bind:MaxRows="'6'"
                            v-bind:MaxLength="'500'"
                            v-model="Medication.prescriberNotes"
                            v-bind:isReadOnly="isReadOnly"
                        />                                     
                    </b-col>
                </b-row> 
            
                <MdTitle v-bind:Text="$t('Medication.DiagnosisAndIndication')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>                         
            
                <b-row>
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('MdPatientDiagnosis.Diagnosis')"
                            v-bind:Options="DiagnosisList"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ModelDiagnosis"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownDiagnosis"
                            :IsDisabled="isReadOnly"               
                        />
                    </b-col>   
                    <b-col lg="3" md="3" sm="12">
                        <MdSelect
                            v-bind:Label="$t('Medication.Indications')"
                            v-bind:Options="IndicationsList"
                            v-bind:FieldDescription="'description'"
                            v-bind:FieldCode="'code'"
                            v-bind:Model="ModelIndication"    
                            v-bind:Multiple="false"                                
                            :Input="ChangeDropDownIndication"
                            :IsDisabled="isReadOnly"               
                        />
                    </b-col> 
                </b-row>
            </div>

            <div class="space-actions"></div>
            
            <div class="page-actions">
                <MdButton
                    v-bind:Label="$t('Shared.Back')"
                    v-bind:Name="'btn_list_medications'"
                    v-bind:ClassProp="'secondary mx-2 btn-login'"
                    v-on:click="DiscardChanges"/>

                <MdButton v-if="!this.Mode"
                    v-bind:Label="$t('Shared.Save')"
                    style="z-index:99999"
                    v-bind:ClassProp="'primary btn-login mx-2'"
                    v-on:click="SaveOrEdit"                
                    v-bind:isReadOnly="!this.pendingChanges"               
                    v-bind:Name="'btn_save_medications'">
                </MdButton>

                <b-button-group variant="primary" v-if="this.Mode">
                    <b-button :disabled="!this.pendingChanges" variant="primary" v-on:click="SaveOrEdit">{{$t('Shared.Save')}}</b-button>
                    <b-dropdown variant="primary" class="ms-2">
                        <b-dropdown-item v-on:click="CopyOrder()" v-if="this.Mode"> {{$t('Medication.CopyOrder')}}</b-dropdown-item>
                        <b-dropdown-item v-on:click="CopyDCOrder()" v-if="this.Mode && this.Medication.rxStatus != 'Inactive'">{{$t('Medication.CopyDCOrder')}}</b-dropdown-item>
                        <b-dropdown-item v-on:click="InactiveOrders()"  v-if="this.setVisibilityInactiveOrder()">{{$t('Medication.InactiveOrder')}}</b-dropdown-item>
                        <b-dropdown-item v-on:click="showReport" v-if="this.Mode">{{ $t('Shared.Report') }}</b-dropdown-item>
                    </b-dropdown>
                </b-button-group>
            </div>
        </div>

        <ModalDosageTime
            v-bind:marsTimes="marsTimes"
            v-bind:unitOfMeasure="unitOfMeasure"
            @OnModalDosageTimeCancel="HideModalDosageTime"
            @RefreshDosageTime="RefreshDosageTime"
            v-if="VisibleDosageTime"
            v-bind:ModalId="'modalIdDosageTime'"
            v-bind:TitleWindow="$t('Medication.TitleModalDosageTime')"        
        />

        <PatientMedicationsRep v-if="isReportVisible" 
            :closeWindow="closeParamRepWindow"
            :patientCode="this.$route.params.id"
            :recNo="recNoToReport"
        />

        <MdPasswordDlg v-if="showSignPassword" 
            v-bind:Type="TypePassWord"
            v-bind:EmployeeCode="EmployeeCodeSelected"
            @getModalPassword="onModalPasswordResult($event)"
        />
    </div>
</template>

<script>

import ShowMessage from '@/components/messages/ShowMessage.js'
import StorageService from '@/services/storage.service'
import MedicationService from '@/services/medication.service'
import InventoryItemService from '@/services/inventoryitem.service'
import DrugFreqSetupHdrService from '@/services/drugfreqsetuphdrs.service'
import DrugFreqSetupDetService from '@/services/drugfreqsetupdet.service'
import PharmacyOptions from '@/services/pharmacyoption.service'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
import EnumService from '@/services/enum.service' 
import Utils from "@/common/utils"

import moment from "moment";
import LocalStorage from '@/services/storage.service';
import GlobalService from '@/services/global.service'
import PatientService from '@/services/patient.service'
import IndicationService from '@/services/indication.service'
import EmployeeService from '@/services/employee.service'

export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUseDailyNotes;
            if(iUA) 
                next()
            else 
                next('/accessdenied');
        }) 
    },

    beforeRouteLeave(to, from, next) {
        if (!this.isReadOnly && this.pendingChanges) {
            this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
                    title: this.$t('Medication.Medications'),
                    okTitle: this.$t('Shared.BtnSave'),
                    cancelTitle: this.$t('Shared.BtnDismiss'),
                    footerClass: 'p-2',
                    titleClass: 'modal-title modal-type-warning'
                })
                .then(value => {
                    if(!value){
                        next();
                    } else {
                        this.SaveOrEdit();
                        next();
                    }
                })
        } else {
            next()
        }
    },

    mixins: [ShowMessage, DBFunctions],  
    name: 'MedicationDetails',
    data() {
        return { 
            startDateTime:null,
            endDateTime:null,
            DiagnosisList:[],
            IndicationsList:[],
            CopyDateTime:null,
            ModelAction:{code:"",description:""},
            ModelRoute:{code:"",description:""},
            ModelWhen:{code:"",description:""},
            ModelReason:{code:"",description:""},
            ModelDiagnosis:{code:"",description:""},
            ModelIndication:{code:"",description:""},
            ModelPrescriber:{code:"",name:""},         
            timesPerDay:0,
            MarsAdminTime:[],
            MarsDosage:[],
            marsTimes:[],
            unitOfMeasure:"",
            VisibleDosageTime:false,
            isReload:true, 
            CreatedBy:'',       
            Mode:this.$attrs.editMode,  
            Loading:true,
            PrescriberModel:{
                prescriberCode:"",
                prescriberName:""
            },
            isReadOnly: false,
            pendingChanges: false,
            PharmacyOption:{},
            OptionsRightMenu:{
                visible:true,
                ItemsList:[],
                btnvisible:true,
                itemdisabled: false,       
            },
            FrequencyAuxFields:['hoursBetweenDoses','mode','recurEveryDays','timesPerDay'],
            RegistrationInfo: {},
            InventoryItem:{
                DispensingQty:1,
                DispensingCapacity:1,
                Dispensinguom:"QTY",
                IsReusable:false
            },
            EmpList:[],
            dDiagnosis:"",         
            dIndications:"",
            ObjData:{},
            Medication:{
                reason:"",
                dailyAmount:0,
                patientCode:this.$route.params.id,
                AdmissionRecordId:this.isShedulerDB ? this.currentAppointmentDB && this.currentAppointmentDB.recId : this.$route.params.recordid,
                frequencyCode:"",
                action:"",
                unitOfMeasure:"",
                restrictChangeDay:false,           
                dateTimeCreated: null,
                dateTimeExpired: null,
                day:1,
                diagCode:"",
                dispensingCapacity:0,
                dosage:0,
                doseQty: 0,
                drugCode:"",
                drugName:"",
                employeeCode:"",
                endDate:null,
                endTime:null,
                externalCreator: "",
                form: "",
                frequency:"",
                headerId: "",
                indicationCode:null,
                internalStatus: "",
                isInpatient: false,
                isPatientOwnMedication: false,
                isReusable: false,
                isSigned: false,
                issueType: "",
                issuedDate:null,
                medication:"",
                mode: "",
                notes: "",
                notesPlaintext:"",
                orderType: "",           
                pharmacistCode:"",
                pharmacistNotes: "",
                pharmacyAddress: "",
                pharmacyId: "",
                pharmacyName: "",
                prescriber: "",
                startDateTime:null,
                endDateTime:null,
                prescriberCode:"",
                qtytoTake:0,
                quantity: 0,
                quantityOrdered:0,
                recordId: "",
                refills:0,
                route: "",
                rxAutoStatus: "",
                rxNorm: "",
                rxStatus: "",
                rxType: "",
                showNoEndDate: false,
                showNoStartDate:false,
                sig: "",
                source: "",
                sourceRecordId:"",
                startTime: null,
                stopDate: null,
                stopTime: null,
                strength:null,
                timeToTake: 0,
                transmissionStatus: "",
                unit: "",
                whenSig: "",
                stopDateTime: null,
                marsTimes:[
                ]
            },
            EnabledDosageBtn:false,
            DateNow:null,
            OrderTypeList:[],
            ReasonList:[],
            WhenList:[],
            WhenModel:"",
            PrescriberList:[],
            RouteNameList:[],
            UsageList:EnumService.ClinicalMedicationUsage,
            RxStatusList:EnumService.ClinicalMedicationRxStatus,
            ActionList:[],
            DrugDescription:"",        
            RxStatusModel:{value:'Active',name: this.$t('Shared.Active')},
            ActionModel:"",
            RouteNameModel:"",
            UsageModel:{value:'Internal',name:'Internal'},
            DrupNameCall:"PatientMedications/AllDrugs?",
            dFrequency:"",
            ParamList:[
                {
                    code:1,
                    description:this.$t('Medication.Param.AllDrugs'),
                    resource:"PatientMedications/AllDrugs?"
                },
                {
                    code:2,
                    description:this.$t('Medication.Param.DrugsHistory'),
                    resource:"PatientMedications/DrugsHistory?PatientCode="+this.$route.params.id
                },
                {
                    code:3,
                    description:this.$t('Medication.Param.DrugByDiag'),
                    resource:"PatientMedications/DrugsByDiagnoses?PatientCode="+this.$route.params.id
                },
                {
                    code:4,
                    description:this.$t('Medication.Param.PrefDrugs'),
                    resource:"PatientMedications/PreferredDrugs?"
                },
            ],

            modelFilter:{code:1,description:this.$t('Medication.Param.AllDrugs'),resource:"PatientMedications/AllDrugs?"},

            //Models
            OrderTypeModel:"",

            style:{
                boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
                width: '100%',
                backgroundImage: '-webkit-linear-gradient(268deg, #FAFAFA, #FAFAFAEE)',
                borderRadius: '0 0 14px 14px',
                borderTop: '1px solid #EEE',
                padding: '10px',
                textAlign: 'right'     
            },

            UserId: LocalStorage.getUserId(),
            BaseFields:[
                { key: 'description', sortable: true, label: this.$t('Shared.Description')},
                { key: 'code', sortable: true, label: this.$t('Shared.Code')},
                { key: 'mode', sortable: true, label: this.$t('Medication.Mode')},
                { key: 'recurEveryDays', sortable: true, label: this.$t('Medication.ReEveryDays')},
                { key: 'timesPerDay', sortable: true, label: this.$t('Medication.TimesPerDay')},
                { key: 'hoursBetweenDoses', sortable: true, label: this.$t('Medication.HoursBetweenDoses')},
            ],
            BaseFieldsDrug:[
                { key: 'drugName', sortable: true, label: this.$t('Medication.DrugName')},
                { key: 'activeIngredients', sortable: true, label: this.$t('Medication.AcIngredients')},
                { key: 'code', sortable: true, label: this.$t('Shared.Code')}
            ],
            isReportVisible: false,
            recNoToReport: '',
            IsPrescriptionCurrent: false,
            frecuencyMode: '',
            defaultDoctor: '',
            isShedulerDB: false,
            PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:'',
                activitytypename:'',
                startdatetime:'',
                ageFormatted:''
            },
            currentAppointmentDB:{
                guid:'',
                activitytypecode:'',
                employee: {
                    firstname:"",
                    lastname:""
                },
            },
            EmployeeCodeSelected:"",   
            showSignPassword: false,
            isSigned:false,
            TypePassWord:"",
            ProviderModel:{
                therapistCode:"",
                therapistName:"", 
                requestSigCreateMed:false
            },
            EmployeeCode:"",
            usageIsReadOnly: false
        }
    },

    methods:{
        DiscardChanges() {
            if (this.isShedulerDB) {
                this.$router.push({
                    name: "Appointments-Medications",
                    params: { id: this.$route.params.id, actId: this.$route.params.actId },
                });
            } else {
                this.$router.push({
                    name: "Clinical-Medications",
                    params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
                });   
            }
        },

        alertMessage(){
            alert("Hello! I am an alert box!!");
        },

        ChangeDropDownEmpl(){

        },

        showReport(){
            this.recNoToReport = this.$route.params.recId
            this.isReportVisible = true
        },

        ChangeDropDownOrderType(){

        },

        PrepareMedication (){
            this.ObjData = {
                PatientCode:this.$route.params.id,
                AdmissionRecordId: this.isShedulerDB ? this.currentAppointmentDB && String(this.currentAppointmentDB.recId) : this.$route.params.recordid,
                PrescriberCode:this.ModelPrescriber.code,
                DrugCode:this.Medication.drugCode,
                Usage:this.Medication.usage,
                IsPatientOwnMedication:this.Medication.isPatientOwnMedication,
                Day:this.Medication.day,
                Dosage:this.Medication.dosage,
                DailyAmount:this.Medication.dailyAmount,
                QuantityOrdered:this.Medication.quantityOrdered,
                StartDateTime:Utils.formatterToISO(this.startDateTime, Utils.getDateTimeFormat()),
                EndDateTime:Utils.formatterToISO(this.endDateTime, Utils.getDateTimeFormat()),
                RxStatus:this.Medication.rxStatus,
                FrequencyCode:this.Medication.frequencyCode,
                Sig:this.Medication.sig,
                Action:this.ModelAction.code,
                Route:this.ModelRoute.code,
                WhenSig:this.ModelWhen.code,
                Reason:this.ModelReason.code,
                PrescriberNotes:this.Medication.prescriberNotes,
                DiagCode:this.Medication.diagCode,
                IndicationCode:this.Medication.indicationCode,
                StopDateTime: Utils.formatterToISO(this.Medication.stopDateTime, Utils.getDateTimeFormat()),
                MarsTimes:this.Medication.marsTimes,
                IsSigned:this.isSigned
            }
        },

        IsNullOrEmpty(value){
            return (((value??"").trim() === "") || value === null) ? true : false;
        },

        async DoAction(){      
            if(this.pendingChanges){
                if(this.Mode) {
                    let savedPrescription = {
                        internalStatus : this.Medication.internalStatus,
                        mode: this.Medication.mode,
                        usage: this.Medication.usage
                    }
                    await MedicationService
                            .get( this.$route.params.recId )
                            .then((response) => {
                                if(response.status == '200' && response.data){ 
                                    const medication = response.data   
                                    savedPrescription.internalStatus = medication.internalStatus;
                                    savedPrescription.mode = medication.mode;
                                    savedPrescription.usage = medication.usage;
                                }
                            });
                    if ( ( (this.Medication.rxStatus??"").trim().toUpperCase() === 'INACTIVE') && 
                        ( !this.PharmacyOption.inactivePrescAtAnyStatus) && 
                        (   ( (savedPrescription.internalStatus??"").trim().toUpperCase() !== 'MARSCREATED' )  || 
                            ( ((savedPrescription.mode??"").trim().toUpperCase() === "PRN") || ((savedPrescription.internalStatus??"").trim().toUpperCase() === "RECEIVED")
                                && ((savedPrescription.usage??"").trim().toUpperCase() === "INTERNAL")    )))
                    {
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.InactiveStatusMARSCREATED'),"error");        
                        return;
                    } else if( ((this.Medication.rxStatus??"").trim().toUpperCase() === 'VOID') && ((savedPrescription.internalStatus??"").trim().toUpperCase() !== "ORDERED")){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.VoidWhenStatusIsNotORDERED'),"error");        
                        return;
                    }
                }

                if(  ((this.Medication.rxStatus??"").trim().toUpperCase() === 'INACTIVE') && (this.Medication.stopDateTime === "")  ){
                    this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.StopDateCannotbeEmpty'),"error");        
                    return;
                }

                if(  ((this.Medication.rxStatus??"").trim().toUpperCase() === 'INACTIVE') && ((this.Medication.stopDateTime !== "") || (this.Medication.stopDateTime !== null) )  ){
                    await this.getDateTimeValidateStopDate();
                    const stopDate = moment(this.Medication.stopDateTime, Utils.getDateTimeFormat())
                    const DNow  = moment(this.DateNow, Utils.getDateTimeFormat())
                    if ( stopDate < DNow ){
                            this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.TheStopDateMustBeGreaterThanTheCurrentDate'),"error");        
                            return;
                    }
                }

                if (this.IsNullOrEmpty(this.Medication.drugCode)){
                    this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.DrugCodeCannotBeEmpty'),"error");        
                    return;
                }         

                if(  ((this.Medication.rxStatus??"").trim().toUpperCase() !== 'INACTIVE') || (this.Medication.rxStatus??"").trim().toUpperCase() !== 'VOID'  ){
                
                    if(this.IsNullOrEmpty(this.Medication.patientCode)){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.PatientCodeCannotBeEmpty'),"error");        
                        return;
                    }

                    if(this.IsNullOrEmpty(this.ModelPrescriber.code)){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.PrescriberCannotBeEmpty'),"error");        
                        return;
                    }

                    if(this.IsNullOrEmpty(this.Medication.usage)){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.UsageCannotBeEmpty'),"error");        
                        return;
                    }

                    if( ((this.Medication.usage??"").trim().toUpperCase() !== 'INTERNAL') && (this.Medication.usage??"").trim().toUpperCase() !== 'HOME'){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.UsageMustBeInternalOrHome'),"error");        
                        return;
                    }

                    if((this.Medication.day??0) <= 0){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.DaysMustBeGreaterThan0'),"error");        
                        return;
                    }

                    if((this.Medication.dosage??0) <= 0){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.DosageMustBeGreaterThan0'),"error");        
                        return;
                    }

                    if((this.Medication.dailyAmount??0) <= 0){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.DailyAmountMustBeGreaterThan0'),"error");        
                        return;
                    }

                    if((this.Medication.quantityOrdered??0) <= 0){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.TotalQuantityMustBeGreaterThan0'),"error");        
                        return;
                    }

                    if(this.IsNullOrEmpty(this.startDateTime)){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.StartDateAndTimeCannotBeEmpty'),"error");        
                        return;
                    }

                    if(this.IsNullOrEmpty(this.endDateTime)){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.EndDateAndTimeCannotBeEmpty'),"error");        
                        return;
                    }

                    if(this.IsNullOrEmpty(this.Medication.rxStatus)){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.RxStatusCannotBeEmpty'),"error");        
                        return;
                    }

                    if(this.IsNullOrEmpty(this.Medication.frequencyCode)){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.FrequencyCodeCannotBeEmpty'),"error");        
                        return;
                    }
                }
            }

            this.WasSaved = true;          
            !this.Mode ? this.AddMedication() : this.UpdateMedication(); 
        },

        isVoidOrInactive() {
            return this.Medication.rxStatus == 'Inactive' || this.Medication.rxStatus == 'Void'
        },

        async SaveOrEdit(){ 
            if (this.isReadOnly == false && this.pendingChanges) {
                if (this.PharmacyOption && this.PharmacyOption.copyActivePresc && !this.isVoidOrInactive()){
                    await this.getPrescriptionCurrent('WRITE')
                
                    if (this.IsPrescriptionCurrent) {
                        this.$bvModal.msgBoxConfirm(this.$t('Medication.MsgPrescriptionCurrent'), {
                                title: this.$t('Medication.Medications'),
                                okTitle: this.$t('Shared.Continue'),
                                cancelTitle: this.$t('Shared.BtnCancel'),
                                footerClass: 'p-2',
                                titleClass: 'modal-title modal-type-warning',
                                id:'modal_delete_vitals'
                            })
                            .then(value => {
                                if(value) {
                                    this.DoAction();
                                }
                            })
                    } else {
                        this.DoAction();
                    }
                } else {
                    this.DoAction();
                }
            }
        },

        GotoMedicationList(){
            if (this.isShedulerDB) {
                this.$router.push({
                    name: "Appointments-Medications",
                    params: { id: this.$route.params.id, actId: this.$route.params.actId },
                });
            } else {
                if(this.$route.name.includes('Clinical-medications')) {
                    this.$router.push({ name: 'Clinical-Medications', params: { id: this.$route.params.id, recordid: this.$route.params.recordid }});
                }
            }
        },

        GotoNewOrder(orderId) {
            if (this.isShedulerDB) {
                this.$router.push({ name: "Appointments-medications-details", 
                    params: { recId: orderId, actId: this.$route.params.actId } });
            } else {
                this.$router.push({ name: "Clinical-medications-details", params: { recId: orderId } });
            }
        },

        AddMedication(){  
            this.$emit('load', true);
            this.PrepareMedication();   
            MedicationService
                .add(this.ObjData)
                .then((response) => {
                    if(response.status == '200'){
                        this.Medication = response.data;   
                        this.pendingChanges = false;   

                        this.GotoMedicationList();
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                        , 50); 
                    }       
                    this.$emit('load', false);                      
                })
                .catch((error) => {   
                    if (!error.response) {
                        this.showMessage(this.$t('Medication.Add'),this.$t('Msg.NotAbleToInsert'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Medication.Add'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('Medication.Add'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Medication.Add'),error.response.data.errorMessage,"error");
                    } 
                    this.$emit('load', false);
                }); 
        },

        UpdateMedication(){ 
            this.$emit('load', true);       
            this.PrepareMedication();
            MedicationService
                .update(this.$route.params.recId, this.ObjData)
                .then((response) => {
                    if(response.status == '200'){                       
                        this.pendingChanges = false; 

                        this.GotoMedicationList();
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                            title: this.$t('Msg.Saving'),
                            autoHideDelay: 5000,
                            variant: "success"
                            })
                        , 50); 
                    }  
                    this.$emit('load', false);                  
                })
                .catch((error) => {
                    if (!error.response) {
                        this.showMessage(this.$t('Medication.Edit'),this.$t('Msg.NotAbleToUpdate'),"error");
                    } else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Medication.Edit'),error.response.data.errorMessage,"error");
                    }   
                    else if(error.response.status === 500){
                        this.showMessage(this.$t('Medication.Edit'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Medication.Edit'),error.response.data.errorMessage,"error");
                    }  
                    this.$emit('load', false);
                });
        },

        checkUserAction() {

        },

        HideModalDosageTime(){
            this.VisibleDosageTime = false;
        },

        RefreshDosageTime(marsTimes){
            this.Medication.marsTimes = marsTimes;
            this.VisibleDosageTime = false;
        },

        async onCodeConditionOk(result){
            var type = result.FieldName;
            this.checkUserAction();
            if(type.indexOf('Frequency')  >= 0 ){
                switch (type) {               
                default:
                    if(result.code){
                        this.frecuencyMode = result.AuxFields[1]
                        this.$emit('load', true);
                        let fCode = result.code.trim(); 
                        if(result.code.trim() !== this.Medication.frequencyCode){                  
                            if(fCode !== null && fCode !== ""){
                                await this.getMedDrugFrequencyByCode(fCode);

                                if(this.Medication.dailyAmount > 0 && this.Medication.dosage === 0){
                                    this.Medication.dosage = this.Medication.dailyAmount / this.timesPerDay;
                                }

                                this.Medication.dailyAmount = this.Medication.dosage * this.timesPerDay; 
                            }
                        }  

                        this.Medication.frequencyCode = result.code.trim();
                        this.Medication.frequencyDescription = result.description.trim();
                        
                        if (result.AuxFields[1] && result.AuxFields[1] === 'TIMED') {
                            await this.getMarsTimes();
                            if(this.marsTimes.length > 0){
                                this.PopulateDatesWithMarsTime(this.marsTimes);
                            }

                            this.getMedQuantityToOrder(); 
                            this.getMedSig();
                        } else if(result.AuxFields[1] && result.AuxFields[1] === 'STAT') {
                            this.marsTimes = [];
                            this.Medication.marsTimes = [];
                            this.Medication.day = 1;
                            this.onChangeDay(1);
                        } else {       
                            this.marsTimes = [];
                            this.Medication.marsTimes = [];
                            this.getMedQuantityToOrder(); 
                            this.getMedSig();
                        } 
                        this.$emit('load', false);
                    }else{
                        this.Medication.frequencyCode = "";
                        this.Medication.frequencyDescription = "";
                        this.$emit('load', false);
                    }
                }
            }
            else if(type.indexOf('Drugs')  >= 0 ){
                switch (type) {               
                default:
                    if(result.code){
                        this.$emit('load', true);  
                        let dCode = result.code.trim();                     
                        if(result.code.trim() !== this.Medication.drugCode){
                            await this.getMedDrugInfo(dCode);                    
                            this.getMedSig();

                            if(this.Medication.day !== 0){
                                if(this.Medication.day > 0 && this.Medication.day !== "" && this.Medication.day !== null){
                                    let stime = Utils.formatTime(this.endDateTime, Utils.getDateTimeFormat());
                                    let sDate = Utils.formatterDate(this.startDateTime, Utils.getDateTimeFormat());
                                    let sDateTime = moment(sDate+" "+stime, 'YYYY-MM-DD hh:mm A').add(this.Medication.day-1, 'd');

                                    this.endDateTime = Utils.formatterDateTimeToString(sDateTime.format()); 
                                }else{
                                    this.endDateTime = this.startDateTime;
                                }    
                            } 
                            this.getMedQuantityToOrder(); 
                        }  
                        this.Medication.drugCode = result.code.trim();
                        this.Medication.drugName = result.description.trim();
                        this.$emit('load', false);                  
                    }else{
                        this.Medication.drugCode = "";
                        this.Medication.drugName = "";
                    }
                }
            }
        },

        PopulateDatesWithMarsTime(arr){     
            if(this.CopyDateTime){
                let hourNow = Utils.formatTime(this.CopyDateTime, Utils.getDateTimeFormat());       
                let _starttime = hourNow;
                let _endtime = "";
                for (const time of arr) { 
                    if(moment(hourNow, 'hh:mm A') < moment(time.adminTime, 'HH:mm')){
                        _starttime = Utils.formatTime(time.adminTime, 'HH:mm');
                        break;
                    }
                } 
                _endtime = Utils.formatTime(arr[arr.length - 1].adminTime, 'HH:mm');  

                let st = this.startDateTime ? this.startDateTime.split(" ") : null;
                let et = this.endDateTime ? this.endDateTime.split(" ") : null;
                
                let stime = st !== null ? st[0]+" "+_starttime : this.CopyDateTime;
                let etime = et !== null ? et[0]+" "+_endtime : this.CopyDateTime;

                this.startDateTime = Utils.formatterDateTimeToString(Utils.formatDateTime(stime, Utils.getDateTimeFormat()));
                this.endDateTime = Utils.formatterDateTimeToString(Utils.formatDateTime(etime, Utils.getDateTimeFormat()));
            }
        },

        async getMedDrugInfo(dCode) { 
            this.$emit('load', true);     
            await MedicationService
                .getDrugInfo(dCode,this.Medication.frequencyCode,this.Medication.dailyAmount,
                    Utils.formatterToISO(this.startDateTime, Utils.getDateTimeFormat()), Utils.formatterToISO(this.endDateTime, Utils.getDateTimeFormat()))
                .then((response) => {      
                    if (response.data) {  
                        this.Medication.day = response.data.day;
                        this.Medication.dosage = response.data.dosage;
                        this.Medication.quantityOrdered = response.data.quantityOrdered;                
                        this.unitOfMeasure = " ( "+response.data.unitOfMeasure+" ) ";
                        this.Medication.restrictChangeDay = response.data.restrictChangeDay;

                        this.ModelAction.code = response.data.actionCode;
                        this.ModelAction.description = response.data.actionDescription;

                        this.ModelRoute.code = response.data.routeNameCode;
                        this.ModelRoute.description = response.data.routeNameDescription;

                        this.ModelWhen.code = response.data.whenSigCode;
                        this.ModelWhen.description = response.data.whenSigDescription;

                        this.ModelReason.code = response.data.reasonCode;
                        this.ModelReason.description = response.data.reasonDescription;
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        getMedQuantityToOrder() {
            this.$emit('load', true); 
            this.SeparateList();
            let params = {
                DrugCode:this.Medication.drugCode,
                FreqCode:this.Medication.frequencyCode,
                DailyAmount:this.Medication.dailyAmount,
                SDTime:Utils.formatterToISO(this.startDateTime, Utils.getDateTimeFormat()),
                EDTime:Utils.formatterToISO(this.endDateTime, Utils.getDateTimeFormat()),
                Day:this.Medication.day,
                MarsAdminTime:this.MarsAdminTime,   
                MarsDosage:this.MarsDosage,   
            }         

            MedicationService
                .getQuantityToOrder(params)
                .then((response) => {      
                    if (response.status === 200) {                   
                        this.Medication.quantityOrdered  = response.data;
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        SeparateList(){
            this.MarsAdminTime = [];
            this.MarsDosage = [];
            for(var i = 0; i < this.Medication.marsTimes.length; i++ ){
                this.MarsAdminTime.push(this.Medication.marsTimes[i].adminTime);
                this.MarsDosage.push(this.Medication.marsTimes[i].dosage);
            }
        },

        getMedSig() {
            this.$emit('load', true);  
            this.SeparateList();       
            let params = {
                DrugCode:this.Medication.drugCode,
                FreqCode:this.Medication.frequencyCode,
                Day:this.Medication.day,
                Dosage:this.Medication.dosage,
                DailyAmount:this.Medication.dailyAmount,
                Action:this.ModelAction.code,
                Route:this.ModelRoute.code,
                WhenSig:this.ModelWhen.code,
                Reason:this.ModelReason.code,
                MarsAdminTime:this.MarsAdminTime,
                MarsDosage:this.MarsDosage,
            }
            MedicationService
                .getSig(params)
                .then((response) => {      
                    if (response.data) {                   
                        this.Medication.sig  = response.data.toString();
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        ChangeDropDownPrescriber(item){
            this.$emit('load', true);        
            if(item !== null){
                this.ModelPrescriber = item;
                this.Medication.prescriberCode = item.code;
            }else{
                this.ModelPrescriber = {code:"",name:""};
                this.Medication.prescriberCode = "";
            }
            this.$emit('load', false);
        },

        ChangeDropDownIndication(item){
            this.$emit('load', true);
            if(item !== null){
                this.ModelIndication = item;
                this.Medication.indicationCode = item.code;           
            }else{
                this.ModelIndication = {code:"",description:""};
                this.Medication.diagCode = "";
            }
            this.$emit('load', false);
        },

        ChangeDropDownUsage(item){
            if(item !== null){
                this.Medication.usage = item.value;
            }else{
                this.Medication.usage = "";
            }
        },
        
        onChangeShowNoEndDate(){

        },

        async ChangeDropDownRxStatus(item){
            if(item !== null){
                this.$emit('load', true);

                let savedPrescription = {
                    internalStatus : this.Medication.internalStatus,
                    mode: this.Medication.mode,
                    usage: this.Medication.usage
                }
                await MedicationService
                        .get( this.$route.params.recId )
                        .then((response) => {
                            if(response.status == '200' && response.data){ 
                                const medication = response.data   
                                savedPrescription.internalStatus = medication.internalStatus;
                                savedPrescription.mode = medication.mode;
                                savedPrescription.usage = medication.usage;
                            }
                        });
                
                //Desktop validation
                if  (   ((item.value ?? "").trim().toUpperCase() == "INACTIVE") &&
                        (!this.PharmacyOption.inactivePrescAtAnyStatus) &&
                        (
                            (
                                (   (savedPrescription.internalStatus??"").trim().toUpperCase() !== 'MARSCREATED' ) ||
                                (   
                                    ((savedPrescription.mode??"").trim().toUpperCase() === "PRN") && 
                                    ((savedPrescription.internalStatus??"").trim().toUpperCase() === "RECEIVED") && 
                                    ((savedPrescription.usage??"").trim().toUpperCase() === "INTERNAL")
                                )
                            ) == false
                        )
                    ) {
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.InactiveStatusMARSCREATED'), "error"); 
                        this.Medication.rxStatus = "Active";
                        this.$emit('load', false);
                        return;
                } else if ( ((item.value ?? "").trim().toUpperCase() == "VOID") &&
                            ((savedPrescription.internalStatus??"").trim().toUpperCase() !== "ORDERED")
                            ) {
                    this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.VoidWhenStatusIsNotORDERED'),"error");    
                    this.Medication.rxStatus = "Active";
                    this.$emit('load', false);
                    return;     
                } else if ((item.value ?? "").trim().toUpperCase() == "VOID") {
                    this.Medication.internalStatus = "VOIDED"
                } else if ((item.value ?? "").trim().toUpperCase() == "PAST") {
                    this.Medication.internalStatus = "FULLYGIVEN"
                } else if ((item.value ?? "").trim().toUpperCase() == "INACTIVE") {
                    const now = new Date();
                    this.Medication.stopDateTime = Utils.formatterDateTimeToString(now);
                }

                this.Medication.rxStatus = item.value;
                if (item.value == "Inactive")
                this.getDateTime(true)
            }
            else{
                this.Medication.rxStatus = ""; 
            }
            this.$emit('load', false);
        },

        onChangePatientOwned(){

        },

        OnBlurDay(item){
            this.$emit('load', true);
            if(item <= 0){
                this.Medication.day = 1;
            }
            this.$emit('load', false);
        },

        ChangeDropDownDiagnosis(item){
            this.$emit('load', true);
            if(item !== null){
                this.ModelDiagnosis = item;
                this.Medication.diagCode = item.code;           
            }else{
                this.ModelDiagnosis = {code:"",description:""};
                this.Medication.diagCode = "";
            }
            this.$emit('load', false);
        },

        ChangeDropDownReason(item){
            this.$emit('load', true);
            if(item !== null){
                this.ModelReason = item;
                this.Medication.reason = item.code;
                this.getMedSig();
            }else{
                this.ModelReason = {code:"",description:""};
                this.Medication.reason = "";
            }
            this.$emit('load', false);
        },

        ChangeDropDownAction(item){
            this.$emit('load', true);
            if(item !== null){
                this.ModelAction = item;
                this.Medication.action = item.code;           
                this.getMedSig();
            }else{
                this.ModelAction = {code:"",description:""};
                this.Medication.action = "";
            }
            this.$emit('load', false);
        },

        ChangeDropDownRouteName(item){
            this.$emit('load', true);
            if(item !== null){
                this.ModelRoute = item;
                this.Medication.route = item.code;
                this.getMedSig();
            }else{
                this.ModelRoute = {code:"",description:""};
                this.Medication.route = "";
            }
            this.$emit('load', false);
        },

        ChangeDropDownWhen(item){
            this.$emit('load', true);
            if(item !== null){
                this.ModelWhen = item;
                this.Medication.whenSig = item.code;
                this.getMedSig();
            }else{
                this.ModelWhen = {code:"",description:""};
                this.Medication.whenSig = "";
            }
            this.$emit('load', false);
        },

        onChangeShowNoStartDate(){

        },

        OpenDosageTime(){
            this.VisibleDosageTime = true;
        },

        async GetAdminTimeByCode(Code){ 
            this.$emit('load', true);
            var result = {}; 
            var ListingRequest = {
                Code : Code
            };      
            await DrugFreqSetupDetService
                .getAllByCode(ListingRequest)
                .then((response) => {      
                    if (response.data) {                    
                        result = response.data;      
                    }  
                    this.$emit('load', false);  
                })
                .catch(error => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });  
            return result;        
        },

        async GetDispensingInfo(DrugCode){ 
            this.$emit('load', true);
            var result = false;        
            await InventoryItemService
                .get(DrugCode)
                .then((response) => {      
                    if (response.data) {                    
                        result = response.data.dispensingQty > 0  && response.data.dispensingCapacity > 0 && response.data.dispensinguom !== "" ? true : false;                                  
                        if(result){
                            this.InventoryItem = response.data;
                        }           
                    }  
                    this.$emit('load', false);  
                })
                .catch(error => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });  
            return result;        
        },

        async GetDrugFreqSetupIsTimed(FreqCode){
            this.$emit('load', true); 
            var result = false;        
            await DrugFreqSetupDetService
                .get(FreqCode)
                .then((response) => {      
                    if (response.data != null && response.data.code != "") {                    
                        result = true;    
                    }
                    this.$emit('load', false);  
                })
                .catch(error => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });  
            return result;        
        },

        async GetDrugFreqSetupRecurEveryDays(Frequency){
            this.$emit('load', true); 
            var result = 0;        
            await DrugFreqSetupHdrService
                .get(Frequency)
                .then((response) => {      
                    if (response.data) {                    
                        result = response.data.recurEveryDays;                               
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });  
            return result;        
        },

        DosesBeforeStartTime(){
            var arr = this.Medication.marsTimeList;
            var sum = 0;
            arr.forEach(element => {
                var adt = moment(element.adminTime).format("HH:mm");
                var st = this.Medication.startTime;
                if(adt < st){
                    sum+=element.qty;
                }  
            });
            return sum;
        },

        async getDateTimeValidateStopDate() {       
            await GlobalService
                .getDateTime()
                .then((response) => {      
                    if (response.data) {
                        this.DateNow = Utils.formatterDateTimeToString(response.data);
                    }  
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },


        async getDateTime(onChangeStopDateTime) {
            this.$emit('load', true); 
            await GlobalService
                .getDateTime()
                .then((response) => {      
                    if (response.data) {
                        if (onChangeStopDateTime)
                            this.Medication.stopDateTime = Utils.formatterDateTimeToString(response.data); 
                        else {
                            this.CopyDateTime = Utils.formatterDateTimeToString(response.data);              
                            this.startDateTime = Utils.formatterDateTimeToString(response.data); 
                            this.endDateTime = Utils.formatterDateTimeToString(response.data);
                        }
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        async getMedDrugFrequencyByCode(fCode) {
            this.$emit('load', true); 
            await MedicationService
                .getDrugFrequencyByCode(fCode)
                .then((response) => {      
                    if (response.data) {             
                        this.timesPerDay = response.data.timesPerDay;
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        async getDiagnosisByPatientCode() { 
            this.$emit('load', true);
            await PatientService
                .getDiagnosisListByPatientCode(this.$route.params.id)
                .then((response) => {      
                    if (response.data) {             
                        this.DiagnosisList = response.data;
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        async getIndicationsList() {
            this.$emit('load', true); 
            await IndicationService
                .getIndications()
                .then((response) => {      
                    if (response.data) {             
                        this.IndicationsList = response.data;
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        async onChangeDosage(){
            this.$emit('load', true); 
            await this.getMarsTimes();
            this.getMedSig();
            if(this.Medication.frequencyCode !== null && this.Medication.frequencyCode !== ""){
                await this.getMedDrugFrequencyByCode(this.Medication.frequencyCode);   
                this.Medication.dailyAmount = this.Medication.dosage * this.timesPerDay; 
            }else{
                this.Medication.dailyAmount = 0;
            }

            this.getMedQuantityToOrder();
            this.$emit('load', false);
        },

        onChangeDay(item){    
            this.$emit('load', true); 
            if(item <= 0){
                this.Medication.day = 1;
            }       
            
            this.getMedSig();
            if(this.Medication.day !== 0){
                if(this.Medication.day > 0 && this.Medication.day !== "" && this.Medication.day !== null) {
                    let stime = Utils.formatTime(this.endDateTime, Utils.getDateTimeFormat());
                    let sDate = Utils.formatterDate(this.startDateTime, Utils.getDateTimeFormat());
                    let sDateTime = moment(sDate+" "+stime, 'YYYY-MM-DD hh:mm A').add(this.Medication.day-1, 'd');
                    this.endDateTime = Utils.formatterDateTimeToString(sDateTime.format());
                } else {
                    this.endDateTime = this.startDateTime;
                }
            }  
            
            this.getMedQuantityToOrder();
            this.$emit('load', false);     
        },

        async onChangeDailyAmount(){
            this.$emit('load', true);
            await this.getMarsTimes();        
            this.getMedSig();
            
            if(this.Medication.frequencyCode !== null && this.Medication.frequencyCode !== ""){
                await this.getMedDrugFrequencyByCode(this.Medication.frequencyCode);
                this.Medication.dosage = this.Medication.dailyAmount / this.timesPerDay;            
            }else{
                this.Medication.dosage = 0;
            }

            this.getMedQuantityToOrder();
            this.$emit('load', false);
        },

        onChangeStartDateTime(){
            this.$emit('load', true);
            this.getMedQuantityToOrder();
        },

        onChangeEndDateTime(){
            this.$emit('load', true);
            this.getMedQuantityToOrder();
        },

        async getMarsTimes() {
            this.$emit('load', true); 
            await MedicationService
                .getMarsTimes(this.Medication.frequencyCode,this.Medication.dosage)
                .then((response) => {      
                    if (response.data) {              
                        let marsList = response.data;

                        if(marsList.length > 0){  
                            let arr = [];                  
                            marsList.forEach((item) => {                       
                                arr.push({adminTime:Utils.formatTimeHm(item.adminTime),
                                        dosage:item.dosage                                                           
                                });                                    
                            });

                            this.marsTimes = arr;
                            this.Medication.marsTimes = [...marsList];
                        }
                    }  
                    this.$emit('load', false);
                })
                .catch(error => {
                    if (error.response) {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        getDefaultDateTime() {   
            this.$emit('load', true);     
            GlobalService
                .getDateTime()
                .then((response) => {      
                    if (response.data) {  
                        this.Medication.stopDate = Utils.formatterDate(response.data);
                        this.Medication.stopTime = Utils.formatTimeHm(response.data);
                    }  
                    this.$emit('load', false);    
                })
                .catch(error => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                }); 
        },

        async getMedicationByRecId(){
            if(this.Mode){ 
                this.$emit('load', true);            
                await MedicationService
                    .get( this.$route.params.recId )
                    .then((response) => {
                        if(response.status == '200' && response.data){                   
                            var med = response.data;
                            
                            med.dateTimeCreated = Utils.formatterDateTimeToString(med.dateTimeCreated);  
                            med.endTime = Utils.formatTimeHm(med.endTime);
                            med.startTime = Utils.formatTimeHm(med.startTime);
                            med.endDate = Utils.formatterDate(med.endDate);
                            med.startDate = Utils.formatterDate(med.issuedDate);
                            med.stopDate = Utils.formatterDate(med.stopDate);
                            med.stopTime = Utils.formatTimeHm(med.stopTime);

                            med.startDateTime = Utils.formatterDateTimeToString(med.startDateTime); 
                            med.endDateTime = Utils.formatterDateTimeToString(med.endDateTime); 

                            med.stopDateTime = Utils.formatterDateTimeToString(med.stopDateTime); 
                            
                            this.CopyDateTime = med.startDateTime; 
                            this.startDateTime = med.startDateTime; 
                            this.endDateTime = med.endDateTime; 
                        
                            if(med.marsTimes.length > 0){  
                                let arr = [];                  
                                med.marsTimes.forEach((item) => {                       
                                    arr.push({adminTime:Utils.formatTimeHm(item.adminTime),
                                        dosage:item.dosage                                                             
                                    });                                    
                                });
                                this.marsTimes = arr;
                            }

                            this.ModelAction.code = med.actionCode;
                            this.ModelAction.description = med.actionDescription;

                            this.ModelRoute.code = med.routeCode;
                            this.ModelRoute.description = med.routeDescription;

                            this.ModelWhen.code = med.whenSigCode;
                            this.ModelWhen.description = med.whenSigDescription;

                            this.ModelReason.code = med.reasonCode;
                            this.ModelReason.description = med.reasonDescription;

                            this.ModelDiagnosis.code = med.diagCode;
                            this.ModelDiagnosis.description = med.diagDescription;

                            this.ModelIndication.code = med.indicationCode;
                            this.ModelIndication.description = med.indicationDescription;

                            this.ModelPrescriber.code = med.prescriberCode;
                            this.ModelPrescriber.name = med.prescriberName; 

                            this.Medication = med;
                            this.frecuencyMode = med.mode;
                            this.isSigned = med.isSigned;

                            this.CreatedBy = this.$t('Medication.CreatedBy') +': '+med.employeeName+' '+med.dateTimeCreated+  '   ' + '<span class="highlightInTitle blueTitle">'+ this.$t('Medication.internalStatus.'+ this.Medication.internalStatus) +'</span>'  ;  
                        }
                        this.$emit('load', false); 
                    })
                    .catch((error) => {
                        this.$emit('load', false); 
                        if (!error.response) {
                            this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                        } else {
                            this.showMessage(this.$t('Medication.Medications'),error,"error");
                        }
                    });
            }else{
                this.CreatedBy = this.$t('Medication.CreatedBy') +': '+Utils.strToTitleCase(StorageService.getName()) + '   ' + '<span class="highlightInTitle blueTitle">'+ this.$t('Medication.internalStatus.NEW')+'</span>';             
            }
        },

        getPharmacyOptions(){
            this.$emit('load', true);             
            PharmacyOptions
                .getPharmacyOptions()
                .then((response) => {
                    if(response.status == '200' && response.data && response.data.length > 0){                
                        this.PharmacyOption = response.data[0];
                    }
                    this.$emit('load', false);
                })
                .catch((error) => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });        
        },

        PrescriberExist(code){    
            for(var i = 0; i < this.PrescriberList.length; i++){
                if(this.PrescriberList[i].code === code){
                    return true;
                }
            }
            return false;
        },

        async getPrescribers(){
            this.$emit('load', true);              
            await MedicationService
                .getPrescribers()
                .then((response) => {
                    if(response.status == '200' && response.data){                        
                        this.PrescriberList = response.data;
                    }
                    this.$emit('load', false);
                })
                .catch((error) => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });        
        },

        getRouteName(){
            this.$emit('load', true);   
            var param = {
                CodeType:"ROUTE",
                GroupCreation:"SIG"
            };     
            GlobalService
                .getCustomGlobalCodes(param)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.RouteNameList = response.data; 
                        this.RouteNameList.forEach(element => {           
                            element.code = element.description;                 
                        });
                    }
                    this.$emit('load', false);
                })
                .catch((error) => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });        
        },

        getAction(){
            this.$emit('load', true);   
            var param = {
                CodeType:"ACTION",
                GroupCreation:"SIG"
            };     
            GlobalService
                .getCustomGlobalCodes(param)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.ActionList = response.data;    
                        this.ActionList.forEach(element => {           
                            element.code = element.description;                
                        });
                    }
                    this.$emit('load', false);
                })
                .catch((error) => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });        
        },

        getReason(){
            this.$emit('load', true);   
            var param = {
                CodeType:"REASON",
                GroupCreation:"SIG"
            };     
            GlobalService
                .getCustomGlobalCodes(param)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.ReasonList = response.data;    
                        this.ReasonList.forEach(element => {           
                            element.code = element.description;                
                        });
                    }
                    this.$emit('load', false);
                })
                .catch((error) => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });        
        },

        getWhen(){
            this.$emit('load', true);   
            var param = {
                CodeType:"WHEN",
                GroupCreation:"SIG"
            };     
            GlobalService
                .getCustomGlobalCodes(param)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.WhenList = response.data; 
                        this.WhenList.forEach(element => {           
                            element.code = element.description;
                        });        
                    }
                    this.$emit('load', false);
                })
                .catch((error) => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                    this.$emit('load', false);
                });        
        },

        getOrderType(){   
            var param = {
                CodeType:"ORDERTYPE",
                GroupCreation:null
            };     
            GlobalService
                .getCustomGlobalCodes(param)
                .then((response) => {
                    if(response.status == '200' && response.data){
                        this.OrderTypeList = response.data;              
                    }
                })
                .catch((error) => {
                    if (!error.response) {            
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                        this.showMessage(this.$t('Medication.Medications'),error,"error");
                    }
                });        
        },

        ChangeFilter(m){      
            this.modelFilter = m;
        },

        beforeWindowUnload(e) {
                if (this.pendingChanges) {
                    e.preventDefault()
                    e.returnValue = ''	
                }
        },

        async SendCopyOrder(stopPreviousPresc){
            if (this.PharmacyOption && this.PharmacyOption.copyActivePresc){
                await this.getPrescriptionCurrent(stopPreviousPresc ? 'COPY_DC' : 'COPY')
            
                if (this.IsPrescriptionCurrent) {
                    this.$bvModal
                        .msgBoxConfirm(this.$t('Medication.MsgPrescriptionCurrent'), {
                            title: this.$t('Medication.Medications'),
                            okTitle: this.$t('Shared.Continue'),
                            cancelTitle: this.$t('Shared.BtnCancel'),
                            footerClass: 'p-2',
                            titleClass: 'modal-title modal-type-warning',
                            id:'modal_delete_vitals'
                        })
                        .then(value => {
                            if(value) {
                                this.setCopyOrder(stopPreviousPresc)
                            }
                        })
                } else {
                    this.setCopyOrder(stopPreviousPresc)
                }
            } else {
                    this.setCopyOrder(stopPreviousPresc)
            }
        },

        setCopyOrder(stopPreviousPresc) {
            const params = {
                "oldRecordID": this.$route.params.recId,
                "admissionRecordID": this.isShedulerDB ? this.currentAppointmentDB && String(this.currentAppointmentDB.recId) : this.$route.params.recordid,
                "stopPreviousPresc": stopPreviousPresc
            }
            MedicationService
                .CopyOrdeer(params)
                .then((response) => {
                    if(response.status == '200'){
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.MsgOrderCopied'));
                        const orderId = response.data;
                        setTimeout(() => {
                            this.GotoNewOrder(orderId);
                        }, 50)
                    }           
                    this.$emit('load', false);                  
                })
                .catch((error) => {   
                    if (!error.response) {
                        this.showMessage(this.$t('Medication.CopyOrder'),this.$t('Msg.NotAbleToInsert'),"error");
                    } else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Medication.CopyOrder'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('Medication.CopyOrder'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Medication.CopyOrder'),error.response.data.errorMessage,"error");
                    } 
                    this.$emit('load', false);
                }); 
        },

        InactiveOrders() {
            const params = {
                "recordIDList": [ this.$route.params.recId ]
            }
            
            MedicationService
                .InactiveOrders(params)
                .then((response) => {
                    if(response.status == '200'){
                        this.GotoMedicationList();
                        
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Medication.MsgOrderInactive'), {
                                title: this.$t('Medication.Medications'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                        , 50); 
                    }     
                    this.$emit('load', false);                        
                })
                .catch((error) => {   
                    if (!error.response) {
                        this.showMessage(this.$t('Medication.InactiveOrder'),this.$t('Msg.NotAbleToInsert'),"error");
                    } else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Medication.InactiveOrder'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('Medication.InactiveOrder'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Medication.InactiveOrder'),error.response.data.errorMessage,"error");
                    } 
                    this.$emit('load', false);
                }); 
        },

        CopyOrder() {
            this.$emit('load', true);
            this.SendCopyOrder(false)
        },

        CopyDCOrder() {
            this.$emit('load', true);
            this.SendCopyOrder(true)
        },

        onChangeStopDateTime() {
            
        },

        setUsageDefault() {
            if (this.RegistrationInfo && this.RegistrationInfo.status == 'Inactive') 
                this.Medication.usage = 'Home'
            else if (this.RegistrationInfo && this.RegistrationInfo.status == 'Active' &&  this.RegistrationInfo.isInpatient == 'Y')
                this.Medication.usage = 'Internal'
            else
                this.Medication.usage = 'Home'
        },

        closeParamRepWindow() {
            this.isReportVisible = false
            this.recNoToReport = ''
        },

        async getPrescriptionCurrent(Action) {
            const params = {
                "PatientCode": this.$route.params.id,  
                "DrugCode": this.Medication.drugCode,
                "Mode":  !this.Mode ? this.frecuencyMode : this.Medication.mode, 
                "RecordId": this.$route.params.recId, 
                "Action": Action, 
                "StartDateTime": !this.Mode ? Utils.formatterToISO(this.startDateTime, Utils.getDateTimeFormat()) : Utils.formatterToISO(this.Medication.startDateTime, Utils.getDateTimeFormat()), 
                "EndDateTime": !this.Mode ? Utils.formatterToISO(this.endDateTime, Utils.getDateTimeFormat()) : Utils.formatterToISO(this.Medication.endDateTime, Utils.getDateTimeFormat()),
                "Day": this.Medication.day,
            }

            await MedicationService
                    .IsPrescriptionCurrent(params)
                    .then((response) => {
                        if(response.status == '200'){
                            this.IsPrescriptionCurrent = response.data 
                        }            
                        this.$emit('load', false);                 
                    })
                    .catch((error) => {   
                        if (!error.response) {
                            this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),this.$t('Msg.NotAbleToInsert'),"error");
                        } else if(error.response.status === 400 ){
                            this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),error.response.data.errorMessage,"error");
                        } else if(error.response.status === 500){
                            this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),this.$t('Msg.ThereWereSomeError'),"error");
                        } else{
                            this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),error.response.data.errorMessage,"error");
                        } 
                        this.$emit('load', false);
                    });

        },

        async getDefaultDoctor() {
            await PatientService
                    .getDefaultDoctor(this.$route.params.id)
                    .then((response) => {
                        if(response.status == '200'){
                            this.defaultDoctor = response.data
                            if(this.PrescriberExist(this.defaultDoctor.doctorCode)){
                                this.ModelPrescriber.code = this.defaultDoctor.doctorCode;
                                this.ModelPrescriber.name = this.defaultDoctor.doctorName;
                            }
                        }          
                        this.$emit('load', false);                   
                    })
                    .catch((error) => {   
                        if (!error.response) {
                            this.showMessage(this.$t('Medication.DefaultDoctor'),this.$t('Msg.NotAbleToInsert'),"error");
                        } else if(error.response.status === 400 ){
                            this.showMessage(this.$t('Medication.DefaultDoctor'),error.response.data.errorMessage,"error");
                        } else if(error.response.status === 500){
                            this.showMessage(this.$t('Medication.DefaultDoctor'),this.$t('Msg.ThereWereSomeError'),"error");
                        } else{
                            this.showMessage(this.$t('Medication.DefaultDoctor'),error.response.data.errorMessage,"error");
                        } 
                        this.$emit('load', false);
                    });
        },

        async setDefaultDoctor() {
            if (this.PharmacyOption && this.PharmacyOption.usePatientDoctorAsPrescriber) {
                await this.getDefaultDoctor()
            } else {
                if(this.PrescriberExist(this.$store.state.employeeCode)) {
                    this.ModelPrescriber.code = this.$store.state.employeeCode;
                    this.ModelPrescriber.name = Utils.strToTitleCase(StorageService.getName());
                }       
            }
        },

        setVisibilityInactiveOrder() {
            let visibility = false

            if (this.Mode && this.Medication.rxStatus != 'Inactive')
                if (this.Medication.internalStatus == 'MARSCREATED' || this.PharmacyOption.inactivePrescAtAnyStatus)
                    visibility = true
            
            return visibility
        },

        async getCurrentAppointment() {
            this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
            this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
            this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
        },

        async GetPatientByCode(){
            let infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0){
                const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

                if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age;
                if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
            }
        },

        onSignature(value){  
            this.TypePassWord = 'Sign';       
            this.EmployeeCodeSelected = this.ProviderModel.therapistCode;
            this.showSignPassword = value;
        },

        onModalPasswordResult(result) {
            if(result.type === 'Sign'){
                if(result.Event === "Ok"){
                    if(result.status !== true) {
                        this.showMessage(this.$t('Medication.VerifyPassword'),result.message,"error");   
                        this.isSigned = false;                  
                    }                  
                } else {
                    this.isSigned = false;
                }  
            }
            this.showSignPassword = false;
        },

        getEmployeeProvider(){
            const UserId = LocalStorage.getUserId();
            EmployeeService
                .getEmployees(UserId)
                .then((response) => { 
                    if(response.data){             
                        let result = response.data;                    
                        for(var i = 0; i < result.length; i++) {
                            if (this.EmployeeCode == result[i].code) {
                                this.ProviderModel.therapistCode = this.EmployeeCode;
                                this.ProviderModel.therapistName = LocalStorage.getName();
                                this.ProviderModel.requestSigCreateMed = result[i].requestSigCreateMed;
                                break;
                            }
                        }
                    }             
                })
                .catch((error) => {
                    this.$emit('load', false);
                        if (!error.response) {
                            this.showMessage(this.$t('Medication.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                        } else if (error.response.status === 400) {
                            this.showMessage(this.$t('Medication.eProvider'), error.response.data.errorMessage, "error");
                        } else if (error.response.status === 500) {
                            this.showMessage(this.$t('Medication.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                        } else {
                            this.showMessage(this.$t('Medication.eProvider'), error.response.data.errorMessage, "error");
                        }
                });
        },
    },

    async mounted(){ 
        this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        this.$emit('getBtnRightMenu', this.OptionsRightMenu);    
        this.isShedulerDB = this.$route.name.includes('Appointments-medications-add') || this.$route.name.includes('Appointments-medications-details')
        let usageIsReadOnly = true;
        let usage = 'Home';
        if (this.isShedulerDB) {
            await this.getCurrentAppointment()
            await this.GetPatientByCode();
            
            if (this.currentAppointmentDB && this.currentAppointmentDB.admissionRecordId) {
                this.RegistrationInfo = await this.getRegistrationInfoTitle(this.currentAppointmentDB.admissionRecordId);
            }
        } else { 
            this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
            const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

            if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
            if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
            if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
            if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
        }
        await this.getPrescribers();
        await this.getMedicationByRecId();
        if (this.RegistrationInfo && this.RegistrationInfo.recordId) {
            const { allowInternalPrescriptions, dateTo, isInpatient } = this.RegistrationInfo;
            const isActive = dateTo == null;

            if (isActive && (isInpatient == 'Y' || (isInpatient == 'N' && allowInternalPrescriptions))) {
                usageIsReadOnly = false;
                usage = 'Internal';
            }
        }
        
        this.usageIsReadOnly = usageIsReadOnly;
        if(!this.Mode){
            setTimeout(() => {
                this.Medication.rxStatus = "Active";
                this.Medication.usage = usage;
            }, 50, this);
            
            this.getDateTime();
            this.setUsageDefault()
            await this.setDefaultDoctor()
        } else {
            setTimeout(() => {
                this.Medication.usage = usage;
            }, 50, this);
            this.isReadOnly = !this.Medication.canEdit;
        }
    },

    async created () {
        this.EmployeeCode = this.$store.state.employeeCode; 
        await this.getPharmacyOptions();

        this.getRouteName();
        this.getAction();
        this.getWhen();
        this.getReason();
        this.getOrderType();
        this.getDiagnosisByPatientCode();
        this.getIndicationsList();
        this.getEmployeeProvider()
        EventBus.$on("onChanged", function (e) {       
            this.pendingChanges = e.Changed; 
            this.checkUserAction();        
        }.bind(this));
        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    beforeDestroy () {       
        window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
} 
</script>