<template>
<div v-bind:style="style" class="cutescroll">
     <b-row>
        <div :class="'col-md-'+ColumnSize" v-for="check in Options" :key="check">
          <b-form-checkbox-group
             id="checkbox-group-2"
             v-model="value"           
             name="flavour-2"
             v-on:input="onInput">
            <b-form-checkbox            
                size="sm"
                :value="check"  
                :disabled="isReadOnly">
                {{check}}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>         
      </b-row>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus.js';
export default {
  name: 'MdCheckListBox',
   props: {
     Label:         String,
     isReadOnly:    Boolean,
     value:         Array,
     CheckedValue:  String,
     UncheckedValue:String,
     TransDate:     String,
     Options:       Array,
     ColumnSize:    Number,
     Tag:           String 
   },

  data(){
      return{
        ControlMax:1,
        selected: this.value,    
        CheckBoxList:[],
        Required: null,
        style:{
            overflowY:'auto',
            overflowX: 'hidden',
            height: '150px',
            padding: '5px 12px'
        },
      }
  },
  methods:{
    InitializeVariables(){
      this.CheckBoxList = this.Options;
    },

    OnChange(value){
      this.$emit('change', value);    
      this.$emit('input', value);
      this.Required = value.length === 0 && this.$attrs.control.isRequired ==='Y' ? false : null;
    },

    onInput(){
      var changed = {
        "Changed":true,
        "ComponentName":this.Label,
        "Tag": this.Tag
      }
      EventBus.$emit('onChanged', changed);
    }
  },

  mounted(){    
    this.InitializeVariables();   
  }
};
</script>


