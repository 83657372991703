<template>
  <div id="m-layout" :class="layoutStatus" ref="mlayout">
    <WhatsNew v-if="!this.Loading" />
    <MdTextSpeech v-if="!this.Loading"/>
    <div id="m-container" ref="mcontainer" class="m-layout m-container" @click="closeMenuClick()">
      <!-- CONTAINER START -->
      <div class="m-pages">
        <div class="m-pagecontent">
          <router-view
            :key="$route.fullPath"
            @getVisibleMenu="onVisibleRightMenu($event)"
            @onMenuRightBarGroupNotes="onMenuRightBarGroupNotes($event)"
            @onMenuRightBarCNContainer="onMenuRightBarCNContainer($event)"
            @onMenuRightBarIncidentWorkflow="onMenuRightBarIncidentWorkflow($event)"
            @load="OnLoading($event)"
            @getInfoPatient="OnInfoPatient($event)"
          />
        </div>
      </div>
      <!-- CONTAINER END -->
    </div>
    <div id="m-rightmenu" class="m-layout m-rightmenu" @click="closeMenuClick()">
      <!-- COMPONENT RIGHT MENU START -->
      <RigthBar
        v-if="this.btnvisible"
        v-bind:ItemList="this.ItemList"
        v-bind:InfoPatient="this.InfoPatient"
        v-bind:itemdisabled="this.itemdisabled"
        v-bind:PatientCode="this.PatientCode"
        v-bind:active_el="this.active_el"
      />
      <GroupNotesRigthBar
        v-if="this.btnGroupNotesvisible"
        v-bind:ItemList="this.ItemListGroupNotes"
        v-bind:InfoPatient="this.InfoPatient"
        v-bind:itemdisabled="this.itemdisabled"
        v-bind:PatientCode="this.PatientCode"
      />
      <ClinicalNotesContainerRigthBar
        v-if="this.btnCNContainervisible"
        v-bind:ItemList="this.ItemListGroupNotes"
        v-bind:InfoPatient="this.InfoPatient"
        v-bind:itemdisabled="this.itemdisabled"
        v-bind:PatientCode="this.PatientCode"
      />
      <IncidentWorkflowRightBar
        v-if="this.btnIncidentWorkflowvisible"
        v-bind:ItemList="this.ItemListIncidentWorkflow"
        v-bind:InfoPatient="this.InfoPatient"
        v-bind:itemdisabled="this.itemdisabled"
        v-bind:PatientCode="this.PatientCode"
      />
      <!-- RIGHT MENU END -->
    </div>
    <div id="m-leftmenu" class="m-layout m-leftmenu" @click="closeMenuClick()">
      <!-- COMPONENT LEFT MENU START -->
      <div id="leftLoader">
        <span><div class="ll01"></div></span>
        <span><div class="ll02"></div></span>
        <span><div class="ll03"></div></span>
      </div>
      <div id="searchMenu"><HeaderLeftMenu /></div>
      <div id="leftMenu" class="w-100 h-100">
        <MdMenu @getActionFromItems="actionFromMenuItems($event)" />
      </div>
      <!-- LEFT MENU END -->
    </div>
     <!-- COMPONENT BOTTOM BAR START -->
    <BottomBar 
      v-show="!this.Loading"
      v-bind:Name="this.Name"
      v-bind:btnvisible="this.btnvisible"
      v-bind:btnGroupNotesvisible="this.btnGroupNotesvisible"
      v-bind:btnCNContainervisible="this.btnCNContainervisible" 
      v-bind:btnIncidentWorkflowvisible="this.btnIncidentWorkflowvisible"
      v-bind:favButtonAtion="this.favButtonAtion"
      
    />
    <!-- BOTTOM BAR END -->
 
    <!-- SPEECH BAR -->
    <div id="speechBar" class="speechBar">
      <button id="stopSpeech" v-on:click="stopSpeechOrSpeechToText()">
        <i class="ikon med-stop"></i> <span id="speechBTNtitle">{{ speechBarLabel }}</span>
      </button>
    </div>
    <!-- SPEECH BAR END -->

    <Loading v-bind:IsLoading="this.Loading" />
    <!-- COMPONENT SCREENSAVER START -->
    <!-- https://codepen.io/sjrh/pen/NNBjKy -->
    <Screensaver ref="Screensaver" />
    <!-- SCREENSAVER END -->
    <!-- REVIEW BANNER START -->
    <a href="https://search.google.com/local/writereview?placeid=ChIJNUsu_EKo2YgRWh4LKOzQWF4" target="_blank">
      <div class="googleReviewBanner" id="googleReviewBanner">
        <div id="gRMessage">{{ Name }}, {{$t('Layout.InfoText')}}</div>
      </div>
    </a>
    <!-- REVIEW BANNER END -->
  </div>
</template>

<script>
import RigthBar from "@/components/template/rigthbar/RigthBar";
import GroupNotesRigthBar from "@/components/pages/groupnotes/GroupNotesRightBar";
import ClinicalNotesContainerRigthBar from "@/components/pages/clinicalNotesContainer/ClinicalNotesContainerRightBar";
import Loading from "@/components/template/loading/Loading";
import Quickhelp from "@/services/quickhelp.service";
import Utils from "../../../common/utils";
import WhatsNew from "@/components/pages/whatsnew/whatsNew";
import StorageService from "@/services/storage.service";
import BottomBar from "@/components/template/bottombar/BottomBar";
import Screensaver from "@/components/template/screensaver/Screensaver";
import { EventBus } from '@/event-bus.js';

export default {
  name: "Layout",
  components: {
    RigthBar,
    GroupNotesRigthBar,
    ClinicalNotesContainerRigthBar,
    Loading,
    WhatsNew,
    BottomBar,
    Screensaver
  },
  data() {
    return {
      layoutStatus: "",
      btnvisible: false,
      btnGroupNotesvisible:false,
      btnCNContainervisible:false,
      btnIncidentWorkflowvisible:false,
      Loading: false,
      ItemList: [],
      ItemListGroupNotes:[],
      ItemListCNContainer:[],
      ItemListIncidentWorkflow:[],
      itemdisabled: false,
      PatientCode: "",
      IsModeEdit: false,
      InfoPatient: {},
      Infolayout: {
        L0R0: "L0R0",
        L1R0: "L1R0",
        L0R1: "L0R1",
        L1R1: "L1R1",
      },
      Name: "",
      resizeObserver: null,
      qHelpReferenceList: [],
      dataArray:[],
      speechBarLabel: '',
      favButtonAtion:false,
      active_el: ""
    };
  },
  created() {
    EventBus.$on("UpdateBtn", function (hide) {   
      if(!hide){           
        this.btnvisible = false;
      }
    }.bind(this));

    EventBus.$on("syncLayout", function (syncValue) {   
      this.layoutStatus = syncValue
    }.bind(this));

    EventBus.$on("speechBarLabel", function (value) {   
      this.speechBarLabel = value;
    }.bind(this));
  },
 
  methods: {
    closeMenuClick() {
      EventBus.$emit('closeMenuLayout', true);
    },

    getName() {
      this.Name = Utils.strToTitleCase(StorageService.getName());
    },

    OnLoading(load) {
      this.Loading = load;
    },

    onVisibleRightMenu(RightMenu) {
        this.active_el = RightMenu.active_el;
        this.btnvisible = RightMenu.btnvisible;     
        this.btnGroupNotesvisible = RightMenu.btnGroupNotesvisible;
        this.btnIncidentWorkflowvisible = RightMenu.btnIncidentWorkflowvisible;
        this.btnCNContainervisible = false;
        this.ItemList = RightMenu.ItemsList; 
        this.itemdisabled = RightMenu.itemdisabled;
        this.PatientCode = RightMenu.PatientCode;
        this.favButtonAtion = RightMenu.favButtonAtion;

      this.$nextTick(() => {
        this.loadLayoutStore();
      });
    },

    onMenuRightBarGroupNotes(RightMenu){
     // this.btnvisible = RightMenu.btnvisible;
     this.$store.commit('setItemListGroupNotes',RightMenu.ItemsList);
      this.btnGroupNotesvisible = RightMenu.btnGroupNotesvisible;      
     // this.ItemListGroupNotes = RightMenu.ItemsList;
      this.ItemListGroupNotes = this.$store.state.itemListGroupNotes; 
      this.itemdisabled = RightMenu.itemdisabled;
      this.PatientCode = RightMenu.PatientCode;
      this.favButtonAtion = RightMenu.favButtonAtion;     
      this.$nextTick(() => {
        this.loadLayoutStore();
      });
    },

    onMenuRightBarCNContainer(RightMenu){
      this.btnvisible = false; 
      this.$store.commit('setItemListCNContainer',RightMenu.ItemsList);
      this.btnCNContainervisible = RightMenu.btnCNContainervisible;      
      this.ItemListCNContainer = this.$store.state.itemListCNContainer; 
      this.itemdisabled = RightMenu.itemdisabled;
      this.PatientCode = RightMenu.PatientCode;
      this.favButtonAtion = RightMenu.favButtonAtion;     
      this.$nextTick(() => {
        this.loadLayoutStore();
      });
    },

    onMenuRightBarIncidentWorkflow(RightMenu) {
      this.btnGroupNotesvisible = RightMenu.btnGroupNotesvisible;
      this.btnCNContainervisible = RightMenu.btnCNContainervisible;
      this.btnIncidentWorkflowvisible = RightMenu.btnIncidentWorkflowvisible;
      this.ItemListIncidentWorkflow = RightMenu.ItemsList; 
      this.itemdisabled = RightMenu.itemdisabled;
      this.PatientCode = RightMenu.PatientCode;
      this.favButtonAtion = RightMenu.favButtonAtion;

      this.$nextTick(() => {
        this.loadLayoutStore();
      });
    },

    actionFromMenuItems(item) {
      if (item && item.child.length == 0) {
        this.$store.commit("setlayoutStatus", this.Infolayout.L0R0);
        EventBus.$emit('syncButtons', this.$store.state.layoutStatus);
      }
    },

    OnInfoPatient(Info) {
      this.InfoPatient = Info;
    },

    loadLayoutStore() {
      const isMovile = this.$store.state.isMovile;
      let layoutStatus = this.$store.state.layoutStatus;
      if (isMovile || this.favButtonAtion) {
          this.$store.commit("setlayoutStatus", this.Infolayout.L0R0);
      } else if (layoutStatus && 
                (this.btnvisible || this.btnGroupNotesvisible || 
                  this.btnIncidentWorkflowvisible || this.btnCNContainervisible)) {
          this.layoutStatus = layoutStatus
      } else {
          this.$store.commit("setlayoutStatus", this.Infolayout.L1R0);
      }
      this.layoutStatus = this.$store.state.layoutStatus; 
      EventBus.$emit('syncButtons', layoutStatus);
    },

    getUserQhReference() {
      Quickhelp.getQhUserPreference(StorageService.getUserId())
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const doNotShowQuickHelp = response.data[0].doNotShowQuickHelp;
            if (!doNotShowQuickHelp) {
              this.$nextTick(() => {
                setTimeout(() => this.$bvModal.show("bv-modal-whatsnew"), 3000);
              });
            }
          } else {
            this.$nextTick(() => {
              setTimeout(() => this.$bvModal.show("bv-modal-whatsnew"), 3000);
            });
          }
        })
        .catch((error) => {
          if (error.errorMessage) {
            this.showMessage(this.$t('UserPreferences.UPTitle'), error, "error");
          }
        });
    },
   
    onResize() {
      if (this.$refs.mlayout != undefined) {
        this.$emit("resize", this.$refs.mlayout.offsetWidth);
        this.$store.commit("setMovile", false);
        if (this.$refs.mlayout.offsetWidth <= 767) {
          this.$store.commit("setMovile", true);
        }
      }
    },

    /* REVIEW BANNER START */
    // --8<--------remove this comment------------
    // PLEASE, CHECK THIS OUT!!!
    /*  The idea was to generate a random number
        and show the banner only if a number was hit.
        Obviously, you can change this to something
        less random. And take it from JS to Vue :)
        At the end, all you need is to set the
        animate class to the div to show and hide it.
    */
   // --8<--------remove this comment------------
    showReview(){
        const randomNumber = Math.floor(Math.random() * 501);
        if (randomNumber == 100) {
          let gRB = document.getElementById('googleReviewBanner');
          gRB.classList.add('animate');
        }
    },
    stopSpeechOrSpeechToText() {
      this.speechBarLabel == 'stop recording'
              ? this.$root.$refs.MdTextSpeech.stopSpeechToText()
              : this.$root.$refs.MdTextSpeech.stopSpeech();
    },
  },
   
  async mounted() {
    // this.showReview();
    /* REVIEW BANNER END */
    this.ItemListGroupNotes = this.$store.state.itemListGroupNotes;
    this.ItemListIncidentWorkflow = [] 
    this.resizeObserver = new ResizeObserver(this.onResize).observe(
      this.$refs.mlayout
    );
    this.getName();
    this.loadLayoutStore();
    this.getUserQhReference();
  },
  updated() {
    this.getName();
  }
};

</script>

<style>

@import "../../../assets/medez/ikons.css";
@import "../../../assets/css/mdi/css/materialdesignicons.min.css";
@import "../../../assets/css/font-awesome/css/font-awesome.min.css";
@import "../../../assets/css/simple-line-icons/css/simple-line-icons.css";
@import "../../../assets/css/style.css";
@import "../../../assets/css/layout.css";
 
</style>
