<template>
   <div>
    <b-modal size="xl" :id="ModalId" :visible="true" scrollable centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalcategory" @close="this.emitEventCancel" @ok="this.emitEventOK" @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
    <b-row>        
        <b-col lg="6" md="6" sm="12">         
          <MdLookup 
            v-bind:ModelCode="categoryItems.categoryCode"
            v-bind:ModelDescription="categoryItems.categoryName"
            v-bind:Label="$t('Shared.Category')"
            v-bind:RefCode="'Category'"   
            v-bind:Api="'IncidentSetups?IsActive=true'"
            v-bind:FieldCode="'code'"
            v-bind:FieldDescription="'description'"
            v-bind:isRequired="true"
            :SearchTitle="$t('Shared.Category')"
            :GetValues="getValues"
            :InvalidFeedback="$t('ModalCategory.fBakInvCatMsg')"
            :SearchFieldCode="'Code'"
            :AuxFields="AuxFields"
            :isValid="isValidCategory"/>
        </b-col>
        <b-col md="2" lg="2" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncIsPrimay')"
            v-bind:Name="'IsPrimary'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="categoryItems.isPrimary"
            v-on:change="ChangeIsPrimary"/>
        </b-col>
        <b-col md="4" lg="4" sm="12">
          <MdSelect
            v-bind:Label="$t('Incident.IncSeverity')"
            v-bind:Options="SeverityList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:isRequired="categoryItems.isPrimary" 
            v-bind:FeedBackText="$t('ModalCategory.fBakSevReqMsg')"   
            v-bind:isValid="isSeverityValid"   
            v-bind:ModelArray="severity"
            v-bind:AllowEmpty="false"                       
            v-bind:Multiple="false"
            :Input="ChangeDropDownSeverity"
            v-bind:IsDisabled="severityRO"/>
        </b-col>     
      </b-row>

      <b-row> 
          <b-col lg="6" md="6" sm="12">  
              <b-row>
                <b-col lg="12" md="12" sm="12"> 
                <MdTitle v-bind:Text="$t('Incident.IncCategoryType')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                <MdCheckListBox2 
                  v-model="IncidentCodes"            
                  v-bind:Options="CategoryOptions"
                  v-bind:Height="'150px'"
                  v-bind:OnChangeProp="OnChangeCategory"
                  v-bind:columnCount="1"
                  v-bind:isRequired="true"
                  :isValid="isValidCategoryType"/>
                </b-col>
              </b-row> 
              <b-row>
                <b-col lg="12" md="12" sm="12"> 
                  <MdTitle v-bind:Text="$t('Shared.Description')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                  <MdRichEdit
                  v-model="categoryItems.incidentDescription"
                  v-bind:Height="'400px'"
                  v-bind:id="'edit_categorydescription'"
                  v-bind:HideToolBar="true"
                  v-bind:StartupFocus="false"
                  v-bind:isRequired="true"
                  :isValid="isValidDescription"/>
                </b-col>
              </b-row> 
          </b-col>  
          <b-col lg="6" md="6" sm="12">     
              <b-row>
                <b-col lg="12" md="12" sm="12"> 
                  <MdTitle v-bind:Text="$t('Incident.IncInterventions')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                  <MdCheckListBox2 
                    v-model="Interventions"
                    v-bind:OnChangeProp="OnChangeIncidentIntervention"
                    v-bind:Options="InterventionsOptions"
                    v-bind:Height="'300px'"
                    v-bind:columnCount="2"
                    :textField="'name'"
                    :valueField="'code'"/> 
                </b-col>                                   
              </b-row> 
              <b-row>
                <b-col lg="12" md="12" sm="12"> 
                  <MdTitle v-bind:Text="$t('Incident.IncOInterventions')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                  <MdRichEdit
                  v-model="categoryItems.intervention"
                  v-bind:Height="'150px'"
                  v-bind:id="'edit_categoryotherinterventions'"
                  v-bind:HideToolBar="true"
                  v-bind:StartupFocus="false"/> 
                </b-col>                                   
              </b-row> 
          </b-col>  
      </b-row>          
      
    </b-modal>   
   </div>
</template>

<script>
import Utils from '@/common/utils'
import ShowMessage from '@/components/messages/ShowMessage.js'
import IncidentReportInterventionsSetups from '@/services/incidentreportinterventionssetups.service'
//import IncidentReportSeveritySetup from '@/services/incidentreportseveritysetup.service'
import IncidentSetup from '@/services/incidentsetup.service'

export default {
  name: 'ModalCategory',
  mixins: [ShowMessage],  
  props: {
    ModalId: String,
    TitleWindow: String,    
    IncidentID: String,
    ModeEdit: Boolean,
    IntervCode: String,
    CategoryItems: Object,
    minIncidentDescription:Number
  },
  
  data(){
    return {
      IsPrimary: false,
      isSeverityValid:true,
      categoryname: '',
      categorytypes: null,
      CategoryOptions: [],
      categorydescription: '',
      InterventionsOptions: [],
      categoryotherinterventions: '',
      categoryItems: {
        categoryCode:'',
        categoryName: '',
        isPrimary: false,
        incidentDescription:'',
        incidentTypes:[],
        interventions: [],
        intervention: '',
        severityCode: '',
        severityName: ''
      },
      CategoryCode: '',
      CategoryDescription: '',
      AuxFields:['description'],
      IncidentCodes: [],
      isValidCategory: true,
      isValidDescription: true,
      isValidCategoryType: true,
      SeverityList:[],
      paramsSeverity: {
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Code: ''
      },
      severity: null,
      severityRO: true,
      Interventions: [],

    }
  },

  methods:{

    ChangeIsPrimary(value) {
      this.categoryItems.isPrimary = value
      this.categoryItems.severityCode = value ? this.categoryItems.severityCode : null
      this.categoryItems.severityName = value ? this.categoryItems.severityName : null
      this.showSeverity()
      if (value) 
        this.selectOnlyOneSeverity()
    },

    showSeverity() {
      if (this.categoryItems.isPrimary && this.categoryItems.categoryCode) {
        this.severityRO = false
      } else {
        this.severityRO = true
      }
    },

    OnChangeCategory(){

    },

    OnChangeIncidentIntervention(){

    },

    selectOnlyOneSeverity() {
      if (this.categoryItems.isPrimary && this.SeverityList && this.SeverityList.length == 1) {
        this.severity = this.SeverityList[0]
        this.categoryItems.severityCode = this.SeverityList[0].code
        this.categoryItems.severityName = this.SeverityList[0].name
      }
    },

    Message(min,text1,text2){
      return text1+" "+min+" "+text2; 
    },
    
    validateFields() {
    
      let valueDescription = this.categoryItems.incidentDescription ? this.categoryItems.incidentDescription.replace(/<[^>]*>?/g, '').trim() : "";
      let regex = /&(nbsp|amp|quot|lt|gt);/g;
      valueDescription = valueDescription.replace(regex, "").trim();
      this.isValidCategory = this.categoryItems.categoryCode ? true : false
      this.isValidDescription = this.categoryItems.incidentDescription != undefined && valueDescription.length > 0 ? true : false
      this.isValidCategoryType = this.IncidentCodes.length > 0 ? true : false;

      let validDescriptionMinValue = false;
      
      if(this.minIncidentDescription !== null && this.minIncidentDescription !== ""){
        validDescriptionMinValue = valueDescription.length >= this.minIncidentDescription;
      }else{
        validDescriptionMinValue = true;
      }
       
      this.isSeverityValid = (this.categoryItems.severityCode !== null && this.categoryItems.severityCode !== "") || (!this.categoryItems.isPrimary) ? true : false
      
      if (this.isValidCategory == false)
        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncProvideCategoryMsg'),"errorAutoHide");
      if (this.isValidCategoryType == false)
        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncAtLeastTypeMsg'),"errorAutoHide"); 
      if (this.isValidDescription == false)
        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncProvideDescMsg'),"errorAutoHide");
      if (this.isSeverityValid == false && this.categoryItems.isPrimary)
        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncProvideSeverityMsg'),"errorAutoHide");
      if(validDescriptionMinValue == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minIncidentDescription,this.$t('Incident.IncProvidDedesCMsg'),this.$t('Incident.IncProvidDedesCMsg1')) ,"errorAutoHide");
      }
      return this.isValidCategory && this.isValidDescription && this.isValidCategoryType && this.isSeverityValid && validDescriptionMinValue;
    },
      
    emitEventOK(bvModalEvent){
      if (this.validateFields()) {
        this.categoryItems.incidentTypes = this.IncidentCodes
        this.setInterventions()
        if (this.ModeEdit == false) {
          this.categoryItems.id = Utils.generateRecID()
        }               
        this.$emit('getCodeConditionOK',this.categoryItems);
      } else {       
        bvModalEvent.preventDefault()
      }      
    },
      
    emitEventCancel(){
      this.$emit('getCodeConditionCANCEL');
    }, 

    getValues: async function (value) {
      if(value.code) {
        this.categoryItems.categoryName = value.AuxFields[0]
        this.categoryItems.categoryCode = value.code
        this.IncidentCodes = []
        this.CategoryOptions = []
        this.InterventionsOptions = []
        await this.getIncidentSetup(value.code)
        this.selectOnlyOneSeverity()       
      } else {
        this.categoryItems.categoryName = ''
        this.categoryItems.categoryCode = ''
      }
      this.showSeverity()
    },

    populateIncidentCodes(arr) {
      this.IncidentCodes = [];
      arr.forEach(element => {
        this.IncidentCodes.push(element.replace(/"/g, ''))
      }); 
    },

    setInterventions() {
      this.categoryItems.interventions = []
      this.Interventions.forEach(element => {
        this.categoryItems.interventions.push(this.InterventionsOptions.find(el => el.code === element))
      });
    },

    populateInterventions(value) {
      let Interventions = []
      value.forEach(element => {
        Interventions.push(element.code)
      });
      this.Interventions = Interventions
    },

    async getInterventions(value) {
      let intervCode = value ? value : this.IntervCode
      await IncidentReportInterventionsSetups.getInterventions(intervCode)
        .then((response) => { 
          if(response.data){
            response.data.forEach(element => {
              this.InterventionsOptions.push({ name:element.description, code:element.code } )
            });
          }             
        })
        .catch((error) => {
          this.$emit("load", false);
            if (error.response) {
              this.showMessage(this.$t('Incident.IncReportInterMsg'),error,"error");
            }
        });
    },

    async getIncidentSetup(value) {
      let intervCode = value ? value : this.IntervCode
      await IncidentSetup.get(intervCode)
        .then((response) => { 
          if(response.data){
            var data = response.data;
            this.CategoryOptions = data.incidentTypes;
            this.SeverityList = data.severityList;
            this.InterventionsOptions = data.interventionList;

            if(this.CategoryOptions.length === 1){
              this.IncidentCodes.push(this.CategoryOptions[0]);
            }
          }             
        })
        .catch((error) => {
          this.$emit("load", false);
          if (error.response) {
            this.showMessage(this.$t('Incident.IncSetup'),error,"error");
          }
        });
    },

    ChangeDropDownSeverity(value) {
      if(value){
        this.severity = value
        this.categoryItems.severityCode = value.code
        this.categoryItems.severityName = value.name
      }      
    },

    loadSeverityValue(value) {
      this.severity = this.SeverityList.find(el => el.code === value)
    },
  },

  async mounted(){
    this.$refs.modalcategory.$on("hidden.bs.modal");

    if (this.ModeEdit) {
      await this.getIncidentSetup();   
      let categoryItems = {...this.CategoryItems}

      this.categoryItems.id = categoryItems.id
      this.categoryItems.categoryCode = categoryItems.categoryCode
      this.categoryItems.categoryName = categoryItems.categoryName
      this.categoryItems.isPrimary = categoryItems.isPrimary
      this.categoryItems.incidentDescription = categoryItems.incidentDescription
      this.categoryItems.incidentTypes = categoryItems.incidentTypes
      this.categoryItems.interventions = categoryItems.interventions
      this.categoryItems.intervention = categoryItems.intervention
      this.categoryItems.severityCode = categoryItems.severityCode
      this.categoryItems.severityName = categoryItems.severityName
      this.loadSeverityValue(this.categoryItems.severityCode)

      this.showSeverity()
      this.populateIncidentCodes(this.categoryItems.incidentTypes)
      this.populateInterventions(this.categoryItems.interventions)
    } 
  },
};
</script>