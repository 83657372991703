<template>
  <div>
    <div class="row">
        <div class="col-12">
          <MdTitle v-bind:Text="$t('claimbatchList.ClaimBatchSelection')" />
          <div class="row">
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
								v-bind:Label="$t('Shared.datefrom')"
								v-bind:TypeProp="'date'" 
								v-bind:Name="'DateFrom'"
								v-model="DateFrom"
								v-bind:Min="'1900-01-01'"
								v-bind:Max="'2200-12-31'"
                v-bind:OnPressEnter="Search"
							>                                     
							</MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdEdit 
								v-bind:Label="$t('Shared.dateTo')"
								v-bind:TypeProp="'date'" 
								v-bind:Name="'DateTo'"
								v-model="DateTo"
								v-bind:Min="'1900-01-01'"
								v-bind:Max="'2200-12-31'"
                v-bind:OnPressEnter="Search"
								>                                     
							</MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">         
              <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="PatientsList"
              v-bind:Model="PatientCodeModel"
              v-bind:FieldDescription="'patientName'"
              v-bind:FieldCode="'patientCode'"
              v-bind:Multiple="true"             
              :Input="ChangeDropDownPatients"/>
            </b-col>
             <b-col lg="3" md="3" sm="12">
               <MdSelect
                  v-bind:Label="$tc('Shared.Program',2)"
                  v-bind:Options="ProgramList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Multiple="true"
                  v-bind:Model="ProgramModel"  
                  :Input="ChangeDropDownPrograms"               
               />
             </b-col>
          </div>
          <div class="row">
            <b-col lg="3" md="3" sm="12">
              <MdSelect
                v-bind:Label="$tc('Shared.Therapist',2)"
                v-bind:Options="TherapistList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                v-bind:Model="TherapistModel"  
                v-bind:Multiple="true" 
                :Input="ChangeDropDownTherapist"             
              />
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdSelect
                v-bind:Label="$t('Shared.Therapies')"
                v-bind:Options="TherapiesList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                v-bind:Model="TherapiesModel"  
                v-bind:Multiple="true" 
                :Input="ChangeDropDownTherapy" 
              />
            </b-col>  
            <b-col lg="3" md="3" sm="12">
              <MdSelect
                  v-bind:Label="$tc('Insurance.Insurance')"
                  v-bind:Options="InsuranceList"
                  v-bind:FieldDescription="'insuranceName'"
                  v-bind:FieldCode="'insuranceCode'"
                  v-bind:Model="InsuranceModel"  
                  v-bind:Multiple="true" 
                  :Input="ChangeDropDownInsurance" 
               />
            </b-col>
          </div>
          <div class="row">
            <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('claimbatchList.CreateAClaim')"
                  v-bind:Name="'CreateAClaim'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="CreateAClaimForEveryServiceDay"                 
               />
            </b-col>
             <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('claimbatchList.BillAcrossMonth')"
                  v-bind:Name="'BillAcrossMonth'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="BillAcrossMonth"                 
               />
            </b-col>
             <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('claimbatchList.DisableCheck')"
                  v-bind:Name="'DisableCheckForPriorAuthorization'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="DisableCheckForPriorAuthorization"                 
               />
            </b-col>
            <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('claimbatchList.UseTherapyProvider')"
                  v-bind:Name="'UseTherapyProvider'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="UseTherapyProvider"                 
               />
            </b-col>
          </div>
          <div class="row">
            <b-col md="12">
              <MdButton
              v-bind:Label="$t('Shared.Search')"
              v-bind:Name="'btnSearchClaimBatch'"
              v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"              
              v-on:click="Search"
             />
            </b-col>
          </div>
        

    <div class="row">
      <div class="col-12 mt-2">
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        <div class="row">
          <div class="col-md-12">

<div class="flex-container">
              <div class="row">                
              
              <div class="col-md-3">
                 <MdCheckBox
                  v-bind:Label="$t('Shared.SelectAll')"
                  v-bind:Name="'SelectTableAll'"
                  v-bind:ClassProp="'AlignCheck mx-3'"
                  v-bind:CheckedValue="true"
                  v-bind:isReadOnly="this.items.length === 0"
                  v-bind:UncheckedValue="false"
                  v-model="UseSelectTableAll" 
                  v-on:change="onChangeSelectAll"                
               /> 
              </div>
              <div class="col-md-3 mt-3">
                <span class="tableSelectedItems"><span class="selItemsVal">{{ SelectedRecords }}</span> {{ $t('claimbatchList.item') }}<span v-if="SelectedRecords != 1">s</span> {{ $t('claimbatchList.selected') }}</span>
              </div>  
             <div class="col-md-6">
                       <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                      <div class="input-group">
                          <input 
                            type="text" 
                            class="form-control"
                            v-model="modelSearch"                          
                            @keyup.enter="FindinItems()"
                          >
                          <div class="input-group-append">
                              <MdButton 
                                v-bind:Name="'btnsearchinput'"
                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                v-bind:IconClass="'mdi mdi-magnify'"                               
                                v-on:click="FindinItems"
                                 >
                              </MdButton>
                          </div>
                      </div>
                  </div>
             </div>
                  
          
                                     
              </div>
          </div>
              <MdTablePaginationClient
              v-bind:fields="fields"           
              v-bind:items="items"
              v-bind:totalRows="totalRows"  
              v-bind:refTable="refTable"           
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"
              v-bind:SelectAll="UseSelectTableAll"
              v-bind:ShowDetails="true"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:pageOptions="pageOptions" 
              v-bind:viewIconEvent="ViewIcon"
              v-bind:Error="Error"
              v-bind:Warning="Warning"
              :onRowSelected="onRowSelected" 
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :RowClick="RowClicked"
              :perPageFunction="PerPageFunction">
            </MdTablePaginationClient>
          
  
            
          <MdButton v-if="this.permission.length > 0 && this.permission[0].roleCanAdd"
              v-bind:Label="$t('claimbatchList.Batch')"
              v-bind:Name="'btnBatchClaimBatch'"
              v-bind:ClassProp="'secondary mx-2 btn-login pull-right'"  
              v-bind:isReadOnly="SelectedRecords==0"         
              v-on:click="DoBatch"
             />
          </div>
        </div>
      </div>
    </div>
        </div>
    </div>   
     <ModalClaimDetail
        v-bind:TitleWindow="TitleDetail"  
        v-bind:AttendanceDetailList="AttendanceDetailListSelected"        
        @getCodeConditionOK="OnReturnData($event)"
        v-bind:ModalId="this.modalIdDetail">
      </ModalClaimDetail>

       <ModalClaimError
        v-bind:TitleWindow="TitleError"  
        v-bind:ErrorList="ErrorList"        
        @getCodeConditionOK="OnReturnData($event)"
        v-bind:ModalId="this.modalIdError">
      </ModalClaimError>

       <ModalClaimWarning
        v-bind:TitleWindow="TitleWarning"  
        v-bind:WarningList="WarningList"        
        @getCodeConditionOK="OnReturnData($event)"
        v-bind:ModalId="this.modalIdWarning">
      </ModalClaimWarning>
  </div>
</template>

<script>
//import EnumService from '@/services/enum.service'
import ClinicalService from '@/services/clinical.service'
import EmployeeService from '@/services/employee.service';
import ClaimBatch from '@/services/claimbatch.service';
import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import { EventBus } from '../../../event-bus.js';
import moment from 'moment'
import Utils from '@/common/utils'

export default {
   beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'ClaimBatch',
 mixins: [ShowMessage,DBFunctions],  
  data(){
      return {
        ClaimBatch:{

        },
        Menu : this.$store.state.leftMenu,
        permission: [],
        FieldsDateArr:['dateFrom','dateTo'],
        AttendanceDetailListSelected:[],
        ShowBtnBatch:false,
        ErrorList:[],
        WarningList:[],
        TitleDetail:"",
        TitleError:"",
        TitleWarning:"",
        SelectedRecords:0,      
        OldUnitNumber:0,
        OldtotalCharges:0,
        refTable: 'selectableTableClaimBatch',
        pageOptions: [5, 10, 15 ],
        modalIdDetail:"modalIdClaimDetail1",
        modalIdError:"modalIdClaimError",
        modalIdWarning:"modalIdClaimWarning",
        PatientCodeList:[],
        ProgramCodeList:[],
        ProgramCodeListBatch:[],
        TherapistCodeList:[],
        TherapistCodeListBatch:[],
        TherapyCodeList:[],
        TherapyCodeListBatch:[],
        InsuranceCodeList:[],
        InsuranceCodeListBatch:[],
        modelSearch:"",
        perPage: 10,
        SelectedPage : 0,
        totalRows: 0,
       // HeightTable:300,
        currentPage: 1,
        wasSelectedAll:false,
        CopyItems:[],
        items:[],
        OriginalSaved:[],             
        ShowMessageIsEmpty:false,
        SelectAll:false,
        PatientCodeModel:"",
        PatientDescription:"",
        ProgramList:[],        
        ProgramModel:"",
        TherapistList:[],
        TherapistModel:"",
        TherapiesList:[],
        TherapiesModel:"",
        InsuranceList:[],
        InsuranceModel:"",
        DateFrom:"",
        DateTo:"",
        CreateAClaimForEveryServiceDay:false,
        BillAcrossMonth:false,
        DisableCheckForPriorAuthorization:false,
        UseTherapyProvider:false,
        UseSelectTableAll:false,
        search:"",
        PatientsList:[],
        itemsSelected:[],
      
        fields: [       
        { key: 'patientName', sortable: true, label: this.$t('Shared.PatientName'), class: 'text-rigth' }, 
        { key: 'selected', sortable: true, label: this.$t('claimbatchList.colSelected'), class: 'text-rigth hidden' },         
        { key: 'patientCode', sortable: true, label: this.$t('Shared.PatientCode'), class: 'text-rigth' },
        { key: 'errorList', sortable: true, label: this.$t('claimbatchList.colErrorList'), class: 'text-rigth hidden' }, 
        { key: 'warningList', sortable: true, label: this.$t('claimbatchList.colWarningList'), class: 'text-rigth hidden' },        
        { key: 'dateFrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth' },
        { key: 'dateTo', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth' },
        { key: 'courseCode', sortable: true, label: this.$t('Shared.CPT'), class: 'text-rigth' },
        { key: 'unit', sortable: true, label: this.$t('claimbatchList.colUnits'), class: 'text-rigth' },
        { key: 'totalCharges', sortable: true, label: this.$t('claimbatchList.colCharges'), class: 'text-rigth',formatter: value => {return "$"+value} },
        { key: 'hcpcsCode', sortable: true, label: this.$t('Shared.HCPCS'), class: 'text-rigth' },
        { key: 'modifier', sortable: true, label: this.$t('claimbatchList.colMod1'), class: 'text-center' },
        { key: 'modifier2', sortable: true, label: this.$t('claimbatchList.colMod2'), class: 'text-center' },
        { key: 'modifier3', sortable: true, label: this.$t('claimbatchList.colMod3'), class: 'text-center' },
        { key: 'modifier4', sortable: true, label: this.$t('claimbatchList.colMod4'), class: 'text-center' },
        { key: 'valueCode', sortable: true, label: this.$t('Shared.ValueCode'), class: 'text-center' }, 
        ], 
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

      }
  },


  methods:{

    CanView(){
      return this.permission[0].roleCanView;
    },

    ViewIcon(row){
    this.TitleDetail = this.$t('claimbatchList.ClaimDetail') + " - "+row.patientName +" - " + row.patientCode + " - "+ row.dateFrom + " - "+ row.dateTo + " - "+ " " + this.$t('Shared.CPT') + " "+row.courseCode;
    this.AttendanceDetailListSelected = row.attendanceDetailList; 
    this.OldUnitNumber = row.unit;
    this.OldtotalCharges = row.totalCharges;
    var data = {
      row:row,
      RecId:row.recId,
      AttendanceDetailListSelected:this.AttendanceDetailListSelected
    }
    EventBus.$emit('onAttendanceDetail', data);
    this.ViewModalDetail();
     
    },

    Error(row){
      this.TitleError = this.$t('claimbatchList.ClaimError') + " - "+row.patientName +" - " + row.patientCode + " - "+ row.dateFrom + " - "+ row.dateTo + " - "+ " " + this.$t('Shared.CPT') + " "+row.courseCode;
      this.ErrorList = row.errorList; 
     var iArray = [];
     for(var i = 0; i < this.ErrorList.length; i++){
       iArray.push({description:this.ErrorList[i]});
     }

      EventBus.$emit('onClaimError', iArray);
      this.$bvModal.show(this.modalIdError);      
    },

    Warning(row){
      this.TitleWarning = this.$t('claimbatchList.ClaimWarning') + " - "+row.patientName +" - " + row.patientCode + " - "+ row.dateFrom + " - "+ row.dateTo + " - "+ " " + this.$t('Shared.CPT') + " "+row.courseCode;
       this.WarningList = row.warningList; 
     var iArray = [];
     for(var i = 0; i < this.WarningList.length; i++){
       iArray.push({description:this.WarningList[i]});
     }

      EventBus.$emit('onClaimWarning', iArray);
      this.$bvModal.show(this.modalIdWarning);  
    },

    ViewModalDetail : async function() {
      this.$bvModal.show(this.modalIdDetail);      
    },

    RowClicked(item){ 
        if(item.selected){      
          this.$set(item, 'selected', false);         
        }else{
          this.$set(item, 'selected', true);       
        }
        EventBus.$emit('rowClicked', item);
    },
    

    OnReturnData(data){
      console.log(data);
    },
    Insurances(){

    },

    SearchinFilterList(){

    },
    PerPageFunction(page){
		this.perPage = page;
    },
    pageChanged(){	
    },
    onFiltered(filteredItems){
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
    },
    onRowSelected(){ 	
    //  this.itemsSelected = row;
     this.SelectedRecords = 0;
     if(this.items.length > 0){
    for(var i = 0; i < this.items.length; i++){
      if(this.items[i].selected){
        this.SelectedRecords++;
      }
    }
     }
    },

    onDBLCLick(){

    },
	
	onSelectRow(index){
		console.log(index);
	},

    SelectAllRecords(){
      this.SelectedPage = this.items.length;
      this.wasSelectedAll = true;
    },
	
	CancelSelection(){     
  this.wasSelectedAll = false;
  this.UseSelectTableAll = false;
	this.SelectedPage = this.perPage;
    },

    FindbyPos(pos,arr){
      var temp = [];
      for(var i = 0; i < arr.length; i++){
        if(arr[i].pos === pos){
          temp.push(arr[i].details);
        }
      }
      return temp;
    },

    FindinItems(){     
      this.items = this.CopyItems;
        var arr = this.items;
        var temp = [];
        if(this.modelSearch){
         arr.forEach(element => {
          if(element.patientName && element.patientName.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.patientCode && element.patientCode.toUpperCase().includes(this.modelSearch.toUpperCase())
           || element.dateFrom && element.dateFrom.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.dateTo && element.dateTo.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
           element.courseCode && element.courseCode.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.unit && element.unit == this.modelSearch ||
           element.totalCharges && element.totalCharges == this.modelSearch || element.hcpcsCode && element.hcpcsCode.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
           element.modifier && element.modifier.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.modifier2 && element.modifier2.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
           element.modifier3 && element.modifier3.toUpperCase().includes(this.modelSearch.toUpperCase()) || element.modifier4 && element.modifier4.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
           element.valueCode && element.valueCode.toUpperCase().includes(this.modelSearch.toUpperCase())
           ){
              temp.push(element);
           }
        });
        }else{
           temp = this.CopyItems;
        }      
         this.items = temp;
         this.totalRows = temp.length;
    },

      formatterDate(value){
         return Utils.formatterDateTimeToString(value);
      },

    Search(){  
    
      if(this.DateFrom && !Utils.IsValidDateAndRange(this.formatterDate(this.DateFrom), Utils.getDateTimeFormat())){
        this.showMessage(this.$t('claimbatchList.ClaimBatch'),this.$t('claimbatchList.DateFromInvalid'),"error");
        return;
      }
      if(this.DateTo && !Utils.IsValidDateAndRange(this.formatterDate(this.DateTo), Utils.getDateTimeFormat())){
        this.showMessage(this.$t('claimbatchList.ClaimBatch'),this.$t('claimbatchList.DateToInvalid'),"error");
         return;
      }
      if(this.DateFrom && Utils.IsValidDateAndRange(this.formatterDate(this.DateFrom), Utils.getDateTimeFormat()) && this.DateTo && Utils.IsValidDateAndRange(this.formatterDate(this.DateTo), Utils.getDateTimeFormat())){
        if(this.DateFrom > this.DateTo){
           this.showMessage(this.$t('claimbatchList.ClaimBatch'),this.$t('claimbatchList.DateFromGreater'),"error");
            return;
        }       
      }

      if(this.DateFrom !== "" && this.DateTo === ""){
         this.showMessage(this.$t('claimbatchList.ClaimBatch'),this.$t('claimbatchList.DateToEmpty'),"error");
          return;
      }

      if(this.DateTo !== "" && this.DateFrom === ""){
         this.showMessage(this.$t('claimbatchList.ClaimBatch'),this.$t('claimbatchList.DateFromEmpty'),"error");
          return;
      }     
      this.SelectedRecords = 0;
      this.UseSelectTableAll = false;
      this.getAll();
    },

    FormatDatesinItems(){
      var arr = this.items;     
        arr.forEach(element => {
           element.dateFrom = moment(element.dateFrom).format();
           element.dateTo = moment(element.dateTo).format()
         });        
         return arr;
    },

    DoBatch(){
      this.modelSearch = "";
      this.$emit('load', true);  
      var List = this.FormatDatesinItems();
      var param = {
        useTherapyProvider:this.UseTherapyProvider,
        disableCheckForPriorAuthorization:this.DisableCheckForPriorAuthorization,
        programCodeList:this.ProgramCodeListBatch,
        therapistCodeList:this.TherapistCodeListBatch,
        therapyCodeList:this.TherapyCodeListBatch,
        insuranceCodeList:this.InsuranceCodeListBatch,
        claimBatches:List       
      };
       ClaimBatch.ApplyBatch(param)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       var data = response.data;

                        if(data.length === 0){
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('claimbatchList.ClaimsBatchedSuccessfully'), {
                         title: this.$t('claimbatchList.ClaimBatch'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                        }else{
                          this.showMessage(this.$t('claimbatchList.ClaimBatch'),this.$t('claimbatchList.SomeErrorsOrWarnings'),"error");
                        }
                       this.SelectedRecords = 0;
                          data.forEach(function(element) { 
                          element.selected = false; 
                          if (element.dateFrom)	
                              element.dateFrom = Utils.formatterDateToString(element.dateFrom);                               
                          if (element.dateTo)	
                              element.dateTo = Utils.formatterDateToString(element.dateTo); 
                          if(element.attendanceDetailList.length > 0){
                             element.attendanceDetailList.forEach(function(detail) {
                               if(detail.transdate)
                                  detail.transdate = Utils.formatterDateToString(detail.transdate); 
                             })
                          } 
                        });
                        this.items = data;
                        this.totalRows = this.items.length;
                        this.$emit('load', false);                           
                     }               
                })
                .catch((error) => {
                  this.$emit('load', false);  
                    if (error.response) {
                        this.showMessage(this.$t('claimbatchList.ClaimBatch'),error,"error");
                    }
                });  
    },
	
	SelectRedordsFirstPage(){	
		
	},
    onChangeSelectAll(v){
      if(v){
        this.SelectedRecords = this.items.length; 
        for(var i = 0 ; i < this.items.length; i++){
           this.$set(this.items[i], 'selected', true); 
        }
      }else{
        this.SelectedRecords = 0;
        for(var j= 0 ; j < this.items.length; j++){
           this.$set(this.items[j], 'selected', false); 
        }
      }
    },

    ChangeDropDownPatients(patients){
    var l = patients.length;    
     var TempPatientList = [];
     if(l>0){
       var i = 0;
        patients.forEach(function(e) {         
        if(i<l-1){
          TempPatientList += e.patientCode+",";
        }else{
          TempPatientList += e.patientCode;
        }
        i++;                    
        });
        this.PatientCodeList = TempPatientList;
    }else{
      this.PatientCodeList = [];
    } 
    },

    ChangeDropDownPrograms(programs){
         var l = programs.length;
     var TempProgramList = [];
     var arr = [];
     if(l>0){
       var i = 0;
        programs.forEach(function(e) { 
          arr.push(e.code);        
        if(i<l-1){
          TempProgramList += e.code+",";
        }else{
          TempProgramList += e.code;
        }
        i++;                    
        });
        this.ProgramCodeListBatch = arr;
        this.ProgramCodeList = TempProgramList;
    }else{
      this.ProgramCodeList = [];
    } 
    },

    ChangeDropDownTherapist(therapist){
       var l = therapist.length;
    var TempTherapistList = [];
     var arr = [];
     if(l>0){
       var i = 0;
        therapist.forEach(function(e) {  
          arr.push(e.code);       
        if(i<l-1){
          TempTherapistList += e.code+",";
        }else{
          TempTherapistList += e.code;
        }
        i++;                    
        });
        this.TherapistCodeList = TempTherapistList;
        this.TherapistCodeListBatch = arr;
    }else{
      this.TherapistCodeList = [];
    } 
    },

    ChangeDropDownTherapy(therapies){
       var l = therapies.length;
     var TempTherapyList = [];
     var arr = [];
     if(l>0){
       var i = 0;
        therapies.forEach(function(e) {   
          arr.push(e.code);      
        if(i<l-1){
          TempTherapyList += e.code+",";
        }else{
          TempTherapyList += e.code;
        }
        i++;                    
        });
        this.TherapyCodeList = TempTherapyList;
        this.TherapyCodeListBatch = arr;
    }else{
      this.TherapyCodeList = [];
    } 
    },

    ChangeDropDownInsurance(insurances){
       var l = insurances.length;
   var TempInsuranceList = [];
     var arr = [];
     if(l>0){
       var i = 0;
        insurances.forEach(function(e) { 
          arr.push(e.insuranceCode);        
        if(i<l-1){
          TempInsuranceList += e.insuranceCode+",";
        }else{
          TempInsuranceList += e.insuranceCode;
        }
        i++;                    
        });
        this.InsuranceCodeList = TempInsuranceList;
        this.InsuranceCodeListBatch = arr;
    }else{
      this.InsuranceCodeList = [];
    } 
    },

    RowClickedClaim(){     
      if(this.itemsSelected.length-1 === 0) {       
        this.UseSelectTableAll = false;
      }
    },

    onCheck(/*param*/){
      //this.NotesId = param.notesId;
      //this.currentPageSubItems = param.currentPage;
      //this.perPageSubItems = 5;
      //this.getNoteAllDate(this.NotesId,this.currentPageSubItems,this.perPageSubItems);
    },

    getPrograms(){
       ClinicalService.getPrograms()
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                       this.ProgramList = response.data;                                
                     }               
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage(this.$t('claimbatchList.ProgramFilter'),error,"error");
                    }
                });  
    },

      getPatients(){
      if(this.permission.length > 0 && this.permission[0].roleCanView){
        ClaimBatch.getPatients()
            .then((response) => {
              if(response.status == '200' && response.data){
                this.PatientsList = response.data;  
                this.PatientsList.forEach(function(element) {  
                element.patientName = element.patientName + " - " + element.patientCode;
              });

            }               
          })
            .catch((error) => {
              if (error.response) {
                this.showMessage(this.$t('claimbatchList.PatientsFilter'),error,"error");
              }
            });  
      }       
    },
   
    getInsurances(){
       if(this.permission.length > 0 && this.permission[0].roleCanView){
          ClaimBatch.getInsurances()
            .then((response) => {
              if(response.status == '200' && response.data){
                this.InsuranceList = response.data;                                
              }               
            })
            .catch((error) => {
              if (error.response) {
                this.showMessage(this.$t('claimbatchList.InsurancesFilter'),error,"error");
              }
          });  
       }
    
    },

    getAllEmployee(){
         EmployeeService.getAllEmployeeByType()
            .then((response) => { 
               if(response.data && response.data.length > 0){
                this.TherapistList = response.data;				
               }             
            })
            .catch((error) => {
               if (error.response) {
                  this.showMessage(this.$t('claimbatchList.EmployeeFilter'),error,"error");
               }
            });
      },

    getTherapies(){
         ClinicalService.getTherapies()
            .then((response) => { 
               if(response.data && response.data.length > 0){
                this.TherapiesList = response.data;		
               }             
            })
            .catch((error) => {
               if (error.response) {
                  this.showMessage(this.$t('claimbatchList.TherapiesFilter'),error,"error");
               }
            });
      },



      getAll(){
        this.$emit('load', true);
        var parameter = {
          "DateFrom" : this.DateFrom,
          "DateTo": this.DateTo,
          "CreateAClaimForEveryServiceDay":this.CreateAClaimForEveryServiceDay,
          "BillAcrossMonth":this.BillAcrossMonth,
          "PatientCodeList":this.PatientCodeList,
          "ProgramCodeList":this.ProgramCodeList,
          "TherapistCodeList":this.TherapistCodeList,
          "TherapyCodeList":this.TherapyCodeList,
          "InsuranceCodeList":this.InsuranceCodeList
        };
         ClaimBatch.getAll(parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data;                       
                      this.$emit('load', false);                        
                      this.totalRows = this.items.length;
                      this.CopyItems = response.data;
                      if (this.items.length === 0) {
                        this.ShowMessageIsEmpty = true;                      
                      }
                       this.$emit('load', false);  
                        this.items.forEach(function(element) { 
                          element.selected = false; 
                          element.totalCharges = element.totalCharges.toFixed(2);                             
                          if (element.dateFrom)	
                              element.dateFrom = Utils.formatterDateToString(element.dateFrom);
                          if (element.dateTo)	
                              element.dateTo = Utils.formatterDateToString(element.dateTo); 
                          if(element.attendanceDetailList.length > 0){
                             element.attendanceDetailList.forEach(function(detail) {
                               if(detail.transdate)
                                  detail.transdate = Utils.formatterDateToString(detail.transdate); 
                             })
                          } 
                        });  
                        
                        
                     }               
                })
                .catch((error) => {
                  this.$emit('load', false);    
                    if (error.response) {
                        this.showMessage(this.$t('claimbatchList.ClaimBatchFilter'),error,"error");
                    }
                });  

      },
      getListDetailByRecId(recId){
        var arr = [];     
         for(var i = 0; i < this.items.length; i++){
            if(this.items[i].recId === recId){
              arr = this.items[i].attendanceDetailList;
              break;
            }
         }
        return arr;
      },
      AssignListDetailByRecId(recId,arr){
        for(var i = 0; i < this.items.length; i++){
            if(this.items[i].recId === recId){
              this.items[i].attendanceDetailList = arr;
              break;
            }
         }       
      },
      AssignUnitsDetailByRecId(recId,newUnit){
        for(var i = 0; i < this.items.length; i++){
            if(this.items[i].recId === recId){
              this.items[i].unit = newUnit;
              break;
            }
         }       
      },
        AssignTotalChargesDetailByRecId(recId,newTotalCharge){
        for(var i = 0; i < this.items.length; i++){
            if(this.items[i].recId === recId){
              this.items[i].totalCharges = newTotalCharge;
              break;
            }
         }       
      },
    AssignNewElementsDetailByRecId(recId,arr,newTotalCharge,newUnit){
        for(var i = 0; i < this.items.length; i++){
            if(this.items[i].recId === recId){
              this.items[i].unit = newUnit;
              this.items[i].attendanceDetailList = arr;
              this.items[i].totalCharges = newTotalCharge;
              break;
            }
         }       
      },
  },
  created(){
    //this.$emit('load', true);    
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission); 
    this.CopyItems = this.items;
    this.OriginalSaved = this.CopyItems; 
    this.getPatients();
    this.getPrograms();
    this.getInsurances();
    this.getAllEmployee();
    this.getTherapies(); 
    
    EventBus.$on("rowClicked", function () {        
    this.SelectedRecords = 0;
    for(var i = 0; i < this.items.length; i++){
      if(this.items[i].selected){
        this.SelectedRecords++;
      }
    }
      
     }.bind(this));

    EventBus.$on("onUpdatedListbyRecNo", function (data) {
    var attList = this.getListDetailByRecId(data.recId);  
    var newUnits = 0;
    var aUnitPrice = 0; 

    for(var i = 0; i < data.items.length; i++){   
       attList[i].selected =  data.items[i].selected;
       if(data.items[i].selected){
         newUnits += data.items[i].unit;
       }       
    }
     aUnitPrice = this.OldtotalCharges / this.OldUnitNumber;  
    var newTotalCharges = newUnits * aUnitPrice;
    this.AssignNewElementsDetailByRecId(data.recId,attList,newTotalCharges.toFixed(2),newUnits);   
    }.bind(this));

     
  },
  mounted(){    
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }


      if(this.permission.length == 0){
        this.$router.push({name: "Main"});    
          setTimeout(() => 
            this.showPermissionMessage()
          , 50); 
        this.$emit('load', false); 
      }
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


