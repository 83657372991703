<template>
    <div id="ContainerClinicalePharmacy">
        <ePharmacyBase v-bind:Origin="'Clinical'" />
    </div>
</template>
<script>
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
import EnumService from '@/services/enum.service' 
export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'ePharmaryClinical',
   mixins: [DBFunctions],

   data(){
       return{
        permission:[],
        OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
      },
        Menu : this.$store.state.leftMenu,
       }
   },

    methods:{
    CanView(){
        return this.permission[0].roleCanView;
    },   
    },

    created(){
        this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
    EventBus.$on("ShowWeel", function (isShow) { 
        isShow ? this.$emit('load', true) : this.$emit('load', false);     
     }.bind(this));
    },

    mounted(){
       // console.log("ePharmacy Clinical");
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('appointments-epharmacy') ? EnumService.AppointmentItems : EnumService.ClinicalItems
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
   },

   destroyed(){
     
   }
};
</script>