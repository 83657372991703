<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditGoals" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
      
        <b-row v-if="VisibleIntvWiley">
          <b-col lg="12" md="12" sm="12">     
            <MdTitle v-bind:Text="$t('modalAddIntervention.intervention')" v-bind:isSubtitle="true" v-bind:Name="'Intervention'" class="subtitleWizard"/>
              <MdCheckListBox2 
                  v-model="InterventionModel"
                  v-bind:Options="InterventionsList"   
                  v-bind:Height="'150px'"         
                  v-bind:OnChangeProp="OnChangeIntervention"
                  v-bind:columnCount="1"
                  :textField="'description'"
                  :valueField="'interventionNumber'"/> 
          </b-col>
        </b-row>  

        <MdTitle v-bind:Text="$t('modalAddGoal.other')" v-bind:isSubtitle="true" v-bind:Name="'Other'"/> 
        <b-row>            
          <b-col md="12">             
              <MdMemo v-bind:Name="'interventiondescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="false"
                          v-bind:DynamicClass="'modalTextarea'" v-bind:MaxRows="'6'"
                          v-model="intdescriptionBlank" />
          </b-col>
         </b-row> 
     </b-modal>  
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import TreatmentPlan from '@/services/treatmentplan.service'
 

 export default {
   name: 'ModalAddIntervention',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String,
     GoalListObj:Array,
     ObjectiveListObj:Array,
     GoalRecId:String,
     ObjRecId:String
   },
   
   data(){
     return {
        VisibleIntvWiley:false,
        intdescriptionBlank:"",
        InterventionsList:[],
        InterventionModel:[],
        IntvData:[],
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){     
        this.AddIntervention();
     },

     OnChangeGoals(){

     },

     getDescrByCode(code){
        let result = {desc:"",isEvidencebased:false};
        for(var i = 0; i < this.InterventionsList.length; i++){
           if(this.InterventionsList[i].interventionNumber === code){           
            result.desc = this.InterventionsList[i].description;
            result.isEvidencebased = this.InterventionsList[i].isEvidenceBased;
            break;
           }
        }
        return result;
     },

     PrepareData(){
            this.IntvData = [];         
            for(var i = 0; i < this.InterventionModel.length; i++){
                let result = this.getDescrByCode(this.InterventionModel[i]);
                this.IntvData.push(
                    {                       
                      intCode:this.InterventionModel[i].toString(),
                      intDesc:result.desc,
                      isEvidenceBased:result.isEvidencebased
                    }
                    );
            }

            if(this.intdescriptionBlank.length > 0){
                this.IntvData.push(
                    {                       
                      intCode:"0",
                      intDesc:this.intdescriptionBlank,
                      isEvidenceBased:false
                    }
                    );
            }
        },


        AddIntervention(){        
        this.PrepareData();
        if(this.IntvData.length > 0){
            var objdata = {
              admissionId:this.$route.params.recordid,
              probRecId:this.ProblemRecId.toString(),
              goalRecId:this.GoalRecId,
              objRecId:this.ObjRecId,
              interventions:this.IntvData
            };
         TreatmentPlan.AddIntervention(objdata)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.InterventionSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 5); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalAddIntervention.intervention'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalAddIntervention.intervention'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalAddIntervention.intervention'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalAddIntervention.intervention'),error.response.data.errorMessage,"error");
                     }    
            });
        }
    },

     OnChangeIntervention(){

     },

     async getWileyTxInterventionsList() {           
            this.$emit('load', true);
            var parameter = {
                GroupId: this.groupId,
                ProblemNumber: this.probCode,
                GoalNumberList:this.GoalListObj,
                ObjectiveNumberList: this.ObjectiveListObj,
                ExcludeWithHeaderId:this.headerId,
                IsWizard:false
            };
           await TreatmentPlan.getWileyTxInterventions(parameter)
                .then((response) => {
                if (response.status == '200' && response.data) {
                    if(response.data.length > 0 && response.data[0].objectives.length > 0){
                      if(Array.isArray(response.data[0].objectives[0].interventions)){
                        this.InterventionsList = response.data[0].objectives[0].interventions;
                      }else{
                        this.InterventionsList = [];
                      }
                    }else{
                      this.InterventionsList = [];
                    }
                    
                    this.VisibleIntvWiley = this.InterventionsList.length > 0 ? true : false;
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('modalAddIntervention.tPlanInterventions'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('modalAddIntervention.tPlanInterventions'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('modalAddIntervention.tPlanInterventions'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('modalAddIntervention.tPlanInterventions'), error.response.data.errorMessage, "error");
                }
            });
        },


     emitEventCancel(){
      this.$emit('ModalTPAddIntervention');
    },    
   },
 
   async mounted(){
    await this.getWileyTxInterventionsList();
   },
 };
 </script>

