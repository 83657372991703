<template>
    <div class="page-height">
        <MdTitleRegInfo
            v-bind:RegistrationInfo="PatientTitle" 
            v-bind:titlePrefix="this.editMode ? $t('PatientAlerts.EditPatientAlert'): $t('PatientAlerts.AddPatientAlert')" 
            v-bind:IsClinical="IsClinical"
            v-bind:titleRef="'titleElementAlert'"
            v-bind:patientCode="this.$route.params.id">
        </MdTitleRegInfo>

        <!--
        <MdTitle v-bind:Text="$t('Shared.Notes')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
        -->
        
        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
                <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
        </div>

        <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="!canCreateAlerts">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
                <span>{{ $t('PatientAlerts.CanCreateAlerts') }}</span>
            </div>
        </div>

        
        <div class="row">
            <div class="col-12 mb-2">
                <div :data-label="$t('Shared.Notes')" class="textlabel"></div>
                <MdRichEdit
                    v-model="Alert.memoInfo"
                    v-bind:isReadOnly="isReadOnly || !canCreateAlerts"
                    v-bind:Height="'56vh'"
                    v-bind:id="'edit_memoInfo'"
                    ref="editor"
                    v-bind:isValid="isMemoInfoValid"
                    v-bind:isRequired="true"
                    v-bind:HideToolBar="true"
                    v-bind:StartupFocus="false"
                    @ready="onEditorReady"
                />
            </div>
        </div>

        <div class="row">
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('PatientAlerts.DateAndTime')"
                    v-bind:Name="'DateAndTime'"
                    v-bind:TypeProp="'text'" 
                    v-bind:Mask="'##/##/#### ##:## AA'"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"              
                    v-bind:isRequired="true"
                    v-bind:isValid="IsValidDateAndTime"
                    v-model="Alert.dateChanged"
                    v-bind:isReadOnly="isReadOnly || !canCreateAlerts"
                >                                     
                </MdEdit>
            </b-col>
            <b-col lg="6" md="6" sm="12">
                <MdEdit 
                    v-bind:Label="$tc('Shared.Employee')"
                    v-bind:Name="'text'"
                    v-bind:TypeProp="'text'" 
                    v-model="employeeNameModel"
                    v-bind:isReadOnly="true">                                     
                </MdEdit>
            </b-col>
        </div>
        
        <div class="flex-container">
            <b-row>           
                <b-col md="3" lg="3" sm="12">
                    <MdSelect
                        v-bind:Label="$t('PatientAlerts.ShowToTherapist')"
                        v-bind:Options="TherapistSourceList"
                        v-bind:FieldDescription="'description'"
                        v-bind:FieldCode="'code'"
                        v-bind:Model="TherapistList"  
                        v-bind:isRequired="false" 
                        v-bind:Multiple="true"
                        :Input="ChangeDropDownTherapistSource"
                        :IsDisabled="isReadOnly || !canCreateAlerts"
                    />
                </b-col>
                <b-col md="3" lg="3" sm="12">
                    <MdSelect
                        v-bind:Label="$t('PatientAlerts.ShowToEmployeeType')"
                        v-bind:Options="EmployeeTypeSourceList"
                        v-bind:FieldDescription="'description'"
                        v-bind:FieldCode="'code'"
                        v-bind:Model="EmployeeTypeList"  
                        v-bind:isRequired="false" 
                        v-bind:Multiple="true"
                        :Input="ChangeDropDownEmployeeTypeSource"
                        :IsDisabled="isReadOnly || !canCreateAlerts"
                    />
                </b-col>
            
                <b-col md="3" lg="3" sm="12">
                <MdCheckBox
                        v-bind:Label="$t('PatientAlerts.DontShowMeAgain')"
                        v-bind:Name="'checkbox-doNotShow'"
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"
                        v-model="Alert.notShowMeMore"
                />
                </b-col>
                <b-col md="3" lg="3" sm="12">
                <MdCheckBox
                        v-bind:Label="$t('PatientAlerts.Active')"
                        v-bind:Name="'checkbox-isActive'"
                        v-bind:ClassProp="'AlignCheck'"
                        v-bind:CheckedValue="true"
                        v-bind:UncheckedValue="false"
                        v-model="Alert.isActive"
                        v-bind:isReadOnly="isReadOnly || !canCreateAlerts"
                />
                </b-col>
            </b-row>
        </div>

        <div class="space-actions"></div>
        <div class="page-actions">
            <MdButton
                v-bind:Label="$t('Shared.Back')"
                v-bind:ClassProp="'secondary mx-2 btn-login'"
                v-on:click="goAlertsList"
            />
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
                v-bind:Name="'btn_save_alerts'"
            />
        </div>
    </div>
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from '@/common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import EmployeeTypesService from '@/services/employeetypes.service'
import EmployeeService from '@/services/employee.service'
import LocalStorage from '@/services/storage.service'
import { EventBus } from '@/event-bus.js';
import ActivitiesService from '@/services/activities.service'
import UserService from '@/services/user.service'

let elemSaveBtn = '';
export default {
    beforeRouteLeave(to, from, next) {
        if (this.pendingChanges) {
            this.$bvModal
                .msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
                    title: this.$t('PatientAlerts.PatientAlerts'),
                    okTitle: this.$t('Shared.BtnSave'),
                    cancelTitle: this.$t('Shared.BtnDismiss'),
                    footerClass: 'p-2',
                    titleClass: 'modal-title modal-type-warning'
                })
                .then(async value => {
                    if(!value){
                        next();
                    } else {
                        await this.SaveOrEdit();
                        if (!this.pendingChanges) {
                            next();
                        }
                    }
                })		
        } else {
            next()
        }
    },

    name: 'EditPatientAlert',
    mixins: [DBFunctions],
    props: ["editMode"],

    data() {
        return {
            InfoPatient:{},
            PatientTitle:{      
                programName:'',
                fullName:'',
                admitDate:'',
                dischargeDate:'',
                dob:''
            }, 
            OptionsRightMenu:{
				visible:true,
				ItemsList:[],
				btnvisible:true,
				itemdisabled: false,
				PatientCode:''
			},
            IsClinical:false,
            Alert: {
                memoInfo: '', //!this.editMode ? LocalStorage.getUserId() + ' - ' + Utils.formatterDateToLocaleString(new Date()) : '',
                dateChanged: Utils.formatterDateTimeToString(new Date()),
                showToUsersList: null,
                showToEmployeeTypeList: null,
                isNotify: null,
                showToPatient: false,
                userId: LocalStorage.getUserId(),
                patientcode: this.$route.params.id,
                isAlert: true,
                isActive: true
            },
            isReadOnly: false,
            isMemoInfoValid: true,
            TherapistSourceList:[],
            TherapistList:[],
            EmployeeTypeSourceList:[],
            EmployeeTypeList:[],
            pendingChanges: false,
            canCreateAlerts: true,
            loaded: false,
            IsValidDateAndTime: true,
            employeeNameModel: null,
        }
    },

    methods: {
        goAlertsList() {
            if(this.$route.name.includes('Clinical-')){ 
                this.$router.push({ name: "Clinical-patient-alerts" });
            } else if(this.$route.name.includes('appointments-')){ 
                this.$router.push({ name: "appointments-alerts" });
            } else {
                this.$router.push({ name: "patient-alerts" });
            }
        },

        validate_required() {
            const plaintText =  this.$refs.editor.getPlaintText();
            this.IsValidDateAndTime = this.Alert.dateChanged ? true : false;
            this.isMemoInfoValid = plaintText.trim().length > 0;
            return this.IsValidDateAndTime && this.isMemoInfoValid;
        },

        validateDate(){
            this.IsValidDateAndTime = Utils.IsValidDateAndRange(this.Alert.dateChanged, Utils.getDateTimeFormat(), true) ? true : false;
            return this.IsValidDateAndTime;
        },

        SaveOrEdit() {
            if(!this.isReadOnly && this.canCreateAlerts) {
                if (this.validate_required()) {
                    if(this.validateDate()){
                        this.$emit('load', true);
                        this.DoAction();
                    } else {
                        elemSaveBtn.removeAttribute("disabled");
                        this.showMessage(this.$t('PatientAlerts.PatientAlerts'), 
                            this.$t('Msg.InvalidDate') + ' ' + this.$t('Shared.Format') + ' '+ Utils.getDateTimeFormat(), "errorAutoHide");          
                    }
                } else {
                    this.showMessage(this.$t('PatientAlerts.PatientAlerts'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
                }
            } else {
                this.$emit('load', true);
                this.resetNotShowMeMore();
            } 
        },

        DoAction() {
            let data = { ...this.Alert }
            data.dateChanged = Utils.formatterToISO(this.Alert.dateChanged, Utils.getDateTimeFormat());
            data.memoinfoPlainText = this.$refs.editor.getPlaintText();
            !this.editMode ? this.AddAlert(data) : this.EditAlert(data); 
        },

        AddAlert(data) {
            ActivitiesService
                .add(data)
                .then(response => {
                    if (response.status == "200") {
                        this.pendingChanges = false;
                        this.goAlertsList();    
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                            , 50); 
                    }

                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                    this.$emit('load', false);
                });
        },

        EditAlert(data) {
            ActivitiesService
                .update(this.$route.params.code, data)
                .then(response => {
                    if (response.status == "200") {
                        this.pendingChanges = false;
                        this.goAlertsList();    
                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                            , 50); 
                    }

                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                    this.$emit('load', false);
                });
        },

        resetNotShowMeMore() {
            let param = {
                PatientCode: this.Alert.patientcode,
                NotShowMeMore: this.Alert.notShowMeMore,
                RecId: this.Alert.recId
            }
            
            ActivitiesService
                .resetAlerts(param)
                .then(response => {
                    if (response.status == "200") {
                        this.pendingChanges = false;
                        this.goAlertsList();   

                        setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                                title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                            })
                        , 50);
                    }

                    this.$emit('load', false);
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                    this.$emit('load', false);
                });
        },

        appendDateTimeStamp() {
            this.$refs.editor.appendHtml('<p>' + LocalStorage.getUserId() + ' - ' + Utils.formatterDateToLocaleString(new Date()) + '</p>');
        },

        ChangeDropDownTherapistSource(value) {
            this.Alert.showToUsersList = value.map(x => x.code);
        },

        ChangeDropDownEmployeeTypeSource(value) {
            this.Alert.showToEmployeeTypeList = value.map(x => x.code);
        },

        onEditorReady() {
            if (!this.editMode) {
                this.$refs.editor.moveToLastPosition();
            }
        }, 

        getEmployeeTypesListing() {
            const param = {
                Active: true
            }

            this.EmployeeTypeSourceList = [];
            EmployeeTypesService
                .getAllEmployeeType(param)
                .then(response => {
                    if (response.status == "200" && response.data) {
                        const data = response.data;
                        data.forEach(element => {
                            this.EmployeeTypeSourceList.push({
                                code: element.code,
                                description: element.description
                            })
                        });
                    }
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                });
        },

        getEmployeeListing() {
            const param = {
                IsUser: true,
                Active: true
            }

            this.TherapistSourceList = [];
            EmployeeService
                .getAll(param)
                .then(response => {
                    if (response.status == "200" && response.data) {
                        response.data.forEach(element => {
                            this.TherapistSourceList.push({
                                code: element.code,
                                description: element.name
                            });
                        });
                    }
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                });
        },

        getAlert() {
            if (this.editMode) {
                ActivitiesService
                    .get(this.$route.params.code)
                    .then(response => {
                        if (response.status == "200" && response.data) {
                            this.Alert = {
                                memoInfo: response.data.memoinfo,
                                dateChanged: Utils.formatterDateTimeToString(response.data.datechanged),
                                showToUsersList: response.data.showToUsersList,
                                showToEmployeeTypeList: response.data.showToEmployeeTypeList,
                                isNotify: response.data.isNotify,
                                showToPatient: response.data.showToPatient,
                                userId: response.data.userid,
                                patientcode: response.data.patientcode,
                                isAlert: response.data.isAlert,
                                headerId: response.data.headerId,
                                isslineno: response.data.isslineno,
                                recId: response.data.recId,
                                showInMars: response.data.showInMars,
                                workflowRecId: response.data.workflowRecId,
                                isActive: response.data.isActive ?? false,
                                notShowMeMore: response.data.notShowMeMore ?? false,
                                employeeName: response.data.userName
                            };

                            this.employeeNameModel = this.Alert.employeeName;
                            
                            setTimeout(() => {
                                this.Alert.showToUsersList.forEach(element => {
                                    var result = this.TherapistSourceList.find(valueIndex => valueIndex.code === element);
                                    if (result && result!=='undefined'){
                                        this.TherapistList.push(result);
                                    }
                                });

                                this.Alert.showToEmployeeTypeList.forEach(element => {
                                    var result = this.EmployeeTypeSourceList.find(valueIndex => valueIndex.code === element);
                                    if (result && result!=='undefined'){
                                        this.EmployeeTypeList.push(result);
                                    }
                                });
                                this.loaded = true;
                            }, 50, this);
                            
                        } else {
                            this.loaded = true;
                        }
                        this.$emit('load', false);
                    })
                    .catch(error => {
                        this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                        this.$emit('load', false);
                    });
            }
        },

        async getUseCanCreateAlerts() {
            UserService.getUserByCode(LocalStorage.getUserId())
                .then((response) => {
                    if(response.status == '200' && response.data){
                        if(response.data.length > 0){
                            this.canCreateAlerts = response.data[0].canCreateAlerts;
                        }else{
                            this.canCreateAlerts = false;
                        } 
                    }                          
                })
                .catch(error => {
                    this.showResponseErrorMessage(error, this.$t('PatientAlerts.PatientAlerts'));
                });
        },

        async GetPatientByCode() {
            var infoList = await this.getInfoTitle(this.$route.params.id);
            if(infoList.length > 0){
                this.PatientTitle.programName = '';
                this.PatientTitle.admitDate = '';
                this.PatientTitle.dischargeDate = '';

                const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

                if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age;
                if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
            }
        },

        PopulatePatientTitle(){
            const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
            if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
            if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	

            if(this.$route.name.includes('Clinical-patient-alert')){ 
                if (programName) this.PatientTitle.programName = programName; 
                if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
                if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
                if (patientName) this.PatientTitle.fullName = patientName;
                if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
                if (age) this.PatientTitle.age = age === 0 ? "0" : age;
                if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
            } else if(this.$route.name.includes('patient-alert')) {         
                this.PatientTitle.programName = '';
                this.PatientTitle.dateFrom = '';
                this.PatientTitle.dateTo = '';
            }
        },

        async PopulateTitle() {
            if(!this.$route.name.includes('Clinical-patient-alert')){  
                await this.GetPatientByCode();
            } else {      
                this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
                const { dateTo, age } = this.RegistrationInfo; // Destructure for easier access

                this.IsClinical = dateTo ? false : true;
                if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
            }
            
            this.PopulatePatientTitle();
        },

        checkUserAction() {
            if (elemSaveBtn && elemSaveBtn.disabled) {
                elemSaveBtn.removeAttribute('disabled');
            }
        },

        beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
		}
    },

    async created(){
        EventBus.$on("onChanged", function (e) {
            if (this.loaded) {
                this.pendingChanges = e.Changed
                this.checkUserAction();
            }
        }.bind(this));

        window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    async mounted() {
        this.isReadOnly = this.$store.state.isPatientAlertsEHRReadOnly;
        this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-patient-alert') ? EnumService.ClinicalItems : 
            this.$route.name.includes('appointments-patient-alert') ? EnumService.AppointmentItems : EnumService.PatientItems;
		this.OptionsRightMenu.Mode = '';
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.$emit('getVisibleMenu', this.OptionsRightMenu);

        this.$emit('load', true);
        await this.getUseCanCreateAlerts();
        await this.PopulateTitle();
        await this.getEmployeeTypesListing();
        await this.getEmployeeListing();
        if (this.editMode) {
            await this.getAlert();
        } else {
            this.employeeNameModel = LocalStorage.getName();
            this.loaded = true;
            this.$emit('load', false);
        }
        
        setTimeout( function() {
            elemSaveBtn = document.getElementById('btn_save_alerts');
            if (elemSaveBtn) {
                elemSaveBtn.setAttribute('disabled','');
            }            
        }.bind(this), 50 );
    },

    destroyed() {
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
    },

    beforeDestroy () {
        window.removeEventListener('beforeunload', this.beforeWindowUnload);
    },
}
</script>