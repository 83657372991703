<template>
   <div>
    <b-modal :id="ModalId" :visible="true" no-close-on-backdrop no-close-on-esc size="md" :title="TitleWindow" ref="modalparticipantcontact" @close="this.emitEventCancel" @ok="this.emitEventOK" @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdSelect
            v-bind:Label="$t('Incident.IncPartType')"
            v-bind:Options="ParticipantList"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="modelParticipantType"                       
            v-bind:Multiple="false"
            :Input="ChangeParticipantType"
            v-bind:isRequired="true" 
            v-bind:isValid="isParticipantTypeValid"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdEdit 
            v-bind:Label="$t('Incident.IncPartStatementDate')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'date'"
            v-model="contactItems.statementDate"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-bind:isRequired="true" 
            v-bind:isValid="isStatementDateValid">                                     
          </MdEdit>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">         
          <MdLookup 
            v-bind:ModelCode="contactItems.entityCode"
            v-bind:ModelDescription="contactItems.entityName"
            v-bind:Label="$tc('Contact.Contact')"
            v-bind:RefCode="'recNo'"   
            v-bind:Fields="FieldsContacts"   
            v-bind:Api="'PatientContacts'+Patients"
            v-bind:FieldCode="'recNo'"
            v-bind:FieldDescription="'contactName'"
            v-bind:AuxFields="ContactAux"
            v-bind:isRequired="true"
            :SearchTitle="$tc('Contact.Contact')"
            :GetValues="getValues"
            :InvalidFeedback="'Invalid contact'"
            :SearchFieldCode="'recNo'"
            :isValid="isValidContact"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartArrest')"
            v-bind:Name="'PoliceArrest3'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="contactItems.arrestMade"
            v-on:change="ChangePoliceArrest"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartVisit')"
            v-bind:Name="'VisittoER3'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="contactItems.ervisit"
            v-on:change="ChangeVisittoER"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartPersonalSta')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_personalstatement'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-bind:isRequired="true"
            v-bind:isValid="isPersonalStatementValid"
            v-model="contactItems.personalStatement"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartConRecommendations')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_resourcecomments'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-model="contactItems.observation"/>
        </b-col>
      </b-row>
    </b-modal>   
   </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import IncidentReport from '@/services/incidentreport.service'
import GlobalService from '@/services/global.service'
import Utils from '@/common/utils'

export default {
  name: 'ModalParticipantContact',
    mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     IncidentID: String,    
     ModeEdit: Boolean,
     ContactItems: Object,
     Patients: String,
     minObsevationStatement:Number,
     minPersonalStatement:Number
   },
   data(){
       return {
        ParticipantList: [],
        ContactAux:['recNo','recordId'],
        modelParticipantType:{code:"",description:""},
        participantType: '',
        personalstatementdate: null,
        personalstatementdatenotes: '',
        resourcecomments: '',
        FieldsContacts:[{ key: 'recNo', sortable: true, label: 'RecNo', class:'hidden'},{ key: 'contactName', sortable: true, label: this.$t('ContactsList.ContactName')},{ key: 'contactType', sortable: true, label: this.$t('Incident.IncPartContType')}],
        contactItems: {
          arrestMade: false,
          ervisit: false,
          observation: '',
          participationType: '',
          entityName: '',
          personalStatement: '',
          statementDate: null,
          entityCode: '',
          entityType: '',
          incidentId: '',
          id: 0,
          recordId: ''
        },
        isParticipantTypeValid: true,
        isStatementDateValid: true,
        isValidContact: true,
        isPersonalStatementValid: true,
        APIDateTime: null
       }
   },

   methods:{

    Message(min,text1,text2){
      return text1+" "+min+" "+text2; 
    },

      validateFields() {
        let personalStatement = this.contactItems.personalStatement ? this.contactItems.personalStatement.replace(/<[^>]*>?/g, '') :"";
        let observation = this.contactItems.observation ? this.contactItems.observation.replace(/<[^>]*>?/g, '') :"";
        this.isParticipantTypeValid = this.contactItems.participationType != '' && this.contactItems.participationType != undefined ? true : false
        this.isStatementDateValid = Utils.ValidateDate(this.contactItems.statementDate) ? true : false
        this.isValidContact = this.contactItems.entityCode ? true : false
        this.isPersonalStatementValid = this.contactItems.personalStatement && this.contactItems.personalStatement.trim() != '' ? true : false

        if (this.isPersonalStatementValid == false)
          this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncPartProvidPerStaMsg'),"errorAutoHide");

        let validpersonalStatement = false;
        let validobservation = false;
      
      if(this.minPersonalStatement !== null && this.minPersonalStatement !== ""){
        validpersonalStatement = personalStatement.length >= this.minPersonalStatement;
      }else{
        validpersonalStatement = true;
      }

      if(this.minObsevationStatement !== null && this.minObsevationStatement !== ""){
        validobservation = observation.length >= this.minObsevationStatement;
      }else{
        validobservation = true;
      }

      if(validpersonalStatement == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minPersonalStatement,this.$t('Incident.IncPartProvidPerStaC1Msg'),this.$t('Incident.IncPartProvidPerStaC2Msg')) ,"errorAutoHide");
      }

      if(validobservation == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minObsevationStatement,this.$t('Incident.IncPartProvidRecoC1Msg'),this.$t('Incident.IncPartProvidRecoC2Msg')) ,"errorAutoHide");
      }

        return this.isParticipantTypeValid && this.isStatementDateValid && this.isValidContact && this.isPersonalStatementValid && validpersonalStatement && validobservation
      },

      ChangePoliceArrest() {
      },

      ChangeVisittoER() {
      },

      ChangeParticipantType(value) {
        this.modelParticipantType = value !== null ? value : {code:'',description:''};
        this.isParticipantTypeValid = value && value.code ? true : false; 
        this.contactItems.participationDescription = value ? value.description : null;
        this.contactItems.participationType = value ? value.code : null
      },
      
      emitEventOK(bvModalEvent){
        if (this.validateFields()) {
          if (this.ModeEdit == false) {
            this.contactItems.entityType = 'C'
            this.contactItems.id = Utils.generateRecID()
          }        
        this.$emit('getCodeConditionOK',this.contactItems);
        } else {
          bvModalEvent.preventDefault()
        }
      },
      
      emitEventCancel(){
        this.$emit('getCodeConditionCANCEL');
      }, 

      getParticipationTypeContacts() {
        IncidentReport.getParticipationTypeContacts()
          .then((response) => {
            if(response.status == '200') {  
              this.ParticipantList = response.data;
            }                             
          })
          .catch((error) => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncPartContacTypeMsg'),error,"error");
            }
          });
      },

      



      getValues: function (value) {
        if(value.code) {
          this.contactItems.entityName = value.description
          this.contactItems.entityCode = value.code;
          if(value.AuxFields.length == 2){
            this.contactItems.RecNo = value.AuxFields[0];
            this.contactItems.RecordId = value.AuxFields[1];
          }else{
            this.contactItems.RecNo = "";
            this.contactItems.RecordId = "";
          }
          
        } else {
          this.contactItems.entityName = ''
        }
      },

       getDateTime() { 
         GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                this.APIDateTime = Utils.formatterDateTimeToString(response.data);
                this.contactItems.statementDate = Utils.formatterDate(response.data)
               }  
            })
            .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
              }else{
                this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
              }
            });   
      },     
   },

  mounted(){ 
    this.$refs.modalparticipantcontact.$on("hidden.bs.modal");
    this.getParticipationTypeContacts()
    if (this.ModeEdit) {
      let contactItems = {...this.ContactItems}
      this.contactItems.arrestMade = contactItems.arrestMade
      this.contactItems.ervisit = contactItems.ervisit
      this.contactItems.observation = contactItems.observation
      this.contactItems.participationType = contactItems.participationType
      this.contactItems.participationDescription = contactItems.participationDescription
      this.contactItems.entityName = contactItems.entityName
      this.contactItems.personalStatement = contactItems.personalStatement
      this.contactItems.statementDate = Utils.formatterDate(contactItems.statementDate, Utils.getShortDateFormat())
      this.contactItems.entityCode = contactItems.entityCode
      this.contactItems.entityType = contactItems.entityType
      this.contactItems.incidentId = contactItems.incidentId
      this.contactItems.id = contactItems.id
      this.contactItems.recordId = contactItems.recordId
      this.modelParticipantType = {code:this.contactItems.participationType,description:contactItems.participationDescription}
    }else{
      this.getDateTime();  
    }
  }
};
</script>