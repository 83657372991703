<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalAddObjective" @close="this.emitEventCancel" @ok="this.emitEventOK" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
      
        <b-row>
            <b-col lg="12" md="12" sm="12">     
                <MdTitle v-bind:Text="$t('modalAddObjective.objectives')" v-bind:isSubtitle="true" v-bind:Name="'Objectives'" class="subtitleWizard"/>     
                <MdCheckListBox2 
                    v-model="ObjectiveModel"
                    v-bind:Options="ObjectiveList"   
                    v-bind:Height="'300px'"         
                    v-bind:OnChangeProp="OnChangeGoals"
                    v-bind:columnCount="1"
                    :textField="'description'"
                    :valueField="'code'"/> 
            </b-col>                                   
          </b-row>  

        
        <b-row class="mt-2">
            
            <b-col md="12">
                <MdTitle v-bind:Text="$t('modalAddGoal.other')" v-bind:isSubtitle="true" v-bind:Name="'Other'"/>  
                <MdMemo v-bind:Name="'objectivedescription'" v-bind:Rows="'4'" v-bind:UseDinamicClass="false"
                            v-bind:DynamicClass="'modalTextarea'" v-bind:MaxRows="'6'"
                            v-model="objectivedescriptionBlank" />
            </b-col>
           </b-row>    
       
     </b-modal>  
    </div>
</template>
 
 <script>
 //import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import TreatmentPlan from '@/services/treatmentplan.service'
 

 export default {
   name: 'ModalAddObjective',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String,
     GoalRecId:String,
     GoalListObj:Array
   },
   
   data(){
     return {
        objectivedescriptionBlank:"",
        ObjectiveList:[],
        ObjectiveModel:[],
        Objectivedata:[],
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
      this.AddObjective();      
     },

     getDescrByCode(code){
        let result = {desc:"",isEvidencebased:false};
        for(var i = 0; i < this.ObjectiveList.length; i++){
           if(this.ObjectiveList[i].code === code){           
            result.desc = this.ObjectiveList[i].description;
            result.isEvidencebased = this.ObjectiveList[i].isEvidenceBased;
            break;
           }
        }
        return result;
     },

     PrepareData(){
            this.Objectivedata = [];         
            for(var i = 0; i < this.ObjectiveModel.length; i++){
                let result = this.getDescrByCode(this.ObjectiveModel[i]);
                this.Objectivedata.push(
                    {                       
                        objCode:this.ObjectiveModel[i].toString(),
                        objDesc:result.desc,
                        isEvidenceBased:result.isEvidencebased
                    }
                    );
            }

            if(this.objectivedescriptionBlank.length > 0){
                this.Objectivedata.push(
                    {                       
                        objCode:"0",
                        objDesc:this.objectivedescriptionBlank,
                        isEvidenceBased:false
                    }
                    );
            }
        },


     AddObjective(){        
        this.PrepareData();
        if(this.Objectivedata.length > 0){
            var objdata = {
              probRecId:this.ProblemRecId.toString(),
              goalRecId:this.GoalRecId,
              objectives:this.Objectivedata
            };
         TreatmentPlan.AddObjective(objdata)
            .then((response) => { 
               if(response.data){
                this.$emit('RefreshGetTP');
                setTimeout(() => 
                        this.$bvToast.toast(this.$t('treatmentplan.ObjectiveSaved'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 5); 
               }             
            })
            .catch((error) => {
                if (!error.response) {
                        this.showMessage(this.$t('modalAddObjective.objectives'),this.$t('Msg.NotAbleToUpdate'),"error");
                }     
                   else if(error.response.status === 400 ){
                     this.showMessage(this.$t('modalAddObjective.objectives'),error.response.data.errorMessage,"error");
                     }   
                     else if(error.response.status === 500){
                        this.showMessage(this.$t('modalAddObjective.objectives'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modalAddObjective.objectives'),error.response.data.errorMessage,"error");
                     }    
            });
        }
    },

     OnChangeGoals(g){
      console.log(g);
     },


        async getMedezTxObjectivesModal() {
            this.$emit('load', true);
            var parameter = {
                IsWizard:false,
                GroupId: this.groupId,
                ProblemCode: this.probCode,
                GoalCodeList : this.GoalListObj,
                ExcludeWithHeaderId:this.headerId
            };
            await TreatmentPlan.getMedTxObjectives(parameter)
                .then((response) => {
                if (response.status == '200' && response.data && response.data.length > 0) {
                    this.ObjectiveList = response.data[0].objectives;                   
                    this.$emit('load', false);
                }
                this.$emit('load', false);
            })
                .catch(error => {
                this.$emit('load', false);
                if (!error.response) {
                    this.showMessage(this.$t('modalAddObjective.tPlanGoals'), this.$t('Msg.retrieveInfo'), "error");
                }
                else if (error.response.status === 400) {
                    this.showMessage(this.$t('modalAddObjective.tPlanGoals'), error.response.data.errorMessage, "error");
                }
                else if (error.response.status === 500) {
                    this.showMessage(this.$t('modalAddObjective.tPlanGoals'), this.$t('Msg.ThereWereSomeError'), "error");
                }
                else {
                    this.showMessage(this.$t('modalAddObjective.tPlanGoals'), error.response.data.errorMessage, "error");
                }
            });
        },

     emitEventCancel(){
      this.$emit('ModalTPObjective');
    },    
   },
 
  async mounted(){
    await this.getMedezTxObjectivesModal();
   },
 };
 </script>

