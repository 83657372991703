<template>
	<div class="row">
		<div class="col-12">
			<div class="accordion" role="tablist">
				<b-card no-body class="noteSearchPanel mb-0">
					<b-card-header header-tag="header" class="p-0" role="tab">
						<b-button block class="collapseBTN text-truncate" v-on:click="Accordion1Click"><b-icon
								icon="arrow-down-right-circle" aria-hidden="true"></b-icon>
							{{$t('GroupNotesAccordion.gATTENDANCE')}} - {{ TransDate }} - {{ this.programName }} - {{ this.therapyName }}
						</b-button>
					</b-card-header>
					<b-collapse id="accordion-1" visible accordion="GroupNotes" role="tabpanel"
						:class="this.ClassAccordion2" class="groupNotesPanel" v-show="ShowFilter && !ShowNote">
						<b-card-body class="p-1">
							<b-card-text class="notesPanelsClinical cutescroll">
								<b-row>
									<div class="col-sm-12 col-md-3">
										<MdButton v-bind:Label="$t('PatientsList.addPatient')"
											v-bind:ClassProp="'btn btn-primary my-3 btn-block btn-login'"
											v-bind:IconClass="'ti-plus pr-1'" v-on:click="CallModalTable()"
											v-bind:Name="'btn_add'"
											v-bind:isReadOnly="group.groupStatus && group.groupStatus == 'Finalized'">
										</MdButton>
									</div>
								</b-row>


								<div class="row">
									<div class="col-12 mt-2">
										<MdTitle v-bind:Text="$t('GroupNotesAccordion.gATTENDANCE')" v-bind:isSubtitle="true"
											v-bind:Name="'T_TitleSearch'" />
										<div class="row">
											<div class="col-md-12">

												<div class="flex-container">
													<div class="row">

														<div class="col-md-3">
															<MdCheckBox v-bind:Label="$t('Shared.SelectAll')"
																v-bind:Name="'SelectTableAll'"
																v-bind:ClassProp="'AlignCheck mx-3'"
																v-bind:CheckedValue="true" v-bind:UncheckedValue="false"
																v-model="UseSelectTableAll"
																v-on:change="onChangeSelectAll"
																v-bind:ignoreChangedEmit="true"
																v-bind:isReadOnly="group.groupStatus && group.groupStatus == 'Finalized'" />
														</div>
														<div class="col-md-3 mt-3">
															<span class="tableSelectedItems"><span
																	class="selItemsVal">{{ SelectedRecords }}</span> {{
							$t('claimbatchList.item') }}<span
																	v-if="SelectedRecords != 1">s</span> {{
							$t('claimbatchList.selected') }}</span>
														</div>
														<div class="col-md-6">
															<div class="textlabel"
																:data-label="this.$t('Shared.SearchElements')">
																<div class="input-group">
																	<input type="text" class="form-control"
																		v-model="modelSearch"
																		@keyup.enter="FindinItems()">
																	<div class="input-group-append">
																		<MdButton v-bind:Name="'btnsearchinput'"
																			v-bind:VariantProp="'btn btn-sm search-attached'"
																			v-bind:IconClass="'mdi mdi-magnify'"
																			v-on:click="FindinItems">
																		</MdButton>
																	</div>
																</div>
															</div>
														</div>



													</div>
												</div>
												<MdTablePaginationClient v-bind:fields="fields" v-bind:items="items"
													v-bind:totalRows="totalRows" v-bind:refTable="refTable"
													v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
													v-bind:currentPage="currentPage" v-bind:perPage="perPage"
													v-bind:SelectAll="UseSelectTableAll" v-bind:ShowDetails="true"
													v-bind:FieldsDate="FieldsDateArr" v-bind:pageOptions="pageOptions"
													:onRowSelected="onRowSelected" :onFiltered="onFiltered"
													:pageChanged="pageChanged" :RowClick="RowClicked"
													:perPageFunction="PerPageFunction" v-bind:sortBy="'patientName'"
													v-bind:sortDesc="false"
													:tbodyRowAttr="tbodyRowAttr">
												</MdTablePaginationClient>
											</div>
											<div class="space-actions">&nbsp;</div>
										</div>
									</div>
								</div>
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="openNotesPanel mb-0">
					<b-card-header header-tag="header" class="p-0" role="tab">
						<b-button block class="collapseBTN" v-on:click="ClickBtnAccordion2"><b-icon
								icon="arrow-down-right-circle" aria-hidden="true"></b-icon>
							{{$t('GroupNotesAccordion.GroupNote')}} - {{ clinicalNoteName }} <span
								class="highlightInTitle blueTitle">{{ this.group.groupStatus }}</span></b-button>
					</b-card-header>
					<b-collapse id="accordion-2" :visible="showMdNote" accordion="ClinicalNotes" role="tabpanel"
						v-show="ShowNote && !ShowFilter" ref="a2">
						<b-card-body v-if="showMdNote" class="p-1">
							<b-card-text class="notesPanels">
								<!-- OPEN NOTES CODE -->
								<div :class="this.ClassContainerNotes" ref="ContainerNotes">
									<MdNote v-bind:Location="'ClinicalNotes'" v-bind:IsChartReview="false"
										v-bind:IsGroupNote="IsGroupNote" v-bind:IsGroupContainer="IsGroupContainer"
										v-bind:Header="this.Header" v-bind:TransDate="Header.transDate"
										v-bind:AdmissionRecordId="Header.admissionRecordId"
										v-bind:NOTE="this.Header.description" v-bind:ProgramCode="ProgramCode"
										v-bind:CategoryCode="CategoryCode"
										v-bind:VisibleNoteHeader="Header.IsCommonPart ? true : false"
										v-bind:GroupNoteStatus="this.group.groupStatus"
										v-bind:PatientList="this.patientList" v-bind:NotePatientInfo="PatientInfo">
									</MdNote>
								</div>
								<!-- END OPEN NOTES CODE -->
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
		</div>
		<div class="page-actions">
			<b-dropdown class="login mr-2" right :text="$tc('Shared.ActionsTxt',2)" :disabled="!showMenuActions()">
					<b-dropdown-item v-if="isDeleteVisible" v-on:click="resetGroupNote">{{$t('GroupNotesAccordion.reset')}}</b-dropdown-item>
					<b-dropdown-item v-if="isFinalizeVisible"
						v-on:click="saveBefore('Finalize')">{{$t('GroupNotesAccordion.finalize')}}</b-dropdown-item>
					<b-dropdown-item v-if="isUnFinalizeVisible" v-on:click="saveBefore('UnFinalize')">
						{{$t('GroupNotesAccordion.unFinalize')}}</b-dropdown-item>
					<b-dropdown-item v-if="!isAllPatientNoteNew()"
						v-on:click="saveBefore('Report')">{{$t('Shared.Report')}}</b-dropdown-item>
			</b-dropdown>
		</div>
		<ModalTablePaginationServer v-bind:Resource="this.ResourceParameter" v-bind:TypeOption="this.TypeOption"
			@getCodeConditionOK="onCodeConditionOk($event)" @getCodeConditionCancel="onCodeConditionCancel($event)"
			v-bind:Fields="this.modalFields" v-bind:FieldCode="this.fieldCode"
			v-bind:FieldDescription="this.fieldDescription" v-bind:TitleWindow="$t('schedule-editor.FindPatient')"
			v-bind:ModalId="this.modalId" v-bind:AuxFields="this.AuxFields" v-bind:FieldsDate="this.fieldsDate">
		</ModalTablePaginationServer>

		<MdPasswordDlg v-if="showSignPassword" v-bind:Type="TypePassWord" 
			v-bind:Operation="Operation" v-bind:VerificationType="'SupervisorPassword'" v-bind:NoValidateChanges="true"
			@getModalPassword="onModalPasswordResult($event)" />

		<GroupNoteCompositeRep v-if="isReportVisible" :closeWindow="closeReport" :reportTitle="clinicalNoteName"
			:groupCreationId="group.groupCreationId" :isGroupNote="IsGroupNote" />
	</div>
</template>

<script>
import Utils from '../../../common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
import moment from "moment";
import GroupNotesService from '@/services/groupnotes.service'
import ProgramService from '@/services/programs.services'
import TherapyService from '@/services/therapy.service'

export default {
	beforeRouteLeave(to, from, next) {
		if (this.pendingChanges) {
			this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
				title: this.$t('ClinicalNotes.ClinicalNotes'),
				okTitle: this.$t('Shared.BtnSave'),
				cancelTitle: this.$t('Shared.BtnDismiss'),
				footerClass: 'p-2',
				titleClass: 'modal-title modal-type-warning'
			})
				.then(value => {
					if (!value) {
						next();
					} else {
						EventBus.$emit('saveFromGroupNotes', null, '', to);
						//next();
					}
				})
		} else {
			next()
		}
	},
	mixins: [DBFunctions],
	name: "GroupNotesAccordion",
	data() {
		return {
			isFinalizeVisible: false,
			isDeleteVisible: false,
			isUnFinalizeVisible: false,

			showSignPassword: false,
			TypePassWord: 'Sign',
			UserIdSelected: '',
			Operation: "",

			ResourceParameter: "",
			TypeOption: "",
			fieldCode: "patientCode",
			fieldDescription: "patientName",
			modalId: "ef-344-9e5861",
			refCode: "PatientName",
			AuxFields: [
				"headerId",
				"dob",
				"admissionRecordId",
				"programName",
				"dateFrom",
				"dateTo",
				"chartno",
				"facilityCode",
				"isNew",
				"isChecked",
				"activityId",
				"isVoid",
				"sex",
				"programCode",
			],
			fieldsDate: ["dob", "dateFrom", "dateTo"],
			modalFields: [
				{
					key: "patientName",
					sortable: true,
					label: this.$t('Shared.PatientName'),
					class: "text-rigth",
				},
				{
					key: "patientCode",
					sortable: true,
					label: this.$t('Shared.PatientCode'),
					class: "text-rigth",
				},
				{ key: "dob", sortable: true, label: this.$t('Shared.Dob'), class: "text-rigth" },
				{
					key: "programName",
					sortable: true,
					label: this.$tc('Shared.Program', 1),
					class: "text-rigth",
				},
				{
					key: "dateFrom",
					sortable: true,
					label: this.$t('Shared.datefrom'),
					class: "text-rigth",
				},
				{
					key: "dateTo",
					sortable: true,
					label: this.$t('Shared.dateTo'),
					class: "text-rigth",
				},
				{
					key: "chartno",
					sortable: true,
					label: this.$t('AdmissionList.ChartNo'),
					class: "text-rigth",
				},
			],

			PatientInfo: {},
			ProgramCode: '',
			CategoryCode: '',
			ShowNote: false,
			ShowFilter: true,

			pageOptions: [5, 10, 15],
			totalRows: 0,
			currentPage: 1,
			perPage: 10,

			items: [],
			group: [],
			selectMode: "single",
			selected: [],
			Header: {
				signedDate:null,
				coSignedDate:null
			},
			refTable: "selectableTable",

			parameter: {
				NoteTypes: [],
				NoteStatus: 0
			},

			visible: true,
			ShowMessageIsEmpty: false,
			ExistsPatientNotes: false,

			fields: [
				{ key: 'patientName', label: this.$t('Shared.PatientName'), sortable: true },
				{ key: 'patientCode', label: this.$t('Shared.PatientCode'), sortable: true },
				{ key: 'dob', label: this.$t('Shared.Dob'), sortable: true },
				{ key: 'programName', label: this.$t('Patient.ProgramName'), sortable: true },
				{ key: 'dateFrom', label: this.$t('Shared.datefrom'), sortable: false },
				{ key: 'dateTo', label: this.$t('Shared.dateTo'), sortable: false },
				{ key: 'isNew', label: this.$t('GroupNotesAccordion.isNew'), sortable: false, class: 'text-center hidden' }
			],
			showMdNote: false,
			isGroupNode: null,
			groupNode: null,
			patientNode: null,
			TransDate: null,
			pendingChanges: false,
			ClassContainerNotes: 'cutescroll',
			OptionsRightMenu: {
				visible: true,
				btnGroupNotesvisible: true,
				ItemsList: [],
				btnvisible: false,
				itemdisabled: false,
				PatientCode: ''
			},
			UseSelectTableAll: false,
			SelectedRecords: 0,
			modelSearch: "",
			FieldsDateArr: ['dateFrom', 'dateTo', 'dob'],
			CopyItems: [],
			ClassAccordion2: '',
			clinicalNoteName: '',
			patientList: {},
			addBtnReadOnly: false,
			IsGroupNote: true,
			IsGroupContainer: true,
			programName: '',
			therapyCode: '',
			therapyName: '',
			isReportVisible: false,
		};
	},

	methods: {

		tbodyRowAttr() {
			return {style: 'cursor: pointer'}
		},

		showMenuActions() {
			return this.isDeleteVisible || this.isFinalizeVisible || this.isUnFinalizeVisible || !this.isAllPatientNoteNew()
		},

		closeReport() {
			this.isReportVisible = false
		},

		saveBefore(action) {
			if (this.pendingChanges) {
				EventBus.$emit('saveFromGroupNotes', '', action);
			} else {
				this.doAction(action)
			}
		},

		doAction(action) {
			if (action == 'Finalize') {
				this.validationFinalizeNote()
			} else if (action == 'UnFinalize') {
				this.unFinalizeNote()
			} else {
				if (this.group.groupCreationId)
					this.isReportVisible = true
			}
		},

		async onModalPasswordResult(result) {
			if (result.Event === 'Ok') {
				if (result.status === true) {
					this.$emit('load', true);
					await GroupNotesService.unFinalizeGroupNote(this.Header.groupCreationId)
						.then(async () => {
							this.isUnFinalizeVisible = false
							await this.getPatients('PENDING')
							await this.getGroup('PENDING')
							EventBus.$emit('updateGroupStatusOnUnFinalized');
							this.closeNote()
							EventBus.$emit('onLoadGroupNode', this.group, this.patientList[0]);
							this.selectRowsIsChecked()
							this.$emit('load', false);
						})
						.catch((error) => {
							if (!error.response) {
								this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
							} else if (error.response.status === 400) {
								this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
							} else if (error.response.status === 500) {
								this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
							} else {
								this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
							}
							this.$emit('load', false);
						});
				}
				else {
					this.showMessage(this.$t('Incident.IncVoidTitle'), this.$t('Msg.InvalidSupervisorPass'), "error");
				}
			}
			this.showSignPassword = false;
		},

		CallModalTable: async function () {
			let filter = this.$store.state.GroupNotesFilters;
			this.FieldsParameter = [this.fieldCode, this.fieldDescription];
			this.ResourceParameter = "/" + `GroupNotes/AddPatients?TransDate=${filter.TransDate}&TherapyCode=${filter.TherapyCode}&ProgramCode=${filter.ProgramCode}`;
			this.TypeOption = this.refCode;
			this.$bvModal.show(this.modalId);
		},

		onCodeConditionOk(result) {
			let patientItem = {
				headerId: result.AuxFields[0],
				patientCode: result.code,
				patientName: result.description,
				admissionRecordId: result.AuxFields[2],
				chartno: result.AuxFields[6],
				dateFrom: result.AuxFields[4],
				dateTo: result.AuxFields[5],
				dob: result.AuxFields[1],
				facilityCode: result.AuxFields[7],
				isChecked: result.AuxFields[9],
				activityId: result.AuxFields[10],
				isNew: result.AuxFields[8],
				programName: result.AuxFields[3],
				programCode: result.AuxFields[13],
				isVoid: result.AuxFields[11],
				sex: result.AuxFields[12]
			}

			let selected = this.items.find(el => el.patientCode === patientItem.patientCode)

			if (!selected) {
				this.items.push(patientItem)
				this.$set(patientItem, 'selected', true);
				EventBus.$emit('onInsertPatient', patientItem);
				this.insertAPatient(patientItem.patientCode, patientItem.admissionRecordId, patientItem.activityId)
				this.SelectedRecords++;
				this.totalRows = this.SelectedRecords
			} else {
				this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('GroupNotesAccordion.msgAlready'), "warning");
			}
			this.$bvModal.hide(this.modalId);
		},

		onCodeConditionCancel() {
			this.ResourceParameter = "";
			this.$bvModal.hide(this.modalId);
		},

		FindinItems() {
			this.items = this.CopyItems;
			var arr = this.items;
			var temp = [];
			if (this.modelSearch) {
				arr.forEach(element => {
					let { patientCode, patientName, programName, dateTo, dateFrom, facilityCode, dob } = element
					if (patientCode && patientCode.toUpperCase().includes(this.modelSearch.toUpperCase()) || patientName && patientName.toUpperCase().includes(this.modelSearch.toUpperCase())
						|| programName && programName.toUpperCase().includes(this.modelSearch.toUpperCase()) || facilityCode && facilityCode.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
						dateFrom && dateFrom.toUpperCase().includes(this.modelSearch.toUpperCase()) || dateTo && dateTo.toUpperCase().includes(this.modelSearch.toUpperCase()) ||
						dob && dob.toUpperCase().includes(this.modelSearch.toUpperCase())
					) {
						temp.push(element);
					}
				});
			} else {
				temp = this.CopyItems;
			}
			this.items = temp;
			this.totalRows = temp.length;

			this.ShowMessageIsEmpty = this.totalRows == 0
		},

		async onChangeSelectAll(v) {
			if (v) {
				this.SelectedRecords = this.items.length;

				for (var i = 0; i < this.items.length; i++) {
					this.$set(this.items[i], 'selected', true);
					EventBus.$emit('onInsertPatient', this.items[i]);
				}

				this.$emit('load', true);
				this.insertAllPatient()
			} else {

				this.$emit('load', true);
				await this.getExistsPatientsNotesInGroup(this.group.groupCreationId)

				if (this.ExistsPatientNotes) {
					this.askDeleteNotes()
				}
				else {
					this.unselectAllPatients(this.items)
				}
			}
		},

		closeNote(keepOnContainer) {
			this.showMdNote = false;
			if (keepOnContainer) {
				this.ShowNote = true;
				EventBus.$emit('unSelectItem')
			} else 
				this.ShowNote = false;
			this.ShowFilter = false;
			EventBus.$emit('onPatientClickGroupNotes', null);
		},

		async deleteNote() {
			this.$emit('load', true);
			await GroupNotesService.deleteGroupNote(this.Header.groupCreationId)
				.then(async () => {
					await this.getPatients('NEW')
					await this.getGroup('NEW')
					this.closeNote()
					EventBus.$emit('updateGroupStatusOnReset', this.group);
					this.SelectedRecords = 0
					this.UseSelectTableAll = true
					this.onChangeSelectAll(true)
					this.ShowFilter = true;
					this.ShowNote = false;
					this.isFinalizeVisible = false,
						this.isDeleteVisible = false,
						this.isUnFinalizeVisible = false,
						this.$emit('load', false);
				})
				.catch((error) => {
					if (!error.response) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
					}
					else if (error.response.status === 400) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					} else if (error.response.status === 500) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
					} else {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					}
					this.$emit('load', false);
				});
		},

		unFinalizeNote() {
			this.$bvModal.msgBoxConfirm(this.$t('GroupNotesAccordion.msgUn'), {
				title: this.$t('GroupNotes.GroupNotes'),
				okTitle: this.$t('Shared.UnFinalize'),
				cancelTitle: this.$t('Shared.BtnCancel'),
				footerClass: 'p-2',
				titleClass: 'modal-title modal-type-warning'
			})
				.then(value => {
					if (value) {
						this.showSignPassword = true;
					}
				})
		},

		Accordion1Click() {
			this.ShowFilter = !this.ShowFilter;
			this.ShowNote = !this.ShowNote;
		},

		ClickBtnAccordion2() {
			this.ShowFilter = !this.ShowFilter;
			this.ShowNote = !this.ShowNote;
		},

		pageChanged(page) {
			this.currentPage = page;
			this.parameter.PageNumber = this.currentPage;
		},

		PerPageFunction(page) {
			this.perPage = page;
		},

		onRowSelected() {
			this.SelectedRecords = 0;
			if (this.items.length > 0) {
				for (var i = 0; i < this.items.length; i++) {
					if (this.items[i].selected) {
						this.SelectedRecords++;
					}
				}
			}
		},

		selectAllRows() {
			this.$refs.selectableTable.selectAllRows()
		},

		clearSelected() {
			this.$refs.selectableTable.clearSelected()
		},

		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length;
			this.currentPage = 1;
		},

		deleteANote(item) {
			this.$emit('load', true);
			GroupNotesService.deleteANote(this.group.groupCreationId, item.patientCode)
				.then(async () => {
					this.unselectAPatient(item)
					this.$emit('load', false);
				})
				.catch((error) => {
					if (!error.response) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
					} else if (error.response.status === 400) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					} else if (error.response.status === 500) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
					} else {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					}
					this.$emit('load', false);
				});
		},

		deleteAllPatientsNotes() {
			this.$emit('load', true);
			GroupNotesService.deleteAllPatientsNotes(this.group.groupCreationId)
				.then(async () => {
					this.unselectAllPatients(this.items)
					this.getPatients()
					this.$emit('load', false);
				})
				.catch((error) => {
					if (!error.response) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
					} else if (error.response.status === 400) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					} else if (error.response.status === 500) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
					} else {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					}
					this.$emit('load', false);
				});
		},

		askDeleteANote(item) {
			this.$bvModal.msgBoxConfirm(this.$t('GroupNotesAccordion.msgConfirmDelNote'), {
				title: this.$t('GroupNotes.GroupNotes'),
				okTitle: this.$t('GroupNotesAccordion.delTitle'),
				cancelTitle: this.$t('Shared.BtnCancel'),
				footerClass: 'p-2',
				titleClass: 'modal-title modal-type-warning'
			})
				.then(value => {
					if (value) {
						this.deleteANote(item)
					}
				})
		},

		askDeleteNotes() {
			this.$bvModal.msgBoxConfirm(this.$t('GroupNotesAccordion.msgConfirmDelNotes'), {
				title: this.$t('GroupNotes.GroupNotes'),
				okTitle: this.$t('GroupNotesAccordion.delAllTitle'),
				cancelTitle: this.$t('Shared.BtnCancel'),
				footerClass: 'p-2',
				titleClass: 'modal-title modal-type-warning'
			})
				.then(value => {
					if (value) {
						this.deleteAllPatientsNotes()
					}
				})
		},

		unselectAPatient(item) {
			this.$set(item, 'selected', false);
			this.$set(item, 'isNew', true);
			EventBus.$emit('onDeletePatient', item);
			this.SelectedRecords--;
		},

		unselectAllPatients(items) {
			for (var j = 0; j < items.length; j++) {
				this.$set(items[j], 'selected', false);
				EventBus.$emit('onDeletePatient', items[j]);
			}

			this.$emit('load', false);
			this.SelectedRecords = 0;
		},

		async RowClicked(item) {
			if (this.group.groupStatus && this.group.groupStatus != 'Finalized') {
				if (item.selected) {

					await this.getExistsPatientNoteInGroup(item.patientCode);
					if (this.ExistsPatientNotes) {
						this.askDeleteANote(item)
					} else {
						this.unselectAPatient(item)
					}

				} else {
					this.$set(item, 'selected', true);
					EventBus.$emit('onInsertPatient', item);
					this.$emit('load', true);
					this.insertAPatient(item.patientCode, item.admissionRecordId, item.activityId)
					this.SelectedRecords++;
				}
			}
		},

		onCheck(param) {
			this.NotesId = param.notesId;
			this.currentPageSubItems = param.currentPage;
			this.perPageSubItems = 5;
			this.getNoteAllDate(this.NotesId, this.currentPageSubItems, this.perPageSubItems);
		},

		beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''
			}
		},

		insertAPatient(patientCode, admissionRecordId, activityId) {
			let data = []
			let param = {};

			param.patientCode = patientCode,
				param.admissionRecordId = admissionRecordId,
				param.GroupCreationID = this.group.groupCreationId,
				param.activityId = activityId

			data.push(param)

			this.AttedancesInsert(data)
		},

		insertAllPatient() {
			let data = []
			let param = {};

			if (this.items !== null && this.items.length > 0) {
				this.items.forEach(el => {
					param.patientCode = el.patientCode
					param.admissionRecordId = el.admissionRecordId
					param.activityId = el.activityId
					param.GroupCreationID = this.group.groupCreationId
					data.push(param)
					param = {};
				});
				this.AttedancesInsert(data)
			}
			else {
				this.$emit('load', false);
			}
		},

		DeleteAPatient(patientCode, admissionRecordId) {
			let data = []
			let param = {};

			param.patientCode = patientCode,
				param.admissionRecordId = admissionRecordId,
				param.GroupCreationID = this.group.groupCreationId,

				data.push(param)

			this.AttedancesDelete(data)
		},

		deleteAllPatient() {
			let data = []
			let param = {};

			this.items.forEach(el => {
				param.patientCode = el.patientCode
				param.admissionRecordId = el.admissionRecordId
				param.GroupCreationID = this.group.groupCreationId
				data.push(param)
				param = {};
			});
			this.AttedancesDelete(data)
		},

		selectRowsIsChecked() {
			this.SelectedRecords = 0
			this.items.forEach((element, index) => {
				if (element.isChecked) {
					this.$set(this.items[index], 'selected', true);
					EventBus.$emit('onInsertPatient', element);
					this.SelectedRecords++;
				}
			});
		},

		async getPatients(Status = '') {
			var filter = this.$store.state.GroupNotesFilters;
			var parameter = {};
			if (filter !== null) {
				parameter.Status = Status ? Status : filter.Status,
					parameter.NoteID = filter.NoteId,
					parameter.TherapyCode = filter.TherapyCode,
					parameter.ProgramCode = filter.ProgramCode,
					parameter.PatientCode = null,
					parameter.GroupCreationID = Status ? this.Header.groupCreationId : filter.GroupCreationId,
					parameter.TransDate = filter.TransDate
			}
			await GroupNotesService.getPatients(parameter)
				.then((response) => {
					if (response.data) {
						this.items = response.data;

						if (response.data.length > 0) {
							this.items.forEach(function (element) {
								let { dob, dateFrom, dateTo } = element
								if (dob) element.dob = Utils.formatterDateToString(dob);  
								if (dateFrom) element.dateFrom = Utils.formatterDateToString(dateFrom);        
								if (dateTo) element.dateTo =  Utils.formatterDateToString(dateTo);   
							})
						}

						this.CopyItems = this.items;
						this.totalRows = this.items.length;
					}
					this.ShowMessageIsEmpty = response.data.length > 0 ? false : true
				})
				.catch((error) => {
					if (error.response) {
						//this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
					}
				});
		},

		async getGroup(Status = '') {
			var filter = this.$store.state.GroupNotesFilters;
			var parameter = {};
			if (filter !== null) {
				parameter.Status = Status ? Status : filter.Status,
					parameter.NoteId = filter.NoteId,
					parameter.TherapyCode = filter.TherapyCode,
					parameter.GroupCreationID = Status ? this.Header.groupCreationId : filter.GroupCreationId,
					parameter.TransDate = filter.TransDate
			}
			await GroupNotesService.getGroup(parameter)
				.then((response) => {
					if (response.data) {
						this.group = response.data;
					}
				})
				.catch((error) => {
					if (error.response) {
						//this.showMessage(this.$t('ClinicalNotes.GetNoteTypes'),error,"error");
					}
				});
		},

		AttedancesInsert(data) {
			GroupNotesService.GroupNotesAttedancesInsert(data)
				.then((response) => {
					if (response.status == '200') {
						this.$emit('load', false);
					}
				})
				.catch((error) => {
					if (!error.response) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.NotAbleToInsert'), "error");
					}
					else if (error.response.status === 400) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					} else if (error.response.status === 500) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
					} else {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					}
					this.$emit('load', false);
				});
		},

		AttedancesDelete(data) {
			GroupNotesService.GroupNotesAttedancesDelete(data)
				.then((response) => {
					if (response.status == '200') {
						this.$emit('load', false);
					}
				})
				.catch((error) => {
					if (!error.response) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.NotAbleToInsert'), "error");
					}
					else if (error.response.status === 400) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					} else if (error.response.status === 500) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
					} else {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					}
					this.$emit('load', false);
				});
		},

		isAllPatientNoteNew() {
			let allNew = true
			if (this.patientList.length > 0) {
				this.patientList.forEach(el => {
					if (el.isNew == false)
						allNew = false
				});
			}
			return allNew
		},

		isSomePatientNoteNew() {
			let somePatientNew = false
			this.patientList.forEach(el => {
				if (el.isNew == true)
					somePatientNew = true
			});
			return somePatientNew
		},

		validationFinalizeNote() {
			if (this.groupNode.noteStatus == 'New') {
				this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('GroupNotesAccordion.msgCommon', {'param': ' done'}), "error");
				return
			} else if (this.isAllPatientNoteNew()) {
				this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('GroupNotesAccordion.msgAtLeast'), "error");
				return
			} else if (this.groupNode.noteStatus == 'Pending Signature') {
				this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('GroupNotesAccordion.msgCommon', {'param': ' signed'}), "error");
				return
			} else if (this.isSomePatientNoteNew()) {
				this.$bvModal.msgBoxConfirm(this.$t('GroupNotesAccordion.msgConfirmPending'), {
					title: this.$t('GroupNotes.GroupNotes'),
					okTitle: this.$t('GroupNotesDashboard.Finalize'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
					.then(value => {
						if (value) {
							this.finalizeNote()
						}
					})
			} else {
				this.finalizeNote()
			}
		},

		finalizeNote() {
			this.$emit('load', true);
			GroupNotesService.finalizeGroupNote(this.Header.groupCreationId)
				.then(async () => {
					this.isFinalizeVisible = false
					EventBus.$emit('updateGroupStatusOnFinalized');
					EventBus.$emit('afterFinalized');
				})
				.catch((error) => {
					if (!error.response) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
					} else if (error.response.status === 400) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					} else if (error.response.status === 500) {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
					} else {
						this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
					}
					this.$emit('load', false);
				});
		},

		async getExistsPatientNoteInGroup(patientCode) {

			this.ExistsPatientNotes = false;

			var parameter = {};
			parameter.GroupCreationID = this.group.groupCreationId
			parameter.PatientCode = patientCode

			this.$emit('load', true);

			await GroupNotesService.getExitsPatientNote(parameter)
				.then((response) => {
					this.$emit('load', false);

					this.ExistsPatientNotes = response.data == true ? true : false
				})
				.catch((error) => {
					this.$emit('load', false);

					if (error.response) {
						if (!error.response) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
						} else if (error.response.status === 400) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
						} else if (error.response.status === 500) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
						} else {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
						}
					}
				});
		},

		async getExistsPatientsNotesInGroup() {

			this.ExistsPatientNotes = false;

			var parameter = {};
			parameter.GroupCreationID = this.group.groupCreationId

			await GroupNotesService.getExitsPatientsNotes(parameter)
				.then((response) => {
					this.ExistsPatientNotes = response.data && response.data == true ? true : false
					this.$emit('load', false);
				})
				.catch((error) => {
					this.$emit('load', false);

					if (error.response) {
						if (!error.response) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
						} else if (error.response.status === 400) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
						} else if (error.response.status === 500) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
						} else {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
						}
					}
				});
		},

		resetGroupNote() {
			this.$bvModal.msgBoxConfirm(this.$t('GroupNotesAccordion.msConfirm'), {
				title: this.$t('GroupNotes.GroupNotes'),
				okTitle: this.$t('GroupNotesAccordion.delAllTitle'),
				cancelTitle: this.$t('Shared.BtnCancel'),
				footerClass: 'p-2',
				titleClass: 'modal-title modal-type-warning'
			})
				.then(value => {
					if (value) {
						this.deleteNote()
					}
				})
		},

		async getProgramName() {

			if (this.ProgramCode !== null && this.ProgramCode !== "") {
				await ProgramService.get(this.ProgramCode)
					.then((response) => {
						if (response.data) {
							this.programName = response.data.name;
						}
					})
					.catch((error) => {
						if (error.response) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), error, "error");
						}
					});
			}
		},

		async getTherapyName() {
			if (this.therapyCode !== null && this.therapyCode !== "") {
				await TherapyService.get(this.therapyCode)
					.then((response) => {
						if (response.data) {
							this.therapyName = response.data.name;
						}
					})
					.catch((error) => {
						if (!error.response) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.retrieveInfo'), "error");
						} else if (error.response.status === 400) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
						} else if (error.response.status === 500) {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), this.$t('Msg.ThereWereSomeError'), "error");
						} else {
							this.showMessage(this.$t('GroupNotes.GroupNotes'), error.response.data.errorMessage, "error");
						}
					});
			}
		},
	},

	async created() {
		this.filter = this.$store.state.GroupNotesFilters
		this.clinicalNoteName = this.filter.NoteName

		if (this.filter) {
			this.TransDate = moment(this.filter.TransDate).format("ddd, ll");
			this.Header.notesId = this.filter.NoteId
			this.Header.patientCode = 'GroupNote'
			this.Header.transDate = Utils.formatterDateToString(this.filter.TransDate)
			this.Header.noteStatus = this.filter.Status === 'NEW' ? 'New' : ''
			this.ProgramCode = this.filter.ProgramCode
			this.therapyCode = this.filter.TherapyCode
		}

		EventBus.$on("onNoteIsEditing", function (e) {
			this.pendingChanges = e.Status;
		}.bind(this));

		EventBus.$on("onUnFinalizeGroupNote", function () {
			this.unFinalizeNote()
		}.bind(this));

		EventBus.$on("CloseMdNote", async function () {
			await this.closeNote(true)
		}.bind(this));

		EventBus.$on("showDeleteGroupNote", async function () {
			this.isDeleteVisible = true
		}.bind(this));

		EventBus.$on("showFinalizeGroupNote", async function () {
			this.isFinalizeVisible = true
		}.bind(this));

		EventBus.$on("updatePatientList", async function (e) {
			this.patientList = e
		}.bind(this));

		EventBus.$on("onDoAction", async function (action) {
			this.doAction(action)
		}.bind(this));

		EventBus.$on("onResetFromMdNote", async function (patientCode) {
			await this.getPatients('NEW')
			await this.getGroup('NEW')
			this.closeNote()
			EventBus.$emit('resetFromMdNote', patientCode);
			if (this.isGroupNode) {
				EventBus.$emit('onShowNote', true, this.groupNode, {});
			} else {
				let selected = this.patientList.find(el => el.patientCode === patientCode)
				EventBus.$emit('onShowNote', false, this.groupNode, selected);
			}
			this.isFinalizeVisible = false
			this.isDeleteVisible = false
			this.isUnFinalizeVisible = false
		}.bind(this));

		EventBus.$on("afterFinalized", async function () {
			await this.getPatients('FINALIZED')
			EventBus.$emit('onUpdateAfterFinalized', this.items);
			await this.getGroup('FINALIZED')
			this.closeNote()
			EventBus.$emit('onLoadGroupNode', this.groupNode, this.patientList[0]);
			this.selectRowsIsChecked()
			this.$emit('load', false);
		}.bind(this));

		EventBus.$on("onShowNote", async function (isGroupNode, groupNode, item) {
			let filter = this.$store.state.GroupNotesFilters
			this.isDeleteVisible = groupNode.groupStatus == 'Pending'
			this.isFinalizeVisible = groupNode.groupStatus == 'Pending'
			this.isUnFinalizeVisible = groupNode.groupStatus == 'Finalized'
			this.isGroupNode = isGroupNode
			this.groupNode = groupNode
			this.patientNode = item

			this.$emit('load', true);

			await this.closeNote()

			this.PatientInfo = await this.getData(item.patientCode);

			this.Header.description = filter.NoteName
			this.Header.admissionRecordId = isGroupNode ? null : item.admissionRecordId
			this.Header.recordid = isGroupNode ? null : item.admissionRecordId
			this.Header.Id = isGroupNode ? groupNode.headerID.trim() : item.headerId.trim()
			this.Header.groupCreationId = groupNode.groupCreationId
			this.Header.patientCode = isGroupNode ? "GroupNote" : item.patientCode
			this.Header.chartno = isGroupNode ? null : item.chartno
			this.Header.isSigned = groupNode.isSigned
			this.Header.signedDate = groupNode.signedDate
			this.Header.isCoSigned = groupNode.isCoSigned
			this.Header.coSignedDate = groupNode.coSignedDate
			this.Header.isVoid = isGroupNode ? false : item.isVoid
			this.Header.isPendingSignature = groupNode.isPendingSignature
			this.Header.isPendingCoSignature = groupNode.isPendingCoSignature
			this.Header.transDate = filter.TransDate
			this.Header.serviceDate = groupNode.serviceDate
			this.Header.isBillable = groupNode.isBillable
			this.Header.disableBillable = groupNode.disableBillable
			this.Header.showBilling = groupNode.showBilling
			this.Header.therapistCode = groupNode.therapistCode
			this.Header.therapistName = groupNode.therapistName

			this.Header.coTherapistCode = groupNode.coTherapistCode
			this.Header.coTherapistName = groupNode.coTherapistName
			this.Header.transCode = groupNode.transCode
			this.Header.defaultUnit = groupNode.defaultUnit
			this.Header.notesID = filter.NoteId
			this.Header.noteStatus = isGroupNode ? groupNode.noteStatus : item.isNew ? 'New' : ''
			this.Header.facilityCode = isGroupNode ? null : item.facilityCode
			this.Header.frequency = groupNode.frequency
			this.Header.startTime = groupNode.startTime
			this.Header.endTime = groupNode.endTime
			this.Header.userHasReadOnlyAccess = groupNode.userHasReadOnlyAccess
			this.Header.enableEdit = groupNode.enableEdit
			this.Header.customFrame = groupNode.groupStatus == 'Finalized' ? 'TFMDFORMDESIGNERRENDERING' : groupNode.customFrame
			this.Header.allowCosignNotesDoneByCosigners = groupNode.allowCosignNotesDoneByCosigners
			this.Header.endTime = groupNode.endTime
			this.ProgramCode = isGroupNode ? filter.ProgramCode : item.programCode
			this.CategoryCode = groupNode.transCode
			this.Header.patientName = isGroupNode ? null : item.patientName
			this.Header.activityId = isGroupNode ? null : item.activityId
			this.Header.IsCommonPart = isGroupNode ? true : false
			this.Header.GroupNodeHeaderId = groupNode.groupStatus != 'Finalized' ? groupNode.headerID.trim() : null
			this.IsGroupNote = groupNode.groupStatus == 'Finalized' ? false : true

			this.ShowNote = true;
			this.ShowFilter = false;
			this.showMdNote = true;

			this.$emit('load', false);
		}.bind(this));

		window.addEventListener('beforeunload', this.beforeWindowUnload);
	},

	beforeDestroy() {
		window.removeEventListener('beforeunload', this.beforeWindowUnload)
	},

	async mounted() {
		let layoutStatus = this.$store.state.layoutStatus;
		this.OptionsRightMenu.btnGroupNotesvisible = true;
		//this.OptionsRightMenu.ItemsList = this.$store.state.itemListGroupNotes;     
		switch (layoutStatus) {
			case 'L0R0':
				this.$store.commit('setlayoutStatus', 'L0R1');
				break;
			default:
				this.$store.commit('setlayoutStatus', 'L1R1');
		}
		this.$emit('onMenuRightBarGroupNotes', this.OptionsRightMenu);

		await this.getProgramName()
		await this.getTherapyName()
		await this.getPatients()
		await this.getGroup()
		await this.selectRowsIsChecked()
		EventBus.$emit('onPatientClickGroupNotes', null);
		EventBus.$emit('onLoadGroupNode', this.group, this.patientList[0]);

		if (this.group.groupStatus == 'New') {
			this.UseSelectTableAll = true
			this.onChangeSelectAll(true)
		}
	},

	destroyed() {
		this.$store.commit('setItemListGroupNotes', []);
		this.OptionsRightMenu.ItemsList = [];
		this.OptionsRightMenu.Mode = false;
		this.OptionsRightMenu.visible = false;
		this.OptionsRightMenu.btnGroupNotesvisible = false;
		this.$emit('onMenuRightBarGroupNotes', this.OptionsRightMenu);

		EventBus.$off('onShowNote');
		EventBus.$off('afterFinalized');
		EventBus.$off('onResetFromMdNote');
		EventBus.$off('updatePatientList');
		EventBus.$off('showFinalizeGroupNote');
		EventBus.$off('showDeleteGroupNote');
		EventBus.$off('CloseMdNote');
		EventBus.$off('onUnFinalizeGroupNote');
		EventBus.$off('onNoteIsEditing');
		EventBus.$off('onDoAction');
	}
};
</script>

<style src="@/assets/css/clinical/multiselect.css">
.showNote {
	display: auto !important;
}

@import "../../../assets/css/flex.css";
</style>
