<template>
<div :id="targetId" v-bind:style="style" class="cutescroll" oncontextmenu="return false;">
  <div v-if="!ReadOnly && !NoteIsVoid && !IsChartReview">
    <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
  </div>
     <b-row :class="this.$attrs.control.controlMaxLenght > 4 ? 'mx-1' : ''">
        <div :class="'col-md-'+ColumnSize"  v-for="(check) in CheckBoxList" :key="check">
          <b-form-checkbox-group
             id="checkbox-group-2"
             v-model="selected"           
             name="flavour-2"
             class="cursor-context-menu"
             v-b-tooltip.hover :title="Hint">
            <b-form-checkbox
                v-b-tooltip.hover :title="Hint"             
                size="sm"
                :value="check"  
                :disabled="ReadOnly || NoteIsVoid || IsChartReview"
                v-on:change="OnChange"
                class="cursor-context-menu"
                >
              <div v-bind:style="checkbox" :ControlId="ControlId">{{check}}</div>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>         
      </b-row>
      <!-- <VueContext ref="menu">
         <a class="dropdown-item" @click="showModalGetLastAnswers()">
           Get 10 Last Answers
         </a>
      </VueContext> -->
      <MdGetLastAnswers v-if="showGetLastAnswers"
         v-bind:ShowPopup="showGetLastAnswers"
         @getCancel="onCancelGetLastAnswers($event)"
         @getOK="onOKGetLastAnswers($event)"
         v-bind:ControlId="ControlId"
         v-bind:PatientCode="PatientCode"
         v-bind:TransDate="TransDate"
         v-bind:Options="CheckBoxList"
         v-bind:ColumnSize="ColumnSize"
      /> 
  </div>
</template>

<script>
import { EventBus } from '../../event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

Vue.use(ContextMenuPlugin);
export default {
  name: 'MdCheckListBoxCN',
   mixins: [EventBus],
   props: {
     Label:     String,
     isReadOnly:Boolean,
      value:         Array,
      CheckedValue:  String,
      UncheckedValue:String,
      TransDate: String,
      NoteId: String,
      id:[String, Number],
      PatientCode: String,
      IsCommonPart: Boolean,
      IsGroupContainer: Boolean
   },

  data(){
      return{
        ControlMax:1,
        ColumnSize:12,
        selected: this.value,
        allSelected: false,
        NoteIsVoid:false,
        indeterminate: false,
        IsChartReview:this.$attrs.IsChartReview,
        CheckBoxString:this.$attrs.control.allValues,       
        CheckBoxList:[],
        Selected: this.checked,
        ControlId:String(this.$attrs.control.controlID),
        checkbox:{
         // marginLeft:'15px'
         fontSize:'',
        },
        style:{
            position:'absolute',
            border: 'solid 1px #e0e0e0 !important',
            borderRadius: '5px',
            padding: '4px 6px',
            backgroundImage: 'linear-gradient(130deg, white, rgb(241, 253, 255))',
            top: this.$attrs.control.controlTop+'px',
            width:this.$attrs.control.controlWidth+'px',
            height:this.$attrs.control.controlHeight+'px',
            left:this.$attrs.control.controlLeft+'px',
            overflowY:'auto',
            overflowX: 'hidden',
            fontSize:'',
            fontWeight: '',
            color:'',
            textDecorationLine:'',
            fontStyle:'',
            lineHeight: '1.45rem'          
        },
        ReadOnly:this.$attrs.control.isReadOnly || this.isReadOnly ? true : false,
        isRequired: this.$attrs.control.isRequired,
        Hint: this.$attrs.control.controlHint,
        showGetLastAnswers: false,
        //PatientCode: this.$route.params.id,
        menuItems:[],
        targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
        menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID
      }
  },
  methods:{
    InitializeVariables(){
      if(this.$attrs.control.controlMaxLenght === null){
        this.ControlMax = 1;
        this.ColumnSize = 12;
      }else{
         this.ColumnSize = 12 / this.$attrs.control.controlMaxLenght;
         this.ControlMax = this.$attrs.control.controlMaxLenght;
      }
      this.CheckBoxList = this.$attrs.control.allValues;
      this.Selected = this.value ? [this.value] : [];

       if(this.$attrs.control.controlFontForHtml){
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
           var size = parseInt(this.controlFont[1]) + 1;
          this.style.fontWeight = this.controlFont[0];
          this.style.fontSize = size+'px !important';
          this.checkbox.fontSize = size+'px !important';
          this.style.color = this.controlFont[3];
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
    },

    OnChange(value){
      this.$emit('input', value);
      this.style.border = value.length == 0 && this.isRequired ? 'solid 1px #dc3545 !important': 'solid 1px #e0e0e0 !important'
      this.setChangedStatus() 
    },
    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    onOKGetLastAnswers(value) {
      this.selected = value;
      this.$emit('input', value);
      this.setChangedStatus()
      this.showGetLastAnswers = false
    },

    handlerClick(evt){
      if(!this.ReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state){
     //  this.style.border = state ? 'solid 1px #dc3545 !important': 'solid 1px red !important' 
       this.style.border = state ? 'solid 1px #dc3545 !important': 'solid 1px #e0e0e0 !important' 
      
     // this.style.borderColor = state ? '#dc3545 !important': '#e0e0e0 !important';
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
      }
  },
   watch: {
      selected(newValue) {
        // Handle changes in individual flavour checkboxes
        if (newValue.length === 0) {
          this.indeterminate = false
          this.allSelected = false
        } else if (newValue.length === this.CheckBoxList.length) {
          this.indeterminate = false
          this.allSelected = true
        } else {
          this.indeterminate = true
          this.allSelected = false
        }
      }
    },
    created(){
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));
  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }

},
  mounted(){    
    this.InitializeVariables();
    this.loadMenuItems()
    
    if (!this.IsCommonPart && this.IsGroupContainer && this.$attrs.control.isCommonControlNote) {
      this.Hint = this.$t('GroupNotesDashboard.ToCompletedInGroupFocus')
    }
  }
};
</script>
<style>
.scroll{
	overflow: hidden !important;
}
</style>