<template>
   <div id="ContainerPatientAllergy">
      <div class="row">
          <div class="col-12">
            <MdTitleRegInfo v-if="!HideHeader"
              v-bind:RegistrationInfo="PatientTitle" 
              v-bind:titlePrefix=" $t('Allergy.PatientAllergies')" 
              v-bind:IsClinical="IsClinical"
              v-bind:titleRef="'titleElementAllergyList'"
              v-bind:patientCode="this.$route.params.id">
            </MdTitleRegInfo>

            <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
              <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
              <div class="flex-grow-1">
                <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
              </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-3">                      
                    <MdButton
                      v-bind:Label= "$t('AllergiesList.AddAllergy')" 
                      v-bind:ClassProp="this.isReadOnly || !this.disableAddAllergies ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                      v-bind:IconClass="'ti-plus pr-1'"
                      v-on:click="AddAllergy"
                      v-bind:isReadOnly="this.isReadOnly || !this.disableAddAllergies"
                      v-bind:Name="'btn_add_allergy'">
                    </MdButton>
                </div>
                <div class="col-sm-12 col-md-9 mb-4">
                    <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">
                            <input 
                              type="text" 
                              class="form-control"
                              v-model="parameter.SearchCriteria"   
                                @keyup.enter="SearchAllergies"                            
                            >
                            <div class="input-group-append">
                                <div class="input-group-append">
                                <MdButton 
                                  v-bind:Name="'btnsearchinput'"
                                  v-bind:VariantProp="'btn btn-sm search-attached'"
                                  v-bind:IconClass="'mdi mdi-magnify'"
                                  v-on:click="SearchAllergies">
                                </MdButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>              
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="isReadOnly"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:FieldsDate="FieldsDateArr"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditAllergy"
                v-bind:VisibleEditIcon="true"
                :deleteIconEvent="RemoveAllergy"
                v-bind:VisibleDeleteIcon="true"
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction"
                :otherIcon="'printer-fill'"
                :otherIconEvent="showReport">                
              </MdTable>
            </div>
          </div>
        </div>
      </div>
      <div class="space-actions"></div>
      <div class="page-actions">
        <MdButton
          v-bind:isReadOnly="items.length == 0"
          v-bind:Label="$t('Shared.Report')"
          v-bind:Name="'btnpatientlist'"
          v-bind:ClassProp="'secondary mx-2 btn-login'"
          v-on:click="showReport()"/>
      </div>
      <PatientAllergyRep v-if="isReportVisible" 
        :closeWindow="closeParamRepWindow"
        :patientCode="this.$route.params.id"
        :recNo="recNoToReport"
        :patientName="this.RegistrationInfo.patientName"
        />          
    </div>
</template>

<script>
import AllergyService from '@/services/allergies.service'
import EnumService from '@/services/enum.service' 
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import Utils from '@/common/utils'

export default {

  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.$store.state.isUsePatientAllergies;
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

  mixins: [ShowMessage, DBFunctions],
  props:{
    HideHeader:Boolean,
    HideBack:Boolean, 
    NoRouter:Boolean,
    MedicationRecId:String,
   },
  name: 'Allergies',
  data() {
    return {
      patient:{},
      IsClinical:false,
      RegistrationInfo:{},
      FieldsDateArr:['datefrom','dateto'],
      ShowMessageIsEmpty:false,
      Loading:true,
      PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:'',
       activitytypename:'',
       startdatetime:'',
       ageFormatted:''
     }, 
      OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },  
      AllergyId:'',   
      Mode:'',         
      parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0 ,
        PatientCode:''             
      },
      fields: [
        { key: 'allergy', sortable: true, label: this.$t('Allergy.Allergy'), class: 'text-rigth' },       
        { key: 'isActive', sortable: true, label: this.$t('Allergy.Active'), class: 'text-rigth', formatter: value => {return value ? 'Y' : 'N'} },       
        { key: 'problem', sortable: true, label: this.$t('AllergiesList.Problem'), class: 'text-rigth' },      
        { key: 'severity', sortable: true, label: this.$t('Allergy.Severity'), class: 'text-rigth' },
        { key: 'datefrom', sortable: true, label: this.$t('Shared.datefrom'), class: 'text-rigth' }, 
        { key: 'dateto', sortable: true, label: this.$t('Shared.dateTo'), class: 'text-rigth' },        
        ],
      pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      filter: null,
      filterOn: [],
      items: [],
      selected: [],
      Header:{}, 
      isReadOnly: false,
      destination:[],
      disableAddAllergies: false,
      isReportVisible: false,
      recNoToReport: null,
      currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee:{
          firstname:"",
              lastname:""
          }
      },      
    }
  },

  computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }    
  },
    
  methods: {
     async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';

          const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

          if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
          if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
          if (age) this.PatientTitle.age = age;
          if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;
      }
    },
    SearchAllergies(){
      this.Loading = true;
      this.$emit('load', this.Loading);
      this.items = [];
      this.currentPage = 1;
      this.parameter.PageNumber = 1;
      this.totalRows = this.items.length;
      this.getAllergies();
    },

    funcDestination(value) {
     this.destination=value;
   },

    OnUpdateContactList() {
      this.getContacts();
    },

    getAllergies() { 
      this.parameter.PageNumber = this.currentPage;           
      this.parameter.PageSize = this.perPage;
      this.parameter.PatientCode = this.$route.params.id;
      AllergyService.getAll(this.parameter)
      .then((response) => {
        if(response.status == '200' && response.data && response.data.length > 0){
         this.items = response.data;
         this.items.forEach(function(element) {
						if (element.datefrom)	
              element.datefrom = Utils.formatterDateToString(element.datefrom); 
            if (element.dateto)	
              element.dateto = Utils.formatterDateToString(element.dateto);
            if (element.isActive)	
              element.isActive = element.isActive ? 'Y' : 'N';   
          });
         let headerString = response.headers["x-pagination"];
         this.Header = JSON.parse(headerString);
         this.totalRows = this.Header.TotalCount;
        }
        if(this.items.length === 0){
           this.ShowMessageIsEmpty = true;
         }
        this.Loading = false;
        this.$emit('load', this.Loading);
      })
      .catch(error => {
        this.Loading = false;
        this.$emit('load', this.Loading);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
        }
      });
    },

    EditAllergy(row){
       this.AllergyId = row.recId;
       this.Mode = "Edit";     
       this.OptionsRightMenu.btnvisible = true;
       
       if (this.$route.name.includes('appointments-allergies')){
            this.$router.push({ name: "appointments-allergy-details", params: { id: this.$route.params.id, allergyid: this.AllergyId, actId: this.$route.params.actId} });
       }
       else if(this.$route.name.includes('patient')){   
          this.$router.push({ name: "allergy-details", params: { id: this.$route.params.id, allergyid: this.AllergyId } });
       }
       else{		
          this.$router.push({ name: "Clinical-allergies-details", params: { id: this.$route.params.id,allergyid: this.AllergyId,recordid:this.$route.params.recordid } }).catch(()=>{});
      }

       this.$emit('getVisibleMenu', this.OptionsRightMenu);   
    },

    RemoveAllergy(row){
      const AllergyId = row.recId
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('AllergiesList.DeleteAllergyTitle'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_allergy'
				})
          .then(value => {
            if(value){
                AllergyService.deleteAllergy(AllergyId)
                .then(() => {
                  const index = this.items.findIndex(item => item.recId === AllergyId) // find the post index 
                  if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view
                    this.getAllergies(); //refresh listing
                  }
                })
                .catch((error) => {
                  if (!error.response) {
                    // network error
                    this.showMessage(this.$t('Allergy.PatientAllergies'),this.$t('Msg.NotAbleToDelete'),"error");
                  } else {
                    this.showMessage(this.$t('Allergy.PatientAllergies'),error,"error");
                  }
                });
            }
          })
    },

    AddAllergy(){
      this.Mode = "Add";     

      if (this.$route.name.includes('appointments-allergies')){
            this.$router.push({ name: "appointments-allergy-add", params: { id: this.$route.params.id, actId: this.$route.params.actId} });
      }

      if(this.$route.name.includes('patient')){  
        this.$router.push({ name: "patient-allergy-add", params: { id: this.$route.params.id, allergyid: this.AllergyId } });
      }
		
      if(this.$route.name.includes('Clinical')){	
        this.$router.push({ name: "Clinical-allergies-add", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } }).catch(()=>{});
      }  
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.getAllergies();     
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.getAllergies();  
    },

    onRowSelected(items) {    
     this.selected = items;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      this.$refs.selectableTable.selectRow(2)
    },

    unselectThirdRow() {
      this.$refs.selectableTable.unselectRow(2)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showReport(item) {
      if(this.items.length > 0){
        if (item && item.recId) {
          this.recNoToReport = item.recId
        }
        this.isReportVisible = true
      } else {
        setTimeout(() => 
          this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
            title:  this.$t('Allergy.PatientAllergies'),
            autoHideDelay: 3000,
            variant: "success"
          }),
        50);
      }
    },

    closeParamRepWindow() {
      this.isReportVisible = false
      this.recNoToReport = null
    },    
    PopulatePatientTitle(){
      const { dob, dateFrom, dateTo, age, 
                programName, patientName, ageFormatted } = this.RegistrationInfo; // Destructure for easier access
        
      if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
      if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	

      if(this.$route.name.includes('Clinical-allergies')){ 
        if (programName) this.PatientTitle.programName = programName; 
        if (dateFrom) this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
        if (dateTo) this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
        if (patientName) this.PatientTitle.fullName = patientName;
        if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
        if (age) this.PatientTitle.age = age === 0 ? "0" : age;
        if (ageFormatted) this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-allergies')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          this.PatientTitle.dob = this.patient.dob;             
          this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    },
    
    async getCurrentAppointment() {
      this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
      this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
      this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
    }
  },

  async mounted() {
    this.$emit('load', this.Loading);    
    this.getAllergies();
    this.OptionsRightMenu.visible = true;   
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-allergies') ? EnumService.ClinicalItems : this.$route.name.includes('appointments-allergies') ? EnumService.AppointmentItems : EnumService.PatientItems;
    this.$emit("getVisibleMenu", this.OptionsRightMenu);   
    this.isReadOnly = this.$store.state.isPatientAllergiesEHRReadOnly
    this.disableAddAllergies = this.$store.state.isUsePatientAllergies
   
    if(this.$route.name.includes('patient-allergies') || this.$route.name.includes('appointments-allergies')){  
        this.GetPatientByCode();
    }
    
    if (this.$route.name.includes('appointments-allergies'))
      await this.getCurrentAppointment()

    if(this.$route.name.includes('Clinical-allergies')){      
      this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
      const { dateTo, age } = this.RegistrationInfo; // Destructure for easier access

      this.IsClinical = dateTo ? false : true;
      this.RegistrationInfo.age = age === 0 ? "0" : age;
    }

    this.PopulatePatientTitle();
  },
  
    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);

    }
};

</script>