<template>
<div>
  <b-modal 
    :id="modalId" 
    :visible="true" 
    title="Notify" 
    @ok="this.emitEventOK" 
    @cancel="this.emitEventCancel"
    @close="this.emitEventCancel" 
    @hide="emitEventHide"  
    size="lg" 
    centered
    :cancel-title="$t('Shared.BtnCancel')"> 
    <Loading v-bind:IsLoading="this.Load" />       
    <b-row>
            <b-col lg="12" md="12" sm="12">
              <MdSelect
                v-bind:Label="$t('scheduleNotify.sTo')"
                v-bind:Options="options"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'value'"
                v-bind:ModelArray="valueOption1"                       
                v-bind:Multiple="false"
                v-bind:AllowEmpty="false"
                :Input="ChangeDropDownOptions"
                v-bind:isRequired="true"
                v-bind:isValid="this.isSendToValid"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <MdSelect
                ref="option2"
                v-bind:Label="$t('scheduleNotify.mType')"
                v-bind:Options="options2"
                v-bind:FieldDescription="'description'"
                v-bind:FieldCode="'recID'"
                v-bind:ModelArray="valueOption2"                       
                v-bind:Multiple="false"
                v-bind:AllowEmpty="false"
                :Input="ChangeDropDownOption2"
                :CustomLabel="customLabelMessageType"
                v-bind:isRequired="true"
                v-bind:isValid="this.isMessageTypeValid"
              />
            </b-col>
          </b-row>
          <b-row v-bind:style="{overflowY: body ? 'scroll' :'', height: body ? 'calc(40vh)' : ''}">
            <b-col lg="12" md="12" sm="12">
              <p v-html="body"></p>
            </b-col>
          </b-row>
</b-modal>
 </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import MessageTemplateSetupsService from '@/services/messagetemplatesetups.service'
import SchedulerService from '@/services/schedulers.service'
import Loading from '@/components/template/loading/Loading'

export default {
  name: 'ScheduleNotify', 
  mixins: [ShowMessage, DBFunctions],
  components: {
    Loading
  },
  props: {
    onClose: Function,
    onSuccess: Function,
    activityId: [String, Array],
    employeeCode: String,
    patientCode: String,
    sysUser: String,
    module: String,
    showIn: String

   },
  data() {
    return {
      options: [
         { description: this.$tc('Shared.Patient'), value: 'patient'},
         { description: this.$tc('Shared.Employee'), value: 'employee'},
         { description: this.$tc('Contact.PatientContact'), value: 'patientcontact'},
      ],
      options2: [],

      valueOption1: null,
      valueOption2: null,
      subject: null,
      body: null,
      modalId: 'modalNotify',
      closeModal: false,
      isSendToValid: true,
      isMessageTypeValid: true,
      Load: false,
    }
  },

  methods: {

    customLabelMessageType ({ description, notificationType }) {
      return `${description} – ${notificationType}`
    },

    ChangeDropDownOptions(item) {
      this.Load = true;
      this.valueOption1 = item
      
      this.isSendToValid = item ? true : false
      this.$refs.option2.clearSelection()
      this.subject = null
      this.body = null
      
      if (item.value == 'patient') {
        this.getOptions('PATIENT')
      }
      else if (item.value == 'employee') {
        this.getOptions('EMPLOYEE')
      }       
      else if (item.value == 'patientcontact') {
        this.getOptions('PATIENTCONTACT')
      }
    },

    ChangeDropDownOption2(item) {
      this.valueOption2 =  item
      this.isMessageTypeValid = item ? true : false
      if (this.activityId && this.activityId.length == 1) {
        this.Load = true;
        this.CreateNotificationMessage(item.messageType,item.notificationType)
      }
    },

    async CreateNotificationMessage(MessageType, NotificationType) {
      let params = {
        MessageType : MessageType, 
        NotificationType: NotificationType,
        EntityCode: this.activityId.toString()
      }

      await MessageTemplateSetupsService.CreateNotificationMessage(params)
        .then((response) => {
          if(response.status == '200'){
            this.body = response.data.body
            this.subject = response.data.subject

            this.Load = false;
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
    },

    emitEventOK(){   
      if (this.validateInput()) {
        this.Load = true;
        this.SendNotificationMessage()
      }
    },

    validateInput() {
      this.isSendToValid = this.valueOption1 ? true : false
      this.isMessageTypeValid = this.valueOption2 ? true : false

      return this.valueOption1 && this.valueOption2
    },

    emitEventCancel(){
      this.onClose()
      this.closeModal = true
    },

    emitEventHide(handled) {
      handled.preventDefault()
    },

    async SendNotificationMessage() {
      let params = {
        recipientType: this.valueOption1.value == 'patient' ? 'PATIENT' : this.valueOption1.value == 'employee' ? 'EMPLOYEE' : 'PATIENTCONTACT' ,
        messageType: this.valueOption2.messageType,
        notificationType: this.valueOption2.notificationType.toUpperCase(),
        module: this.module,
        action: "SendByUser",
        activityIdList: this.activityId
      }
      
      await SchedulerService.SendNotificationMessage(params)
        .then((response) => {
          if(response.status == '200'){
            this.Load = false;
            this.closeModal = true
            this.onSuccess()
            this.onClose() 	
          } else {
              this.showMessage(this.$t('scheduleNotify.noti'),response.data.errorMessage,"error");
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
      },

    async getOptions(SendTo) {
      let params = {
        SendTo: SendTo,
        ShowIn: this.showIn
      }

      await MessageTemplateSetupsService.getAll(params)
        .then((response) => {
          if(response.status == '200'){
              this.options2 = response.data;
              this.Load = false;
          }
        })
        .catch((error) => {
          this.Load = false;
          if(error.response) {
            this.showMessage(this.$t('scheduleNotify.noti'),error,"error");
          }
        });
      },
    
    async loadFirtValue() {
      this.valueOption1 = { description: this.$tc('Shared.Patient'), value: 'patient'}
      await this.getOptions('PATIENT')
      this.ChangeDropDownOption2(this.options2[0])
    }
  },

  async mounted() {
    await this.loadFirtValue()
  },

  async created () {
    
  },
};

</script>
<style>
  .tab-content-no-padding {
    padding: 0 0 !important;
  }
</style>