<template>
    <div id="ContainerMedications">
      <div class="row">
        <div class="col-12">
          <MdTitleRegInfo
              v-bind:RegistrationInfo="isShedulerDB ? PatientTitle : RegistrationInfo" 
              v-bind:titlePrefix="$t('Medication.Medications')" 
              v-bind:titleRef="'titleElementMedicationList'"
              v-bind:patientCode="this.$route.params.id">
          </MdTitleRegInfo>
          
          <div class="alert alert-fill-warning d-flex align-items-center m-3" role="alert" v-if="isReadOnly">
            <i class="mdi mdi-alert-circle me-2" style="font-size: 1.5rem;"></i>
            <div class="flex-grow-1">
              <span>{{ $t('Shared.ReadOnlyPermission') }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-3">                      
              <MdButton
                v-bind:Label="$t('Medication.Add')"                 
                v-bind:ClassProp="this.isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                v-bind:IconClass="'ti-plus pr-1'"
                v-on:click="AddMedication"
                v-bind:isReadOnly="this.isReadOnly"
                v-bind:Name="'btn_add_medication'">
              </MdButton>
            </div>
            <div class="col-sm-12 col-md-3">  
                <MdSelect
                  v-bind:Label="$t('Medication.RxStatus')"
                  v-bind:Options="RxStatusList"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Model="ModelRxStatus"
                  v-bind:Multiple="false"              
                  :Input="ChangeDropDownRxStatus"                 
                  v-bind:Name="'rxstatus_select'"
                  id="rxtatus_select"
                  />
              </div>
            <div class="col-sm-12 col-md-6 mb-4">
              <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                <div class="input-group">
                  <input 
                    type="text" 
                    class="form-control"
                    v-model="parameter.SearchCriteria"   
                    @keyup.enter="SearchMedications">
  
                  <div class="input-group-append">
                    <MdButton 
                      v-bind:Name="'btnsearchinput'"
                      v-bind:VariantProp="'btn btn-sm search-attached'"
                      v-bind:IconClass="'mdi mdi-magnify'"
                      v-on:click="SearchMedications">
                    </MdButton>
                  </div>
                </div>
              </div>
            </div>
          </div>              
         
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <div class="row">
            <div class="col-md-12">
              <MdTable 
                v-bind:fields="fields"
                v-bind:items="items"
                v-bind:totalRows="totalRows"
                v-bind:isReadOnly="isReadOnly"
                 v-bind:IsMedicationList="true"
                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                v-bind:currentPage="currentPage"
                v-bind:perPage="perPage"
                v-bind:pageOptions="pageOptions"
                :editIconEvent="EditMedication"
                v-bind:VisibleEditIcon="true"
                v-bind:FieldsDate="FieldsDateArr"               
                :onRowSelected="onRowSelected"
                :onFiltered="onFiltered"
                :pageChanged="pageChanged"
                :perPageFunction="PerPageFunction"
                :otherIcon="'printer-fill'"
                :otherIconEvent="ShowReport"
              >
              </MdTable>
            </div>
          </div>
        </div>
      </div>
      <div class="space-actions"></div>
      <div class="page-actions">  
           <b-dropdown id="dropdown-dropup" dropup :text="$tc('Shared.ActionsTxt',2)" variant="primary" class="m-2">      
                <b-dropdown-item  href="#" v-on:click="ShowReport()">{{$t('schedulerDB.dropdown.Report')}}</b-dropdown-item>
                <b-dropdown-item  v-if="visibleCopyOrdeer"  href="#" v-on:click="CopyOrder()">{{$t('Medication.CopyOrder')}}</b-dropdown-item>
                <b-dropdown-item v-if="visibleCopyOrdeerDC" href="#" v-on:click="CopyDCOrder()">{{$t('Medication.CopyDCOrder')}}</b-dropdown-item>
                <b-dropdown-item v-if="visibleInactiveOrder" href="#" v-on:click="InactiveOrders()">{{$t('Medication.InactiveOrder')}}</b-dropdown-item>
                <!-- <b-dropdown-item-button v-if="visibleVoid"> {{ $t('Medication.Void') }}</b-dropdown-item-button> -->
            </b-dropdown>          
        </div>
     <PatientMedicationsRep v-if="isReportVisible" 
      :closeWindow="closeParamRepWindow"
      :patientCode="this.$route.params.id"
      :recNo="recNoToReport"
      />
    </div>
  </template>
  
  <script>
  import MedicationService from '@/services/medication.service'
  import EnumService from '@/services/enum.service' 
  //import PatientService from '@/services/patient.service'
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import DBFunctions from '@/common/DBFunctions.js'
  import Utils from "@/common/utils";
  import PharmacyOptions from '@/services/pharmacyoption.service'
  
  export default {
  
    beforeRouteEnter(to, from, next) {
          next(async vm => {
              const iPI = await vm.CanView();
              if(iPI) next()
              else next('/accessdenied');
          }) 
    },
    mixins: [ShowMessage, DBFunctions],
    name: 'MedicationsList',
    data() {
      return {
        patient:{},
        FieldsDateArr:['startDateTime','endDateTime','stopDateTime'],
        ShowMessageIsEmpty:false,
        Loading:true,
        RegistrationInfo:{},
        PatientInfo:{},
        id:"",
        permission:[],
        ModelRxStatus: {value:'ActiveDuration',name: this.$t('MedisList.ActiveDuration')},
        RxStatusList:EnumService.ClinicalMedicatiosRxStatus,
        Menu : this.$store.state.leftMenu,
        OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,
          PatientCode:''         
        },      
        Mode:'',         
        parameter:{
          SearchCriteria:'',
          PageNumber : 0,
          PageSize:0 ,
          PatientCode:''             
        },
        fields: [
          { key: 'startDateTime', sortable: true, label: this.$t('MedisList.StartDate'), class: 'text-rigth' },    
          { key: 'endDateTime', sortable: true, label: this.$t('MedisList.EndDate'), class: 'text-rigth' },    
          { key: 'recordId', sortable: true, label: 'RecordId', class: 'hidden' },
          { key: 'day', sortable: true, label:this.$t('MedisList.Days'), class: 'text-rigth' },       
          { key: 'drugName', sortable: true, label:this.$t('Medication.DrugName'), class: 'text-rigth' },       
          { key: 'quantityOrdered', sortable: true, label: this.$t('MedisList.TotalQuantity'), class: 'text-rigth' },      
          //{ key: 'sig', sortable: true, label: this.$t('MedisList.Sig'), class: 'text-rigth'},       
          { key: 'prescriber', sortable: true, label:this.$t('MedisList.Prescriber'), class: 'text-rigth' }, 
          { key: 'internalStatus', sortable: true, label:this.$t('MedisList.InternalStatus'), class: 'text-rigth' }, 
          { key: 'stopDateTime', sortable: true, label:this.$t('Medication.StopDate'), class: 'text-rigth' },
          //{ key: 'rxStatus', sortable: true, label: this.$t('Medication.RxStatus'), class: 'text-rigth' }, 
        ],
        pageOptions: [5, 10, 15 ],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
        items: [],
        selected: [],
        Header:{}, 
        isReadOnly: false,
        isReportVisible: false,
        recNoToReport: '',
        visibleCopyOrdeer: false,
        visibleCopyOrdeerDC: false,
        visibleInactiveOrder: false,
        visibleVoid: false,
        PharmacyOption:{},
        IsPrescriptionCurrent: false,
        isShedulerDB: false,
        PatientTitle:{      
          programName:'',
          fullName:'',
          admitDate:'',
          dischargeDate:'',
          dob:'',
          activitytypename:'',
          startdatetime:'',
          ageFormatted:''
        },
        currentAppointmentDB:{
          guid:'',
          activitytypecode:'',
          employee: {
          firstname:"",
              lastname:""
          }
        },       
      }
    },
  
    computed: {
      sortOptions() {       
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          });
      }    
    },
      
    methods: {
      SearchMedications(){
        this.Loading = true;
        this.$emit('load', this.Loading);
        this.items = [];
        this.currentPage = 1;
        this.parameter.PageNumber = 1;
        this.totalRows = this.items.length;
        this.getMedications();
      },
  
      CanView(){
        return this.permission[0].roleCanView;
    },

    ChangeDropDownRxStatus(v){
        this.ModelRxStatus = v !== null ? v : {value:"All",name:this.$t('Shared.All')};
        this.getMedications();
    },
  

  
      EditMedication(row){       
        this.id = row.recordId.trim();        
        this.Mode = "Edit";
        if (this.isShedulerDB) {
          this.$router.push({ name: "Appointments-medications-details", 
          params: { recId: this.id, actId: this.$route.params.actId } });
        } else {
          this.$router.push({ name: "Clinical-medications-details", params: { recId: this.id } });
        }
      },
  
      RemoveMedication(/*row*/){
        //const recNo = row.recNo
        this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
          title: this.$t('Medication.Medications'),
          okTitle: this.$t('Shared.TitleDelete'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning',
          id:'modal_delete_vitals'
        })
        .then(value => {
          if(value) {
           /* VitalSignService.deleteVital(recNo)
              .then(() => {
                  const index = this.items.findIndex(item => item.recNo === recNo) // find the post index 
                  if (~index) {// if the post exists in array 
                    this.items.splice(index, 1) //delete the post and refresh view
                    this.getVitalSign(); //refresh listing
                  }
              })
              .catch((error) => {
                if (!error.response) {            
                this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
              } else {
                this.showMessage(this.$t('Medication.Medications'),error,"error");
              }
              });*/
          }
        })
      },
  
      AddMedication(){    
        this.Mode = "Add";
        if (this.isShedulerDB) {
          this.$router.push({ name: "Appointments-medications-add", 
          params: { id: this.$route.params.id, actId: this.$route.params.actId } });
        }
        else {
          this.$router.push({ name: "Clinical-medications-add", params: { id: this.$route.params.id } });
        }
      },
  
      pageChanged (page) {            
        this.currentPage = page;    
        this.getMedications();     
      },
  
      PerPageFunction(perpage){
        this.perPage = perpage;  
        this.currentPage = 1;
        this.getMedications();  
      },

      async getMedications() {
        this.items = [];       
        var param = {
            PatientCode:this.$route.params.id,
            Status:this.ModelRxStatus.value,
            PageNumber:this.currentPage,
            PageSize:this.perPage,
            SearchCriteria:this.parameter.SearchCriteria ? this.parameter.SearchCriteria : ""
        }
      await MedicationService.getAll(param)
        .then((response) => {
          if (response.status == "200" && response.data && response.data.length > 0) {
            this.items = response.data; 
            this.totalRows =  this.items[0].maxRows;  
            this.items.forEach(function(item) {
              if (item.startDateTime)	
                  item.startDateTime = Utils.formatterDateTimeToString(item.startDateTime);
              if (item.endDateTime)	
                  item.endDateTime = Utils.formatterDateTimeToString(item.endDateTime);
              if (item.stopDateTime)	
                  item.stopDateTime = Utils.formatterDateTimeToString(item.stopDateTime);
            });      
          }
          if(this.items.length === 0){
            this.ShowMessageIsEmpty = true;
          }
          this.Loading = false;
          this.$emit('load', this.Loading);
        })
        .catch((error) => {
          this.Loading = false;
          this.$emit('load', this.Loading);
          if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
        });
    },
  
      onRowSelected(items) {    
       this.selected = items;
       this.visibleCopyOrdeer = items.length > 0 ? true : false
       this.visibleCopyOrdeerDC = items.length > 0 && items[0].rxStatus != 'Inactive' ? true : false
       this.visibleInactiveOrder = this.setVisibilityInactiveOrder(items)
       this.visibleVoid = items.length > 0 && items[0].internalStatus == 'ORDERED' ? true : false
      },
      
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
  
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
      },
  
      selectThirdRow() {
        this.$refs.selectableTable.selectRow(2)
      },
  
      unselectThirdRow() {
        this.$refs.selectableTable.unselectRow(2)
      },
  
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      closeParamRepWindow() {
        this.isReportVisible = false
        this.recNoToReport = ''
      },

      ShowReport(item) {
        if(this.items.length > 0){
          if (item && item.recordId) {
            this.recNoToReport = item.recordId
          }
          this.isReportVisible = true
        } else {
          setTimeout(() => 
            this.$bvToast.toast(this.$t('MdTable.nRecShow'), {
              title:  this.$t('Medication.Medications'),
              autoHideDelay: 3000,
              variant: "success"
            }),
          50);
        }
      },

      async getPrescriptionCurrent(Action) {
        const params = {
          "PatientCode": this.selected[0].patientCode,  
          "DrugCode": this.selected[0].drugCode,
          "Mode": this.selected[0].mode, 
          "RecordId": this.selected[0].recordId, 
          "Action": Action ? 'COPY_DC' : 'COPY', 
          "StartDateTime": Utils.formatterToISO(this.selected[0].startDateTime, Utils.getDateTimeFormat()), 
          "EndDateTime": Utils.formatterToISO(this.selected[0].endDateTime, Utils.getDateTimeFormat()),
          "Day": this.selected[0].day,
        }

        await MedicationService.IsPrescriptionCurrent(params)
                .then((response) => {
                    if(response.status == '200'){
                        this.$emit('load', false);
                        this.IsPrescriptionCurrent = response.data 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                      this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),this.$t('Msg.NotAbleToInsert'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                      this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                      this.showMessage(this.$t('Medication.IsPrescriptionCurrent'),error.response.data.errorMessage,"error");
                    } 
                    this.$emit('load', false);
                });

      },

      async SendCopyOrder(stopPreviousPresc){    

        if (this.PharmacyOption && this.PharmacyOption.copyActivePresc){
          
          await this.getPrescriptionCurrent(stopPreviousPresc)
          
          if (this.IsPrescriptionCurrent) {
            this.$bvModal.msgBoxConfirm(this.$t('Medication.MsgPrescriptionCurrent'), {
                title: this.$t('Medication.Medications'),
                okTitle: this.$t('Shared.Continue'),
                cancelTitle: this.$t('Shared.BtnCancel'),
                footerClass: 'p-2',
                titleClass: 'modal-title modal-type-warning',
                id:'modal_delete_vitals'
              })
              .then(value => {
                if(value) {
                  this.setCopyOrder(stopPreviousPresc)
                }
              })
          } else {
            this.setCopyOrder(stopPreviousPresc)
          }
        } else {
            this.setCopyOrder(stopPreviousPresc)
         }
      },

      setCopyOrder(stopPreviousPresc) {
        const params = {
          "oldRecordID": this.selected[0].recordId,
          "admissionRecordID": this.$route.params.recordid,
          "stopPreviousPresc": stopPreviousPresc
        }
        MedicationService.CopyOrdeer(params)
                .then((response) => {
                    if(response.status == '200'){
                        this.$emit('load', false);
                        this.getMedications();
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.MsgOrderCopied'));
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                      this.showMessage(this.$t('Medication.CopyOrder'),this.$t('Msg.NotAbleToInsert'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Medication.CopyOrder'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                      this.showMessage(this.$t('Medication.CopyOrder'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                      this.showMessage(this.$t('Medication.CopyOrder'),error.response.data.errorMessage,"error");
                    } 
                    this.$emit('load', false);
                }); 
      },

      InactiveOrders() {
        const params = {
          "recordIDList": [ this.selected[0].recordId ]
        }
        
         MedicationService.InactiveOrders(params)
                .then((response) => {
                    if(response.status == '200'){
                        this.$emit('load', false);                        
                        this.getMedications();
                        this.showMessage(this.$t('Medication.Medications'),this.$t('Medication.MsgOrderInactive'));
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                      this.showMessage(this.$t('Medication.InactiveOrder'),this.$t('Msg.NotAbleToInsert'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Medication.InactiveOrder'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                      this.showMessage(this.$t('Medication.InactiveOrder'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                      this.showMessage(this.$t('Medication.InactiveOrder'),error.response.data.errorMessage,"error");
                    } 
                    this.$emit('load', false);
                }); 

      },

      async CopyOrder() {
        //this.$emit('load', true);
        await this.SendCopyOrder(false)
      },

      async CopyDCOrder() {
        //this.$emit('load', true);
        await this.SendCopyOrder(true)
      },

      async getPharmacyOptions(){
         this.$emit('load', true);             
         await PharmacyOptions.getPharmacyOptions()
            .then((response) => {
               if(response.status == '200' && response.data && response.data.length > 0){                
                  this.PharmacyOption = response.data[0];
                  this.$emit('load', false);   
               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
          this.$emit('load', false);
               });        
      },

      setVisibilityInactiveOrder(items) {
        let visibility = false
        
        if (items.length > 0 && items[0].rxStatus != 'Inactive')
          if (items[0].internalStatus == 'MARSCREATED' || this.PharmacyOption.inactivePrescAtAnyStatus)
            visibility = true
        
        return visibility
      },

      async getCurrentAppointment() {
        this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);
        this.PatientTitle.activitytypename  = this.currentAppointmentDB.activitytypename
        this.PatientTitle.startdatetime = `${Utils.formatterDateToString(this.currentAppointmentDB.transdate)} ${this.currentAppointmentDB.timein}`  
      },

      async GetPatientByCode(){
        let infoList = await this.getInfoTitle(this.$route.params.id);
        if(infoList.length > 0){
          const { dob, firstname, lastname, age, ageFormatted } = infoList[0]; // Destructure for easier access

          if (ageFormatted) this.PatientTitle.ageFormatted = ageFormatted;
          if (dob) this.PatientTitle.dob = Utils.formatterDateToString(dob);
          if (age) this.PatientTitle.age = age;
          if (firstname) this.PatientTitle.fullName = firstname + ' '+ lastname;     
        }
      },
    },
  
   async mounted() {
      this.$emit('load', this.Loading);    
      this.getMedications();
      this.OptionsRightMenu.visible = true;
      this.isShedulerDB = this.$route.name.includes('Appointments-Medications')
      this.OptionsRightMenu.ItemsList = this.isShedulerDB ? EnumService.AppointmentItems : EnumService.ClinicalItems
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
      this.$emit('getBtnRightMenu', this.OptionsRightMenu);
      this.isReadOnly = this.$store.state.isClinicalMedicationsEHRReadOnly; 
      
      if (this.isShedulerDB) {
        await this.getCurrentAppointment()
        await this.GetPatientByCode();
      } else {
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
        const { dob, dateFrom, dateTo, age } = this.RegistrationInfo; // Destructure for easier access

        if (dateFrom) this.RegistrationInfo.dateFrom = Utils.formatterDateToString(dateFrom);
        if (dateTo) this.RegistrationInfo.dateTo = Utils.formatterDateToString(dateTo);	
        if (dob) this.RegistrationInfo.dob =  Utils.formatterDateToString(dob);
        if (age) this.RegistrationInfo.age = age === 0 ? "0" : age;
      }
    },
  
    async created(){
      this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
      await this.getPharmacyOptions()      
    },
    
      destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
  };
  
  </script>