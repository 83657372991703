<template>
  <div>
  <!-- <b-modal :id="modalId" size="xl" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> -->
    <b-modal :id="modalId" no-close-on-backdrop no-close-on-esc size="md" centered scrollable  :title="TitleWindow" ref="modalreasonappt" @close="Close" @ok="handleOk" @cancel="this.emitEventCancel" :cancel-title="$t('Shared.BtnCancel')"> 
    <form ref="form" @submit.stop.prevent="handleSubmit">
     <b-row>
      <b-col lg="12" md="12" sm="12">
        <MdSelect
           v-bind:Label="$t('ModalCancelAppointmentReason.Reason')"
           v-bind:Options="ReasonList"
           v-bind:Model="ReasonVModel"
           v-bind:FieldDescription="'name'"
           v-bind:FieldCode="'code'"
           v-bind:isRequired="true" 
           v-bind:FeedBackText="$t('ModalCancelAppointmentReason.InvalidReason')"    
           v-bind:isValid="isReasonValid"
           v-bind:Multiple="false"
           v-bind:IfUsevalidation="false"
           :Input="ChangeDropDownReason"/>
     </b-col>   
     </b-row>
 
    </form>
   </b-modal>   
  </div>
</template>

<script>
//import GlobalService from '@/services/global.service' 
//import Utils from "@/common/utils";
import ReasonService from '@/services/reason.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import { EventBus } from '@/event-bus.js';

export default {
 name: 'ModalAppointmentReason',
   mixins: [ShowMessage],  
  props: {
    ModalId: String,
    TitleWindow:String, 
    ClickedText:String,
  },
  data(){
      return {
            modalId:this.ModalId,
            ReasonList:[],
            isReasonValid:true,
            ReasonVModel:"",
            ReasonCode: ''           
      }
  },

  methods:{

    checkFormValidity() {
      return this.ReasonVModel === "";
     },

     ChangeDropDownReason(r){
          this.ReasonVModel = r ? r.name:"";
          this.ReasonCode = r ? r.code:"";
      },

      validateRequired(){
        this.isReasonValid = this.ReasonVModel.length > 0 ? true : false;          
      },

      Close(){
        this.ReasonVModel = "";
      },


     handleOk(bvModalEvt) {      
       bvModalEvt.preventDefault();      
       this.handleSubmit()
     },
     handleSubmit() {   
      this.validateRequired(); 
       if (!this.checkFormValidity()) {
         this.emitEventOK();
         return
       }
       this.emitEventOK();
     },
    emitEventOK(){        
      if(this.ReasonVModel){
        var obj = {
          Reason:this.ReasonVModel,
          Text:this.ClickedText
        };

        EventBus.$emit('onSelectAppointmentReason', obj);
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId);
        })

        let objCode = {
          Code:this.ReasonCode,
          Text:this.ClickedText
        };

        EventBus.$emit('onSelectAppointmentReasonCode', objCode);
      }
     },

     emitEventCancel(){
      this.ReasonVModel = "";
     },
     
     getReason() { 
        ReasonService.getAll()
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.ReasonList = response.data;             
            }
          })
          .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('ModalCancelAppointmentReason.AReason'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('ModalCancelAppointmentReason.AReason'),error,"error");
            }
          });
      },
  },

  mounted(){    
    this.$root.$on('bv::modal::show',(bvEvent, modalId) => {   
      if(modalId === 'modalIdAppointmentReason'){         
          this.getReason();
      }
    });
     
  },

   beforeDestroy () {     
     EventBus.$off('onSelectAppointmentReason');  
   },

  
};
</script>